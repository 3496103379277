import PageHeader from 'app/design/speedBot/PageHeader';
import { Order } from 'types/ApiServicesTypes';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import InfoListOrder from 'app/design/speedBot/ComponentItem/Bots/InfoListOrder';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

export default function BotHistoryOrdersModal({
  open,
  handleClose,
  data,
  title,
  source,
}: {
  open: boolean;
  handleClose: () => void;
  data: Order | null;
  title: string | React.ReactElement;
  source?: string;
}) {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <MuiFullScreenModal
        open={open}
        handleClose={handleClose}
        isNoBackButton={true}
        body={
          <>
            <PageHeader
              variant="withChipWithSubtitleWithBack"
              subtitle={data ? data.instrument_id : ''}
              title={data ? data.trading_symbol : ''}
              chipProps={{
                label:
                  data?.direction === '0' ? (
                    <FormattedMessage id="chip.buy" />
                  ) : (
                    <FormattedMessage id="chip.sell" />
                  ),
                color: data?.direction === '0' ? 'infolight' : 'purplelight',
                variant: 'filled',
              }}
              {...(isMdUp && { hideBackButton: true })}
              buttonText={title}
              buttonHandleClick={() => handleClose()}
            />
            {data && <InfoListOrder data={data} source={source} />}
          </>
        }
      />
    </>
  );
}
