import { useQuery } from 'react-query';
import { GetBotHistoryDetail } from 'services/apiServices';
import { Errors, GetBotHistoryDetailResult } from 'types/ApiServicesTypes';

export default function useGetBotHistoryDetail(bid, sid) {
  const objQuery = useQuery<GetBotHistoryDetailResult, Errors>(
    ['GetBotHistoryDetail', bid, sid],
    () =>
      GetBotHistoryDetail(`bots/${bid}/history/${sid}?data_points=20`, 'GET'),
  );
  return objQuery;
}
