import { BrokerFormField } from 'types/ApiServicesTypes';
import { SubscriptionTransformPlan } from 'types/SubscriptionPlans';
import { formatDateString } from 'utils/GenericFunctions';
import * as Yup from 'yup';
import { YupMixedSchema } from './types';

export const ConcatSchema = (field: BrokerFormField) => {
  let validationSchema: YupMixedSchema = Yup.mixed();
  if (field.validationRules && field.validationRules.length > 0) {
    field.validationRules.forEach(rule => {
      switch (rule.type) {
        case 'required':
          validationSchema = validationSchema.concat(
            Yup.string().required(rule.errorMessage),
          );
          break;
        case 'email':
          validationSchema = validationSchema.concat(
            Yup.string().email(rule.errorMessage),
          );
          break;
        case 'regex':
          validationSchema = validationSchema.concat(
            Yup.string().matches(new RegExp(rule.regex!), rule.errorMessage),
          );
          break;
        default:
      }
    });
  }
  return validationSchema;
};

export const createRequestSubscriptionPlan = (
  billingType: string,
  plan: SubscriptionTransformPlan,
) => {
  const objReq = {
    plan_id: 0,
    end_date: '',
    payment_type: '',
    payment_acquirer: '',
    coupon_code: '',
  };
  const dtNow = new Date();
  if (billingType === 'annual') {
    objReq.plan_id = plan.plan_id_yearly;

    if (plan.duration_yearly === 'Forever') {
      const sixYearFromNow = new Date(
        dtNow.setFullYear(dtNow.getFullYear() + 6),
      );
      objReq.end_date = formatDateString(sixYearFromNow, 'DD/M/YYYY');
    } else {
      const intervalFromNow = new Date(
        dtNow.setFullYear(dtNow.getFullYear() + plan.interval),
      );
      objReq.end_date = formatDateString(intervalFromNow, 'DD/M/YYYY');
    }
  } else {
    objReq.plan_id = plan?.plan_id_monthly;

    if (plan.duration_yearly === 'Forever') {
      const sixYearFromNow = new Date(
        dtNow.setFullYear(dtNow.getFullYear() + 6),
      );
      objReq.end_date = formatDateString(sixYearFromNow, 'DD/M/YYYY');
    } else {
      const intervalFromNow = new Date(
        dtNow.setMonth(dtNow.getMonth() + plan.interval),
      );
      objReq.end_date = formatDateString(intervalFromNow, 'DD/M/YYYY');
    }
  }

  return objReq;
};
