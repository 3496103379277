import { useQuery } from 'react-query';
import { Errors, GetBotTemplateDetailResult } from 'types/ApiServicesTypes';
import { GetBotTemplateDetail } from './api';

export default function useGetBotTemplateDetails(id: string) {
  const objQuery = useQuery<GetBotTemplateDetailResult['data'], Errors>(
    ['GetBotTemplateDetails', id],
    () => GetBotTemplateDetail(id),
  );
  return objQuery;
}
