import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';
import { BotSignalParams } from 'types/ApiServicesTypes';
import { createBotSignalModalTableRows } from './util';

const BotSignalsModalTable = ({
  signalData,
  handleMenuClick,
}: {
  signalData: BotSignalParams[];
  handleMenuClick: (
    event: React.MouseEvent<HTMLElement>,
    signal: BotSignalParams,
    index: number,
  ) => void;
}) => {
  const { columns, rows } = createBotSignalModalTableRows(
    signalData,
    handleMenuClick,
  );

  return (
    <>
      <Box>
        <CommonTable
          rows={rows}
          columns={columns}
          // sx={{ '& td': { verticalAlign: 'top' } }}
        />
      </Box>
    </>
  );
};

export default BotSignalsModalTable;
