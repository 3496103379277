import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useCallback, useEffect, useState } from 'react';
import useWalletAction from 'services/Menu/useWalletAction';
import { loadScript } from 'utils/loadScript';
import useGetProfile from 'services/Menu/useGetProfile';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { WalletActionData } from 'types/SubscriptionPlans';
import useWalletPaymentStatus from 'services/Menu/useWalletPaymentStatus';
import WalletTransactionList from './WalletTransactionList';
import AddCreditModal from './AddCreditModal';
import { formatNumber, openInNewTab } from 'utils/GenericFunctions';
import AddIcon from '@mui/icons-material/Add';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { getPlatform } from 'utils/auth';
import { useHistory } from 'react-router-dom';
import TransferCreditModal from './TransferCreditModal';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

function WalletDetails() {
  const history = useHistory();
  const { data: profileDetails, isLoading } = useGetProfile();
  const { data: siteInfo } = useGetSiteInfo();
  const isMdUp = useGetMediaQueryUp('md');
  const mutationWalletPaymentStatus = useWalletPaymentStatus();
  const [openAddCreditModal, setOpenAddCreditModal] = useState(false);
  const [openTransferCreditModal, setOpenTransferCreditModal] = useState(false);
  const creditTransferFlag = useIsFeatureFlag([FEATURES.IS_CREDIT_TRANSFER]);
  const platform = getPlatform();
  const handleAddBalance = (creds: WalletActionData) => {
    const options = {
      key: siteInfo ? siteInfo.razorpay_key : '',
      handler: response => {
        const objPaymentStatusReq = {
          payment_id: creds.payment_id,
          razorpay_subscription_id: response.razorpay_subscription_id as string,
          razorpay_signature: response.razorpay_signature as string,
          razorpay_order_id: response.razorpay_order_id as string,
        };
        mutationWalletPaymentStatus.mutate(objPaymentStatusReq);
      },
      prefill: {
        name: profileDetails?.name,
        email: profileDetails?.email,
        contact: profileDetails?.mobile,
      },
      notes: {
        // note_key_1: invoice.name,
        // note_key_2: `Invoice Date: ${invoice.invoice_date}`,
      },
      order_id: creds.order_id,
    };

    // eslint-disable-next-line
    const paymentObject = new (window as any).Razorpay(options);
    // eslint-disable-next-line
    paymentObject.open();
  };
  const walletAction = useWalletAction(handleAddBalance);

  const handleAddCredit = useCallback(
    (success: boolean, amount: number) => {
      if (success)
        walletAction.mutate({
          action: 'add',
          amount,
        });
      setOpenAddCreditModal(false);
    },
    [walletAction],
  );
  const handleTransferCredit = useCallback((success: boolean) => {
    if (success) {
      setOpenTransferCreditModal(false);
    }
  }, []);

  useEffect(() => {
    void loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
      'razorpay_script',
    );
    return () => {
      document.getElementById('razorpay_script')!.remove();
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('action')) {
      if (queryParams.get('action') === 'add') {
        setOpenAddCreditModal(true);
      }
      queryParams.delete('action');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history]);

  return (
    <>
      <CircularLoader
        open={
          isLoading ||
          walletAction.isLoading ||
          mutationWalletPaymentStatus.isLoading
        }
      />
      {profileDetails && (
        <>
          <Box
            className="p-main-spacing"
            sx={{
              py: 2,
              gap: 2,
              display: 'flex',
              alignItems: {
                xs: creditTransferFlag ? 'flex-start' : 'flex-end',
                md: 'flex-start',
              },
              justifyContent: 'space-between',
              flexDirection: {
                xs: creditTransferFlag ? 'column' : 'row',
                md: 'column',
              },
              backgroundColor: 'grey.100',
            }}
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight={500}
                color={
                  profileDetails.wallet_balance < 0
                    ? 'error.main'
                    : 'text.primary'
                }
              >
                {profileDetails.wallet_balance < 0 && '-'}
                {formatNumber(profileDetails.wallet_balance)}
              </Typography>
              <Typography variant="body2" color={'text.secondary'}>
                <FormattedMessage id="menu.credit_balance" />
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: isMdUp ? 'flex-start' : 'flex-end',
                width: !isMdUp && creditTransferFlag ? '100%' : 'auto',
              }}
            >
              <MuiButton
                variant="contained"
                onClick={() => {
                  platform == 'ios'
                    ? openInNewTab(window.location.pathname, { action: 'add' })
                    : setOpenAddCreditModal(true);
                }}
                size={isMdUp ? 'medium' : 'small'}
                startIcon={<AddIcon />}
                sx={{
                  marginRight: 1,
                  alignSelf: 'flex-end',
                  width: !isMdUp && creditTransferFlag ? '50%' : 'auto',
                }}
              >
                <FormattedMessage id="menu.add_credit" />
              </MuiButton>
              {creditTransferFlag ? (
                <MuiButton
                  variant="contained"
                  onClick={() => setOpenTransferCreditModal(true)}
                  size={isMdUp ? 'medium' : 'small'}
                  startIcon={<SyncAltIcon />}
                  sx={{
                    width: isMdUp ? 'auto' : '50%',
                  }}
                >
                  <FormattedMessage id="menu.transfer_credit" />
                </MuiButton>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <WalletTransactionList />
        </>
      )}
      <AddCreditModal open={openAddCreditModal} handleClose={handleAddCredit} />
      <TransferCreditModal
        open={openTransferCreditModal}
        handleClose={handleTransferCredit}
      />
    </>
  );
}

export default WalletDetails;
