import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ReportDropDownValue } from 'types/ApiServicesTypes';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

interface CategorySelectProps {
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  options: ReportDropDownValue[];
  labelName: string;
  resetSelectedValues: () => void;
  // openFrom: string;
}

function CategorySelect({
  value,
  onChange,
  options,
  labelName,
  resetSelectedValues,
}: // openFrom,
// resetSelectedValues,
CategorySelectProps) {
  // const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const isMdUp = useGetMediaQueryUp('md');
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        maxWidth: 'auto',
      },
    },
  };
  // Function to handle the change in selected values
  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    // setSelectedValues(event.target.value as string[]);
    onChange(event);
  };

  return (
    <FormControl variant="outlined" sx={{ width: '100%' }}>
      <InputLabel>{labelName}</InputLabel>
      <Select
        multiple
        value={value}
        MenuProps={isMdUp ? {} : MenuProps}
        onChange={handleSelectChange}
        label={labelName}
        renderValue={selected => (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '90%',
            }}
          >
            {selected.join(', ')}
          </div>
        )}
        onBlur={() => setShow(true)}
        onFocus={() => setShow(false)}
        endAdornment={
          <InputAdornment sx={{ mr: 2 }} position="end">
            {value.length > 0 && show ? (
              <IconButton onClick={resetSelectedValues}>
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </InputAdornment>
        }
      >
        {options?.map(option => (
          <MenuItem key={option.label} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CategorySelect;
