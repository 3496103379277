import { useQuery } from 'react-query';
import { GetInstruments } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import { GetInstrumentsResult } from 'types/Instrument';

// Instruments stale time is 8 hours.
export const INSTRUMENTS_STATE_TIME = 8 * 60 * 60 * 1000;

export default function useGetInstruments(extraPatrams?: string) {
  const objQuery = useQuery<GetInstrumentsResult, Errors>(
    ['GetInstruments', extraPatrams],
    () => GetInstruments(extraPatrams),
    // .then(res => {
    //   if (isOrderBySymbols)
    //     res = res.sort((a, b) =>
    //       a.trading_symbol > b.trading_symbol
    //         ? 1
    //         : b.trading_symbol > a.trading_symbol
    //         ? -1
    //         : 0,
    //     );
    //   return res;
    // })
    {
      staleTime: INSTRUMENTS_STATE_TIME,
    },
  );
  return objQuery;
}
