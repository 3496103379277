import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSaveUserBot from 'services/Bots/useSaveUserBot';
import { SaveUserBotResult } from 'types/ApiServicesTypes';
import * as Yup from 'yup';
import usePostUserBotsIsExist from 'services/Bots/usePostUserBotsIsExist';
import { createEmptyOptionBuilder } from 'app/components/Builder/OptionsStrategy/Utils/utils';
import { SbStrategy, UserBot } from './types';
import { OptionStrategyFields } from 'app/components/Builder/OptionsStrategy/FieldsData';
import { StrategyBuiltWithTypes } from './util';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import useGetUserBot from 'services/Bots/useGetUserBot';
import { useTheme } from '@mui/material/styles';

type AddCreditModalProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
};

function CreateCodeStrategtModal({ open, handleClose }: AddCreditModalProps) {
  const theme = useTheme();
  const [step, setStep] = useState(0);
  const [res, setRes] = useState<SaveUserBotResult>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sbStrategy, setSbStrategy] = useState<SbStrategy>(
    createEmptyOptionBuilder(OptionStrategyFields),
  );
  const breadcrumb = useBreadcrumbManager();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveUserBotSuccessCallback = (res: SaveUserBotResult, name: string) => {
    if (res) {
      setStep(1);
      setRes(res);
    }
  };
  const mutationIsExist = usePostUserBotsIsExist();

  const mutationAdd = useSaveUserBot(saveUserBotSuccessCallback);
  const setUserBotData = (userBotData: UserBot) => {
    // Call api here
    if (userBotData) {
      const objReq = userBotData;

      objReq.is_backtest = false;

      mutationAdd.mutate(objReq);
    }
  };
  const { data: userBotDetails, isLoading } = useGetUserBot(res?.bot_id);
  // const mutationEdit = useEditUserBot(botId, saveUserBotSuccessCallback);
  const { t } = useTranslation();
  return (
    <MuiFullScreenModal
      open={open}
      handleClose={() => handleClose(false)}
      body={
        <Formik
          enableReinitialize
          initialValues={{ amount: 8, name: '' }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_bot_startegy.form.bot_name'),
              }),
            ),
          })}
          onSubmit={values => {
            mutationIsExist.mutate(
              { name: values.name },
              {
                onSuccess: resSuccess => {
                  if (resSuccess.success)
                    setUserBotData({
                      ...values,
                      sb_strategy: {
                        ...sbStrategy,
                        type: 'code',
                        built_with: StrategyBuiltWithTypes.code_builder,
                      },
                    });
                },
              },
            );
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            handleBlur,
            touched,
            setFieldValue,
          }) => {
            const nameExistError =
              mutationIsExist.data && mutationIsExist.data.error
                ? mutationIsExist.data.error
                : '';
            return (
              <>
                {step === 0 ? (
                  <Box
                    className="p-main-spacing"
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1,
                      p: 2,
                      borderRadius: 2,
                      justifyContent: 'space-between',
                      bgcolor: 'background.paper',
                      height: 1 / 1,
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <FormattedMessage id="user_bot_startegy.form.bot_name" />
                    </Typography>

                    <FormControl
                      fullWidth
                      error={
                        Boolean(touched.name && errors.name) ||
                        Boolean(touched.name && nameExistError)
                      }
                      size="medium"
                      variant="outlined"
                      sx={{ mb: 1 }}
                    >
                      <InputLabel htmlFor="label-name">
                        <FormattedMessage id="user_bot_startegy.form.bot_name" />
                      </InputLabel>
                      <OutlinedInput
                        id="label-name"
                        name="name"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={e => {
                          if (nameExistError) mutationIsExist.reset();
                          setFieldValue('name', e.target.value);
                        }}
                        label={t('user_bot_startegy.form.bot_name')}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText>{errors.name}</FormHelperText>
                      )}
                      {touched.name && nameExistError && (
                        <FormHelperText>{nameExistError}</FormHelperText>
                      )}
                    </FormControl>

                    <MuiButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ py: 1 }}
                    >
                      <FormattedMessage id="buttons.save_and_continue" />
                    </MuiButton>
                  </Box>
                ) : step === 1 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1,
                      p: 2,
                      borderRadius: 2,
                      justifyContent: 'space-between',
                      bgcolor: 'background.paper',
                      height: 1 / 1,
                    }}
                  >
                    <Box>
                      <Typography variant="h6">
                        Your strategy has been created{' '}
                        <span style={{ color: theme.palette.success.main }}>
                          {' '}
                          successfully !!
                        </span>
                      </Typography>
                      <Typography variant="body1">
                        You can now proceed to the code environment by clicking
                        proceed
                      </Typography>
                    </Box>

                    <MuiButton
                      disabled={isLoading}
                      onClick={() => {
                        if (res && res.bot_id) {
                          breadcrumb.push(
                            `/bot-builder/${res?.bot_id}/0/${
                              userBotDetails?.strategy_id || ''
                            }`,
                            PAGES.BOTS,
                            `/bots?ptype=mybots`,
                            t('bots.page_title.bots'),
                          );
                        }
                      }}
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      Proceed
                    </MuiButton>
                  </Box>
                ) : null}
              </>
            );
          }}
        </Formik>
      }
      footer={<></>}
    />
  );
}

export default CreateCodeStrategtModal;
