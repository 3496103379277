import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import useGetEvents from 'services/common/useGetEvents';
import { getEventsFromCookies } from './utils';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import EventItem from './EventItem';

const EventModal = () => {
  const [openEvent, setOpenEvent] = useState(false);
  const { data: scheduledEvents } = useGetEvents('popup');
  const { data: siteInfo } = useGetSiteInfo();

  const handleOpenEventModal = () => {
    setOpenEvent(true);
  };
  const handleCloseEventModal = () => {
    setOpenEvent(false);
  };

  useEffect(() => {
    if (scheduledEvents && scheduledEvents.length) {
      const scheduledEventsCookieData = getEventsFromCookies();
      const scheduledEvent = scheduledEventsCookieData.filter(
        x => x.id === scheduledEvents[0].id,
      )[0];

      if (scheduledEvent) return;

      handleOpenEventModal();
    }
  }, [scheduledEvents]);

  if (!scheduledEvents || scheduledEvents.length == 0) return <></>;
  return (
    <Dialog
      open={openEvent}
      onClose={handleCloseEventModal}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogContent sx={{ p: 0 }}>
        <EventItem
          event={scheduledEvents[0]}
          handleCloseEventModal={handleCloseEventModal}
          companyName={siteInfo?.name}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EventModal;
