import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UserBot } from './types';
import { SaveUserBotResult } from 'types/ApiServicesTypes';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import PageHeader from 'app/design/speedBot/PageHeader';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import OptionStrategyBuilderForm from './OptionStrategyBuilderForm';
import { getQueryParam } from 'utils/GenericFunctions';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';

//Services
import useEditUserBot from 'services/Bots/useEditUserBot';
import useSaveUserBot from 'services/Bots/useSaveUserBot';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import HelpGuideDialog from './HelpGuideDialog';
import CreateTickFormModal from 'app/components/Menu/CreateTicketFormModal';
import { isUserLoggedIn } from 'utils/auth';
import { CoPresent, SaveAltOutlined } from '@mui/icons-material';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import ImportStrategyDialog from './ImportStrategyDialog';

export default function OptionStrategyBuilderContent({
  botId,
  subId,
  userBotDetails,
  isLoading,
  isTemplate,
}: {
  botId: number;
  subId: number;
  isTemplate?: boolean;
  userBotDetails: UserBot | undefined;
  isLoading: boolean;
}) {
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();
  const { t } = useTranslation();
  const isLgUp = useGetMediaQueryUp('lg');
  const isLoggedIn = isUserLoggedIn();
  const theme = useTheme();
  const source = getQueryParam('source');

  const [open, setOpen] = useState(false);
  const [openReadDialog, setOpenReadDialog] = useState(false);
  const [openImpmortDialog, setOpenImportDialog] = useState(false);
  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);
  const isHelpDeskInSb = useIsFeatureFlag([FEATURES.IS_HELP_DESK_IN_SB]);
  const isBotDescription = useIsFeatureFlag([FEATURES.IS_BOT_DISCRIPTION]);
  const isImportExportStrategy = useIsFeatureFlag([
    FEATURES.IS_IMPORT_EXPORT_STRATEGY,
  ]);
  const saveUserBotSuccessCallback = (
    res?: SaveUserBotResult,
    name?: string,
  ) => {
    if (res) {
      breadcrumb.push(
        `/bot/${res.bot_id}/backtesting/${subId ? subId : ''}`,
        PAGES.BOT_STRATEGY_BUILDER,
        `/bot-builder/${botId}/${subId ? subId : ''}?type=option`,
        name as string,
      );
    }
  };

  const mutationAdd = useSaveUserBot(saveUserBotSuccessCallback);
  const mutationEdit = useEditUserBot(botId, saveUserBotSuccessCallback);
  const { data: siteInfo } = useGetSiteInfo();
  const setUserBotData = (userBotData: UserBot) => {
    // Call api here
    if (userBotData) {
      const objReq = userBotData;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      //  userBotDetails&&userBotDetails.sb_strategy&&userBotDetails.sb_strategy.sb_opt_strategy.strategy_sets.map(x=>x.indicator_extras)
      if (
        botId &&
        objReq.sb_strategy &&
        userBotDetails &&
        userBotDetails.sb_strategy
      ) {
        objReq.sb_strategy.start_date = userBotDetails.sb_strategy.start_date;
        objReq.sb_strategy.end_date = userBotDetails.sb_strategy.end_date;

        objReq.sb_strategy.initial_capital =
          userBotDetails.sb_strategy.initial_capital;
        objReq.sb_strategy.trading_start_time =
          userBotDetails.sb_strategy.trading_start_time;
        objReq.sb_strategy.trading_stop_time =
          userBotDetails.sb_strategy.trading_stop_time;
      }
      objReq.is_backtest = false;

      if (isTemplate) objReq.template_id = botId;
      if (!isBotDescription) delete objReq.description;

      if (botId && !isTemplate) mutationEdit.mutate(objReq);
      else mutationAdd.mutate(objReq);
    }
  };
  const params = new URLSearchParams({ ['pytype']: 'mybots' });
  return (
    <Box>
      <CircularLoader
        open={isLoading || mutationAdd.isLoading || mutationEdit.isLoading}
      />
      <Box>
        <PageHeader
          variant="inactive"
          buttonText={
            userBotDetails && Object.keys(userBotDetails).length > 0
              ? t('buttons.backtesting')
              : source
              ? source
              : t('bots.page_title.bots')
          }
          title={
            <Box
              sx={{
                display: { xs: 'block', md: 'flex' },
                alignItems: 'center',
              }}
            >
              {t('user_bot_startegy.page_title3')}
              <Box
                sx={{
                  display: 'flex',
                  mt: { xs: 1, md: 0 },
                  ml: { xs: 0, md: 1 },
                  gap: 1,
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  rowGap={1}
                >
                  <Box display={'flex'}>
                    <FeatureFlag
                      features={[FEATURES.IS_TEMPLATES]}
                      fallback={<></>}
                    >
                      <MuiButton
                        variant="contained"
                        color="secondary"
                        startIcon={<AutoAwesomeMotionIcon />}
                        onClick={() => history.push('/templates?type=option')}
                        // sx={{ width: { xs: '65%', md: 'auto' } }}
                        sx={{ flex: { xs: 5, md: 'auto' }, mr: 1 }}
                      >
                        {t('buttons.use_bot_templates')}
                      </MuiButton>
                    </FeatureFlag>
                    {isHelpDeskInSb && isLoggedIn ? (
                      <>
                        <MuiButton
                          variant="contained"
                          color="secondary"
                          startIcon={<CoPresent />}
                          // startIcon={<AutoStoriesIcon />}
                          onClick={() => setOpenCreateTicketDialog(true)}
                          sx={{ flex: { xs: 5, md: 'auto' }, mr: 1 }}
                        >
                          {t('buttons.develop_with_experts')}
                        </MuiButton>
                      </>
                    ) : (
                      <> </>
                    )}
                  </Box>
                  {isImportExportStrategy && (
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      startIcon={<SaveAltOutlined />}
                      // startIcon={<AutoStoriesIcon />}
                      onClick={() => setOpenImportDialog(true)}
                      sx={{ flex: { xs: 5, md: 'auto' }, mr: 1 }}
                    >
                      Import Strategy
                    </MuiButton>
                  )}
                  <Box
                    display={'flex'}
                    sx={{
                      backgroundColor:
                        theme.component.circularProgressBar.trailColor,
                      flex: { xs: 5, md: 'auto' },
                      mr: 1,
                      pl: 1,
                      borderRadius: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        alignSelf: 'center',
                        // width: { xs: '15%', md: 'auto' },
                        // flex: 'auto',
                      }}
                    >
                      {isLgUp
                        ? t('buttons.how_to_create')
                        : `${t('menu.help')}?`}
                    </Typography>
                    {/* <MuiButton
                  variant="contained"
                  color="secondary"
                  startIcon={<YouTubeIcon />}
                  onClick={() => setOpen(true)}
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                /> */}
                    <IconButton
                      // sx={{ width: { xs: '10%', md: 'auto' } }}
                      // sx={{ flex: 'auto' }}
                      onClick={() => setOpen(true)}
                    >
                      <YouTubeIcon />
                    </IconButton>
                    <IconButton
                      // sx={{ width: { xs: '10%', md: 'auto' } }}
                      // sx={{ flex: 'auto' }}
                      onClick={() => setOpenReadDialog(true)}
                    >
                      <AutoStoriesIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
          note={isLgUp ? '' : t('user_bot_startegy.info_message')}
          buttonHandleClick={() =>
            history.replace({ pathname: '/bots', search: params.toString() })
          }
          breadcrumb={
            <Breadcrumbs
              page={PAGES.BOT_STRATEGY_BUILDER}
              data={{
                subId,
                botId: userBotDetails?.strategy_id,
                userBotId: botId,
                botName: userBotDetails?.name,
              }}
            />
          }
        />
      </Box>
      <Box sx={{ height: '100%' }}>
        <OptionStrategyBuilderForm
          isTempalte={isTemplate}
          setUserData={setUserBotData}
          userData={
            userBotDetails && userBotDetails.sb_strategy ? userBotDetails : null
          }
        />
      </Box>

      <HelpGuideDialog
        handleClose={(val: boolean) => setOpen(val)}
        openReadDialog={open}
        url={
          siteInfo && siteInfo.option_youtube
            ? siteInfo.option_youtube
            : 'https://www.youtube.com/embed/VFJtu0l_YRY'
        }
        title="YouTube video player"
        height="560px"
      />
      <Box>
        <HelpGuideDialog
          handleClose={(val: boolean) => setOpenReadDialog(val)}
          openReadDialog={openReadDialog}
          url={
            siteInfo && siteInfo.option_builder_toolkit_link
              ? siteInfo.option_builder_toolkit_link
              : 'https://help.speedbot.tech/Options-Strategy-Builder-Toolkit/'
          }
          title="Reader Dialog"
          height="760px"
        />
      </Box>
      <CreateTickFormModal
        open={openCreateTicketDialog}
        handleClose={(val: boolean) => {
          setOpenCreateTicketDialog(val);
        }}
        isRequireToRedirect={true}
        subject="Custom Option Strategy Development with Expert"
      />
      <ImportStrategyDialog
        open={openImpmortDialog}
        handleClose={(val: boolean) => setOpenImportDialog(val)}
      />
    </Box>
  );
}
