import { Redirect } from 'react-router-dom';
// project imports
import { GuardProps } from 'types/ComponentTypes';
import { isUserLoggedIn } from 'utils/auth';

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const defaultPath: string = process.env.REACT_APP_DEFAULT_PATH!;

const GuestGuard = ({ children }: GuardProps) => {
  if (isUserLoggedIn()) {
    return <Redirect to={defaultPath} />;
  }

  return children;
};

export default GuestGuard;
