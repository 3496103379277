import { useContext } from 'react';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useMutation } from 'react-query';
import { ReadNotifications } from 'services/apiServices';
import { ReadNotificationsResult, Errors } from 'types/ApiServicesTypes';

export default function useReadNotifications() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    ReadNotificationsResult,
    Errors,
    Pick<{ id: number }, 'id'>
  >('ReadNotifications', values =>
    ReadNotifications(values.id)
      .then(res => {
        return res;
      })
      .catch(err => {
        // showAlert(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
