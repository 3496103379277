// material-ui
import { Alert } from '@mui/material';

const SliceErrorAlert = ({ message }: { message: string }) => {
  return (
    // <Box
    //   sx={{
    //     backgroundColor: 'error.main',
    //     color: '#fff',
    //     textAlign: 'center',
    //     position: 'absolute',
    //     bottom: theme => theme.spacing(8),
    //     left: 0,
    //     right: 0,
    //     width: '100%',
    //     fontSize: '14px',
    //     padding: '10px 0px',
    //   }}
    // >
    //   {message}
    // </Box>
    <Alert
      severity="error"
      sx={{
        position: 'absolute',
        bottom: theme => theme.spacing(8),
        left: 0,
        right: 0,
        width: '100%',
        fontSize: '13px',
      }}
    >
      {message}
    </Alert>
  );
};

export default SliceErrorAlert;
