import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

function IframeDialog({
  open,
  title,
  handleClose,
  link,
}: {
  open: boolean;
  title?: string;
  link: string;
  handleClose: () => void;
}) {
  const theme = useTheme();
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogContent sx={{ p: 0, position: 'relative' }}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 4,
            right: 0,
            zIndex: 1,
            color: theme.palette.common.white,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          style={{
            width: '100%',
            height: '360px',
          }}
          src={link}
          title={title}
          // frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </DialogContent>
    </Dialog>
  );
}

export default IframeDialog;
