import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { Item } from 'app/design/speedBot/EntityItem';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { PAGES } from '../Common/Breadcrumb/types';
import { useBreadcrumbManager } from '../Common/Breadcrumb';

// Service
import useGetBrokers from 'services/Menu/useGetBrokers';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { formatDateString } from 'utils/GenericFunctions';
import { manipulateAddBrokerRef } from '../Bots/BotDetail/util';
import { PRODUCT_TOUR } from 'constants/common';
import ProductTour from '../Common/ProductTour/ProductTour';
import { BrokerAddSteps } from '../Common/ProductTour/ProductTourData';

const BrokerList = (): JSX.Element => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const { data, isLoading } = useGetBrokers();

  return (
    <>
      <CircularLoader open={isLoading} />
      <Box>
        <Box>
          {data &&
            data.length > 0 &&
            data.map((broker, i) => (
              <Fragment key={i}>
                <Item
                  variant="itemSubtextIcon"
                  title={broker.broker}
                  subtext={
                    broker.name
                      ? `${broker.name} - ${broker.username}`
                      : broker.username
                  }
                  {...(broker.is_auth && !broker.isVerified
                    ? {
                        bottomChip: (
                          <MuiChip
                            color="errorlight"
                            label={t('menu.login_required')}
                            size="small"
                            sx={{ height: '1rem' }}
                          />
                        ),
                      }
                    : broker.isVerified && {
                        bottomChip: (
                          <MuiChip
                            color="successlight"
                            label={`Last login: ${formatDateString(
                              broker.last_login,
                              'LT',
                            )}`}
                            size="small"
                            sx={{ height: '1rem' }}
                          />
                        ),
                      })}
                  icon={<ChevronRightIcon />}
                  handleClick={() =>
                    breadcrumb.push(
                      `/broker/${broker.id}`,
                      PAGES.BROKER,
                      `/broker`,
                      t('menu.page_title.brokers'),
                    )
                  }
                />
                {data.length - 1 !== i && (
                  <Divider className="m-main-spacing" sx={{ py: 0 }} />
                )}
              </Fragment>
            ))}
        </Box>

        <Box className="m-main-spacing add-broker-button" sx={{ mt: 1 }}>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            // component={RouterLink}
            // to="/broker/new"
            onClick={() => {
              manipulateAddBrokerRef('remove');
              breadcrumb.push(
                `/brokers/new`,
                PAGES.BROKER,
                `/broker`,
                t('menu.page_title.brokers'),
              );
            }}
          >
            <FormattedMessage id="buttons.add_new" />
          </MuiButton>
        </Box>
      </Box>
      {!JSON.parse((localStorage.getItem(PRODUCT_TOUR) as string) || '')[
        'Add Broker Page'
      ] && <ProductTour steps={BrokerAddSteps} tour_page="Add Broker Page" />}
    </>
  );
};

export default BrokerList;
