import { ADD_BROKER_REF } from 'constants/common';
import i18next from 'i18next';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';

export const getPaperTestTerms = () => {
  return [
    i18next.t('bot_paper_modal.term1'),
    i18next.t('bot_paper_modal.term2'),
    'Forward Testing will run as long as there is at least one live bot in your broker.',
  ];
};

export const getLiveTerms = (siteName: string) => {
  return [
    i18next.t('bot_deploy_modal.term1', { seteName: siteName }),
    i18next.t('bot_deploy_modal.term2'),
    i18next.t('bot_deploy_modal.term3'),
    i18next.t('bot_deploy_modal.term4', { seteName: siteName }),
    i18next.t('bot_deploy_modal.term5'),
  ];
};

export const manipulateAddBrokerRef = (
  action: 'set' | 'get' | 'remove',
  extraParams = '',
) => {
  let returnValue: undefined | string;
  switch (action) {
    case 'get':
      returnValue = getCookie(ADD_BROKER_REF);
      break;
    case 'set':
      setCookie(ADD_BROKER_REF, window.location.pathname + extraParams);
      break;
    case 'remove':
      removeCookie(ADD_BROKER_REF);
      break;
  }
  return returnValue;
};
