import { CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { Link as RouterLink } from 'react-router-dom';
import { openInNewTab } from 'utils/GenericFunctions';

type CarouselCardProps = {
  url: string;
  label?: string;
  description: string;
  title: string;
  image: string;
  routerLink?: boolean;
  color?: string;
};
const CarouselCard = ({
  url,
  label,
  description,
  title,
  image,
  routerLink,
  color,
}: CarouselCardProps) => {
  const isMdUp = useGetMediaQueryUp('md');

  return (
    <CardMedia
      image={image}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        backgroundSize: 'cover',
      }}
    >
      <Box
        className="p-main-spacing"
        sx={{
          py: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            variant={isMdUp ? 'h6' : 'body1'}
            sx={{ color: color ? color : 'black' }}
          >
            {title}
          </Typography>
          {isMdUp && (
            <Typography
              variant={isMdUp ? 'body1' : 'body3'}
              sx={{ color: color ? color : 'black' }}
            >
              {description}
            </Typography>
          )}
        </Box>
        <Box>
          {!routerLink ? (
            <MuiButton
              // sx={{
              //   color: color ? color : 'black',
              // }}
              color={!color?.includes('#fff') ? 'primary' : 'secondary'}
              variant={'outlined'}
              onClick={() => {
                openInNewTab(url);
              }}
            >
              {label}
            </MuiButton>
          ) : (
            <RouterLink style={{ width: '100%', height: '100%' }} to={url}>
              <MuiButton
                color={!color?.includes('#fff') ? 'primary' : 'secondary'}
                // sx={{ color: color ? color : 'black' }}
                variant={'outlined'}
              >
                {label}
              </MuiButton>
            </RouterLink>
          )}
        </Box>
      </Box>
    </CardMedia>
  );
};
export default CarouselCard;
