/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import moment from 'moment';
import { jStat } from 'jstat';
function round(value: number, decimals = 0) {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}
export class OptionGreeks {
  static SIGMA = 0.3;
  spot_price: number;
  option_price: number;
  strike_price: number;
  risk_free_ir: number;
  is_ce: boolean;
  max_iterations: number;
  tolerance: number;
  time_to_maturity: number;
  ttm_sqrt: number;
  rfi_ttm_exp: number;
  _iv: null | number;
  _d_1: null | number;
  _d_2: null | number;

  constructor(
    spot_price: number,
    option_price: number,
    strike_price: number,
    expiry: moment.MomentInput,
    candle_time: moment.MomentInput,
    risk_free_interest_rate = 0.070797,
    is_ce = false,
    max_iterations = 100,
    tolerance = 0.0001,
    iv = null,
  ) {
    this.spot_price = spot_price;
    this.option_price = option_price;
    this.strike_price = strike_price;
    this.risk_free_ir = risk_free_interest_rate;
    this.is_ce = is_ce;
    this.max_iterations = max_iterations;
    this.tolerance = tolerance;
    this.time_to_maturity =
      moment(expiry).hours(15).minutes(30).diff(moment(candle_time), 'days') /
      365;
    this.ttm_sqrt = Math.sqrt(this.time_to_maturity);
    this.rfi_ttm_exp = Math.exp(-this.risk_free_ir * this.time_to_maturity);
    this._iv = iv;
    this._d_1 = null;
    this._d_2 = null;
  }

  d(sigma: number) {
    const d1 =
      (Math.log(this.spot_price / this.strike_price) +
        (this.risk_free_ir + Math.pow(sigma, 2) / 2) * this.time_to_maturity) /
      (sigma * this.ttm_sqrt);
    const d2 = d1 - sigma * this.ttm_sqrt;
    return [d1, d2];
  }

  d_at_iv() {
    if (this._d_1 === null || this._d_2 === null) {
      [this._d_1, this._d_2] = this.d(this.iv);
    }
    return [this._d_1, this._d_2];
  }

  v(d1: number) {
    return this.spot_price * this.ttm_sqrt * jStat.normal.pdf(d1, 0, 1);
  }

  black_and_scholes(d1: number, d2: number) {
    if (this.is_ce) {
      return (
        this.spot_price * jStat.normal.cdf(d1, 0, 1) -
        jStat.normal.cdf(d2, 0, 1) * this.strike_price * this.rfi_ttm_exp
      );
    }
    return (
      this.strike_price * this.rfi_ttm_exp * jStat.normal.cdf(-d2, 0, 1) -
      this.spot_price * jStat.normal.cdf(-d1, 0, 1)
    );
  }

  get iv() {
    if (this._iv === null) {
      try {
        this._iv = this.newton_raphson();
      } catch (e) {
        this._iv = 0;
      }
    }
    return this._iv;
  }

  set iv(iv) {
    this._iv = iv;
  }

  newton_raphson() {
    let sigma = OptionGreeks.SIGMA;
    for (let i = 0; i < this.max_iterations; i++) {
      const [d1, d2] = this.d(sigma);
      const diff = this.black_and_scholes(d1, d2) - this.option_price;
      if (Math.abs(diff) < this.tolerance) break;
      const v = this.v(d1);
      if (v === 0) {
        sigma = 0;
        break;
      }
      sigma -= diff / v;
      if (isNaN(sigma)) {
        sigma = 0;
        break;
      }
    }
    return sigma;
  }

  // delta() {
  //   if (this._delta === null) {
  //     const [d_1] = this.d_at_iv();
  //     this._delta = this.is_ce
  //       ? (jStat.normal.cdf(d_1, 0, 1) as number)
  //       : jStat.normal.cdf(d_1, 0, 1) - 1;
  //   }
  //   return this._delta;
  // }

  // gamma() {
  //   if black_and_scholes(this._gamma === null) {
  //     const [d_1] = this.d_at_iv();
  //     this._gamma =
  //       jStat.normal.pdf(d_1, 0, 1) /
  //       (this.spot_price * this.iv * this.ttm_sqrt);
  //   }
  //   return this._gamma;
  // }

  // theta() {
  //   if (this._theta === null) {
  //     const [d_1, d_2] = this.d_at_iv();
  //     const first_term =
  //       (-this.spot_price * jStat.normal.pdf(d_1, 0, 1) * this.iv) /
  //       (2 * this.ttm_sqrt);
  //     const t = this.risk_free_ir * this.strike_price * this.rfi_ttm_exp;
  //     if (this.is_ce) {
  //       const second_term = t * jStat.normal.cdf(d_2, 0, 1);
  //       this._theta = (first_term - second_term) / 365.0;
  //     } else {
  //       const second_term = t * jStat.normal.cdf(-d_2, 0, 1);
  //       this._theta = (first_term + second_term) / 365.0;
  //     }
  //   }
  //   return this._theta;
  // }

  // vega() {
  //   if (this._vega === null) {
  //     const [d_1] = this.d_at_iv();
  //     this._vega =
  //       this.spot_price * jStat.normal.pdf(d_1, 0, 1) * this.ttm_sqrt * 0.01;
  //   }
  //   return this._vega;
  // }

  // rho() {
  //   if (this._rho === null) {
  //     const [, d_2] = this.d_at_iv();
  //     const t = this.strike_price * this.rfi_ttm_exp * this.time_to_maturity;
  //     this._rho = this.is_ce
  //       ? t * jStat.normal.cdf(d_2, 0, 1) * 0.01
  //       : -t * jStat.normal.cdf(-d_2, 0, 1) * 0.01;
  //   }
  //   return this._rho;
  // }

  greeks() {
    let iv = 0;
    // d = 0,
    // g = 0,
    // t = 0,
    // v = 0,
    // r = 0;
    try {
      const i = this.iv;
      if (i === 0) throw new Error();
      iv = i;
      // d = this.delta();
      // g = this.gamma();
      // t = this.theta();
      // v = this.vega();
      // r = this.rho();
    } catch (e) {
      //
    }
    return {
      // delta: round(d, 3),
      // gamma: round(g, 4),
      // theta: round(t, 3),
      // vega: round(v, 3),
      // rho: round(r, 3),
      iv: round(iv * 100, 3),
    };
  }
}

interface PositionParams {
  strike: string;
  contract_type: string;
  direction: string;
  premium: number;
  quantity: number;
  entry_time?: string;
  expiry?: string;
  candle_time?: string;
  risk_free_interest_rate?: number;
  iv?: number;
  spot_price_at_entry?: number;
  current_spot_price?: number;
  current_contract_price?: number;
}
export class Position {
  strike: string;
  premium: number;
  contract_type: string;
  direction: string;
  quantity: number;
  spot_price_at_entry: number | null;
  _iv: number | null;
  entry_time?: moment.Moment | null | string;
  expiry?: moment.Moment;
  candle_time?: moment.Moment;
  risk_free_ir: number;
  payoff_with_iv: number[] | undefined;
  current_spot_price: number;
  current_contract_price: number;
  constructor(params: PositionParams) {
    if (
      !params.strike ||
      !params.contract_type ||
      !params.direction ||
      !params.premium ||
      (params.contract_type !== 'ce' &&
        params.contract_type !== 'pe' &&
        params.contract_type !== 'fut') ||
      (params.direction !== 'long' && params.direction !== 'short')
    ) {
      throw new Error('Invalid Position');
    }
    this.strike = params.strike;
    this.premium = params.premium;
    this.contract_type = params.contract_type;
    this.direction = params.direction;
    this.quantity = params.quantity;
    this.spot_price_at_entry = params.spot_price_at_entry ?? 0;
    this._iv = params.iv ?? 0;
    this.entry_time =
      params.entry_time ?? ''
        ? moment(params.entry_time, 'YYYY-MM-DD HH:mm:ss')
        : null;
    this.expiry =
      params.expiry ?? ''
        ? moment(String(params.expiry) + ' 09:59:00', 'YYYY-MM-DD HH:mm:ss')
        : undefined;
    this.candle_time =
      params.candle_time ?? ''
        ? moment(params.candle_time, 'YYYY-MM-DD HH:mm:ss')
        : undefined;
    this.risk_free_ir = params.risk_free_interest_rate ?? 0.070797;
    this.current_spot_price = params.current_spot_price || 0;
    this.current_contract_price = params.current_contract_price || 0;
  }

  // get iv() {
  //   if (this._iv === null) {
  //     const o = new OptionGreeks(
  //       this.spot_price_at_entry as number,
  //       this.premium,
  //       this.strike,
  //       this.expiry,
  //       this.entry_time,
  //       this.risk_free_ir,
  //       this.is_call,
  //     );
  //     // eslint-disable-next-line no-console
  //     console.log(o.greeks());
  //     this._iv = o.iv;
  //   }
  //   return this._iv;
  // }

  get is_short() {
    return this.direction === 'short';
  }

  get is_call() {
    return this.contract_type === 'ce';
  }

  payoff(datapoints: any[]) {
    const payoff = datapoints.map((spot_price: number) => {
      if (this.contract_type === 'fut') {
        return (
          (spot_price - this.premium) * this.quantity * (this.is_short ? -1 : 1)
        );
      }
      const p = this.is_call
        ? Math.max(spot_price - Number(this.strike), 0) - this.premium
        : Math.max(Number(this.strike) - spot_price, 0) - this.premium;
      return p * this.quantity * (this.is_short ? -1 : 1);
    });
    return payoff;
  }

  N(x: number) {
    return jStat.normal.cdf(x, 0, 1);
  }

  real_time_payoff(datapoints: any[]) {
    if (this.contract_type === 'fut') {
      if (!(this.current_spot_price && this.current_contract_price)) {
        throw new Error(
          'Invalid Parameters for payoff calculation before expiry',
        );
      }
      const mtm = (this.premium - this.current_contract_price) * this.quantity;
      const payoff = datapoints.map(spot_price => {
        return (
          (mtm +
            (Math.floor(this.current_spot_price) - spot_price) *
              this.quantity) *
          (this.is_short ? 1 : -1)
        );
      });
      return payoff;
    } else {
      if (
        !(
          this.expiry &&
          this.candle_time &&
          this.entry_time &&
          this.spot_price_at_entry !== null
        )
      ) {
        throw new Error(
          'Invalid Parameters for payoff calculation before expiry',
        );
      }

      const ttm = this.expiry.diff(this.candle_time, 'days') / 365;
      const sqrt_ttm = Math.sqrt(ttm);
      const rfi_ttm_exp = Math.exp(-this.risk_free_ir * ttm);

      const d1 = datapoints.map(
        (spot_price: number) =>
          (Math.log(spot_price / Number(this.strike)) +
            (this.risk_free_ir + Math.pow(this._iv!, 2) / 2) * ttm) /
          (this._iv! * sqrt_ttm),
      );
      const d2 = d1.map((d: number) => d - this._iv! * sqrt_ttm);

      if (this.is_call) {
        return datapoints.map(
          (spot_price: number, idx: string | number) =>
            (spot_price * this.N(d1[idx] as number) -
              this.N(d2[idx] as number) * Number(this.strike) * rfi_ttm_exp -
              this.premium) *
            this.quantity *
            (this.is_short ? -1 : 1),
        );
      } else {
        return datapoints.map(
          (spot_price: number, idx: string | number) =>
            (Number(this.strike) * rfi_ttm_exp * this.N(-d2[idx]) -
              spot_price * this.N(-d1[idx]) -
              this.premium) *
            this.quantity *
            (this.is_short ? -1 : 1),
        );
      }
    }
  }
}
// class OptionStrategy {
//   constructor(df, strikes, strikeStep, ocAtStrike, logger) {
//     this.df:Otioncahin = df;
//     this.strikes = strikes;
//     this.strikeStep = strikeStep;
//     this.ocAtStrike = ocAtStrike;
//     this._pop = null;
//     this.logger = logger;
//   }

//   get pop() {
//     if (this._pop === null) {
//       try {
//         const breakevens = this.breakevens;
//         if (!breakevens) {
//           throw new Error('No breakevens found for POP calculations');
//         }

//         const maxIdx = this.df.length - 1;
//         const pops = breakevens.map((breakeven, idx) => {
//           const _df = this.df.slice(
//             Math.max(idx - 5, 0),
//             Math.min(idx + 5, maxIdx),
//           );
//           return {
//             breakeven,
//             type: _df.every(row => row.payoff >= 0) ? 'ce' : 'pe',
//           };
//         });

//         const deltas = pops.map(pop => this.calculateBreakevenDelta(pop));

//         let pop;
//         if (pops.length === 2) {
//           const _delta = deltas[1] + deltas[0];
//           pop =
//             pops[0].type === 'ce' && pops[1].type === 'pe'
//               ? _delta - 1
//               : _delta;
//         } else {
//           pop = deltas[0];
//         }

//         this._pop = Math.round(pop * 100 * 100) / 100; // rounding to 2 decimal places
//       } catch (e) {
//         this.logger.error(`Error while calculating POP: ${e.message}`);
//         this._pop = 0;
//       }
//     }

//     return this._pop;
//   }

//   calculateBreakevenDelta(breakeven) {
//     const strikeStep = this.strikeStep;
//     const breakevenPoint = breakeven.breakeven;
//     const type = breakeven.type;

//     if (
//       this.strikes[this.strikes.length - 1] - strikeStep < breakevenPoint &&
//       breakevenPoint < this.strikes[0] + strikeStep
//     ) {
//       throw new Error(
//         `Breakeven point is not in bound of option chain ${breakevenPoint}`,
//       );
//     }

//     const [lStrike, uStrike] = this.getNearestStrikes(breakevenPoint);

//     if (lStrike !== null && uStrike !== null) {
//       const direction = type === 'ce' ? 'CE' : 'PE';

//       const lDelta = this.ocAtStrike(lStrike)?.[direction]?.delta || 0;
//       const uDelta = this.ocAtStrike(uStrike)?.[direction]?.delta || 0;

//       let d = Math.abs(lDelta - uDelta) / strikeStep;
//       if (lDelta) {
//         d = d * (lStrike - breakevenPoint) + lDelta;
//       } else {
//         d = d * (breakevenPoint - uStrike) + uDelta;
//       }

//       const delta = Math.abs(d);
//       if (0 < delta && delta < 1) {
//         return delta;
//       }
//     }

//     throw new Error(
//       `Illiquid breakeven found: [breakeven: ${breakeven}, nearest_strikes: [${lStrike}, ${uStrike}]]`,
//     );
//   }

// getNearestStrikes(breakeven) {
//   const pos = this.strikes.findIndex(strike => strike >= breakeven);
//   const lowerStrike = pos > 0 ? this.strikes[pos - 1] : null;
//   const upperStrike = pos < this.strikes.length ? this.strikes[pos] : null;
//   return [lowerStrike, upperStrike];
// }
// }
