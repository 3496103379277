import { Fragment, useCallback, useState } from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import { BotSignalParams } from 'types/ApiServicesTypes';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import BotSignalsModalTable from './BotSignalsModalTable';
import { SignalModalItem } from 'app/design/speedBot/EntityItem';
import { ContentCopy } from '@mui/icons-material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const SignalModalListLayot = ({
  signalData,
  handleReplicateSelectSignal,
  handleEditSelectSignal,
  handleDeleteSelectSignal,
}: {
  signalData: BotSignalParams[];
  handleReplicateSelectSignal: (val: BotSignalParams) => void;
  handleEditSelectSignal: (val: BotSignalParams, i: number) => void;
  handleDeleteSelectSignal: (val: number) => void;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const [selecedSignal, setSelecedSignal] = useState<null | BotSignalParams>(
    null,
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const open = Boolean(showMenu);

  const handleMenuClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      signal: BotSignalParams,
      index: number,
    ) => {
      setShowMenu(event.currentTarget);
      setSelecedSignal(signal);
      setSelectedIndex(index);
    },
    [],
  );

  const handleMenuClose = useCallback(() => {
    setSelecedSignal(null);
    setSelectedIndex(-1);
    setShowMenu(null);
  }, []);

  const handleClickReplicate = () => {
    if (!selecedSignal) return;
    handleReplicateSelectSignal(selecedSignal);
    handleMenuClose();
  };

  const handleClickEdit = () => {
    if (!selecedSignal) return;
    handleEditSelectSignal(selecedSignal, selectedIndex);
    handleMenuClose();
  };

  const handleClickDelete = () => {
    handleDeleteSelectSignal(selectedIndex);
    handleMenuClose();
  };

  return (
    <>
      <Box sx={{ py: 1, flex: 1 }}>
        {isMdUp ? (
          <BotSignalsModalTable
            signalData={signalData}
            handleMenuClick={handleMenuClick}
          />
        ) : (
          signalData &&
          signalData.map((signal: BotSignalParams, i: number) => (
            <Fragment key={i}>
              <SignalModalItem
                signalData={signal}
                isNoPadding
                handleMenuClick={handleMenuClick}
                index={i}
              />
            </Fragment>
          ))
        )}

        <Paper sx={{ maxWidth: '100%' }}>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={showMenu}
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClickReplicate} key="buttons.replicate">
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="buttons.duplicate" />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClickEdit} key="buttons.edit">
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="buttons.edit" />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClickDelete} key="buttons.remove">
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="buttons.remove" />
              </ListItemText>
            </MenuItem>
          </Menu>
        </Paper>
      </Box>
    </>
  );
};

export default SignalModalListLayot;
