import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { WalletPaymentStatus } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import {
  WalletStatusParams,
  WalletStatusResult,
} from 'types/SubscriptionPlans';

export default function useWalletPaymentStatus() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    WalletStatusResult,
    Errors,
    Pick<WalletStatusParams, 'razorpay_order_id' | 'payment_id'>
  >('WalletPaymentStatus', values =>
    WalletPaymentStatus(values.payment_id, values.razorpay_order_id, 'razorpay')
      .then(res => {
        if (res.error) {
          showAlert!(res.error);
        } else if (!res || !res.success)
          showAlert!('Something went wrong. Please try again.');
        else showAlert!('Payment successful', 'success');
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
