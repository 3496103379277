import { useQuery } from 'react-query';
import { GetPortfolioBotsV2 } from 'services/apiServices';
import {
  Errors,
  GetPortfolioBotResult,
  PortfolioBotsData,
  Position,
} from 'types/ApiServicesTypes';
import {
  AllBotOpenPositions,
  getAllBotOpenPositions,
  getUniqueSymbols,
} from './useGetPortfolioActivePaper';

export default function useGetPortfolioBotsV2(ptype: string) {
  const objQuery = useQuery<PortfolioBotsData, Errors>(
    ['GetPortfolioBotV2', ptype],
    async () => {
      const resultV2 = await GetPortfolioBotsV2(ptype);
      const transformedResult: GetPortfolioBotResult = {
        todays_change: resultV2.todays_change,
        total_profit_loss: resultV2.total_profit_loss,
        total_profit_loss_per: resultV2.total_profit_loss_per,
        initial_capital: resultV2.initial_capital,
        portfolio_value: resultV2.portfolio_value,
        bots: {
          manual: resultV2.bots,
        },
      };
      let arrPos: Position[] = [];
      let symbols: Array<string> = [];
      let arrPositions: Array<Position> | Array<AllBotOpenPositions> = [];
      const objPos = getAllBotOpenPositions(resultV2.bots);
      arrPos = objPos.arrOpenPos;
      arrPositions = objPos.arrOpenPositionsData;

      // unique symbols
      symbols = getUniqueSymbols(arrPos);
      const tranform: PortfolioBotsData = {
        bots: resultV2.bots,
        data: transformedResult,
        positionData: arrPositions,
        symbols,
      };
      return tranform;
    },
  );

  return objQuery;
}
