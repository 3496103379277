import { useQuery } from 'react-query';
import { GetPlans } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import { SubscriptionPlan } from 'types/SubscriptionPlans';

export default function useGetAllPlans(couponCode = '') {
  const queryKey = ['GetAllPlans', couponCode];

  const objQuery = useQuery<SubscriptionPlan[], Errors>(queryKey, () =>
    GetPlans(couponCode).then(res => res),
  );

  return objQuery;
}
