import { useQuery } from 'react-query';
import { GetUserBot } from 'services/apiServices';
import { Errors, GetUserBotResult } from 'types/ApiServicesTypes';

export default function useGetUserBot(id?: number, versionId?: string) {
  const objQuery = useQuery<GetUserBotResult, Errors>(
    ['GetUserBot', id, versionId],
    async () => {
      if (id) return await GetUserBot(id, versionId);
      else return {} as GetUserBotResult;
    },
  );
  return objQuery;
}
