import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import SellOnMarketForm from 'app/components/Bots/SellOnMarket/SellOnMarketForm';

export function SellOnMarketAddPage() {
  const { id, title } = useParams<{ id: string; title: string }>();

  return (
    <>
      <Helmet>
        <title>Sell On Discover</title>
        <meta name="description" content="Sell On Discover Page" />
      </Helmet>
      <CircularLoader open={false} />

      <Box>
        <PageHeader
          variant="common"
          title={
            <FormattedMessage id="sell_on_market.page_title.sell_on_market" />
          }
        />
      </Box>
      <Box className="m-main-spacing">
        <Typography variant="h6" sx={{ mb: 3 }}>
          <FormattedMessage id="sell_on_market.lets_get_you_on_market" />
        </Typography>
      </Box>
      <SellOnMarketForm mode="add" id={Number(id)} botName={title} />
    </>
  );
}
