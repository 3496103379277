import { useQuery } from 'react-query';
import { GetHomePageData } from 'services/apiServices';
import { Errors, GetHomePageDataResult } from 'types/ApiServicesTypes';

export default function useGetHomePageData() {
  const objQuery = useQuery<GetHomePageDataResult, Errors>(
    'GetHomePageData',
    () => GetHomePageData(),
  );
  return objQuery;
}
