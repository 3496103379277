import { ItemHeadVariantProps } from '../types';
import {
  LargeVariant,
  LargeWithIconVariant,
  MediumVariant,
  MediumWithIconVariant,
  SmallVariant,
} from './ItemHeadVariants';

const ItemHead = <V extends ItemHeadVariantProps>(props: V) => {
  const { variant } = props;
  switch (variant) {
    case 'large':
      return (
        <LargeVariant
          title={props.title}
          color={props.color || 'text.primary'}
        />
      );
    case 'largeWithIcon':
      return <LargeWithIconVariant {...props} />;
    case 'medium':
      return (
        <MediumVariant
          title={props.title}
          subtitle={props.subtitle}
          subtitleColor={props.subtitleColor}
        />
      );
    case 'mediumWithIcon':
      return <MediumWithIconVariant title={props.title} icon={props.icon} />;
    case 'small':
      return (
        <SmallVariant
          title={props.title}
          color={props.color || 'text.primary'}
        />
      );
    default:
      return <></>;
  }
};

export default ItemHead;
