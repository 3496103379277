import { useMemo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import moment, { Duration } from 'moment';
import useGetUserBot from 'services/Bots/useGetUserBot';
import useGetBotPositions from 'services/Bots/useGetBotPositions';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { transformSaveUserBotResponse } from '../../../components/Bots/StrategyBuilder/util';
import { StrategyBuilderFormData } from '../../../components/Bots/StrategyBuilder/types';
import { FieldData } from 'app/components/Builder/Strategy/fields/types';
import { TVChartContainer } from './TVChartContainer';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import { groupBy, getFormattedDate } from 'utils/GenericFunctions';
import { Position } from 'types/ApiServicesTypes';
import PositionsListLayout from 'app/design/layouts/PositionsListLayout';
import { cloneDeep } from 'lodash';
import {
  ChartOrders,
  DyanmicObject,
  TableDataType,
} from './TVChartContainer/types';
import PageHeader from 'app/design/speedBot/PageHeader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { NIFTY_SYMBOL } from 'constants/common';

// can not find aroon oscilator
// vwap is not working
const indicatorsMapping: DyanmicObject = {
  moving_average: 'Moving Average',
  moving_average_simple: 'Moving Average',
  rsi: 'Relative Strength Index',
  macd: 'MACD',
  moving_average_exponential: 'Moving Average Exponential',
  moving_average_weighted: 'Moving Average Weighted',
  ema: 'Moving Average Exponential',
  aroon_down: 'Aroon',
  adx: 'Average Directional Index',
  bollinger_bands: 'Bollinger Bands',
  super_trend: 'SuperTrend',
  ichimoku_cloud: 'Ichimoku Cloud',
  atr: 'Average True Range',
  dema: 'Double EMA',
  alma: 'Arnaud Legoux Moving Average',
  hma: 'Hull Moving Average',
  lsma: 'Least Squares Moving Average',
  tema: 'Triple EMA',
  mom: 'Momentum',
  roc: 'Rate Of Change',
  balance_of_power: 'Balance of Power',
  mfi: 'Money Flow Index',
  ad: 'Accumulation/Distribution',
  coppock_curve: 'Coppock Curve',
  cci: 'Commodity Channel Index',
  chandemo: 'Chande Momentum Oscillator',
  dc: 'Donchian Channels',
  dpo: 'Detrended Price Oscillator',
  kc: 'Keltner Channels',
  obv: 'On Balance Volume',
  ppo: 'Price Oscillator',
  sar: 'Parabolic SAR',
  stoch: 'Stochastic',
  trix: 'TRIX',
  uo: 'Ultimate Oscillator',
  vwap: 'VWAP',
  '%r': 'Williams %R',
  pivot_point: 'Pivot Points Standard',
};

function Direction(state: string) {
  if (state === '0') {
    return 'Long';
  }
  if (state === '1') {
    return 'Short';
  }
  return '';
}

export function changeDateFormat(input: string, time = '') {
  if (time === 'time') {
    return moment
      .utc(input, 'YYYYMMDD hh:mm')
      .local()
      .format('DD/MM/YYYY HH:mm');
  } else {
    return moment(getFormattedDate(input)).format('DD/MM/YYYY');
  }
}

export function UserBotTradingviewPage() {
  const history = useHistory();
  const { bid, id, sid, title } =
    useParams<{ bid: string; id: string; sid: string; title: string }>();
  const botId = parseInt(id);
  const baseApiUrl = `bots/${bid}/backtest`;

  const { data: userBotDetails } = useGetUserBot(botId);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isOrdersLoaded, setIsOrdersLoaded] = useState<boolean>(false);
  const [highlightedOrder, setHighlightedOrder] = useState<ChartOrders | null>(
    null,
  );
  const userBotTransforDetails: StrategyBuilderFormData | null = useMemo(() => {
    if (botId) {
      return transformSaveUserBotResponse(userBotDetails);
    }
    return null;
  }, [botId, userBotDetails]);

  const [page, setPage] = useState<number>(1);
  const [show, setShow] = useState<boolean>(false);
  const [Fromdate, setFromdate] = useState('2021-08-05 09:15:00');
  const [Todate, setTodate] = useState('2021-08-05 15:15:00');
  const [symbol, setsymbol] = useState('NSE:NIFTY 50');
  const [interval, setInterval] = useState('15');
  const [intervalInSeconds, setIntervalInSeconds] = useState(15 * 60);
  const [indicators, setIndicators] = useState<DyanmicObject[]>([]);
  const [chartOrders, setChartOrders] = useState<ChartOrders[]>([]);

  const {
    data: botPositions,
    isLoading,
    refetch,
    isSuccess,
  } = useGetBotPositions(botId, page, baseApiUrl);

  const botGrpPositions: PositionsListLayoutProps = useMemo(() => {
    if (isSuccess && botPositions) {
      const data = groupBy(
        botPositions,
        'exit_time',
        true,
      ) as PositionsListLayoutProps;
      return data;
    } else return [] as PositionsListLayoutProps;
  }, [isSuccess, botPositions]);

  const totalPages =
    botPositions && botPositions.length > 0
      ? Number(botPositions[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setIsOrdersLoaded(false);
    setPage(value);
  };

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const createIndicators = (
    conditions: FieldData[],
    uniqueIndicators: string[],
  ) => {
    const retData: DyanmicObject[] = [];
    const offsetSupportedIndicators = ['moving_average', 'ema', 'alma', 'lsma'];
    if (conditions) {
      conditions.map((condition: FieldData) => {
        const indicator: DyanmicObject = {};
        if (
          condition.type === 'indicators' &&
          indicatorsMapping[condition.key]
        ) {
          const newParams = cloneDeep(condition.params);
          newParams['indicator'] = condition.key;
          //convert every param into string to check for uniq param
          const keys = Object.keys(newParams);
          keys.forEach(key => {
            newParams[key] = newParams[key].toString();
          });
          if (offsetSupportedIndicators.indexOf(condition.key) === -1) {
            delete newParams.offset;
          }
          const strParams = JSON.stringify(newParams);
          if (uniqueIndicators.indexOf(strParams) === -1) {
            indicator['name'] = indicatorsMapping[condition.key];
            switch (condition.key) {
              case 'moving_average':
                indicator['name'] =
                  indicatorsMapping[
                    `${condition.key}_${condition.params.type}`
                  ];
                indicator['options'] = [
                  condition.params.period,
                  condition.params.field,
                  condition.params.offset,
                ];
                break;
              case 'ema':
                indicator['options'] = [
                  condition.params.period,
                  condition.params.field,
                  condition.params.offset,
                ];
                break;
              case 'rsi':
                indicator['options'] = [condition.params.period];
                break;
              case 'macd':
                indicator['options'] = [
                  condition.params.fastperiod,
                  condition.params.slowperiod,
                  condition.params.field,
                  parseInt(condition.params.signalperiod as string),
                ];
                break;
              case 'aroon_down':
                indicator['options'] = [condition.params.period];
                break;
              case 'adx':
                indicator['options'] = [
                  condition.params.Smoothing,
                  condition.params.di_length,
                ];
                break;
              case 'bollinger_bands':
                indicator['options'] = [
                  condition.params.period,
                  condition.params.std_dev,
                ];
                if (condition.params.line === 'upper band') {
                  indicator.overrides = {
                    'lower.transparency': 80,
                    'median.transparency': 80,
                    'upper.transparency': 0,
                  };
                } else if (condition.params.line === 'lower band') {
                  indicator.overrides = {
                    'upper.transparency': 80,
                    'median.transparency': 80,
                    'lower.transparency': 0,
                  };
                } else if (condition.params.line === 'middle band') {
                  indicator.overrides = {
                    'upper.transparency': 80,
                    'lower.transparency': 80,
                    'median.transparency': 0,
                  };
                }
                break;
              case 'super_trend':
                indicator['options'] = [
                  condition.params.atr_period,
                  condition.params.factor,
                ];
                break;
              case 'ichimoku_cloud':
                indicator['options'] = [
                  condition.params.conversion_line_period,
                  condition.params.base_line_period,
                  condition.params.leadingspan_b_period,
                  condition.params.displacement,
                ];
                break;
              case 'atr':
                indicator['options'] = [condition.params.period];
                break;
              case 'dema':
                indicator['options'] = [condition.params.period];
                break;
              case 'alma':
                indicator['options'] = [
                  condition.params.period,
                  condition.params.offset,
                  condition.params.sigma,
                ];
                break;
              case 'hma':
                indicator['options'] = [condition.params.period];
                break;
              case 'lsma':
                indicator['options'] = [
                  condition.params.period,
                  condition.params.offset,
                ];
                break;
              case 'tema':
                indicator['options'] = [condition.params.period];
                break;
              case 'mom':
                indicator['options'] = [
                  condition.params.period,
                  condition.params.field,
                ];
                break;
              case 'roc':
                indicator['options'] = [condition.params.period];
                break;
              case 'balance_of_power':
                indicator['options'] = [];
                break;
              case 'mfi':
                indicator['options'] = [condition.params.period];
                break;
              case 'ad':
                indicator['options'] = [];
                break;
              case 'coppock_curve':
                indicator['options'] = [
                  condition.params.wmaperiod,
                  condition.params.longrocperiod,
                  condition.params.shortrocperiod,
                ];
                break;
              case 'cci':
                indicator['options'] = [condition.params.period];
                break;
              case 'chandemo':
                indicator['options'] = [condition.params.period];
                break;
              case 'dc':
                indicator['options'] = [condition.params.period];
                break;
              case 'dpo':
                indicator['options'] = [condition.params.period];
                break;
              case 'kc':
                indicator['options'] = [
                  0,
                  condition.params.period,
                  condition.params.no_of_multiples,
                ];
                break;
              case 'obv':
                indicator['options'] = [];
                break;
              case 'ppo':
                indicator['options'] = [
                  condition.params.shortperiod,
                  condition.params.longperiod,
                ];
                break;
              case 'sar':
                indicator['options'] = [
                  condition.params.start,
                  condition.params.increment,
                  condition.params.max,
                ];
                break;
              case 'stoch':
                indicator['options'] = [
                  condition.params.period,
                  condition.params.kperiod,
                  condition.params.dperiod,
                ];
                break;
              case 'trix':
                indicator['options'] = [condition.params.period];
                break;
              case 'uo':
                indicator['options'] = [
                  condition.params.period1,
                  condition.params.period2,
                  condition.params.period3,
                ];
                break;
              case 'vwap':
                indicator['options'] = [condition.params.period];
                break;
              case '%r':
                indicator['options'] = [condition.params.period];
                break;
              case 'pivot_point':
                indicator['options'] = [
                  condition.params.type,
                  condition.params.line,
                ];
                break;
            }
            uniqueIndicators.push(JSON.stringify(newParams));
            retData.push(indicator);
          }
        }
      });
    }
    return retData;
  };

  const parseInterval = (resolution: string | undefined) => {
    if (resolution) {
      const resolutionObject: Duration = moment.duration(resolution);
      const minute = resolutionObject.minutes();
      const hour = resolutionObject.hours();
      const day = resolutionObject.days();
      let time: string;
      if (hour >= 1 && hour <= 4) {
        time = (parseInt(hour.toString()) * 60).toString();
      } else if (day >= 1 && day < 7) {
        time = 'D';
      } else if (day >= 7 && day < 29) {
        time = 'W';
      } else if (day >= 29) {
        time = 'M';
      } else {
        time = minute.toString();
      }
      return time;
    }
    return '';
  };

  if (userBotTransforDetails && !isDataLoaded) {
    if (userBotTransforDetails.entryExitData) {
      const {
        longEntryConditions,
        longExitConditions,
        shortEntryConditions,
        shortExitConditions,
      } = userBotTransforDetails.entryExitData;
      const uniqueIndicators: string[] = [];
      const shortEntries = shortEntryConditions
        ? createIndicators(shortEntryConditions.conditions, uniqueIndicators)
        : [];
      const shortExit = shortExitConditions
        ? createIndicators(shortExitConditions.conditions, uniqueIndicators)
        : [];
      const longEntries = longEntryConditions
        ? createIndicators(longEntryConditions.conditions, uniqueIndicators)
        : [];
      const longExits = longExitConditions
        ? createIndicators(longExitConditions.conditions, uniqueIndicators)
        : [];
      setIndicators([
        ...shortEntries,
        ...shortExit,
        ...longEntries,
        ...longExits,
      ]);
      setShow(false);
      setInterval(parseInterval(userBotTransforDetails.symbols?.interval));
      setShow(true);
      const resolution = moment.duration(
        userBotTransforDetails.symbols?.interval,
      );
      setIntervalInSeconds(resolution.asSeconds());
    }
    setIsDataLoaded(true);
  }

  const _setSymbolData = (e: TableDataType) => {
    setShow(false);
    if (e.instrument === NIFTY_SYMBOL || e.instrument === 'NIFTY50') {
      setsymbol('NSE:NIFTY 50');
    } else {
      setsymbol('NSE:' + e.instrument);
    }
    setTimeout(() => {
      setShow(true);
    }, 100);
  };

  const _parseChartOrders = (
    data: TableDataType[],
    limit: number,
    symbol: string,
  ) => {
    let count = 0;
    const retData: ChartOrders[] = [];
    let maxDate = 0;
    let minDate = 0;
    data.forEach((order: TableDataType) => {
      const fromDate = moment(order.entry_time, 'DD/MM/YYYY HH:mm').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      const toDate = moment(order.exit_time, 'DD/MM/YYYY HH:mm').format(
        'YYYY-MM-DD HH:mm:ss',
      );

      retData.push({
        entryType: order.direction === 'Long' ? 'Long' : 'Short',
        fromDate,
        toDate,
        entryPrice: order.entry_price,
        exitPrice: order.exit_price,
        profit_loss: order['profit_loss'],
        symbol: order['instrument'],
      });
      if (count < limit && order.instrument === symbol) {
        let tempDate = new Date(toDate).getTime();
        !maxDate && (maxDate = tempDate);
        !minDate && (minDate = tempDate);
        if (tempDate > maxDate) {
          maxDate = tempDate;
        }
        tempDate = new Date(fromDate).getTime();
        if (tempDate < minDate) {
          minDate = tempDate;
        }
        count++;
      }
    });
    return {
      retData,
      maxDate: moment(new Date(maxDate)).format('YYYY-MM-DD HH:mm:ss'),
      minDate: moment(new Date(minDate)).format('YYYY-MM-DD HH:mm:ss'),
    };
  };

  if (!isOrdersLoaded && botGrpPositions && botGrpPositions.length > 0) {
    const data: Array<TableDataType> = [];
    botGrpPositions.forEach(item => {
      item.data.forEach(val => {
        const tmp: TableDataType = {
          direction: Direction(val['direction']),
          instrument: val['trading_symbol'],
          entry_time: changeDateFormat(val['entry_time'], 'time'),
          entry_price: parseFloat(val['entry_Price'].toString()).toFixed(2),
          quantity: val['quantity'],
          exit_time: changeDateFormat(val['exit_time'].toString(), 'time'),
          exit_price: parseFloat(val['exit_price'].toString()).toFixed(2),
          profit_loss: parseFloat(val['profit_loss'].toString()).toFixed(2),
          totalfees: val['totalfees'].toFixed(2),
        };
        data.push(tmp);
      });
    });
    if (data.length > 0) {
      _setSymbolData(data[0]);
      const { maxDate, minDate, retData } = _parseChartOrders(
        data,
        4,
        data[0].instrument,
      );
      setFromdate(minDate);
      setTodate(maxDate);
      setChartOrders(retData);
      setIsOrdersLoaded(true);
    }
  }

  const onClickRow = (data: Position) => {
    // setShow(false);
    const tmp: TableDataType = {
      direction: Direction(data['direction']),
      instrument: data['trading_symbol'],
      entry_time: changeDateFormat(data['entry_time'], 'time'),
      entry_price: parseFloat(data['entry_Price'].toString()).toFixed(2),
      quantity: data['quantity'],
      exit_time: changeDateFormat(data['exit_time'].toString(), 'time'),
      exit_price: parseFloat(data['exit_price'].toString()).toFixed(2),
      profit_loss: parseFloat(data['profit_loss'].toString()).toFixed(2),
      totalfees: data['totalfees'].toFixed(2),
    };
    const { retData } = _parseChartOrders([tmp], 4, tmp.instrument);
    // setFromdate(minDate);
    // setTodate(maxDate);
    const fromDate = moment(data.entry_time, 'YYYY/MM/DD HH:mm').format(
      'YYYY-MM-DD hh:mm:ss',
    );
    const toDate = moment(data.exit_time, 'YYYY/MM/DD HH:mm').format(
      'YYYY-MM-DD hh:mm:ss',
    );
    if (tmp.instrument === NIFTY_SYMBOL || tmp.instrument === 'NIFTY50') {
      setsymbol('NSE:NIFTY 50');
    } else {
      setsymbol('NSE:' + tmp.instrument);
    }
    setFromdate(fromDate);
    setTodate(toDate);
    setHighlightedOrder(retData[0]);
    // setShow(true);
  };

  // const { pageUrl, botDetailUrl, backtestUrl, moreInfoUrl } =
  //   useGetBreadcrumbUrls({
  //     page: breadcrumbPage,
  //     ptype: ptype,
  //     botId: botId.toString(),
  //     subId: subId,
  //     id: userBotDetails?.strategy_id?.toString(),
  //     title: userBotDetails?.name,
  //   });
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="back"
        buttonText={userBotDetails ? userBotDetails.name : ''}
        buttonHandleClick={() => history.goBack()}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_TREE_VIEW}
            data={{
              sid,
              botId,
              botName: title,
            }}
          />
        }
      />
      <Box sx={{ mb: 3 }}>
        {show ? (
          <TVChartContainer
            fromdate={Fromdate}
            todate={Todate}
            symbol={symbol}
            interval={interval}
            indicators={indicators}
            chartOrders={chartOrders}
            intervalInSeconds={intervalInSeconds}
            showDrawingToolbar={isMdUp}
            highlightedOrder={highlightedOrder}
          />
        ) : null}
      </Box>
      <PositionsListLayout
        data={botGrpPositions}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleChartRowClick={(position: Position) => {
          onClickRow(position);
        }}
        handleClick={(position: Position) => {
          onClickRow(position);
        }}
      />
    </>
  );
}
