import { Box } from '@mui/material';
import { PageTitleBackVariant, PageTitleWithChipWithSubtitleVariant } from '.';
import { PageTitleWithChipWithSubtitleWithBackVariantProps } from '../types';

const PageTitleWithChipWithSubtitleWithBackVariant = ({
  title,
  subtitle,
  chipProps,
  buttonText,
  buttonHandleClick,
  hideBackButton,
}: PageTitleWithChipWithSubtitleWithBackVariantProps) => {
  return (
    <Box>
      {!hideBackButton && (
        <PageTitleBackVariant
          buttonText={buttonText}
          buttonHandleClick={buttonHandleClick}
        />
      )}
      <PageTitleWithChipWithSubtitleVariant
        title={title}
        subtitle={subtitle}
        chipProps={chipProps}
      />
    </Box>
  );
};

export default PageTitleWithChipWithSubtitleWithBackVariant;
