import CircularLoader from 'app/design/uiComponents/CircularLoader';
import InfoListTradeStatsProfitLoss from 'app/design/speedBot/ComponentItem/Bots/InfoListTradeStatsProfitLoss';
import { ItemHead, ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

// Service
import InfoListTradeStats from 'app/design/speedBot/ComponentItem/Bots/InfoListTradeStats';
import InfoGridTradeStats from 'app/design/speedBot/ComponentItem/Bots/InfoGridTradeStats';
import useGetStatistics from 'services/Statistics/useGetStatistics';

export function InfoTradeStats({ baseApiUrl }: { baseApiUrl: string }) {
  const { data: statisticsData, isLoading } = useGetStatistics(baseApiUrl);

  const isMdUp = useGetMediaQueryUp('md');

  return (
    <>
      <CircularLoader open={isLoading} />
      {statisticsData && statisticsData.trade_statistics && (
        <>
          {isMdUp ? (
            <InfoGridTradeStats
              currencySymbol={statisticsData.currency_symbol}
              data={statisticsData.trade_statistics}
              baseApiUrl={baseApiUrl}
            />
          ) : (
            <>
              <InfoListTradeStats
                currencySymbol={statisticsData.currency_symbol}
                data={statisticsData.trade_statistics}
              />
              <ItemHead
                variant="medium"
                title={
                  <>
                    <FormattedMessage id="summary_labels.win" /> /{' '}
                    <FormattedMessage id="summary_labels.loss" />
                  </>
                }
              />
              <ItemSeparator variant="2px" mx={3} />
              <InfoListTradeStatsProfitLoss
                currencySymbol={statisticsData.currency_symbol}
                data={statisticsData.trade_statistics}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default InfoTradeStats;
