import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { PostBotAction } from 'services/apiServices';
import {
  PostBotActionResult,
  Errors,
  PostBotActionParams,
} from 'types/ApiServicesTypes';

export default function usePostBotAction(id: number, openFrom: string) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostBotActionResult,
    Errors,
    Pick<PostBotActionParams, 'type' | 'multiplier' | 'action'>
  >(['PostBotAction', id], values =>
    PostBotAction(
      openFrom === 'discover' ? 'marketplace/bots' : 'bots',
      id,
      values,
    )
      .then(res => {
        if (res.error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          showAlert!(res.error);
        }
        if (values.action === 'add') {
          showAlert!('More lots added successfully', 'success');
        }
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
