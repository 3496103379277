import {
  PAGES,
  PortfolioDetailPositionsBreadcrumbData,
  PortfolioDetailsBreadcrumbData,
  PortfolioDetailStatisticPositionsBreadcrumbData,
} from '../types';

export const portfolioDetails = (data: PortfolioDetailsBreadcrumbData) => {
  return [
    {
      page: PAGES.PORTFOLIO,
      link: `/portfolio?ptype=${data.ptype}`,
      label: 'Portfolio',
    },
  ];
};

export const portfolioDetailOrderPositions = (
  data: PortfolioDetailPositionsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.PORTFOLIO,
      link: `/portfolio?ptype=${data.ptype}`,
      label: 'Portfolio',
    },
    {
      page: PAGES.PORTFOLIO_DETAIL,
      link: `/portfolio/${data.ptype}/${data.subId}`,
      label: data.botName,
    },
  ];
};

export const portfolioDetailStatisticOrderPositions = (
  data: PortfolioDetailStatisticPositionsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.PORTFOLIO,
      link: `/portfolio?ptype=${data.ptype}`,
      label: 'Portfolio',
    },
    {
      page: PAGES.PORTFOLIO_DETAIL,
      link: `/portfolio/${data.ptype}/${data.subId}`,
      label: data.botName,
    },
    {
      page: PAGES.PORTFOLIO_DETAIL_STATISTIC,
      link: `/portfolio/${data.ptype}/${data.subId}/statistics/${data.botName}`,
      label: 'Statistics',
    },
  ];
};
