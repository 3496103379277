import { Box, Slider, Typography } from '@mui/material';
import TimeLineButtonStack from './TImeLineButtonStack';
import moment from 'moment';

const TimeLineProgress = ({
  date,
  setDate,
}: {
  date: string;
  setDate: (predate: string) => void;
}) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      border={'1px solid rgb(0 0 0 / 23%)'}
      borderRadius={'0.4em'}
      ml={1}
    >
      <TimeLineButtonStack date={date} setDate={setDate} />
      <Box display={'flex'} mx={1} alignItems={'center'}>
        <Typography variant="body2" flexShrink={0}>
          9:16
        </Typography>
        <Slider
          sx={{
            flexGrow: 1,
            mx: 2,
            py: 1,
          }}
          size="small"
          valueLabelDisplay="on"
          valueLabelFormat={moment(date).format('HH:mm')}
          min={556}
          value={moment(date).hours() * 60 + moment(date).minutes()}
          max={930}
          onChange={(_, value) =>
            setDate(
              moment(date)
                .hour((value as number) / 60)
                .minute((value as number) % 60)
                .format('YYYY-MM-DD HH:mm'),
            )
          }
        />
        <Typography variant="body2" flexShrink={0}>
          15:30
        </Typography>
      </Box>
    </Box>
  );
};

export default TimeLineProgress;
