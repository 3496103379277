import { Typography, Box, Alert } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { formatNumber } from 'utils/GenericFunctions';
import { Link as RouterLink } from 'react-router-dom';

export default function BotDeployCreditFallback({
  botName,
  onclose,
  variant,
  walletBalance,
  requiredCredits,
}: {
  variant?: 'live' | 'paper';
  botName: string;
  walletBalance: number;
  requiredCredits: number;
  onclose: () => void;
}) {
  return (
    <Box
      className="m-main-spacing"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box flex={1}>
        <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
          <FormattedMessage id="common.are_you_sure" />
        </Typography>
        <Typography
          variant="body1"
          sx={{ pb: 1, display: 'flex', fontWeight: 600 }}
          component="span"
        >
          {variant === 'live' ? (
            <FormattedMessage id="buttons.deploy" />
          ) : (
            <FormattedMessage id="bot_status.paper" />
          )}
          <Typography
            variant="body1"
            sx={{ pl: 0.5, fontWeight: 400 }}
            component="span"
          >
            <FormattedMessage id="common.with" />
          </Typography>
        </Typography>
        <Typography variant="body1" sx={{ pb: 4 }}>
          {botName}?
        </Typography>
      </Box>
      <Alert severity="error">
        You do not have sufficient credits to go live. Please add credit from{' '}
        <RouterLink to="/menu/wallet" style={{ textDecoration: 'underline' }}>
          <FormattedMessage id="bot_deploy_modal.here" />
        </RouterLink>{' '}
        and try again.
      </Alert>
      <Box display="flex" justifyContent="space-between" sx={{ pt: 2 }}>
        <Typography variant="body2" color="text.primary">
          <FormattedMessage id={'menu.credit_balance'} />
        </Typography>
        <Typography
          variant="body2"
          color={walletBalance < 0 ? 'error.main' : 'text.primary'}
        >
          {walletBalance < 0 && '-'}
          {formatNumber(walletBalance)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ pt: 1 }}>
        <Typography variant="body2" color="text.primary">
          Required Credits
        </Typography>
        <Typography variant="body2">{formatNumber(requiredCredits)}</Typography>
      </Box>
      <MuiButton
        variant="contained"
        color="secondary"
        fullWidth
        onClick={onclose}
        sx={{ mt: 2 }}
      >
        <FormattedMessage id="buttons.cancel" />
      </MuiButton>
    </Box>
  );
}
