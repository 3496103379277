import { ClickableSmallSubtextVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const ClickableSmallSubtextVariant = (
  props: ClickableSmallSubtextVariantProps,
) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2.75, cursor: 'pointer' }}
      onClick={props.handleClick}
    >
      {props.description && (
        <Box sx={{ pb: 0.5 }}>
          <Typography variant="body3" color="text.secondary">
            {props.description}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body2"
          color={props.color ? props.color : 'primary.main'}
          sx={{ minWidth: 'min-content' }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          color="primary.light"
          sx={{ minWidth: 'min-content' }}
        >
          {props.subtext}
        </Typography>
      </Box>
    </Box>
  );
};

export default ClickableSmallSubtextVariant;
