import { Alert, Box, CircularProgress } from '@mui/material';
import useGetProfile from 'services/Menu/useGetProfile';
import useGetEstimatedCredits from 'services/common/useGetEstimatedCredits';

type CreditEstimationChildProps = {
  isSufficientCredit: boolean;
  remainingCredits: number;
  requiredCredits: number;
  walletBalance: number;
  actionInfo?: string;
};
function CreditEstimation(props: {
  action: string;
  data?: Record<string, string>;
  children: (props: CreditEstimationChildProps) => JSX.Element;
}) {
  const { action, children, data } = props;
  const { data: profile } = useGetProfile();
  const { data: creditRes, isLoading } = useGetEstimatedCredits(action, data);

  if (isLoading)
    return (
      <Box textAlign={'center'}>
        <CircularProgress color="primary" />
      </Box>
    );
  if (profile === undefined || creditRes === undefined) return <></>;
  if (!creditRes.success)
    return <Alert severity="error">{creditRes.error}</Alert>;

  const capabilityInfo =
    profile.user_plan &&
    profile.user_plan.capabilities.find(
      c => c.key === `${action}_${profile.user_plan?.plan.plan_type}`,
    )?.description;

  const isSufficientCredit =
    profile.wallet_balance - creditRes.data.credits > 0;
  const remainingCredits = profile.wallet_balance - creditRes.data.credits;

  return children({
    isSufficientCredit,
    remainingCredits,
    requiredCredits: creditRes.data.credits,
    walletBalance: profile.wallet_balance,
    actionInfo: capabilityInfo,
  });
}

export default CreditEstimation;
