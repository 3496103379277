import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getCookie } from 'typescript-cookie';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import ToastrAlert from 'app/design/uiComponents/ToastrAlert';
import BotDeployModal from 'app/components/Bots/BotDetail/BotDeployModal';
import BotPaperTestModal from 'app/components/Bots/BotDetail/BotPaperTestModal';
import BotStopModal from 'app/components/Bots/BotDetail/BotStopModal';
import Statistics from 'app/components/Marketplace/Statistics';
import { IS_PORTFOLIO_PAPER_AVAIL } from 'constants/common';
import PageHeader from 'app/design/speedBot/PageHeader';
import BotDescription from 'app/design/speedBot/ComponentItem/Bots/BotDescription';
import BotAttributes from 'app/design/speedBot/ComponentItem/Bots/BotAttributes';
import BotOnboardingInfo from 'app/design/speedBot/ComponentItem/Bots/BotOnboardingInfo';
import BotActions from 'app/design/speedBot/ComponentItem/Bots/BotActions';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Services
import useGetMarketplaceBotDetail from 'services/Marketplace/useGetMarketplaceBotDetail';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function MarketplaceBotDetailPage() {
  const { id, title } = useParams<{ id: string; title: string }>();
  const history = useHistory();
  const breadcrumb = useBreadcrumbManager();
  const [message, setMessage] = useState<string>('');
  const [openDeployModal, setOpenDeployModal] = useState<boolean>(false);
  const [openStopModal, setOpenStopModal] = useState<boolean>(false);
  const [openPaperModal, setOpenPaperModal] = useState<boolean>(false);
  const [skipTerms, setSkipTerms] = useState<boolean>(false);
  const isMdUp = useGetMediaQueryUp('md');

  const botId = parseInt(id);
  const {
    data: marketplaceBotDetailData,
    refetch,
    isLoading,
  } = useGetMarketplaceBotDetail(botId);

  const handleDeployModalOpen = () => {
    setMessage('');
    setOpenDeployModal(true);
  };
  const handleDeployModalClose = (isSuccess: boolean, message?: string) => {
    if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenDeployModal(false);
    setSkipTerms(false);
  };

  const handlePaperModalOpen = () => {
    setMessage('');
    setOpenPaperModal(true);
  };
  const handlePaperModalClose = (isSuccess: boolean, message?: string) => {
    if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenPaperModal(false);
  };

  const handleStopModalOpen = () => {
    setMessage('');
    setOpenStopModal(true);
  };
  const handleStopModalClose = (isSuccess: boolean, message?: string) => {
    if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenStopModal(false);
  };

  const isMarketPaperAvail = getCookie(IS_PORTFOLIO_PAPER_AVAIL)
    ? getCookie(IS_PORTFOLIO_PAPER_AVAIL) === 'true'
    : false;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('action')) {
      if (queryParams.get('action') === 'deploy') {
        setSkipTerms(true);
        setOpenDeployModal(true);
      }
      queryParams.delete('action');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history]);

  return (
    <>
      <Helmet>
        <title>Discover Bot Detail</title>
        <meta name="description" content="Discover Bot Detail Page" />
      </Helmet>

      <CircularLoader open={isLoading} />
      <ToastrAlert type="success" message={message} setMessage={setMessage} />
      <PageHeader
        variant="inactive"
        buttonText={
          <FormattedMessage id="market.page_title.bot_detail.back_btn" />
        }
        title={marketplaceBotDetailData ? marketplaceBotDetailData.name : ''}
        buttonHandleClick={() => history.goBack()}
        {...(isMdUp && {
          // breadcrumbLinks: [
          //   {
          //     text: t('market.page_title.market'),
          //     to: `/discover/bots`,
          //     active: true,
          //   },
          // ],
          breadcrumb: <Breadcrumbs page={PAGES.MARKETPLACE_DETAIL} data={{}} />,
        })}
      />
      {marketplaceBotDetailData && (
        <>
          <BotDescription
            desc={marketplaceBotDetailData.description}
            author={marketplaceBotDetailData.author}
            authorLink={`/discover/author/${marketplaceBotDetailData.owner_id}`}
            authorSubscriberCount={marketplaceBotDetailData.subscribers_count}
          />
          <BotAttributes data={marketplaceBotDetailData} />
          <FeatureFlag features={[FEATURES.IS_PAPER_TRADING]} fallback={<></>}>
            {!isMarketPaperAvail ? (
              <BotOnboardingInfo link="/discover/bots" />
            ) : (
              <></>
            )}
          </FeatureFlag>
          <BotActions
            variant="bot"
            botCategory={marketplaceBotDetailData.category}
            handlePaperModal={handlePaperModalOpen}
            handleStopModal={handleStopModalOpen}
            handleDeployModal={handleDeployModalOpen}
          />
          <Statistics
            id={parseInt(id)}
            baseApiUrl={`marketplace/bots/${id}`}
            title={title}
            positionsOnClick={() =>
              breadcrumb.push(
                `/discover/${id}/positions/${title}`,
                PAGES.MARKETPLACE_DETAIL,
                `/discover/bot/${id}/${title}`,
                title,
              )
            }
            ordersOnClick={() =>
              breadcrumb.push(
                `/discover/${id}/orders/${title}`,
                PAGES.MARKETPLACE_DETAIL,
                `/discover/bot/${id}/${title}`,
                title,
              )
            }
            symbolsOnClick={() =>
              breadcrumb.push(
                `/discover/${id}/symbols/${title}`,
                PAGES.MARKETPLACE_DETAIL,
                `/discover/bot/${id}/${title}`,
                title,
              )
            }
            profitLossOnClick={() =>
              breadcrumb.push(
                `/discover/${id}/profitloss/${title}`,
                PAGES.MARKETPLACE_PROFITLOSS,
                `/discover/bot/${id}/${title}`,
                title,
              )
            }
            source="DISCOVER"
          />
        </>
      )}

      <BotDeployModal
        open={openDeployModal}
        handleClose={handleDeployModalClose}
        exchanges={
          marketplaceBotDetailData ? marketplaceBotDetailData.exchanges : []
        }
        // id={parseInt(id)}
        botName={marketplaceBotDetailData ? marketplaceBotDetailData.name : ''}
        botId={botId}
        openFrom="discover"
        skipTerms={skipTerms}
      />
      <BotPaperTestModal
        open={openPaperModal}
        id={parseInt(id)}
        sid={0}
        botName={marketplaceBotDetailData ? marketplaceBotDetailData.name : ''}
        handleClose={handlePaperModalClose}
        openFrom="discover"
      />
      <BotStopModal
        open={openStopModal}
        bid={
          marketplaceBotDetailData && marketplaceBotDetailData.bot_id
            ? marketplaceBotDetailData.bot_id
            : 0
        }
        sid={
          marketplaceBotDetailData && marketplaceBotDetailData.bot_id
            ? marketplaceBotDetailData.bot_id
            : 0
        }
        botName={marketplaceBotDetailData ? marketplaceBotDetailData.name : ''}
        handleClose={handleStopModalClose}
        openFrom="discover"
        category={
          marketplaceBotDetailData ? marketplaceBotDetailData.category : ''
        }
      />
    </>
  );
}
