import { useState, memo } from 'react';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import useGetPlans from 'services/Menu/useGetPlans';
import useGetProfile from 'services/Menu/useGetProfile';
import { SubscriptionTransformPlan } from 'types/SubscriptionPlans';
import PlanChangePageContent from '../Bots/PlanUpgrade/PlanChangePageContent';
import PlanSubscribeConfirm from './PlanSubscribeConfirm';
import { cloneDeep } from 'lodash';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const PlanSubscribeFlow = ({
  type,
  handleBackClick,
  handleClose,
}: {
  type: 'upgrade' | 'change';
  handleBackClick?: () => void;
  handleClose?: () => void;
}) => {
  const [plan, setPlan] = useState<SubscriptionTransformPlan | null>(null);
  const setPlanInfo = (value: SubscriptionTransformPlan | null) =>
    setPlan(value);
  const resetPlanInfo = () => {
    setPlan(null);
  };

  const { data: profileDetails, isLoading: isLoadingGetProfile } =
    useGetProfile();
  const { data: plans, isLoading: isLoadingGetPlans } = useGetPlans('');
  const isIncludeGstInPlans = useIsFeatureFlag([
    FEATURES.IS_INCLUDE_GST_IN_PLANS,
  ]);
  const removeGstFromPlans = cloneDeep(plans);
  if (isIncludeGstInPlans) {
    removeGstFromPlans?.map(item => {
      item.recurring_price_yearly = item.recurring_price_yearly / 1.18;
      item.recurring_price_monthly = item.recurring_price_monthly / 1.18;
    });
  }
  return (
    <>
      <CircularLoader open={isLoadingGetProfile || isLoadingGetPlans} />
      {!plan && profileDetails && (
        <PlanChangePageContent
          setPlanInfo={setPlanInfo}
          plans={removeGstFromPlans}
          handleBack={handleBackClick}
          // If type is change then only check existing plan
          existingPlanId={
            type === 'change' && profileDetails.user_plan
              ? profileDetails.user_plan.plan.plan_id
              : undefined
          }
        />
      )}
      {plan && (
        <PlanSubscribeConfirm
          resetPlanInfo={resetPlanInfo}
          selectedPlan={plan}
          type={type}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default memo(PlanSubscribeFlow);
