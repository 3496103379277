import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import React from 'react';

const MuiStyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
  '&.MuiButton-outlined': {
    '&.MuiButton-sizeMedium': {
      height: '36px',
      color: theme.palette.custom.hoverText,
      background: '#616161',
      borderRadius: '35px',
      border: 'none',
      textTransform: 'capitalize',
    },
  },
}));

type CastedForwardRefButtonType = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>,
  ref?: React.Ref<HTMLButtonElement>,
) => React.ReactElement;
const CastedForwardRefButtonFnc: CastedForwardRefButtonType = (props, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiStyledButton ref={ref} {...rest}>
      {children}
    </MuiStyledButton>
  );
};
const MuiBuilderButton = React.forwardRef(
  CastedForwardRefButtonFnc,
) as CastedForwardRefButtonType;

export default MuiBuilderButton;
