import React from 'react';
import { IconButton, useTheme } from '@mui/material';
import useClasses from './utils/useClasses';
import { FieldData } from './fields/types';
import { FieldKeyToField } from './fields/fieldsData';
import { SelectField } from './fields/SelectField';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { StrategyMode } from './utils/strategy-data';

// Style const

interface Props {
  fieldData: FieldData;
  fieldIndex: number;
  onRemove: (fieldIndex: number) => void;
  onEdit?: (fieldIndex: number, fieldData: FieldData) => void;
  onAddFieldAtPosition: (fieldIndex: number) => void;
  possibleValues: string[];
  onEditClicked: (fieldIndex: number) => void;
  mode: string;
  isValid: {
    status: boolean;
    errorIndex: number;
  };
}

export function FieldWrapper(props: Props) {
  const theme = useTheme();
  const styles = () => ({
    container: {
      display: 'flex',
      position: 'relative',
      // marginBottom: '10px',
      marginBottom: '8px',
    },
    fieldContainer: {
      display: 'flex',
      alignItems: 'stretch',
    },
    addFieldIcon: {
      display: 'none',
      position: 'absolute',
      top: '-28px',
      padding: 0,
      color: theme.palette.grey[700],
      left: '-5px',
    },
    seperatar: {
      display: 'flex',
      justifyContent: 'center',
      height: '44px',
      width: '16px',
      backgroundColor: theme.palette.grey[100],
      borderRadius: '8px',
      position: 'relative',
      border: `1px dashed ${theme.palette.grey[500]}`,
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      '&:hover .addFieldIcon': {
        display: 'block !important',
      },
    },
    invalid: {
      border: `1px dashed ${theme.palette.error.main} !important`,
    },
  });
  const classes = useClasses(styles);
  const {
    fieldData,
    fieldIndex,
    onEdit,
    onRemove,
    possibleValues,
    onEditClicked,
    mode,
    onAddFieldAtPosition,
    isValid,
  } = props;

  const [editMode, setEditMode] = React.useState<boolean>(false);

  const { key } = fieldData;
  const FieldComponent = FieldKeyToField()[key].component;

  const onRemoveClick = React.useCallback(() => {
    onRemove(fieldIndex);
  }, [fieldIndex, onRemove]);

  const onEditIconClick = () => {
    setEditMode(true);
    onEditClicked(fieldIndex);
  };

  const createAddFieldIconClick = React.useCallback(
    (addFieldIndex: number) => {
      return () => onAddFieldAtPosition(addFieldIndex);
    },
    [onAddFieldAtPosition],
  );

  const onEditDone = React.useCallback(
    (fieldData: FieldData) => {
      onEdit && onEdit(fieldIndex, fieldData);
    },
    [fieldIndex, onEdit],
  );

  const onEditCancel = React.useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  return (
    <div className={classes.container}>
      {editMode ? (
        <SelectField
          defaultFieldData={fieldData}
          onDone={onEditDone}
          onCancel={onEditCancel}
          possibleValues={possibleValues}
          onRemove={onRemoveClick}
        />
      ) : (
        <>
          {mode === StrategyMode.EDIT ? (
            <div
              className={`${classes.seperatar} ${
                isValid &&
                isValid.status &&
                isValid.errorIndex === fieldIndex - 1
                  ? classes.invalid
                  : ''
              }`}
            >
              <IconButton
                className={`${classes.addFieldIcon} addFieldIcon`}
                color="secondary"
                onClick={createAddFieldIconClick(fieldIndex)}
              >
                <AddCircleSharpIcon fontSize={'medium'} />
              </IconButton>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.fieldContainer}>
            <FieldComponent
              fieldData={fieldData}
              mode={mode}
              onEdit={onEditIconClick}
              onRemove={onRemoveClick}
              isValid={isValid}
              fieldIndex={fieldIndex}
            />
          </div>
        </>
      )}
    </div>
  );
}
