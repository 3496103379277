import { IosShare } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { openInNewTab } from 'utils/GenericFunctions';

interface ExportStrategyButtonProps {
  strategyId?: number;
}

const ExportStrategyButton = ({ strategyId }: ExportStrategyButtonProps) => {
  const URL = location.origin;
  const handleClick = () => {
    const url = `${URL}/api/strategy/${strategyId}/export`;
    openInNewTab(url);
  };

  return (
    <Box>
      <Tooltip title="Export this strategy" arrow>
        <MuiButton
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<IosShare />}
          onClick={handleClick}
        >
          Export Strategy
        </MuiButton>
      </Tooltip>
    </Box>
  );
};

export default ExportStrategyButton;
