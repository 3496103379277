import { useState, Fragment, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BotHistoryGraph from 'app/components/Common/Charts/BotHistoryGraph';
import {
  Position,
  GetPortfolioBotDetailQueryResult,
  BotSignal,
} from 'types/ApiServicesTypes';
import { decimalFormat, getDate, getQueryParam } from 'utils/GenericFunctions';
import BotHistoryPositionsModal from 'app/components/Bots/BotHistory/BotHistoryPositionsModal';
import TodaysPositionHelpModal from 'app/components/Portfolio/TodaysPositionHelpModal';
import SummaryDetail from 'app/design/speedBot/ComponentItem/Portfolio/SummaryDetail';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import { SymbolsData } from 'types/Symbols';
import StatusMessage from '../Common/StatusMessage';
import { useGetTickData } from 'app/hooks/useGetTickData';
import TodaysPositions from './TodaysPositions';
import PortfolioOpenPositionsTable from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioOpenPositionsTable';
import PortfolioClosePositionsTable from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioClosePositionsTable';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import BotSignalTodaysOpenPositions from '../Bots/BotSignal/BotSignalTodaysOpenPositions';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import LogoutIcon from '@mui/icons-material/Logout';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useParams } from 'react-router-dom';
import { ToastContext } from '../Context/ToastContext';
import usePortfolioExitStrategyPositions from 'services/Portfolio/usePortfolioExitStrategyPositions';
import { useSocket } from '../Context/SocketContext';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import ExitAllConfirmModal from './ExitAllConfirmModal';
import { useGetLogData } from 'app/hooks/useGetLogData';
import BotSignalTodaysPositions from '../Bots/BotSignal/BotSignalTodaysPositions';
import usePostBotExitStrategyPositions from 'services/Bots/usePostBotExitStrategyPositions';
import useGetBotTodaysPositions from 'services/Bots/useGetBotTodaysPositions';
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import BotGenerateSignalModal from '../Bots/BotSignal/BotGenerateSignalModal';
import AddIcon from '@mui/icons-material/Add';
import BotSignalTodaysOpenPositionsTable from '../Bots/BotSignal/BotSignalTodaysOpenPositionsTable';
import { MCXSYMBOLEMULTIPLAYER } from 'types/Position';

type PortfolioSumaryWithPositionsProps = {
  data: GetPortfolioBotDetailQueryResult;
  isLoading: boolean;
  type: string;
};

const calculateTodaysChange = (
  liveFeedData: SymbolsData,
  openBotPositions: Array<Position>,
  todaysChange: number,
) => {
  let sum = todaysChange;
  if (openBotPositions && openBotPositions.length > 0 && liveFeedData) {
    openBotPositions.map(position => {
      const isBuy = position.direction === '0';
      const multiplier =
        (MCXSYMBOLEMULTIPLAYER[position.instrument_id] as number) || 1;
      const currentPrice =
        liveFeedData && liveFeedData[position.trading_symbol]
          ? Number(liveFeedData[position.trading_symbol].Close)
          : 0;
      let profitLoss =
        currentPrice === 0
          ? position.profit_loss
          : isBuy
          ? (currentPrice - position.entry_Price) *
            Math.abs(position.quantity) *
            multiplier
          : (position.entry_Price - currentPrice) *
            Math.abs(position.quantity) *
            multiplier;
      profitLoss = Number(decimalFormat(profitLoss));
      sum += profitLoss;
    });
  }
  return sum;
};

export function PortfolioSumaryWithPositions({
  data,
  type,
  isLoading,
}: PortfolioSumaryWithPositionsProps): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const [selectedPos, setSelectedPos] = useState<Position | null>(null);
  const isDebugMode = getQueryParam('debug', 'false') === 'true';

  const [selectedSignal, setSelectedSignal] = useState<BotSignal | null>(null);
  const [isModify, setIsModify] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ExitAllModal, setExitAllModal] = useState(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [openSignalModal, setOpenSignalModal] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[] | null>(null);
  const { data: botDetailData, openPositions, closePositions, symbols } = data;
  const handleCloseModal = useCallback(() => {
    setIsModify(false);
    setSelectedSignal(null);
    setOpenSignalModal(false);
  }, []);
  const { data: botDetails } = useGetBotDetails(data.data.botid, Number(id));
  const { data: positions, isLoading: isLoadingg } = useGetBotTodaysPositions(
    data.data.botid,
  );

  const mutatePostExitStrategyPositions = usePostBotExitStrategyPositions(
    data.data.botid,
  );
  const handleClickSelectAll = useCallback(() => {
    if (!openPositions.length) return;

    if (selectedItems && openPositions.length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      const selectedItemData = openPositions.map(x => x.id);
      setSelectedItems(selectedItemData);
    }
  }, [openPositions, selectedItems]);
  const liveFeedData = useGetTickData(symbols);
  const isExitPositionPortfolio =
    useIsFeatureFlag([FEATURES.IS_EXIT_POSITION_PORTFOLIO]) &&
    !(data.data.category === 'paper' && data.data.slave);

  const handleSetSelectedItems = useCallback(
    (id: number) => {
      if (!selectedItems) {
        setSelectedItems([id]);
        return;
      }

      const items = selectedItems ? [...selectedItems] : [];
      const index = items.findIndex(x => x === id);
      if (index >= 0) {
        items.splice(index, 1);
      } else {
        items.push(id);
      }
      setSelectedItems(items);
    },
    [selectedItems],
  );
  const handlePositionClick = useCallback((position: Position) => {
    setSelectedPos(position);
    setOpenModal(true);
  }, []);
  const handlePositionSelect = useCallback(
    (position: Position) => {
      if (!isMdUp && selectedItems === null) {
        handlePositionClick(position);
      } else {
        handleSetSelectedItems(position.id);
      }
    },
    [isMdUp, selectedItems, handleSetSelectedItems, handlePositionClick],
  );
  // const handleSelectSignal = useCallback(
  //   (val: BotSignal, isModify: boolean) => {
  //     const signalData: BotSignal = {
  //       ...val,
  //       position_type: isModify ? 'MODIFY' : val.position_type,
  //     };
  //     setIsModify(isModify);
  //     setSelectedSignal(signalData);
  //     setOpenModal(true);
  //   },
  //   [],
  // );
  const { showAlert } = useContext(ToastContext);

  const handleModalClose = useCallback(() => {
    setOpenModal(false);
    setSelectedPos(null);
  }, []);

  const infoClickHandler = useCallback(() => {
    setOpenInfo(true);
  }, []);

  const handleInfoClose = useCallback(() => {
    setOpenInfo(false);
  }, []);

  const todaysChange =
    data && data.data.todays_change ? Number(data.data.todays_change) : 0;
  const calculatedTodaysChange = calculateTodaysChange(
    liveFeedData,
    openPositions,
    todaysChange,
  );
  const todaysChangePer =
    botDetailData.initial_capital === 0
      ? 0
      : (calculatedTodaysChange / botDetailData.initial_capital) * 100;
  const handleClickSelectAndCancel = useCallback(
    (action: 'select' | 'cancel') => {
      if (action === 'select') setSelectedItems([]);
      else setSelectedItems(null);
    },
    [],
  );
  const { socket } = useSocket();

  // const handleclickAddPosition = () => {
  //   setOpenModal(true);
  // };
  const subId = parseInt(id);
  const mutatePortfolioStrategyPositions =
    usePortfolioExitStrategyPositions(subId);
  // useEffect(() => {
  //   () => {
  //     socket?.emit('control', {
  //       bot_id: subId,
  //       position_id: '',
  //       msg_type: 11,
  //       msg_action: 'exit',
  //     });
  //     return () => {
  //       socket?.emit('control', {
  //         bot_id: subId,
  //         position_id: '',
  //         msg_type: 11,
  //         msg_action: 'exit',
  //       });
  //     };
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const handleExitWithSocket = () => {
    // Loop through the array of position IDs
    data.data.today_positions.forEach((positionId: Position) => {
      // Emit socket event for each position ID
      if (selectedItems?.includes(positionId.id))
        socket?.emit('control', {
          bot_id: subId,
          position_id: positionId.metadata?.unique_identifier
            ? positionId.metadata?.unique_identifier
            : positionId.metadata?.trade_id,
          msg_type: 11,
          msg_action: 'selective_exit',
          msg_data: {
            position_id: positionId.metadata?.unique_identifier
              ? positionId.metadata?.unique_identifier
              : positionId.metadata?.trade_id,
          },
        });
    });
    showAlert!('Exit signals are sent successfully', 'success');
  };

  const handleExitPositions = useCallback((positionIds: number[]) => {
    mutatePortfolioStrategyPositions.mutate(
      { positionIds },
      {
        onSuccess: res => {
          if (res.success && type == 'manual') {
            showAlert!(
              res.data && res.data.toaster_message
                ? res.data.toaster_message
                : t('bot_signals.signal_exited'),
              'success',
            );
            // history.replace({
            //   pathname: `/bots/${data.data.botid}/signals/${id}/${data.data.bot_name}`,
            //   search: isDebugMode ? '?debug=true' : '',
            // });
          }
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickExit = () => {
    if (!selectedItems || selectedItems.length === 0) return;
    if (data.data.slave) {
      handleExitPositions(selectedItems);
    } else {
      handleExitWithSocket();
    }
    setSelectedItems([]);
  };

  const { updatedErrors } = useGetLogData(subId);

  useEffect(() => {
    if (updatedErrors.length > 0) {
      updatedErrors.map(msg => showAlert!(msg));
    }
  }, [updatedErrors, showAlert]);

  const isManualTrading = type === 'manual';

  return (
    <Fragment>
      {botDetailData &&
      botDetailData.portfolio_graph &&
      botDetailData.portfolio_graph.length >= 5 &&
      Array.isArray(botDetailData.portfolio_graph) ? (
        <Box sx={{ height: '150px' }} className="m-main-spacing">
          <BotHistoryGraph
            data={botDetailData.portfolio_graph}
            minHeight="150px"
          />
        </Box>
      ) : (
        <></>
      )}
      <SummaryDetail
        todays_change={calculatedTodaysChange}
        total_fees={botDetailData.total_fees}
        todays_change_per={todaysChangePer}
        total_profit_loss={botDetailData.total_profit_loss}
        total_profit_loss_per={botDetailData.total_profit_loss_per}
        portfolio_value={botDetailData.portfolio_value}
        netProfit={botDetailData.net_profit}
        initial_capital={botDetailData.initial_capital}
        started_date={`${getDate(botDetailData.start_date, false)}`}
        strategy={true}
        currencySymbol={botDetailData.currency_symbol}
        liveFeedData={liveFeedData}
        changeOnScroll={false}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ItemHead
          variant="largeWithIcon"
          title={t('todays_position.title.main')}
          icon={
            <IconButton
              sx={{ p: 0.75, color: 'action.active' }}
              onClick={infoClickHandler}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          }
        />
        {!isManualTrading ? (
          <>
            {isExitPositionPortfolio &&
              openPositions.length > 0 &&
              !(selectedItems && selectedItems.length > 0) && (
                <Box
                  className="p-main-spacing"
                  display="flex"
                  justifyContent="end"
                  sx={{ ml: 'auto', alignSelf: 'center', mt: 1 }}
                >
                  {
                    <MuiLoadingButton
                      variant="contained"
                      endIcon={<LogoutIcon />}
                      onClick={() => setExitAllModal(true)}
                      // loading={isLoadingExit}
                    >
                      {/* <FormattedMessage id="buttons.exit" /> */}
                      Exit All
                    </MuiLoadingButton>
                  }
                </Box>
              )}
          </>
        ) : (
          <Box sx={{ mt: 1, textAlign: 'right' }} className="p-main-spacing">
            <MuiButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenSignalModal(true)}
            >
              New Orders
            </MuiButton>
          </Box>
        )}
        {selectedItems && selectedItems.length > 0 && !isManualTrading && (
          <Box
            className="p-main-spacing"
            display="flex"
            justifyContent="end"
            gap={1}
            sx={{ ml: 'auto', alignSelf: 'center', mt: 1 }}
          >
            <MuiLoadingButton
              className="p-main-spacing"
              variant="contained"
              endIcon={<LogoutIcon />}
              onClick={handleClickExit}
              // loading={isLoadingExit}
            >
              <FormattedMessage id="buttons.exit" />
            </MuiLoadingButton>
          </Box>
        )}
      </Box>
      {!isExitPositionPortfolio && !isManualTrading && (
        <Box>
          {!isLoading &&
          (openPositions.length > 0 || closePositions.length > 0) ? (
            <>
              {isMdUp ? (
                <PortfolioOpenPositionsTable
                  positions={openPositions}
                  liveFeedData={liveFeedData}
                  handlePositionClick={handlePositionClick}
                />
              ) : (
                <TodaysPositions
                  positions={openPositions}
                  liveFeedData={liveFeedData}
                  handlePositionClick={handlePositionClick}
                  isTopBorder={true}
                />
              )}
              {closePositions.length > 0 && (
                <>
                  <ItemHead
                    variant="small"
                    title={t('todays_position.title.close')}
                    color={'text.secondary'}
                  />
                  {isMdUp ? (
                    <PortfolioClosePositionsTable
                      positions={closePositions}
                      handlePositionClick={handlePositionClick}
                    />
                  ) : (
                    <TodaysPositions
                      positions={closePositions}
                      liveFeedData={liveFeedData}
                      handlePositionClick={handlePositionClick}
                      isTopBorder={true}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <StatusMessage
              title={t('todays_position.no_pos.title')}
              subtitle={t('todays_position.no_pos.sub_title')}
            />
          )}
        </Box>
      )}
      {!isManualTrading && isExitPositionPortfolio && (
        <>
          {openPositions.length > 0 && (
            <Box className="p-main-spacing" sx={{ mb: 1 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ my: 1 }}
                gap={1}
              >
                {!isMdUp && (
                  <Box>
                    {!selectedItems ? (
                      <MuiButton
                        variant="contained"
                        color="secondary"
                        onClick={() => handleClickSelectAndCancel('select')}
                      >
                        <FormattedMessage id="buttons.select" />
                      </MuiButton>
                    ) : (
                      <>
                        <MuiButton
                          variant="contained"
                          color="secondary"
                          onClick={handleClickSelectAll}
                          sx={{ mr: 1 }}
                        >
                          <FormattedMessage id="buttons.select_all" />
                        </MuiButton>
                        <MuiButton
                          variant="contained"
                          color="secondary"
                          onClick={() => handleClickSelectAndCancel('cancel')}
                        >
                          <FormattedMessage id="buttons.cancel" />
                        </MuiButton>
                      </>
                    )}
                  </Box>
                )}
              </Box>
              {/* {!isMdUp && selectedItems && selectedItems.length === 1 && (
            <Box>
              <MuiButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleclickAddPosition}
                fullWidth
              >
                <FormattedMessage id="buttons.add_position" />
              </MuiButton>
            </Box>
          )} */}
            </Box>
          )}
          <Box>
            {!isLoading &&
            (openPositions.length > 0 || closePositions.length > 0) ? (
              <>
                {isMdUp ? (
                  <BotSignalTodaysOpenPositionsTable
                    positions={openPositions}
                    liveFeedData={liveFeedData}
                    handlePositionClick={handlePositionClick}
                    handleClickSelectPosition={handlePositionSelect}
                    handleClickSelectAll={handleClickSelectAll}
                    selectedItems={selectedItems}
                  />
                ) : (
                  <Box>
                    <BotSignalTodaysOpenPositions
                      positions={openPositions}
                      liveFeedData={liveFeedData}
                      handlePositionClick={handlePositionSelect}
                      isTopBorder={true}
                      selectedItems={selectedItems}
                    />
                  </Box>
                )}
                {closePositions.length > 0 && (
                  <>
                    <ItemHead
                      variant="small"
                      title={t('todays_position.title.close')}
                      color={'text.secondary'}
                    />
                    {isMdUp ? (
                      <PortfolioClosePositionsTable
                        positions={closePositions}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        handlePositionClick={handlePositionClick}
                      />
                    ) : (
                      <BotSignalTodaysOpenPositions
                        positions={closePositions}
                        liveFeedData={liveFeedData}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        handlePositionClick={handlePositionClick}
                        isTopBorder={true}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <StatusMessage
                title={t('todays_position.no_pos.title')}
                subtitle={t('todays_position.no_pos.sub_title')}
              />
            )}
          </Box>
        </>
      )}
      {type == 'manual' && (
        <>
          {positions && botDetails && (
            <BotSignalTodaysPositions
              exchanges={botDetails.exchanges}
              botId={data.data.botid}
              data={positions}
              isLoading={isLoadingg}
              handleChangeTab={() => {
                null;
              }}
              handleExitPositions={handleExitPositions}
              isLoadingExit={mutatePostExitStrategyPositions.isLoading}
            />
          )}
        </>
      )}
      <ExitAllConfirmModal
        oPositions={openPositions.map(p => p.id)}
        bid={data.data.botid}
        botName={data.data.bot_name}
        category={data.data.category}
        handleClose={() => setExitAllModal(false)}
        open={ExitAllModal}
        openFrom="bots"
        sid={subId}
        slave={data.data.slave}
      />
      <BotHistoryPositionsModal
        open={openModal}
        handleClose={handleModalClose}
        data={selectedPos || null}
        title={<FormattedMessage id="buttons.position" />}
        // buySellPositions={true}
      />
      {botDetails && (
        <BotGenerateSignalModal
          from={'manual'}
          isModify={isModify}
          exchanges={botDetails.exchanges}
          open={openSignalModal}
          handleClose={handleCloseModal}
          botId={data.data.botid}
          signal={selectedSignal}
          isDebugMode={isDebugMode}
        />
      )}

      <TodaysPositionHelpModal open={openInfo} handleClose={handleInfoClose} />
    </Fragment>
  );
}
