import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Box } from '@mui/material';
import MarketPlaceBox from 'app/design/layouts/MarketPlaceBox';
import { Errors, GetMarketplaceBotsResult } from 'types/ApiServicesTypes';
import { useQuery } from 'react-query';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { GetMarketplaceBots } from 'services/apiServices';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import SearchIcon from '@mui/icons-material/Search';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';

export function TradersPage() {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);
  const query = useQuery<GetMarketplaceBotsResult, Errors>(
    ['GetTraderBots', 'traders'],
    () => GetMarketplaceBots('traders', 'GET', !isChargesAndProfit),
  );
  const { data: marketplaceBotsData, isLoading } = query;
  return (
    <>
      <Helmet>
        <title>Traders</title>
        <meta name="description" content="Traders Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="market"
        title={<FormattedMessage id="traders.page_title.traders" />}
        marketIconProps={{
          icon: (
            <SearchIcon
              fontSize="medium"
              color="primary"
              sx={{ ml: { md: 1 } }}
            />
          ),
          iconClick: () =>
            breadcrumb.push(
              '/traders/search',
              PAGES.TRADERS,
              `/traders`,
              t('traders.page_title.traders'),
            ),
        }}
      />
      <Box>
        {marketplaceBotsData && marketplaceBotsData.length > 0 && (
          <MarketPlaceBox
            type="traders"
            categories={marketplaceBotsData[0].category_data}
            isVisibleCheckbox={false}
            selectedBots={[]}
            handleSelectBot={() => {
              // handle select
            }}
          />
        )}
      </Box>
    </>
  );
}
