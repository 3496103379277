import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { VersionType } from './types';
import CodeStrategyVersionControlCard from './CodeStrategyVersionControlCard';

const CodePastHistoryModal = ({
  open,
  handleClose,
  versions,
  selectedVersionId,
}: {
  open: boolean;
  handleClose: () => void;
  versions: VersionType | undefined;
  selectedVersionId: string;
}) => {
  const dateCardSX = {
    ml: 'auto',
    maxWidth: '100%',

    flexDirection: 'column',
    boxShadow: '0 4px 4px rgba(0,0,0,0.2)',

    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '1em',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  };
  return (
    <MuiFullScreenModal
      open={open}
      handleClose={handleClose}
      body={
        <Box sx={{ dateCardSX, pb: 0 }}>
          <Typography
            variant="h5"
            sx={{
              py: 2,
              paddingLeft: 2,
              borderBottom: '1px solid #e0e0e0',
              textAlign: 'center',
            }}
          >
            Version History
          </Typography>
          {versions && (
            <CodeStrategyVersionControlCard
              versions={versions}
              selectedVersionId={selectedVersionId}
              handleClose={handleClose}
            />
          )}
        </Box>
      }
    />
  );
};

export default CodePastHistoryModal;
