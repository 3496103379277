import { Add } from '@mui/icons-material';
import { Box } from '@mui/system';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import CreateTickFormModal from 'app/components/Menu/CreateTicketFormModal';
import TicketListView from 'app/components/Menu/HelpDeskComps/TicketListView';
import PageHeader from 'app/design/speedBot/PageHeader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getQueryParam } from 'utils/GenericFunctions';

const MenuHelpPage = () => {
  const openTicketModal = getQueryParam('create');
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Helmet>
        <title>Help Desk</title>
        <meta name="description" content="Help Desk" />
      </Helmet>
      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.menu" />}
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <FormattedMessage id="menu.page_title.help" />
            </Box>
            <Box>
              <MuiButton
                variant="contained"
                color="secondary"
                startIcon={<Add />}
                onClick={() => setOpenModal(true)}
              >
                Create Ticket
              </MuiButton>
            </Box>
          </Box>
        }
        buttonHandleClick="/menu"
        breadcrumb={<Breadcrumbs page={PAGES.HELP} data={{}} />}
      />
      <Box>
        <TicketListView />
      </Box>
      <CreateTickFormModal
        open={openModal || openTicketModal.length > 0}
        handleClose={(val: boolean) => setOpenModal(val)}
      />
    </>
  );
};

export default MenuHelpPage;
