import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import { GetTicketMessage } from 'types/ApiServicesTypes';
import DownloadIcon from '@mui/icons-material/Download';
import { formatDateString } from 'utils/GenericFunctions';
import { openInNewTab } from 'utils/GenericFunctions';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
export const MessageItemComp = ({
  messages,
}: {
  messages: GetTicketMessage;
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const isLgDown = useGetMediaQueryDown('lg');

  // const openImageDialog = (image: string) => {
  //   setSelectedImage(image);
  // };

  const closeImageDialog = () => {
    setSelectedImage(null);
  };
  // const handleClick = () => {
  //   mutation.mutate({
  //     attachmentIndex: 1,
  //     id: 2,
  //     messageIndex: 2,
  //     mimetype: '',
  //     name: '',
  //   });
  // };
  const handleCLick = (url: string) => {
    openInNewTab(url);
  };
  return (
    <>
      {/* // <Card className="p-main-spacing" sx={{ py: 1 }}> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
            <Avatar sx={{ bgcolor: '#1166ff', mr: 1 }}>
              {messages.author.charAt(0)}
            </Avatar>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant="caption" gutterBottom>
                {messages.author}
              </Typography>
              <Typography variant="caption">
                {formatDateString(messages.date, 'DD/MM/YYYY hh:mm')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="body2"
              // eslint-disable-next-line @typescript-eslint/naming-convention
              dangerouslySetInnerHTML={{ __html: messages.body }}
            />
          </Box>
          {messages.attachments && messages.attachments.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption">Attached files: </Typography>
              {messages.attachments.map((attachment, index) => {
                const URL = `${location.origin}${attachment.url}`;
                return (
                  <Tooltip title={attachment.name} key={index}>
                    <MuiButton
                      // key={index}
                      startIcon={<DownloadIcon />}
                      onClick={() =>
                        handleCLick(`${URL}?file=${attachment.name}`)
                      }
                    >
                      {isLgDown
                        ? attachment.name.length < 7
                          ? attachment.name
                          : `${attachment.name.slice(0, 8)}...`
                        : attachment.name}
                    </MuiButton>
                  </Tooltip>
                );
              })}
            </Box>
          )}
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="subtitle2">
            {formatDateString(messages.date, 'h')}h
          </Typography>
        </Box> */}
      </Box>
      <Dialog open={!!selectedImage} onClose={closeImageDialog}>
        <DialogContent>
          <img
            src={selectedImage || ''}
            alt="Zoomed Image"
            style={{
              width: '100%',
              maxHeight: '70vh',
              objectFit: 'contain',
            }}
          />
          <Button onClick={closeImageDialog}>Close</Button>
        </DialogContent>
      </Dialog>
      {/* </Card> */}
    </>
  );
};
