import { useState } from 'react';
import { useMutation } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';

// Service
import {
  ChangePasswordParams,
  ChangePasswordResult,
  Errors,
} from 'types/ApiServicesTypes';
import { ChangePassword } from 'services/apiServices';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useDoSingOut } from 'services/Auth';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function ChangePasswordPage() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { doSignOut } = useDoSingOut();
  // Sign out when password updated
  const handleLogout = () => {
    doSignOut('Password changed successfully. Login Now.');
  };

  // Api calling for edit name in profile
  const mutation = useMutation<
    ChangePasswordResult,
    Errors,
    Pick<ChangePasswordParams, 'password' | 'new_password'>
  >('ChangePassword', values =>
    ChangePassword(values.password, values.new_password).then(res => {
      handleLogout();
      return res;
    }),
  );

  return (
    <>
      <Helmet>
        <title>Change Password Page</title>
        <meta name="description" content="Change Password Page" />
      </Helmet>
      <Formik
        initialValues={{
          password: '',
          new_password: '',
          confirm_password: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(
            t('menu.required', { type: 'Current password' }),
          ),
          new_password: Yup.string()
            .required(t('menu.required', { type: 'New password' }))
            .min(8, t('menu.new_pass_valid')),
          confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password'), null], t('menu.pass_must_match'))
            .required(t('menu.required', { type: 'Confirm password' })),
        })}
        onSubmit={(values, actions) => {
          mutation.mutate(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
        }) => (
          <>
            <CircularLoader open={mutation.isLoading} />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <PageHeader
                variant="inactive"
                buttonText={<FormattedMessage id="menu.page_title.profile" />}
                title={<FormattedMessage id="menu.page_title.change_pass" />}
                buttonHandleClick="/profile"
                breadcrumb={
                  <Breadcrumbs page={PAGES.CHANGE_PASSWORD} data={{}} />
                }
              />
              {mutation.isError ||
              (mutation.data !== undefined &&
                Boolean(mutation.data) === false) ? (
                <Box className="m-main-spacing" sx={{ mb: 2 }}>
                  <Alert severity="error">
                    <FormattedMessage id="menu.current_pass_invalid" />
                  </Alert>
                </Box>
              ) : (
                <></>
              )}
              <Box className="m-main-spacing" sx={{ mb: 2, flex: 1 }}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  variant="outlined"
                  sx={{ mb: 4 }}
                >
                  <InputLabel htmlFor="outlined-adornment-password-login">
                    <FormattedMessage id="menu.current_pass" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-login"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    label={t('menu.current_pass')}
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(touched.new_password && errors.new_password)}
                  variant="outlined"
                  sx={{ mb: 2 }}
                >
                  <InputLabel htmlFor="outlined-adornment-new_password-login">
                    <FormattedMessage id="menu.new_pass" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-new_password-login"
                    type={showPassword ? 'text' : 'password'}
                    name="new_password"
                    value={values.new_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={t('menu.new_pass')}
                  />
                  {touched.new_password && errors.new_password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-NewPassword-login"
                    >
                      {errors.new_password}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.confirm_password && errors.confirm_password,
                  )}
                  variant="outlined"
                  sx={{ mb: 2 }}
                >
                  <InputLabel htmlFor="outlined-adornment-confirm_password-login">
                    <FormattedMessage id="menu.confirm_pass" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm_password-login"
                    type={showPassword ? 'text' : 'password'}
                    name="confirm_password"
                    value={values.confirm_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={t('menu.confirm_pass')}
                    inputProps={{}}
                  />
                  {touched.confirm_password && errors.confirm_password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-confirm_password-login"
                    >
                      {errors.confirm_password}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormHelperText>
                  <FormattedMessage id="menu.pass_tip" />
                </FormHelperText>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => setShowPassword(e.target.checked)}
                      />
                    }
                    label={<FormattedMessage id="menu.show_pass" />}
                  />
                </FormGroup>
              </Box>
              <Box className="m-main-spacing" sx={{ mb: 0 }}>
                <MuiButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mb: 2 }}
                  type="submit"
                  disabled={
                    Boolean(
                      errors.password ||
                        errors.new_password ||
                        errors.confirm_password,
                    ) || mutation.isLoading
                  }
                >
                  <FormattedMessage id="buttons.change_pass" />
                </MuiButton>
                <MuiButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  component={RouterLink}
                  to="/profile"
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
