import { Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Typography, IconButton, useTheme } from '@mui/material';
import { PageTitleMarketVariantProps } from '../types';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import AppbarNotification from 'app/components/Layouts/MainLayout/AppbarNotification';
import { Suspense } from 'react';
import useGetProfile from 'services/Menu/useGetProfile';

const PageTitleMarketVariant = ({
  title,
  marketIconProps,
  rightElement,
  sideBarView,
  isMenu,
}: PageTitleMarketVariantProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  const { data: siteInfo } = useGetSiteInfo();
  const { data: profile } = useGetProfile();
  const theme = useTheme();
  return (
    <Box>
      <Box
        className="p-main-spacing"
        sx={{
          pt: sideBarView ? 1 : 3,
          pb: 2,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.palette.common.white,
        }}
      >
        {!isMdUp && siteInfo && siteInfo.small_logo && (
          <img src={siteInfo.small_logo} />
        )}
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ flex: 1, pl: 1 }}
          color="common.black"
        >
          {title}
        </Typography>
        {marketIconProps && (
          <>
            {isMdUp ? (
              <Box
                {...(marketIconProps.link && {
                  component: RouterLink,
                  to: marketIconProps.link,
                })}
                {...(marketIconProps.iconClick !== undefined && {
                  onClick: marketIconProps.iconClick,
                })}
                sx={{ cursor: 'pointer' }}
              >
                <MuiChip
                  color="greylight"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={() => {}}
                  label={
                    <>
                      Search
                      {marketIconProps.icon}
                    </>
                  }
                />
              </Box>
            ) : (
              <IconButton
                color="primary"
                size="small"
                {...(marketIconProps.link && {
                  component: RouterLink,
                  to: marketIconProps.link,
                })}
                {...(marketIconProps.iconClick !== undefined && {
                  onClick: marketIconProps.iconClick,
                })}
                sx={{ p: 0.5 }}
              >
                {marketIconProps.icon}
              </IconButton>
            )}
          </>
        )}
        {rightElement}
        {sideBarView && !isMenu && (
          <Suspense fallback={<div>Loading..</div>}>
            <AppbarNotification activeNav={'menu'} profile={profile} />
          </Suspense>
        )}
      </Box>
      <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
    </Box>
  );
};

export default PageTitleMarketVariant;
