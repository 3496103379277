import { Box } from '@mui/system';
import * as Yup from 'yup';

import { Formik } from 'formik';
import {
  Button,
  Chip,
  FormHelperText,
  TextField,
  Typography,
  createTheme,
} from '@mui/material';
import { useContext, useState } from 'react';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { usePostTicketData } from 'services/Menu/usePostTicketData';
import { ToastContext } from '../Context/ToastContext';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { PAGES } from '../Common/Breadcrumb/types';
import { useTranslation } from 'react-i18next';
import MUIRichTextEditor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';
import { ThemeProvider } from '@mui/styles';
import { getQueryParam } from 'utils/GenericFunctions';
type HelpDeskModalProps = {
  open: boolean;
  handleClose: (val: boolean) => void;
  subject?: string;
  isRequireToRedirect?: boolean;
};
// interface FormValues {
//   subject: string;
//   description: string;
//   uploadFile: File | null;
// }
const getDecodedDescription = (desc: string) => {
  return decodeURIComponent(desc).split('\\n');
};

// Create content state from the description
const createContentStateFromDescription = (description: string) => {
  const lines = getDecodedDescription(description);
  const blocks = lines.map((line, index) => ({
    key: `${index}`,
    text: line,
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {},
  }));

  return JSON.stringify({
    blocks: blocks,
    entityMap: {},
  });
};
const CreateTickFormModal = ({
  open,
  handleClose,
  subject,
  isRequireToRedirect,
}: HelpDeskModalProps) => {
  const handlCloseModal = () => {
    handleClose(false);
  };
  const { showAlert } = useContext(ToastContext);
  const breadcrumb = useBreadcrumbManager();
  const { t } = useTranslation();
  const mutation = usePostTicketData(handlCloseModal);
  const description = getQueryParam('desc');
  const myTheme = createTheme({
    // set up yout custom mui theme
  });

  Object.assign(myTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          flex: 2,
          border: '1px solid #adadad',
          borderRadius: '4px',
          marginTop: '5px',
        },
        editor: {
          paddingLeft: '14px',
        },
        placeHolder: {
          paddingLeft: '14px',
        },
      },
    },
  });
  const queryParam = getQueryParam('create');
  const queryParams = new URLSearchParams(location.search);
  const openModal = queryParams.has('create');
  const pathName = location.href.split('&')[0].split('/')[3];
  const helpDeskValidationSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    description: Yup.string().required('Description is required'),
  });
  // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files,
  //     f = files && (files[0] as Blob);
  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //     if (e.target) {
  //       const data = JSON.parse(e.target.result as string) as Blob;

  //       setOptionBuilderData({ ...data });
  //     }
  //   };
  //   if (f) {
  //     reader.readAsText(f);
  //   }
  // };
  const isMdUp = useGetMediaQueryUp('md');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  return (
    <MuiModal
      maxWidth="md"
      isFullScreen={!isMdUp}
      open={open || openModal}
      handleClose={handlCloseModal}
      body={
        <Box
          className={'m-main-spacing'}
          // position={'relative'}
          sx={{
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            p: { sx: 0, md: 2 },
          }}
        >
          <Typography variant="h6" mb={2}>
            Create Ticket
          </Typography>
          <Formik
            initialValues={{
              subject: subject || '',
              description: '',
              uploadFiles: [],
            }}
            enableReinitialize
            validationSchema={helpDeskValidationSchema}
            // eslint-disable-next-line no-console
            onSubmit={values => {
              const splitString = String(values.uploadFiles[0]).split(',');
              const result = splitString[1];
              const attachments = selectedFiles.map(file => ({
                name: file.name,
                mimetype: file.type,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                data: result,
              }));
              mutation.mutate(
                {
                  description: values.description,
                  attachments: attachments,
                  subject: values.subject,
                },
                {
                  onSuccess: () => {
                    handlCloseModal();
                    if (isRequireToRedirect) {
                      breadcrumb.push(
                        '/menu/help',
                        PAGES.MENU,
                        '/bots',
                        t('menu.page_title.help'),
                      );
                    }
                  },
                },
              );
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <Box flex={1} display={'flex'} flexDirection={'column'}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <TextField
                    fullWidth
                    label="Subject"
                    error={Boolean(touched.subject && errors.subject)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type="text"
                    name={'subject'}
                    defaultValue={values.subject || queryParam}
                  />
                  {touched.subject && errors.subject && (
                    <FormHelperText error>{errors.subject}</FormHelperText>
                  )}
                  {/* <TextField
                    sx={{ mt: 2 }}
                    multiline
                    rows={18}
                    fullWidth
                    label="Description"
                    name="description"
                    error={Boolean(touched.description && errors.description)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type="text"
                  /> */}
                  <Box sx={{ display: 'flex', flex: 1 }}>
                    <ThemeProvider theme={myTheme}>
                      <MUIRichTextEditor
                        controls={[
                          'title',
                          'bold',
                          'italic',
                          'underline',
                          'highlight',
                          'undo',
                          'redo',
                        ]}
                        // value={mutation.isSuccess && data}
                        onChange={value => {
                          setFieldValue(
                            'description',
                            stateToHTML(value.getCurrentContent()),
                          );
                        }}
                        defaultValue={createContentStateFromDescription(
                          description,
                        )}
                        inlineToolbar
                        label="Description"
                      />
                    </ThemeProvider>
                  </Box>
                  {touched.description && errors.description && (
                    <FormHelperText error>{errors.description}</FormHelperText>
                  )}
                  <Box mt={1}>
                    {selectedFiles.map((file, index) => (
                      <Chip
                        sx={{ mb: 1 }}
                        key={index}
                        label={file.name}
                        onDelete={() => {
                          const updatedFiles = [...selectedFiles];
                          updatedFiles.splice(index, 1);
                          setSelectedFiles(updatedFiles);

                          setFieldValue('uploadFiles', updatedFiles);
                        }}
                      />
                    ))}
                  </Box>

                  <input
                    id="fileInput"
                    type="file"
                    multiple
                    hidden
                    onChange={async event => {
                      const files = event.target.files
                        ? Array.from(event.target.files)
                        : [];

                      if (files.length > 5) {
                        showAlert!('You cannot attach more than 5 files');
                        return;
                      }

                      const invalidFiles = files.filter(
                        file => file.size > 2 * 1024 * 1024,
                      );

                      if (invalidFiles.length > 0) {
                        showAlert!(
                          'File size too large. Try to select file with less then 2MB.',
                          'error',
                        );
                        return;
                      }
                      setSelectedFiles([...selectedFiles, ...files]);
                      await Promise.all(
                        files.map(file => {
                          return new Promise<string>(resolve => {
                            const reader = new FileReader();
                            reader.onload = function (e) {
                              if (e.target) {
                                const base64Data = e.target.result as string;
                                resolve(base64Data);
                              }
                            };
                            reader.readAsDataURL(file);
                          });
                        }),
                      ).then(base64Files => {
                        setFieldValue('uploadFiles', base64Files);
                      });
                    }}
                  />
                </Box>
                <Box sx={{ width: '100%', mb: { sm: 2, md: 0 } }}>
                  <label htmlFor="fileInput">
                    <Button
                      sx={{ mb: 2 }}
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      Upload File
                    </Button>
                  </label>
                  <MuiLoadingButton
                    loading={mutation.isLoading}
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    loadingPosition={'start'}
                    sx={{ mb: 1 }}
                  >
                    Submit
                  </MuiLoadingButton>
                  <MuiButton
                    fullWidth
                    variant="outlined"
                    onClick={() =>
                      queryParam.length
                        ? breadcrumb.push(
                            pathName == 'menu' ? '/menu/help' : pathName,
                            PAGES.MENU,
                            `${pathName === 'menu' ? '/menu' : '/bots'}`,
                            pathName === 'menu'
                              ? t('menu.page_title.menu')
                              : 'Bots',
                          )
                        : handlCloseModal()
                    }
                  >
                    Cancel
                  </MuiButton>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      }
    />
  );
};

export default CreateTickFormModal;
