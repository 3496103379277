import { Box, Typography } from '@mui/material';
import {
  ClockSvg,
  LikeSvg,
  PercentSvg,
} from 'app/components/SvgComponents/SvgComponents';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

const items = [
  <Box
    key={2}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      // height: '',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '12%',
      padding: '20px',
      // backgroundColor: '#141e30',
    }}
    className="carousel-slide"
  >
    {/* Centered SVG */}
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ClockSvg />
    </Box>

    {/* Typography at the bottom */}
    <Box sx={{ textAlign: 'start', mb: 2 }}>
      <Typography variant="h5">
        Stay ahead with instant data insights and market trends.
      </Typography>
      <Typography variant="body1">
        Track performance metrics and respond fast to market changes.
      </Typography>
    </Box>
  </Box>,
  <Box
    key={2}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      // height: '',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '12%',
      padding: '20px',
      // backgroundColor: '#141e30',
    }}
    className="carousel-slide"
  >
    {/* Centered SVG */}
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LikeSvg />
    </Box>

    {/* Typography at the bottom */}
    <Box sx={{ textAlign: 'start', mb: 2 }}>
      <Typography variant="h5">
        Let algorithms trade for you—automate strategies and never miss a market
        move.
      </Typography>
      <Typography variant="body1">
        Deploy bots and optimize your performance with backtests and real-time
        tracking.
      </Typography>
    </Box>
  </Box>,
  <Box
    key={2}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      // height: '',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '12%',
      padding: '0px 20px',
      // backgroundColor: '#141e30',
    }}
    className="carousel-slide"
  >
    {/* Centered SVG */}
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PercentSvg />
    </Box>

    {/* Typography at the bottom */}
    <Box sx={{ textAlign: 'start', mb: 2 }}>
      <Typography variant="h5">
        Build trading bots that match your style, no coding required.
      </Typography>
      <Typography variant="body1">
        Create, test, and deploy bots quickly, and let them trade for you.
      </Typography>
    </Box>
  </Box>,
];

const CarouselIntro = ({
  onAuthPageChange,
  handleClose,
}: {
  onAuthPageChange: (view: string) => void;
  handleClose: () => void;
}) => {
  const { data: stiteInfo } = useGetSiteInfo();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      className="carousel-container"
    >
      <Box
        p={2}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {stiteInfo && stiteInfo.logo && (
          <RouterLink to="/">
            <img src={stiteInfo.logo} />
          </RouterLink>
        )}

        <MuiButton
          size="small"
          onClick={handleClose}
          startIcon={<CloseIcon />}
          sx={{ px: '0 !important' }}
        >
          <FormattedMessage id="buttons.close" />
        </MuiButton>
      </Box>
      <AliceCarousel
        mouseTracking
        items={items}
        autoPlay
        autoPlayInterval={2000}
        infinite
        disableDotsControls={true}
        disableButtonsControls={true}
      />
      <Box sx={{ height: '12%', display: 'flex', gap: 2, p: 2 }}>
        <MuiButton
          size="large"
          variant="outlined"
          color="primary"
          fullWidth={true}
          onClick={() => {
            onAuthPageChange('Sign In');
          }}
        >
          Login
        </MuiButton>
        <MuiButton
          size="large"
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => {
            onAuthPageChange('Sign Up');
          }}
        >
          Signup
        </MuiButton>
      </Box>
    </Box>
  );
};

export default CarouselIntro;
