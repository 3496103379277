import { Position } from 'app/components/Simulator/PayoffChart/blacknScholes';
import {
  generateData,
  generateDataPoint,
} from 'app/components/Simulator/PayoffChart/utils';
import { PositionType } from 'app/components/TradeIdeas/types';

export const dataPointForPositions = (
  positions: Array<PositionType> | undefined,
  lotSize: number,
) => {
  if (positions) {
    const max = Math.max(...positions.map(position => position.strike));
    const min = Math.min(...positions.map(position => position.strike));
    const totalDataPoint = generateDataPoint({
      maxStrike: max + 1500,
      minStrike: min - 1500,
    });
    const payOffDataPoints: number[] = new Array(totalDataPoint.length).fill(
      0,
    ) as number[];
    positions.forEach(position => {
      const p = new Position({
        strike: position.strike.toString(),
        contract_type: position.contract_type,
        direction: position.direction,
        premium: position.premium,
        quantity: Number(position.quantity) * lotSize,
      });
      const positionExpiryPayoffs = p.payoff(totalDataPoint);

      positionExpiryPayoffs.forEach((payoff, idx) => {
        payOffDataPoints[idx] += payoff;
      });
    });
    return generateData(payOffDataPoints, [], min - 1500, max + 1500);
  }
  return [];
};
