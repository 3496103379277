import { useCallback, useState } from 'react';
import MuiButton from 'app/design/uiComponents/MuiButton';
import BotStopModal from 'app/components/Bots/BotDetail/BotStopModal';
import ToastrAlert from 'app/design/uiComponents/ToastrAlert';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import BotRestartModal from './BotRestartModal';
import BotDeployModal from './BotDeployModal';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import BotMTMGraphModal from './BotMTMGraphModal';
import useGetMTMData from 'services/Bots/useGetMTMData';

type BotStopActionProps = {
  search: string;
  bid: string;
  sid: string;
  isRestart: boolean;
};

const BotStopAction = ({ bid, sid, isRestart }: BotStopActionProps) => {
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);

  const [message, setMessage] = useState<string>('');
  const [openStopModal, setOpenStopModal] = useState<boolean>(false);
  const [openRestartModal, setOpenRestartModal] = useState<boolean>(false);
  const [openBotDeployModal, setOpenBotDeployModal] = useState<boolean>(false);
  const [openMTMGraphModal, setOpenMTMGraphModal] = useState<boolean>(false);

  const { data: botDetails, refetch } = useGetBotDetails(parseInt(bid), subId);

  const isAddCredit = useIsFeatureFlag([FEATURES.IS_ADD_CREDIT]);

  const handleStopModalOpen = useCallback(() => {
    setMessage('');
    setOpenStopModal(true);
  }, [setOpenStopModal, setMessage]);

  const handleStopModalClose = useCallback(
    (isSuccess: boolean, message?: string) => {
      if (isSuccess) void refetch();
      if (message) setMessage(message);
      setOpenStopModal(false);
    },
    [setOpenStopModal, setMessage, refetch],
  );

  const handleRestartModalOpen = useCallback(() => {
    setMessage('');
    setOpenRestartModal(true);
  }, [setOpenRestartModal, setMessage]);

  const isShowRestartButton = useIsFeatureFlag([
    FEATURES.IS_SHOW_RESTART_BUTTON,
  ]);

  const isShowMTMGraph = useIsFeatureFlag([FEATURES.IS_SHOW_MTM_GRAPH]);

  const handleRestartModalClose = useCallback(
    (isSuccess: boolean, message?: string) => {
      if (isSuccess) void refetch();
      if (message) setMessage(message);
      setOpenRestartModal(false);
    },
    [setOpenRestartModal, setMessage, refetch],
  );
  const handleMTMGraphModalOpen = () => {
    setOpenMTMGraphModal(true);
  };

  const handleMTMGraphModalClose = () => {
    setOpenMTMGraphModal(false);
  };

  const handleBotDeployModalClose = useCallback((isSuccess: boolean) => {
    if (isSuccess) setOpenBotDeployModal(false);
  }, []);
  const isLiveOrPaper =
    botDetails &&
    (botDetails.category === 'paper' || botDetails.category === 'active');
  const { data: dummyData } = useGetMTMData(openMTMGraphModal, sid);
  return (
    <>
      {isLiveOrPaper && (
        <>
          <ToastrAlert
            type="success"
            message={message}
            setMessage={setMessage}
          />
          {isShowMTMGraph && dummyData?.data && (
            <MuiButton
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ maxWidth: { md: '192px' }, mr: 1 }}
              onClick={handleMTMGraphModalOpen}
            >
              <FormattedMessage id="buttons.mtm_graph" />
            </MuiButton>
          )}
          {botDetails.category === 'active' &&
            isRestart &&
            isShowRestartButton && (
              <MuiButton
                variant="outlined"
                color="error"
                fullWidth
                sx={{ maxWidth: { md: '192px' } }}
                onClick={handleRestartModalOpen}
              >
                <FormattedMessage id="buttons.restart" />
              </MuiButton>
            )}
          {isAddCredit && !isRestart ? (
            <MuiButton
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ maxWidth: { md: '192px' }, mr: 1 }}
              onClick={() => setOpenBotDeployModal(true)}
            >
              <FormattedMessage id="buttons.add_more_lots" />
            </MuiButton>
          ) : (
            <> </>
          )}
          <MuiButton
            variant="outlined"
            color="error"
            fullWidth
            sx={{ maxWidth: { md: '192px' } }}
            onClick={handleStopModalOpen}
          >
            {botDetails.category === 'active' ? (
              <FormattedMessage id="buttons.stop_live" />
            ) : (
              <FormattedMessage id="buttons.stop_paper" />
            )}
          </MuiButton>

          <BotStopModal
            open={openStopModal}
            bid={parseInt(bid)}
            sid={subId}
            botName={botDetails.name}
            handleClose={handleStopModalClose}
            openFrom="bots"
            category={botDetails.category}
          />
          <BotRestartModal
            open={openRestartModal}
            bid={subId}
            botName={botDetails.name}
            handleClose={handleRestartModalClose}
            openFrom="bots"
            category={botDetails.category}
          />
          <BotDeployModal
            open={openBotDeployModal}
            botId={parseInt(bid)}
            botName={botDetails ? botDetails.name : ''}
            openFrom="discover"
            skipTerms={true}
            handleClose={handleBotDeployModalClose}
            isAddMoreLots={true}
          />
          {openMTMGraphModal && (
            <BotMTMGraphModal
              botId={sid}
              botName={botDetails.name}
              open={openMTMGraphModal}
              handleClose={handleMTMGraphModalClose}
            />
          )}
        </>
      )}
    </>
  );
};

export default BotStopAction;
