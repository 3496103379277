import { NotificationItemProps } from './types';
import { Box, Typography } from '@mui/material';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { formatDateString, formatNumber } from 'utils/GenericFunctions';
import { orderStatus } from '../ComponentItem/Bots/utils';
import { ChipColor } from 'types/Generic';
import { GetNotifications } from 'types/ApiServicesTypes';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export type ErrorTypesType = {
  [key: string]: { color: string; icon: JSX.Element };
};
const errorTypes: ErrorTypesType = {
  warn: {
    color: 'warning.main',
    icon: <WarningAmberIcon fontSize="small" />,
  },
  error: {
    color: 'error.main',
    icon: <ErrorOutlineIcon fontSize="small" />,
  },
  info: {
    color: 'info.main',
    icon: <InfoOutlinedIcon fontSize="small" />,
  },
};

const NotificationItemType1 = ({
  notification,
}: {
  notification: GetNotifications;
}) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Typography variant="body3" color="text.disabled">
            {notification.body.order_date
              ? formatDateString(notification.body.order_date, 'hh:mm a')
              : ''}
          </Typography>
        </Box>
        {orderStatus[notification.body.status] && (
          <Box sx={{ flex: 'initial', pl: 2, lineHeight: 0 }}>
            <MuiChip
              icon={<>{orderStatus[notification.body.status].icon}</>}
              label={<>{orderStatus[notification.body.status].value}</>}
              size="xsmallicon"
              color={
                orderStatus[notification.body.status].chipColor as ChipColor
              }
            />
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <Typography variant="body3" color="primary.light">
          {notification.title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" color="text.primary">
            {notification.body.trading_symbol}
          </Typography>
        </Box>
        <Box sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}>
          <Typography variant="body2" color="text.primary">
            {`₹${formatNumber(notification.body.value)}`}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body3" color="primary.light">{`${
            notification.body.direction === '0' ? 'BUY' : 'SELL'
          } ${
            notification.body.tag ? '| ' + notification.body.tag : ''
          }`}</Typography>
        </Box>
        <Box sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}>
          <Typography variant="body3" color="primary.light">{`${
            notification.body.quantity
          } ${
            notification.body.price
              ? `• ₹${formatNumber(notification.body.price)}`
              : ''
          }`}</Typography>
        </Box>
      </Box>
      {notification.body.message && (
        <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
          <Typography variant="body3" color="primary.light">
            {notification.body.message}
          </Typography>
        </Box>
      )}
    </>
  );
};

const NotificationItemType2 = ({
  notification,
}: {
  notification: GetNotifications;
}) => {
  const { data: stiteInfo } = useGetSiteInfo();

  return (
    <>
      <Box display="flex">
        <Box flex={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body3" color="text.disabled">
              {notification.body.order_date
                ? formatDateString(notification.body.order_date, 'hh:mm a')
                : ''}
            </Typography>
            {notification.body.error_type &&
              errorTypes[notification.body.error_type] && (
                <Box
                  sx={{
                    color: errorTypes[notification.body.error_type].color,
                    height: '1rem',
                    display: 'flex',
                  }}
                >
                  {errorTypes[notification.body.error_type].icon}
                </Box>
              )}
          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
            <Typography variant="body2" color="text.primary">
              {notification.title}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
            <Typography variant="body3" color="primary.light">
              {['4'].includes(notification.type)
                ? notification.body.description
                : notification.message}
            </Typography>
          </Box>
        </Box>
        {notification.body.image_url && (
          <Box>
            <img
              src={notification.body.image_url}
              alt={stiteInfo && stiteInfo.name}
              style={{
                height: '3.75rem',
                width: '3.75rem',
                marginRight: '1rem',
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

const NotificationItem = ({
  notification,
  handleClick,
}: NotificationItemProps) => {
  if (
    !['0', '1', '2', '3', '4', '5', '6', '7', '9'].includes(notification.type)
  )
    return <></>;

  return (
    <Box
      key={notification.body.id}
      className="p-main-spacing"
      onClick={() => {
        handleClick(
          notification.id,
          notification.body.sub_type,
          notification.subscription_id,
          notification.body.redirect_url,
          notification.type,
          notification.body.user_broker_info_id,
        );
      }}
      sx={{
        cursor: 'pointer',
        py: 1,
        my: 1,
        position: 'relative',
        ...(!notification.read && {
          '&:before': {
            position: 'absolute',
            content: '""',
            height: 1,
            width: '3px',
            backgroundColor: 'grey.700',
            left: '9px',
            top: '0px',
          },
        }),
      }}
    >
      {['0', '2', '3'].includes(notification.type) && (
        <NotificationItemType1 notification={notification} />
      )}
      {['4', '1', '5', '6', '7', '9'].includes(notification.type) && (
        <NotificationItemType2 notification={notification} />
      )}
    </Box>
  );
};

export default NotificationItem;
