import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ProfileCapability } from 'types/ApiServicesTypes';

function CreditUsageInfo({
  capabilities,
}: {
  capabilities: ProfileCapability[];
}) {
  return (
    <TableContainer>
      <Table
        sx={{
          '& th': {
            whiteSpace: 'nowrap',
            fontWeight: 500,
          },
          '& td': {
            fontWeight: 400,
            verticalAlign: 'middle',
          },
          '& th,td': {
            '&:first-of-type': {
              pl: 0,
            },
            '&:last-of-type': {
              pr: 0,
            },
            '&:not(:first-of-type):not(:last-of-type)': {
              textAlign: 'center',
            },
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>How to use credits?</TableCell>
            <TableCell align="right">Credits Required</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {capabilities.map(capability => (
            <TableRow key={capability.key}>
              <TableCell>
                <Typography variant="body2" fontWeight={500}>
                  {capability.name}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={400}
                  color={'text.secondary'}
                >
                  {capability.description}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontWeight={500}>{capability.credit}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CreditUsageInfo;
