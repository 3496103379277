import { memo } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import {
  BarChart,
  Bar,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  CartesianGrid,
  Tooltip,
  // Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import Typography from '@mui/material/Typography';

import GreyBgCard from 'app/design/uiComponents/BoxGrey';
import { getMarketPlaceBotGraphData } from 'services/apiServices';
import { BotTradeGraphData, Errors } from 'types/ApiServicesTypes';

import { BarGraphPoint } from 'app/design/uiComponents/Charts/BaseBarChart';
import { BAR_STROKE_COLOR } from 'app/design/uiComponents/Charts/constants';

import {
  calculateXAxisTicks,
  getGroupBy,
  renderXAxisTick,
  xAxisTickFormatter,
} from '../ChartUtils';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useIsFeatureFlag } from '../../FeatureFlag';
import { FEATURES } from 'types/Feature';

export const convertGraphDataToPoints = (
  graphData: BotTradeGraphData,
): BarGraphPoint[] => {
  const points = [] as BarGraphPoint[];

  graphData.forEach(item => {
    const date = moment(
      item.label,
      item.label.split(' ').length === 3
        ? 'DD MMM YYYY'
        : item.label.split(' ').length === 2
        ? 'MMMM YYYY'
        : 'YYYY',
    ).toDate();
    points.push({
      date,
      x: date.getTime(),
      y: item.value,
      label: item.label,
    });
  });

  return points;
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<Payload<ValueType, NameType>>;
  label?: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const point = payload[0].payload;
    return (
      <GreyBgCard padding={1}>
        <Typography variant="body2" sx={{ fontSize: '0.625rem' }}>
          {/* {`Date : ${formatDateString(
            point.date as string,
            'DD MMM YYYY HH:MM:SS',
          )}`} */}
          {`Date : ${point.label}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: '0.625rem' }}
        >{`Positions : ${point.y}`}</Typography>
      </GreyBgCard>
    );
  }

  return null;
};

interface TradesGraphProps {
  id: number;
  baseApiUrl: string;
  minHeight?: string;
}

const TradesGraph = (props: TradesGraphProps) => {
  const { minHeight, baseApiUrl } = props;
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  // Api calling for fetch trades graph data
  const { data } = useQuery<BotTradeGraphData, Errors>(
    ['GetMarketplacBarGraphTrade', baseApiUrl],
    () =>
      getMarketPlaceBotGraphData<BotTradeGraphData>(
        baseApiUrl,
        'trades',
        !isChargesAndProfit,
      ),
  );

  if (!data) {
    return null;
  }

  const graphApiResponse = data || [];
  const graphData = convertGraphDataToPoints(graphApiResponse);
  const dates = graphData.map(item => item.date);
  const groupBy = getGroupBy(dates);
  const yAxisProps = {
    tickSize: 0,
    tickMargin: 5,
    orientation: 'right' as YAxisProps['orientation'],
    width: 30,
  };

  const xAxisTicks = calculateXAxisTicks(dates as Date[], groupBy);
  const xAxisProps = {
    dataKey: 'x',
    ticks: xAxisTicks,
    scale: 'time' as XAxisProps['scale'],
    type: 'number' as XAxisProps['type'],
    domain: ['dataMin', 'auto'],
    tickSize: 20,
    interval: 'preserveStartEnd' as XAxisProps['interval'],
    tickFormatter: xAxisTickFormatter(groupBy),
    tick: renderXAxisTick(groupBy)(xAxisTicks),
  };
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      {...(minHeight && { minHeight: minHeight })}
    >
      <BarChart data={graphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis {...xAxisProps} padding={{ left: 10, right: 10 }} />
        <YAxis {...yAxisProps} />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <ReferenceLine y={0} stroke={'rgba(0, 0, 0, 0.04)'} />
        <Bar dataKey="y" stackId="a" maxBarSize={20} fill={BAR_STROKE_COLOR} />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default memo(TradesGraph);
