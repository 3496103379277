import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallBack = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Box className="m-main-spacing" sx={{ textAlign: 'center', py: 2 }}>
      <Typography variant="body1" sx={{ py: 1 }}>
        Something went wrong
      </Typography>
      <Typography variant="body1" sx={{ py: 1 }}>
        {error.message}
      </Typography>
      <MuiButton
        variant="contained"
        sx={{ mt: 2 }}
        onClick={resetErrorBoundary}
      >
        Try again
      </MuiButton>
    </Box>
  );
};

export default ErrorFallBack;
