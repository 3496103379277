import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';
import ColorBar from 'app/design/uiComponents/Calendar/ColorBar';
import { ReportTransfromPnlData } from 'types/ApiServicesTypes';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
// import { ProfitLossByDateTransform } from './types';

//function to get all days by week
const ListViewCalendarDateRow = ({
  data,
  currencySymbol,
}: {
  data: ReportTransfromPnlData[];
  currencySymbol: string;
}) => {
  // const datePLData = [...data].reverse();
  // const sortedData = [...datePLData].sort();
  const datePLData = [...data].sort((a, b) =>
    moment(a.date).diff(moment(b.date)),
  );
  const isMdDown = useGetMediaQueryDown('md');

  return (
    <>
      {datePLData.map((dateData, i) => {
        const dt = moment(dateData.date);
        return (
          <tr key={i}>
            <td>
              <Typography
                variant="body3"
                color="GrayText.secondary"
                component="div"
              >
                {dt.format('D')}
              </Typography>
            </td>
            <td>
              <Typography variant="body3" color="text.disabled" component="div">
                {dt.format('ddd')}
              </Typography>
            </td>
            <td align="center">
              <Typography
                variant="body3"
                color="text.secondary"
                component="div"
              >
                {dateData.trades ? dateData.trades : '-'}
              </Typography>
            </td>
            <td align="left" style={isMdDown ? { width: '132px' } : {}}>
              <Typography
                variant="body3"
                color="text.secondary"
                component="div"
              >
                {dateData.bot_name ? dateData.bot_name : '-'}
              </Typography>
            </td>
            <td align="right">
              <Typography
                variant="body3"
                color={getColorByNum(dateData.profit_loss)}
                component="div"
                fontWeight={500}
              >
                {dateData.profit_loss
                  ? `${currencySymbol}${formatNumber(
                      dateData.profit_loss,
                      false,
                      0,
                    )}`
                  : `${currencySymbol}0`}
              </Typography>
            </td>
            <td align="center">
              <Box
                sx={{
                  width: '48px',
                  display: 'flex',
                  justifyContent: dateData.profit_loss >= 0 ? 'start' : 'end',
                }}
              >
                <ColorBar
                  percentage={dateData.profit_loss_percentage}
                  color={dateData.color}
                />
              </Box>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default ListViewCalendarDateRow;
