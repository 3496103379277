import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiToggleButton from 'app/design/uiComponents/MuiToggleButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SignalSpreadValueType } from './types';
import {
  SignalTransactionTypes,
  SignalProductTypes,
  SignalPositionTypes,
} from '../util';
import { createContractSelectionOptions } from 'app/components/Builder/OptionsStrategy/FieldsData';

const BotGenerateSignalTemplateSpread = ({
  niftySymbol,
  handleGenerateSignal,
  getAtmInstrument,
  handleClickCancel,
}: {
  niftySymbol: string;
  handleGenerateSignal: (type: string, values: SignalSpreadValueType) => void;
  getAtmInstrument: (atmValue: number) => number;
  handleClickCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [val1AtmPrice, setVal1AtmPrice] = useState(0);
  const [val2AtmPrice, setVal2AtmPrice] = useState(0);

  const contractSelectionOptions = useMemo(() => {
    return createContractSelectionOptions(niftySymbol, 'strike', 'call');
  }, [niftySymbol]);

  useEffect(() => {
    const difVal1StrikePrice = getAtmInstrument(0);
    const difVal2StrikePrice = getAtmInstrument(5);
    setVal1AtmPrice(difVal1StrikePrice ? difVal1StrikePrice : 0);
    setVal2AtmPrice(difVal2StrikePrice ? difVal2StrikePrice : 0);
  }, [getAtmInstrument]);

  return (
    <>
      <Box sx={{ height: '100%', overflow: 'auto', display: 'grid' }}>
        <Formik
          initialValues={
            {
              type: 'call',
              value1: 0,
              value2: 5,
              lots: 1,
              transactionType: 'BUY',
              productType: 'MIS',
              orderTag: '',
              tradeId: '',
              positionType: 'EN',
            } as SignalSpreadValueType
          }
          validationSchema={Yup.object().shape({
            lots: Yup.number()
              .min(1, t('bot_deploy_modal.valid_msg.valid', { type: 'Lots' }))
              .required(t('bot_deploy_modal.valid_msg.req', { type: 'Lots' })),
          })}
          onSubmit={(values, actions) => {
            handleGenerateSignal('SPREAD', values);
            actions.resetForm();
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleBlur,
            handleChange,
            touched,
            isValid,
            resetForm,
            submitForm,
          }) => {
            return (
              <>
                <Box
                  component="form"
                  noValidate
                  // onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ mb: 2, flex: 1 }}>
                    <Box sx={{ mb: 3 }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="type"
                          value={values.type}
                          onChange={e => {
                            const type = e.target.value;
                            setFieldValue('type', type);
                            const newVal1StrikePrice = getAtmInstrument(
                              (type === 'call' ? 1 : -1) * values.value1,
                            );
                            setVal1AtmPrice(newVal1StrikePrice);
                            const newVal2StrikePrice = getAtmInstrument(
                              (type === 'call' ? 1 : -1) * values.value2,
                            );
                            setVal2AtmPrice(newVal2StrikePrice);
                          }}
                        >
                          <FormControlLabel
                            value="call"
                            control={<Radio />}
                            label={`${t(
                              'bot_signals.template_form.call_spread',
                            )}`}
                          />
                          <FormControlLabel
                            value="put"
                            control={<Radio />}
                            label={`${t(
                              'bot_signals.template_form.put_spread',
                            )}`}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <InputLabel id="label-value1">
                            {`+${t('bot_signals.template_form.1x')} [${t(
                              `bot_signals.template_form.${
                                values.type === 'call' ? 'ce' : 'pe'
                              }`,
                            )}]`}
                          </InputLabel>
                          <Select
                            labelId="label-value1"
                            name="value1"
                            value={values.value1}
                            label={`+${t('bot_signals.template_form.1x')} [${t(
                              `bot_signals.template_form.${
                                values.type === 'call' ? 'ce' : 'pe'
                              }`,
                            )}]`}
                            onChange={e => {
                              const val = e.target.value as number;
                              setFieldValue('value1', val);
                              const newStrikePrice = getAtmInstrument(
                                (values.type === 'call' ? 1 : -1) * val,
                              );
                              setVal1AtmPrice(newStrikePrice);
                            }}
                          >
                            {contractSelectionOptions.map(interval => (
                              <MenuItem value={interval.key} key={interval.key}>
                                {interval.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Box className="extra-label">
                            <Typography variant="body2">
                              {val1AtmPrice}
                            </Typography>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <InputLabel id="label-value2">
                            {`-${t('bot_signals.template_form.1x')} [${t(
                              `bot_signals.template_form.${
                                values.type === 'call' ? 'ce' : 'pe'
                              }`,
                            )}]`}
                          </InputLabel>
                          <Select
                            labelId="label-value2"
                            name="value2"
                            value={values.value2}
                            label={`-${t('bot_signals.template_form.1x')} [${t(
                              `bot_signals.template_form.${
                                values.type === 'call' ? 'ce' : 'pe'
                              }`,
                            )}]`}
                            onChange={e => {
                              const val = e.target.value as number;
                              setFieldValue('value2', val);
                              const newStrikePrice = getAtmInstrument(
                                (values.type === 'call' ? 1 : -1) * val,
                              );
                              setVal2AtmPrice(newStrikePrice);
                            }}
                          >
                            {contractSelectionOptions.map(interval => (
                              <MenuItem value={interval.key} key={interval.key}>
                                {interval.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Box className="extra-label">
                            <Typography variant="body2">
                              {val2AtmPrice}
                            </Typography>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.lots && errors.lots)}
                          variant="outlined"
                          sx={{ mb: 3 }}
                        >
                          <InputLabel htmlFor="outlined-adornment-lots">
                            {t('bot_signals.template_form.lots')}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-lots"
                            type="number"
                            name="lots"
                            value={values.lots}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{
                              min: 1,
                              style: { textAlign: 'center' },
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setFieldValue('lots', values.lots + 1)
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={() =>
                                    setFieldValue(
                                      'lots',
                                      values.lots <= 1 ? 1 : values.lots - 1,
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t('bot_signals.template_form.lots')}
                          />
                          {touched.lots && errors.lots && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.lots}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ mb: 3 }}
                        >
                          <InputLabel htmlFor="label-tradeId">
                            <FormattedMessage id="bot_signals.form.trade_id_optional" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-tradeId"
                            name="tradeId"
                            value={values.tradeId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={t('bot_signals.form.trade_id_optional')}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ mb: 3 }}>
                          <Typography sx={{ mb: 1 }} fontWeight={500}>
                            {t('bot_signals.form.transaction_type')}
                          </Typography>
                          <Box display="flex" gap={2}>
                            {SignalTransactionTypes.map(item => (
                              <Fragment key={item.value}>
                                <MuiToggleButton
                                  label={item.label}
                                  value={item.value}
                                  autoWidth={true}
                                  isSelected={
                                    item.value === values.transactionType
                                  }
                                  setValue={val => {
                                    setFieldValue('transactionType', val);
                                  }}
                                />
                              </Fragment>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ mb: 3 }}>
                          <Typography sx={{ mb: 1 }} fontWeight={500}>
                            {t('bot_signals.form.product_type')}
                          </Typography>
                          <Box display="flex" gap={2}>
                            {SignalProductTypes.map(item => (
                              <Fragment key={item.value}>
                                <MuiToggleButton
                                  label={item.label}
                                  value={item.value}
                                  autoWidth={true}
                                  isSelected={item.value === values.productType}
                                  setValue={val => {
                                    setFieldValue('productType', val);
                                  }}
                                />
                              </Fragment>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ mb: 3 }}>
                          <Typography sx={{ mb: 1 }} fontWeight={500}>
                            {t('bot_signals.form.position_type')}
                          </Typography>
                          <Box display="flex" gap={2}>
                            {SignalPositionTypes.map(item => (
                              <Fragment key={item.value}>
                                <MuiToggleButton
                                  label={item.label}
                                  value={item.value}
                                  autoWidth={true}
                                  isSelected={
                                    item.value === values.positionType
                                  }
                                  setValue={val => {
                                    setFieldValue('positionType', val);
                                  }}
                                />
                              </Fragment>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    sx={{
                      textAlign: 'center',
                      display: { xs: 'block', md: 'flex' },
                      justifyContent: 'end',
                      flexDirection: 'row-reverse',
                      gap: 2,
                    }}
                  >
                    <MuiLoadingButton
                      // type="submit"
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                      // loading={mutation.isLoading}
                      sx={{ mb: 2, width: { xs: '100%', lg: 'auto' } }}
                      onClick={submitForm}
                    >
                      <FormattedMessage id={'buttons.add_basket'} />
                    </MuiLoadingButton>
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleClickCancel();
                        resetForm();
                      }}
                      sx={{ mb: 2, width: { xs: '100%', lg: 'auto' } }}
                    >
                      <FormattedMessage id="buttons.cancel" />
                    </MuiButton>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default memo(BotGenerateSignalTemplateSpread);
