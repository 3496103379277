import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StorefrontOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import BoxGrey from 'app/design/uiComponents/BoxGrey';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { encodeUrlData } from 'utils/GenericFunctions';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { Divider } from '@mui/material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import {
  StrategyBuilderTypes,
  StrategyBuiltWithTypes,
} from '../StrategyBuilder/util';

const BotDetailLinks = () => {
  const breadcrumb = useBreadcrumbManager();
  const { t } = useTranslation();
  const { bid, sid, title } =
    useParams<{ bid: string; sid: string; title: string }>();

  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const botId = parseInt(bid);
  const titleText = isNaN(parseInt(sid)) ? sid : title;
  const isFromPortfolio = breadcrumb.isPageExist(PAGES.PORTFOLIO);
  const { data: botDetails } = useGetBotDetails(botId, subId);

  const botName =
    botDetails && botDetails.name ? encodeUrlData(botDetails.name) : '';

  const isMdUp = useGetMediaQueryUp('md');
  const isSignal =
    botDetails && botDetails.is_own_bot && botDetails.is_line_strategy;
  const isShowBackTest = useIsFeatureFlag([FEATURES.IS_SHOW_BACKTEST]);
  const isBuiltWithApp = botDetails
    ? (botDetails.built_with === null ||
        botDetails.built_with in StrategyBuiltWithTypes) &&
      botDetails.type in StrategyBuilderTypes
    : false;

  return (
    <>
      {botDetails && (
        <>
          <Box display="flex" className="m-main-spacing" sx={{ mb: 3 }}>
            <FeatureFlag
              features={[FEATURES.IS_SELL_ON_MARKET]}
              fallback={<></>}
            >
              <MuiButton
                fullWidth
                size={isMdUp ? 'xlarge' : 'medium'}
                variant="contained"
                color="secondary"
                startIcon={<StorefrontOutlined />}
                onClick={() =>
                  breadcrumb.push(
                    `/bots/${botDetails.user_botid}/sell-on-discover/${botName}`,
                    PAGES.BOT_DETAIL,
                    `/bots/${botId}/details${
                      subId ? `/${subId}` : ''
                    }/${titleText}`,
                    isFromPortfolio ? t('buttons.more_info') : botDetails.name,
                  )
                }
              >
                <FormattedMessage id="buttons.sell_on_market" />
              </MuiButton>
            </FeatureFlag>
          </Box>
          {isMdUp && (
            <Box display="flex" className="m-main-spacing" sx={{ mb: 2 }}>
              {/* <MuiButton
                size="xlarge"
                variant="contained"
                color="secondary"
                fullWidth
                endIcon={<ChevronRightOutlined />}
                sx={{
                  justifyContent: 'space-between',
                  flex: 1,
                  mr: 2,
                  maxWidth: '20rem',
                }}
              >
                <FormattedMessage id="buttons.market_stats" />
              </MuiButton>
              <Divider sx={{ borderColor: 'grey.300' }} /> */}
              <FeatureFlag features={[FEATURES.IS_BOT_SIGNAL]} fallback={<></>}>
                {isSignal && (
                  <>
                    <MuiButton
                      size="xlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bots/${botId}/signals/${subId}/${botName}`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{
                        justifyContent: 'space-between',
                        flex: 1,
                        mr: 2,
                        maxWidth: '20rem',
                      }}
                    >
                      <FormattedMessage id="buttons.signals" />
                    </MuiButton>
                    <MuiButton
                      size="xlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bots/${botId}/signals/${subId}/positions/${botName}`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{
                        justifyContent: 'space-between',
                        flex: 1,
                        mr: 2,
                        maxWidth: '20rem',
                      }}
                    >
                      <FormattedMessage id="buttons.history" />
                    </MuiButton>
                  </>
                )}
              </FeatureFlag>
              {!isSignal && (
                <>
                  {isShowBackTest && (
                    <MuiButton
                      size="xlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bots/${botId}/backtest/${subId}/${botName}/`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{
                        justifyContent: 'space-between',
                        flex: 1,
                        mr: 2,
                        maxWidth: '20rem',
                      }}
                    >
                      <FormattedMessage id="buttons.backtesting" />
                    </MuiButton>
                  )}
                  {botDetails.history_count > 0 && (
                    <>
                      <Divider sx={{ borderColor: 'grey.300' }} />
                      <MuiButton
                        size="xlarge"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          breadcrumb.push(
                            `/bots/${botId}/history/${subId}/${botName}/`,
                            PAGES.BOT_DETAIL,
                            `/bots/${botId}/details${
                              subId ? `/${subId}` : ''
                            }/${titleText}`,
                            isFromPortfolio
                              ? t('buttons.more_info')
                              : botDetails.name,
                          )
                        }
                        fullWidth
                        endIcon={<ChevronRightOutlined />}
                        sx={{
                          justifyContent: 'space-between',
                          flex: 1,
                          maxWidth: '20rem',
                        }}
                      >
                        <FormattedMessage id="buttons.history" />
                      </MuiButton>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          <BoxGrey sx={{ mb: 2 }} className="m-main-spacing">
            {botDetails.user_botid > 0 && (
              <FeatureFlag
                features={[FEATURES.IS_BACKTESTING]}
                fallback={<></>}
              >
                {isBuiltWithApp && (
                  <>
                    <MuiButton
                      size="xxlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bot/${botDetails.user_botid}/strategy/${subId}`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <FormattedMessage id="buttons.strategy" />
                    </MuiButton>
                    <Divider sx={{ borderColor: 'grey.300' }} />
                  </>
                )}
                {botDetails?.is_custom_form && (
                  <FeatureFlag
                    features={[FEATURES.IS_STRATEGY_PARAMETER_FORM]}
                    fallback={<></>}
                  >
                    <MuiButton
                      size="xxlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bot/${botDetails.user_botid}/backtesting/${subId}`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <FormattedMessage id="user_bot_startegy.strategy_parameters" />
                    </MuiButton>
                    <Divider sx={{ borderColor: 'grey.300' }} />
                  </FeatureFlag>
                )}
              </FeatureFlag>
            )}
            <>
              <MuiButton
                size="xxlarge"
                variant="contained"
                color="secondary"
                onClick={() =>
                  breadcrumb.push(
                    `/bots/${botId}/${subId ? subId : 0}/symbols/${botName}`,
                    PAGES.BOT_DETAIL,
                    `/bots/${botId}/details${
                      subId ? `/${subId}` : ''
                    }/${titleText}`,
                    isFromPortfolio ? t('buttons.more_info') : botDetails.name,
                  )
                }
                fullWidth
                endIcon={<ChevronRightOutlined />}
                sx={{ justifyContent: 'space-between' }}
              >
                {botDetails.scripts.length}{' '}
                <FormattedMessage id="buttons.symbol" />
              </MuiButton>
              {/* <Divider sx={{ borderColor: 'grey.300' }} />
              <MuiButton
                size="xxlarge"
                variant="contained"
                color="secondary"
                fullWidth
                endIcon={<ChevronRightOutlined />}
                sx={{ justifyContent: 'space-between' }}
              >
                <FormattedMessage id="buttons.market_stats" />
              </MuiButton> */}
            </>
            {!isMdUp && (
              <>
                {isSignal && (
                  <>
                    <Divider sx={{ borderColor: 'grey.300' }} />
                    <MuiButton
                      size="xxlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bots/${botId}/signals/${subId}/${botName}/`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <FormattedMessage id="buttons.signals" />
                    </MuiButton>
                    <Divider sx={{ borderColor: 'grey.300' }} />
                    <MuiButton
                      size="xxlarge"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        breadcrumb.push(
                          `/bots/${botId}/signals/${subId}/positions/${botName}`,
                          PAGES.BOT_DETAIL,
                          `/bots/${botId}/details${
                            subId ? `/${subId}` : ''
                          }/${titleText}`,
                          isFromPortfolio
                            ? t('buttons.more_info')
                            : botDetails.name,
                        )
                      }
                      fullWidth
                      endIcon={<ChevronRightOutlined />}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <FormattedMessage id="buttons.history" />
                    </MuiButton>
                  </>
                )}
                {!isSignal && (
                  <>
                    <Divider sx={{ borderColor: 'grey.300' }} />
                    {isShowBackTest && (
                      <MuiButton
                        size="xxlarge"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          breadcrumb.push(
                            `/bots/${botId}/backtest/${subId}/${botName}/`,
                            PAGES.BOT_DETAIL,
                            `/bots/${botId}/details${
                              subId ? `/${subId}` : ''
                            }/${titleText}`,
                            isFromPortfolio
                              ? t('buttons.more_info')
                              : botDetails.name,
                          )
                        }
                        fullWidth
                        endIcon={<ChevronRightOutlined />}
                        sx={{ justifyContent: 'space-between' }}
                      >
                        <FormattedMessage id="buttons.backtesting" />
                      </MuiButton>
                    )}
                    <Divider sx={{ borderColor: 'grey.300' }} />
                    {botDetails.history_count > 0 && (
                      <>
                        <MuiButton
                          size="xxlarge"
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            breadcrumb.push(
                              `/bots/${botId}/history/${subId}/${botName}/`,
                              PAGES.BOT_DETAIL,
                              `/bots/${botId}/details${
                                subId ? `/${subId}` : ''
                              }/${titleText}`,
                              isFromPortfolio
                                ? t('buttons.more_info')
                                : botDetails.name,
                            )
                          }
                          fullWidth
                          endIcon={<ChevronRightOutlined />}
                          sx={{ justifyContent: 'space-between' }}
                        >
                          <FormattedMessage id="buttons.history" />
                        </MuiButton>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </BoxGrey>
        </>
      )}
    </>
  );
};

export default BotDetailLinks;
