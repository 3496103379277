import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  FormHelperText,
  TextField,
  Alert,
  Divider,
} from '@mui/material';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import * as Yup from 'yup';
import 'yup-phone';
import { Formik } from 'formik';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiPhoneNumber from 'material-ui-phone-number';
import { parseFormattedPhoneNumber } from 'utils/GenericFunctions';
import { DEFAULT_COUNTRY_CODE } from 'constants/common';

// service
import useForgotPass from 'services/Auth/useForgotPass';
import { useTranslation } from 'react-i18next';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { ForgotPassPageProps } from './SignInModal';
import SpeedBotLogo from '../Common/SpeedBotLogo';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function ForgotPassPage({
  onAuthPageChange,
  handleForgotPassData,
}: {
  onAuthPageChange: (view: string) => void;
  handleForgotPassData: (forgotPassObj: ForgotPassPageProps) => void;
}) {
  const { t } = useTranslation();
  // const history = useHistory();
  const { data: siteInfo } = useGetSiteInfo();
  const mutation = useForgotPass();
  const handleCancelClick = () => {
    onAuthPageChange('Sign In');
  };
  const handleNextClick = () => {
    onAuthPageChange('Confirm Code');
  };
  const isMobileNumberLogin = useIsFeatureFlag([
    FEATURES.IS_MOBILE_NUMBER_LOGIN,
  ]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Signup Verify Phone Page</title>
        <meta name="description" content="Signup verify phone page" />
      </Helmet>
      <CircularLoader open={mutation.isLoading} />
      <SigninPageContentLayout>
        {/* Must have max two child element */}
        <Box>
          <SpeedBotLogo />
          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mt: 4, textAlign: 'center' }}
          >
            {t('find_your_account', {
              seteName: siteInfo ? siteInfo.name : '',
            })}
          </Typography>
          <Formik
            initialValues={{
              phone: '',
              email: '',
              country_code:
                siteInfo && siteInfo.default_country_code
                  ? siteInfo.default_country_code
                  : '',
              mobile:
                siteInfo && siteInfo.default_country_code
                  ? siteInfo.default_country_code
                  : DEFAULT_COUNTRY_CODE,
            }}
            validationSchema={Yup.object().shape(
              {
                country_code: Yup.string()
                  .min(1, 'Country code must be at least one digit.')
                  .max(3, 'Country code must be less than three digit.')
                  .when('email', {
                    is: (email: string) => !email,
                    then: schema =>
                      schema.required('Country code is required.'),
                  }),
                phone: Yup.string().when('email', {
                  is: (email: string) => !email,
                  then: schema => schema.required('Phone number is required.'),
                }),
                mobile: Yup.string().when('email', {
                  is: (email: string) => !email,
                  then: schema =>
                    schema
                      .phone(
                        undefined,
                        undefined,
                        'Please enter a valid phone number.',
                      )
                      .required('Phone number is required.'),
                }),
                email: Yup.string()
                  .email('Please enter valid email address')
                  .when('phone', {
                    is: (phone: string) => !phone,
                    then: schema => schema.required('Email is required'),
                  }),
              },
              [['email', 'phone']],
            )}
            onSubmit={values => {
              mutation.mutate(
                {
                  action: 'send',
                  country_code: values.country_code,
                  email: values.email,
                  mobile: values.phone,
                  auth_type: values.phone ? 'mobile' : 'email',
                },
                {
                  onSuccess: res => {
                    if (res.success && res.auth_type === 'mobile') {
                      if (values.country_code === DEFAULT_COUNTRY_CODE) {
                        handleForgotPassData({
                          code: values.country_code,
                          emailOrPhone: values.phone,
                          type: 'phone',
                        });
                      } else {
                        handleForgotPassData({
                          code: values.country_code,
                          emailOrPhone: values.phone,
                          type: 'email',
                        });
                        // setResetData({
                        //   code: values.country_code,
                        //   emailOrPhone: values.phone,
                        //   type: 'email',
                        // });
                      }
                      // getDataForReset(resetData);
                      handleNextClick();
                      // history.push(
                      //   ''
                      //   // `/forgotpass/resetconfirmcode/${values.country_code}/${
                      //   //   values.phone
                      //   // }/${
                      //   //   values.country_code === DEFAULT_COUNTRY_CODE
                      //   //     ? 'phone'
                      //   //     : 'email'
                      //   // }`,
                      // );
                    } else if (res.success && res.auth_type === 'email') {
                      // history.push(
                      //   `/forgotpass/resetconfirmcode/0/${values.email}/email`,
                      // );
                      handleForgotPassData({
                        code: '0',
                        emailOrPhone: values.email,
                        type: 'email',
                      });
                      // setResetData({
                      //   code: '0',
                      //   emailOrPhone: values.email,
                      //   type: 'email',
                      // });
                      // getDataForReset(resetData);

                      handleNextClick();
                    }
                  },
                },
              );
            }}
          >
            {({
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <Box
                component="form"
                noValidate
                id="forgot_pass_form"
                onSubmit={handleSubmit}
                sx={{ mt: 2, maxWidth: '20rem', margin: '0 auto' }}
              >
                {mutation.data && mutation.data.error && (
                  <Alert severity="error" sx={{ mb: 1 }}>
                    {mutation.data.error}
                  </Alert>
                )}
                {/* <TextField
                  fullWidth
                  error={Boolean(touched.phone && errors.phone)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Phone"
                  name="phone"
                />
                {touched.phone && errors.phone && (
                  <FormHelperText error>{errors.phone}</FormHelperText>
                )} */}
                {isMobileNumberLogin && (
                  <>
                    <MuiPhoneNumber
                      sx={{
                        mt: 1,
                        '& .MuiPhoneNumber-flagButton': { width: '30px' },
                      }}
                      fullWidth
                      label="Phone"
                      name="phone"
                      defaultCountry={
                        siteInfo && siteInfo.default_country
                          ? siteInfo.default_country
                          : 'in'
                      }
                      disableAreaCodes={true}
                      disableCountryCode={false}
                      autoFormat={true}
                      error={Boolean(
                        touched.phone && (errors.phone || errors.mobile),
                      )}
                      onBlur={handleBlur}
                      onChange={(
                        formattedNumber:
                          | string
                          | React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                        country?: {
                          name: string;
                          dialCode: string;
                          countryCode: string;
                        },
                      ) => {
                        if (country) {
                          const phoneData = parseFormattedPhoneNumber(
                            formattedNumber as string,
                            country.dialCode,
                          );
                          setFieldValue('phone', phoneData.phone, false);
                          setFieldValue(
                            'country_code',
                            phoneData.countryCode,
                            false,
                          );
                          setFieldValue(
                            'mobile',
                            '+' + phoneData.countryCode + phoneData.phone,
                            false,
                          );
                        }
                      }}
                      variant="outlined"
                    />
                    {touched.phone && errors.phone && (
                      <FormHelperText error>{errors.phone}</FormHelperText>
                    )}
                    {touched.phone && !errors.phone && errors.mobile && (
                      <FormHelperText error>{errors.mobile}</FormHelperText>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <Divider
                        sx={{
                          flex: 1,
                          borderColor: theme => theme.palette.grey[300],
                        }}
                      />
                      <Typography
                        variant="overline"
                        sx={{ mx: 1, textTransform: 'lowercase' }}
                      >
                        <FormattedMessage id="or" />
                      </Typography>
                      <Divider
                        sx={{
                          flex: 1,
                          borderColor: theme => theme.palette.grey[300],
                        }}
                      />
                    </Box>
                  </>
                )}
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: 1 }}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-email">
                    {errors.email}
                  </FormHelperText>
                )}
              </Box>
            )}
          </Formik>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          mb={2}
        >
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="primary"
            form="forgot_pass_form"
            type="submit"
            fullWidth={true}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="next" />
          </MuiButton>
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="secondary"
            fullWidth={true}
            // component={RouterLink}
            // to={undefined}
            onClick={() => handleCancelClick()}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="cancel" />
          </MuiButton>
        </Box>
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default ForgotPassPage;
