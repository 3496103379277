import ReportFilter from './ReportFilter';
// import { MarketplaceSearchParams } from 'types/ApiServicesTypes';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { Box } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import CloseIcon from '@mui/icons-material/Close';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import {
  GetReportDropDownData,
  ReportSearchParams,
} from 'types/ApiServicesTypes';
import { memo } from 'react';
// type ReportSearchParams = {
//   priceRange?: string;
//   riskTypes?: string[];
//   search?: string;
//   subscriptionType?: string;
//   tags: string[];
//   exchanges: string[];
//   categories: string[];
// };
function ReportFilterModal({
  open,
  filters,
  handleApplyFilters,
  handleClose,
  handleApplyFilterCount,
  filterMetaData,
}: {
  filters: ReportSearchParams;
  handleApplyFilters: (filters: ReportSearchParams) => void;
  open: boolean;
  handleClose: () => void;
  handleApplyFilterCount: (count: number) => void;
  filterMetaData: GetReportDropDownData | undefined;
}) {
  return (
    <MuiModal
      open={open}
      handleClose={handleClose}
      isFullScreen
      header={
        <Box sx={{ pt: 2 }}>
          <PageHeader
            variant="withIcon"
            title={'Filter'}
            iconProps={{
              handleClick: handleClose,
              icon: <CloseIcon fontSize="small" />,
            }}
          />
          <Box className="p-main-spacing">
            <ItemSeparator variant="2px" />
          </Box>
        </Box>
      }
      body={
        <ReportFilter
          isMobile
          handleClose={handleClose}
          filters={filters}
          handleAppyFilter={handleApplyFilters}
          handleApplyFilterCount={handleApplyFilterCount}
          filterMetaData={filterMetaData}
        />
      }
    />
  );
}

export default memo(ReportFilterModal);
