import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { ChevronRightOutlined } from '@mui/icons-material';
import BotDeployModal from 'app/components/Bots/BotDetail/BotDeployModal';
import BotPaperTestModal from 'app/components/Bots/BotDetail/BotPaperTestModal';
import ToastrAlert from 'app/design/uiComponents/ToastrAlert';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import { getDate } from 'utils/GenericFunctions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BotStatusAction = ({ search }: { search: string }) => {
  const breadcrumb = useBreadcrumbManager();
  const { bid, sid, title } =
    useParams<{ bid: string; sid: string; title: string }>();

  const history = useHistory();
  const { t } = useTranslation();
  const botId = parseInt(bid);
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const titleText = isNaN(parseInt(sid)) ? sid : title;
  const isFromPortfolio = breadcrumb.isPageExist(PAGES.PORTFOLIO);

  const { data: botDetails /* refetch */ } = useGetBotDetails(botId, subId);

  const [message, setMessage] = useState<string>('');
  const [openDeployModal, setOpenDeployModal] = useState<boolean>(false);
  const [openPaperModal, setOpenPaperModal] = useState<boolean>(false);
  const [skipTerms, setSkipTerms] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeployModalOpen = () => {
    setMessage('');
    setOpenDeployModal(true);
  };
  const handleDeployModalClose = (isSuccess: boolean, message?: string) => {
    // if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenDeployModal(false);
    setSkipTerms(false);
  };

  const handlePaperModalOpen = () => {
    setMessage('');
    setOpenPaperModal(true);
  };
  const handlePaperModalClose = (isSuccess: boolean, message?: string) => {
    // if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenPaperModal(false);
  };

  const isLiveOrPaper =
    botDetails &&
    (botDetails.category === 'paper' || botDetails.category === 'active');

  const isMdUp = useGetMediaQueryUp('md');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('action')) {
      if (queryParams.get('action') === 'deploy') {
        setSkipTerms(true);
        setOpenDeployModal(true);
      }
      queryParams.delete('action');
    }
    if (queryParams.has('successMessage')) {
      setMessage(queryParams.get('successMessage') || '');
      queryParams.delete('successMessage');
    }
    history.replace({
      search: queryParams.toString(),
    });
  }, [history]);

  return (
    <>
      <ToastrAlert type="success" message={message} setMessage={setMessage} />
      {botDetails && (
        <Box className="m-main-spacing" sx={{ mb: { md: 3 } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { md: 'center' },
              justifyContent: { md: 'end' },
            }}
          >
            {isLiveOrPaper && (
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ flex: { md: 1 }, mb: { md: 0, xs: 1 } }}
              >
                <Typography
                  variant="body2"
                  fontWeight={400}
                  color="text.primary"
                >
                  <FormattedMessage id="bot_status.started" />
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  {botDetails.start_date
                    ? getDate(botDetails.start_date, false)
                    : '-'}
                </Typography>
              </Box>
            )}
            {isLiveOrPaper &&
              titleText !== t('portfolio.page_title.portfolio') && (
                <MuiButton
                  size="xlarge"
                  fullWidth
                  endIcon={<ChevronRightOutlined />}
                  variant="outlined"
                  color="primary"
                  sx={{
                    justifyContent: 'space-between',
                    mt: { xs: 1, md: 0 },
                    mb: { xs: 3, md: 0 },
                    ml: { md: 1 },
                    maxWidth: { md: '192px' },
                  }}
                  // component={RouterLink}
                  // to={`/portfolio/${botDetails.category}/${sid}`}
                  onClick={() =>
                    breadcrumb.push(
                      `/portfolio/${botDetails.category}/${sid}`,
                      PAGES.BOT_DETAIL,
                      `/bots/${botId}/details${
                        subId ? `/${subId}` : ''
                      }/${titleText}`,
                      isFromPortfolio
                        ? t('buttons.more_info')
                        : botDetails.name,
                    )
                  }
                >
                  {botDetails.category === 'active' ? (
                    <FormattedMessage id="bot_status.live" />
                  ) : (
                    <FormattedMessage id="bot_status.paper" />
                  )}
                </MuiButton>
              )}
            {(!botDetails.category || botDetails.category === 'inactive') && (
              <>
                <FeatureFlag
                  features={[FEATURES.IS_PAPER_TRADING]}
                  fallback={<></>}
                >
                  <MuiButton
                    fullWidth
                    size={isMdUp ? 'xlarge' : 'medium'}
                    variant="outlined"
                    onClick={() => handlePaperModalOpen()}
                    sx={{
                      mb: { xs: 3, md: 0 },
                      ml: { md: 1 },
                      maxWidth: { md: '192px' },
                    }}
                  >
                    <FormattedMessage id="buttons.paper" />
                  </MuiButton>
                </FeatureFlag>
                <FeatureFlag features={[FEATURES.IS_GOLIVE]} fallback={<></>}>
                  <MuiButton
                    fullWidth
                    size={isMdUp ? 'xlarge' : 'medium'}
                    variant="contained"
                    onClick={() => handleDeployModalOpen()}
                    sx={{
                      mb: { xs: 3, md: 0 },
                      ml: { md: 1 },
                      maxWidth: { md: '192px' },
                    }}
                  >
                    <FormattedMessage id="buttons.deploy" />
                  </MuiButton>
                </FeatureFlag>
              </>
            )}
            {botDetails.category === 'paper' && (
              <FeatureFlag features={[FEATURES.IS_GOLIVE]} fallback={<></>}>
                <MuiButton
                  fullWidth
                  size={isMdUp ? 'xlarge' : 'medium'}
                  variant="contained"
                  sx={{
                    mb: { xs: 3, md: 0 },
                    ml: { md: 1 },
                    maxWidth: { md: '192px' },
                  }}
                  onClick={() => handleDeployModalOpen()}
                >
                  <FormattedMessage id="buttons.deploy" />
                </MuiButton>
              </FeatureFlag>
            )}
          </Box>
        </Box>
      )}
      <BotDeployModal
        open={openDeployModal}
        handleClose={handleDeployModalClose}
        // id={parseInt(id)}
        exchanges={botDetails?.exchanges}
        botName={botDetails ? botDetails.name : ''}
        botId={botId}
        openFrom="bots"
        skipTerms={skipTerms}
      />
      <BotPaperTestModal
        open={openPaperModal}
        id={parseInt(bid)}
        sid={subId}
        botName={botDetails ? botDetails.name : ''}
        handleClose={handlePaperModalClose}
        openFrom="bots"
      />
    </>
  );
};

export default BotStatusAction;
