import { useTranslation } from 'react-i18next';
import { PositionItemProps } from './types';
import { Box, Checkbox, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MuiChip from '../../uiComponents/MuiChip';
import {
  formatNumber,
  decimalFormat,
  toTitleCase,
  getQueryParam,
} from 'utils/GenericFunctions';
import { MCXSYMBOLEMULTIPLAYER } from 'types/Position';

const TodaysPositionItem = ({
  position,
  handleClick,
  currentPrice,
  //   profitLoss,
  //   percentChange,
  demo,
  closed,
  isVisibleCheckbox,
  isChecked,
}: PositionItemProps) => {
  const { t } = useTranslation();
  //   if (!profitLoss) {
  //     profitLoss = position.profit_loss - position.totalfees;
  //   }
  //   if (!percentChange) {
  //     percentChange =
  //       (profitLoss * 100) / (position.entry_Price * position.quantity);
  //   }
  const queryParam = getQueryParam('ptype');
  const isBuy = position.direction === '0';
  const multiplier =
    (MCXSYMBOLEMULTIPLAYER[position.instrument_id] as number) || 1;

  const profitLoss =
    currentPrice &&
    (currentPrice === 0
      ? position.profit_loss
      : isBuy
      ? (currentPrice - position.entry_Price) *
        Math.abs(position.quantity) *
        multiplier
      : (position.entry_Price - currentPrice) *
        Math.abs(position.quantity) *
        multiplier);
  const percentChange =
    currentPrice &&
    (currentPrice === 0
      ? position.percentage_change
      : isBuy
      ? ((currentPrice - position.entry_Price) / position.entry_Price) * 100
      : ((position.entry_Price - currentPrice) / position.entry_Price) * 100);
  return (
    <Box
      className="p-main-spacing"
      sx={{
        py: 2,
        cursor: !demo ? 'pointer' : 'default',
        position: 'relative',
        ...(isChecked && { backgroundColor: 'grey.100' }),
        display: 'flex',
      }}
      {...(!demo && { onClick: () => handleClick!(position) })}
    >
      {isVisibleCheckbox && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '8px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Checkbox
            checked={isChecked}
            onClick={() => handleClick!(position)}
            sx={{ p: 0 }}
            disabled
          />
        </Box>
      )}
      <Box
        flex={1}
        sx={{
          ml: isVisibleCheckbox ? 2 : 0,
          transition: 'margin 0.25s ease-in-out',
        }}
      >
        <Box display="flex" alignItems="center">
          <Box sx={{ flex: 1 }}>
            <Typography
              component="div"
              variant="body1"
              color="text.primary"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <span>
                {!demo
                  ? position.trading_symbol
                  : t('todays_position.help.pos_item.symbol')}
              </span>
              <MuiChip
                label={!demo ? (position.direction === '0' ? 'L' : 'S') : 'D'}
                color={
                  !demo
                    ? position.direction === '0'
                      ? 'infolight'
                      : 'purplelight'
                    : 'greylight'
                }
                size="singleLetter"
                variant="filled"
                sx={{
                  ml: 1,
                }}
              />
            </Typography>
            {queryParam && (
              <Typography variant="caption">{position.bot_name}</Typography>
            )}
          </Box>

          <Box
            sx={{
              flex: 'inherit',
              textAlign: 'right',
              whiteSpace: 'nowrap',
              pl: 2,
            }}
          >
            {queryParam && (
              <MuiChip
                sx={{ mb: 0.5 }}
                label={toTitleCase(position.status)}
                size="xsmall"
                color={
                  position.status === 'open' ? 'successlight' : 'errorlight'
                }
              />
            )}
            {/* <Typography>{position.status}</Typography> */}
            <Typography
              variant="body2"
              color={
                !demo
                  ? profitLoss === 0
                    ? 'primary.main'
                    : profitLoss && profitLoss > 0
                    ? 'success.main'
                    : 'error.main'
                  : 'success.main'
              }
            >
              {!demo
                ? profitLoss === 0
                  ? `${position.currency_symbol}${formatNumber(
                      profitLoss,
                      false,
                    )}`
                  : profitLoss && profitLoss > 0
                  ? `▲ ${position.currency_symbol}${formatNumber(
                      profitLoss,
                      false,
                    )}`
                  : `▼ ${position.currency_symbol}${formatNumber(
                      profitLoss!,
                      false,
                    )}`
                : t('todays_position.help.pos_item.current_value')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
          <Box display="flex" sx={{ flex: 1 }} alignItems="center">
            <Typography
              variant="body3"
              fontWeight={500}
              color={
                !demo
                  ? position.direction === '0'
                    ? 'info.main'
                    : 'purple.500'
                  : 'text.primary'
              }
            >
              {!demo
                ? position.quantity
                : t('todays_position.help.pos_item.quantity')}
            </Typography>
            {!demo ? (
              position.exit_price && position.exit_price > 0 ? undefined : (
                <Typography variant="body3" sx={{ ml: 0.5 }} fontWeight={500}>
                  {position.entry_Price
                    ? `• ${position.currency_symbol}${formatNumber(
                        position.entry_Price,
                      )}`
                    : ''}
                </Typography>
              )
            ) : !closed ? (
              <Typography variant="body3" sx={{ ml: 0.5 }}>
                • {t('todays_position.help.pos_item.entry_price')}
              </Typography>
            ) : (
              ''
            )}
            {position.tag && (
              <MuiChip
                label={position.tag}
                size="xsmallicon"
                color={
                  !demo
                    ? position.direction === '0'
                      ? 'infolight'
                      : 'secondary'
                    : 'greylight'
                }
                sx={{ ml: 1, txetAlign: 'center', minWidth: '2.5rem' }}
              />
            )}
            {position.trade_id && (
              <Typography variant="body3" sx={{ ml: 1 }}>
                {position.trade_id}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flex: 'inherit',
              whiteSpace: 'nowrap',
              textAlign: 'right',
              pl: 2,
            }}
          >
            {!demo ? (
              position.exit_price > 0 && (
                <>
                  <Typography
                    variant="body3"
                    fontWeight={500}
                    color="primary.light"
                    sx={{ letterSpacing: '0.4px', lineHeight: '20px' }}
                  >
                    {position.entry_Price
                      ? `${position.currency_symbol}${formatNumber(
                          position.entry_Price,
                        )}`
                      : ''}
                  </Typography>
                  {position.entry_Price && (
                    <ArrowForwardIcon
                      sx={{ fontSize: '14px', ml: 0.5, color: 'primary.light' }}
                    />
                  )}
                </>
              )
            ) : !closed ? (
              ''
            ) : (
              <>
                <Typography
                  variant="body3"
                  fontWeight={500}
                  color="primary.light"
                  sx={{ letterSpacing: '0.4px', lineHeight: '20px' }}
                >
                  {t('todays_position.help.pos_item.entry_price')}
                </Typography>
                <ArrowForwardIcon
                  sx={{ fontSize: '14px', ml: 0.5, color: 'primary.light' }}
                />
              </>
            )}
            {!demo ? (
              position.exit_price > 0 && (
                <Typography
                  variant="body3"
                  fontWeight={500}
                  sx={{ ml: 0.5, letterSpacing: '0.4px', lineHeight: '20px' }}
                  color="primary.dark"
                >
                  {position.exit_price
                    ? `${position.currency_symbol}${formatNumber(
                        position.exit_price,
                      )}`
                    : ''}
                </Typography>
              )
            ) : !closed ? (
              <Typography
                variant="body3"
                fontWeight={500}
                sx={{ ml: 0.5, letterSpacing: '0.4px', lineHeight: '20px' }}
                color="text.primary"
              >
                {t('todays_position.help.pos_item.current_price')}
              </Typography>
            ) : (
              <Typography
                variant="body3"
                fontWeight={500}
                sx={{ ml: 0.5, letterSpacing: '0.4px', lineHeight: '20px' }}
                color="text.primary"
              >
                {t('todays_position.help.pos_item.exit_price')}
              </Typography>
            )}
            {!demo
              ? position.exit_price === 0 && (
                  <Typography
                    variant="body3"
                    fontWeight={500}
                    sx={{ ml: 0.5, letterSpacing: '0.4px', lineHeight: '20px' }}
                    color="primary.dark"
                  >
                    {currentPrice! > 0
                      ? `${position.currency_symbol}` +
                        formatNumber(currentPrice!)
                      : position.entry_Price
                      ? `${position.currency_symbol}` +
                        formatNumber(position.entry_Price)
                      : ''}
                  </Typography>
                )
              : undefined}
            {!demo ? (
              profitLoss && (percentChange || percentChange === 0) ? (
                <Typography
                  color={
                    profitLoss === 0
                      ? 'primary.main'
                      : profitLoss > 0
                      ? 'success.main'
                      : 'error.main'
                  }
                  variant="body3"
                  sx={{ ml: 0.5, letterSpacing: '0.4px' }}
                >
                  {`(${decimalFormat(percentChange)}%)`}
                </Typography>
              ) : undefined
            ) : (
              <Typography
                color="success.main"
                variant="body3"
                sx={{ ml: 0.5, letterSpacing: '0.4px' }}
              >
                (% {t('todays_position.help.pos_item.change')})
              </Typography>
            )}
          </Box>
        </Box>
        {/* <MuiChip
              sx={{ padding: 0, mb: 0.5 }}
              label={position.bot_name}
              color="greyselected"
              variant="outlined"
              size="small"
            /> */}
      </Box>
    </Box>
  );
};

export default TodaysPositionItem;
