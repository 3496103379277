import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useEffect } from 'react';
import { getQueryParams } from 'utils/GenericFunctions';
import useAuthenticateWithApple from 'services/Auth/useAuthenticateWithApple';
import { useDoSingIn } from 'services/Auth';
import { UserInfo } from 'types/User';
import { Alert, Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function AppleOAuthPage() {
  const history = useHistory();
  const { doSignIn } = useDoSingIn();
  const appleAuthLogin = useAuthenticateWithApple();
  const isHomePage = useIsFeatureFlag([FEATURES.IS_HOME_PAGE]);
  const isTemplates = useIsFeatureFlag([FEATURES.IS_TEMPLATES]);

  useEffect(() => {
    const { code, id_token, bundle_id } = getQueryParams([
      'code',
      'id_token',
      'bundle_id',
    ]);
    appleAuthLogin.mutate(
      { code, id_token, bundle_id },
      {
        onSuccess: res => {
          if (res) {
            if (res.session_id) {
              doSignIn({ uid: res.uid } as UserInfo);
            } else if (res.email) {
              history.push(`/portfolio`);
            }
          }
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Apple Auth</title>
        <meta name="description" content="Apple Auth Page" />
      </Helmet>
      <CircularLoader open={appleAuthLogin.isLoading} />
      {appleAuthLogin.isSuccess && appleAuthLogin.data.error && (
        <Box sx={{ textAlign: 'left', mt: 5 }}>
          <Alert severity="error">
            <Typography variant="body2" fontWeight={600}>
              We encountered an issue while attempting to sign you in with
              Apple. It seems there was an error during the authentication
              process.
            </Typography>
            <Typography variant="body2" mt={2}>
              If you are facing this issue again and again then, please follow
              these steps:
            </Typography>

            <ol>
              <li>
                Open your device settings and navigate to &quot;<b>Apple ID</b>
                &quot; or &quot;<b>iCloud</b>
                &quot;.
              </li>
              <li>
                Go to &quot;<b>Password & Security</b>
                &quot;.
              </li>
              <li>
                Go to &quot;<b>Sign in with Apple</b>
                &quot;, revoke the access for the application you are trying to
                sign in to.
              </li>
              <li>
                Retry the sign-in process on our platform, and you should be
                prompted to reauthorize.
              </li>
            </ol>

            <p>If the issue persists, consider the following:</p>

            <ul>
              <li>Ensure that your Apple ID credentials are correct.</li>
              <li>Check your internet connection.</li>
            </ul>

            <p>
              If the problem continues, contact our support team for assistance.
            </p>

            <p>Thank you for your understanding.</p>
          </Alert>

          <MuiButton
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() =>
              history.push(
                isHomePage
                  ? '/home?action=login'
                  : isTemplates
                  ? '/templates?action=login'
                  : 'signin',
              )
            }
          >
            Go Back
          </MuiButton>
        </Box>
      )}
    </>
  );
}

export default AppleOAuthPage;
