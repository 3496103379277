import { memo } from 'react';
import { Typography, Box } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';

// Service
import { PostBotActionParams } from 'types/ApiServicesTypes';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import usePostBotAction from 'services/Bots/usePostBotAction';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import { useTranslation } from 'react-i18next';

const BotRestartModal = ({
  open,
  // openFrom,
  bid,
  botName,
  handleClose,
  category,
}: {
  open: boolean;
  openFrom: string;
  bid: number;
  botName: string;
  handleClose: (isSuccess, message?: string) => void;
  category: string;
}) => {
  const { t } = useTranslation();

  let subscriptionCategory = '';

  const mutation = usePostBotAction(bid, 'discover');

  const handleRestartClick = () => {
    subscriptionCategory = category;
    const objReq: PostBotActionParams = {
      type: 'restart',
      multiplier: 1,
    };
    mutation.mutate(objReq);
  };

  if (mutation.isSuccess && open) {
    // handleClose(true, 'Bot is successfully restarted');

    // Log entry for Restart bot
    logFirebaseEventEntry('BOT_RESTART_' + subscriptionCategory.toUpperCase(), {
      bot_id: bid,
    });

    handleClose(true, t('bot_restart_modal.success_message'));
    mutation.reset();
  }

  if (mutation.error && open) {
    mutation.reset();
  }

  const handleCloseModal = () => {
    handleClose(true);
  };

  return (
    <>
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <>
            <Box className="m-main-spacing">
              <Typography variant="body1" sx={{ pb: 4, fontWeight: 400 }}>
                <FormattedMessage id="bot_restart_modal.are_you_sure" />{' '}
                <b>{botName}</b>?
              </Typography>
            </Box>
          </>
        }
        footer={
          <>
            <MuiLoadingButton
              fullWidth
              variant="outlined"
              color="error"
              sx={{ mb: 2 }}
              onClick={handleRestartClick}
              loading={mutation.isLoading}
              loadingPosition="start"
            >
              <FormattedMessage id="buttons.restart" />
            </MuiLoadingButton>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </>
        }
      />
    </>
  );
};

export default memo(BotRestartModal);
