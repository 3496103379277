import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, TextField, FormHelperText } from '@mui/material';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// service
import MuiButton from 'app/design/uiComponents/MuiButton';
import useResetPass from 'services/Auth/useResetPass';
import { ResetPageProps } from './SignInModal';
import SpeedBotLogo from '../Common/SpeedBotLogo';

export function ResetPassPage({
  propsData,
  onAuthPageChange,
}: {
  propsData: ResetPageProps;
  onAuthPageChange: (view: string) => void;
}) {
  // const { ccode, phone, otp } =
  //   useParams<{ ccode: string; phone: string; otp: string }>();
  const { code, emailOrPhone, otp } = propsData;
  const formValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(5, 'Password must be at least 5 characters'),
    confirmPass: Yup.string()
      .required('Confirm password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Password and Confirm Password must match',
      ),
  });
  const handleSignInClick = () => {
    onAuthPageChange('Sign In');
  };
  const mutation = useResetPass();

  return (
    <React.Fragment>
      <Helmet>
        <title>Signup Verify Phone Page</title>
        <meta name="description" content="Signup verify phone page" />
      </Helmet>
      <CircularLoader open={mutation.isLoading} />
      <SigninPageContentLayout>
        {/* Must have max two child element */}
        <Box>
          <SpeedBotLogo />

          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mt: 4, textAlign: 'center' }}
          >
            <FormattedMessage id="reset_your_pass" />
          </Typography>
          <Formik
            initialValues={{
              password: '',
              confirmPass: '',
            }}
            validationSchema={formValidationSchema}
            onSubmit={values => {
              mutation.mutate(
                {
                  otp,
                  action: 'reset',
                  mobile: emailOrPhone,
                  auth_type: code === '0' ? 'email' : 'mobile',
                  country_code: code,
                  email: emailOrPhone,
                  password: values.password,
                },
                {
                  onSuccess: res => {
                    if (res.success) {
                      // history.push({
                      //   pathname: `/signin`,
                      //   state: { msg: 'Password reset successful. Login now.' },
                      // });
                      handleSignInClick();
                    }
                  },
                },
              );
            }}
          >
            {({ errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                id="set_new_password"
                sx={{ mt: 2, maxWidth: '20rem', margin: '4px auto' }}
              >
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  autoComplete="on"
                  error={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  name="confirmPass"
                  autoComplete="on"
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.confirmPass && errors.confirmPass && (
                  <FormHelperText error>{errors.confirmPass}</FormHelperText>
                )}
                {mutation.data && mutation.data.error && (
                  <FormHelperText error>{mutation.data.error}</FormHelperText>
                )}
              </Box>
            )}
          </Formik>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="primary"
            fullWidth={true}
            form="set_new_password"
            type="submit"
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="next" />
          </MuiButton>
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="secondary"
            fullWidth={true}
            // component={}
            // to="/signin"
            onClick={() => handleSignInClick()}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="cancel" />
          </MuiButton>
        </Box>
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default ResetPassPage;
