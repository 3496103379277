import moment from 'moment';
import { ProfitLossByDate } from 'types/ApiServicesTypes';
import { formatStringToDateString } from 'utils/GenericFunctions';
import { MonthYearData, ProfitLossByDateTransform } from './types';

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);

  const now = toDate.clone(),
    months: MonthYearData[] = [];

  while (now.format('MM/YYYY') !== fromDate.format('MM/YYYY')) {
    months.push({
      month: parseInt(now.format('MM')),
      year: parseInt(now.format('YYYY')),
    });
    now.subtract(1, 'months');
  }
  months.push({
    month: parseInt(fromDate.format('MM')),
    year: parseInt(fromDate.format('YYYY')),
  });

  return months;
};

const getColorForPercentage = (percentage: number, profitLoss: number) => {
  let opacity = 1;
  if (Math.abs(percentage) <= 20) opacity = 0.2;
  else if (Math.abs(percentage) <= 40) opacity = 0.4;
  else if (Math.abs(percentage) <= 60) opacity = 0.6;
  else if (Math.abs(percentage) <= 80) opacity = 0.8;

  return { color: profitLoss >= 0 ? 'green' : 'red', opacity };
};

export const getDateDataByMonth = (
  data: ProfitLossByDateTransform[],
  month: number,
  year: number,
) => {
  return data.filter(e => {
    const [yr, mnth] = e.date.split('-');
    return month === parseInt(mnth) && year === parseInt(yr);
  });
};

export const transformProfitLossByDateData = (
  data: ProfitLossByDate[],
): ProfitLossByDateTransform[] => {
  //   const posData = data.filter(x => x.profit_loss >= 0);
  //   const negData = data.filter(x => x.profit_loss < 0);
  //   const minPLPos = Math.min(...posData.map(x => x.profit_loss));
  //   const maxPLPos = Math.max(...posData.map(x => x.profit_loss));
  //   const minPLNeg = Math.min(...negData.map(x => x.profit_loss));
  //   const maxPLNeg = Math.max(...negData.map(x => x.profit_loss));

  const min = Math.min(...data.map(x => Math.abs(x.profit_loss)));
  const max = Math.max(...data.map(x => Math.abs(x.profit_loss)));
  const minPLPos = min;
  const maxPLPos = max;
  const minPLNeg = max === 0 ? 0 : max * -1;
  const maxPLNeg = min === 0 ? 0 : min * -1;

  const resultData: ProfitLossByDateTransform[] = data.map(x => {
    // Get percentage which is return 20 to 100
    const profitLossPercentage =
      x.profit_loss === 0
        ? 0
        : x.profit_loss > 0
        ? 20 + ((x.profit_loss - minPLPos) / (maxPLPos - minPLPos)) * 80
        : 20 + ((x.profit_loss - maxPLNeg) / (minPLNeg - maxPLNeg)) * 80;

    const data: ProfitLossByDateTransform = {
      ...x,
      date: formatStringToDateString(x.date, 'DD MMM YYYY', 'YYYY-MM-DD'),
      profitLossPercentage: profitLossPercentage,
      color: getColorForPercentage(profitLossPercentage, x.profit_loss),
    };
    return data;
  });

  return resultData;
};
