import i18next from 'i18next';
import { Box, Typography } from '@mui/material';
import { GetPortfolioBotDetailScripts } from 'types/ApiServicesTypes';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
  // TableRowsData,
} from 'app/components/Common/CommonTable/type';
import { SymbolsData } from 'types/Symbols';
import CommonTable from 'app/components/Common/CommonTable';
import {
  formatNumber,
  getArrowDirByNum,
  getColorByNum,
} from 'utils/GenericFunctions';

type SymbolsTableProps = {
  symbols: GetPortfolioBotDetailScripts[];
  liveFeedData: SymbolsData;
};

export const createSymbolsTableRows = (
  symbols: GetPortfolioBotDetailScripts[],
  liveFeedData: SymbolsData,
): { rows: TableRow<unknown>[]; columns: TableColumns[] } => {
  const rows: TableRow<unknown>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.symbol') },
    { text: i18next.t('summary_labels.company_name') },
    { text: i18next.t('summary_labels.price') },
    { text: i18next.t('summary_labels.change') },
    { text: `% ${i18next.t('summary_labels.change')}` },
  ];

  symbols.map(symbol => {
    rowCellsData = [];
    const symbolValue = liveFeedData[symbol.symbol] || 0;
    const symbolClose = Number(symbolValue.Close) || 0;
    const symbolCloseDiff = symbolClose - Number(symbolValue.LastClose) || 0;

    rowCellsData = [
      {
        content: symbol.symbol,
      },
      {
        content: symbol.company_name,
      },
      {
        content: (
          <Typography
            color={getColorByNum(symbolCloseDiff)}
            variant="body2"
          >{`${getArrowDirByNum(symbolCloseDiff)} ${formatNumber(
            symbolClose,
          )}`}</Typography>
        ),
      },
      {
        content: (
          <Typography variant="body2" color="text.primary">
            {symbolCloseDiff > 0 ? '+' : symbolCloseDiff < 0 ? '-' : ''}
            {formatNumber(symbolCloseDiff)}
          </Typography>
        ),
      },
      {
        content: (
          <Typography variant="body2" color={'text.primary'}>{`${formatNumber(
            (symbolCloseDiff / symbolClose) * 100,
          )}%`}</Typography>
        ),
      },
    ];

    rows.push({ rowCellsData: rowCellsData, rowData: [] });
  });

  return { rows, columns };
};

const SymbolsTable = ({ symbols, liveFeedData }: SymbolsTableProps) => {
  const { rows, columns } = createSymbolsTableRows(symbols, liveFeedData);
  return (
    <>
      {symbols.length > 0 && (
        <Box sx={{ px: 3, mx: 1 }}>
          <CommonTable rows={rows} columns={columns} />
        </Box>
      )}
    </>
  );
};

export default SymbolsTable;
