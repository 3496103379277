import { useQuery } from 'react-query';
import { GetOptionHelpData } from 'services/apiServices';
import { GetOptionHelpResult } from 'types/ApiServicesTypes';

export default function useGetOptionBuilderHelpData() {
  const objQuery = useQuery<GetOptionHelpResult>(
    ['GetOptionBotHelpData'],
    async () => {
      const data = await GetOptionHelpData();
      return data;
    },
    {
      staleTime: 2 * 60 * 60 * 1000,
    },
  );
  return objQuery;
}
