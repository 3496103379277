import {
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  OutlinedInput,
  Box,
} from '@mui/material';
import * as Yup from 'yup';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useEffect } from 'react';
import { manipulateAddBrokerRef } from './util';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { GetBrokersResult } from 'types/ApiServicesTypes';
const botLiveDurations = [
  '1 Week',
  '2 Weeks',
  '3 Weeks',
  '4 Weeks',
  '1 Month',
  '2 Months',
  '3 Months',
  '6 Months',
  '12 Months',
];
const BotPaperDurationModal = ({
  saveData,
  brokersData,
}: {
  saveData: (value) => void;
  brokersData?: GetBrokersResult;
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        duration: '1 Week',
        durationType: 'Weeks',
        amount: '',
      },
      onSubmit: () => {
        // saveData(values);
      },
      validationSchema: Yup.object().shape({
        duration: Yup.string().required(
          t('bot_deploy_modal.valid_msg.req', { type: 'Duration' }),
        ),
      }),
    });
  useEffect(() => {
    saveData(values);
  }, [saveData, values]);
  //   const { data: brokersData } = useGetBrokers();
  return (
    <Box>
      {!brokersData || brokersData?.length === 0 ? (
        <Alert severity="error" sx={{ mb: 4 }}>
          {/* <FormattedMessage id="bot_deploy_modal.link_your_broker_account" /> */}
          You need to add at least one broker account to use the Forward Testing
          feature. Please configure your broker account from{' '}
          <RouterLink
            to="/brokers/new"
            onClick={() => {
              manipulateAddBrokerRef('set', '?action=deploy');
            }}
            style={{ textDecoration: 'underline' }}
          >
            <FormattedMessage id="bot_deploy_modal.here" />
          </RouterLink>
        </Alert>
      ) : (
        <></>
      )}

      <FormControl
        fullWidth
        sx={{ mb: 4 }}
        error={Boolean(touched.duration && errors.duration)}
      >
        <InputLabel id="label-duration">
          <FormattedMessage id="bot_deploy_modal.duration" />
        </InputLabel>
        <Select
          labelId="label-duration"
          name="duration"
          value={values.duration}
          label={<FormattedMessage id="bot_deploy_modal.duration" />}
          onChange={e => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            setFieldValue('duration', e.target.value);
          }}
        >
          {botLiveDurations.map((duration, i) => (
            <MenuItem value={duration} key={i}>
              {duration}
            </MenuItem>
          ))}
          <MenuItem value="Manually">
            <FormattedMessage id="bot_deploy_modal.set_manually" />
          </MenuItem>
        </Select>
        {touched.duration && errors.duration && (
          <FormHelperText error>{errors.duration}</FormHelperText>
        )}
      </FormControl>
      {values.duration === 'Manually' && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={Boolean(touched.amount && errors.amount)}
              variant="outlined"
              sx={{ mb: 4 }}
            >
              <InputLabel>
                <FormattedMessage id="bot_deploy_modal.duration_count" />
              </InputLabel>
              <OutlinedInput
                name="amount"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                label={
                  <FormattedMessage id="bot_deploy_modal.duration_count" />
                }
                inputProps={{ min: 1 }}
              />
              {touched.amount && errors.amount && (
                <FormHelperText error>{errors.amount}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <InputLabel id="label-durationType">
                <FormattedMessage id="bot_deploy_modal.duration_type" />
              </InputLabel>
              <Select
                labelId="label-durationType"
                name="durationType"
                value={values.durationType}
                label={<FormattedMessage id="bot_deploy_modal.duration_type" />}
                onChange={e => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  setFieldValue('durationType', e.target.value);
                }}
              >
                <MenuItem value="Days">
                  <FormattedMessage id="bot_deploy_modal.days" />
                </MenuItem>
                <MenuItem value="Weeks">
                  <FormattedMessage id="bot_deploy_modal.weeks" />
                </MenuItem>
                <MenuItem value="Months">
                  <FormattedMessage id="bot_deploy_modal.months" />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BotPaperDurationModal;
