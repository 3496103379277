import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  TextField,
} from '@mui/material';
import {
  BrokerFormField,
  GetStrategyParameterKeyValue,
} from 'types/ApiServicesTypes';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState, useEffect } from 'react';
import { getCookie } from 'typescript-cookie';
import useGetStrategyParameterUrl from 'services/Bots/useGetStrategyParameterUrl';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Combobox = ({
  fieldData,
  handleChange,
  field,
}: {
  fieldData: BrokerFormField;
  handleChange: (selectedValues?: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any;
}) => {
  const isUrl =
    fieldData && fieldData.url && fieldData.url.length ? true : false;
  const exchange = getCookie('exchange');
  const exchangeIfUndefined = exchange
    ? exchange
    : fieldData && fieldData.url && fieldData.url.split('=')[1];
  const { data: data } = useGetStrategyParameterUrl(
    isUrl,
    fieldData.url,
    exchange,
  );
  const backendString: string =
    field && field.value && field.value.length
      ? (field.value as string)
      : ('' as string);
  const keys = backendString.split(',');
  // const hello = data?.data[exchange]
  const manipulateData = (data?: GetStrategyParameterKeyValue) => {
    const result: { type: string; key: string; value: string }[] = [];

    if (data)
      Object.keys(data).forEach((key: string) => {
        (data[key] as { key: string; value: string }[]).forEach(item => {
          result.push({
            type: key,
            key: item.key,
            value: item.value,
          });
        });
      });
    return result;
  };
  const transformData = manipulateData(
    fieldData && fieldData.url && fieldData.url.length > 0
      ? (data?.data[
          String(exchangeIfUndefined)
        ] as GetStrategyParameterKeyValue)
      : fieldData.symbolData,
  );
  const result = keys.map(
    key => transformData && transformData.find(item => item.value === key),
  ) as { type: string; key: string; value: string }[];

  const [selectedOptions, setSelectedOptions] = useState<
    { type: string; key: string; value: string }[]
  >(result.some(data => data !== undefined) ? result : []);

  useEffect(() => {
    if (
      fieldData &&
      fieldData.min_symbol &&
      selectedOptions.length > fieldData.min_symbol
    ) {
      const limitedSelection = selectedOptions.slice(0, fieldData.min_symbol);
      setSelectedOptions(limitedSelection);
      handleChange(limitedSelection.map(item => item.value).toString());
    }
  }, [selectedOptions, handleChange, fieldData]);

  const handleAutoCompleteChange = (
    event,
    selectedValues: { type: string; key: string; value: string }[],
  ) => {
    const updatedSelectedValues =
      fieldData &&
      fieldData.min_symbol &&
      selectedValues.length > fieldData.min_symbol
        ? selectedValues.slice(0, fieldData.min_symbol)
        : selectedValues;

    setSelectedOptions(updatedSelectedValues);
    handleChange(updatedSelectedValues.map(item => item.value).toString());
  };

  const filterOptions = createFilterOptions({
    stringify: (option: { type: string; value: string; key: string }) =>
      option.value,
  });
  return (
    <>
      {transformData && (
        <Autocomplete
          multiple
          filterOptions={filterOptions}
          groupBy={option => option.type}
          autoHighlight
          disableCloseOnSelect
          value={selectedOptions.length ? selectedOptions : []}
          onChange={handleAutoCompleteChange}
          options={transformData}
          getOptionLabel={option => option.value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => {
            const { ...optionProps } = props;
            const isSelected = selectedOptions.some(
              selectedOption => selectedOption.key === option.key,
            );
            return (
              <li {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={isSelected}
                />
                {option.value}
              </li>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={fieldData.label}
              placeholder=""
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default Combobox;
