import { useQuery } from 'react-query';
import { GetEstimatedCredits } from 'services/apiServices';
import { Errors, GetEstimatedCreditResult } from 'types/ApiServicesTypes';

export default function useGetEstimatedCredits(
  action: string,
  params?: Record<string, string>,
) {
  const objQuery = useQuery<GetEstimatedCreditResult, Errors>(
    ['EstimatedCredits', action, params],
    () => GetEstimatedCredits(action, params),
  );
  return objQuery;
}
