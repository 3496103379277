import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import AddBrokerForm from 'app/components/Menu/AddBrokerForm';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { Alert } from '@mui/material';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function AddBrokerPage() {
  const isShowBrokerDisclaimer = useIsFeatureFlag([
    FEATURES.IS_SHOW_BROKER_DISCLAIMER,
  ]);
  return (
    <>
      <Helmet>
        <title>Add Broker</title>
        <meta name="description" content="Add Broker Page" />
      </Helmet>

      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.brokers" />}
        title={<FormattedMessage id="menu.page_title.add_broker" />}
        buttonHandleClick="/broker"
        breadcrumb={<Breadcrumbs page={PAGES.ADD_BROKER} data={{}} />}
      />
      {isShowBrokerDisclaimer && (
        <Alert severity="warning" sx={{ mx: 2.5 }}>
          Disclaimer: SpeedBot has no direct association or partnership with any
          of the brokers listed here. This feature is an optional software addon
          that allows users to link their chosen broker’s API to their SpeedBot
          account voluntarily. By using this feature, the user acknowledges
          understanding the purpose for linking their broker account credentials
          and accepts the risks associated with SpeedBot’s trading analysis and
          order placement features. For more information, please refer to the
          risk disclosure section available here.
        </Alert>
      )}
      <AddBrokerForm />
    </>
  );
}
