import CommonTable from 'app/components/Common/CommonTable';
import createTradeTableColumnsRows from './utils';
import { TradesType } from '../types';

type TradeTableProps = {
  tradeData: Array<TradesType> | undefined;
  handleTradeRowClick?: (trade: TradesType) => void;
};

const TradeTable = ({ tradeData, handleTradeRowClick }: TradeTableProps) => {
  const { rows, columns } = createTradeTableColumnsRows(
    tradeData,
    handleTradeRowClick,
  );
  return (
    <CommonTable
      containerStyle={{
        height: '90vh',
        scrollbarWidth: 'none',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          width: '0.4em',
        },
      }}
      rows={rows}
      headerSticky={true}
      columns={columns}
      handleChartRowClick={handleTradeRowClick}
    />
  );
};

export default TradeTable;
