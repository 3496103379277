import { Box, Divider, Grid } from '@mui/material';
import { GetMarketplaceBotsCategoryData } from 'types/ApiServicesTypes';
import MarketplaceItems from 'app/design/itemsComponent/MarketplaceItems';
import { CategoryHeadItem } from 'app/design/speedBot/EntityItem';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { encodeUrlData } from 'utils/GenericFunctions';
import { useTranslation } from 'react-i18next';

type MarketPlaceBoxType = 'market' | 'traders';

const MarketPlaceBox = ({
  categories,
  isViewFullItem,
  isVisibleCheckbox,
  selectedBots,
  handleSelectBot,
  type,
}: {
  type: MarketPlaceBoxType;
  categories: GetMarketplaceBotsCategoryData[];
  isViewFullItem?: boolean;
  isVisibleCheckbox?: boolean;
  selectedBots?: number[];
  handleSelectBot?: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();

  const getOnClickHandler = (
    itemType: MarketPlaceBoxType,
    category_id: number,
    category_name: string,
  ) => {
    if (itemType === 'market') {
      return () =>
        breadcrumb.push(
          `/discover/bots/category/${category_id}/Discover/${encodeUrlData(
            category_name,
          )}`,
          PAGES.MARKETPLACE,
          `/discover/bots`,
          t('market.page_title.market'),
        );
    } else if (itemType === 'traders') {
      return () =>
        breadcrumb.push(
          `/traders/category/${category_id}/Traders/${encodeUrlData(
            category_name,
          )}`,
          PAGES.TRADERS,
          `/traders`,
          t('traders.page_title.traders'),
        );
    }
  };

  const isTraders = type === 'traders';

  return (
    <>
      {categories &&
        categories.map(category => (
          <Box
            key={category.category_id}
            sx={{
              mt: { xs: 1, md: 0 },
              mb: { xs: 0, md: 2 },
              display: { xs: 'block', md: isTraders ? 'block' : 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                flex: { md: 1, lg: 'initial' },
                width: {
                  xs: 'initial',
                  ...(!isTraders && {
                    md: isViewFullItem ? '24rem' : '22rem',
                    lg: isViewFullItem ? '24rem' : '15rem',
                  }),
                },
              }}
            >
              <CategoryHeadItem
                isSeeAll={!isTraders}
                fullWidth={isTraders}
                category={category}
                handleClick={
                  getOnClickHandler(
                    type,
                    category.category_id,
                    category.category_name,
                  )!
                }
              />
              {!isTraders && (
                <Divider
                  sx={{
                    borderColor: 'grey.200',
                    display: { xs: 'block', md: 'none' },
                  }}
                />
              )}
            </Box>

            <Box sx={{ flex: 1.5, justifyContent: 'end' }}>
              <Grid
                container
                spacing={isTraders ? 2 : 0}
                sx={{
                  maxWidth: { xs: 'initial', md: '32rem', lg: 'initial' },
                  flex: { lg: 1, xl: 1 },
                }}
                className={isTraders ? 'p-main-spacing' : ''}
              >
                {category && category.bots && (
                  <MarketplaceItems
                    bots={category.bots}
                    isGraph={true}
                    isViewFullItem={isViewFullItem}
                    isVisibleCheckbox={isVisibleCheckbox}
                    selectedBots={selectedBots}
                    handleSelectBot={handleSelectBot}
                    isTrader={isTraders}
                  />
                )}
              </Grid>
            </Box>
          </Box>
        ))}
    </>
  );
};

export default MarketPlaceBox;
