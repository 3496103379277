import { memo, useCallback, useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { BotSignalParams } from 'types/ApiServicesTypes';
import BotGenerateSignalCommonForm from './BotGenerateSignalCommonForm';
import { SignalFormDataContext } from './BotGenerateSignalModal';
import { useTheme } from '@mui/material/styles';

export type SignalModalTitleData = {
  symbol: string;
  qty: number;
  transactionType: string;
};

const BotBasketSignalFormModal = ({
  open,
  isLoading,
  handleClose,
  onSave,
  signalData,
  isModifyBasket,
}: {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  onSave: (data: BotSignalParams[], successCallback?: () => void) => void;
  signalData?: BotSignalParams;
  isModifyBasket?: boolean;
}) => {
  const isMdUp = useGetMediaQueryUp('md');

  const [titleData, setTitleData] = useState<SignalModalTitleData>({
    symbol: '',
    qty: 1,
    transactionType: 'BUY',
  });

  const { isModify, isCrypto, signal, type, instruments, isDebugMode } =
    useContext(SignalFormDataContext);
  const handleChangeTitleData = useCallback((data: SignalModalTitleData) => {
    setTitleData(data);
  }, []);
  const theme = useTheme();
  return (
    <>
      <MuiModal
        open={open}
        handleClose={handleClose}
        isFullScreen={!isMdUp}
        maxWidth="lg"
        header={
          <Box
            sx={{
              pt: 2,
              backgroundColor:
                titleData.transactionType === 'BUY'
                  ? 'success.light'
                  : 'error.light',
            }}
            color={theme.palette.common.white}
          >
            <PageHeader
              variant="withIcon"
              titleElipsis
              title={
                titleData.symbol ? (
                  <>
                    <Typography
                      variant={isMdUp ? 'h6' : 'body2'}
                      className="text-ellipsis"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Box className="text-ellipsis">{titleData.symbol}</Box>
                      <Box sx={{ ml: 0.5 }}>
                        {titleData.qty ? ' X ' + titleData.qty.toString() : ''}
                      </Box>
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    style={{
                      display: 'inline-block',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    <FormattedMessage id="bot_signals.generate_new_signal" />
                  </Typography>
                )
              }
              iconProps={{
                handleClick: () => handleClose(),
                icon: <CloseIcon fontSize="small" />,
              }}
            />
            <Box className="p-main-spacing" sx={{ mb: 2 }}>
              <ItemSeparator
                variant="2px"
                borderColor={
                  titleData.transactionType === 'BUY'
                    ? 'success.light'
                    : 'error.light'
                }
              />
            </Box>
          </Box>
        }
        body={
          <Box sx={{ pb: 2, height: '100%' }}>
            <BotGenerateSignalCommonForm
              isModify={isModify}
              isCrypto={isCrypto}
              handleClose={handleClose}
              handleChangeTitleData={handleChangeTitleData}
              signal={signal}
              type={type}
              instruments={instruments ? instruments : []}
              isDebugMode={isDebugMode}
              onSave={onSave}
              isModifyBasket={isModifyBasket}
              signalData={signalData}
              isLoading={isLoading}
            />
          </Box>
        }
      />
    </>
  );
};

export default memo(BotBasketSignalFormModal);
