import { ToastContext } from 'app/components/Context/ToastContext';
import React, { memo, ReactElement, useContext } from 'react';
import useGetProfile from 'services/Menu/useGetProfile';
import { GetProfileResult, ProfileCapability } from 'types/ApiServicesTypes';
import { CAPABILITIES } from 'types/Capability';
import { capabilityToChecker } from './capabilityToChecker';

type CapabilityDataProps = {
  userCapabilities: ProfileCapability[];
  profileDetails: GetProfileResult | undefined;
};

type CapabilityFlagProps = {
  capabilities: CAPABILITIES[];
  fallback: (data: CapabilityDataProps) => ReactElement | React.ReactNode;
  children: (data: CapabilityDataProps) => ReactElement | React.ReactNode;
  // children: React.ReactNode;
};

const CapabilityFlagComp = ({
  capabilities,
  fallback,
  children,
}: CapabilityFlagProps) => {
  const { showAlert } = useContext(ToastContext);

  // Get capabilities from profile API
  const { data: profileDetails } = useGetProfile();
  const userCapabilities =
    profileDetails &&
    profileDetails.user_plan &&
    profileDetails.user_plan.capabilities
      ? profileDetails.user_plan.capabilities
      : [];

  let isValid = true;
  capabilities.forEach(capability => {
    const capacityData = userCapabilities.filter(x => x.key === capability)[0];
    const checker = capabilityToChecker[capability];
    if (checker && capacityData) {
      if (!checker(capacityData)) {
        isValid = false;
      }
    } else if (!checker) {
      //TODO: In future, we will send error message via logging for this.
      throw 'Capability checker is not defined.';
    } else {
      isValid = false;
    }
  });

  if (isValid) {
    return <>{children({ userCapabilities, profileDetails })}</>;
  } else if (fallback) {
    return <>{fallback({ userCapabilities, profileDetails })}</>;
  } else {
    showAlert!('Balance not available');
    return <></>;
  }
};
export const CapabilityFlag = memo(CapabilityFlagComp);
