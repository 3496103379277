import { Box, Checkbox, Divider, Grid, useMediaQuery } from '@mui/material';
import { MarketItem } from 'app/design/speedBot/EntityItem';
import { GetMarketplaceBotsItem } from 'types/ApiServicesTypes';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import TraderItem from '../speedBot/EntityItem/TraderItem';

const MarketplaceItems = ({
  bots,
  isGraph,
  isSearch,
  isViewFullItem,
  isVisibleCheckbox,
  selectedBots,
  handleSelectBot,
  isTrader,
}: {
  isTrader?: boolean;
  bots: GetMarketplaceBotsItem[];
  isGraph?: boolean;
  isSearch?: boolean;
  isViewFullItem?: boolean;
  isVisibleCheckbox?: boolean;
  selectedBots?: number[];
  handleSelectBot?: (id: number) => void;
}) => {
  isGraph = !isGraph ? false : true;
  const isMdUp = useGetMediaQueryUp('md');
  const isLgUp = useGetMediaQueryUp('lg');
  const is1600Up = useMediaQuery('(min-width:1600px)');

  const totalBots = bots.length;

  return (
    <>
      {bots.map((bot, j) => {
        const isChecked = selectedBots && selectedBots.includes(bot.id);
        return (
          <Grid
            item
            xs={12}
            md={isSearch ? 6 : 12}
            lg={isViewFullItem ? 12 : 6}
            xl={
              isViewFullItem
                ? 12
                : is1600Up && !isSearch
                ? isTrader
                  ? 3
                  : 4
                : 6
            }
            key={bot.id}
            sx={{ position: 'relative' }}
          >
            {isVisibleCheckbox && (
              <Box
                sx={{
                  flex: 1,
                  position: 'absolute',
                  top: 0,
                  left: '8px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'end',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => handleSelectBot!(bot.id)}
              >
                <Checkbox checked={isChecked} sx={{ p: 0 }} disabled />
              </Box>
            )}
            <Box
              flex={1}
              sx={{
                ml: isVisibleCheckbox ? 2 : 0,
                transition: 'margin 0.15s ease-in-out',
                ...(isTrader && { height: '100%' }),
              }}
            >
              {isTrader ? (
                <TraderItem
                  bot={bot}
                  isGraph={false}
                  isVisibleCheckbox={isVisibleCheckbox}
                  isChecked={isChecked}
                />
              ) : (
                <MarketItem
                  bot={bot}
                  isGraph={isGraph}
                  isVisibleCheckbox={isVisibleCheckbox}
                  isChecked={isChecked}
                />
              )}
            </Box>
            {!isTrader && (
              <>
                {is1600Up &&
                  ((totalBots % 3 == 0 && totalBots - 3 > j) ||
                    (totalBots % 3 == 1 && totalBots - 1 > j) ||
                    (totalBots % 3 == 2 && totalBots - 2 > j)) && (
                    <Divider
                      sx={{ borderColor: 'grey.200', mx: isMdUp ? 3 : 0 }}
                    />
                  )}
                {isLgUp &&
                  !is1600Up &&
                  ((totalBots % 2 == 0 && totalBots - 2 > j) ||
                    (totalBots % 2 == 1 && totalBots - 1 > j)) && (
                    <Divider
                      sx={{ borderColor: 'grey.200', mx: isMdUp ? 3 : 0 }}
                    />
                  )}

                {!isLgUp && totalBots - 1 !== j && (
                  <Divider
                    sx={{ borderColor: 'grey.200', mx: isMdUp ? 3 : 0 }}
                  />
                )}
              </>
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default MarketplaceItems;
