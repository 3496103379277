import { Badge, Box, IconButton, useTheme } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

import { useTranslation } from 'react-i18next';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useCallback, useState } from 'react';
import ReportFilter from 'app/components/Report/ReportFilter';
import useGetQueryFilters from 'app/components/Report/useGetQueryFilters';
import ReportFilterModal from 'app/components/Report/ReportFilterModal';
// import ReportFilterModal from 'app/components/Report/ReportFilterModal';
import { ReportSearchParams } from 'types/ApiServicesTypes';
import useGetMarketplaceBots from 'app/components/Report/useGetReportBot';
import ProfilLossByDateSummaryDetail from 'app/design/speedBot/ComponentItem/Common/ProfilLossByDateSummaryDetail';
import ListViewCalendar from 'app/components/Report/ListViewCalendar';
import StatusMessage from 'app/components/Common/StatusMessage';
import nodatafound from '../../../../assets/images/No_data_image.png';
import useGetReportMetaData from 'app/components/Report/useGetReportMetaData';
import { PRODUCT_TOUR } from 'constants/common';
import ProductTour from 'app/components/Common/ProductTour/ProductTour';
import { ReportPageProductSteps } from 'app/components/Common/ProductTour/ProductTourData';

const HistoryReportsPage = () => {
  const { setQueryParams, ...queryFilters } = useGetQueryFilters();

  const [searchParams, setSearchParams] = useState<ReportSearchParams>({
    endDate: queryFilters.END_DATE,
    startDate: queryFilters.START_DATE,
    exchanges: queryFilters.EXCHANGES as string[] | undefined,
    deployment: queryFilters.DEPLOYMENT,
    strategy:
      typeof queryFilters.STRATEGY === 'string' ? queryFilters.STRATEGY : [],
    brokers:
      typeof queryFilters.BROKERS === 'string' ? queryFilters.BROKERS : [],
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { data: filterMetaData } = useGetReportMetaData();
  const {
    isLoading,
    data: reportData,
    transformedData,
    // pnlItems,
    // hasNextPage,
    //  fetchNextPage,
    //  isFetchingNextPage
  } = useGetMarketplaceBots(searchParams);
  // eslint-disable-next-line no-console

  const isMdDown = useGetMediaQueryDown('md');
  const { t } = useTranslation();
  const [appliedFilters, setAppliedFilters] = useState<number | null>();
  const handleApplyFilter = useCallback(
    (newValues: ReportSearchParams) => {
      setSearchParams(newValues);
      setQueryParams(newValues);
    },
    [setQueryParams],
  );
  const theme = useTheme();
  return (
    <>
      <CircularLoader open={isLoading} zIndex={1302} />

      <Helmet>
        <title>Reports</title>
        <meta name="description" content="Report Page" />
      </Helmet>
      {/* <CircularLoader open={isLoading} /> */}
      <Box
        className="sticky-top"
        sx={{ backgroundColor: theme.palette.common.white }}
      >
        <PageHeader
          variant="market"
          title={<FormattedMessage id="report.report_title" />}
          // marketIconProps={{
          //   // link: '/discover/bots/search',
          //   icon: (
          //     <SearchIcon
          //       fontSize="medium"
          //       color="primary"
          //       sx={{ ml: { md: 1 } }}
          //     />
          //   ),
          //   iconClick: () =>
          //     breadcrumb.push(
          //       '/report/search',
          //       PAGES.REPORTS,
          //       `/report`,
          //       t('report.report_title'),
          //     ),
          // }}
          {...(isMdDown && {
            rightElement: (
              <IconButton color="primary" onClick={() => setOpenFilter(true)}>
                <FilterListIcon />
                <Badge
                  color="primary"
                  badgeContent={appliedFilters}
                  sx={{ mb: 3 }}
                />
              </IconButton>
            ),
          })}
        />
      </Box>
      {!isMdDown ? (
        <ReportFilter
          filters={searchParams}
          handleAppyFilter={handleApplyFilter}
          filterMetaData={filterMetaData}
        />
      ) : (
        <ReportFilterModal
          open={openFilter}
          handleClose={() => setOpenFilter(false)}
          filters={searchParams}
          handleApplyFilters={handleApplyFilter}
          handleApplyFilterCount={count => setAppliedFilters(count)}
          filterMetaData={filterMetaData}
        />
      )}
      <Box>
        {reportData && (
          <Box>
            <ProfilLossByDateSummaryDetail
              currencySymbol={'₹'}
              net_profit_loss={reportData.net_profit}
              total_profit_loss={reportData.realized_pnl}
              total_fees={reportData.charges_and_taxes}
              baseUrl={'baseUrl'}
            />
          </Box>
        )}
        <Box>
          {transformedData.map((transform, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              {reportData && reportData.data && reportData.data.length > 0 ? (
                <ListViewCalendar
                  key={index}
                  currencySymbol={'₹'}
                  data={transform}
                  initialCapital={reportData.net_profit}
                />
              ) : (
                // <Box>Herlllajhfrl</Box>
                <Box>empty</Box>
              )}
            </Box>
          ))}
          {reportData && reportData.data && reportData.data.length === 0 && (
            // <StatusMessage
            //   subtitle={t('reports.couldnt_find')}
            //   fontStyle="italic"
            // />
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                // justifyContent: 'center',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                mt: 10,
              }}
            >
              <img
                src={nodatafound}
                style={{ width: '150px', height: '150px' }}
              />
              <StatusMessage
                subtitle={t('reports.couldnt_find')}
                fontStyle="italic"
              />
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: 'center' }}></Box>
      </Box>
      {!JSON.parse((localStorage.getItem(PRODUCT_TOUR) as string) || '')[
        'Report Page'
      ] && (
        <ProductTour steps={ReportPageProductSteps} tour_page="Report Page" />
      )}
    </>
  );
};

export default HistoryReportsPage;
