import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiSwitchUnstyled from 'app/design/uiComponents/MuiUnstyledSwitch';
import { FormikErrors, FormikTouched } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURES } from 'types/Feature';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatNumber } from 'utils/GenericFunctions';

interface SLValue {
  enabled: boolean;
  price?: number | undefined;
  trigger?: number | undefined;
  exit_order_type: string;
}
interface TargetValue {
  enabled: boolean;
  price: number;
  trigger?: number;
  exit_order_type: string;
}

interface FormValue {
  stoploss: SLValue;
  target: TargetValue;
}

interface Props {
  buysell: string;
  setFieldValue: (fieldName: string, value: SLValue | TargetValue) => void;
  values: SLValue | TargetValue;
  touched: FormikTouched<FormValue>;
  errors: FormikErrors<FormValue>;
  type: string;
  symbolPrice: number | undefined;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    unitType?: string,
  ) => void;
  tooltipKey?: string;
  isModify: boolean;
}

const BotSignalUnitBaseComponent = ({
  setFieldValue,
  values,
  touched,
  errors,
  handleBlur,
  buysell,
  symbolPrice,
  type,
  tooltipKey,
  isModify,
}: Props) => {
  const { t } = useTranslation();
  const [unitType, setUnitType] = useState('price');
  const [priceValue, setPriceValue] = useState(0);
  const [triggerValue, setTriggerValue] = useState(0);
  const [SLMarket, setSLMarket] = useState<{
    stopLoss: boolean;
    target: boolean;
  }>({ stopLoss: false, target: false });
  const handleUnitType = (unit: string) => {
    setUnitType(unit);
  };
  useEffect(() => {
    setPriceValue(0);
    setTriggerValue(0);
  }, [values.enabled]);
  const isExitSignalSLM = useIsFeatureFlag([FEATURES.IS_EXIT_SIGNAL_SLM]);
  const calculateTriggerValue = (
    unitType: string,
    symbolPrice: number | undefined,
    buysell: string,
    inputValue: number,
    type: string,
  ) => {
    let triggerChangeValue: number | undefined;
    if (inputValue === 0) {
      triggerChangeValue = 0;
    } else if (unitType === 'price') {
      triggerChangeValue = Math.max(0, Number(inputValue));
    } else if (unitType === 'percentage') {
      triggerChangeValue =
        type === 'stoploss'
          ? symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice + symbolPrice * inputValue * 0.01
              : symbolPrice - symbolPrice * inputValue * 0.01)
          : symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice - symbolPrice * inputValue * 0.01
              : symbolPrice + symbolPrice * inputValue * 0.01);
    } else if (unitType === 'point') {
      triggerChangeValue =
        type === 'stoploss'
          ? symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice + inputValue
              : symbolPrice - inputValue)
          : symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice - inputValue
              : symbolPrice + inputValue);
    }
    return triggerChangeValue;
  };
  const calculatePriceChangeValue = (
    unitType: string,
    symbolPrice: number | undefined,
    buysell: string,
    type: string,
    inputValue: number,
  ): number => {
    let priceChangeValue;
    if (inputValue === 0) {
      priceChangeValue = 0;
    } else if (unitType === 'price') {
      priceChangeValue = Math.max(0, Number(inputValue));
    } else if (unitType === 'percentage') {
      priceChangeValue =
        type === 'stoploss'
          ? symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice + symbolPrice * inputValue * 0.01
              : symbolPrice - symbolPrice * inputValue * 0.01)
          : symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice - symbolPrice * inputValue * 0.01
              : symbolPrice + symbolPrice * inputValue * 0.01);
    } else if (unitType === 'point') {
      priceChangeValue =
        type === 'stoploss'
          ? symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice + inputValue
              : symbolPrice - inputValue)
          : symbolPrice &&
            (buysell === 'SELL'
              ? symbolPrice - inputValue
              : symbolPrice + inputValue);
    }
    return priceChangeValue;
  };
  // // eslint-disable-next-line no-console
  // console.log("errors in parent", errors, touched);
  useEffect(() => {
    let priceUpdate: number | undefined;
    let triggerUpdate: number | undefined;
    if (priceValue === 0) {
      priceUpdate = 0;
    }
    if (triggerValue === 0) {
      triggerUpdate = 0;
    }
    if (unitType === 'price') {
      if (priceValue < 0) {
        priceUpdate = 0;
      } else {
        priceUpdate = priceValue;
      }
      if (triggerValue < 0) {
        triggerUpdate = 0;
      } else {
        triggerUpdate = triggerValue;
      }
    } else if (
      unitType === 'percentage' &&
      (triggerValue > 0 || !SLMarket.target) &&
      priceValue > 0
    ) {
      if (type == 'stoploss') {
        priceUpdate =
          symbolPrice && buysell === 'SELL'
            ? symbolPrice + symbolPrice * priceValue * 0.01
            : symbolPrice && symbolPrice - symbolPrice * priceValue * 0.01;
        triggerUpdate =
          symbolPrice && buysell === 'SELL'
            ? symbolPrice + symbolPrice * triggerValue * 0.01
            : symbolPrice && symbolPrice - symbolPrice * triggerValue * 0.01;
      } else {
        priceUpdate =
          symbolPrice && buysell === 'SELL'
            ? symbolPrice - symbolPrice * priceValue * 0.01
            : symbolPrice && symbolPrice + symbolPrice * priceValue * 0.01;
        if (SLMarket.target) {
          triggerUpdate =
            symbolPrice && buysell === 'SELL'
              ? symbolPrice - symbolPrice * triggerValue * 0.01
              : symbolPrice && symbolPrice + symbolPrice * triggerValue * 0.01;
        }
      }
    } else if (
      unitType === 'point' &&
      (triggerValue > 0 || !SLMarket.target) &&
      priceValue > 0
    ) {
      if (type == 'stoploss') {
        priceUpdate =
          symbolPrice && buysell == 'SELL'
            ? symbolPrice + priceValue
            : symbolPrice && symbolPrice - priceValue;
        // triggerUpdate = symbolPrice && symbolPrice + triggerValue;
        triggerUpdate =
          symbolPrice && buysell == 'SELL'
            ? symbolPrice + triggerValue
            : symbolPrice && symbolPrice - triggerValue;
      } else {
        priceUpdate =
          symbolPrice && buysell == 'SELL'
            ? symbolPrice - priceValue
            : symbolPrice && symbolPrice + priceValue;
        // triggerUpdate = symbolPrice && symbolPrice + triggerValue;
        if (SLMarket.target) {
          triggerUpdate =
            symbolPrice && buysell == 'SELL'
              ? symbolPrice - triggerValue
              : symbolPrice && symbolPrice + triggerValue;
        }
      }
    }

    if (values.enabled) {
      setFieldValue(`${type}`, {
        ...values,
        price: priceUpdate,
        trigger: triggerUpdate,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitType, buysell, symbolPrice, values.enabled, unitType]);
  return (
    <>
      <Grid item xs={12} md={6}>
        <Box sx={{ mb: 3 }}>
          <Box
            sx={{
              gap: 2,
              mb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              // alignItems: 'center',
            }}
          >
            <Box
              sx={{
                gap: 2,
                mb: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography fontWeight={500}>
                  {t(
                    type === 'stoploss'
                      ? 'bot_signals.form.stoploss'
                      : 'bot_signals.form.target',
                  )}
                </Typography>
                <MuiSwitchUnstyled
                  checked={values.enabled}
                  onChange={e => {
                    if (type === 'stoploss') {
                      e.target.checked &&
                        setSLMarket({ ...SLMarket, stopLoss: false });
                      setFieldValue('stoploss', {
                        ...values,
                        exit_order_type: 'STOP_LOSS',
                        enabled: e.target.checked,
                        ...(!e.target.checked && {
                          trigger: 0,
                          price: 0,
                        }),
                      });
                    } else {
                      e.target.checked &&
                        setSLMarket({ ...SLMarket, target: false });
                      setFieldValue('target', {
                        ...values,
                        enabled: e.target.checked,
                        exit_order_type: 'LIMIT',
                        ...(!e.target.checked && {
                          price: 0,
                          trigger: 0,
                        }),
                      });
                    }
                  }}
                />
              </Box>
              {values.enabled && isExitSignalSLM && type === 'target' && (
                <Box display={'flex'} alignItems={'center'} gap={2}>
                  <Typography fontWeight={500}>SL-L</Typography>
                  <MuiSwitchUnstyled
                    disabled={isModify}
                    checked={
                      values.enabled
                        ? SLMarket.stopLoss ||
                          SLMarket.target ||
                          ['STOP_LOSS'].includes(values.exit_order_type)
                        : false
                    }
                    onChange={e => {
                      // if (type === 'stoploss') {
                      //   setFieldValue('stoploss', {
                      //     ...values,
                      //     exit_order_type: 'STOP_LOSS',
                      //     // slmEnabled: e.target.checked,
                      //     ...(!e.target.checked && {
                      //       exit_order_type: 'STOP_LOSS',
                      //     }),
                      //   });
                      //   setSLMarket({
                      //     ...SLMarket,
                      //     stopLoss: e.target.checked,
                      //   });
                      // } else
                      {
                        setFieldValue('target', {
                          ...values,
                          exit_order_type: 'STOP_LOSS',
                          // slmEnabled: e.target.checked,
                          ...(!e.target.checked && {
                            exit_order_type: 'LIMIT',
                            trigger: 0,
                          }),
                        });
                        // eslint-disable-next-line no-console
                        setSLMarket({ ...SLMarket, target: e.target.checked });
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
            <FeatureFlag
              features={[FEATURES.IS_UNIT_SHOW_IN_SIGNAL]}
              fallback={<></>}
            >
              <ButtonGroup
                size="small"
                aria-label="small button group"
                orientation={'horizontal'}
                className="toggle-button-group"
                sx={{
                  width: '60%',
                  mb: 1,
                  display: !values.enabled ? 'none' : '',
                }}
              >
                <Button
                  sx={
                    {
                      // border: '1px solid rgba(0, 0, 0, 0.87)',
                      // color: 'rgb(256, 256, 0)',
                    }
                  }
                  size="small"
                  onClick={() => {
                    handleUnitType('price');
                  }}
                  disabled={false}
                  className={unitType === 'price' ? 'active' : ''}
                >
                  {t('bot_signals.form.price')}
                </Button>
                <Button
                  sx={{}}
                  size="small"
                  onClick={() => {
                    handleUnitType('percentage');
                  }}
                  disabled={false}
                  className={unitType === 'percentage' ? 'active' : ''}
                >
                  {t('bot_signals.form.percentage')}
                </Button>
                <Button
                  sx={{}}
                  size="small"
                  onClick={() => {
                    handleUnitType('point');
                  }}
                  disabled={false}
                  className={unitType === 'point' ? 'active' : ''}
                >
                  {t('bot_signals.form.point')}
                </Button>
              </ButtonGroup>
            </FeatureFlag>
          </Box>
          {values.enabled && (
            <Box display="flex" gap={2} alignItems={'center'}>
              {type === 'stoploss' ||
              (type == 'target' &&
                (SLMarket.target ||
                  ['STOP_LOSS'].includes(values.exit_order_type))) ? (
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={Boolean(
                    touched.stoploss?.trigger && errors.stoploss?.trigger,
                  )}
                >
                  {values.enabled && (
                    <InputLabel htmlFor="label-stoploss-trigger">
                      <FormattedMessage id="bot_signals.form.trigger" />
                    </InputLabel>
                  )}
                  {values.enabled && (
                    <OutlinedInput
                      id="label-stoploss-trigger"
                      name="stoploss.trigger"
                      type="number"
                      //   value={ typeof values.trigger ? values.trigger : ''}
                      // value={values.trigger||''}
                      onBlur={handleBlur}
                      // value={
                      //   values.enabled && triggerValue > 0
                      //     ? triggerValue.toString()
                      //     : '0'
                      // }
                      onChange={e => {
                        const inputValue = Number(e.target.value) || 0;
                        setTriggerValue(
                          type == 'target' || type == 'stoploss'
                            ? inputValue
                            : 0,
                        );
                        if (
                          values.enabled ||
                          (type == 'target' && SLMarket.target)
                        ) {
                          const triggerChangeValue = calculateTriggerValue(
                            unitType,
                            symbolPrice,
                            buysell,
                            inputValue,
                            type,
                          );
                          setFieldValue(`${type}`, {
                            ...values,
                            trigger: triggerChangeValue,
                          });
                        }
                      }}
                      disabled={!values.enabled}
                      label={t('bot_signals.form.trigger')}
                      endAdornment={
                        unitType === 'price' ? (
                          <InputAdornment position="end">
                            <CustomTooltip tooltipKey={'signal_expiry'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            {values.trigger && (
                              <Box
                                sx={{
                                  color:
                                    type == 'target'
                                      ? 'success.main'
                                      : 'error.main',
                                }}
                              >
                                {values && values
                                  ? `${
                                      values.trigger < 0 ? '-' : ''
                                    } ₹${formatNumber(
                                      values.trigger,
                                      false,
                                      2,
                                    )}`
                                  : `₹0`}
                              </Box>
                            )}
                          </InputAdornment>
                        )
                      }
                    />
                  )}
                  {touched.stoploss?.trigger && errors.stoploss?.trigger && (
                    <FormHelperText error sx={{ mx: 0 }}>
                      {errors.stoploss.trigger}
                    </FormHelperText>
                  )}
                  {/* {values.trigger && (
                  <Box
                    sx={{
                      border: '1px solid #00000054',
                      padding: 0.5,
                      borderRadius: 1,
                      width: 'max-content',
                      color:
                        values && values.trigger > 0
                          ? 'success.main'
                          : 'error.main',
                    }}
                  >
                    {values && values
                      ? `${getArrowDirByNum(values.trigger)} ₹${formatNumber(
                          values.trigger,
                          false,
                          2,
                        )}`
                      : `₹0`}
                  </Box>
                )} */}
                </FormControl>
              ) : (
                <></>
              )}
              {
                // !(SLMarket.stopLoss || SLMarket.target)
                <PriceInput
                  // value={values.price}
                  handleBlur={handleBlur}
                  handleChange={e => {
                    const inputValue = Number(e.target.value);
                    setPriceValue(SLMarket.target ? 0 : inputValue);
                    if (values.enabled) {
                      const priceChangeValue = calculatePriceChangeValue(
                        unitType,
                        symbolPrice,
                        buysell,
                        type,
                        inputValue,
                      );
                      setFieldValue(`${type}`, {
                        ...values,
                        price: priceChangeValue,
                      });
                    }
                  }}
                  values={values.price}
                  touched={
                    type === 'stoploss'
                      ? touched?.stoploss?.price
                      : touched?.target?.price
                  }
                  errors={
                    type === 'stoploss'
                      ? errors.stoploss?.price
                      : errors.target?.price
                  }
                  type={unitType}
                  show={values.enabled}
                  parentType={type}
                  tooltipKey={tooltipKey}
                />
              }
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default BotSignalUnitBaseComponent;

const PriceInput = ({
  touched,
  errors,
  handleChange,
  handleBlur,
  values,
  type,
  parentType,
  show,
  tooltipKey,
}: {
  touched: boolean | undefined;
  errors: string | undefined;
  values: number | undefined;
  type: string;
  show: boolean;
  parentType: string;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  tooltipKey?: string;
}) => {
  // const { t } = useTranslation();
  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <FormControl fullWidth error={Boolean(touched && errors)}>
        <InputLabel htmlFor="label-price">
          <FormattedMessage id={`Price`} />
        </InputLabel>
        {show && (
          <OutlinedInput
            id={`label-stoploss-price`}
            name={`${parentType}.price`}
            type="number"
            // value={values}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={!show}
            label={'Price'}
            endAdornment={
              type === 'price' ? (
                <InputAdornment position="end">
                  <CustomTooltip tooltipKey={tooltipKey}>
                    {({ isTooltipAvailable }) =>
                      isTooltipAvailable ? (
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )
                    }
                  </CustomTooltip>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      alignItems: 'center',
                      color:
                        parentType == 'target' ? 'success.main' : 'error.main',
                    }}
                  >
                    {values && values
                      ? `${values < 0 ? '-' : ''} ₹${formatNumber(
                          values,
                          false,
                          2,
                        )}`
                      : `₹0`}
                  </Box>
                </InputAdornment>
              )
            }
          />
        )}
        {touched && errors && (
          <FormHelperText error sx={{ mx: 0 }}>
            {errors}
          </FormHelperText>
        )}
      </FormControl>
      {/* <Box
        sx={{
          border: '1px solid #00000054',
          padding: 0.5,
          borderRadius: 1,
          width: 'max-content',
          color: values && values > 0 ? 'success.main' : 'error.main',
        }}
      >
        {values && values
          ? `${getArrowDirByNum(values)} ₹${formatNumber(values, false, 2)}`
          : `₹0`}
      </Box> */}
    </Box>
  );
};
