import { memo } from 'react';
import { Box } from '@mui/material';
import { createBotSignalOpenPositionTableRows } from './util';
import CommonTable from 'app/components/Common/CommonTable';
import { Position } from 'types/ApiServicesTypes';
import { SymbolsData } from 'types/Symbols';

export type BotSignalTodaysPositionsProps = {
  positions: Position[];
  handlePositionClick: (position: Position) => void;
  handleClickSelectPosition?: (position: Position) => void;
  handleClickSelectAll: () => void;
  liveFeedData?: SymbolsData;
  isTopBorder?: boolean;
  selectedItems?: number[] | null;
  isDebugMode?: boolean;
};

const BotSignalTodaysOpenPositionsTable = ({
  positions,
  handlePositionClick,
  handleClickSelectAll,
  liveFeedData,
  selectedItems,
  isDebugMode,
  handleClickSelectPosition,
}: BotSignalTodaysPositionsProps) => {
  const { rows, columns } = createBotSignalOpenPositionTableRows(
    positions,
    liveFeedData,
    handlePositionClick,
    handleClickSelectAll,
    handleClickSelectPosition,
    selectedItems,
    isDebugMode,
  );

  return (
    <>
      {positions.length > 0 && (
        <Box className="p-main-spacing">
          <CommonTable rows={rows} columns={columns} />
        </Box>
      )}
    </>
  );
};

export default memo(BotSignalTodaysOpenPositionsTable);
