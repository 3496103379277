import React from 'react';
import {
  Autocomplete,
  TextField,
  Box,
  Button,
  FilterOptionsState,
  useTheme,
} from '@mui/material';
import { v4 } from 'uuid';
import useClasses from '../utils/useClasses';
import MuiButton from 'app/design/uiComponents/MuiButton';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { FieldData, FieldParam, FieldParamValue } from './types';
import {
  FieldWithComponentWithGroup,
  FieldKeyToField,
  Indicators,
  Operators,
  Comparators,
  MathFunctions,
  IndicatorWithResolution,
} from './fieldsData';

// Style const

interface Props {
  defaultFieldData?: FieldData;
  onDone: (fieldData: FieldData) => void;
  onCancel: () => void;
  hideRemoveButton?: boolean;
  possibleValues: string[];
  onRemove: () => void;
  defaultResolution?: string;
}

export function SelectField(props: Props) {
  const theme = useTheme();
  const disableIntellisense = true;
  const styles = () => ({
    container: {
      position: 'relative',
    },
    paramsContainer: {
      marginTop: '16px',
      width: '232px',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      background: theme.palette.common.white,
      zIndex: 2,
      padding: '16px',
      borderRadius: '4px',
      border: '1px solid #bdbdbd',
      boxShadow: '0px 5px 15px -7px rgba(0,0,0,0.14)',
    },
    autoComplete: {
      display: 'inline-flex',
      width: '232px',
      marginBottom: '0',
      height: '44px',
      padding: '2px',
      background: theme.palette.common.white,
      '& .MuiOutlinedInput-root': {
        padding: '0px',
        height: '100%',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#bcbcbc',
      },
    },
  });
  const classes = useClasses(styles);
  const {
    defaultFieldData,
    onDone,
    onCancel,
    hideRemoveButton,
    possibleValues,
    onRemove,
    defaultResolution,
  } = props;

  // If defaultFieldData exist that means we are in edit mode.
  // So set selectedField and fieldData from defaultFieldData.
  const defaultSelectedField = defaultFieldData
    ? FieldKeyToField()[defaultFieldData.key]
    : null;
  const defaultSelectedFieldParams = defaultFieldData
    ? defaultFieldData.params
    : {};
  const [selectedField, setSelectedField] =
    React.useState<FieldWithComponentWithGroup | null>(defaultSelectedField);
  const [fieldData, setFieldData] = React.useState<FieldData>(
    defaultFieldData || {
      key: '',
      type: '',
      params: {},
    },
  );
  const indicatorOptions: FieldWithComponentWithGroup[] = [];
  if (defaultResolution) {
    IndicatorWithResolution().forEach(x => {
      if (possibleValues.indexOf(x.key) !== -1 || disableIntellisense) {
        indicatorOptions.push({
          ...x,
          group: 'Indicators',
        });
      }
    });
  } else {
    Indicators.forEach(x => {
      if (possibleValues.indexOf(x.key) !== -1 || disableIntellisense) {
        indicatorOptions.push({
          ...x,
          group: 'Indicators',
        });
      }
    });
  }
  const comparatorOptions: FieldWithComponentWithGroup[] = [];
  Comparators.forEach(x => {
    if (possibleValues.indexOf(x.key) !== -1 || disableIntellisense) {
      comparatorOptions.push({
        ...x,
        group: 'Comparators',
        name: `${x.name} ${x.description ? `(${x.description})` : ''}`,
      });
    }
  });
  const mathOptions: FieldWithComponentWithGroup[] = [];
  MathFunctions.forEach(x => {
    if (possibleValues.indexOf(x.key) !== -1 || disableIntellisense) {
      mathOptions.push({
        ...x,
        group: 'Math Functions',
      });
    }
  });
  const operatorOptions: FieldWithComponentWithGroup[] = [];
  Operators.forEach(x => {
    if (possibleValues.indexOf(x.key) !== -1 || disableIntellisense) {
      let name = x.name;
      if (x.description) {
        name = `${x.description} (${x.name})`;
      }
      operatorOptions.push({
        ...x,
        group: 'Operators',
        name,
      });
    }
  });
  const options = [
    ...indicatorOptions,
    ...comparatorOptions,
    ...mathOptions,
    ...operatorOptions,
  ];

  const fieldParams = selectedField
    ? selectedField.params
    : ([] as FieldParam[]);

  const onParamChange = React.useCallback(
    (param: FieldParam, value: FieldParamValue) => {
      fieldData.params[param.key] = value;
      setFieldData(fieldData);
    },
    [fieldData, setFieldData],
  );

  const onDoneButtonClick = React.useCallback(() => {
    onDone(fieldData);
  }, [onDone, fieldData]);

  const onChangeFieldValue = (
    event: Event | React.SyntheticEvent,
    value: FieldWithComponentWithGroup | null,
  ) => {
    if (!value) {
      return;
    }
    const field = FieldKeyToField()[value.key];
    fieldData.key = field.key;
    fieldData.type = field.type;
    fieldData.params = {}; //Reset params
    field.params.forEach(param => {
      if (param.type === 'dropdown') {
        if (param.key === 'resolution') {
          fieldData.params[param.key] = defaultResolution as string;
        } else {
          fieldData.params[param.key] = param.defaultValue.key;
        }
      } else {
        fieldData.params[param.key] = param.defaultValue;
      }
    });
    setFieldData(fieldData);
    setSelectedField(field);

    field.params && field.params.length === 0 && onDoneButtonClick();
  };

  const filterOptions = (
    options: FieldWithComponentWithGroup[],
    state: FilterOptionsState<FieldWithComponentWithGroup>,
  ) => {
    const value = state.inputValue.toLowerCase().trim();
    let result: FieldWithComponentWithGroup[] = [];
    if (value) {
      result = options.filter(option => {
        const name = option.name.toLowerCase();
        const shortName = option.shortName
          ? option.shortName.toLowerCase()
          : '';
        return name.indexOf(value) !== -1 || shortName.indexOf(value) !== -1;
      });
    } else {
      result = options;
    }
    return result;
  };

  return (
    <div className={classes.container}>
      {hideRemoveButton ? (
        <></>
      ) : (
        <Box
          sx={{
            width: '232px',
            height: '30px',
            position: 'absolute',
            top: '-46px',
            background: theme.palette.common.white,
            boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.14)',
          }}
        >
          <MuiButton
            variant="text"
            startIcon={<ClearIcon />}
            sx={{
              width: '115px',
              color: theme.palette.common.black,
              borderRight: '1px solid #C4C4C4',
              backgroundColor: theme.palette.common.white,
              padding: '0 !important',
              height: '100%',
              borderRadius: '0',
            }}
            onClick={onCancel}
          >
            Cancel
          </MuiButton>
          <MuiButton
            variant="text"
            startIcon={<DeleteOutlineOutlinedIcon />}
            sx={{
              width: '116px',
              color: theme.component.equityBuilder.brown,
              backgroundColor: theme.palette.common.white,
              padding: '0 !important',
              height: '100%',
              borderRadius: '0',
            }}
            onClick={onRemove}
          >
            Remove
          </MuiButton>
        </Box>
      )}
      <Autocomplete
        className={classes.autoComplete}
        options={options}
        getOptionLabel={option => option.name}
        groupBy={option => String(option ? option.group : '')}
        filterOptions={filterOptions}
        size="medium"
        onChange={onChangeFieldValue}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        renderInput={params => {
          return (
            <TextField
              {...params}
              placeholder="Add Indicator"
              variant="outlined"
              sx={{
                color: theme.palette.common.black,
              }}
            />
          );
        }}
        value={selectedField}
      />
      {fieldParams.length > 0 && (
        <div className={classes.paramsContainer}>
          {fieldParams.map((param: FieldParam) => {
            const ParamRenderer = param.renderer;
            const onChange = (value: FieldParamValue) => {
              onParamChange(param, value);
            };
            const selected = defaultSelectedFieldParams[param.key];
            return (
              <ParamRenderer
                selected={selected as string}
                // param={param as Omit<FieldParam, 'renderer' | 'key' | 'type'>}
                param={param as Omit<FieldParam, 'renderer' | 'type'>}
                onChange={onChange}
                key={v4()}
                defaultResolution={defaultResolution}
              />
            );
          })}
          <Button
            sx={{
              width: '100%',
              height: '30px',
              border: `1px solid ${theme.palette.common.black}`,
              color: theme.palette.common.black,
              textTransform: 'unset',
            }}
            onClick={onDoneButtonClick}
          >
            Accept
          </Button>
        </div>
      )}
    </div>
  );
}
