import { useQuery } from 'react-query';
// import { INSTRUMENTS_STATE_TIME } from 'services/Bots/useGetInstruments';
import { GetFilterMetaData } from 'services/apiServices';
import { Errors, GetReportDropDownData } from 'types/ApiServicesTypes';
import { signal } from '@preact/signals-react';
// const STALETIME = INSTRUMENTS_STATE_TIME;
export const Metadata = signal<GetReportDropDownData>(
  {} as GetReportDropDownData,
);
export default function useGetReportMetaData() {
  const objQuery = useQuery<GetReportDropDownData, Errors>(
    'GetFilterMetaData',
    () => GetFilterMetaData(),
    // {
    //   staleTime: STALETIME,
    // },
  );
  if (objQuery.isSuccess) {
    Metadata.value = objQuery.data;
  }
  return objQuery;
}
