import { useQuery } from 'react-query';
import { GetBotType } from 'services/apiServices';
import { Errors, GetBotTypeResult } from 'types/ApiServicesTypes';

export default function useGetBotType(id?: number) {
  const objQuery = useQuery<GetBotTypeResult, Errors>(['GetBrokers', id], () =>
    GetBotType(id),
  );
  return objQuery;
}
