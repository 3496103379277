import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { DeleteBot } from 'services/apiServices';
import { DeleteBotResult, Errors } from 'types/ApiServicesTypes';

export default function useDeleteBot(id: number) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<DeleteBotResult, Errors>(['DeleteBot', id], () =>
    DeleteBot(id)
      .then(res => {
        if (res.error) showAlert!(res.error);

        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
