import React from 'react';
import { Divider, Pagination, Box } from '@mui/material';
import { OrdersListLayoutProps } from 'types/ComponentTypes';
import { OrderItem } from 'app/design/speedBot/EntityItem';
import BotOrdersTable from '../speedBot/ComponentItem/Bots/BotOrdersTable';
import { Order } from 'types/ApiServicesTypes';
import { handleTouchMoveHandle, handleTouchStartHandle } from './swipeUtil';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

let eventDir: string | null = null;

const OrdersListLayout = ({
  data,
  page,
  totalPages,
  handleChangePagination,
  handleClick,
  handleChangeTab,
  isHidePagination,
}: {
  data: OrdersListLayoutProps;
  page: number;
  totalPages: number;
  handleChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => void;
  handleClick?: (orderData: Order) => void;
  handleChangeTab?: (newValue: number) => void;
  isHidePagination?: boolean;
}) => {
  const isMdUp = useGetMediaQueryUp('md');

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    handleTouchStartHandle(e);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const eventDirRes = handleTouchMoveHandle(e);
    if (eventDirRes) eventDir = eventDirRes;
  };
  const handleTouchEnd = () => {
    if (!isMdUp && eventDir === 'left' && handleChangeTab) {
      handleChangeTab(0);
      eventDir = '';
    }
  };

  return (
    <>
      <Box
        // sx={{ py: 1, height: '100%' }}
        sx={{ py: 1 }}
        onTouchStart={(touchStartEvent: React.TouchEvent<HTMLElement>) =>
          handleTouchStart(touchStartEvent)
        }
        onTouchMove={(touchMoveEvent: React.TouchEvent<HTMLElement>) =>
          handleTouchMove(touchMoveEvent)
        }
        onTouchEnd={() => handleTouchEnd()}
      >
        {isMdUp ? (
          <BotOrdersTable orders={data!} handleOrderRowClick={handleClick} />
        ) : (
          data &&
          data.map((order, i) => (
            <Box key={i}>
              <OrderItem order={order} handleClick={handleClick} />
              {data.length - 1 !== i && <Divider className="m-main-spacing" />}
            </Box>
          ))
        )}
        {data && data.length > 0 && !isHidePagination && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePagination}
            color="primary"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '1.5rem',
              pb: 3,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default OrdersListLayout;
