import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ContentCopy, TaskAlt } from '@mui/icons-material';
import CopyWebHookImg from 'assets/images/signalguide/webhook_url.png';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';

type Param = {
  signalType?: string;
  url?: string;
  img_url?: string;
};

const CopyWebHook = ({ url, img_url }: Param) => {
  const [showGreenTick, setShowGreenTick] = useState(false);
  const handleCoppy = () => {
    void navigator.clipboard.writeText(url!);
    setShowGreenTick(true);
    setTimeout(() => {
      setShowGreenTick(false);
    }, 700);
  };
  return (
    <Box className="m-main-spacing">
      <Typography py={1} variant="body1" fontWeight={600}>
        Add webhook URL
      </Typography>
      <ItemSeparator orientation="horizontal" variant="1px" />
      <Box
        display={'flex'}
        mt={2}
        flexDirection={{ sm: 'column-reverse', md: 'row' }}
      >
        <Box flex={1}>
          <Typography mt={{ sm: 2, md: 0 }}>
            Copy the WebHook URL as Shown here and Paste it into the
            &quot;Notifications&quot; Tab on the TradingView
          </Typography>
          <FormControl sx={{ mt: 3, mb: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="label-connection-url">
              Connection URL
            </InputLabel>
            <OutlinedInput
              type="text"
              id="label-connection-url"
              name="connection_url"
              label="Connection URL"
              value={url}
              readOnly
              fullWidth
              //   disabled={isReadonly ? isReadonly : !values.enable}
              endAdornment={
                <IconButton aria-label="Copy" onClick={handleCoppy}>
                  {showGreenTick ? (
                    <TaskAlt sx={{ color: 'success.main' }} />
                  ) : (
                    <ContentCopy />
                  )}
                </IconButton>
              }
            />
          </FormControl>
        </Box>
        <Box
          flex={1}
          alignItems={'center'}
          sx={{
            mt: 1,
            aspectRatio: '1/1',
            maxHeight: { sm: '400px', md: '600px' },
          }}
        >
          <img
            className="p-main-spacing"
            src={img_url ? img_url : CopyWebHookImg}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CopyWebHook;
