import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SignalModalTitleData } from '../BotGenerateSignalModal';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import BotGenerateSignalTemplateStraddle from './BotGenerateSignalTemplateStraddle';
import BotGenerateSignalTemplateStrangle from './BotGenerateSignalTemplateStrangle';
import BotGenerateSignalTemplateSpread from './BotGenerateSignalTemplateSpread';
import BotGenerateSignalTemplateIronFly from './BotGenerateSignalTemplateIronFly';
import BotGenerateSignalTemplateIronCondor from './BotGenerateSignalTemplateIronCondor';
import { Instrument } from 'types/Instrument';
import {
  generateTemplateSignal,
  getInstrumentByAtmValue,
  getNextWeekDate,
  NiftySymbols,
} from './utils';
import { ExpiryTypeValueOptions } from 'app/components/Builder/OptionsStrategy/FieldsData';
import { DropdownFieldValue } from 'app/components/Builder/OptionsStrategy/types';
import { sortBy } from 'lodash';
import { SignalTeplateValueType } from './types';
import { useGetTickData } from 'app/hooks/useGetTickData';
import { formatNumber, getArrowDirByNum } from 'utils/GenericFunctions';
import { SymbolsData } from 'types/Symbols';
import { ToastContext } from 'app/components/Context/ToastContext';
import { BotSignalParams } from 'types/ApiServicesTypes';

const BotGenerateSignalTemplateContents = ({
  instruments,
  handleChangeTitleData,
  handleAddToBasket,
  handleClickCancel,
}: {
  instruments: Instrument[];
  handleChangeTitleData: (data: SignalModalTitleData) => void;
  handleAddToBasket: (signals: BotSignalParams[]) => void;
  handleClickCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { showAlert } = useContext(ToastContext);
  const [tab, setTab] = useState(0);
  const [niftySymbol, setNiftySymbol] = useState(NiftySymbols[0].symbol);
  const [expiry, setExpiry] = useState('weekly_0');
  const [fetchSocket, setFetchSocket] = useState<string | null>(niftySymbol);
  const [symbolData, setSymbolData] = useState<SymbolsData>({});
  // const symbolData = useRef<SymbolsData>({});
  const selectedSymbol = useMemo(() => {
    return NiftySymbols.filter(x => x.symbol === niftySymbol)[0];
  }, [niftySymbol]);

  const liveFeedData = useGetTickData(fetchSocket ? [fetchSocket] : [], true);

  useEffect(() => {
    delete liveFeedData[niftySymbol];
    setFetchSocket(niftySymbol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [niftySymbol, tab]);

  useEffect(() => {
    handleChangeTitleData({ symbol: '', qty: 1, transactionType: 'BUY' });
  }, [handleChangeTitleData]);

  useEffect(() => {
    if (fetchSocket && liveFeedData && liveFeedData[fetchSocket]) {
      setSymbolData(prev => {
        return {
          ...prev,
          [fetchSocket]: liveFeedData[fetchSocket],
        };
      });
      setFetchSocket(null);
    }
  }, [fetchSocket, liveFeedData]);

  const expiryTypeValueOptions = useMemo(() => {
    let options = ExpiryTypeValueOptions as DropdownFieldValue[];
    // if (niftySymbol === FIN_NIFTY_SYMBOL)
    // options = options.filter(x => x.key.startsWith('weekly'));

    options = options.filter(x => x.key.startsWith('weekly'));

    return options;
  }, []);

  const nextWeekDate = useMemo(() => {
    const instrumentData = instruments.filter(x =>
      selectedSymbol.checkingSymbols.includes(x.name),
    );
    // return getFutureNextDayDate('thursday', expiry === 'weekly_1');
    return getNextWeekDate(instrumentData, expiry === 'weekly_0' ? 0 : 1);
  }, [expiry, instruments, selectedSymbol.checkingSymbols]);

  const selectedInstruments = useMemo(() => {
    if (!selectedSymbol) return [];

    let instrumentData = instruments.filter(
      x =>
        selectedSymbol.checkingSymbols.includes(x.name) &&
        x.expiry === nextWeekDate,
    );
    instrumentData = sortBy(instrumentData, 'strike_price');
    return instrumentData;
  }, [instruments, nextWeekDate, selectedSymbol]);

  const zeroAtmInstrument = useMemo(() => {
    return getInstrumentByAtmValue(
      selectedInstruments,
      0,
      symbolData[niftySymbol],
    );
  }, [niftySymbol, selectedInstruments, symbolData]);

  const getAtmInstrument = useCallback(
    (atmValue: number) => {
      if (!zeroAtmInstrument) return 0;
      return (
        zeroAtmInstrument.strike_price + atmValue * selectedSymbol.stepSize
      );
    },
    [zeroAtmInstrument, selectedSymbol],
  );

  const handleGenerateSignal = useCallback(
    (type: string, val: SignalTeplateValueType) => {
      const signals = generateTemplateSignal(
        type,
        val,
        zeroAtmInstrument,
        selectedInstruments,
        selectedSymbol,
      );

      if (!signals) {
        showAlert!(t('bot_signals.invalid_signal_generate'), 'warning');
        return;
      }

      handleAddToBasket(signals);

      // handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zeroAtmInstrument, selectedInstruments],
  );

  return (
    <>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ position: 'relative' }}>
            <ContentTabs
              noPadding={true}
              scrollable={true}
              value={tab}
              onChange={(e, newVal) => setTab(newVal)}
              tabs={[
                { label: t('buttons.straddle') },
                { label: t('buttons.strangle') },
                { label: t('buttons.spread') },
                { label: t('buttons.iron_fly') },
                { label: t('buttons.iron_condor') },
              ]}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  sx={{ mb: { xs: 3, md: 2 }, position: 'relative' }}
                >
                  <InputLabel id="label-value1">
                    {t('bot_signals.template_form.nifty_symbol')}
                  </InputLabel>
                  <Select
                    labelId="label-value1"
                    name="value1"
                    value={niftySymbol}
                    label={t('bot_signals.template_form.nifty_symbol')}
                    onChange={e => {
                      setNiftySymbol(e.target.value);
                    }}
                  >
                    {NiftySymbols.map(symbol => (
                      <MenuItem value={symbol.symbol} key={symbol.symbol}>
                        {symbol.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {liveFeedData &&
                    liveFeedData[niftySymbol] &&
                    liveFeedData[niftySymbol].Close && (
                      <Box
                        className="extra-label"
                        sx={{
                          color: liveFeedData[niftySymbol]
                            ? liveFeedData[niftySymbol].Close -
                                liveFeedData[niftySymbol].Open >
                              0
                              ? 'success.main'
                              : 'error.main'
                            : 'text.primary',
                        }}
                      >
                        <Typography variant="body2">
                          {liveFeedData[niftySymbol]
                            ? `${getArrowDirByNum(
                                liveFeedData[niftySymbol].Close -
                                  liveFeedData[niftySymbol].Open,
                              )} ₹${formatNumber(
                                liveFeedData[niftySymbol].Close,
                                false,
                                0,
                              )}`
                            : `₹0`}
                        </Typography>
                      </Box>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="label-expiry">
                    {t('bot_signals.template_form.expiry')}
                  </InputLabel>
                  <Select
                    labelId="label-expiry"
                    name="expiry"
                    value={expiry}
                    label={t('bot_signals.template_form.expiry')}
                    onChange={e => {
                      setExpiry(e.target.value);
                    }}
                  >
                    {expiryTypeValueOptions.map(interval => (
                      <MenuItem value={interval.key} key={interval.key}>
                        {interval.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box className="extra-label">
                    <Typography variant="body2">{nextWeekDate}</Typography>
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          {tab === 0 ? (
            <BotGenerateSignalTemplateStraddle
              niftySymbol={niftySymbol}
              handleGenerateSignal={handleGenerateSignal}
              getAtmInstrument={getAtmInstrument}
              handleClickCancel={handleClickCancel}
            />
          ) : tab === 1 ? (
            <BotGenerateSignalTemplateStrangle
              niftySymbol={niftySymbol}
              handleGenerateSignal={handleGenerateSignal}
              getAtmInstrument={getAtmInstrument}
              handleClickCancel={handleClickCancel}
            />
          ) : tab === 2 ? (
            <BotGenerateSignalTemplateSpread
              niftySymbol={niftySymbol}
              handleGenerateSignal={handleGenerateSignal}
              getAtmInstrument={getAtmInstrument}
              handleClickCancel={handleClickCancel}
            />
          ) : tab === 3 ? (
            <BotGenerateSignalTemplateIronFly
              niftySymbol={niftySymbol}
              handleGenerateSignal={handleGenerateSignal}
              getAtmInstrument={getAtmInstrument}
              handleClickCancel={handleClickCancel}
            />
          ) : tab === 4 ? (
            <BotGenerateSignalTemplateIronCondor
              niftySymbol={niftySymbol}
              handleGenerateSignal={handleGenerateSignal}
              getAtmInstrument={getAtmInstrument}
              handleClickCancel={handleClickCancel}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default memo(BotGenerateSignalTemplateContents);
