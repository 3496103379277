import { ReactElement } from 'react';
import type { DropdownFieldRenderer } from './fields-params-renderers/DropdownFieldRenderer';
import type { InputFieldRenderer } from './fields-params-renderers/InputFieldRenderer';

type BaseFieldParam = {
  name: string;
  key: string;
  enabled: boolean;
  renderer: typeof DropdownFieldRenderer | typeof InputFieldRenderer;
};

export type DropdownFieldValue = {
  key: string;
  value: string | number | boolean;
};

export type DropdownFieldParam = BaseFieldParam & {
  type: 'dropdown';
  values: Array<DropdownFieldValue>;
  defaultValue: DropdownFieldValue;
};

export type NumberInputFieldParam = BaseFieldParam & {
  type: 'number';
  defaultValue: number;
};

export type InputFieldParam = BaseFieldParam & {
  type: 'input';
  defaultValue: string;
};

export type FieldParam =
  | DropdownFieldParam
  | NumberInputFieldParam
  | InputFieldParam;

export enum FieldType {
  Indicators = 'indicators',
  Operator = 'operators',
  Comparators = 'comparators',
  Condition = 'condition',
  Bracket = 'brackets',
  MathFunctions = 'mathfunctions',
}

export type Field = {
  name: string;
  icon?: ReactElement;
  shortName?: string;
  description: string;
  type: FieldType;
  key: string;
  params: FieldParam[];
  syntax?: string;
  conditionTextDesc: string;
};

export type FieldParamValue = DropdownFieldValue['key'] | string | number;

export type FieldData = {
  key: string;
  type: string;
  params: { [key: string]: FieldParamValue | FieldData[] };
};

export type FieldDataWithValidate = {
  conditions: FieldData[];
  isConditionValid: boolean;
};
export type FieldDataConditions = {
  entry_on_underlying?: boolean;
  exit_on_underlying?: boolean;
  isConditionValid: boolean;
  entry_conditions: FieldData[];
  exit_conditions: FieldData[];
  resolution: string;
};
