import { useState, useCallback, createContext, memo } from 'react';

export type NotificationPopoverState = {
  open: boolean;
  toggle: () => void;
};

export type NotificationPopoverContextProps = {
  toggle?: () => void;
  open?: boolean;
};

export const PopoverContext = createContext<NotificationPopoverContextProps>({
  open: false,
  toggle: () => {
    //
  },
});

const NotificationProviderComp = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  return (
    <PopoverContext.Provider value={{ open, toggle }}>
      {children}
    </PopoverContext.Provider>
  );
};

export const NotificationPopoverContextProvider = memo(
  NotificationProviderComp,
);
