import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useEffect, useState } from 'react';
import { encodeUrlData } from 'utils/GenericFunctions';
import { PAGES } from '../Common/Breadcrumb/types';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

const VerifyPhoneDialog = ({
  countryCode,
  phone,
}: {
  countryCode?: string;
  phone?: string;
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const totalVisitCount = parseInt(
      localStorage.getItem('totalVisitCount') || '0',
      10,
    );
    const sessionVisitCount = parseInt(
      sessionStorage.getItem('sessionVisitCount') || '0',
      10,
    );

    if (
      totalVisitCount > 0 &&
      totalVisitCount % 5 === 0 &&
      sessionVisitCount < 7
    ) {
      setOpen(true);
      sessionStorage.setItem(
        'sessionVisitCount',
        (sessionVisitCount + 1).toString(),
      );
    }

    localStorage.setItem('totalVisitCount', (totalVisitCount + 1).toString());
  }, []);

  const breadcrumb = useBreadcrumbManager();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('menu.verify_phone.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="menu.verify_phone.description" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{ display: 'flex', flexDirection: 'row' }}
          className="m-main-spacing"
        >
          <MuiButton
            size="medium"
            sx={{ mb: '16px' }}
            variant="outlined"
            onClick={handleClose}
          >
            <FormattedMessage id="menu.verify_phone.skip" />
          </MuiButton>
          <MuiButton
            size="medium"
            variant="contained"
            onClick={() =>
              breadcrumb.push(
                `/profile/edit/mobile/${encodeUrlData(
                  countryCode || '91',
                )}/${encodeUrlData(phone as string)}`,
                PAGES.PROFILE,
                `/profile`,
                t('menu.page_title.profile'),
              )
            }
            autoFocus
            sx={{ margin: '0px 0px 16px 12px' }}
          >
            <FormattedMessage id="menu.verify_phone.verify_mobile" />
          </MuiButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyPhoneDialog;
