import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { Avatar, Badge, BottomNavigationAction, Box } from '@mui/material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import MuiBottomNavigation from 'app/design/uiComponents/MuiBottomNavigation';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import {
  AccountCircle,
  AccountCircleOutlined,
  Assessment,
  AssessmentOutlined,
  Link,
  LinkOutlined,
  TipsAndUpdates,
  TipsAndUpdatesOutlined,
} from '@mui/icons-material';
import { isUserLoggedIn } from 'utils/auth';
import LoginIcon from '@mui/icons-material/Login';
import LockIcon from '@mui/icons-material/Lock';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { getFirstSecondLetterfromName } from 'utils/GenericFunctions';
import { GetProfileResult } from 'types/ApiServicesTypes';
// import botIcon from '../../../../assets/images/icons/K9OX6h01.svg';
// import botIconOut from '../../../../assets/images/icons/hisdRY01.svg';
// import { SVGComponent1, SVGComponent } from 'app/components/Bots/Svg';

function AppbarNavigationMenu({
  activeNav,
  open,
  profile,
}: {
  activeNav: string;
  open: (val: boolean) => void;
  profile?: GetProfileResult;
}) {
  const handleModalOpen = () => {
    open(true);
  };
  const theme = useTheme();
  const history = useHistory();
  const isLoggedIn = isUserLoggedIn();
  const isTraders = useIsFeatureFlag([FEATURES.IS_TRADERS]);
  const isDiscover = useIsFeatureFlag([FEATURES.IS_DISCOVER]);
  const isOptionChain = useIsFeatureFlag([FEATURES.IS_OPTIONCHAIN]);
  const isTemplates = useIsFeatureFlag([FEATURES.IS_TEMPLATES]);
  const isBots = useIsFeatureFlag([FEATURES.IS_BOTS]);
  const isMyPortfolio = useIsFeatureFlag([FEATURES.IS_MY_PORTFOLIO]);
  const isReport = useIsFeatureFlag([FEATURES.IS_REPORT]);
  const isHomePage = useIsFeatureFlag([FEATURES.IS_HOME_PAGE]);
  const isTradeIdeas = useIsFeatureFlag([FEATURES.IS_TRADE_IDEAS]);
  const isMybotsInPortfolio = useIsFeatureFlag([
    FEATURES.IS_MYBOTS_IN_PORTFOLIO,
  ]);

  return (
    <MuiBottomNavigation
      showLabels
      value={
        activeNav === 'portfolio'
          ? 'portfolio'
          : activeNav === 'discover'
          ? 'discover/bots'
          : activeNav === 'profile'
          ? 'menu'
          : activeNav
      }
      onChange={(event, newValue) => {
        if (newValue === 'login') {
          handleModalOpen();
          // const refPath = history.location.pathname + history.location.search;
          // history.push(`/signin?ref=${refPath}`);
        } else history.push(`/${newValue}`);
      }}
      sx={{
        height: theme => theme.spacing(8),
        backgroundColor: theme => theme.palette.common.white,
        [theme.breakpoints.up('md')]: {
          flex: 1,
          mx: 1,
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
        },
      }}
    >
      {isHomePage && (
        <BottomNavigationAction
          label={`Home`}
          icon={activeNav === 'home' ? <HomeIcon /> : <HomeOutlinedIcon />}
          value="home"
          sx={{ mt: '-2px', minWidth: '70px' }}
        />
      )}

      {/* {isLoggedIn && (
        <BottomNavigationAction
          label={`${isMyPortfolio ? 'My ' : ''}Portfolio`}
          icon={
            activeNav === 'portfolio' ? (
              <LeaderboardIcon />
            ) : (
              <LeaderboardOutlinedIcon />
            )
          }
          value="portfolio"
          sx={{ mt: '-2px', minWidth: '70px' }}
        />
      )} */}
      {isLoggedIn && (
        <BottomNavigationAction
          label={`${isMyPortfolio ? 'My ' : ''}Bots`}
          icon={
            activeNav === 'portfolio' ? (
              <LeaderboardIcon />
            ) : (
              <LeaderboardOutlinedIcon />
            )
          }
          value="portfolio"
          sx={{ mt: '-2px', minWidth: '70px' }}
        />
      )}
      {!isLoggedIn && (
        <BottomNavigationAction
          label={`${isMyPortfolio ? 'My ' : ''}Bots`}
          icon={
            activeNav === 'portfolio' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <LeaderboardIcon />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <LeaderboardIcon />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {isLoggedIn && isDiscover && (
        <BottomNavigationAction
          label="Discover"
          icon={
            activeNav === 'discover' ? <StoreIcon /> : <StoreOutlinedIcon />
          }
          value="discover/bots"
          sx={{ mt: '-2px', minWidth: '70px' }}
        />
      )}
      {!isLoggedIn && isDiscover && (
        <BottomNavigationAction
          label="Discover"
          icon={
            activeNav === 'discover' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <StoreIcon />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <StoreOutlinedIcon />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {isLoggedIn && isTraders && (
        <BottomNavigationAction
          label="Traders"
          icon={
            activeNav === 'traders' ? <PeopleIcon /> : <PeopleOutlinedIcon />
          }
          value="traders"
          sx={{ mt: '-2px', minWidth: '70px' }}
        />
      )}
      {!isLoggedIn && isTraders && (
        <BottomNavigationAction
          label="Traders"
          icon={
            activeNav === 'traders' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <PeopleIcon />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <PeopleOutlinedIcon />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {isLoggedIn && isBots && !isMybotsInPortfolio && (
        <BottomNavigationAction
          label="My Bots"
          icon={
            activeNav === 'bots' ? <WidgetsIcon /> : <WidgetsOutlinedIcon />
          }
          value="bots"
          sx={{ mt: '-2px', minWidth: '70px' }}
        />
      )}
      {!isLoggedIn && isBots && !isMybotsInPortfolio && (
        <BottomNavigationAction
          label="My Bots"
          icon={
            activeNav === 'bots' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <WidgetsIcon />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <WidgetsOutlinedIcon />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {isLoggedIn && isOptionChain && (
        <BottomNavigationAction
          label="Option chain"
          icon={activeNav === 'optionchain' ? <Link /> : <LinkOutlined />}
          value="option-chain"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {!isLoggedIn && isOptionChain && (
        <BottomNavigationAction
          label="Option chain"
          icon={
            activeNav === 'optionchain' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <Link />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <LinkOutlined />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {isLoggedIn && isTradeIdeas && (
        <BottomNavigationAction
          label="Trade Ideas"
          icon={
            activeNav === 'tradeideas' ? (
              <TipsAndUpdates />
            ) : (
              <TipsAndUpdatesOutlined />
            )
          }
          value="trade-ideas"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {!isLoggedIn && isTradeIdeas && (
        <BottomNavigationAction
          label="Trade Ideas"
          icon={
            activeNav === 'tradeideas' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <TipsAndUpdates />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <TipsAndUpdatesOutlined />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {!isLoggedIn && isReport && (
        <BottomNavigationAction
          label="Report"
          icon={
            activeNav === 'bots' ? (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <Assessment />
              </Badge>
            ) : (
              <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                <AssessmentOutlined />
              </Badge>
            )
          }
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        />
      )}
      {isTemplates && (
        <BottomNavigationAction
          label="Templates"
          icon={
            activeNav === 'templates' ? (
              <WidgetsIcon />
            ) : (
              <WidgetsOutlinedIcon />
            )
          }
          value="templates"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            display: {
              xs: isLoggedIn && isDiscover ? 'none' : 'inline-flex',
              md: 'inline-flex',
            },
          }}
        />
      )}
      {isLoggedIn && isReport && (
        <BottomNavigationAction
          label="Report"
          icon={
            activeNav === 'report' ? <Assessment /> : <AssessmentOutlined />
          }
          value="report"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            display: {
              xs: isLoggedIn ? 'none' : 'inline-flex',
              md: 'inline-flex',
            },
          }}
        />
      )}

      {isLoggedIn && (
        <BottomNavigationAction
          label="Menu"
          icon={
            profile && profile.name.trim().length > 0 ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  sx={{
                    backgroundColor: theme.palette.common.black,
                    color: theme.palette.common.white,
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                    border: '1px solid',
                    borderColor: theme.palette.common.black,
                    borderRadius: '50%',
                    fontSmooth: 'auto',
                  }}
                >
                  {getFirstSecondLetterfromName(profile?.name)}
                </Avatar>
              </Box>
            ) : activeNav === 'menu' ? (
              <AccountCircle />
            ) : (
              <AccountCircleOutlined />
            )
          }
          value="menu"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            display: { xs: 'flex', md: 'none' },
          }}
        />
      )}
      {!isLoggedIn && (
        <BottomNavigationAction
          label="Sign In"
          icon={<LoginIcon />}
          value="login"
          sx={{
            mt: '-2px',
            minWidth: '70px',
            display: { xs: 'flex', md: 'none' },
          }}
        />
      )}
    </MuiBottomNavigation>
  );
}

export default AppbarNavigationMenu;
