import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import { PositionType, TradesType } from '../types';
import i18next from 'i18next';
import { Box, Typography } from '@mui/material';
import { formatDateString } from 'utils/GenericFunctions';

const LotsDetails = ({ positions }: { positions: Array<PositionType> }) => {
  return (
    <Box>
      {positions.map(position => (
        <Box key={position.strike} display={'flex'}>
          <Typography
            variant="body2"
            p={0.2}
            color={
              position.direction.toUpperCase()[0] === 'L'
                ? 'success.main'
                : 'error.main'
            }
          >
            {position.direction.toUpperCase()[0]}
          </Typography>
          <Typography variant="body2" p={0.2}>
            {position.strike}
          </Typography>
          <Typography variant="body2" p={0.2}>
            {position.contract_type.toUpperCase()}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const TableCell = (cellData: number | null, isProfitable: boolean | null) => (
  <Typography
    width={'fit-content'}
    variant="body2"
    bgcolor={
      isProfitable !== null ? (isProfitable ? '#e8f5e8dd' : '#fff0f2dd') : ''
    }
    color={
      isProfitable !== null
        ? isProfitable
          ? 'success.main'
          : 'error.main'
        : ''
    }
    sx={{
      p: 0.5,
      borderRadius: 2,
    }}
  >
    {(cellData && `${cellData.toFixed(1)}%`) || '-'}
  </Typography>
);

const tradeRows = ({ trade }: { trade: TradesType }): TableRowCellsData[] => {
  return [
    {
      content: (
        <>
          <Typography variant="body2">{trade.symbol}</Typography>
          <Typography variant="body2">{trade.strategy_type}</Typography>
          <Typography variant="body3">
            {formatDateString(trade.candle_time, 'DD MMM YYYY HH:mm')}
          </Typography>
        </>
      ),
    },
    {
      content: LotsDetails({ positions: trade.positions }),
    },
    {
      content:
        (trade.payoff.risk_to_reward &&
          trade.payoff.risk_to_reward.toFixed(1)) ||
        '-',
    },
    {
      content: TableCell(
        trade.payoff.pop,
        trade.payoff.pop ? trade.payoff.pop > 50 : null,
      ),
    },
    {
      content: TableCell(
        trade.payoff.pmp,
        trade.payoff.pmp ? trade.payoff.pmp > 50 : null,
      ),
    },
    {
      content: TableCell(
        trade.payoff.pml,
        trade.payoff.pml ? trade.payoff.pml < 20 : null,
      ),
    },
    {
      content: TableCell(
        trade.payoff.alpha,
        trade.payoff.alpha ? trade.payoff.alpha > 50 : null,
      ),
    },
    {
      content: (
        <Typography
          variant="body2"
          bgcolor={
            trade.payoff.ev
              ? trade.payoff.ev > 0
                ? '#e8f5e8dd'
                : '#fff0f2dd'
              : ''
          }
          color={
            trade.payoff.ev
              ? trade.payoff.ev > 0
                ? 'success.main'
                : 'error.main'
              : ''
          }
          width={'fit-content'}
        >
          {(trade.payoff.ev && trade.payoff.ev && `${trade.payoff.ev}`) || '-'}
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body2"
          bgcolor={'#e8f5e8dd'}
          color={'success.main'}
          width={'fit-content'}
        >
          {(trade.payoff.max_gain &&
          Math.abs(Number(trade.payoff.max_gain)) >= 1000000000
            ? 'Infinity'
            : `${trade.payoff.max_gain}`) || '-'}
        </Typography>
      ),
    },
    {
      content: (
        <Typography
          variant="body2"
          bgcolor={'#fff0f2dd'}
          color={'error.main'}
          width={'fit-content'}
        >
          {(trade.payoff.max_loss &&
          Math.abs(Number(trade.payoff.max_loss)) >= 1000000000
            ? '-Infinity'
            : `${trade.payoff.max_loss}`) || '-'}
        </Typography>
      ),
    },
  ];
};

const createTradeTableColumnsRows = (
  tradeData: Array<TradesType> | undefined,
  handleTradeRowClick?: (tradeData: TradesType) => void,
): { rows: TableRow<TradesType>[]; columns: TableColumns[] } => {
  const rows: TableRow<TradesType>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('trade_ideas.position') },
    { text: i18next.t('trade_ideas.leg') },
    {
      text: i18next.t('trade_ideas.risk_to_reward'),
      key: 'payoff.risk_to_reward',
    },
    { text: i18next.t('trade_ideas.pop'), key: 'payoff.pop' },
    { text: i18next.t('trade_ideas.pmp'), key: 'payoff.pmp' },
    { text: i18next.t('trade_ideas.pml'), key: 'payoff.pml' },
    { text: i18next.t('trade_ideas.alpha'), key: 'payoff.alpha' },
    {
      text: i18next.t('trade_ideas.ev'),
      key: 'payoff.ev',
    },
    {
      text: i18next.t('trade_ideas.max_profit'),
      key: 'payoff.max_gain',
    },
    { text: i18next.t('trade_ideas.max_loss'), key: 'payoff.max_loss' },
  ];

  if (!tradeData) return { rows, columns };

  tradeData.map(trade => {
    (rowCellsData = tradeRows({ trade: trade })),
      rows.push({
        rowCellsData,
        rowData: trade,
        handleClick: () => handleTradeRowClick?.(trade),
      });
  });
  return { rows, columns };
};

export default createTradeTableColumnsRows;
