import { useQuery } from 'react-query';
import { GetAuthor } from 'services/apiServices';
import { Errors, GetAuthorResult } from 'types/ApiServicesTypes';

export default function useGetAuthor(id: number) {
  const objQuery = useQuery<GetAuthorResult, Errors>(['GetAuthor', id], () =>
    GetAuthor(id),
  );

  return objQuery;
}
