import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Box,
  Divider,
  Snackbar,
  Alert,
  useTheme,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Item, ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import { GetProfileResult, Errors } from 'types/ApiServicesTypes';
import { GetProfile } from 'services/apiServices';
import useGetBrokers from 'services/Menu/useGetBrokers';
import { useDoSingOut } from 'services/Auth';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import Footer from 'app/components/Layouts/MainLayout/Footer';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import {
  downloadWithCustomFileName,
  encodeUrlData,
  formatNumber,
  openInNewTab,
} from 'utils/GenericFunctions';
import { Link as RouterLink } from 'react-router-dom';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import {
  Brightness4,
  Brightness7,
  ContentCopy,
  LibraryAddCheckOutlined,
} from '@mui/icons-material';
import VerifyPhoneDialog from 'app/components/Menu/VerifyPhoneDialog';
import moment from 'moment';
import ProductTourModal from 'app/components/Common/ProductTour/ProductTourModal';
import { themeContext } from 'app/components/Context/ToggleThemeContext';

export function MenuPage() {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const { doSignOut } = useDoSingOut();
  const isCreditWallet = useIsFeatureFlag([FEATURES.IS_CREDIT_WALLET]);
  const isTemplates = useIsFeatureFlag([FEATURES.IS_TEMPLATES]);
  const isReport = useIsFeatureFlag([FEATURES.IS_REPORT]);
  const isDiscover = useIsFeatureFlag([FEATURES.IS_DISCOVER]);
  const isMdDown = useGetMediaQueryDown('md');
  const isShowReferral = useIsFeatureFlag([FEATURES.IS_REFERRAL_CODE]);
  const isGetOrderSheet = useIsFeatureFlag([FEATURES.IS_GET_ORDERSHEET]);
  const isSideBarView = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);
  const isShowProductTour = useIsFeatureFlag([FEATURES.IS_SHOW_PRODUCT_TOUR]);
  const [selectedIndex, setSelectedIndex] = useState([0]);
  const isVerifyPhoneDialog = useIsFeatureFlag([
    FEATURES.IS_VERIFY_PHONE_DIALOG,
  ]);
  const isHelpDeskInSb = useIsFeatureFlag([FEATURES.IS_HELP_DESK_IN_SB]);
  const isDarkThemeFeature = useIsFeatureFlag([FEATURES.IS_DARK_THEME_FEATURE]);
  const { isDarkTheme, toggleTheme } = useContext(themeContext);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopied(false);
  };
  useEffect(() => {
    breadcrumb.resetBreadcrumpPaths();
  }, [breadcrumb]);
  const [copied, setCopied] = useState<boolean>(false);
  const [ticked, setTicked] = useState<boolean>(false);

  const { data: profileDetails, isLoading } = useQuery<
    GetProfileResult,
    Errors
  >('GetProfile', () => GetProfile());
  const { data: stiteInfo } = useGetSiteInfo();
  const handleLogout = () => {
    doSignOut();
  };
  const handleClick = () => {
    setCopied(true);
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert('Content copied to clipboard successfully');
        // setCopied(true);
        handleClick();
        setTicked(true);
        setTimeout(() => setTicked(false), 600);
      })
      .catch(err => {
        alert(`Error copying content to clipboard: ${err}`);
      });
  };
  const { data: brokersData } = useGetBrokers();

  const [openTourModal, setOpenTourModal] = useState(false);
  const handleCloseTourModal = () => {
    setOpenTourModal(false);
  };
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Menu</title>
        <meta name="description" content="Menu Page" />
      </Helmet>
      <CircularLoader open={isLoading} />

      <Box
        className="sticky-top"
        sx={{ backgroundColor: theme.palette.common.white }}
      >
        <PageHeader
          variant="market"
          isMenu={true}
          title={<FormattedMessage id="menu.page_title.menu" />}
          {...(isCreditWallet &&
            profileDetails && {
              rightElement: (
                <Box
                  textAlign={'right'}
                  component={RouterLink}
                  to={'/menu/wallet'}
                >
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color={
                      profileDetails.wallet_balance < 0
                        ? 'error.main'
                        : 'text.primary'
                    }
                  >
                    {profileDetails.wallet_balance < 0 ? '-' : ''}
                    {formatNumber(profileDetails.wallet_balance)}
                  </Typography>
                  <Typography variant="caption">
                    {t('menu.wallet_balance')}
                  </Typography>
                </Box>
              ),
            })}
        />
      </Box>
      {profileDetails && (
        <>
          <Box sx={{ mt: 1, flex: 1 }}>
            <Box>
              <Item
                variant="itemSubtextIcon"
                title={profileDetails.name ? profileDetails.name : '-'}
                subtext={<FormattedMessage id="menu.view_edit_account" />}
                icon={<ChevronRightIcon />}
                handleClick={() =>
                  breadcrumb.push(
                    '/profile',
                    PAGES.MENU,
                    `/menu`,
                    t('menu.page_title.menu'),
                  )
                }
              />
              <Divider
                className="m-main-spacing"
                sx={{ borderColor: 'grey.200' }}
              />
              <FeatureFlag features={[FEATURES.IS_PLANS]} fallback={<></>}>
                <Item
                  variant="itemSubtextIcon"
                  title={<FormattedMessage id="menu.plan" />}
                  subtext={
                    profileDetails.user_plan && profileDetails.user_plan.plan
                      ? profileDetails.user_plan.plan.plan_name
                      : 'Free'
                  }
                  icon={<ChevronRightIcon />}
                  handleClick={() =>
                    breadcrumb.push(
                      '/menu/plan-details',
                      PAGES.MENU,
                      `/menu`,
                      t('menu.page_title.menu'),
                    )
                  }
                />
                <Divider
                  className="m-main-spacing"
                  sx={{ borderColor: 'grey.200' }}
                />
              </FeatureFlag>
              {isCreditWallet && (
                <>
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="menu.wallet" />}
                    icon={<ChevronRightIcon />}
                    handleClick={() =>
                      breadcrumb.push(
                        '/menu/wallet',
                        PAGES.MENU,
                        `/menu`,
                        t('menu.page_title.menu'),
                      )
                    }
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
              <Item
                variant="itemIcon"
                title={<FormattedMessage id="menu.notifications" />}
                icon={<ChevronRightIcon />}
                handleClick={() =>
                  breadcrumb.push(
                    '/menu/notifications',
                    PAGES.MENU,
                    `/menu`,
                    t('menu.page_title.menu'),
                  )
                }
              />
              {/* <Item
                variant="itemSwitch"
                title={<FormattedMessage id="menu.notifications" />}
                switch={
                  <MuiSwitchUnstyled
                    checked={notificationCheck}
                    onChange={e => {
                      handleChangeNotification(e);
                    }}
                    sx={{ flex: 'inherit' }}
                  />
                }
              /> */}
              <Divider
                className="m-main-spacing"
                sx={{ borderColor: 'grey.200' }}
              />
              {(isMdDown || !isSideBarView) && (
                <>
                  <Item
                    variant="itemValueIcon"
                    title={<FormattedMessage id="common.broker" />}
                    value={brokersData ? brokersData.length.toString() : '0'}
                    icon={<ChevronRightIcon />}
                    handleClick={() =>
                      breadcrumb.push(
                        '/broker',
                        PAGES.MENU,
                        `/menu`,
                        t('menu.page_title.menu'),
                      )
                    }
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
              {isTemplates && isMdDown && isDiscover && (
                <>
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="menu.templates" />}
                    icon={<ChevronRightIcon />}
                    handleClick={() =>
                      breadcrumb.push(
                        '/templates',
                        PAGES.MENU,
                        `/menu`,
                        t('menu.page_title.menu'),
                      )
                    }
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
              {isReport && isMdDown && (
                <>
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="report.report_title" />}
                    icon={<ChevronRightIcon />}
                    handleClick={() =>
                      breadcrumb.push(
                        '/report',
                        PAGES.MENU,
                        `/menu`,
                        t('menu.page_title.menu'),
                      )
                    }
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
              {isHelpDeskInSb ? (
                <Item
                  variant="itemIcon"
                  title={<FormattedMessage id="menu.help" />}
                  icon={<ChevronRightIcon />}
                  handleClick={() =>
                    breadcrumb.push(
                      '/menu/help',
                      PAGES.MENU,
                      `/menu`,
                      t('menu.page_title.menu'),
                    )
                  }
                />
              ) : (
                <Item
                  variant="itemIcon"
                  title={<FormattedMessage id="menu.help" />}
                  icon={<ChevronRightIcon />}
                  handleClick={() => {
                    openInNewTab(
                      stiteInfo && stiteInfo.help_link
                        ? stiteInfo.help_link
                        : 'https://help.speedbot.tech/speedbot-mobile-app/',
                    );
                  }}
                />
              )}

              <FeatureFlag
                features={[FEATURES.IS_SUPPORT_MENU]}
                fallback={<></>}
              >
                <Divider
                  className="m-main-spacing"
                  sx={{ borderColor: 'grey.200' }}
                />
                <Item
                  variant="itemIcon"
                  title={<FormattedMessage id="menu.support" />}
                  icon={<ChevronRightIcon />}
                  handleClick={() => {
                    openInNewTab(
                      stiteInfo && stiteInfo.support_link
                        ? stiteInfo.support_link
                        : 'https://speedbot.tech/helpdesk/',
                    );
                  }}
                />
              </FeatureFlag>
              {isShowReferral && (
                <>
                  <ItemSeparator variant="1px" className="m-main-spacing" />
                  <Box
                    sx={{
                      py: 2,
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                    }}
                    className="p-main-spacing"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() =>
                      breadcrumb.push(
                        `/referral/${encodeUrlData(
                          profileDetails.referral_code,
                        )}`,
                        PAGES.MENU,
                        `/menu`,
                        t('menu.page_title.menu'),
                      )
                    }
                  >
                    <Box>
                      <Typography variant="body2" color="primary.main">
                        <FormattedMessage id="menu.refnearn" />
                      </Typography>

                      <Typography
                        variant="body2"
                        fontWeight={400}
                        color="primary.light"
                        sx={{ mt: 0.5 }}
                      >
                        {profileDetails.referral_code
                          ? profileDetails.referral_code
                          : '-'}
                      </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'}>
                      <Box
                        mr={1}
                        onClick={e => {
                          const referralUrl = `${window.location.origin}/signup/start?referral=${profileDetails.referral_code}`;
                          e.stopPropagation();

                          copyToClipboard(referralUrl);
                          handleClick();
                        }}
                      >
                        {!ticked ? (
                          <ContentCopy />
                        ) : (
                          <LibraryAddCheckOutlined color="success" />
                        )}
                      </Box>
                      <ChevronRightIcon />
                    </Box>
                  </Box>
                </>
              )}
              {!isMdDown && isShowProductTour && (
                <>
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="menu.product_tour" />}
                    icon={<></>}
                    handleClick={() => setOpenTourModal(true)}
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
              {isGetOrderSheet && (
                <>
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="menu.order_sheet" />}
                    icon={<></>}
                    handleClick={async () => {
                      await downloadWithCustomFileName(
                        `${location.origin}/api/trade/journal`,
                        `${moment(Date.now())
                          .format('DD-MMM-YYYY')
                          .toUpperCase()} Order Sheet (AASMA-TAP)`,
                      );
                    }}
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
              {isDarkThemeFeature && (
                <>
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="menu.change_theme" />}
                    icon={isDarkTheme ? <Brightness4 /> : <Brightness7 />}
                    handleClick={() => toggleTheme()}
                  />
                  <Divider
                    className="m-main-spacing"
                    sx={{ borderColor: 'grey.200' }}
                  />
                </>
              )}
            </Box>
          </Box>
          <Box className="m-main-spacing" sx={{ mt: 4, mb: 0 }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t('menu.version', { name: 'SpeedBot' })}
            </Typography>
            <MuiButton
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleLogout}
            >
              <FormattedMessage id="buttons.sign_out" />
            </MuiButton>
          </Box>
        </>
      )}

      <Footer />
      <Snackbar
        open={copied}
        autoHideDuration={1500}
        onClose={handleClose}
        style={{ position: 'absolute', bottom: 60 }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Referral Link Copied to Clipboard
        </Alert>
      </Snackbar>
      {!isLoading && !profileDetails?.mobile && isVerifyPhoneDialog && (
        <VerifyPhoneDialog
          countryCode={profileDetails?.country_code}
          phone={profileDetails?.mobile}
        />
      )}
      {openTourModal && isShowProductTour && (
        <ProductTourModal
          open={openTourModal}
          handleClose={handleCloseTourModal}
          selectedIndexs={selectedIndex}
          handleSelectedIndex={setSelectedIndex}
        />
      )}
    </>
  );
}
