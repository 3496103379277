import { Box } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useState } from 'react';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { isUserLoggedIn } from 'utils/auth';
import useGetProfile from 'services/Menu/useGetProfile';
import CommonCard from 'app/components/Home/CommonCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronleftIcon from '@mui/icons-material/ChevronLeft';
import useGetHomePageData from 'services/Home/useGetHomePageData';
import useReferralValidate from 'services/Auth/useReferralValidate';
import BotTemplateItem from 'app/design/speedBot/EntityItem/BotTemplateItem';
import { FEATURES } from 'types/Feature';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import SignInModal from 'app/components/Auth/SignInModal';
import HomeCarousel from 'app/components/Home/HomeCarousel';
import HomeButtons from 'app/components/Home/HomeButtons';
import NoScrollbar from 'app/components/Home/ScrollBarHiddenBox';
import BackTestingCard from 'app/components/Home/BackTestingCard';
import ModelHeading from 'app/components/Home/ModelHeading';
import LearFromTutorialCard from 'app/components/Home/LearFromTutorialCard';
import UserDetailCard from 'app/components/Home/UserDetailCard';
import { filterArrayWithKey, getGreetings } from 'utils/GenericFunctions';
import PortfolioSummary from 'app/components/Home/PortfolioSummary';
import NotificationUi from 'app/components/Home/NotificationUi';
import { WarningAmberOutlined } from '@mui/icons-material';
import HintAlert from 'app/design/speedBot/ComponentItem/Bots/HintAlert';
import { deepOrange } from '@mui/material/colors';
import useGetBrokers from 'services/Menu/useGetBrokers';
import { PRODUCT_TOUR } from 'constants/common';
import ProductTour from 'app/components/Common/ProductTour/ProductTour';
import { HomePageProductSteps } from 'app/components/Common/ProductTour/ProductTourData';
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const isLoggedIn = isUserLoggedIn();
  const isNotificationOnHomePage = useIsFeatureFlag([
    FEATURES.IS_NOTIFICATION_ON_HOME_PAGE,
  ]);

  const { data: homePageData } = useGetHomePageData();
  const { data: brokerData } = useGetBrokers();
  const isMdUp = useGetMediaQueryUp('md');
  const [isOpenLoginModal, setOpenLoginModal] = useState(false);
  const isTemplate = useIsFeatureFlag([FEATURES.IS_TEMPLATES]);
  const isReferral = useIsFeatureFlag([FEATURES.IS_REFERRAL_CODE]);

  const goodGreeting = getGreetings();

  const { data: profileData } = useGetProfile();

  const { referralCode } = useReferralValidate('');
  const isShowTicksDataWithoutBroker = useIsFeatureFlag([
    FEATURES.IS_SHOW_TICKS_DATA_WITHOUT_BROKER,
  ]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const container = document.getElementById('carousel-container');
  const handleScroll = direction => {
    const scrollAmount =
      direction === 'right' && container
        ? container.clientWidth
        : -container!.clientWidth;
    container &&
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    setScrollPosition(container!.scrollLeft + scrollAmount);
  };

  const filteredBrokersData =
    brokerData && filterArrayWithKey(brokerData, 'isVerified', true);
  const showTickMessage = isShowTicksDataWithoutBroker
    ? true
    : filteredBrokersData && filteredBrokersData.length > 0
    ? true
    : false;
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.component.mainLayoutContainer.main }}>
      <CircularLoader open={false} zIndex={1302} />
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="Help Page" />
      </Helmet>
      {/* <CircularLoader open={isLoading} /> */}
      <Box
        className="sticky-top"
        sx={{ backgroundColor: theme.palette.common.white }}
      >
        <PageHeader
          variant={isMdUp ? 'market' : 'portfolio'}
          title={
            !isMdUp
              ? 'Home'
              : isLoggedIn
              ? `${goodGreeting} ${profileData?.name}`
              : `${goodGreeting} Guest`
          }
          tabs={<div></div>}
        />
      </Box>
      <Box className="p-main-spacing">
        <Box sx={{ mt: 1, pb: !isLoggedIn ? 2 : 0 }}>
          <HomeCarousel />
        </Box>
        {isLoggedIn && (
          <Box display={'flex'} flexDirection={'column'} rowGap={1}>
            <PortfolioSummary />
            {!showTickMessage && (
              <HintAlert
                severity="warning"
                textColor={deepOrange['900']}
                iconColor={deepOrange['900']}
                hintTextKey="home.hint_text"
                icon={
                  <WarningAmberOutlined sx={{ color: deepOrange['A400'] }} />
                }
              />
            )}
          </Box>
        )}
        {isLoggedIn && isNotificationOnHomePage && <NotificationUi />}

        <HomeButtons />
        {homePageData &&
          homePageData.data &&
          homePageData.data.map(item => {
            const highestCount =
              item.model === 'template_strategies'
                ? Math.max(...item.data.map(templateitem => templateitem.count))
                : undefined;
            return (
              <Box key={item.model} sx={{ mt: 2 }} className="default-template">
                {item.model === 'template_strategies' && item.data.length > 0 && (
                  <Box mt={2} position="relative">
                    <ModelHeading label={item.label} click="template" />

                    <Box
                      id="carousel-container"
                      sx={{
                        overflowX: 'auto',
                        position: 'relative',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                        }}
                      >
                        {item &&
                          item.data.map(templateitem => (
                            <Box key={templateitem.id} sx={{ marginRight: 4 }}>
                              <BotTemplateItem
                                template={templateitem}
                                highestCount={highestCount}
                              />
                            </Box>
                          ))}
                      </Box>
                    </Box>
                    {scrollPosition > 0 && (
                      <Box
                        onClick={() => handleScroll('left')}
                        sx={{
                          position: 'absolute',
                          left: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                          backgroundColor: theme.palette.grey[200],
                          borderRadius: '6em',
                        }}
                      >
                        <ChevronleftIcon />
                      </Box>
                    )}
                    {container &&
                      scrollPosition <
                        container.scrollWidth - container.clientWidth && (
                        <Box
                          onClick={() => handleScroll('right')}
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            backgroundColor: theme.palette.grey[200],
                            borderRadius: '6em',
                          }}
                        >
                          <ChevronRightIcon />
                        </Box>
                      )}
                  </Box>
                )}
                {isTemplate &&
                  item.model === 'algo_category' &&
                  item.data.length > 0 && (
                    <Box mt={2}>
                      <ModelHeading label={item.label} click="template" />
                      <NoScrollbar>
                        {isTemplate &&
                          item &&
                          item.data.map(category => (
                            <Box key={category.id} sx={{ marginRight: 2 }}>
                              <CommonCard
                                click="template"
                                description={category.description}
                                title={category.name}
                              />
                            </Box>
                          ))}
                      </NoScrollbar>
                    </Box>
                  )}
                {item.model === 'backtest_pipeline' && item.data.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <ModelHeading label={item.label} click="" />

                    <NoScrollbar>
                      {item &&
                        item.data &&
                        item.data.map(backtest => (
                          <BackTestingCard
                            key={backtest.id}
                            percentage={backtest.percentage}
                            name={backtest.strategy_name}
                          />
                        ))}
                    </NoScrollbar>
                  </Box>
                )}
              </Box>
            );
          })}
        {isReferral && profileData && isLoggedIn && (
          <UserDetailCard profileData={profileData} />
        )}

        <Box sx={{ mt: 2 }}>
          <LearFromTutorialCard />
        </Box>
      </Box>
      {!isLoggedIn && isOpenLoginModal && (
        <SignInModal
          open={isOpenLoginModal}
          handleClose={() => setOpenLoginModal(false)}
          type={'Sign Up'}
          data={referralCode}
        />
      )}
      {!JSON.parse((localStorage.getItem(PRODUCT_TOUR) as string) || '')[
        'Home Page'
      ] && <ProductTour steps={HomePageProductSteps} tour_page="Home Page" />}
    </Box>
  );
};

export default HomePage;
