import { Socket } from 'socket.io-client';
import {
  LogDataCallback,
  LogResultData,
  SOCKET_LOG_MESSAGE_TYPES,
  State,
  TempStoringDataType,
} from './types';
import { SOCKET_CONSTANTS } from 'constants/socket';
import { useSocket } from 'app/components/Context/SocketContext';
import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export class LogDataManager {
  private state: State;
  private socket: Socket;
  constructor(socket: Socket) {
    this.state = {
      result: {} as TempStoringDataType,
      registredComponents: {},
    };
    this.socket = socket;
    this.socket.on(SOCKET_CONSTANTS.DATA_CHANNEL, this.onLogMessage.bind(this));
  }

  private onLogMessage(data: LogResultData) {
    this.addNewMessages(data);
    this.throttleCallComponentsCallback();
  }
  private addNewMessages(data: LogResultData) {
    if (data.msg_type === 1 || data.msg_type === 2) {
      if (this.state.result[SOCKET_LOG_MESSAGE_TYPES.LOG]) {
        this.state.result[SOCKET_LOG_MESSAGE_TYPES.LOG] = {
          ...this.state.result[SOCKET_LOG_MESSAGE_TYPES.LOG],
          data: [
            ...this.state.result[SOCKET_LOG_MESSAGE_TYPES.LOG].data,
            ...data.data,
          ],
        };
      } else {
        this.state.result[SOCKET_LOG_MESSAGE_TYPES.LOG] = data;
      }
    } else if (data.msg_type === 3) {
      if (this.state.result[SOCKET_LOG_MESSAGE_TYPES.WARN]) {
        this.state.result[SOCKET_LOG_MESSAGE_TYPES.WARN] = {
          ...this.state.result[SOCKET_LOG_MESSAGE_TYPES.WARN],
          data: [
            ...this.state.result[SOCKET_LOG_MESSAGE_TYPES.WARN].data,
            ...data.data,
          ],
        };
      } else {
        this.state.result[SOCKET_LOG_MESSAGE_TYPES.WARN] = data;
      }
    }
  }
  // this.state.result.push(data);
  // }

  throttleCallComponentsCallback = throttle(() => {
    this.callComponentsCallback();
    this.resetComponetsCallback();
  }, 1 * 2000);

  private resetComponetsCallback() {
    this.state.result = {} as TempStoringDataType;
  }
  private callComponentsCallback() {
    const newMessages = this.state.result;
    const registredComponents = this.state.registredComponents;

    Object.entries(registredComponents).forEach(entry => {
      const componentData = entry[1];
      const { messageType: componentMessageTypes, callback } = componentData;

      // const isComponentCallbackRequired =
      //   intersection([componentMessageTypes], newMessagesType).length > 0;
      // if (isComponentCallbackRequired) {
      // const newMessages = this.state.result.map(
      //   item => item.msg_type === componentMessageTypes,
      // );
      callback(newMessages[componentMessageTypes]);
      // }
    });
    this.state.result = {} as TempStoringDataType;
  }

  public registerComponent(
    componentId: string,
    messageType: SOCKET_LOG_MESSAGE_TYPES,
    callback: LogDataCallback,
  ) {
    const registerComponents = this.state.registredComponents;
    if (registerComponents[componentId]) {
      this.unRegisterComponent(componentId);
    }
    registerComponents[componentId] = { messageType: messageType, callback };
  }
  public unRegisterComponent(componentId: string) {
    const registerComponent = this.state.registredComponents;
    delete registerComponent[componentId];
  }
}

export const useLogDataManager = () => {
  const { socket } = useSocket();
  const [logDataManager, setLogDataManager] = useState<LogDataManager>();

  useEffect(() => {
    if (socket) {
      setLogDataManager(new LogDataManager(socket));
    }
  }, [socket]);
  return logDataManager;
};
