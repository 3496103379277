import { Box, Divider, Grid } from '@mui/material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { CategoryHeadItem } from 'app/design/speedBot/EntityItem';
import { useTranslation } from 'react-i18next';
import { BotTemplateCategory } from 'types/ApiServicesTypes';
import TemplateItems from '../itemsComponent/TemplateItems';

function BotTemplateCategoryBox({
  category,
  isViewFullItem,
}: {
  category: BotTemplateCategory;
  isViewFullItem?: boolean;
}) {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();

  return (
    <Box
      key={category.category_id}
      sx={{
        mt: { xs: 1, md: 0 },
        mb: { xs: 0, md: 2 },
        display: { xs: 'block', md: 'flex' },
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          flex: { md: 1, lg: 'initial' },
          width: {
            xs: 'initial',
            md: isViewFullItem ? '24rem' : '22rem',
            lg: isViewFullItem ? '24rem' : '15rem',
          },
        }}
      >
        <CategoryHeadItem
          isSeeAll
          handleClick={() =>
            breadcrumb.push(
              `/templates/category?category=${category.category_name}`,
              PAGES.TEMPLATES,
              '/templates',
              t('templates.page_title.templates'),
            )
          }
          category={category}
        />
        <Divider
          sx={{
            borderColor: 'grey.200',
            display: { xs: 'block', md: 'none' },
          }}
        />
      </Box>

      <Box sx={{ flex: 1.5, justifyContent: 'end' }}>
        <Grid
          container
          spacing={0}
          sx={{
            maxWidth: { xs: 'initial', md: '32rem', lg: 'initial' },
            flex: { lg: 1, xl: 1 },
          }}
        >
          <TemplateItems templates={category.templates} />
        </Grid>
      </Box>
    </Box>
  );
}

export default BotTemplateCategoryBox;
