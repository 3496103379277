import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { createPositionGreeksRow } from '../utils';
import { PositionMetaData } from '../../OptionChain/types';
import CommonTable from 'app/components/Common/CommonTable';
import { PositionsGreekDataForTable } from 'types/ApiServicesTypes';
import MuiChip from 'app/design/uiComponents/MuiChip';
import moment from 'moment';
// import { TodaysPositionsProps } from 'types/Position';
const ContractTypeFormatted = ({
  strike,
  position,
  date,
  lot,
}: {
  strike: string;
  position: 'short' | 'long';
  date: string;
  lot: number;
}): JSX.Element => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
    >
      <MuiChip
        label={position === 'short' ? 'S' : 'B'}
        variant="filled"
        size="singleLetter"
        color={position === 'short' ? 'purplelight' : 'infolight'}
      />
      <Typography variant="body2">{moment(date).format('DD MMM')}</Typography>
      <Typography variant="body2"> {strike}</Typography>
      <Typography variant="body2">x {lot}</Typography>
    </Box>
  );
};
const PositionGreeksTable = ({
  positions,
}: {
  positions: PositionMetaData[];
}) =>
  // {
  //   // positions,
  //   // handlePositionClick,
  //   // isDebugMode,
  // },
  {
    const result: PositionsGreekDataForTable[] = positions.map(item => {
      const contractType = `${Number(item.strike).toFixed(
        0,
      )} ${item.contractType.toUpperCase()}`;
      const candleData = Object.values(item.positionCandleWise)[0]
        .contractDetails;
      const { lots, entryTime, direction } = Object.values(
        item.positionCandleWise,
      )[0];
      return {
        contractType: ContractTypeFormatted({
          strike: contractType,
          position:
            direction === 'short' || direction === 'long' ? direction : 'short',
          date: entryTime,
          lot: lots,
        }),
        delta: candleData.delta,
        theta: candleData.theta,
        vega: candleData.vega,
        gamma: candleData.gamma,
        iv: candleData.iv,
        close: candleData.close,
        rho: candleData.rho,
      };
    });
    const { rows, columns } = createPositionGreeksRow(result);

    return (
      <>
        {
          <Box>
            <CommonTable
              containerStyle={{
                height: '68vh',
                scrollbarWidth: 'none',
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '0.4em',
                },
              }}
              rows={rows}
              columns={columns}
            />
          </Box>
        }
      </>
    );
  };

export default memo(PositionGreeksTable);
