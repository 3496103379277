import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { Condition } from './types';
import { StrategyMode } from './utils/strategy-data';

interface Props {
  condition: Condition;
  conditionIndex: number;
  onConditionOperatorChange: (conditionIndex: number, key: string) => void;
  mode: string;
}

export function ConditionOperataor(props: Props) {
  const { condition, conditionIndex, onConditionOperatorChange, mode } = props;
  const { fields } = condition;
  const selectedOperator = fields[0].key;
  const theme = useTheme();
  const onOperatorChange = (event: SelectChangeEvent) => {
    onConditionOperatorChange(conditionIndex, event.target.value.toString());
  };

  return (
    <Box sx={{ display: 'flex' }} margin={'20px 0px'}>
      {mode === StrategyMode.EDIT ? (
        <FormControl size={'small'}>
          <Select
            onChange={onOperatorChange}
            defaultValue={selectedOperator}
            sx={{
              border: 'none',
              backgroundColor: theme.palette.purple?.[200],
              color: theme.palette.purple?.[900],
              borderRadius: '8px',
            }}
          >
            <MenuItem value="and">And</MenuItem>
            <MenuItem value="or">Or</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Box
          padding={'6px 12px 6px 12px'}
          sx={{
            backgroundColor: theme.palette.purple?.[200],
            borderRadius: '8px',
          }}
        >
          <Typography variant="body2" color={theme.palette.purple?.[900]}>
            {selectedOperator === 'and' ? 'And' : 'Or'}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
