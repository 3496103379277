import { ReactElement } from 'react';
import { Box, Dialog } from '@mui/material';

export default function SnakbarMessageModal({
  open,
  handleClose,
  body,
  footer,
}: {
  open: boolean;
  handleClose: () => void;
  body?: ReactElement | string;
  footer?: ReactElement;
}) {
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ width: '100%' }}
      >
        <Box sx={{ p: 3 }}>
          <Box>{body}</Box>
          {footer ? <Box sx={{ pt: 3 }}>{footer}</Box> : <></>}
        </Box>
      </Dialog>
    </>
  );
}
