import { useQuery } from 'react-query';
import { GetInvoices } from 'services/apiServices';
import { Errors, GetInvoicesResult } from 'types/ApiServicesTypes';

export default function useGetInvoices(page: number, maxPage = 10) {
  const objQuery = useQuery<GetInvoicesResult, Errors>(
    ['GetInvoices', page, maxPage],
    () => GetInvoices(page, maxPage),
    {
      keepPreviousData: true,
    },
  );
  return objQuery;
}
