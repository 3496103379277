import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SymbolsData } from 'types/Symbols';
import { useTickDataManager } from './TickDataManager';
import {
  BANK_NIFTY_SYMBOL,
  NIFTY_SYMBOL,
  FIN_NIFTY_SYMBOL,
  NIFTY_SYMBOLS,
} from 'constants/common';
import useGetBrokers from 'services/Menu/useGetBrokers';
import { filterArrayWithKey } from 'utils/GenericFunctions';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export const useGetTickData = (symbols: string[], isNoLocalData?: boolean) => {
  const { data: brokers } = useGetBrokers();
  const isShowTicksDataWithoutBroker = useIsFeatureFlag([
    FEATURES.IS_SHOW_TICKS_DATA_WITHOUT_BROKER,
  ]);
  const verifiedBrokers =
    brokers && filterArrayWithKey(brokers, 'isVerified', true);
  let symbolInitData: SymbolsData = {};
  const niftyInitSymbolData = localStorage.getItem(NIFTY_SYMBOLS);
  if (niftyInitSymbolData) {
    symbolInitData = JSON.parse(niftyInitSymbolData) as SymbolsData;
  }
  const showTick = isShowTicksDataWithoutBroker
    ? true
    : verifiedBrokers && verifiedBrokers.length > 0
    ? true
    : false;
  const tickDataManager = useTickDataManager(showTick);
  const [updatedSymbolsData, setUpdatedSymbolsData] =
    React.useState<SymbolsData>(symbolInitData);

  const onUpdatedSymbolsData = React.useCallback((symbolsData: SymbolsData) => {
    if (
      symbolsData[NIFTY_SYMBOL] &&
      symbolsData[BANK_NIFTY_SYMBOL] &&
      symbolsData[FIN_NIFTY_SYMBOL]
    ) {
      const niftySymbolData: SymbolsData = {
        NIFTY_SPACE_50: symbolsData[NIFTY_SYMBOL],
        NIFTY_SPACE_BANK: symbolsData[BANK_NIFTY_SYMBOL],
        NIFTY_SPACE_FIN_SPACE_SERVICE: symbolsData[FIN_NIFTY_SYMBOL],
      };
      localStorage.setItem(NIFTY_SYMBOLS, JSON.stringify(niftySymbolData));
    }
    setUpdatedSymbolsData(symbolsData);
  }, []);

  React.useEffect(() => {
    const componentId: string = uuidv4();
    if (tickDataManager) {
      tickDataManager.registerForSymbolsData(
        componentId,
        symbols,
        onUpdatedSymbolsData,
        // brokers?.length,
      );
      if (!isNoLocalData) tickDataManager.checkForLocalStorageSymbolsData();
    }

    return () => {
      if (tickDataManager) {
        tickDataManager.unRegisterSymbolsData(componentId);
      }
    };
  }, [symbols, onUpdatedSymbolsData, tickDataManager, isNoLocalData]);

  return updatedSymbolsData;
};
