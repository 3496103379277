import { Divider, FormControl, RadioGroup } from '@mui/material';
import { Fragment, ChangeEvent } from 'react';
import { ComboBox } from 'types/Generic';
import SelectableRadio from './SelectableRadio';
type FilterRadioGroupProps = {
  controlName: string;
  radioItems: ComboBox[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
};

function FilterRadioGroup({
  radioItems,
  controlName,
  handleChange,
  selectedValue,
}: FilterRadioGroupProps) {
  return (
    <FormControl fullWidth>
      <RadioGroup
        row
        name={controlName}
        value={selectedValue}
        onChange={handleChange}
      >
        {radioItems.map((item, i) => (
          <Fragment key={item.value}>
            <SelectableRadio
              checked={item.value === selectedValue}
              label={item.label}
              value={item.value}
            />
            {i !== radioItems.length - 1 && <Divider sx={{ width: '100%' }} />}
          </Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default FilterRadioGroup;
