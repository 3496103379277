import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { CancelPlan } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import { CancelPlanParams, CancelPlanResult } from 'types/SubscriptionPlans';

export default function useCancelPlan(
  successCallback: (res: CancelPlanResult) => void,
) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    CancelPlanResult,
    Errors,
    Pick<CancelPlanParams, 'subscription_id'>
  >('CancelPlan', values =>
    CancelPlan(values.subscription_id)
      .then(res => {
        if (res && res.error) {
          showAlert!(res.error);
        } else if (!res || !res.status)
          showAlert!('Something went wrong. Please try again.');
        else successCallback(res);

        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
