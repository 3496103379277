import { useQuery } from 'react-query';
import { GetMarketplace } from './api';
import {
  Errors,
  ReportSearchParams,
  GetMonthPnl,
  GetReportResult,
} from 'types/ApiServicesTypes';

export default function useGetMarketplaceBots(params: ReportSearchParams) {
  const objQuery = useQuery<GetReportResult['data'], Errors>(
    ['GetStatistics', params],
    () => GetMarketplace(params),
  );

  const { data } = objQuery;
  const getColorForPercentage = (percentage: number, profitLoss: number) => {
    let opacity = 1;
    if (Math.abs(percentage) <= 20) opacity = 0.2;
    else if (Math.abs(percentage) <= 40) opacity = 0.4;
    else if (Math.abs(percentage) <= 60) opacity = 0.6;
    else if (Math.abs(percentage) <= 80) opacity = 0.8;

    return { color: profitLoss >= 0 ? 'green' : 'red', opacity };
  };

  const transformedData =
    // data &&
    // Array.isArray(data?.data) &&
    data
      ? data.data.map((monthData: GetMonthPnl) => {
          // const profitLossData = monthData.day_wise_pnl?.map(dayData => ({
          //   date: dayData.date,
          //   profit_loss: dayData.profit_loss,
          //   profit_loss_percent: dayData.profit_loss_percent,
          //   trades: dayData.trades,
          //   bot_name: dayData.bot_name,
          //   total_fees: dayData.total_fees,
          //   profitLossPercentage: dayData.profit_loss_percent,
          // }));

          const transformProfitLossData = monthData.day_wise_pnl?.map(
            colorData => ({
              date: colorData.date,
              profit_loss: colorData.profit_loss,
              // profit_loss_percent: colorData.profit_loss_percent,
              profit_loss_percentage: colorData.profit_loss_percentage,

              trades: colorData.trades,
              bot_name: colorData.bot_name,
              total_fees: Number(colorData.total_fees),
              color: getColorForPercentage(
                colorData.profit_loss_percentage,
                colorData.profit_loss,
              ),
            }),
          );

          return {
            startDate:
              monthData.day_wise_pnl[monthData.day_wise_pnl.length - 1].date,
            endDate: monthData.day_wise_pnl[0].date,
            minDate: monthData.day_wise_pnl.reduce(
              (min, dayData) => (dayData.date < min ? dayData.date : min),
              '',
            ),
            maxDate: monthData.day_wise_pnl.reduce(
              (max, dayData) => (dayData.date > max ? dayData.date : max),
              '',
            ),
            // profitLossData: profitLossData || [],
            transformProfitLossData: transformProfitLossData || [],
          };
        })
      : [];

  return { ...objQuery, transformedData };
}
