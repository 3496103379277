import { Checkbox, FormControlLabel, Typography } from '@mui/material';

const SelectableCheckbox = ({
  value,
  checked,
  label,
  handleChange,
}: {
  value: number | string;
  checked: boolean;
  label: string;
  handleChange: (value: number | string, checked: boolean) => void;
}) => {
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(value, event.target.checked);
  };

  return (
    <FormControlLabel
      sx={{
        ...(checked && {
          backgroundColor: 'transparent',
          color: 'text.primary',
        }),
        width: '100%',
      }}
      control={
        <Checkbox
          checked={checked}
          color="default"
          size="small"
          onChange={handleCheckChange}
          sx={{
            color: checked ? 'text.primary' : 'text.secondary',
          }}
        />
      }
      label={
        <Typography
          variant="body2"
          color={checked ? 'text.primary' : 'text.secondary'}
        >
          {label}
        </Typography>
      }
    />
  );
};
export default SelectableCheckbox;
