import { Box, Typography } from '@mui/material';
import { PageTitleSmallVariantProps } from '../types';

const PageTitleSmallVariant = ({ title }: PageTitleSmallVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 1 }}>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

export default PageTitleSmallVariant;
