import { MarketItemProps } from './types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import InstrumentString from 'app/design/itemsComponent/InstrumentString';
import BotHistoryGraph from 'app/components/Common/Charts/BotHistoryGraph';
import {
  encodeUrlData,
  formatNumber,
  getAnnualReturn,
} from 'utils/GenericFunctions';
import { positionTypes, riskTypes } from '../ComponentItem/Bots/utils';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const MarketItem = ({ bot, isGraph }: MarketItemProps) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();

  const riskType = (
    bot.risk_type
      ? riskTypes[bot.risk_type]
        ? riskTypes[bot.risk_type]
        : bot.risk_type
      : ''
  ) as string;

  const positionType = bot.position_type
    ? positionTypes[bot.position_type]
      ? (positionTypes[bot.position_type] as string)
      : bot.position_type.toString()
    : '';

  const annualReturn = getAnnualReturn(
    bot.start_date,
    bot.end_date,
    bot.net_profit_percentage,
  );

  const isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);

  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box
        onClick={() =>
          breadcrumb.push(
            `/discover/bot/${bot.id}/${encodeUrlData(bot.name)}`,
            PAGES.MARKETPLACE,
            `/discover/bots`,
            t('market.page_title.market'),
          )
        }
        sx={{ cursor: 'pointer' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" color="text.primary">
            {bot.name}
          </Typography>
          {bot.risk_type && (
            <MuiChip
              size="xsmall"
              color={
                riskType === 'High Risk'
                  ? 'errorlight'
                  : riskType === 'Moderate Risk'
                  ? 'infolight'
                  : 'successlight'
              }
              label={riskType}
            />
          )}
        </Box>
        {isShowResult && (
          <Typography variant="body3" color="text.secondary">
            <InstrumentString instrument={bot.instruments} />
          </Typography>
        )}
        <Box display="flex" {...(isShowResult && { sx: { mt: 0.5 } })}>
          <Box sx={{ flex: 1 }}>
            {!isShowResult && (
              <Typography
                variant="body3"
                color="text.secondary"
                sx={{ mb: 0.5 }}
              >
                <InstrumentString instrument={bot.instruments} />
              </Typography>
            )}
            {isShowResult && isChargesAndProfit && (
              <Typography
                variant="body3"
                component="p"
                fontWeight={500}
                sx={{ mb: 0.5 }}
                color={
                  bot.net_profit === 0
                    ? 'primary.main'
                    : bot.net_profit >= 0
                    ? 'success.main'
                    : 'error.main'
                }
              >
                {`${
                  bot.net_profit === 0 ? '' : bot.net_profit > 0 ? '▲' : '▼'
                } ${bot.currency_symbol}${formatNumber(bot.net_profit)}`}
              </Typography>
            )}
            <Box display="flex" {...(!isShowResult && { sx: { mt: 0.5 } })}>
              {isShowResult && (
                <>
                  <Typography variant="body3" color="text.disabled">
                    <FormattedMessage id="summary_labels.ar" />
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={500}
                    color="text.secondary"
                    sx={{ mx: 0.5 }}
                  >{`${
                    annualReturn < 0 ? '↓' : annualReturn > 0 ? '↑' : ''
                  }${formatNumber(annualReturn, false, 1)}%,`}</Typography>
                </>
              )}
              <Typography variant="body3" color="text.disabled">
                Max DD
              </Typography>
              <Typography
                variant="body3"
                fontWeight={500}
                color="text.secondary"
                sx={{ ml: 0.5 }}
              >{`${
                // bot.max_drowdown < 0 ? '↓' : bot.max_drowdown > 0 ? '↑' : ''
                bot.max_drowdown !== 0 ? '↓' : ''
              }${formatNumber(bot.max_drowdown, false, 1)}%`}</Typography>
            </Box>
            <Typography
              component="p"
              variant="body3"
              color="text.secondary"
              sx={{ mt: 0.5, height: '15.6px' }}
            >
              {positionType}
            </Typography>
          </Box>
          {isGraph && bot.graph_data && (
            <Box sx={{ width: '30%' }} alignSelf="stretch">
              <BotHistoryGraph
                data={bot.graph_data[0]}
                xAxis={false}
                yAxis={false}
                tooltip={false}
                grid={false}
                useYAxisDomain={false}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MarketItem;
