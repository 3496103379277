import { getCookie } from 'typescript-cookie';
import {
  SESSION_ID,
  USER_NAME,
  USER_ID,
  PLATFORM,
  // APP_VERSION,
} from 'constants/common';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export const getAuthDetails = () => {
  return {
    sessionId: getCookie(SESSION_ID),
    userId: getCookie(USER_ID),
    userName: getCookie(USER_NAME),
  };
};

export const isUserLoggedIn = () => {
  return Boolean(
    getCookie(SESSION_ID) && getCookie(USER_ID),
    // getCookie(SESSION_ID) && getCookie(USER_NAME) && getCookie(USER_ID),
  );
};

export type PlatformOSType =
  | 'ios'
  | 'android'
  | 'macos'
  | 'windows'
  | 'web'
  | 'native'
  | undefined;

export function getPlatform() {
  return getCookie(PLATFORM) as PlatformOSType;
}

export function useIsGoogleAuthEnabled() {
  // const platform = getPlatform();
  const isGoogleLogin = useIsFeatureFlag([FEATURES.IS_GOOGLE_LOGIN]);

  const isMobileApp = Object.prototype.hasOwnProperty.call(
    window,
    'ReactNativeWebView',
  );

  const isShowGoogleLogin = isGoogleLogin
    ? isMobileApp
      ? //platform === 'android' || platform === 'ios'
        true
      : true
    : false;

  return isShowGoogleLogin;
}

// function isNewVersionAvailable(currentVersion: string, newVersion: string) {
//   const currentVersionArray = currentVersion.split('.').map(Number);
//   const newVersionArray = newVersion.split('.').map(Number);

//   for (
//     let i = 0;
//     i < Math.max(currentVersionArray.length, newVersionArray.length);
//     i++
//   ) {
//     const currentPart = currentVersionArray[i] || 0;
//     const newPart = newVersionArray[i] || 0;

//     if (currentPart < newPart) {
//       return true;
//     } else if (currentPart > newPart) {
//       return false;
//     }
//   }
//   return false;
// }

export function useAppleAuthentication() {
  // const platform = getPlatform();
  // const appVersion = getCookie(APP_VERSION) || '';
  const isAppleLogin = useIsFeatureFlag([FEATURES.IS_APPLE_LOGIN]);

  const isMobileApp = Object.prototype.hasOwnProperty.call(
    window,
    'ReactNativeWebView',
  );

  const isShowAppleLogin = isAppleLogin
    ? isMobileApp
      ? //  (platform === 'android' &&
        //     isNewVersionAvailable('0.2.2', appVersion)) ||
        //   (platform === 'ios' && isNewVersionAvailable('1.5', appVersion))
        true
      : true
    : false;

  const handleAppleLogin = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('appleSignIn');
    } else {
      const queryParams = new URLSearchParams({
        scope: 'email name',
        response_type: 'code id_token',
        response_mode: 'form_post',
        client_id: 'com.speedbot.com.sid',
        redirect_uri: window.location.origin + '/api/login/apple',
      });
      window.location.href =
        'https://appleid.apple.com/auth/authorize?' + queryParams.toString();
    }
  };

  return { isShowAppleLogin, handleAppleLogin };
}
