import { Box, Stepper, Step, StepLabel } from '@mui/material';

const StrategyBuilderStepper = ({ step }: { step: number }) => {
  const steps = ['Symbols', 'Entry/Exit', 'Portfolio'];
  return (
    <Box sx={{ width: '100%', py: 2, backgroundColor: 'grey.100' }}>
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
            marginTop: theme => theme.spacing(0.5),
          },
        }}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StrategyBuilderStepper;
