import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { ForgotPass } from 'services/apiServices';
import {
  Errors,
  ForgotPassParams,
  ResetPassResult,
} from 'types/ApiServicesTypes';

export default function useResetPass() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<ResetPassResult, Errors, ForgotPassParams>(
    'ResetPass',
    values =>
      ForgotPass(values)
        .then(res => {
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
