import { useContext } from 'react';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useMutation } from 'react-query';
import { AuthBroker } from 'services/apiServices';
import {
  AuthBrokerParams,
  AuthBrokerResult,
  Errors,
} from 'types/ApiServicesTypes';
import { manipulateAddBrokerRef } from 'app/components/Bots/BotDetail/util';

export default function useAuthBroker() {
  const { showAlert } = useContext(ToastContext);
  const breadcrumb = useBreadcrumbManager();

  const mutation = useMutation<AuthBrokerResult, Errors, AuthBrokerParams>(
    'AuthBroker',
    values =>
      AuthBroker(values)
        .then(res => {
          if (res && res.success) {
            // do nothing
          } else if (res && res.error) showAlert!(res.error);
          else showAlert!('Something went wrong. Please try again.');

          const redirectUrl = manipulateAddBrokerRef('get');
          if (res.success && redirectUrl) {
            manipulateAddBrokerRef('remove');
            window.location.assign(redirectUrl);
          } else {
            breadcrumb.goBackPathPage('/broker');
          }
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
