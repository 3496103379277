import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { WalletAction } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import {
  WalletActionResult,
  WalletActionParams,
  WalletActionData,
} from 'types/SubscriptionPlans';

export default function useWalletAction(
  successCallback: (res: WalletActionData) => void,
) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    WalletActionResult,
    Errors,
    Pick<WalletActionParams, 'action' | 'amount'>
  >('WalletAction', values =>
    WalletAction(values.action, values.amount, 'razorpay')
      .then(res => {
        successCallback(res.data);
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
