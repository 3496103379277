import { Box, IconButton, Typography } from '@mui/material';
import { PageTitleSmallWithIconVariantProps } from '../types';

const PageTitleSmallWithIconVariant = ({
  title,
  iconProps,
}: PageTitleSmallWithIconVariantProps) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{
        py: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" sx={{ flex: '1' }}>
        {title}
      </Typography>
      <IconButton
        color="primary"
        size="medium"
        onClick={iconProps.handleClick}
        sx={{ p: 0.75 }}
      >
        {iconProps.icon}
      </IconButton>
    </Box>
  );
};

export default PageTitleSmallWithIconVariant;
