import React from 'react';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import { DropdownFieldParam, DropdownFieldValue, FieldParam } from '../types';

// type Param = Omit<FieldParam, 'renderer' | 'key' | 'type'>;
type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected?: DropdownFieldValue['key'];
  onChange: (value: DropdownFieldValue['key']) => void;
  defaultResolution?: string;
}

export function DropdownFieldRenderer(props: Props) {
  const { param, selected, onChange, defaultResolution } = props;
  const { name, values, defaultValue, key } = param as DropdownFieldParam;

  const onChangeFieldType = React.useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px',
      }}
    >
      <FormControl variant="outlined" size={'small'}>
        <InputLabel id="name">{name}</InputLabel>
        <Select
          label={name}
          onChange={onChangeFieldType}
          labelId={'name'}
          id={'name'}
          defaultValue={
            key === 'resolution'
              ? selected || defaultResolution || defaultValue.key
              : selected || defaultValue.key
          }
        >
          {values.map((value: DropdownFieldValue) => (
            <MenuItem key={value.key} value={value.key}>
              {value.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
