import { Helmet } from 'react-helmet-async';
import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
// Service
import { useParams } from 'react-router-dom';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { ContentCopy, LibraryAddCheckOutlined } from '@mui/icons-material';
import ShareIcons from 'app/components/Common/ShareIcons';
import { useState } from 'react';
import ReferralTable from 'app/components/Menu/ReferralComps/ReferralTable';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import useReferralValidate from 'services/Auth/useReferralValidate';
import DoneIcon from '@mui/icons-material/Done';
import MuiButton from 'app/design/uiComponents/MuiButton';
import useSetReferral from 'services/Menu/useSetReferral';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import useGetProfile from 'services/Menu/useGetProfile';
import { useTranslation } from 'react-i18next';

function ReferralPage() {
  const [copied, setCopied] = useState<boolean>(false);
  const [ticked, setTicked] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopied(false);
  };
  const { referral } = useParams<{ referral: string }>();
  const {
    referralCode,
    setReferralCode,
    referralValidate,
    // isError,
    // isLoading,
    debouncedRefetch,
  } = useReferralValidate('');
  const mutation = useSetReferral();
  const { data: profileDetails } = useGetProfile();
  const [showError, setShowError] = useState<boolean>(false);
  setTimeout(() => setShowError(true), 2000);
  const isReferralOauthUser = useIsFeatureFlag([
    FEATURES.IS_REFERRAL_OAUTH_USER,
  ]);

  const credit = 100;
  const getPercentage = (val: number) => {
    setPercentage(val);
  };
  const handleClick = () => {
    setCopied(true);
  };
  const theme = useTheme();

  const derivedReferalCode = profileDetails?.referral_code || referral || '';
  const referralUrl = `${window.location.origin}/signup/start?referral=${derivedReferalCode}`;
  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert('Content copied to clipboard successfully');
        // setCopied(true);
        handleClick();
        setTicked(true);
        setTimeout(() => setTicked(false), 600);
      })
      .catch(err => {
        alert(`Error copying content to clipboard: ${err}`);
      });
  };
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Refer & Earn Page </title>
        <meta name="description" content="Refer and Earn Page" />
      </Helmet>

      <>
        {/* <CircularLoader open={mutation.isLoading} /> */}
        <Box
          //   onSubmit={handleSubmit}
          //   className="m-main-spacing"

          sx={{
            display: 'flex',
            flexDirection: 'column',

            flex: 1,
          }}
        >
          <PageHeader
            variant="inactive"
            buttonText={<FormattedMessage id="menu.page_title.menu" />}
            title={<FormattedMessage id="menu.refnearn" />}
            buttonHandleClick="/menu"
            breadcrumb={<Breadcrumbs page={PAGES.REFER_EARN} data={{}} />}
          />
          {percentage != 0 && (
            <Box className="m-main-spacing" sx={{ pb: 3 }}>
              <Typography
                sx={{ fontSize: 18, color: theme.palette.text.disabled }}
              >
                For every
                <span
                  style={{
                    color: theme.palette.text.secondary,
                    fontWeight: '600',
                  }}
                >
                  {' '}
                  {credit} credits they buy
                </span>
                , you get{' '}
                <span
                  style={{
                    color: theme.palette.text.secondary,
                    fontWeight: '600',
                  }}
                >
                  {(credit * percentage) / 100} credits each!{' '}
                </span>
              </Typography>
            </Box>
          )}
          <ItemHead variant="large" title={'Referral Link'} />

          <Box
            className="m-main-spacing"
            sx={{
              backgroundColor: 'grey.100',
              borderRadius: '1em',
              display: 'flex',
              justifyContent: [
                'block',
                'block',
                'space-between',
                'space-between',
              ],
              flexDirection: ['column', 'column', 'row', 'row'],

              padding: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: [
                  'grey.100',
                  'grey.100',
                  'transparent',
                  'transparent',
                ],
                borderRadius: '0.5rem',
                padding: 2,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box sx={{ marginBottom: 2 }}>
                <Box
                  sx={{
                    // backgroundColor: '#e0e0e0',
                    padding: 2,
                    paddingBottom: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={[18, 30, '3vw']}
                    sx={{
                      color: theme.palette.grey[700],
                      fontWeight: '400',
                      fontSize: [18, 30, '3vw'],
                      marginBottom: 2,
                    }}
                  >
                    {derivedReferalCode}
                  </Typography>
                  <Typography variant="caption">{referralUrl}</Typography>
                </Box>
              </Box>
              <Box>
                <IconButton
                  sx={{
                    borderRadius: '0.5rem',
                    border: '1px solid rgba(0, 0, 0, 0.06)',
                    backgroundColor: theme.palette.common.white,
                    ml: 1.5,
                    height: '3.5rem',
                    width: '3.5rem',
                  }}
                  onClick={() => {
                    copyToClipboard(referralUrl);
                    handleClick();
                  }}
                >
                  {!ticked ? (
                    <ContentCopy sx={{ fontSize: [18, 30, '2.0vw'] }} />
                  ) : (
                    <LibraryAddCheckOutlined
                      sx={{ fontSize: [18, 30, '2.0vw'] }}
                      color="success"
                    />
                  )}
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                flexDirection: 'column',
                marginTop: 2,
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ display: ['none', 'none', 'block'], textAlign: 'end' }}
              >
                <FormattedMessage id="referral_page.share_via" /> :
              </Typography>
              <ShareIcons
                style={{ backgroundColor: theme.palette.common.white }}
                title={t('referral_page.share_note')}
                variant="medium"
                referralURL={referralUrl}
                whichIconYouWant={[
                  'twitter',
                  'facebook',
                  'whatsapp',
                  'email',
                  'telegram',
                ]}
              />
            </Box>
          </Box>
          {isReferralOauthUser &&
            profileDetails?.is_oauth_user &&
            !profileDetails?.referred_by && (
              <Box>
                <Box sx={{ mt: 2 }}>
                  <ItemHead
                    variant="large"
                    title={'Do You Have any Referral Code?'}
                  />
                </Box>
                {
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControl className="p-main-spacing" variant="outlined">
                      <TextField
                        label="Referral Code (optional)"
                        name="referral"
                        value={referralCode}
                        onBlur={() => {
                          if (
                            referralCode.trim() !== '' &&
                            referralCode.trim().length > 3
                          ) {
                            debouncedRefetch();
                          }
                        }}
                        // onChange={async event => {
                        //   const newReferralCode = event.target.value;
                        //   setReferralCode(newReferralCode);
                        //   await debouncedRefetch(newReferralCode); // This will ensure the fetch is debounced
                        // }}
                        onChange={e => setReferralCode(e.target.value)}
                        // error={Boolean(touched.referral && errors.referral)}
                        variant="outlined"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {referralCode.trim() !== '' &&
                                (referralValidate &&
                                referralValidate.success ? (
                                  <DoneIcon color="success" fontSize="medium" />
                                ) : // <CloseOutlined
                                //   style={{ color: 'red' }}
                                //   fontSize="medium"
                                // />
                                null)}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {showError &&
                        referralCode.trim() !== '' &&
                        (referralValidate && referralValidate.success ? (
                          // <DoneIcon color="success" fontSize="medium" />
                          ''
                        ) : (
                          <Typography sx={{ color: theme.palette.error.main }}>
                            Oops! It seems your referral code isn
                            <span>&apos;</span>t valid, Double-check and retry!
                          </Typography>
                        ))}
                      {/* {referralCode.trim() !== '' && isError ? (
              <FormHelperText error>An error occurred.</FormHelperText>
            ) : null} */}
                    </FormControl>
                    {referralCode !== derivedReferalCode &&
                      referralCode !== '' && (
                        <MuiButton
                          sx={{ height: '56px' }}
                          className="loginflowBtn"
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            mutation.mutate({ referralCode: referralCode })
                          }
                          disabled={
                            referralCode.trim() !== ''
                              ? !referralValidate?.success
                              : false
                          }
                        >
                          <FormattedMessage id="next" />
                        </MuiButton>
                      )}
                  </Box>
                }
              </Box>
            )}
          <Box sx={{ mt: 2 }}>
            <ItemHead variant="large" title={'Referral Income'} />
          </Box>

          <ReferralTable getPercentage={getPercentage} />
          <Snackbar
            open={copied}
            autoHideDuration={1500}
            onClose={handleClose}
            style={{ position: 'absolute', bottom: 60 }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              <FormattedMessage id="referral_page.referral_copy" />
            </Alert>
          </Snackbar>
        </Box>
      </>
    </>
  );
}
export default ReferralPage;
