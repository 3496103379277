import { ThemeProvider } from '@mui/styles';
import { Box, Typography, createTheme } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { stateToHTML } from 'draft-js-export-html';
import { Formik } from 'formik';
import MUIRichTextEditor from 'mui-rte';
import * as Yup from 'yup';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';

const OptionBotDescriptionModal = ({
  open,
  handleClose,
  description,
  setDescription,
}: {
  open: boolean;
  handleClose: (val: boolean) => void;
  description: string;
  setDescription: (desc: string) => void;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const handleCloseModal = () => {
    handleClose(false);
  };

  const myTheme = createTheme({});

  const state = ContentState.createFromBlockArray(
    convertFromHTML(description).contentBlocks,
    convertFromHTML(description).entityMap,
  );

  Object.assign(myTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          minHeight: '70vh',
          border: '1px solid #adadad',
          borderRadius: '4px',
          marginTop: '5px',
        },
        editor: {
          paddingLeft: '14px',
        },
        placeHolder: {
          paddingLeft: '14px',
        },
      },
    },
  });
  return (
    <MuiModal
      maxWidth="md"
      isFullScreen={!isMdUp}
      open={open}
      handleClose={handleCloseModal}
      body={
        <Formik
          initialValues={{
            description: description || '',
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string().required('Description is required'),
          })}
          onSubmit={values => {
            setDescription(values.description);
            handleCloseModal();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Box
              className="m-main-spacing"
              component="form"
              id="initial-description-form"
              onSubmit={handleSubmit}
            >
              <Typography variant="h5" sx={{ my: 2 }}>
                Bot Description
              </Typography>
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  controls={[
                    'title',
                    'bold',
                    'italic',
                    'underline',
                    'highlight',
                    'undo',
                    'redo',
                  ]}
                  // value={mutation.isSuccess && data}
                  onChange={value => {
                    setFieldValue(
                      'description',
                      stateToHTML(value.getCurrentContent()),
                    );
                  }}
                  defaultValue={JSON.stringify(convertToRaw(state))}
                  inlineToolbar
                  label="Description"
                />
              </ThemeProvider>
            </Box>
          )}
        </Formik>
      }
      footer={
        <Box display={'flex'} p={2}>
          <MuiButton
            fullWidth
            variant="outlined"
            onClick={handleCloseModal}
            sx={{ mx: 2 }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            fullWidth
            variant="contained"
            type="submit"
            form="initial-description-form"
            sx={{ mx: 2 }}
          >
            Save
          </MuiButton>
        </Box>
      }
    />
  );
};

export default OptionBotDescriptionModal;
