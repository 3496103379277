import { YouTube, Telegram, Twitter, Instagram } from '@mui/icons-material';
import { openInNewTab } from 'utils/GenericFunctions';
import { Tooltip, IconButton } from '@mui/material';
import { TikTok } from './CustomSocialIcons';

const SocialIcon = ({
  name,
  link,
}: {
  name: 'YouTube' | 'Telegram' | 'Twitter' | 'Instagram' | 'Tiktok';
  link: string;
}) => {
  const socials = {
    YouTube: YouTube,
    Telegram: Telegram,
    Twitter: Twitter,
    Instagram: Instagram,
    Tiktok: TikTok,
  };
  const Icon = socials[name];
  const handelClick = () => {
    openInNewTab(link);
  };
  return (
    <>
      {link && Icon ? (
        <Tooltip title={name}>
          <IconButton onClick={handelClick}>
            <Icon fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

export default SocialIcon;
