import { Box, Typography } from '@mui/material';
import { PositionBaseDataType } from '../types';
import { ShoppingBag } from '@mui/icons-material';
import { SymbolOptionChainData } from 'types/ApiServicesTypes';

type BuySellProps = {
  handleClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void;
  strike: string;
  contractDetails: SymbolOptionChainData;
  contractType: string;
  date: string;
  position?: PositionBaseDataType;
  lots?: number;
  trade_type?: string;
};

const BuySellButton = ({
  strike,
  handleClick,
  contractDetails,
  contractType,
  date,
  position,
  lots,
  trade_type,
}: BuySellProps) => {
  const handleButtonClick = (clickedButton: string, action: string) => {
    if (clickedButton === 'long' || clickedButton === 'short') {
      if (position) {
        handleClick(
          {
            ...position,
            direction: clickedButton,
            lots:
              action === 'add'
                ? Number(position.lots) + 1
                : Number(position.lots) > 1
                ? Number(position.lots) - 1
                : 1,
          },
          contractType,
          strike,
        );
      } else {
        handleClick(
          {
            direction: clickedButton,
            lots: 1,
            iv: contractDetails.iv,
            premium: contractDetails.close,
            entryTime: date,
            contractDetails: contractDetails,
          },
          contractType,
          strike,
        );
      }
    }
  };
  return (
    <Box
      display={'flex'}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        className={
          trade_type && trade_type !== 'fut'
            ? position?.entryTime === date
              ? ''
              : 'hover-visible'
            : ''
        }
      >
        {position && position.direction === 'short' && lots && lots > 0 ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShoppingBag sx={{ fontSize: '15px', color: 'grey' }} />
            <Typography sx={{ fontSize: '15px', color: 'grey' }}>
              {lots}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {position && position.direction === 'long' && position.lots > 0 && (
          <Typography variant="caption">{position.lots}X</Typography>
        )}
        {position?.direction === 'long' && lots && lots > 0 ? (
          <Box
            sx={{
              borderRadius: 0.7,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'success.main',
              mx: 0.3,
              my: 0.5,
              cursor: 'pointer',
              height: '1.2rem',
              width: '1.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transitionDuration: '0ms',
              transition: 'none',
            }}
            onClick={() => {
              handleButtonClick('long', 'remove');
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              -
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box
          sx={{
            borderRadius: 0.7,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'success.main',
            mx: 0.3,
            my: 0.5,
            cursor: 'pointer',
            height: '1.2rem',
            width: '1.2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transitionDuration: '0ms',
            transition: 'none',
            backgroundColor:
              position && position.direction === 'long' ? 'success.main' : '',
            '&:hover': {
              backgroundColor:
                position && position.direction === 'long' ? 'success.dark' : '',
            },
          }}
          onClick={() => {
            handleButtonClick('long', 'add');
          }}
        >
          <Typography
            sx={{
              color:
                position && position.direction === 'long'
                  ? 'white'
                  : 'success.main',
              fontSize: 14,
            }}
          >
            B
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: 0.7,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'error.main',
            mx: 0.3,
            my: 0.5,
            cursor: 'pointer',
            height: '1.2rem',
            width: '1.2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transitionDuration: '0ms',
            transition: 'none',
            backgroundColor:
              position && position.direction === 'short' ? 'error.main' : '',
            '&:hover': {
              backgroundColor:
                position && position.direction === 'short' ? 'error.dark' : '',
            },
          }}
          onClick={() => {
            handleButtonClick('short', 'add');
          }}
        >
          <Typography
            sx={{
              color:
                position && position.direction === 'short'
                  ? 'white'
                  : 'error.main',
              fontSize: 14,
            }}
          >
            S
          </Typography>
        </Box>
        {position?.direction === 'short' && lots && lots > 0 ? (
          <Box
            sx={{
              borderRadius: 0.7,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'error.main',
              mx: 0.3,
              my: 0.5,
              cursor: 'pointer',
              height: '1.2rem',
              width: '1.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transitionDuration: '0ms',
              transition: 'none',
            }}
            onClick={() => {
              handleButtonClick('short', 'remove');
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              -
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {position && position.direction === 'short' && position.lots > 0 && (
          <Typography variant="caption">X{position.lots}</Typography>
        )}
        {position && position.direction === 'long' && lots && lots > 0 ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShoppingBag sx={{ fontSize: '15px', color: 'grey' }} />
            <Typography sx={{ fontSize: '15px', color: 'grey' }}>
              {lots}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {!position && lots && lots > 0 ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShoppingBag sx={{ fontSize: '15px', color: 'grey' }} />
            <Typography sx={{ fontSize: '15px', color: 'grey' }}>
              {lots}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default BuySellButton;

// type BuySellProps = {
//   handleClick: (
//     position: PositionBaseDataType,
//     contractType: string,
//     strike: string,
//   ) => void;
//   strike: string;
//   contractDetails: SymbolOptionChainData;
//   contractType: string;
//   position?: PositionBaseDataType;
//   date: string;
//   lots?: number;
//   trade_type?: string;
// };

// const BuySellButton = ({
//   strike,
//   handleClick,
//   contractDetails,
//   contractType,
//   date,
//   position,
//   lots,
//   trade_type,
// }: BuySellProps) => {
//   const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
//   const [direction, setDirection] = useState('');

//   // eslint-disable-next-line no-console
//   console.log('buy sell', position);
//   const handleSelection = useCallback(
//     (selected: string) => {
//       // eslint-disable-next-line no-console
//       console.log('handle click called');
//       if (position) {
//         // eslint-disable-next-line no-console
//         console.log('if', position);
//         handleClick(
//           {
//             ...position,
//             direction: direction,
//             lots:
//               selected !== 'Remove all'
//                 ? Number(position.lots) +
//                   (selected === 'Add'
//                     ? 1
//                     : selected === 'Remove' && Number(position.lots) > 1
//                     ? -1
//                     : 0)
//                 : 0,
//           },
//           contractType,
//           strike,
//         );
//       } else {
//         // eslint-disable-next-line no-console
//         console.log('else ', position, direction, contractDetails);
//         handleClick(
//           {
//             direction: direction,
//             lots: 1,
//             iv: contractDetails.iv,
//             premium: contractDetails.close,
//             entryTime: date,
//             contractDetails: contractDetails,
//           },
//           contractType,
//           strike,
//         );
//       }
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [direction],
//   );
//   const handleMenuClick = useMemo(
//     () => (event: React.MouseEvent<HTMLElement>) => {
//       setShowMenu(event.currentTarget);
//     },
//     [],
//   );

//   const open = Boolean(showMenu);
//   const handleMenuClose = () => {
//     setShowMenu(null);
//   };
//   const handleBuyClick = (e: React.MouseEvent<HTMLElement>) => {
//     setDirection('long');
//     handleMenuClick(e);
//   };
//   // eslint-disable-next-line no-console
//   console.log('direction ', direction);
//   return (
//     <Box
//       display={'flex'}
//       sx={{
//         position: 'absolute',
//         top: 0,
//         left: 0,
//       }}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyItems: 'center',
//         }}
//         className={
//           trade_type && trade_type !== 'fut'
//             ? position?.entryTime === date
//               ? ''
//               : 'hover-visible'
//             : ''
//         }
//       >
//         {position && position.direction === 'long' && position.lots > 0 && (
//           <Typography variant="caption">{position.lots}X</Typography>
//         )}
//         <Box
//           sx={{
//             borderRadius: 0.7,
//             borderWidth: 1,
//             borderStyle: 'solid',
//             borderColor: 'success.main',
//             mx: 0.3,
//             my: 0.5,
//             cursor: 'pointer',
//             height: '1.2rem',
//             width: '1.2rem',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             transitionDuration: '0ms',
//             transition: 'none',
//             backgroundColor:
//               position && position.direction === 'long' ? 'success.main' : '',
//             '&:hover': {
//               backgroundColor:
//                 position && position.direction === 'long' ? 'success.dark' : '',
//             },
//           }}
//           onClick={handleBuyClick}
//         >
//           <Typography
//             sx={{
//               color:
//                 position && position.direction === 'long'
//                   ? 'white'
//                   : 'success.main',
//               fontSize: 14,
//             }}
//           >
//             B
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             borderRadius: 0.7,
//             borderWidth: 1,
//             borderStyle: 'solid',
//             borderColor: 'error.main',
//             mx: 0.3,
//             my: 0.5,
//             cursor: 'pointer',
//             height: '1.2rem',
//             width: '1.2rem',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             transitionDuration: '0ms',
//             transition: 'none',
//             backgroundColor:
//               position && position.direction === 'short' ? 'error.main' : '',
//             '&:hover': {
//               backgroundColor:
//                 position && position.direction === 'short' ? 'error.dark' : '',
//             },
//           }}
//           onClick={e => {
//             setDirection('short');
//             handleMenuClick(e);
//           }}
//         >
//           <Typography
//             sx={{
//               color:
//                 position && position.direction === 'short'
//                   ? 'white'
//                   : 'error.main',
//               fontSize: 14,
//             }}
//           >
//             S
//           </Typography>
//         </Box>
//         {position && position.direction === 'short' && position.lots > 0 && (
//           <Typography variant="caption">X{position.lots}</Typography>
//         )}
//       </Box>
//       {lots && lots > 0 ? (
//         <Box display={'flex'} alignItems={'center'}>
//           <ShoppingBag sx={{ fontSize: '15px', color: 'grey' }} />
//           <Typography sx={{ fontSize: '15px', color: 'grey' }}>
//             {lots}
//           </Typography>
//         </Box>
//       ) : (
//         <></>
//       )}
//       <Paper sx={{ width: 'auto' }}>
//         <Menu
//           id="long-menu"
//           MenuListProps={{
//             'aria-labelledby': 'long-button',
//           }}
//           sx={{ width: '220px' }}
//           anchorEl={showMenu}
//           open={open}
//           onClose={handleMenuClose}
//           anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
//           transformOrigin={{ horizontal: 'center', vertical: 'top' }}
//         >
//           <Box>
//             {['Add', 'Remove', 'Remove all'].map(action => (
//               <MenuItem
//                 onClick={() => {
//                   handleSelection(action);
//                 }}
//                 key={action}
//               >
//                 <Typography variant="body1">{action}</Typography>
//               </MenuItem>
//             ))}
//           </Box>
//         </Menu>
//       </Paper>
//     </Box>
//   );
// };

// export default memo(BuySellButton);
