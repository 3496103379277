/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, theme } from './theme';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Custom styling
import 'assets/scss/global.scss';

// Import root app
import App from 'app';

import { HelmetProvider } from 'react-helmet-async';

// Initialize languages
import './locales/i18n';
import {
  themeContext,
  ToggleThemeProvider,
} from 'app/components/Context/ToggleThemeContext';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const MainApp = () => {
  const { isDarkTheme } = React.useContext(themeContext); // Use the context to get theme state
  const curTheme = isDarkTheme ? darkTheme : theme;
  // const [themeMode, setThemeMode] = React.useState(getInitialTheme());
  // React.useEffect(() => {
  //   const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  //   const handleChange = () =>
  //     setThemeMode(mediaQuery.matches ? 'dark' : 'light');
  //   mediaQuery.addEventListener('change', handleChange);
  //   return () => mediaQuery.removeEventListener('change', handleChange);
  // }, []);

  // const toggleTheme = () => {
  //   setThemeMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  // };
  const body = document.querySelector('body');

  React.useEffect(() => {
    if (body)
      isDarkTheme
        ? (body.style.backgroundColor = '#263238')
        : (body.style.backgroundColor = '#eeeeee');
  }, [body, isDarkTheme]);
  // const curtheme = themeMode === 'light' ? theme : darkTheme;
  return (
    <ThemeProvider theme={curTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <ToggleThemeProvider>
        <MainApp />
      </ToggleThemeProvider>
    </React.StrictMode>
  </HelmetProvider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again bec  ause i18next works with the hooks
  });
}
