import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Statistics from 'app/components/Marketplace/Statistics';
import PageHeader from 'app/design/speedBot/PageHeader';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';

export function StatisticsPage() {
  const { id, ptype, title } =
    useParams<{ id: string; ptype: string; title: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const botName = title;

  const breadcrumb = useBreadcrumbManager();

  return (
    <Fragment>
      <Helmet>
        <title>Portfolio Statistics</title>
        <meta name="description" content="Portfolio Statistics Page" />
      </Helmet>
      <PageHeader
        variant="back"
        buttonText={botName}
        buttonHandleClick={() => history.goBack()}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.PORTFOLIO_DETAIL_ORDERS}
            data={{ botName: title, ptype: ptype, subId: id }}
          />
        }
      />
      <Statistics
        id={parseInt(id)}
        source="PORTFOLIO"
        baseApiUrl={`portfolio/${id}`}
        title={botName}
        positionsOnClick={() =>
          breadcrumb.push(
            `/portfolio/${ptype}/${id}/statistics/positions/${botName}`,
            PAGES.PORTFOLIO_DETAIL_STATISTIC,
            `/portfolio/${ptype}/${id}/statistics/${botName}`,
            t('statistics'),
          )
        }
        ordersOnClick={() =>
          breadcrumb.push(
            `/portfolio/${ptype}/${id}/statistics/orders/${botName}`,
            PAGES.PORTFOLIO_DETAIL_STATISTIC,
            `/portfolio/${ptype}/${id}/statistics/${botName}`,
            t('statistics'),
          )
        }
        symbolsOnClick={() =>
          breadcrumb.push(
            `/portfolio/${ptype}/${id}/statistics/symbols/${botName}`,
            PAGES.PORTFOLIO_DETAIL_STATISTIC,
            `/portfolio/${ptype}/${id}/statistics/${botName}`,
            t('statistics'),
          )
        }
        profitLossOnClick={() =>
          breadcrumb.push(
            `/portfolio/${ptype}/${id}/statistics/profitloss/${botName}`,
            PAGES.PORTFOLIO_DETAIL_STATISTIC,
            `/portfolio/${ptype}/${id}/statistics/${botName}`,
            t('statistics'),
          )
        }
      />
    </Fragment>
  );
}
