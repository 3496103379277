import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { GetPortfolioBotDetailScripts } from 'types/ApiServicesTypes';
import { SymbolItem } from 'app/design/speedBot/EntityItem';
import StatusMessage from 'app/components/Common/StatusMessage';
import { SymbolsData } from 'types/Symbols';

type SymbolsListProps = {
  symbols: GetPortfolioBotDetailScripts[];
  liveFeedData: SymbolsData;
};

const SymbolsList = ({ symbols, liveFeedData }: SymbolsListProps) => {
  const getSymbolTickData = symbol => {
    const symbolValue = liveFeedData[symbol] || 0;
    const symbolClose = Number(symbolValue.Close) || 0;
    const symbolCloseDiff = symbolClose - Number(symbolValue.LastClose) || 0;
    return { symbolClose, symbolCloseDiff };
  };
  return (
    <Fragment>
      <Divider className="m-main-spacing" sx={{ borderBottomWidth: '2px' }} />
      {symbols && symbols.length > 0 ? (
        symbols.map((symbol, i) => (
          <Fragment key={symbol.symbol}>
            <SymbolItem
              key={symbol.symbol}
              symbol={symbol}
              data={getSymbolTickData(symbol.symbol)}
            />
            {symbols.length - 1 !== i && <Divider className="m-main-spacing" />}
          </Fragment>
        ))
      ) : (
        <StatusMessage title="No Symbols" subtitle="" />
      )}
    </Fragment>
  );
};

export default SymbolsList;
