import { useState } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import useGetProfile from 'services/Menu/useGetProfile';
import PlanChangeModal from './PlanChangeModal';
import PlanSubscribeModal from './PlanSubscribeModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import ActivePlanInfo from './ActivePlanInfo';
import { UserPlanType } from 'types/User';
import CreditUsageInfo from './CreditUsageInfo';

const PlanSubscribeCapabilities = () => {
  const isMdUp = useGetMediaQueryUp('md');

  const [openSubscribePlanModal, setOpenSubscribePlanModal] =
    useState<boolean>(false);
  const [openChangePlanModal, setOpenChangePlanModal] =
    useState<boolean>(false);

  const { data: profileDetails } = useGetProfile();
  const userPlan = profileDetails ? profileDetails.user_plan : null;

  const handleCloseSubscribePlanModal = () => setOpenSubscribePlanModal(false);

  const handleCloseChangePlanModal = () => setOpenChangePlanModal(false);

  const planType = userPlan && userPlan.plan.plan_type;

  return (
    <>
      <Box>
        {userPlan && (
          <ActivePlanInfo
            walletBalance={profileDetails?.wallet_balance}
            userPlan={userPlan}
          />
        )}
        {/* Capabilities Info */}
        {userPlan && userPlan.capabilities && userPlan.capabilities.length > 0 && (
          <Box mt={2}>
            {planType === UserPlanType.PAY_AS_YOU_GO ? (
              <CreditUsageInfo capabilities={userPlan.capabilities} />
            ) : (
              <Grid container spacing={3} sx={{ pt: 2 }}>
                {userPlan.capabilities.map(capability => (
                  <Grid item xs={12} md={6} lg={4} key={capability.key}>
                    <Box
                      display="flex"
                      justifyContent={'space-between'}
                      sx={{ mb: 0.5 }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        sx={{ mr: 1 }}
                      >
                        {capability.name}
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                        {capability.balance}
                        <Typography
                          variant="body3"
                          color="text.secondary"
                          sx={{ ml: 0.5 }}
                        >
                          available
                        </Typography>
                      </Typography>
                    </Box>
                    <Box sx={{ mb: 0.5 }}>
                      <LinearProgress
                        variant="determinate"
                        value={
                          capability.credit
                            ? (capability.balance * 100) / capability.credit
                            : 0
                        }
                        sx={{ transform: 'scale(-1, 1)' }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        color="text.secondary"
                      >
                        {capability.debit} of {capability.credit} used
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}
      </Box>

      {isMdUp && (
        <>
          <PlanChangeModal
            open={openChangePlanModal}
            handleClose={handleCloseChangePlanModal}
          />
          <PlanSubscribeModal
            open={openSubscribePlanModal}
            handleClose={handleCloseSubscribePlanModal}
          />
        </>
      )}
    </>
  );
};

export default PlanSubscribeCapabilities;
