import { useQuery } from 'react-query';
import { GetTicketsData } from 'services/apiServices';
import { Errors, GetTicketsDataResult } from 'types/ApiServicesTypes';

export default function useGetTicketData() {
  const objQuery = useQuery<GetTicketsDataResult, Errors>(
    'GetTicketsData',
    () => GetTicketsData(),
  );
  return objQuery;
}
