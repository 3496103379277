import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { AuthenticateDefault } from 'services/apiServices';
import { Errors, AuthenticateDefualtResult } from 'types/ApiServicesTypes';

export default function useAuthenticateDefault(sessionId: string) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<AuthenticateDefualtResult, Errors>(
    'AuthenticateDefualt',
    () =>
      AuthenticateDefault(sessionId, 'GET')
        .then(res => {
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
