import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, IconButton, useTheme } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import useGetBotTemplates from 'services/BotTemplates/useGetBotTemplates';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import BotTemplateCategoryBox from '../../../design/layouts/BotTemplateCategoryBox';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import IframeDialog from 'app/components/Common/IframeDialog';
import SignInModal from 'app/components/Auth/SignInModal';
import { isUserLoggedIn } from 'utils/auth';
import { YouTube } from '@mui/icons-material';
import { getQueryParam } from 'utils/GenericFunctions';
import { PRODUCT_TOUR } from 'constants/common';
import ProductTour from 'app/components/Common/ProductTour/ProductTour';
import { TemplatePageProductSteps } from 'app/components/Common/ProductTour/ProductTourData';

export function BotTemplatesPage() {
  const { t } = useTranslation();
  const { data: siteInfo } = useGetSiteInfo();
  const queryType = getQueryParam('type');
  const qt: string | undefined = queryType;
  const { data: categories, isLoading } = useGetBotTemplates({ type: qt });
  const breadcrumb = useBreadcrumbManager();
  const isMdUp = useGetMediaQueryUp('md');

  const isFeatureEquity = useIsFeatureFlag([FEATURES.IS_EQUITY_BOTBUILDER]);
  const isFeatureOption = useIsFeatureFlag([FEATURES.IS_OPTION_BOTBUILDER]);

  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoggedIn = isUserLoggedIn();
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Bot Templates</title>
        <meta name="description" content="Bot Templates" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <Box
        className="sticky-top"
        sx={{ backgroundColor: theme.palette.common.white }}
      >
        <PageHeader
          variant="market"
          title={
            <>
              <FormattedMessage id="templates.page_title.templates" />
              {isMdUp ? (
                <>
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    startIcon={<YouTube fontSize="medium" />}
                    onClick={() => setOpen(true)}
                    sx={{ ml: 2 }}
                  >
                    How to ?
                  </MuiButton>
                </>
              ) : (
                <IconButton onClick={() => setOpen(true)} sx={{ p: '3px' }}>
                  <YouTube fontSize="medium" />
                </IconButton>
              )}
            </>
          }
          marketIconProps={{
            icon: (
              <SearchIcon
                fontSize="medium"
                color="primary"
                sx={{ ml: { md: 1 } }}
              />
            ),
            iconClick: () =>
              breadcrumb.push(
                '/templates/search',
                PAGES.TEMPLATES,
                `/templates`,
                t('templates.page_title.templates'),
              ),
          }}
        />
      </Box>
      {(isFeatureEquity || isFeatureOption) && (
        <FeatureFlag features={[FEATURES.IS_BACKTESTING]} fallback={<></>}>
          <Box
            display="flex"
            justifyContent="end"
            className="p-main-spacing"
            gap={2}
            sx={{
              mt: 1,
              width: { xs: '100%', md: 'auto' },
            }}
          >
            {isFeatureEquity && isFeatureOption ? (
              <>
                <Box
                  display="flex"
                  justifyContent="end"
                  gap={2}
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    onClick={() => {
                      isLoggedIn
                        ? breadcrumb.push(
                            '/bot-builder?type=equity&source=Portfolio',
                            PAGES.MARKETPLACE,
                            `/discover/bots`,
                            t('market.page_title.market'),
                          )
                        : setIsModalOpen(true);
                    }}
                  >
                    <FormattedMessage id="buttons.create_equity_bot" />
                  </MuiButton>
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    onClick={() => {
                      breadcrumb.push(
                        '/bot-builder?type=option&source=Portfolio',
                        PAGES.MARKETPLACE,
                        `/discover/bots`,
                        t('market.page_title.market'),
                      );
                    }}
                  >
                    <FormattedMessage id="buttons.create_option_bot" />
                  </MuiButton>
                </Box>
              </>
            ) : (
              <>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  sx={{ width: { xs: '100%', md: 'auto' }, alignSelf: 'end' }}
                  onClick={() => {
                    breadcrumb.push(
                      `/bot-builder?type=${
                        isFeatureEquity ? 'equity' : 'option'
                      }&source=Portfolio`,
                      PAGES.MARKETPLACE,
                      `/discover/bots`,
                      t('market.page_title.market'),
                    );
                  }}
                >
                  <FormattedMessage id="buttons.create_bot" />
                </MuiButton>
              </>
            )}
          </Box>
        </FeatureFlag>
      )}
      {categories &&
        categories.map(category => (
          <BotTemplateCategoryBox
            key={category.category_id}
            category={category}
          />
        ))}
      <IframeDialog
        open={open}
        link={
          siteInfo && siteInfo.bot_template_help_video_link
            ? siteInfo.bot_template_help_video_link
            : 'https://www.youtube.com/embed/uV6bmoy-QDk'
        }
        handleClose={handleClose}
      />
      <SignInModal
        handleClose={() => setIsModalOpen(false)}
        open={isModalOpen}
      />
      {!JSON.parse((localStorage.getItem(PRODUCT_TOUR) as string) || '')[
        'Template Page'
      ] && (
        <ProductTour
          steps={TemplatePageProductSteps}
          tour_page="Template Page"
        />
      )}
    </>
  );
}
