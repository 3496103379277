import { Typography, Box, Alert } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { CAPABILITIES } from 'types/Capability';
import PlanUpgradeButton from './PlanUpgradeButton';
import BotActivateFreeTrial from './BotActivateFreeTrial';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export default function BotDeployCapabilityFallback({
  botName,
  planName,
  credit,
  onclose,
  variant,
}: {
  variant?: 'live' | 'paper';
  botName: string;
  planName: string;
  credit: number;
  onclose: () => void;
}) {
  const isPlans = useIsFeatureFlag([FEATURES.IS_PLANS]);
  return (
    <>
      <Box
        className="m-main-spacing"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box flex={1}>
          <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
            <FormattedMessage id="common.are_you_sure" />
          </Typography>
          <Typography
            variant="body1"
            sx={{ pb: 1, display: 'flex', fontWeight: 600 }}
            component="span"
          >
            {variant === 'live' ? (
              <FormattedMessage id="buttons.deploy" />
            ) : (
              <FormattedMessage id="bot_status.paper" />
            )}
            <Typography
              variant="body1"
              sx={{ pl: 0.5, fontWeight: 400 }}
              component="span"
            >
              <FormattedMessage id="common.with" />
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ pb: 4 }}>
            {botName}?
          </Typography>
          {isPlans ? (
            <Alert severity="warning" sx={{ mb: 4 }}>
              <Typography variant="body2">Go live limit reached</Typography>
              <Typography variant="body2" fontWeight={400}>
                {planName} plan is limited to {credit} Go Live. Unlock more Go
                Live by upgrading your plan.
              </Typography>
            </Alert>
          ) : (
            <Alert severity="error" sx={{ mb: 4 }}>
              <Typography variant="body2">Go live limit reached</Typography>
              <Typography variant="body2" fontWeight={400}>
                You have reached the maximum capacity for{' '}
                <Typography variant="body2" fontWeight={600} component={'span'}>
                  Go Live
                </Typography>
                . Please contact your Administrator for more details.
              </Typography>
            </Alert>
          )}
        </Box>
        <Box>
          {isPlans && (
            <>
              <PlanUpgradeButton capability={CAPABILITIES.LIVE_TRADING} />
              <Box>
                <BotActivateFreeTrial isFromModal />
              </Box>
            </>
          )}
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onclose}
          >
            <FormattedMessage id="buttons.cancel" />
          </MuiButton>
        </Box>
      </Box>
    </>
  );
}
