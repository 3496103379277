import { useState, useCallback, createContext, memo } from 'react';
import { AlertColor } from '@mui/material';

export type Toastr = {
  message: string;
  type: AlertColor | undefined;
  visible: boolean;
};

export type ToastContextProps = {
  hide?: () => void;
  showAlert?: (message: string, type?: AlertColor | undefined) => void;
  toast?: Toastr;
};

const initialToast: Toastr = {
  message: '',
  type: 'error',
  visible: false,
};

export const ToastContext = createContext<ToastContextProps>({});

const ToastProviderComp = ({ children }) => {
  const [toast, setToast] = useState<Toastr>(initialToast);

  const showAlert = useCallback(
    (message: string, type?: AlertColor | undefined) => {
      if (!type) type = 'error';

      setToast({
        ...initialToast,
        visible: true,
        message: message,
        type: type,
      });
    },
    [],
  );

  const hide = useCallback(() => {
    setToast({ ...toast, visible: false, message: '' });
  }, [toast]);

  return (
    <ToastContext.Provider
      value={{
        hide,
        showAlert,
        toast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const ToastProvider = memo(ToastProviderComp);

// export const ShowToastr = ({
//   message,
//   type,
// }: {
//   message: string;
//   type: string;
// }) => {
//   const { showAlert } = useContext(ToastContext);
//   type = type ? type : 'error';

//   useEffect(() => {
//     showAlert({ message, type });
//   }, [message, type, showAlert]);
//   return { message, type };
// };
