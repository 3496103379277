export const loadScript = (src: string, id: string) => {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
