import { Grid, Typography } from '@mui/material';
import { BotTradeStatsData } from 'types/ApiServicesTypes';
import ItemSeparator from 'app/design/speedBot/EntityItem/ItemSeparator';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const InfoGridTradeStats = ({
  data,
  baseApiUrl,
  currencySymbol,
}: {
  data: BotTradeStatsData;
  baseApiUrl: string;
  currencySymbol: string;
}) => {
  const isFromDiscover = baseApiUrl.startsWith('marketplace');
  let isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);
  isShowResult = isFromDiscover ? isShowResult : true;

  return (
    <Grid container spacing={2} sx={{ px: 3, py: 2 }}>
      <Grid item xs={3}></Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body1" color="text.primary">
          <FormattedMessage id="summary_labels.total" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body1" color="text.primary">
          <FormattedMessage id="summary_labels.win" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body1" color="text.primary">
          <FormattedMessage id="summary_labels.loss" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ItemSeparator variant="2px" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" fontWeight={400} color="text.disabled">
          <FormattedMessage id="summary_labels.trades" />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.total" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.numberofwinningtrades + data.numberoflosingtrades}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.numberofwinningtrades}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.numberoflosingtrades}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.max_consecutive" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          -
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.maxconsecutivewinningtrades}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.maxconsecutivelosingtrades}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ItemSeparator variant="1px" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="text.disabled">
          <FormattedMessage id="summary_labels.gross_profit_loss" />
        </Typography>
      </Grid>
      {isShowResult && (
        <>
          <Grid item xs={3}>
            <Typography variant="body3" color="text.secondary">
              <FormattedMessage id="summary_labels.total" />
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <Typography
              variant="body2"
              fontWeight={400}
              color={getColorByNum(
                data.totalprofit_loss ? data.totalprofit_loss : 0,
              )}
            >
              {currencySymbol}
              {formatNumber(data.totalprofit_loss)}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <Typography
              variant="body2"
              fontWeight={400}
              color={getColorByNum(data.total_profit_trade)}
            >
              {currencySymbol}
              {formatNumber(data.total_profit_trade)}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <Typography
              variant="body2"
              fontWeight={400}
              color={getColorByNum(data.total_loss_trade)}
            >
              {currencySymbol}
              {formatNumber(data.total_loss_trade)}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.avg.title" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography
          variant="body2"
          fontWeight={400}
          color={getColorByNum(
            data.averageprofitloss ? data.averageprofitloss : 0,
          )}
        >
          {currencySymbol}
          {formatNumber(data.averageprofitloss)}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="success.main">
          {currencySymbol}
          {formatNumber(data.average_profit)}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="error.main">
          {currencySymbol}
          {formatNumber(data.average_loss)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.largest" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          -
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="success.main">
          {currencySymbol}
          {formatNumber(data.largestprofit)}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="error.main">
          {currencySymbol}
          {formatNumber(data.largestloss)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ItemSeparator variant="1px" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="text.disabled">
          <FormattedMessage id="summary_labels.trade_duration" />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.avg.title" />
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.averagetradeduration}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.averagewinningtradeduration}
        </Typography>
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Typography variant="body2" fontWeight={400} color="text.primary">
          {data.averagelosingtradeduration}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InfoGridTradeStats;
