import { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tooltip,
} from '@mui/material';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import { Position } from 'types/ApiServicesTypes';

import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import GetAllBotPositionTable from '../speedBot/ComponentItem/Portfolio/GetAllPositionTable';
import PositionItemCard from './PositionItemCard';
import { Sort } from '@mui/icons-material';
import { TableColumns } from 'app/components/Common/CommonTable/type';
import i18next from 'i18next';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { getQueryParam } from 'utils/GenericFunctions';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { ToggleContext } from 'app/components/Context/ToggleViewContext';
import MuiSwitchUnstyled from '../uiComponents/MuiUnstyledSwitch';
import nodatafound from '../../../assets/images/No_data_image.png';
import StatusMessage from 'app/components/Common/StatusMessage';
import { SymbolsData } from 'types/Symbols';

// let eventDir: string | null = null;

const AllTodaysPositionLayout = ({
  data,
  handleClick,
  handleChartRowClick,
  notMdUpData,
  isloading,
  liveFeedData,
}: {
  data: PositionsListLayoutProps;

  //   handleChangePagination: (
  //     event: React.ChangeEvent<unknown>,
  //     value: number,
  //   ) => void;
  notMdUpData: Position[];
  handleClick?: (positionData: Position) => void;
  handleChartRowClick?: (positionData: Position) => void;
  handleChangeTab?: (newValue: number) => void;
  isloading: boolean;
  liveFeedData?: SymbolsData;
}) => {
  const isMdUp = useGetMediaQueryUp('md');

  //   const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
  //     handleTouchStartHandle(e);
  //   };
  //   const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
  //     const eventDirRes = handleTouchMoveHandle(e);
  //     if (eventDirRes) eventDir = eventDirRes;
  //   };
  //   const handleTouchEnd = () => {
  //     if (!isMdUp && eventDir === 'right' && handleChangeTab) {
  //       handleChangeTab(1);
  //       eventDir = '';
  //     }
  //   };
  const [sortKey, setSortKey] = useState<string>('trading_symbol');
  const [sortDirection] = useState<'asc' | 'desc'>('asc');

  const handleSortChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'remove') setSortKey('');
    else setSortKey(event.target.value);
  };
  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.bot_name'), key: 'bot_name' },

    { text: i18next.t('summary_labels.symbol'), key: 'trading_symbol' },
    {
      text: i18next.t('summary_labels.status'),
      key: 'status',
    },
    {
      text: i18next.t('summary_labels.position'),
      align: 'center',
      key: 'direction',
    },
    {
      text: i18next.t('summary_labels.qty_short'),
      align: 'center',
      key: 'quantity',
    },
    { text: i18next.t('summary_labels.entry_time'), key: 'entry_time' },
    {
      text: i18next.t('summary_labels.entry_price'),
      align: 'right',
      key: 'entry_price',
    },
    { text: i18next.t('summary_labels.exit_time'), key: 'exist_time' },
    {
      text: i18next.t('summary_labels.exit_price'),
      align: 'right',
      key: 'exit_price',
    },

    {
      text: i18next.t('summary_labels.net_profit'),
      align: 'right',
      // hide: !isChargesAndProfit,
      key: 'profit_loss',
    },
    // {
    //   text: `${i18next.t('summary_labels.profit')}/${i18next.t(
    //     'summary_labels.loss',
    //   )}`,
    //   align: 'right',
    //   // hide: isChargesAndProfit,
    // },
  ];
  const { open, toggle } = useContext(ToggleContext);
  const query = getQueryParam('ptype');
  const isViewAllPositions = useIsFeatureFlag([FEATURES.IS_VIEW_ALL_POSITIONS]);
  return (
    <>
      <CircularLoader open={isloading} />
      <Box
        sx={{ py: 1, height: '100%' }}
        // onTouchStart={(touchStartEvent: React.TouchEvent<HTMLElement>) =>
        //   handleTouchStart(touchStartEvent)
        // }
        // onTouchMove={(touchMoveEvent: React.TouchEvent<HTMLElement>) =>
        //   handleTouchMove(touchMoveEvent)
        // }
        // onTouchEnd={() => handleTouchEnd()}
      >
        {!isMdUp && (
          <Box
            className="p-main-spacing"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* <Typography variant="h6"></Typography> */}
            <FormControl
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <>
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortKey}
                  onChange={handleSortChange}
                  label="Sort by"
                  defaultValue=""
                  sx={{ minWidth: '120px' }}
                  displayEmpty
                  endAdornment={
                    sortKey && (
                      <InputAdornment position="end" sx={{ mr: 2 }}>
                        <Sort />
                      </InputAdornment>
                    )
                  }
                >
                  {/* <MenuItem disabled value="">
              <em>Sort by</em>
            </MenuItem> */}
                  {columns.map(column => {
                    return (
                      // <>
                      <MenuItem key={column.key} value={column.key}>
                        {column.text}
                      </MenuItem>
                      // {/* </> */}
                    );
                  })}
                  {/* <MenuItem value={'remove'}>Remove</MenuItem> */}
                </Select>
              </>

              {!isMdUp &&
                isViewAllPositions &&
                (query === 'active' || query === 'paper') && (
                  <Box
                    flex={1}
                    mt={1}
                    display="flex"
                    justifyContent="center"
                    // className="p-main-spacing"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <Typography variant="body2">
                      {/* {toTitleCase(query === 'active' ? 'Live' : query)}  */}
                      Bots
                    </Typography>
                    <Tooltip
                      title={!open ? 'See all Positions' : 'See all bots'}
                    >
                      <MuiSwitchUnstyled checked={open} onChange={toggle} />
                    </Tooltip>
                    <Typography variant="body2">
                      {/* {toTitleCase(query === 'active' ? 'Live' : query)}{' '} */}
                      Positions
                    </Typography>
                  </Box>
                )}
            </FormControl>
          </Box>
        )}
        {isMdUp ? (
          <GetAllBotPositionTable
            liveFeedData={liveFeedData}
            data={data}
            handleClick={handleClick}
            handleChartRowClick={handleChartRowClick}
          />
        ) : (
          // data &&
          // data.map((grp, i: number) => {
          //   rows.push(grp.data);
          //   return (
          <Box>
            <PositionItemCard
              liveFeedData={liveFeedData}
              data={notMdUpData}
              handleClick={handleClick}
              sortKey={sortKey}
              sortDirection={sortDirection}
            />
          </Box>
          //   );
          // })
        )}
        {((data && !data.length) || (nodatafound && !notMdUpData.length)) && (
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              // justifyContent: 'center',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              mt: 10,
            }}
          >
            <img
              src={nodatafound}
              style={{ width: '150px', height: '150px' }}
            />
            <StatusMessage
              subtitle={'Currently you do not have any open positions'}
              fontStyle="italic"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AllTodaysPositionLayout;
