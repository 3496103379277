import { memo } from 'react';
import PageHeader from 'app/design/speedBot/PageHeader';
import { Position } from 'types/ApiServicesTypes';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import InfoListPosition from 'app/design/speedBot/ComponentItem/Bots/InfoListPosition';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const BotHistoryPositionsModal = ({
  open,
  handleClose,
  data,
  title,
  buySellPositions,
}: {
  open: boolean;
  handleClose: () => void;
  data: Position | null;
  title: string | React.ReactElement;
  buySellPositions?: boolean;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <MuiFullScreenModal
      open={open}
      handleClose={handleClose}
      isNoBackButton={true}
      isDisableEscapeKeyDown={false}
      isDisableEnforceFocus={false}
      body={
        <>
          <PageHeader
            variant="withChipWithSubtitleWithBack"
            subtitle={data ? data.instrument_id : ''}
            title={data ? data.trading_symbol : ''}
            chipProps={{
              label:
                buySellPositions && data?.direction === '0' ? (
                  <FormattedMessage id="chip.buy" />
                ) : buySellPositions ? (
                  <FormattedMessage id="chip.sell" />
                ) : data?.direction === '0' ? (
                  <FormattedMessage id="chip.long_pos" />
                ) : (
                  <FormattedMessage id="chip.short_pos" />
                ),
              color: data?.direction === '0' ? 'infolight' : 'purplelight',
              // color: !buySellPositions
              //   ? 'greylight'
              //   : data?.direction === '0'
              //   ? 'infolight'
              //   : 'purplelight',
              variant: 'filled',
            }}
            {...(isMdUp && { hideBackButton: true })}
            buttonText={title}
            buttonHandleClick={() => handleClose()}
          />
          {data && <InfoListPosition data={data} />}
        </>
      }
    />
  );
};

export default memo(BotHistoryPositionsModal);
