import { Box, Typography } from '@mui/material';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import CreateAlert from 'assets/images/signalguide/create_alert.png';

export type TradingViewModelStepProps = {
  handleBack: (backStep: number) => void;
  handleNext: (nextStep: number) => void;
  currentStep: number;
};

const CreateTradingViewAlert = ({ img_url }: { img_url?: string }) => {
  return (
    <Box className="m-main-spacing">
      <Typography py={1} variant="body1" fontWeight={600}>
        Open Trading View and Create Alert
      </Typography>
      <ItemSeparator variant="1px" orientation="horizontal" />
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          mt: 1,
          aspectRatio: '1/1',
          maxHeight: { sm: '400px', md: '600px' },
          width: '100%',
        }}
      >
        <img
          className="p-main-spacing"
          src={img_url ? img_url : CreateAlert}
          style={{
            width: '100%',
            height: '100%',
            margin: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Box>
  );
};

export default CreateTradingViewAlert;
