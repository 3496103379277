import { useContext } from 'react';
import { ToastContext } from './ToastContext';
import ToastrAlert from 'app/design/uiComponents/ToastrAlert';

const Toast = () => {
  const { toast, hide } = useContext(ToastContext);

  return (
    <ToastrAlert
      type={toast && toast.type ? toast.type : 'error'}
      message={toast && toast.message ? toast.message : ''}
      setMessage={hide!}
    />
  );
};

export default Toast;
