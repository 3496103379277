import { useHistory } from 'react-router-dom';
import { Typography, Box, Alert } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { formatNumber } from 'utils/GenericFunctions';
import { GoLiveFormProps } from './BotDeployModal';
import { PostBotActionParams } from 'types/ApiServicesTypes';

// Services
import useGetBrokers from 'services/Menu/useGetBrokers';
import usePostBotAction from 'services/Bots/usePostBotAction';
import useGetMarketplaceBotDetail from 'services/Marketplace/useGetMarketplaceBotDetail';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import moment from 'moment';
import CreditEstimation from 'app/components/Common/CreditEstimation';

export default function BotDeploySummary({
  botName,
  openFrom,
  botId,
  summary,
  onclose,
  isPayAsYouGo,
  isAddMoreLots,
}: {
  botName: string;
  openFrom: string;
  botId?: number;
  summary: GoLiveFormProps;
  isPayAsYouGo?: boolean;
  onclose: () => void;
  isAddMoreLots?: boolean;
}) {
  // const { t } = useTranslation();
  const history = useHistory();
  const { data: marketplaceBotDetailData } = useGetMarketplaceBotDetail(
    botId as number,
  );
  const mutation = usePostBotAction(botId as number, 'discover');
  const { data: brokersData } = useGetBrokers();
  const brokerId = parseInt(summary.broker);
  const selectedBroker =
    brokersData && brokersData.find(broker => broker.id === brokerId);

  const currencySymbol = marketplaceBotDetailData?.currency_symbol || '';

  const handleDeployClick = () => {
    const curDate = moment(new Date());
    let durationDays = 0;
    const duration = summary.duration.split(' ');

    if (summary.duration === 'Manually') {
      const dt = moment().add(
        Number(summary.amount!),
        summary.durationType!.toLowerCase() as moment.unitOfTime.DurationConstructor,
      );
      durationDays = dt.diff(curDate, 'days');
    } else if (duration[1] === 'Days' || duration[1] === 'Day') {
      durationDays = parseInt(duration[0]);
    } else if (duration[1] === 'Weeks' || duration[1] === 'Week') {
      durationDays = parseInt(duration[0]) * 7;
    } else if (duration[1] === 'Months' || duration[1] === 'Month') {
      const dt = moment(new Date()).add(parseInt(duration[0]), 'months');
      durationDays = dt.diff(curDate, 'days');
    }

    const objReq: PostBotActionParams = {
      type: 'live',
      user_broker_id: brokerId,
      duration: durationDays,
      multiplier: summary.multiple
        ? parseInt(summary.multiple)
        : parseInt(summary.capital) /
          (marketplaceBotDetailData?.initial_capital
            ? marketplaceBotDetailData?.initial_capital
            : 1),
    };

    if (isAddMoreLots) {
      objReq.action = 'add';
    }
    mutation.mutate(objReq);
  };
  if (mutation.isSuccess) {
    onclose();
    const newId: number = mutation.data.sub_id;
    mutation.reset();

    // Log entry for subscribe live
    logFirebaseEventEntry('BOT_START_LIVE', {
      bot_id: botId,
      sub_id: mutation.data.sub_id,
    });

    // Redirect to new id which is found
    if (newId !== botId && openFrom === 'bots') {
      history.push(
        // `/bots/${newId}/Bots/?successMessage=Bot is successfully started`,
        `/bots/${botId}/details/${newId}/Bots`,
      );
    }
  }

  // if (mutation.error && open) {
  if (mutation.error) {
    mutation.reset();
  }
  return (
    <>
      <Box
        className="m-main-spacing"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box flex={1}>
          <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
            <FormattedMessage id="bot_deploy_modal.confirm" />
          </Typography>
          <Typography
            variant="body1"
            sx={{ pb: 1, display: 'flex', fontWeight: 600 }}
            component="span"
          >
            <FormattedMessage id="buttons.deploy" />
            <Typography
              variant="body1"
              sx={{ pl: 0.5, fontWeight: 400 }}
              component="span"
            >
              <FormattedMessage id="common.with" />
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            {botName}?
          </Typography>

          <ItemSeparator variant="2px" />

          <Box sx={{ mb: 3, pt: 2 }}>
            <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
              <Typography variant="body2">
                <FormattedMessage id="common.broker" />
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {selectedBroker?.broker} ({selectedBroker?.username})
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
              <Typography variant="body2">
                <FormattedMessage id="bot_deploy_modal.duration" />
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {summary.duration === 'Manually'
                  ? `${summary.amount} ${summary.durationType}`
                  : summary.duration}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
              <Typography variant="body2">
                <FormattedMessage id="bot_deploy_modal.capital" />
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {summary.capital === 'Manually'
                  ? `${currencySymbol}${formatNumber(
                      (marketplaceBotDetailData?.initial_capital as number) *
                        Number(summary.multiple!),
                      false,
                      0,
                    )}`
                  : `${currencySymbol}${formatNumber(
                      Number(summary.capital),
                      false,
                      0,
                    )}`}
              </Typography>
            </Box>
            {isPayAsYouGo && (
              <CreditEstimation action="go_live" data={{}}>
                {({ walletBalance, requiredCredits, actionInfo }) => (
                  <>
                    <ItemSeparator variant="2px" />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{ py: 2 }}
                    >
                      <Typography variant="body2">
                        <FormattedMessage id="menu.credit_balance" />
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color={
                          walletBalance < 0 ? 'error.main' : 'text.primary'
                        }
                      >
                        {walletBalance < 0 ? '-' : ''}
                        {walletBalance}
                      </Typography>
                    </Box>
                    <Alert severity="info">
                      <Typography variant="body2">
                        <Typography fontWeight={600} component={'span'}>
                          {requiredCredits}
                        </Typography>{' '}
                        credits
                        <Typography variant="body2" component={'span'} mx={0.5}>
                          {actionInfo}
                        </Typography>
                        will be deducted
                      </Typography>
                    </Alert>
                  </>
                )}
              </CreditEstimation>
            )}
          </Box>
        </Box>
        <Box>
          <MuiButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2 }}
            onClick={handleDeployClick}
          >
            <FormattedMessage id="buttons.deploy" />
          </MuiButton>

          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onclose}
          >
            <FormattedMessage id="buttons.cancel" />
          </MuiButton>
        </Box>
      </Box>
    </>
  );
}
