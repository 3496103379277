import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import StrategyBuilderEntryExitTab from './StrategyBuilderEntryExitTab';
import StrategyBuilderSymbolTab from './StrategyBuilderSymbolTab';
import {
  StrategyBuilderEntryExitFormData,
  StrategyBuilderFormData,
  StrategyBuilderPortfolioFormData,
  StrategyBuilderSymbolsFormData,
  UserBot,
} from './types';
import StrategyBuilderPortfolioTab from './StrategyBuilderPortfolioTab';
import StrategyBuilderStepper from './StrategyBuilderStepper';
import useSaveUserBot from 'services/Bots/useSaveUserBot';
import { SaveUserBotResult } from 'types/ApiServicesTypes';
import {
  StrategyBuiltWithTypes,
  transformSaveUserBotRequest,
  transformSaveUserBotResponse,
} from './util';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import PageHeader from 'app/design/speedBot/PageHeader';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import MuiButton from 'app/design/uiComponents/MuiButton';

import AutoStoriesIcon from '@mui/icons-material/AutoStories';
//Services
import useEditUserBot from 'services/Bots/useEditUserBot';
import { getQueryParam } from 'utils/GenericFunctions';
import HelpGuideDialog from './HelpGuideDialog';
import CreateTickFormModal from 'app/components/Menu/CreateTicketFormModal';
import { isUserLoggedIn } from 'utils/auth';
import { CoPresent, SaveAltOutlined } from '@mui/icons-material';
import HintAlert from 'app/design/speedBot/ComponentItem/Bots/HintAlert';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { callingBuilderType } from 'app/components/Builder/Strategy/fields/fieldsData';
import ImportStrategyDialog from './ImportStrategyDialog';

export default function EquityStrategyBuilderContent({
  botId,
  subId,
  userBotDetails,
  isLoading,
  isTemplate,
}: {
  botId: number;
  subId: number;
  userBotDetails: UserBot | undefined;
  isLoading: boolean;
  isTemplate?: boolean;
}) {
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();
  const { t } = useTranslation();
  const isLgUp = useGetMediaQueryUp('lg');
  const source = getQueryParam('source');
  const isLoggedIn = isUserLoggedIn();
  const [openReadDialog, setOpenReadDialog] = useState(false);
  const isHelpDeskInSb = useIsFeatureFlag([FEATURES.IS_HELP_DESK_IN_SB]);
  const [selectedExchange, SetSelectedExchange] = useState<string>(
    location.hostname.split('.')[0] === 'us' ? 'USA' : 'NSE',
  );
  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);
  // const { data: userBotDetails, isLoading } = useGetUserBot(botId);
  const { data: siteInfo } = useGetSiteInfo();
  const userBotTransforDetails: StrategyBuilderFormData | null = useMemo(() => {
    if (botId) return transformSaveUserBotResponse(userBotDetails);
    return null;
  }, [botId, userBotDetails]);

  const [step, setStep] = useState<number>(0);
  const [formValues, setFormValues] = useState<StrategyBuilderFormData | null>(
    userBotTransforDetails,
  );
  const [openImpmortDialog, setOpenImportDialog] = useState(false);

  const isBotDescription = useIsFeatureFlag([FEATURES.IS_BOT_DISCRIPTION]);
  const isUSAsupporInEq = useIsFeatureFlag([FEATURES.IS_USA_SUPPORT_IN_EQ]);
  const isImportExportStrategy = useIsFeatureFlag([
    FEATURES.IS_IMPORT_EXPORT_STRATEGY,
  ]);
  const setWizardStep = (value: number) => {
    setStep(value);
  };

  const setSymbols = (symbols: StrategyBuilderSymbolsFormData) => {
    setWizardStep(1);
    if (!isBotDescription) delete symbols.description;
    setFormValues({ ...formValues, symbols: symbols });
  };

  const setEntryExit = (values: StrategyBuilderEntryExitFormData) => {
    setStep(2);
    setFormValues({ ...formValues, entryExitData: values });
  };

  const saveUserBotSuccessCallback = (
    res?: SaveUserBotResult,
    name?: string,
  ) => {
    if (res) {
      breadcrumb.push(
        `/bot/${res.bot_id}/backtesting/${subId ? subId : ''}`,
        PAGES.BOT_STRATEGY_BUILDER,
        `/bot-builder/${botId}/${subId ? subId : ''}`,
        name as string,
      );
    }
  };

  const mutationAdd = useSaveUserBot(saveUserBotSuccessCallback);
  const mutationEdit = useEditUserBot(botId, saveUserBotSuccessCallback);

  useEffect(() => {
    callingBuilderType('equity');
  }, []);

  const setPortfolio = (values: StrategyBuilderPortfolioFormData) => {
    setFormValues({ ...formValues, portfolioData: values });

    // Call api here
    if (formValues && formValues.symbols && formValues.entryExitData) {
      const objReq = transformSaveUserBotRequest(
        formValues.symbols,
        formValues.entryExitData,
        values,
      );

      if (objReq.sb_strategy) {
        objReq.sb_strategy.type = 'equity';
        objReq.sb_strategy.built_with = StrategyBuiltWithTypes.equity_builder;
      }

      if (
        botId &&
        objReq.sb_strategy &&
        userBotDetails &&
        userBotDetails.sb_strategy
      ) {
        objReq.sb_strategy.start_date = userBotDetails.sb_strategy.start_date;
        objReq.sb_strategy.end_date = userBotDetails.sb_strategy.end_date;

        objReq.sb_strategy.initial_capital =
          userBotDetails.sb_strategy.initial_capital;
        objReq.sb_strategy.trading_start_time =
          userBotDetails.sb_strategy.trading_start_time;
        objReq.sb_strategy.trading_stop_time =
          userBotDetails.sb_strategy.trading_stop_time;
        objReq.sb_strategy.override_leverage = 5000;
      }
      objReq.is_backtest = false;
      if (isUSAsupporInEq) objReq.exchange = selectedExchange;

      if (isTemplate) objReq.template_id = botId;

      if (botId && !isTemplate) mutationEdit.mutate(objReq);
      else mutationAdd.mutate(objReq);
    }
  };

  return (
    <>
      <CircularLoader
        open={isLoading || mutationAdd.isLoading || mutationEdit.isLoading}
      />
      <Box>
        <PageHeader
          variant="inactive"
          buttonText={
            userBotDetails && Object.keys(userBotDetails).length > 0
              ? t('buttons.backtesting')
              : source
              ? source
              : t('bots.page_title.bots')
          }
          title={
            <Box
              sx={{
                display: { xs: 'block', md: 'flex' },
                alignItems: 'center',
              }}
            >
              {t('user_bot_startegy.page_title2')}
              <Box
                sx={{
                  display: 'flex',
                  mt: { xs: 1, md: 0 },
                  ml: { xs: 0, md: 'auto', lg: 'auto' },
                  gap: 1,
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <Box display={'flex'}>
                  <FeatureFlag
                    features={[FEATURES.IS_TEMPLATES]}
                    fallback={<></>}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      startIcon={<AutoAwesomeMotionIcon />}
                      onClick={() => history.push('/templates?type=equity')}
                      // sx={{ width: { xs: '50%', md: 'auto' } }}

                      sx={{ flex: { xs: 1, md: 'auto' }, mr: 1 }}
                    >
                      {t('buttons.use_bot_templates')}
                    </MuiButton>
                  </FeatureFlag>

                  {/* <Typography
                  sx={{
                    alignSelf: 'center',
                    width: { xs: '15%', md: 'auto' },
                  }}
                >
                  {isLgUp ? t('buttons.how_to_create') : `${t('menu.help')}?`}
                </Typography>


                <IconButton
                  sx={{ width: { xs: '10%', md: 'auto' } }}
                  onClick={() => setOpen(true)}
                >
                  <YouTubeIcon />
                </IconButton> */}

                  <MuiButton
                    variant="contained"
                    color="secondary"
                    startIcon={<AutoStoriesIcon />}
                    onClick={() => setOpenReadDialog(true)}
                    // sx={{ width: { xs: '50%', md: 'auto' } }}
                    sx={{ flex: { xs: 1, md: 'auto' } }}
                  >
                    {isLgUp ? t('buttons.how_to_create') : `${t('menu.help')}?`}
                  </MuiButton>
                </Box>
                {/* <MuiButton
                  variant="contained"
                  color="secondary"
                  startIcon={<AutoStoriesIcon />}
                  onClick={() => setOpenReadDialog(true)}
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                  {t('buttons.how_to_create')}
                </MuiButton> */}
                {isHelpDeskInSb && isLoggedIn ? (
                  <>
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      startIcon={<CoPresent />}
                      // startIcon={<AutoStoriesIcon />}
                      onClick={() => setOpenCreateTicketDialog(true)}
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                      {t('buttons.develop_with_experts')}
                    </MuiButton>
                  </>
                ) : (
                  <> </>
                )}
                {isImportExportStrategy && (
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    startIcon={<SaveAltOutlined />}
                    // startIcon={<AutoStoriesIcon />}
                    onClick={() => setOpenImportDialog(true)}
                    sx={{ flex: { xs: 5, md: 'auto' }, mr: 1 }}
                  >
                    Import Strategy
                  </MuiButton>
                )}
              </Box>
            </Box>
          }
          note={isLgUp ? '' : t('user_bot_startegy.info_message')}
          buttonHandleClick={() => history.goBack()}
          breadcrumb={
            <Breadcrumbs
              page={PAGES.BOT_STRATEGY_BUILDER}
              data={{
                subId,
                botId: userBotDetails?.strategy_id,
                userBotId: botId,
                botName: userBotDetails?.name,
              }}
            />
          }
        />
        {/* <Box className="p-main-spacing">
          <Typography variant="body3">
            <FormattedMessage id="user_bot_startegy.info_message" />
          </Typography>
        </Box> */}
      </Box>
      <Box sx={{ mb: 2, mt: { md: 1 } }}>
        <StrategyBuilderStepper step={step} />
      </Box>
      {isTemplate && (
        <FeatureFlag
          features={[FEATURES.IS_NOTE_FOR_TEMPLATE_DETAIL]}
          fallback={<></>}
        >
          <Box className="m-main-spacing" mb={0.5}>
            <HintAlert />
          </Box>
        </FeatureFlag>
      )}
      <Box sx={{ height: '100%', mt: 2 }}>
        {step === 0 && (
          <StrategyBuilderSymbolTab
            setSymbolData={setSymbols}
            symbolData={
              formValues && formValues.symbols ? formValues.symbols : null
            }
            selectedExchange={selectedExchange}
            setSelectedExchange={SetSelectedExchange}
          />
        )}
        {step === 1 && (
          <StrategyBuilderEntryExitTab
            setEntryExit={setEntryExit}
            entryExitData={
              formValues && formValues.entryExitData
                ? formValues.entryExitData
                : {}
            }
            handleBack={() => setWizardStep(0)}
            // defaultResolution={formValues?.symbols?.interval}
          />
        )}
        {step === 2 && (
          <StrategyBuilderPortfolioTab
            setPortfolio={setPortfolio}
            portfolioData={
              formValues && formValues.portfolioData
                ? formValues.portfolioData
                : {}
            }
            handleBack={() => setWizardStep(1)}
          />
        )}
      </Box>
      <HelpGuideDialog
        handleClose={(val: boolean) => setOpenReadDialog(val)}
        openReadDialog={openReadDialog}
        url={
          siteInfo && siteInfo?.equity_helpguide_url
            ? siteInfo.equity_helpguide_url
            : 'https://help.speedbot.tech/Stock-Strategy-Builder-Toolkit-Guide/'
        }
        title="Reader Dialog"
        height="760px"
      />
      <CreateTickFormModal
        open={openCreateTicketDialog}
        handleClose={(val: boolean) => {
          setOpenCreateTicketDialog(val);
        }}
        isRequireToRedirect={true}
        subject="Custom Equity Strategy Development with Expert"
      />
      <ImportStrategyDialog
        open={openImpmortDialog}
        handleClose={(val: boolean) => setOpenImportDialog(val)}
      />
    </>
  );
}
