import { SymbolItemProps } from './types';
import { Box, Typography } from '@mui/material';
import {
  formatNumber,
  getArrowDirByNum,
  getColorByNum,
} from 'utils/GenericFunctions';

const SymbolItem = ({ symbol, data }: SymbolItemProps) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box display="flex" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" color="text.primary">
            {symbol.symbol}
          </Typography>
        </Box>
        {data && (
          <Box sx={{ flex: 'initial', whiteSpace: 'nowrap' }}>
            <Typography
              variant="body2"
              color={getColorByNum(data.symbolCloseDiff)}
            >{`${getArrowDirByNum(data.symbolCloseDiff)} ₹${formatNumber(
              data.symbolClose,
            )}`}</Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body3" color="text.disabled">
            {symbol.company_name}
          </Typography>
        </Box>
        {data && (
          <Box sx={{ flex: 'initial', whiteSpace: 'nowrap' }}>
            <Typography variant="body3" color="text.primary">
              {data.symbolCloseDiff > 0
                ? '+'
                : data.symbolCloseDiff < 0
                ? '-'
                : ''}
              {`${formatNumber(data.symbolCloseDiff)} (${formatNumber(
                (data.symbolCloseDiff / data.symbolClose) * 100,
              )}%)`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SymbolItem;
