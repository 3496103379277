import { Box, Typography } from '@mui/material';

type StatusMessageProps = {
  title?: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  fontStyle?: string;
};

const StatusMessage = ({ title, subtitle, fontStyle }: StatusMessageProps) => {
  return (
    <Box sx={{ py: 3, textAlign: 'center' }} className="p-main-spacing">
      {title && (
        <Typography variant="body1" sx={{ pb: title && subtitle ? 0.5 : 0 }}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="body1"
          sx={{ fontWeight: 400, fontStyle: fontStyle ? fontStyle : 'normal' }}
          color="text.secondary"
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default StatusMessage;
