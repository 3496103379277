import { Box, IconButton, Typography } from '@mui/material';
import { PageTitleWithIconVariantProps } from '../types';
import { Link as RouterLink } from 'react-router-dom';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const PageTitleWithIconVariant = ({
  title,
  iconProps,
  subButton,
  filterChips,
  titleElipsis,
  secondButton,
}: PageTitleWithIconVariantProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <Box className="p-main-spacing" sx={{ pt: 1, pb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={{ flex: '1' }}
          {...(titleElipsis && { className: 'text-ellipsis' })}
        >
          {title}
        </Typography>
        {subButton && (
          <Box
            display={'flex'}
            flexDirection={isMdUp ? 'row' : 'column'}
            gap={1}
          >
            {secondButton}
            <MuiButton
              variant="contained"
              size="small"
              color={subButton.color ? subButton.color : 'primary'}
              {...(subButton.link && {
                component: props => <RouterLink {...props} />,
                to: subButton.link,
              })}
              {...(subButton.handleClick && {
                onClick: subButton.handleClick,
              })}
              {...(subButton.icon && { startIcon: subButton.icon })}
              sx={{ mr: 1 }}
            >
              {subButton.text}
            </MuiButton>
          </Box>
        )}
        <IconButton
          color="primary"
          size="medium"
          {...(iconProps.handleClick && { onClick: iconProps.handleClick })}
          {...(iconProps.link && { component: RouterLink, to: iconProps.link })}
          sx={{ p: 0.75 }}
        >
          {iconProps.icon}
        </IconButton>
      </Box>
      {filterChips && (
        <Box
          sx={{
            pt: 1,
            overflow: 'auto',
            display: 'flex',
            '&::-webkit-scrollbar': { display: 'none' },
            gap: 1,
          }}
        >
          {filterChips}
        </Box>
      )}
    </Box>
  );
};

export default PageTitleWithIconVariant;
