export type OptionType = {
  label: string;
  value: string;
};

type FilterListType = {
  Options: Array<OptionType>;
  key: string;
};

export const getMenuOption = (
  startValue: number,
  endValue: number,
  conditionType: string,
  menuType?: string,
): Array<OptionType> => {
  let value = startValue;
  const options: Array<OptionType> = [];
  while (value <= endValue) {
    if (conditionType === 'gt')
      options.push({
        label: `${value} ${menuType && menuType === 'ev' ? '' : '%'} or higher`,
        value: `>= ${value}`,
      });
    if (conditionType === 'lt')
      options.push({ label: `${value}% or lower`, value: `<= ${value}` });
    if (menuType && menuType === 'ev') {
      if (value >= 1000) value += 1000;
      else if (value >= 200) value += 200;
      else value += 100;
    } else if (menuType && menuType === 'rr') {
      if (value >= 1) value += 2;
      else value = parseFloat((value + 0.3).toFixed(1));
    } else {
      if (value >= 200) value += 50;
      else if (value >= 100) value += 25;
      else value += 5;
    }
  }
  return options;
};

export const FilterList: FilterListType[] = [
  {
    Options: getMenuOption(0, 10, 'lt', 'rr'),
    key: 'risk_to_reward',
  },
  {
    Options: getMenuOption(15, 90, 'gt'),
    key: 'pop',
  },
  {
    Options: getMenuOption(15, 90, 'gt'),
    key: 'pmp',
  },
  {
    Options: getMenuOption(10, 75, 'lt'),
    key: 'pml',
  },
  {
    Options: getMenuOption(5, 100, 'gt'),
    key: 'alpha',
  },
  {
    Options: getMenuOption(50, 2500, 'gt', 'ev'),
    key: 'ev',
  },
];
