// Service
import { createBotSignalTableRows } from './utils';
import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';
import { BotSignal } from 'types/ApiServicesTypes';
import BotSignalRowDetail from './BotSignalRowDetail';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
// import { getQueryParam } from 'utils/GenericFunctions';

const DetailsRowRenderer = ({
  rowData,
  handleClose,
}: {
  rowData: BotSignal;
  handleClose: () => void;
}) => {
  return <BotSignalRowDetail signal={rowData} handleClose={handleClose} />;
};

const BotSignalsTable = ({
  data,
  page,
  handleClickRegenerate,
  handleClickModify,
  handleClickExit,
  isCrypto,
  isLoading,
  handleClickSelectSignal,
  selectedItems,
  handleClickSelectAll,
  selectableItems,
}: {
  data: BotSignal[];
  page: number;
  handleChartRowClick?: (positionData: BotSignal) => void;
  handleClickRegenerate: (signal: BotSignal) => void;
  handleClickModify: (signal: BotSignal) => void;
  handleClickExit: (signal: BotSignal) => void;
  isCrypto: boolean;
  isLoading?: boolean;
  handleClickSelectSignal?: (signal: BotSignal) => void;
  selectedItems?: number[] | null;
  handleClickSelectAll?: () => void;
  selectableItems?: number[] | null;
}) => {
  const isModifySignal = useIsFeatureFlag([FEATURES.IS_MODIFY_SIGNAL]);
  const isSelectedSignalExit = useIsFeatureFlag([
    FEATURES.IS_SELECTED_SIGNAL_EXIT,
  ]);
  // const isDebugMode = getQueryParam('debug', 'false') === 'true';

  const { columns, rows } = createBotSignalTableRows(
    data,
    handleClickRegenerate,
    handleClickModify,
    handleClickExit,
    isModifySignal,
    isSelectedSignalExit,
    isCrypto,
    isLoading,
    handleClickSelectSignal,
    selectedItems,
    handleClickSelectAll,
    selectableItems,
  );

  return (
    <>
      <Box sx={{ px: 3, mx: 1 }}>
        <CommonTable
          rows={rows}
          columns={columns}
          page={page}
          DetailsRowRenderer={DetailsRowRenderer}
          // sx={{ '& td': { verticalAlign: 'top' } }}
        />
      </Box>
    </>
  );
};

export default BotSignalsTable;
