import { Alert, Box, Button, Tooltip, Typography } from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { MessageAlertErrors } from 'types/ApiServicesTypes';
import {
  formatDateString,
  formatDateStringToFromNow,
} from 'utils/GenericFunctions';

export function MessageAlerts({
  errors,
  handleClearClick,
}: {
  errors: MessageAlertErrors[];
  handleClearClick?: (id: number) => void;
}) {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      {errors.map((error, i) => (
        <Alert
          severity={
            error.type === 'warn'
              ? 'warning'
              : error.type === 'error'
              ? 'error'
              : error.type === 'info'
              ? 'info'
              : 'success'
          }
          key={i}
          sx={{
            ...(i > 0 && { mt: 2 }),
            '& .MuiAlert-action': {
              md: { alignItems: 'center' },
            },
          }}
          {...(handleClearClick && {
            ...(isMdUp
              ? {
                  action: (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => handleClearClick(error.id)}
                    >
                      Clear
                    </Button>
                  ),
                }
              : { onClose: () => handleClearClick(error.id) }),
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              '&': {
                md: {
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '8px',
                },
              },
              '& .MuiTypography-body2': {
                md: {
                  order: -1,
                },
              },
            }}
          >
            {error.message && (
              <>
                <Box>
                  <Tooltip
                    title={formatDateString(error.error_timestamp, 'LTS')}
                  >
                    <Typography variant="body3">
                      {formatDateStringToFromNow(error.error_timestamp)}
                    </Typography>
                  </Tooltip>
                </Box>
                <Typography variant="body2">{error.message}</Typography>
              </>
            )}
          </Box>
          <Typography variant="body2" fontWeight={400}>
            {error.info}
          </Typography>
        </Alert>
      ))}
    </>
  );
}
