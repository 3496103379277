import { Verified } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { utcToLocalUsingMoment } from './HistoryCard';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';
import UnShareButton from './UnShareButton';

import { useParams } from 'react-router-dom';
import { isUserLoggedIn } from 'utils/auth';
import { useTheme } from '@mui/material/styles';

type TopCardProps = {
  userId?: string;
  sx: CSSProperties;
  profit?: number;
  date?: string;
  companyName?: string;
  currency?: string;
  isTableData?: boolean;
};

function TopCard({
  sx,
  profit,
  isTableData,
  date,
  companyName,
  currency,
  userId,
}: TopCardProps) {
  const isMdDown = useGetMediaQueryDown('md');
  const { t } = useTranslation();
  const UTCDate = date && utcToLocalUsingMoment(date);
  const isDown = useGetMediaQueryDown('max');
  const isloggedin = isUserLoggedIn();
  const { uuid } = useParams<{ uuid: string }>();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          marginTop: 2,
          padding: 1,
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          // backgroundColor: '#f7f7f7',
          borderRadius: 8,
          // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
          width: isMdDown ? '100%' : 500,
          ...sx,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.custom.speedbotLogoColor,
            mb: 1,
          }}
        >
          <Verified fontSize="small" sx={{ mr: 0.5 }} />
          {t('portfolio.public.verified_by', { company: companyName })}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {t('portfolio.public.taken_at', {
            date: UTCDate && UTCDate.format('DD MMM YYYY HH:mm:ss'),
          })}
        </Typography>
        {profit ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.text.disabled }}
            >
              <FormattedMessage id="portfolio.public.total_pnl" />
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: getColorByNum(profit),
                fontWeight: '500',
              }}
            >
              {currency}
              {formatNumber(profit)}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {!isDown && isloggedin && uuid == userId && isTableData && (
        <UnShareButton />
      )}
    </Box>
  );
}

export default TopCard;
