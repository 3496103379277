import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import PageHeader from 'app/design/speedBot/PageHeader';
import { decodeUrlData, getQueryParam } from 'utils/GenericFunctions';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import BotSignalTodaysPositions from 'app/components/Bots/BotSignal/BotSignalTodaysPositions';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Box } from '@mui/material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

import { useCallback, useContext } from 'react';
import { ToastContext } from 'app/components/Context/ToastContext';
import StatusMessage from 'app/components/Common/StatusMessage';

// Service
import useGetBotTodaysPositions from 'services/Bots/useGetBotTodaysPositions';
import usePostBotExitStrategyPositions from 'services/Bots/usePostBotExitStrategyPositions';
import useGetBotDetails from 'services/Bots/useGetBotDetails';

export function BotSignalsTodaysPositioPage() {
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();

  // Hooks
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();
  const { t } = useTranslation();
  const { showAlert } = useContext(ToastContext);

  const isDebugMode = getQueryParam('debug', 'false') === 'true';

  const handleChange = (newValue: number) => {
    if (newValue === 0) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/${title}`,
        search: isDebugMode ? '?debug=true' : '',
      });
    }
    if (newValue === 2) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/todays-orders/${title}`,
        search: isDebugMode ? '?debug=true' : '',
      });
    }
  };

  const botId = parseInt(bid);
  const subId = parseInt(sid);

  const { data: botDetails } = useGetBotDetails(botId, subId);
  const { data, isLoading } = useGetBotTodaysPositions(botId);
  const mutatePostExitStrategyPositions =
    usePostBotExitStrategyPositions(botId);

  const handleExitPositions = useCallback((positionIds: number[]) => {
    mutatePostExitStrategyPositions.mutate(
      { positionIds },
      {
        onSuccess: res => {
          if (res.success) {
            showAlert!(
              res.data && res.data.toaster_message
                ? res.data.toaster_message
                : t('bot_signals.signal_exited'),
              'success',
            );
            history.replace({
              pathname: `/bots/${bid}/signals/${sid}/${title}`,
              search: isDebugMode ? '?debug=true' : '',
            });
          }
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Bot Signals Todays Positions</title>
        <meta name="description" content="Bot Signals Todays Positions" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${decodeUrlData(title)}`}
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={1}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.signals') },
              { label: t('buttons.todays_position') },
              { label: t('buttons.todays_order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_SIGNALS_TODAYS_POSITIONS}
            data={{ sid, botId, botName: title }}
          />
        }
      />
      {data && botDetails && (
        <>
          <BotSignalTodaysPositions
            exchanges={botDetails.exchanges}
            botId={botId}
            data={data}
            isLoading={isLoading}
            handleChangeTab={handleChange}
            handleExitPositions={handleExitPositions}
            isLoadingExit={mutatePostExitStrategyPositions.isLoading}
          />
        </>
      )}
      {!isLoading && !data && (
        <StatusMessage
          title={t('todays_position.no_pos.title')}
          subtitle={t('todays_position.no_pos.sub_title')}
        />
      )}

      <Box sx={{ textAlign: 'right', mt: 2, mb: 1 }} className="p-main-spacing">
        <MuiButton
          endIcon={<ChevronRightIcon />}
          onClick={() => {
            breadcrumb.push(
              `/bots/${bid}/signals/${sid}/positions/${title}`,
              PAGES.BOT_SIGNALS_TODAYS_POSITIONS,
              `/bots/${bid}/signals/${sid}/todays-positions/Today's Orders`,
              title,
            );
          }}
        >
          <FormattedMessage id="buttons.position_history" />
        </MuiButton>
      </Box>

      {/* <Box
        className="p-main-spacing"
        display="flex"
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Alert severity="info">
          <Typography variant="body2" color="text.secondary" fontWeight={400}>
            <FormattedMessage id="bot_signals.note" />
          </Typography>
        </Alert>
      </Box> */}
    </>
  );
}
