import { makeApiRequest } from '../HelperTradingView/odoo-rpc.helper';

export default {
  getAllCharts: function () {
    return new Promise((resolve, reject) => {
      makeApiRequest('charts')
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          reject();
        });
    });
  },

  removeChart: function (chartId: string) {
    return new Promise<void>((resolve, reject) => {
      makeApiRequest('removechart', { chartId: parseInt(chartId) })
        .then(() => {
          resolve();
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          reject();
        });
    });
  },

  saveChart: function (chartData: { id: { toString: () => string } }) {
    let url = 'savechart';
    if (chartData.id) {
      url = url + '/' + chartData.id.toString();
    }
    return new Promise((resolve, reject) => {
      makeApiRequest(url, { chartData: chartData })
        .then(id => {
          resolve(id);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          reject();
        });
    });
  },

  getChartContent: function (chartId: string) {
    return new Promise((resolve, reject) => {
      makeApiRequest('chart', { chartId: chartId })
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          reject();
        });
    });
  },
};
