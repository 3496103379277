import { Box, Typography, Alert } from '@mui/material';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import React from 'react';
import { formatNumber } from 'utils/GenericFunctions';
import { getPaperTestTerms } from './util';
type BotPapercontinueModalTypes = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  requiredCredits?: number;
  walletBalance?: number;
  actionInfo?: string;
  step: number;
};
const BotPapercontinueModal = ({
  actionInfo,
  requiredCredits,
  setStep,
  walletBalance,
  step,
}: BotPapercontinueModalTypes) => {
  const paperTestTerms = getPaperTestTerms();
  const handleContiueClick = (value: number) => {
    setStep(value);
  };
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ pb: 1 }}>
        <FormattedMessage id="bot_paper_modal.what_is" />
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontWeight: 400 }}
        color="text.secondary"
      >
        <ul
          style={{
            marginTop: 0,
            marginBottom: 24,
            paddingLeft: 24,
          }}
        >
          {paperTestTerms &&
            paperTestTerms.map((term, i) => <li key={i}>{term}</li>)}
        </ul>
      </Typography>
      <ItemSeparator variant="2px" />
      {walletBalance && (
        <Box display="flex" justifyContent="space-between" sx={{ py: 2 }}>
          <Typography variant="body2">
            <FormattedMessage id="menu.credit_balance" />
          </Typography>

          <Typography
            variant="body2"
            fontWeight={500}
            color={walletBalance < 0 ? 'error.main' : 'text.primary'}
          >
            {walletBalance < 0 ? '-' : ''}
            {formatNumber(walletBalance)}
          </Typography>
        </Box>
      )}
      {requiredCredits && (
        <Alert severity="info">
          <Typography variant="body2">
            <Typography variant="body2" fontWeight={600} component={'span'}>
              {requiredCredits}
            </Typography>{' '}
            credits
            <Typography variant="body2" component={'span'} mx={0.5}>
              {actionInfo}
            </Typography>
            will be deducted
          </Typography>
        </Alert>
      )}
      {!step && (
        <MuiLoadingButton
          fullWidth
          variant="contained"
          color="primary"
          sx={{ my: 2 }}
          onClick={() => handleContiueClick(1)}
          // loading={mutation.isLoading}
          loadingPosition="start"
        >
          {/* <FormattedMessage id="" /> */}
          Continue
        </MuiLoadingButton>
      )}
    </Box>
  );
};

export default BotPapercontinueModal;
