import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

const MuiChip = styled(Chip)(({ theme }) => ({
  letterSpacing: theme.spacing(0.02),
  lineHeight: theme.spacing(2.25),
  fontWeight: 400,
  '&.MuiChip-sizeMedium': {
    padding: theme.spacing(0.5),
    '& .MuiChip-label': {
      padding: `${theme.spacing(0.375)} ${theme.spacing(0.75)}`,
    },
  },
  '&.MuiChip-sizeSmall .MuiChip-label': {
    padding: `${theme.spacing(0.375)} ${theme.spacing(1.25)}`,
  },
  '&.MuiChip-sizeSingleLetter': {
    height: `${theme.spacing(3)}!important`,
    width: `${theme.spacing(3)}!important`,
    fontWeight: 500,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  '&.MuiChip-sizeXsmall': {
    height: `${theme.spacing(2.5)}!important`,
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.95),
    letterSpacing: theme.spacing(0.01875),
    '& .MuiChip-label': {
      padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    },
    '& svg': {
      marginLeft: `${theme.spacing(0.5)}`,
      fontSize: '1rem',
    },
  },
  '&.MuiChip-sizeXsmallicon': {
    height: `${theme.spacing(2.5)}!important`,
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.95),
    letterSpacing: theme.spacing(0.01875),
    borderRadius: theme.spacing(0.5),
    '& .MuiChip-label': {
      padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    },
    '& svg': {
      marginLeft: `${theme.spacing(0.5)}`,
      fontSize: '0.75rem',
    },
  },
  '&.MuiChip-filledPurplelight': {
    color: theme.palette.purple?.[500],
  },
  '&.MuiChip-filledInfolight': {
    color: theme.palette.info.main,
  },
  '&.MuiChip-filledGreylight': {
    color: theme.palette.grey['700'],
  },
  '&.MuiChip-filledGreyselected': {
    color: theme.palette.common.white,
  },
  '&.MuiChip-filledErrorlight': {
    color: theme.palette.error.main,
  },
  '&.MuiChip-filledSuccesslight': {
    color: theme.palette.success.main,
  },
  '&.MuiChip-filledWarninglight': {
    color: theme.palette.warning.main,
  },
}));

export default MuiChip;
