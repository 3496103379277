import {
  Box,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetInvoices from 'services/Menu/useGetInvoices';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import useGetProfile from 'services/Menu/useGetProfile';
import { Invoice } from 'types/ApiServicesTypes';
import useSubscribePlanStatus from 'services/Menu/useSubscribePlanStatus';
import { formatDateString } from 'utils/GenericFunctions';
import useDownloadInvoice from 'services/Menu/useDownloadInvoice';
import { SubscribePlanStatusParams } from 'types/SubscriptionPlans';

function Invoices() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data: siteInfo } = useGetSiteInfo();
  const { data: profileDetails } = useGetProfile();
  const { data: invoices, isLoading } = useGetInvoices(page, 5);
  const {
    isLoading: isDownloading,
    variables: currentDownloading,
    mutate: downloadInvoice,
  } = useDownloadInvoice();

  const isMdDown = useGetMediaQueryDown('md');
  const mutationSubscribePlanStatus = useSubscribePlanStatus(() => undefined);

  const totalPages =
    invoices && invoices.length > 0 ? Number(invoices[0]['total_pages']) : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleDownloadInvoiceClick = (id: number, name: string) => {
    downloadInvoice({ id, name });
  };

  const handleInvoicePayment = (invoice: Invoice) => {
    const options = {
      key: siteInfo ? siteInfo.razorpay_key : '',
      handler: response => {
        const objPaymentStatusReq: SubscribePlanStatusParams = {
          razorpay_payment_id: response.razorpay_payment_id as string,
          razorpay_subscription_id: response.razorpay_subscription_id as string,
          razorpay_signature: response.razorpay_signature as string,
          razorpay_order_id: response.razorpay_order_id as string,
        };
        if (profileDetails?.user_plan?.plan.payment_type === 'one_time') {
          objPaymentStatusReq['invoice_type'] = invoice.id;
        }
        mutationSubscribePlanStatus.mutate(objPaymentStatusReq);
      },
      prefill: {
        name: profileDetails?.name,
        email: profileDetails?.email,
        contact: profileDetails?.mobile,
      },
      notes: {
        note_key_1: invoice.name,
        note_key_2: `Invoice Date: ${invoice.invoice_date}`,
      },
      order_id: invoice.order_id,
    };

    // eslint-disable-next-line
    const paymentObject = new (window as any).Razorpay(options);
    // eslint-disable-next-line
    paymentObject.open();
  };

  const currencySymbol = siteInfo?.currency_symbol || '';

  return (
    <Box className="m-main-spacing" position="relative">
      <CircularLoader open={isLoading} position="absolute" />
      {invoices && invoices.length > 0 && (
        <>
          <Divider />
          <Typography variant="h6" fontWeight={500} sx={{ mt: 2 }}>
            <FormattedMessage id="menu.invoice.title" />
          </Typography>
          <TableContainer>
            <Table
              sx={{
                '& th': { whiteSpace: 'nowrap' },
                '& td': {
                  fontWeight: 400,
                  verticalAlign: { xs: 'top', md: 'middle' },
                },
                '& th,td': {
                  '&:first-child': {
                    pl: 0,
                  },
                  '&:last-child': {
                    pr: 0,
                  },
                  '&:not(:first-child):not(:last-child)': {
                    textAlign: 'center',
                  },
                },
              }}
            >
              <TableHead>
                {!isMdDown && (
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {invoices.map(invoice => (
                  <Fragment key={invoice.name}>
                    {!isMdDown && (
                      <TableRow>
                        <TableCell>{invoice.name}</TableCell>
                        <TableCell>
                          {formatDateString(invoice.start_date, 'DD/MM/YYYY')}
                          <ArrowForwardIcon
                            sx={{ fontSize: '14px', mx: 0.5 }}
                          />
                          {formatDateString(invoice.end_date, 'DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {currencySymbol}
                          {invoice.amount}
                        </TableCell>
                        <TableCell>
                          <MuiChip
                            label={t(`menu.invoice.${invoice.status}`)}
                            variant="filled"
                            size="small"
                            color={
                              invoice.status === 'paid'
                                ? 'successlight'
                                : 'errorlight'
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          {invoice.status === 'paid' ? (
                            <MuiButton
                              variant="contained"
                              onClick={() =>
                                handleDownloadInvoiceClick(
                                  invoice.id,
                                  invoice.name,
                                )
                              }
                              disabled={
                                isDownloading &&
                                currentDownloading?.id == invoice.id
                              }
                            >
                              <FormattedMessage id="menu.invoice.download" />
                            </MuiButton>
                          ) : (
                            <MuiButton
                              variant="contained"
                              onClick={() => handleInvoicePayment(invoice)}
                            >
                              <FormattedMessage id="menu.invoice.pay" />
                            </MuiButton>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {isMdDown && (
                      <TableRow>
                        <TableCell>
                          {invoice.name}
                          <br />
                          {formatDateString(invoice.start_date, 'DD/MM/YYYY')}
                          <ArrowForwardIcon
                            sx={{ fontSize: '14px', ml: 0.5 }}
                          />
                          {formatDateString(invoice.end_date, 'DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="right">
                          {currencySymbol}
                          {invoice.amount}
                          <br />
                          <MuiChip
                            label={t(`menu.invoice.${invoice.status}`)}
                            variant="filled"
                            size="xsmall"
                            color={
                              invoice.status === 'paid'
                                ? 'successlight'
                                : 'errorlight'
                            }
                          />
                        </TableCell>
                        {invoice.status !== 'paid' && (
                          <TableCell>
                            <MuiButton
                              sx={{ mt: 1 }}
                              variant="contained"
                              onClick={() => handleInvoicePayment(invoice)}
                              size="small"
                              fullWidth
                            >
                              <FormattedMessage id="menu.invoice.pay" />
                            </MuiButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePagination}
              color="primary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: '1.5rem',
                pb: 3,
              }}
            />
          </TableContainer>
        </>
      )}
    </Box>
  );
}

export default Invoices;
