import { Box, Typography } from '@mui/material';
import { PageTitleWithDescriptionVariantProps } from '../types';

const PageTitleWithDescriptionVariant = ({
  title,
  description,
}: PageTitleWithDescriptionVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ pt: 1, pb: 2 }}>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body2" fontWeight={400} sx={{ pt: 2 }}>
        {description}
      </Typography>
    </Box>
  );
};

export default PageTitleWithDescriptionVariant;
