import { Box, Typography } from '@mui/material';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { PageTitleWithChipWithValueVariantProps } from '../types';

const PageTitleWithChipWithValueVariant = ({
  title,
  value,
  chipProps,
}: PageTitleWithChipWithValueVariantProps) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{
        pt: 1,
        pb: 2,
        display: { md: 'flex' },
      }}
    >
      <Typography variant="h5" sx={{ flex: '1' }}>
        {title}
      </Typography>
      <Box sx={{ pt: { xs: 2, md: 0 }, display: 'flex', alignItems: 'center' }}>
        <MuiChip
          label={chipProps.label}
          color={chipProps.color}
          variant={chipProps.variant}
          size="small"
        />
        <Typography
          variant="body2"
          fontWeight={400}
          sx={{ textAlign: 'right', flex: 1, ml: { md: 2 } }}
          color="text.secondary"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default PageTitleWithChipWithValueVariant;
