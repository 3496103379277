import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PlanSubscribeFlow from 'app/components/Menu/PlanSubscribeFlow';
import PlanChangeModal from 'app/components/Menu/PlanChangeModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

export function PlanChangePage() {
  const isMdUp = useGetMediaQueryUp('md');
  const [open, setOpen] = useState<boolean>(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Plan Change Page</title>
        <meta name="description" content="Plan Change Page" />
      </Helmet>

      {isMdUp ? (
        <PlanChangeModal open={open} handleClose={handleClose} />
      ) : (
        <PlanSubscribeFlow type="change" />
      )}
    </>
  );
}
