import { createTheme } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material';
import {
  red,
  grey,
  green,
  blue,
  deepPurple,
  blueGrey,
} from '@mui/material/colors';
import React from 'react';

declare module '@mui/material/styles' {
  interface CustomPalette {
    greylight: PaletteColorOptions;
  }
  interface BreakpointOverrides {
    max: true;
  }
  // interface Palette extends CustomPalette {}
  // interface PaletteOptions extends CustomPalette {}
  interface Theme {
    component: {
      circularProgressBar: {
        pathColor: string;
        trailColor: string;
      };
      productTour: {
        bgColor?: string;
      };
      versionControlCard: {
        borderColor?: string;
        backgroundColor?: string;
      };
      optionChainColors: {
        atmBg: string;
        itmBg: string;
        yellowColor: string;
      };
      optionChainGraphColors: {
        cartesianGrid: {
          stroke: string;
          fill: string;
        };
        yaxis: string;
        areaPositive: {
          stroke: string;
          fill: string;
        };
        areaNegative: {
          stroke: string;
          fill: string;
        };
        line: string;
      };
      tradeIdeas: {
        mainSelectedCard: string;
        greenColor: string;
        CardBackgroundColorValue: {
          bullish: string;
          bearish: string;
          sideways: string;
          directional: string;
        };
        ButtonBackgroundColorValue: {
          bullish: string;
          bearish: string;
          sideways: string;
          directional: string;
        };
        BorderColorValue: {
          bullish: string;
          bearish: string;
          sideways: string;
          directional: string;
        };
      };
      mainLayoutContainer: {
        main: string;
      };
      equityBuilder: {
        blueText: string;
        indicatortext: string;
        brown: string;
      };
    };
  }

  interface ThemeOptions {
    component?: {
      circularProgressBar?: {
        pathColor?: string;
        trailColor?: string;
      };
      productTour: {
        bgColor?: string;
      };
      versionControlCard: {
        borderColor?: string;
        backgroundColor?: string;
      };
      optionChainColors: {
        atmBg: string;
        itmBg: string;
        yellowColor: string;
      };
      optionChainGraphColors: {
        cartesianGrid: {
          stroke: string;
          fill: string;
        };
        yaxis: string;
        areaPositive: {
          stroke: string;
          fill: string;
        };
        areaNegative: {
          stroke: string;
          fill: string;
        };
        line: string;
      };
      equityBuilder: {
        blueText: string;
        indicatortext: string;
        brown: string;
      };
      tradeIdeas: {
        mainSelectedCard: string;
        greenColor: string;
        CardBackgroundColorValue: {
          bullish: string;
          bearish: string;
          sideways: string;
          directional: string;
        };
        ButtonBackgroundColorValue: {
          bullish: string;
          bearish: string;
          sideways: string;
          directional: string;
        };
        BorderColorValue: {
          bullish: string;
          bearish: string;
          sideways: string;
          directional: string;
        };
      };
      mainLayoutContainer: {
        main: string;
      };
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
    xxlarge: true;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    purplelight: true;
    greyselected: true;
    greylight: true;
    infolight: true;
    successlight: true;
    errorlight: true;
    warninglight: true;
  }
  interface ChipPropsSizeOverrides {
    singleLetter: true;
    xsmall: true;
    xsmallicon: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface Palette {
    custom: {
      hoverOnDark: string;
      hoverOnLight: string;
      myCustomColor?: string;
      border?: string;
      speedbotLogoColor?: string;
      hoverText?: string;
      boxShadow?: string;
      highlightingItem?: string;
      highlightingBorder?: string;
    };
    purple?: {
      50?: string;
      200?: string;
      300?: string;
      100?: string;
      500?: string;
      600?: string;
      700?: string;
      800?: string;
      900?: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      hoverOnDark?: string;
      hoverOnLight?: string;
      myCustomColor?: string;
      border?: string;
      hoverText?: string;
      boxShadow?: string;
    };
    purple?: {
      50?: string;
      200?: string;
      300?: string;
      100?: string;
      500?: string;
      600?: string;
      700?: string;
      800?: string;
      900?: string;
    };
  }

  interface PaletteOptions {
    component?: {
      circularProgressBar?: {
        pathColor?: string;
        trailColor?: string;
      };
    };
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
  interface ThemeOptions {
    colorSchemes?: {
      dark: boolean;
    };
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    h3: false;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = mainColor =>
  augmentColor({ color: { main: mainColor as string } });

const paletteData = {
  purplelight: createColor(deepPurple[50]),
  greyselected: createColor(grey[800]),
  greylight: createColor(grey[200]),
  infolight: createColor(blue[50]),
  successlight: createColor(green[50]),
  errorlight: createColor(red[50]),
  warninglight: createColor('#FDF0E6'),
  hoverOndark: '#444',
  hoverOnLight: '#F0F0F0',
  custom: {
    hoverOndark: '#444',
    hoverOnLight: '#F0F0F0',
    border: 'rgba(0, 0, 0, 0.12)',
    speedbotLogoColor: '#3659ea',
    hoverText: '#fff',
    boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.1), 
    0px 1px 1px 0px rgba(0, 0, 0, 0.08), 
    0px 1px 3px 0px rgba(0, 0, 0, 0.06)`,
    highlightingItem: '#e0f7fa',
    highlightingBorder: '#00acc1',
  },
  component: {
    circularProgressBar: {
      pathColor: '#4E9F3D',
      trailColor: '#EEEEEE',
    },
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    main: 'rgba(0, 0, 0, 0.87)',
    dark: '#000',
    light: 'rgba(0, 0, 0, 0.6)',
    contrastText: '#fff',
  },
  secondary: {
    light: grey['50'],
    main: grey['200'],
    dark: grey['500'],
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  error: {
    // light: red[50],
    main: red[500],
    dark: red[700],
  },
  warning: {
    main: '#ED6C02',
    dark: '#C77700',
  },
  success: {
    light: green[50],
    main: green[500],
    dark: green[700],
  },
  info: {
    main: blue[500],
    dark: blue[700],
    light: blue[50],
  },
  grey: {
    50: grey[50],
    100: grey[100],
    200: grey[200],
    300: grey[300],
    400: grey[400],
    500: grey[500],
    600: grey[600],
    700: grey[700],
    800: grey[800],
    900: grey[900],
  },
  purple: {
    50: deepPurple[50],
    200: deepPurple[200],
    300: deepPurple[300],
    100: deepPurple[100],
    500: deepPurple[500],
    600: deepPurple[600],
    700: deepPurple[700],
    800: deepPurple[800],
    900: deepPurple[900],
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    paper: '#fff',
    default: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
};
const darkPalette = {
  purplelight: createColor(deepPurple[100]), // Lighter shades for visibility in dark mode
  greyselected: createColor(grey[100]), // Lighter grey for selected items
  greylight: createColor(grey[700]), // Darker grey for backgrounds
  infolight: createColor(blue[100]), // Light enough to stand out on dark
  successlight: createColor(green[50]),
  errorlight: createColor(red[100]),
  warninglight: createColor('#F7C19C'), // Lighter warning color
  hoverOndark: '#F0F0F0', // Inverse for hover states in dark mode
  hoverOnLight: '#333',
  custom: {
    hoverOndark: '#F0F0F0',
    hoverOnLight: '#333',
    border: 'rgba(255, 255, 255, 0.24)', // Light border for dark backgrounds
    hoverText: '#000',
    boxShadow: `0px 2px 1px -1px rgba(255, 255, 255, 0.1), 
    0px 1px 1px 0px rgba(255, 255, 255, 0.08), 
    0px 1px 3px 0px rgba(255, 255, 255, 0.06)`,
    highlightingItem: '#37474f',
    highlightingBorder: '#00acc1',
  },
  component: {
    circularProgressBar: {
      pathColor: '#4E9F3D',
      trailColor: '#444', // Darker trail on dark background
    },
  },
  common: {
    white: '#082032',
    black: '#fff',
  },
  primary: {
    main: 'rgba(255, 255, 255, 0.87)', // Light text for primary content
    dark: '#fff',
    light: 'rgba(255, 255, 255, 0.6)', // Lighter variant for secondary content
    contrastText: '#000',
  },
  secondary: {
    light: grey['800'],

    main: grey['700'],
    dark: grey['900'],
    contrastText: 'rgba(255, 255, 255, 0.87)',
  },
  error: {
    main: red[500], // Lighter error color to pop against dark
    dark: red[600],
  },
  warning: {
    main: '#ED6C02', // Can remain the same
    dark: '#C77700',
  },
  success: {
    main: green[500],
    dark: green[600],
  },
  info: {
    main: blue[500],
    dark: blue[400],
    light: '#04131e',
  },
  grey: {
    50: blueGrey[900],
    100: blueGrey[800],
    200: blueGrey[700],
    300: blueGrey[600],
    400: blueGrey[500],
    500: blueGrey[400],
    600: blueGrey[300],
    700: blueGrey[200],
    800: blueGrey[100],
    900: blueGrey[50],
  },
  purple: {
    50: deepPurple[50],
    100: deepPurple[100],
    200: deepPurple[200],
    300: deepPurple[300],
    500: deepPurple[500],
    600: deepPurple[600],
    700: deepPurple[700],
    800: deepPurple[800],
    900: deepPurple[900],
  },
  text: {
    primary: 'rgba(255, 255, 255, 0.87)', // Light text for readability
    secondary: 'rgba(255, 255, 255, 0.6)', // Slightly muted light text
    disabled: 'rgba(255, 255, 255, 0.38)', // Disabled text
  },
  background: {
    paper: '#121212', // Dark background for dark mode
    default: '#121212',
  },
  action: {
    active: 'rgba(255, 255, 255, 0.54)',
    disabled: 'rgba(255, 255, 255, 0.26)',
    disabledBackground: 'rgba(255, 255, 255, 0.24)',
  },
};

// Create a theme instance.
export const theme = createTheme({
  // palette: themePalette(themeOption),
  palette: paletteData,
  component: {
    circularProgressBar: {
      pathColor: '#4E9F3D',
      trailColor: '#EEEEEE',
    },
    productTour: {
      bgColor: '#007DFF',
    },
    versionControlCard: {
      borderColor: '#007bff',
      backgroundColor: '#f5faff',
    },
    optionChainColors: {
      atmBg: '#0782da4d',
      itmBg: '#fffbe5',
      yellowColor: '#ffb200',
    },
    optionChainGraphColors: {
      areaNegative: { fill: '#F9E8EF', stroke: '#E45464' },
      areaPositive: { fill: '#e9f6f5', stroke: '#62B49B' },
      cartesianGrid: { fill: '#FFFFFF', stroke: '#F0F3FA' },
      line: '#2962FE',
      yaxis: '#000',
    },
    tradeIdeas: {
      mainSelectedCard: '#1a73e8',
      greenColor: '#018749',
      CardBackgroundColorValue: {
        bullish: '#22ab9411',
        bearish: '#c3090911',
        sideways: 'linear-gradient(90deg, #01874911 1.24%, #e3da0811 28.8%)',
        directional:
          'linear-gradient(90deg, #01874911 14.24%, #e3da0811 70.8%)',
      },
      ButtonBackgroundColorValue: {
        bullish: '#22ab9411',
        bearish: '#c3090911',
        sideways: '#e3da0811',
        directional: '#e3da0811',
      },
      BorderColorValue: {
        bullish: '#67e1cc',
        bearish: '#f63434',
        sideways: '#e9df08',
        directional: '#e9df08',
      },
    },
    mainLayoutContainer: {
      main: '#fafafa',
    },
    equityBuilder: {
      blueText: '#0d3c61',
      indicatortext: '#15609b',
      brown: '#f44336',
    },
  },
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      // md: 900,
      // lg: 1200,
      // xl: 1536,
      xs: 0,
      sm: 352,
      md: 640,
      lg: 880,
      xl: 1040,
      max: 1600,
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontWeight: '300',
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: '300',
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: '56px',
    },
    h4: {
      fontWeight: '400',
      fontSize: '34px',
      lineHeight: '42px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
    },
    h6: {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    body3: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '15.6px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '28px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '32px',
      letterSpacing: '1px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.4px',
      color: paletteData.grey?.[600],
    },
  },
  components: {
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       letterSpacing: '0.16px',
    //       textTransform: 'capitalize',
    //       '& .MuiChip-label': {
    //         letterSpacing: '0.16px',
    //         padding: '6px 10px',
    //       },
    //     },
    //   },
    // },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': { height: '3px', zIndex: 1 },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-padding': { paddingTop: '4px', paddingBottom: '4px' },
          '& .MuiListItemText-root': { margin: 0 },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: paletteData.grey?.[200],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardWarning': {
            backgroundColor: '#FDF0E6',
            '& .MuiAlert-icon': {
              color: paletteData.warning.main,
            },
            '& .MuiAlert-message': {
              color: '#5F2B01',
            },
          },
        },
      },
    },
  },
  colorSchemes: {
    dark: true,
  },
});
export const darkTheme = createTheme({
  // palette: themePalette(themeOption),
  palette: darkPalette,
  component: {
    circularProgressBar: {
      pathColor: '#4E9F3D',
      trailColor: '#444',
    },
    equityBuilder: {
      blueText: '#4ca6ff',
      indicatortext: '#4ea8de',
      brown: '#f44336',
    },
    productTour: {
      bgColor: '#FFFFFF',
    },
    versionControlCard: {
      borderColor: '#007bff',
      backgroundColor: '#f5faff',
    },
    optionChainColors: {
      atmBg: '#314256',
      itmBg: '#3a3426',
      yellowColor: '#ffb200',
    },
    optionChainGraphColors: {
      areaNegative: { fill: '#3B1F26', stroke: '#E45464' },
      areaPositive: { fill: '#16352F', stroke: '#62B49B' },
      cartesianGrid: { fill: '#04131e', stroke: 'white' },
      line: '#82B1FF',
      yaxis: '#FFFFFF',
    },

    tradeIdeas: {
      mainSelectedCard: '#1a73e8',
      greenColor: '#018749',
      CardBackgroundColorValue: {
        bullish: '#22ab9433',
        bearish: '#c3090933',
        sideways: 'linear-gradient(90deg, #01874933 1.24%, #e3da0833 28.8%)',
        directional:
          'linear-gradient(90deg, #01874933 14.24%, #e3da0833 70.8%)',
      },
      ButtonBackgroundColorValue: {
        bullish: '#22ab9433',
        bearish: '#c3090933',
        sideways: '#e3da0833',
        directional: '#e3da0833',
      },
      BorderColorValue: {
        bullish: '#67e1cc',
        bearish: '#f63434',
        sideways: '#e9df08',
        directional: '#e9df08',
      },
    },
    mainLayoutContainer: {
      main: '#04131e',
    },
  },
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      // md: 900,
      // lg: 1200,
      // xl: 1536,
      xs: 0,
      sm: 352,
      md: 640,
      lg: 880,
      xl: 1040,
      max: 1600,
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontWeight: '300',
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: '300',
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: '56px',
    },
    h4: {
      fontWeight: '400',
      fontSize: '34px',
      lineHeight: '42px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
    },
    h6: {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    body3: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '15.6px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '28px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '32px',
      letterSpacing: '1px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.4px',
      color: paletteData.grey?.[600],
    },
  },
  components: {
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       letterSpacing: '0.16px',
    //       textTransform: 'capitalize',
    //       '& .MuiChip-label': {
    //         letterSpacing: '0.16px',
    //         padding: '6px 10px',
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            height: '3px',
            zIndex: 2,
            backgroundColor: '#275576',
          },
          '&::after': {
            content: '""', // Required for ::after to render
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '2px', // Height of the divider
            backgroundColor: '#2755769e', // Divider color
            zIndex: 1, // Ensure the indicator appears above this
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'gray', // Default outline color
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'lightgray', // Outline color on hover
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-padding': { paddingTop: '4px', paddingBottom: '4px' },
          '& .MuiListItemText-root': { margin: 0 },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: paletteData.grey?.[200],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(256,256,256,0.87)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardWarning': {
            backgroundColor: '#FDF0E6',
            '& .MuiAlert-icon': {
              color: paletteData.warning.main,
            },
            '& .MuiAlert-message': {
              color: '#5F2B01',
            },
          },
        },
      },
    },
  },
  colorSchemes: {
    dark: true,
  },
});
