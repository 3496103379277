import { useQuery } from 'react-query';
import { GetProfile } from 'services/apiServices';
import { Errors, GetProfileResult } from 'types/ApiServicesTypes';
import { isUserLoggedIn } from 'utils/auth';

export default function useGetProfile() {
  const isLoggedIn = isUserLoggedIn();
  const objQuery = useQuery<GetProfileResult, Errors>(
    ['GetProfile', isLoggedIn],
    async () => {
      if (isLoggedIn) {
        return await GetProfile();
      } else {
        return {
          name: '',
        } as GetProfileResult;
      }
    },
  );

  return objQuery;
}
