import { useQuery } from 'react-query';
import { GetReferralData } from 'services/apiServices';
import { Errors, GetReferralDetails } from 'types/ApiServicesTypes';

function useGetReferral() {
  const objQuery = useQuery<GetReferralDetails, Errors>(
    'GetReferralDetailsData',
    () => GetReferralData(),
  );
  return objQuery;
}
export default useGetReferral;
