import { Card, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import { isUserLoggedIn } from 'utils/auth';
import { openInNewTab } from 'utils/GenericFunctions';
import SignInModal from '../Auth/SignInModal';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
const LearFromTutorialCard = () => {
  const [isOpenLoginModal, setOpenLoginModal] = useState(false);
  const isLoggedIn = isUserLoggedIn();
  const isMdUp = useGetMediaQueryUp('md');
  const { data: siteInfo } = useGetSiteInfo();
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 2,
        borderRadius: '1em',
        flexDirection: ['column', 'column', 'row'],
        backgroundColor: theme.component.mainLayoutContainer.main,
        borderColor: theme.palette.custom.border,
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant={isMdUp ? 'h6' : 'body1'}>
            Explore Algo Trading Courses
          </Typography>
          <Typography variant={isMdUp ? 'h6' : 'body1'}>
            And Automate your Trading with SpeedBot
          </Typography>
        </Box>
        <Box>
          <Typography variant={isMdUp ? 'subtitle1' : 'body3'}>
            RoboTrading - No More Limited to Big Financial Players. So What are
            you waiting for?
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 'auto', display: 'flex', flexDirection: 'row' }}>
        <MuiButton
          onClick={() =>
            openInNewTab(
              siteInfo && siteInfo.speedbot_course
                ? siteInfo.speedbot_course
                : 'https://speedbot.tech/slides/all',
            )
          }
          sx={{ mr: 1, borderRadius: '3em', width: '163px' }}
          variant="contained"
          size="small"
        >
          <Typography variant="body3"> LEARN FROM TUTORIALS</Typography>
        </MuiButton>
        {!isLoggedIn ? (
          <MuiButton
            onClick={() => setOpenLoginModal(true)}
            sx={{ borderRadius: '3em', width: '163px' }}
            variant="contained"
          >
            <Typography variant="body3"> SIGNUP AND TRY NOW!</Typography>
          </MuiButton>
        ) : (
          <MuiButton
            size="small"
            onClick={() =>
              openInNewTab(
                siteInfo && siteInfo.speedbot_youtube
                  ? siteInfo.speedbot_youtube
                  : 'https://www.youtube.com/@speedbotalgotrading',
              )
            }
            sx={{ mr: 1, borderRadius: '3em', width: '163px' }}
            variant="contained"
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Typography variant="body3">EXPLORE</Typography>
              <Typography variant="body3">
                VIDEOS <YouTubeIcon />
              </Typography>
            </Box>
          </MuiButton>
        )}
      </Box>
      {!isLoggedIn && isOpenLoginModal && (
        <SignInModal
          open={isOpenLoginModal}
          handleClose={() => setOpenLoginModal(false)}
          type={'Sign Up'}
        />
      )}
    </Card>
  );
};

export default LearFromTutorialCard;
