import { Box, Card } from '@mui/material';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useGetEvents from 'services/common/useGetEvents';

import CarouselCard from './CarouselCard';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const HomeCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };
  const platform = useGetMediaQueryUp('md');
  const { data: carouselData } = useGetEvents('home', platform);
  return (
    <Slider {...settings}>
      {carouselData &&
        carouselData.map((slide, index) => (
          <Box
            sx={{
              mb: 1,
            }}
            key={index}
          >
            <Card
              sx={{
                width: '100%',
                borderRadius: '1em',
                // height: [150, 150, 'auto', 'auto'],
                display: 'flex',
                aspectRatio: ['2.5/1', '2.5/1', '3/0.7', '3/0.7'],
              }}
            >
              {slide.eventURL && !slide.eventURL.startsWith('http') ? (
                <CarouselCard
                  routerLink
                  title={slide.title}
                  description={slide.description}
                  label={slide.label}
                  image={slide.imageURL}
                  url={slide.eventURL}
                  color={slide.color}
                />
              ) : slide.eventURL && slide.eventURL.startsWith('http') ? (
                <CarouselCard
                  title={slide.title}
                  description={slide.description}
                  label={slide.label}
                  image={slide.imageURL}
                  url={slide.eventURL}
                  color={slide.color}
                />
              ) : (
                // </Box>
                <CarouselCard
                  title={slide.title}
                  description={slide.description}
                  label={slide.label}
                  image={slide.imageURL}
                  url={slide.eventURL}
                  color={slide.color}
                />
              )}
            </Card>
          </Box>
        ))}
    </Slider>
  );
};

export default HomeCarousel;
