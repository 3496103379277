import { useQuery } from 'react-query';
import { Errors, GetBotTemplatesResult } from 'types/ApiServicesTypes';
import { GetBotTemplates } from './api';

export default function useGetBotTemplates(type: { type?: string }) {
  const objQuery = useQuery<GetBotTemplatesResult['data'], Errors>(
    ['GetBotTemplates'],
    () => GetBotTemplates(type),
  );
  return objQuery;
}
