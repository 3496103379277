import { useQuery } from 'react-query';
import { GetPortfolioBotDetail } from 'services/apiServices';
import {
  Errors,
  Position,
  GetPortfolioBotDetailQueryResult,
  GetPortfolioBotDetailResult,
} from 'types/ApiServicesTypes';

const PORTFOLIO_DETAILS_GRAPH_DATAPOINTS_SIZE = 50;

// return unique symbols
export function getUniqueSymbolsForActive(arrPos: Position[]) {
  const symbols = arrPos
    .map(pos => {
      const isDisabled = Boolean(pos.exit_price && pos.exit_price > 0);
      if (isDisabled) return '';
      return pos.trading_symbol;
    })
    .filter((v, i, self) => {
      return v !== '' && i == self.indexOf(v);
    });

  return symbols;
}

// process response data
export function processResponseData(res: GetPortfolioBotDetailResult) {
  let arrOpenPositions: Array<Position> = [],
    arrClosePositions: Array<Position> = [];
  let symbols: Array<string> = [];
  if (res) {
    arrOpenPositions = res.today_positions.filter(x =>
      Boolean(x.exit_price === 0),
    );
    arrClosePositions = res.today_positions.filter(x =>
      Boolean(x.exit_price > 0),
    );
    symbols = getUniqueSymbolsForActive(res.today_positions);
  }
  return {
    data: res,
    openPositions: arrOpenPositions,
    closePositions: arrClosePositions,
    symbols,
  };
}

export default function useGetPortfolioBotDetail(idText: string) {
  const id = parseInt(idText);
  const queryKey = ['GetPortfolioBotDetail', id];
  const objQuery = useQuery<GetPortfolioBotDetailQueryResult, Errors>(
    queryKey,
    async () => {
      return await GetPortfolioBotDetail(
        `portfolio/${id}?data_points=${PORTFOLIO_DETAILS_GRAPH_DATAPOINTS_SIZE}`,
        'GET',
      ).then(res => {
        return processResponseData(res);
      });
    },
  );
  if (objQuery.data && objQuery.data.data && objQuery.data.data.scripts) {
    objQuery.data.data.scripts = objQuery.data.data.scripts.map(script => ({
      ...script,
      symbol: script.symbol.replace(/_SPACE/g, ''),
    }));
  }
  return objQuery;
}
