import { Box, Typography } from '@mui/material';
import BotDeployModal from 'app/components/Bots/BotDetail/BotDeployModal';
import BotStopModal from 'app/components/Bots/BotDetail/BotStopModal';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllBots } from 'types/ApiServicesTypes';
import { formatNumber, toTitleCase } from 'utils/GenericFunctions';
import DayAgo from './BotCreatedDayAgo';
import BotPaperTestModal from 'app/components/Bots/BotDetail/BotPaperTestModal';
import InstrumentString from 'app/design/itemsComponent/InstrumentString';
import { ToastContext } from 'app/components/Context/ToastContext';
import { FEATURES } from 'types/Feature';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';

const BotsCard = ({ bots }: { bots: GetAllBots }) => {
  const breadcrumb = useBreadcrumbManager();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<string>('');
  const [skipTerms, setSkipTerms] = useState<boolean>(false);
  const [openStopModal, setOpenStopModal] = useState<boolean>(false);
  const [openPaperModal, setOpenPaperModal] = useState<boolean>(false);
  const [openDeployModal, setOpenDeployModal] = useState<boolean>(false);
  const isMdDown = useGetMediaQueryDown('md');
  const handleDeployModalClose = (isSuccess: boolean, message?: string) => {
    if (message) setMessage(message);
    setOpenDeployModal(false);
    setSkipTerms(false);
  };
  const handleStopModalClose = useCallback(
    (isSuccess: boolean, message?: string) => {
      if (message) setMessage(message);
      setOpenStopModal(false);
    },
    [setOpenStopModal, setMessage],
  );
  const handleStopModalOpen = useCallback(() => {
    setMessage('');
    setOpenStopModal(true);
  }, [setOpenStopModal, setMessage]);
  const handlePaperModalOpen = () => {
    setMessage('');
    setOpenPaperModal(true);
  };
  const handlePaperModalClose = (isSuccess: boolean, message?: string) => {
    if (message) setMessage(message);
    setOpenPaperModal(false);
  };
  const { t } = useTranslation();
  const handleDeployModalOpen = () => {
    setMessage('');
    setOpenDeployModal(true);
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const { showAlert } = useContext(ToastContext);
  const isPaperTrading = useIsFeatureFlag([FEATURES.IS_PAPER_TRADING]);
  const isShowBacktest = useIsFeatureFlag([FEATURES.IS_SHOW_BACKTEST]);
  return (
    <Box sx={{ my: 1.5, cursor: 'pointer' }} className="m-main-spacing">
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          breadcrumb.push(
            `bots/${bots.botid}/details${
              bots.sub_id ? `/${bots.sub_id}` : ''
            }/Bots`,
            PAGES.BOTS,
            `/bots`,
            t('bots.page_title.bots'),
          );
        }}
      >
        <Box
          display={'flex'}
          width={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            display={'flex'}
            sx={{ flexDirection: ['row', 'row', 'row'] }}
            alignSelf={'baseline'}
            alignItems={['', '', 'center']}
          >
            <DayAgo
              botsCreateDate={{
                create_date: bots.create_date,
                write_date: bots.write_date,
              }}
            />
            {!isMdDown && (
              <Box>
                <MuiChip
                  size="xsmall"
                  sx={{ mr: 1 }}
                  label={bots.category ? toTitleCase(bots.category) : 'Draft'}
                  color={
                    bots.category === 'live'
                      ? 'successlight'
                      : bots.category === 'paper'
                      ? 'purplelight'
                      : bots.category === 'inactive'
                      ? 'warninglight'
                      : 'infolight'
                  }
                />
              </Box>
            )}
          </Box>
          {isMdDown && (
            <Box>
              <MuiChip
                size="xsmall"
                sx={{ mr: 1 }}
                label={bots.category ? toTitleCase(bots.category) : 'Draft'}
                color={
                  bots.category === 'live'
                    ? 'successlight'
                    : bots.category === 'paper'
                    ? 'purplelight'
                    : bots.category === 'inactive'
                    ? 'warninglight'
                    : 'infolight'
                }
              />
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ flexDirection: ['column', 'column', 'row', 'row', 'row'] }}
        >
          <Box
            flex={0.5}
            display={'flex'}
            mb={isMdDown ? 1 : 0}
            justifyContent={'space-between'}
            flexDirection={'column-reverse'}
          >
            {isMdDown && (
              <>
                {bots.min_capital !== 0 && (
                  <Typography variant="caption" fontWeight={400}>
                    Required Capital
                    <Typography
                      variant="caption"
                      color={'black'}
                      component={'span'}
                      fontWeight={600}
                      ml={0.5}
                    >
                      {bots.currency_symbol ? bots.currency_symbol : '$'}
                      {formatNumber(bots.min_capital, true, 0)}
                    </Typography>
                  </Typography>
                )}

                <Typography
                  color="text.secondary"
                  display="block"
                  variant="body3"
                  sx={{ mt: 0.2 }}
                >
                  {bots.symbols.length > 0 ? (
                    <InstrumentString instrument={bots.symbols} />
                  ) : undefined}
                </Typography>
              </>
            )}
            <Typography variant="body1">{bots.name}</Typography>

            {<></>}
          </Box>
          {!isMdDown && (
            <Box flex={0.5}>
              {bots.min_capital !== 0 && (
                <Typography variant="caption">
                  capital: {bots.currency_symbol ? bots.currency_symbol : '$'}
                  {formatNumber(bots.min_capital, true, 0)}
                </Typography>
              )}
              <Typography
                color="text.secondary"
                display="block"
                variant="body3"
              >
                {bots.symbols.length > 0 ? (
                  <InstrumentString instrument={bots.symbols} />
                ) : (
                  '-'
                )}
              </Typography>
            </Box>
          )}
          <Box
            flex={1}
            justifyContent={!isMdDown ? 'right' : ''}
            display={'flex'}
            alignItems={!isMdDown ? 'flex-end' : ''}
            flexDirection={isMdDown ? 'row' : 'row'}
            sx={{
              gap: 1,
              visibility: !isMdDown
                ? isHovered
                  ? 'visible'
                  : 'hidden'
                : 'visible',
            }}
          >
            {bots && bots.category !== 'live' ? (
              <MuiButton
                sx={{
                  fontSize:
                    isMdDown && bots.category === 'paper'
                      ? '12px !important'
                      : '14px',
                }}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  handleDeployModalOpen();
                }}
                variant="outlined"
                size={isMdDown ? 'small' : 'medium'}
              >
                {' '}
                Go Live
              </MuiButton>
            ) : (
              <MuiButton
                sx={{
                  fontSize:
                    isMdDown && bots.category === 'paper'
                      ? '12px !important'
                      : '14px',
                }}
                variant="outlined"
                color="error"
                size={isMdDown ? 'small' : 'medium'}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  handleStopModalOpen();
                }}
              >
                Stop Live
              </MuiButton>
            )}
            {bots && (!bots.category || bots.category === 'inactive')
              ? isPaperTrading && (
                  <MuiButton
                    sx={{
                      fontSize:
                        isMdDown && bots.category === 'paper'
                          ? '12px !important'
                          : '14px',
                    }}
                    variant="outlined"
                    color="error"
                    size={isMdDown ? 'small' : 'medium'}
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation();
                      handlePaperModalOpen();
                    }}
                  >
                    Forward
                  </MuiButton>
                )
              : bots.category === 'paper' && (
                  <MuiButton
                    sx={{
                      fontSize:
                        isMdDown && bots.category === 'paper'
                          ? '12px !important'
                          : '14px',
                    }}
                    variant="outlined"
                    color="error"
                    size={isMdDown ? 'small' : 'medium'}
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation();
                      handleStopModalOpen();
                    }}
                  >
                    Stop Forward
                  </MuiButton>
                )}
            {isShowBacktest && (
              <MuiButton
                sx={{
                  fontSize:
                    isMdDown && bots.category === 'paper'
                      ? '12px !important'
                      : '14px',
                }}
                size={isMdDown ? 'small' : 'medium'}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  bots.is_own_bot
                    ? breadcrumb.push(
                        `/bots/${bots.botid}/backtest/${bots.sub_id}/${bots.name}/`,
                        PAGES.BOTS,
                        `/bots?ptype=mybots`,
                        t('bots.page_title.bots'),
                      )
                    : breadcrumb.push(
                        `/bots/${bots.botid}/backtest/${bots.sub_id}/${bots.name}/`,
                        PAGES.BOTS,
                        `/bots?ptype=mybots`,
                        t('bots.page_title.bots'),
                      );
                }}
                variant={isMdDown ? 'outlined' : 'outlined'}
                color="primary"
              >
                {' '}
                Backtest
              </MuiButton>
            )}
            {
              <MuiButton
                sx={{
                  typography: ['body3'],
                  fontSize:
                    isMdDown && bots.category === 'paper'
                      ? '12px !important'
                      : '14px',
                }}
                size={isMdDown ? 'small' : 'medium'}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();

                  if (bots.is_own_bot && bots.user_botid !== 0) {
                    breadcrumb.push(
                      `/bot-builder/${bots?.user_botid}/${
                        bots.sub_id ? bots.sub_id : '0'
                      }/${bots.botid}`,
                      PAGES.BOTS,
                      `/bots?ptype=mybots`,
                      t('bots.page_title.bots'),
                    );
                  } else if (
                    bots.category === 'live' ||
                    bots.category === 'paper'
                  ) {
                    showAlert!(
                      'Oops! The bot is currently active in live or paper mode. Please pause or stop the bot before making any edits.',
                    );
                  } else if (bots.user_botid === 0 && bots.is_own_bot) {
                    breadcrumb.push(
                      `bots/${bots.botid}/details${
                        bots.sub_id ? `/${bots.sub_id}` : ''
                      }/Bots`,
                      PAGES.BOTS,
                      `/bots`,
                      t('bots.page_title.bots'),
                    );
                  } else {
                    showAlert!('You do not own this bot ');
                  }
                }}
                variant="outlined"
              >
                Edit
              </MuiButton>
            }
          </Box>
        </Box>
      </Box>
      <BotDeployModal
        open={openDeployModal}
        handleClose={handleDeployModalClose}
        // id={parseInt(id)}
        exchanges={bots?.exchanges}
        botName={bots ? bots.name : ''}
        botId={bots.botid}
        openFrom="bots"
        skipTerms={skipTerms}
      />
      <BotStopModal
        open={openStopModal}
        bid={bots.botid}
        sid={bots.sub_id}
        botName={bots?.name}
        handleClose={handleStopModalClose}
        openFrom="bots"
        category={bots ? bots.category : ''}
      />
      <BotPaperTestModal
        open={openPaperModal}
        id={bots.botid}
        sid={bots.sub_id}
        botName={bots?.name}
        handleClose={handlePaperModalClose}
        openFrom="bots"
      />
    </Box>
  );
};

export default BotsCard;
