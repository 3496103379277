import { Field, FieldType } from './types';
import { GenericFieldComponent } from './GenericFieldComponent';
import { MovingAverageField } from './MovingAverageField';
import { OperatorField } from './OperatorField';
import { ComparatorField } from './ComparatorField';
import { DropdownFieldRenderer } from './fields-params-renderers/DropdownFieldRenderer';
import { InputFieldRenderer } from './fields-params-renderers/InputFieldRenderer';
import { CloseField } from './CloseField';
import { NumberField } from './CloseField';
import { MathPeriod } from './MathFunctions/MathPeriod';
import { MathTwoOp } from './MathFunctions/MathTwoOp';
import { MathSingleOp } from './MathFunctions/MathSingleOp';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { IntervalsData } from 'app/components/Bots/StrategyBuilder/util';
import { cloneDeep } from 'lodash';
export type FieldWithComponent = Field & {
  component:
    | typeof GenericFieldComponent
    | typeof MovingAverageField
    | typeof OperatorField
    | typeof ComparatorField
    | typeof MathPeriod
    | typeof MathTwoOp
    | typeof MathSingleOp;
};

export type FieldWithComponentWithGroup = FieldWithComponent & {
  group: string;
};

export const Indicators: FieldWithComponent[] = [
  {
    key: 'number',
    name: 'Number',
    description: 'Number',
    type: FieldType.Indicators,
    syntax: 'Number({value})',
    params: [
      {
        name: 'Value',
        key: 'value',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Number</b>(%value%)',
    component: NumberField,
  },
  {
    description: 'Moving Average',
    name: 'Moving Average',
    shortName: 'MA',
    key: 'moving_average',
    syntax: 'Moving Average(  {field}, {period}, {type}, {type})',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
          { key: 'volume', value: 'Volume' },
        ],
        defaultValue: { key: 'close', value: 'Close' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Period',
        key: 'period',
        type: 'number',
        defaultValue: 20,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Type',
        key: 'type',
        type: 'dropdown',
        values: [
          { key: 'simple', value: 'simple' },
          { key: 'exponential', value: 'exponential' },
          { key: 'weighted', value: 'weighted' },
        ],
        defaultValue: { key: 'simple', value: 'simple' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>Moving average</b>(%field%,%period%,%type%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'aroon_down',
    name: 'Aroon Down',
    description: 'Aroon Down',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Period',
        key: 'period',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Aroon down</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'bollinger_bands',
    name: 'Bollinger Bands',
    description: 'Bollinger Bands',
    type: FieldType.Indicators,
    params: [
      {
        key: 'field',
        name: 'Field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
        ],
        defaultValue: { key: 'close', value: 'Close' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Period',
        key: 'period',
        type: 'number',
        defaultValue: 20,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'std dev',
        key: 'std_dev',
        type: 'number',
        defaultValue: 2,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'line',
        name: 'Line',
        type: 'dropdown',
        values: [
          { key: 'upper band', value: 'upper band' },
          { key: 'lower band', value: 'lower band' },
          { key: 'middle band', value: 'middle band' },
        ],
        defaultValue: { key: 'upper band', value: 'upper band' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>Bollinger Bands</b>(%field%,%period%,%std_dev%,%line%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'super_trend',
    name: 'Super Trend',
    description: 'Super Trend',
    syntax: 'SuperTrend({atr_period}, {factor}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'atr_period',
        name: 'Atr Period',
        type: 'number',
        defaultValue: 10,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Factor',
        key: 'factor',
        type: 'number',
        defaultValue: 3,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Supertrend</b>(%atr_period%,%factor%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'rsi',
    name: 'Relative Strength Index',
    shortName: 'RSI',
    description: 'Relative Strength Index',
    syntax: 'RSI({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>RSI</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'adx',
    name: 'Average Directional Index',
    shortName: 'ADI',
    description: 'Average Directional Index',
    syntax: 'ADX({smoothing}, {di_length}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'Smoothing',
        name: 'smoothing',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'di_length',
        name: 'DI Length',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>ADX</b>(%smoothing%,%di_length%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'ichimoku_cloud',
    name: 'ICHIMOKU Cloud',
    description: 'ICHIMOKU Cloud',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Conversion Line Period',
        key: 'conversion_line_period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Base Line Period',
        key: 'base_line_period',
        type: 'number',
        defaultValue: 26,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Leadingspan B Period',
        key: 'leadingspan_b_period',
        type: 'number',
        defaultValue: 52,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Displacement',
        key: 'displacement',
        type: 'number',
        defaultValue: 26,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'line',
        name: 'Line',
        type: 'dropdown',
        values: [
          { key: 'conversion line', value: 'conversion line' },
          { key: 'base line', value: 'base line' },
          { key: 'span a', value: 'span a' },
          { key: 'span b', value: 'span b' },
          { key: 'lagging span', value: 'lagging span' },
          { key: 'span a at present', value: 'span a at present' },
          { key: 'span b at present', value: 'span b at present' },
          { key: 'span a at lagging', value: 'span a at lagging' },
          { key: 'span b at lagging', value: 'span b at lagging' },
        ],
        defaultValue: { key: 'conversion line', value: 'conversion line' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>ICHIMOKU</b>(%conversion_line_period%,%base_line_period%,%leadingspan_b_period%,%displacement%,%line%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'atr',
    name: 'Average True Range',
    shortName: 'ATR',
    description: 'Average True Range',
    syntax: 'AverageTrueRange({period}, {type}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>ATR</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'dema',
    name: 'Double Exponential Moving Average',
    shortName: 'DEMA',
    description: 'Double Exponential Moving Average',
    syntax: 'DoubleExponentialMovingAverage({period}, {field}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'open' },
          { key: 'high', value: 'high' },
          { key: 'low', value: 'low' },
          { key: 'close', value: 'close' },
        ],
        defaultValue: { key: 'open', value: 'open' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>DEMA</b>(%field%,%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'ema',
    name: 'Exponential Moving Average',
    shortName: 'EMA',
    description: 'Exponential Moving Average',
    syntax: 'ExponentialMovingAverage({period}, {field}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'open' },
          { key: 'high', value: 'high' },
          { key: 'low', value: 'low' },
          { key: 'close', value: 'close' },
        ],
        defaultValue: { key: 'open', value: 'open' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>EMA</b>(%field%,%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'alma',
    name: 'Arnaud Legoux Moving Average',
    shortName: 'ALMA',
    description: 'Arnaud Legoux Moving Average',
    syntax: 'ArnaudLegouxMovingAverage({period}, {sigma}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Sigma',
        key: 'sigma',
        type: 'number',
        defaultValue: 6,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>ALMA</b>(%period%,%sigma%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'hma',
    name: 'Hull Moving Average',
    shortName: 'HMA',
    description: 'Hull Moving Average',
    syntax: 'HullMovingAverage({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>HMA</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'lsma',
    name: 'Least Squares Moving Average',
    shortName: 'LSMA',
    description: 'Least Squares Moving Average',
    syntax: 'LeastSquaresMovingAverage({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>LSMA</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'macd',
    shortName: 'MACD',
    name: 'Moving Average Convergence Divergence',
    description: 'Moving Average Convergence Divergence',
    syntax:
      'MovingAverageConvergenceDivergence({fastperiod}, {slowperiod}, {signalperiod}, {type}, {field}, {line}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'fastperiod',
        name: 'Fastperiod',
        type: 'number',
        defaultValue: 12,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'slowperiod',
        name: 'SlowPeriod',
        type: 'number',
        defaultValue: 26,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'signalperiod',
        name: 'SignalPeriod',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Type',
        key: 'type',
        type: 'dropdown',
        values: [
          { key: 'simple', value: 'simple' },
          { key: 'exponential', value: 'exponential' },
          { key: 'weighted', value: 'weighted' },
        ],
        defaultValue: { key: 'exponential', value: 'exponential' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
          { key: 'volume', value: 'Volume' },
        ],
        defaultValue: { key: 'open', value: 'Open' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Line',
        key: 'line',
        type: 'dropdown',
        values: [
          { key: 'histogram', value: 'Histogram' },
          { key: 'macd', value: 'MACD' },
          { key: 'signal', value: 'Signal' },
        ],
        defaultValue: { key: 'signal', value: 'Signal' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>MACD</b>(%fastperiod%,%slowperiod%,%signalperiod%,%type%,%field%,%line%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'tema',
    shortName: 'TEMA',
    name: 'Triple Exponential Moving Average',
    description: 'Triple Exponential Moving Average',
    syntax: 'TripleExponentialMovingAverage({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>TEMA</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'aroon',
    name: 'Aroon Oscillator',
    description: 'Aroon Oscillator',
    syntax: 'AroonOscillator({period}, {line}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Line',
        key: 'line',
        type: 'dropdown',
        values: [
          { key: 'upper', value: 'Upper' },
          { key: 'lower', value: 'Lower' },
        ],
        defaultValue: { key: 'upper', value: 'Upper' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Aroon oscillator</b>(%period%,%line%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'mom',
    shortName: 'MOM',
    name: 'Momentum',
    description: 'Momentum',
    syntax: 'Momentum({period}, {field})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
        ],
        defaultValue: { key: 'close', value: 'Close' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>MOM</b>(%period%,%field%)',
    component: MovingAverageField,
  },
  {
    key: 'roc',
    name: 'RateOfChange',
    shortName: 'ROC',
    description: 'RateOfChange',
    syntax: 'RateOfChange({period})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>ROC</b>(%period%)',
    component: MovingAverageField,
  },
  {
    key: 'balance_of_power',
    name: 'Balance Of Power',
    shortName: 'BOP',
    description: 'Balance Of Power',
    syntax: 'BalanceOfPower({offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>BOP</b>(%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'mfi',
    shortName: 'MFI',
    name: 'Money Flow Index',
    description: 'Money Flow Index',
    syntax: 'MoneyFlowIndex({period},{offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>MFI</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'ad',
    name: 'Accumulation Distribution',
    shortName: 'AD',
    description: 'Accumulation Distribution',
    syntax: 'AccumulationDistribution({offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>AD</b>(%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'coppock_curve',
    name: 'Coppock Curve',
    description: 'Coppock Curve',
    syntax:
      'CoppockCurve({wmaperiod}, {longrocperiod}, {shortrocperiod}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'wmaperiod',
        name: 'WMAPeriod',
        type: 'number',
        defaultValue: 10,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'longrocperiod',
        name: 'Longrocperiod',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'shortrocperiod',
        name: 'Shortrocperiod',
        type: 'number',
        defaultValue: 11,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>Coppock curve</b>(%wmaperiod%,%longrocperiod%,%shortrocperiod%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'cci',
    name: 'Commodity Channel Index',
    shortName: 'CCI',
    description: 'Commodity Channel Index',
    syntax: 'CommodityChannelIndex({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>CCI</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'chandemo',
    name: 'Chande Momentum Oscillator',
    shortName: 'CMO',
    description: 'Chande Momentum Oscillator',
    syntax: 'ChandeMomentumOscillator({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>CMO</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'dc',
    name: 'Donchian Channel',
    shortName: 'DC',
    description: 'Donchian Channel',
    syntax: 'DonchianChannel({period}, {line}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Line',
        key: 'line',
        type: 'dropdown',
        values: [
          { key: 'upper', value: 'Upper' },
          { key: 'lower', value: 'Lower' },
        ],
        defaultValue: { key: 'upper', value: 'Upper' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Donchian channel</b>(%line%,%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'dpo',
    name: 'Detrended Price Oscillator',
    shortName: 'DPO',
    description: 'Detrended Price Oscillator',
    syntax: 'DetrendedPriceOscillator({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>DPO</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'kc',
    name: 'Keltner Channels',
    shortName: 'KC',
    description: 'Keltner Channels',
    syntax:
      'KeltnerChannels({period}, {no_of_multiples}, {type}, {line}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 10,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'no_of_multiples',
        name: 'No Of Multiples',
        type: 'number',
        defaultValue: 1,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Line',
        key: 'line',
        type: 'dropdown',
        values: [
          { key: 'upper band', value: 'Upper Band' },
          { key: 'middle band', value: 'Middle Band' },
          { key: 'lower band', value: 'Lower Band' },
        ],
        defaultValue: { key: 'upper band', value: 'Upper Band' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>KC</b>(%line%,%period%,%no_of_multiples%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'obv',
    name: 'On Balance Volume',
    shortName: 'OBV',
    description: 'On Balance Volume',
    syntax: 'OnBalanceVolume({offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>OBV</b>(%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'ppo',
    name: 'Percentage Price Oscillator',
    shortName: 'PPO',
    description: 'Percentage Price Oscillator',
    syntax: 'PercentagePriceOscillator({shortperiod}, {longperiod}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'shortperiod',
        name: 'Short Period',
        type: 'number',
        defaultValue: 10,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'longperiod',
        name: 'Long Period',
        type: 'number',
        defaultValue: 21,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>PPO</b>(%shortperiod%,%longperiod%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'sar',
    name: 'Parabolic Stop And Reverse',
    shortName: 'PSR',
    description: 'Parabolic Stop And Reverse',
    syntax: 'ParabolicStopAndReverse({start}, {increment}, {max}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'start',
        name: 'Start',
        type: 'number',
        defaultValue: 0.02,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'increment',
        name: 'Increment',
        type: 'number',
        defaultValue: 0.02,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'max',
        name: 'Max',
        type: 'number',
        defaultValue: 0.2,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>Parabolic SAR</b>(%start%,%increment%,%max%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'stoch',
    name: 'Stochastic',
    description: 'Stochastic',
    syntax: 'Stochastic({period}, {kperiod}, {dperiod}, {line}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'kperiod',
        name: 'Kperiod',
        type: 'number',
        defaultValue: 1,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'dperiod',
        name: 'Dperiod',
        type: 'number',
        defaultValue: 3,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'line',
        name: 'Line',
        type: 'dropdown',
        values: [
          { key: '%k', value: '%k' },
          { key: '%d', value: '%d' },
        ],
        defaultValue: { key: '%k', value: '%k' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>Stochastic</b>(%period%,%kperiod%,%dperiod%,%line%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'trix',
    name: 'Trix',
    description: 'Trix',
    syntax: 'Trix({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Trix</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'uo',
    name: 'Ultimate Oscillator',
    shortName: 'UO',
    description: 'Ultimate Oscillator',
    syntax: 'UltimateOscillator({period1}, {period2}, {period3}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period1',
        name: 'Period1',
        type: 'number',
        defaultValue: 7,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'period2',
        name: 'Period2',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'period3',
        name: 'Period3',
        type: 'number',
        defaultValue: 28,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc:
      '<b>Ultimate oscillator</b>(%period1%,%period2%,%period3%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'vwap',
    name: 'Volume Weighted Average Price Indicator',
    shortName: 'VWAP',
    description: 'Volume Weighted Average Price Indicator',
    syntax: 'VolumeWeightedAveragePriceIndicator({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>VWAP</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: '%r',
    name: 'Williams Percent R',
    shortName: '%R',
    description: 'Williams Percent R',
    syntax: 'WilliamsPercentR({period}, {offset})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'period',
        name: 'Period',
        type: 'number',
        defaultValue: 9,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Will%r</b>(%period%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'pivot_point',
    name: 'Pivot Point',
    description: 'Pivot Point',
    syntax: 'pivot_point({type}, {line})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'type',
        name: 'Type',
        type: 'dropdown',
        values: [
          { key: 'Traditional', value: 'Traditional' },
          { key: 'Fibonacci', value: 'Fibonacci' },
          { key: 'Woodie', value: 'Woodie' },
          { key: 'Classic', value: 'Classic' },
          { key: 'Demark', value: 'Demark' },
          { key: 'Camarilla', value: 'Camarilla' },
        ],
        defaultValue: { key: 'Traditional', value: 'Traditional' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'line',
        name: 'Line',
        type: 'dropdown',
        values: [
          { key: 'r1', value: 'r1' },
          { key: 'pp', value: 'pp' },
          { key: 's1', value: 's1' },
          { key: 'r2', value: 'r2' },
          { key: 's2', value: 's2' },
          { key: 'r3', value: 'r3' },
          { key: 's3', value: 's3' },
          { key: 'r4', value: 'r4' },
          { key: 's4', value: 's4' },
          { key: 'r5', value: 'r5' },
          { key: 's5', value: 's5' },
        ],
        defaultValue: { key: 'r1', value: 'r1' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Pivot point</b>(%line%,%type%,%offset%)',
    component: MovingAverageField,
  },
  {
    key: 'candle_time',
    name: 'Candle Time',
    description: 'Candle Time',
    syntax: 'candle_time({type})',
    type: FieldType.Indicators,
    params: [
      {
        key: 'type',
        name: 'Type',
        type: 'dropdown',
        values: [
          { key: 'minuteofcandle', value: 'minute of candle' },
          { key: 'hourofcandle', value: 'hour of candle' },
          { key: 'dayofweek', value: 'day of week' },
        ],
        defaultValue: { key: 'minuteofcandle', value: 'minute of candle' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Candle time</b>(%type%)',
    component: MovingAverageField,
  },
  {
    key: 'close',
    name: 'Close',
    description: 'Close',
    type: FieldType.Indicators,
    syntax: 'Close({offset})',
    params: [
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Close</b>(%offset%)',
    component: CloseField,
  },
  {
    key: 'open',
    name: 'Open',
    description: 'Open',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Open</b>(%offset%)',
    component: CloseField,
  },
  {
    key: 'high',
    name: 'High',
    description: 'High',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>High</b>(%offset%)',
    component: CloseField,
  },
  {
    key: 'low',
    name: 'Low',
    description: 'Low',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Offset',
        key: 'offset',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Low</b>(%offset%)',
    component: CloseField,
  },
  {
    description: 'Opening Range Breakout',
    name: 'Opening Range Breakout',
    shortName: 'ORB',
    key: 'orb',
    syntax: '',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
          { key: 'volume', value: 'Volume' },
        ],
        defaultValue: { key: 'close', value: 'Close' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Range',
        key: 'range',
        type: 'dropdown',
        values: [
          { key: '1', value: '1min' },
          { key: '2', value: '2min' },
          { key: '3', value: '3min' },
          { key: '4', value: '4min' },
          { key: '5', value: '5min' },
          { key: '10', value: '10min' },
          { key: '15', value: '15min' },
          { key: '30', value: '30min' },
          { key: '60', value: '1hour' },
          { key: '120 ', value: '2hour' },
          { key: '180 ', value: '3hour' },
          { key: '1440', value: '1day' },
        ],
        defaultValue: { key: '5', value: '5min' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Opening Range Breakout</b>(%field%,%Range%)',
    component: MovingAverageField,
  },
  {
    description: 'Nth Candle',
    name: 'Nth Candle',
    shortName: 'NC',
    key: 'nth_candle',
    syntax: '',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
          { key: 'volume', value: 'Volume' },
        ],
        defaultValue: { key: 'close', value: 'Close' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Range',
        key: 'range',
        type: 'dropdown',
        values: [
          { key: '1', value: '1min' },
          { key: '3', value: '3min' },
          { key: '5', value: '5min' },
          { key: '10', value: '10min' },
          { key: '15', value: '15min' },
          { key: '30', value: '30min' },
          { key: '60 ', value: '1hour' },
        ],
        defaultValue: { key: '5', value: '5min' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'number',
        name: 'Number',
        type: 'number',
        defaultValue: 2,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Nth Candle</b>(%field%,%number%,%Range%,)',
    component: MovingAverageField,
  },
  {
    description: 'Previous N Candle',
    name: 'Previous N Candle',
    shortName: 'PNC',
    key: 'previous_n_candle',
    syntax: '',
    type: FieldType.Indicators,
    params: [
      {
        name: 'Field',
        key: 'field',
        type: 'dropdown',
        values: [
          { key: 'open', value: 'Open' },
          { key: 'high', value: 'High' },
          { key: 'low', value: 'Low' },
          { key: 'close', value: 'Close' },
          { key: 'volume', value: 'Volume' },
        ],
        defaultValue: { key: 'close', value: 'Close' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        name: 'Range',
        key: 'range',
        type: 'dropdown',
        values: [
          { key: '1', value: '1min' },
          { key: '3', value: '3min' },
          { key: '5', value: '5min' },
          { key: '10', value: '10min' },
          { key: '15', value: '15min' },
          { key: '30', value: '30min' },
          { key: '60 ', value: '1hour' },
          { key: '1440 ', value: '1day' },
        ],
        defaultValue: { key: '5', value: '5min' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
      {
        key: 'offset',
        name: 'Offset',
        type: 'number',
        defaultValue: 1,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Previous N Candle</b>(%field%,%Range%,%offset%)',
    component: MovingAverageField,
  },
];

export const Comparators: FieldWithComponent[] = [
  {
    name: 'Equal to',
    description: '=',
    key: 'equal_to',
    params: [],
    conditionTextDesc: 'is <b>equal</b> to',
    component: ComparatorField,
    type: FieldType.Comparators,
  },
  {
    name: 'Crosses above',
    description: '',
    key: 'crosses_above',
    params: [],
    conditionTextDesc: '<b>crosses</b> above',
    component: ComparatorField,
    type: FieldType.Comparators,
    icon: <NorthEastIcon />,
  },
  {
    name: 'Crosses below',
    description: '',
    key: 'crosses_below',
    params: [],
    conditionTextDesc: '<b>crosses</b> below',
    component: ComparatorField,
    type: FieldType.Comparators,
    icon: <SouthEastIcon />,
  },
  {
    name: 'Lower Than Equal To',
    description: '<=',
    key: 'lower_than_equal_to',
    params: [],
    conditionTextDesc: 'is <b>lower</b> than <b>equal</b> to',
    component: ComparatorField,
    type: FieldType.Comparators,
  },
  {
    name: 'Lower Than',
    description: '<',
    key: 'lower_than',
    params: [],
    conditionTextDesc: 'is <b>lower</b> than',
    component: ComparatorField,
    type: FieldType.Comparators,
  },
  {
    name: 'Higher Than Equal To',
    description: '>=',
    key: 'higher_than_equal_to',
    params: [],
    conditionTextDesc: 'is <b>higher</b> than <b>equal</b> to',
    component: ComparatorField,
    type: FieldType.Comparators,
  },
  {
    name: 'Higher Than',
    description: '>',
    key: 'higher_than',
    params: [],
    conditionTextDesc: 'is <b>higher</b> than',
    component: ComparatorField,
    type: FieldType.Comparators,
  },
  {
    description: '',
    name: 'Down By',
    key: 'down_by',
    syntax: 'Down By({value}, {type})',
    type: FieldType.Comparators,
    params: [
      {
        name: 'Value',
        key: 'value',
        type: 'number',
        defaultValue: 14,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Type',
        key: 'type',
        type: 'dropdown',
        values: [
          { key: 'percentage', value: '%' },
          { key: 'points', value: 'points' },
        ],
        defaultValue: { key: 'percentage', value: '%' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
    ],
    conditionTextDesc: 'is <b>down by</b> %value%%type% from',
    component: MovingAverageField,
  },
  {
    description: '',
    name: 'Up By',
    key: 'up_by',
    syntax: 'Up By({value}, {type})',
    type: FieldType.Comparators,
    params: [
      {
        name: 'Value',
        key: 'value',
        type: 'number',
        defaultValue: 50,
        enabled: true,
        renderer: InputFieldRenderer,
      },
      {
        name: 'Type',
        key: 'type',
        type: 'dropdown',
        values: [
          { key: 'percentage', value: '%' },
          { key: 'points', value: 'points' },
        ],
        defaultValue: { key: 'percentage', value: '%' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      },
    ],
    conditionTextDesc: 'is <b>up by</b> %value%%type% from',
    component: MovingAverageField,
  },
];

export const Operators: FieldWithComponent[] = [
  {
    name: '+',
    description: 'Plus',
    key: '+',
    params: [],
    conditionTextDesc: '<b>+</b>',
    component: OperatorField,
    type: FieldType.Operator,
  },
  {
    name: '-',
    description: 'Minus',
    key: '-',
    params: [],
    conditionTextDesc: '<b>-</b>',
    component: OperatorField,
    type: FieldType.Operator,
  },
  {
    name: '×',
    description: 'Multiply',
    key: '*',
    params: [],
    conditionTextDesc: '<b>*</b>',
    component: OperatorField,
    type: FieldType.Operator,
  },
  {
    name: '/',
    description: 'Divide',
    key: '/',
    params: [],
    conditionTextDesc: '/',
    component: OperatorField,
    type: FieldType.Operator,
  },
  {
    name: '(',
    description: '',
    key: '(',
    params: [],
    conditionTextDesc: '<b>(</b>',
    component: OperatorField,
    type: FieldType.Bracket,
  },
  {
    name: ')',
    description: '',
    key: ')',
    params: [],
    conditionTextDesc: '<b>)</b>',
    component: OperatorField,
    type: FieldType.Bracket,
  },
  {
    name: 'Or',
    description: '',
    key: 'or',
    params: [],
    conditionTextDesc: '<b>or</b>',
    component: OperatorField,
    type: FieldType.Operator,
  },
  {
    name: 'And',
    description: '',
    key: 'and',
    params: [],
    conditionTextDesc: '<b>and</b>',
    component: OperatorField,
    type: FieldType.Operator,
  },
];

export const MathFunctions: FieldWithComponent[] = [
  {
    name: 'Min',
    description: 'Min function',
    key: 'min',
    params: [],
    conditionTextDesc: '<b>Min</b>(%param1%,%param2%)',
    component: MathTwoOp,
    type: FieldType.MathFunctions,
  },
  {
    name: 'Max',
    description: 'Max function',
    key: 'max',
    params: [],
    conditionTextDesc: '<b>Max</b>(%param1%,%param2%)',
    component: MathTwoOp,
    type: FieldType.MathFunctions,
  },
  {
    name: 'Abs',
    description: 'Abs function',
    key: 'abs',
    params: [],
    conditionTextDesc: '<b>Abs</b>(%param1%)',
    component: MathSingleOp,
    type: FieldType.MathFunctions,
  },
  {
    name: 'Ceil',
    description: 'Ceil function',
    key: 'ceil',
    params: [],
    conditionTextDesc: '<b>Ceil</b>(%param1%)',
    component: MathSingleOp,
    type: FieldType.MathFunctions,
  },
  {
    name: 'Floor',
    description: 'Floor function',
    key: 'floor',
    params: [],
    conditionTextDesc: '<b>Floor</b>(%param1%)',
    component: MathSingleOp,
    type: FieldType.MathFunctions,
  },
  {
    name: 'Period Min',
    description: 'Period Min function',
    key: 'period_min',
    params: [
      {
        name: 'Period',
        key: 'period',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Period min</b>(%period%,%param1%)',
    component: MathPeriod,
    type: FieldType.MathFunctions,
  },
  {
    name: 'Period Max',
    description: 'Period Max function',
    key: 'period_max',
    params: [
      {
        name: 'Period',
        key: 'period',
        type: 'number',
        defaultValue: 0,
        enabled: true,
        renderer: InputFieldRenderer,
      },
    ],
    conditionTextDesc: '<b>Period max</b>(%period%,%param1%)',
    component: MathPeriod,
    type: FieldType.MathFunctions,
  },
];

export const IndicatorWithResolution = (): FieldWithComponent[] => {
  const IndicatorsClone = cloneDeep([...Indicators]);
  return IndicatorsClone.map(indicator => {
    if (!(indicator.key === 'number')) {
      indicator.params.push({
        name: 'Resolution',
        key: 'resolution',
        type: 'dropdown',
        values: IntervalsData.map(interval => {
          const intervals = {
            key: interval['value'],
            value: interval['label'],
          };
          return intervals;
        }),
        defaultValue: { key: '00:10:00', value: '10 Min' },
        enabled: true,
        renderer: DropdownFieldRenderer,
      });
      indicator.conditionTextDesc = indicator.conditionTextDesc.replace(
        ')',
        ',%resolution%)',
      );
    }
    return indicator;
  });
};

const AllFieldsWithResolution = [
  ...IndicatorWithResolution(),
  ...Comparators,
  ...Operators,
  ...MathFunctions,
];

const AllEquityFields = [
  ...Indicators,
  ...Comparators,
  ...Operators,
  ...MathFunctions,
];
const AllFields: FieldWithComponent[][] = [AllEquityFields];
export const callingBuilderType = (type: string) => {
  AllFields.length = 0;
  if (type === 'equity') {
    AllFields.push(AllEquityFields);
  } else {
    AllFields.push(AllFieldsWithResolution);
  }
};

const getGroup = (field: FieldWithComponent): string => {
  if (Indicators.includes(field)) {
    return 'Indicators';
  } else if (Comparators.includes(field)) {
    return 'Comparators';
  } else if (Operators.includes(field)) {
    return 'Operators';
  } else if (MathFunctions.includes(field)) {
    return 'MathFunctions';
  }
  return '';
};
export const FieldKeyToField = () =>
  AllFields[AllFields.length - 1].reduce((accumulator, field) => {
    accumulator[field.key] = { ...field, group: getGroup(field) };
    return accumulator;
  }, {} as { [key: string]: FieldWithComponentWithGroup });

export const IndicatorsKeys = (() => {
  let keys = '';
  Indicators.forEach(indicator => {
    keys += `"${indicator.key}" | `;
  });
  keys = keys.substring(0, keys.length - 3);
  return keys;
})();

export const MathFunctionsKeys = (() => {
  let keys = '';
  MathFunctions.forEach(mathFunction => {
    keys += `"${mathFunction.key}" | `;
  });
  keys = keys.substring(0, keys.length - 3);
  return keys;
})();

export const ComparatorsKeys = (() => {
  let keys = '';
  Comparators.forEach(comparator => {
    keys += `"${comparator.key}" | `;
  });
  keys = keys.substring(0, keys.length - 3);
  return keys;
})();

export const OperatorsKeys = (() => {
  let keys = '';
  Operators.forEach(operator => {
    if (
      operator.key !== '(' &&
      operator.key !== ')' &&
      operator.key !== 'and' &&
      operator.key !== 'or'
    )
      keys += `"${operator.key}" | `;
  });
  keys = keys.substring(0, keys.length - 3);
  return keys;
})();
