import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PageHeader from 'app/design/speedBot/PageHeader';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import MuiModal from 'app/design/uiComponents/MuiModal';
import PlanSubscribeFlow from 'app/components/Menu/PlanSubscribeFlow';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

type PlanSubscribeModalProps = {
  open: boolean;
  handleClose: () => void;
};

const PlanSubscribeModal = ({ open, handleClose }: PlanSubscribeModalProps) => {
  const isMdUp = useGetMediaQueryUp('md');

  const handleCloseModal = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    handleClose();
  };

  return (
    <MuiModal
      open={open}
      handleClose={handleCloseModal}
      isFullScreen={!isMdUp}
      {...(isMdUp && {
        header: (
          <Box sx={{ pt: 2 }}>
            <PageHeader
              variant="withIcon"
              title={<FormattedMessage id="menu.page_title.plan_upgrade" />}
              iconProps={{
                handleClick: e => handleCloseModal(e, ''),
                icon: <CloseIcon fontSize="small" />,
              }}
            />
            <Box className="p-main-spacing" sx={{ mb: 2 }}>
              <ItemSeparator variant="2px" />
            </Box>
          </Box>
        ),
      })}
      body={
        <Box sx={{ pb: 2 }}>
          <PlanSubscribeFlow
            type="upgrade"
            handleBackClick={handleClose}
            handleClose={handleClose}
          />
        </Box>
      }
    />
  );
};

export default PlanSubscribeModal;
