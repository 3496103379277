export enum APP_MESSAGE_TYPE {
  APP_STATE = 'appState',
}

export type AppStateMessageData = {
  state: 'active' | 'inactive' | 'background';
};

export type AppStateMessage = {
  type: APP_MESSAGE_TYPE.APP_STATE;
  data: AppStateMessageData;
};

export type AppMessage = AppStateMessage;
