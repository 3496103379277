import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { PageTitleBackVariantProps } from '../types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const PageTitleBackVariant = ({
  buttonText,
  buttonHandleClick,
  breadcrumbLinks,
  breadcrumb,
}: PageTitleBackVariantProps) => {
  const isLink = typeof buttonHandleClick === 'string';
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <Box className="p-main-spacing" sx={{ pt: 3, pb: 0 }}>
      {!isMdUp ? (
        isLink ? (
          <MuiButton
            size="small"
            to={buttonHandleClick}
            component={RouterLink}
            startIcon={<ArrowBackIcon />}
            sx={{ px: '0!important', justifyContent: 'flex-start' }}
          >
            {buttonText}
          </MuiButton>
        ) : (
          <MuiButton
            size="small"
            onClick={buttonHandleClick}
            startIcon={<ArrowBackIcon />}
            sx={{ px: '0!important' }}
          >
            {buttonText}
          </MuiButton>
        )
      ) : breadcrumb !== undefined ? (
        <Box>{breadcrumb}</Box>
      ) : (
        <MuiBreadcrumbs links={breadcrumbLinks!} />
      )}
    </Box>
  );
};

export default PageTitleBackVariant;
