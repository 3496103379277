import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import Statistics from 'app/components/Marketplace/Statistics';

// Service
import { GetBacktestBotDetail } from 'services/apiServices';
import { Errors, GetBacktestBotDetailResult } from 'types/ApiServicesTypes';
import { decodeUrlData } from 'utils/GenericFunctions';
import PageHeader from 'app/design/speedBot/PageHeader';
import useGetStatistics from 'services/Statistics/useGetStatistics';
import { Box, Alert, Typography } from '@mui/material';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { MessageAlerts } from 'app/components/Common/MessageAlerts';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

export function BotBackTestingPage() {
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();
  const { t } = useTranslation();

  const botId = parseInt(bid);
  const queryKey = ['GetBacktestBotDetail', botId];
  const { data: stiteInfo } = useGetSiteInfo();
  const { data: backtestBotDetailData, isLoading } = useQuery<
    GetBacktestBotDetailResult,
    Errors
  >(queryKey, () => GetBacktestBotDetail('bots', 'GET', botId));
  const { data: statisticsData, isLoading: isLoadingGetStatistics } =
    useGetStatistics(`bots/${bid}/backtest`);

  if (
    backtestBotDetailData &&
    statisticsData &&
    backtestBotDetailData.user_botid > 0 &&
    backtestBotDetailData.is_own_bot &&
    !statisticsData.result_ready
  ) {
    history.replace({
      pathname: `/bot/${backtestBotDetailData.user_botid}/backtesting/${
        sid ? sid : ''
      }`,
    });
  }
  return (
    <>
      <Helmet>
        <title>Statistics</title>
        <meta name="description" content="Bot Backtesting" />
      </Helmet>
      <CircularLoader open={isLoading || isLoadingGetStatistics} />
      <PageHeader
        variant="back"
        buttonText={title ? decodeUrlData(title) : ''}
        buttonHandleClick={() => history.goBack()}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_BACK_TESTING}
            data={{ subId: sid, botId: bid, botName: title }}
          />
        }
      />
      {backtestBotDetailData && statisticsData && statisticsData.result_ready && (
        <>
          {statisticsData.errors && statisticsData.errors.length > 0 && (
            <Box className="m-main-spacing" sx={{ mt: 2 }}>
              <MessageAlerts errors={statisticsData.errors} />
            </Box>
          )}
          {backtestBotDetailData.order_count === 0 && (
            <Box className="m-main-spacing" sx={{ mt: 2 }}>
              <Alert severity="warning">
                <Typography variant="body2">
                  Warning : {stiteInfo && stiteInfo.name} platform was unable to
                  place any trades with the defined bot strategy logic,
                  backtesting duration, and portfolio rules. Please check and
                  try again.
                </Typography>
              </Alert>
            </Box>
          )}
          <Statistics
            id={botId}
            subId={backtestBotDetailData?.bot_id.toString()}
            baseApiUrl={`bots/${bid}/backtest`}
            title={title}
            source={'BACKTEST'}
            positionsOnClick={() =>
              breadcrumb.push(
                `/bots/${bid}/backtest/${sid}/positions/${title}`,
                PAGES.BOT_BACK_TESTING,
                `/bots/${bid}/backtest/${sid}/${title}`,
                t('statistics'),
              )
            }
            ordersOnClick={() =>
              breadcrumb.push(
                `/bots/${bid}/backtest/${sid}/orders/${title}`,
                PAGES.BOT_BACK_TESTING,
                `/bots/${bid}/backtest/${sid}/${title}`,
                t('statistics'),
              )
            }
            symbolsOnClick={() =>
              breadcrumb.push(
                `/bots/${bid}/backtest/${sid}/symbols/${title}`,
                PAGES.BOT_BACK_TESTING,
                `/bots/${bid}/backtest/${sid}/${title}`,
                t('statistics'),
              )
            }
            profitLossOnClick={() =>
              breadcrumb.push(
                `/bots/${bid}/backtest/${sid}/profitloss/${title}`,
                PAGES.BOT_BACK_TESTING_PROFITLOSS,
                `/bots/${bid}/backtest/${sid}/${title}`,
                t('statistics'),
              )
            }
          />
        </>
      )}
    </>
  );
}
