export enum PAGES {
  BOTS = 'BOTS',
  BOT_DETAIL = 'BOT_DETAIL',
  BOT_DETAIL_SYMBOLS = 'BOT_DETAIL_SYMBOLS',
  BOT_HISTORY = 'BOT_HISTORY',
  BOT_HISTORY_DETAIL = 'BOT_HISTORY_DETAIL',
  BOT_HISTORY_SYMBOLS = 'BOT_HISTORY_SYMBOLS',
  BOT_HISTORY_ORDERS = 'BOT_HISTORY_ORDERS',
  BOT_HISTORY_POSITIONS = 'BOT_HISTORY_POSITIONS',
  BOT_HISTORY_PROFITLOSS = 'BOT_HISTORY_PROFITLOSS',
  BOT_BACK_TESTING = 'BOT_BACK_TESTING',
  BOT_SIGNALS = 'BOT_SIGNALS',
  BOT_SIGNALS_TODAYS_POSITIONS = 'BOT_SIGNALS_TODAYS_POSITIONS',
  BOT_SIGNALS_TODAYS_ORDERS = 'BOT_SIGNALS_TODAYS_ORDERS',
  BOT_SIGNALS_POSITIONS = 'BOT_SIGNALS_POSITIONS',
  BOT_SIGNALS_ORDERS = 'BOT_SIGNALS_ORDERS',
  BOT_BACK_TESTING_POSITIONS = 'BOT_BACK_TESTING_POSITIONS',
  BOT_BACK_TESTING_ORDERS = 'BOT_BACK_TESTING_ORDERS',
  BOT_BACK_TESTING_SYMBOLS = 'BOT_BACK_TESTING_SYMBOLS',
  BOT_BACK_TESTING_PROFITLOSS = 'BOT_BACK_TESTING_PROFITLOSS',
  BOT_BACK_TESTING_FORM = 'BOT_BACK_TESTING_FORM',
  BOT_TREE_VIEW = 'BOT_TREE_VIEW',

  BOT_STRATEGY_BUILDER = 'BOT_STRATEGY_BUILDER',
  BOT_STRATEGY_DETAIL = 'BOT_STRATEGY_DETAIL',

  PORTFOLIO = 'PORTFOLIO',
  PORTFOLIO_DETAIL = 'PORTFOLIO_DETAIL',
  PORTFOLIO_DETAIL_POSITIONS = 'PORTFOLIO_DETAIL_POSITIONS',
  PORTFOLIO_DETAIL_ORDERS = 'PORTFOLIO_DETAIL_ORDERS',
  PORTFOLIO_DETAIL_SYMBOLS = 'PORTFOLIO_DETAIL_SYMBOLS',

  PORTFOLIO_DETAIL_STATISTIC = 'PORTFOLIO_DETAIL_STATISTIC',
  PORTFOLIO_DETAIL_STATISTIC_POSITIONS = 'PORTFOLIO_DETAIL_STATISTIC_POSITIONS',
  PORTFOLIO_DETAIL_STATISTIC_ORDERS = 'PORTFOLIO_DETAIL_STATISTIC_ORDERS',
  PORTFOLIO_DETAIL_STATISTIC_SYMBOLS = 'PORTFOLIO_DETAIL_STATISTIC_SYMBOLS',
  PORTFOLIO_DETAIL_STATISTIC_PROFITLOSS = 'PORTFOLIO_DETAIL_STATISTIC_PROFITLOSS',

  MARKETPLACE = 'MARKETPLACE',
  MARKETPLACE_DETAIL = 'MARKETPLACE_DETAIL',
  MARKETPLACE_SEARCH = 'MARKETPLACE_SEARCH',
  MARKETPLACE_CATEGORY = 'MARKETPLACE_CATEGORY',
  MARKETPLACE_ORDERS = 'MARKETPLACE_ORDERS',
  MARKETPLACE_POSITIONS = 'MARKETPLACE_POSITIONS',
  MARKETPLACE_SYMBOLS = 'MARKETPLACE_SYMBOLS',
  MARKETPLACE_PROFITLOSS = 'MARKETPLACE_PROFITLOSS',

  MENU = 'MENU',
  BROKER = 'BROKER',
  PROFILE_NOTIFICATION = 'PROFILE_NOTIFICATION',
  WALLET = 'WALLET',
  HELP = 'HELP',
  ADD_BROKER = 'ADD BROKER',
  EDIT_BROKER = 'EDIT BROKER',
  PROFILE = 'PROFILE',
  PROFILE_EDIT = 'PROFILE_EDIT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PLAN_DETAIL = 'PLAN_DETAIL',
  REFER_EARN = 'REFER_EARN',
  BILLING_DETAILS = 'BILLING_DETAILS',
  TICKET_DETAILS = 'TICKET_DETAILS',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_DETAIL = 'TEMPLATES_DETAIL',
  TEMPLATES_SEARCH = 'TEMPLATES_SEARCH',
  TEMPLATES_CATEGORY = 'TEMPLATES_CATEGORY',

  TRADERS = 'TRADERS',
  TRADERS_SEARCH = 'TRADERS_SEARCH',
  TRADERS_DETAIL = 'TRADERS_DETAIL',
  TRADERS_CATEGORY = 'TRADERS_CATEGORY',
  TRADERS_ORDERS = 'TRADERS_ORDERS',
  TRADERS_POSITIONS = 'TRADERS_POSITIONS',
  TRADERS_SYMBOLS = 'TRADERS_SYMBOLS',
  TRADERS_PROFITLOSS = 'TRADERS_PROFITLOSS',

  REPORTS = 'REPORTS',
  TRADE_OPTIONS = 'TRADE_OPTIONS',
  TRADE_IDEAS = 'TRADE_IDEAS',
}

// General
export type NoBreadcrumbData = Record<string, unknown>;

// Portfolio
export type PortfolioDetailsBreadcrumbData = {
  ptype: number;
};

export type PortfolioDetailPositionsBreadcrumbData = {
  subId?: number;
  botName: string;
} & PortfolioDetailsBreadcrumbData;

export type PortfolioDetailStatisticPositionsBreadcrumbData =
  PortfolioDetailPositionsBreadcrumbData;

// Marketplace
export type MarketplacePositionsBreadcrumbData = {
  botId: number;
  botName: string;
};

// Bots
export type BotBacktestHistorySymbolsBreadcrumbData = {
  botId: number;
  subId: number;
  botName: string;
};
export type BotHistoryDetailBreadcrumbData = {
  title: string;
  historySubId: string;
} & BotBacktestHistorySymbolsBreadcrumbData;

export type BotBacktestFormBreadcrumbData = {
  userBotId: number;
} & BotBacktestHistorySymbolsBreadcrumbData;

// Breadcrumb
export type BreadcrumbInfo = {
  page: PAGES;
  label: string;
  link: string;
};

export type BreadcrumbData =
  | NoBreadcrumbData
  | BotBacktestHistorySymbolsBreadcrumbData
  | BotHistoryDetailBreadcrumbData
  | BotBacktestFormBreadcrumbData
  | PortfolioDetailsBreadcrumbData
  | PortfolioDetailStatisticPositionsBreadcrumbData
  | MarketplacePositionsBreadcrumbData;

export type Generator = (data: BreadcrumbData) => BreadcrumbInfo[];

export type GeneratorPages = Extract<
  PAGES,
  | PAGES.BOT_DETAIL
  | PAGES.BOT_DETAIL_SYMBOLS
  | PAGES.BOT_HISTORY
  | PAGES.BOT_HISTORY_DETAIL
  | PAGES.BOT_HISTORY_SYMBOLS
  | PAGES.BOT_HISTORY_ORDERS
  | PAGES.BOT_HISTORY_POSITIONS
  | PAGES.BOT_HISTORY_PROFITLOSS
  | PAGES.BOT_SIGNALS
  | PAGES.BOT_SIGNALS_TODAYS_POSITIONS
  | PAGES.BOT_SIGNALS_TODAYS_ORDERS
  | PAGES.BOT_SIGNALS_POSITIONS
  | PAGES.BOT_SIGNALS_ORDERS
  | PAGES.BOT_BACK_TESTING
  | PAGES.BOT_BACK_TESTING_ORDERS
  | PAGES.BOT_BACK_TESTING_POSITIONS
  | PAGES.BOT_BACK_TESTING_SYMBOLS
  | PAGES.BOT_BACK_TESTING_PROFITLOSS
  | PAGES.BOT_STRATEGY_BUILDER
  | PAGES.BOT_STRATEGY_DETAIL
  | PAGES.BOT_BACK_TESTING_FORM
  | PAGES.BOT_TREE_VIEW
  | PAGES.PORTFOLIO_DETAIL
  | PAGES.PORTFOLIO_DETAIL_POSITIONS
  | PAGES.PORTFOLIO_DETAIL_ORDERS
  | PAGES.PORTFOLIO_DETAIL_SYMBOLS
  | PAGES.PORTFOLIO_DETAIL_STATISTIC_POSITIONS
  | PAGES.PORTFOLIO_DETAIL_STATISTIC_ORDERS
  | PAGES.PORTFOLIO_DETAIL_STATISTIC_SYMBOLS
  | PAGES.PORTFOLIO_DETAIL_STATISTIC_PROFITLOSS
  | PAGES.MARKETPLACE_DETAIL
  | PAGES.MARKETPLACE_SEARCH
  | PAGES.MARKETPLACE_CATEGORY
  | PAGES.MARKETPLACE_POSITIONS
  | PAGES.MARKETPLACE_ORDERS
  | PAGES.MARKETPLACE_SYMBOLS
  | PAGES.MARKETPLACE_PROFITLOSS
  | PAGES.BROKER
  | PAGES.PROFILE_NOTIFICATION
  | PAGES.ADD_BROKER
  | PAGES.EDIT_BROKER
  | PAGES.PROFILE
  | PAGES.PROFILE_EDIT
  | PAGES.CHANGE_PASSWORD
  | PAGES.PLAN_DETAIL
  | PAGES.BILLING_DETAILS
  | PAGES.WALLET
  | PAGES.TEMPLATES
  | PAGES.TEMPLATES_DETAIL
  | PAGES.TEMPLATES_SEARCH
  | PAGES.TEMPLATES_CATEGORY
  | PAGES.TRADERS_DETAIL
  | PAGES.TRADERS_CATEGORY
  | PAGES.TRADERS_ORDERS
  | PAGES.TRADERS_POSITIONS
  | PAGES.TRADERS_SYMBOLS
  | PAGES.TRADERS_PROFITLOSS
  | PAGES.REFER_EARN
  | PAGES.REPORTS
  | PAGES.HELP
  | PAGES.TICKET_DETAILS
  | PAGES.TRADE_OPTIONS
  | PAGES.TRADE_IDEAS
>;

export type BreadcrumbPush = (
  link: string,
  page: PAGES,
  backLink: string,
  label: string,
) => void;
