import { LargeVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const LargeVariant = (props: LargeVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Typography variant="h6" fontWeight={500} color={props.color}>
        {props.title}
      </Typography>
    </Box>
  );
};

export default LargeVariant;
