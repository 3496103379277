import { Box, Typography } from '@mui/material';
import emptyposition from '../../../../assets/images/emptyposition.svg';
import { useContext } from 'react';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
const CommonMessage = ({
  title,
  desc1,
  desc2,
}: {
  title: string;
  desc1: string;
  desc2: string;
}) => {
  const { isDarkTheme } = useContext(themeContext);
  return (
    <Box
      sx={{
        height: '100%',
        // border: '1px solid rgb(0 0 0 / 23%)',
        // borderRadius: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        style={{
          filter: `invert(${isDarkTheme ? 1 : 0}) opacity(${
            isDarkTheme ? 0.5 : 1
          })`,
        }}
        width={350}
        src={emptyposition}
        alt="Add some positions from optionchain"
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={2}
      >
        <Typography
          variant="body1"
          fontWeight={'600'}
          mb={1}
          color={'text.secondary'}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontStyle={'italic'}
          color={'text.secondary'}
        >
          {desc1}
        </Typography>
        <Typography
          variant="body2"
          fontStyle={'italic'}
          color={'text.secondary'}
        >
          {desc2}
        </Typography>
      </Box>
    </Box>
  );
};

export default CommonMessage;
