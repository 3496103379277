import { Box, Divider, Typography, useTheme } from '@mui/material';
import { PageTitleBotsVariantProps } from '../types';

const PageTitleBotsVariant = ({
  title,
  filterChips,
}: PageTitleBotsVariantProps) => {
  const theme = useTheme();
  return (
    <Box
      className="sticky-top"
      sx={{ backgroundColor: theme.palette.common.white }}
    >
      <Box
        className="p-main-spacing"
        display={{ md: 'flex' }}
        justifyContent={{ md: 'space-between' }}
        sx={{ pt: 3, pb: 2 }}
      >
        <Box sx={{ pb: 1 }}>
          <Typography variant="h5" fontWeight={600} color="common.black">
            {title}
          </Typography>
        </Box>
        <Box>{filterChips}</Box>
      </Box>
      <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
    </Box>
  );
};

export default PageTitleBotsVariant;
