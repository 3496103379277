import { Divider } from '@mui/material';
import { ItemSeparatorProps } from '../types';

const ItemSeparator = (props: ItemSeparatorProps) => {
  return (
    <Divider
      orientation={props.orientation}
      {...(props.className && { className: props.className })}
      sx={{
        borderBottom: props.variant === '1px' ? 1 : 2,
        borderColor: props.borderColor ? props.borderColor : 'grey.200',
        mx: props.mx ? props.mx : 0,
      }}
      flexItem={props.orientation === 'vertical' ? true : false}
    />
  );
};

export default ItemSeparator;
