import { SESSION_ID, USER_ID } from 'constants/common';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setCookie } from 'typescript-cookie';

export function useAuthFromQueryParams() {
  const history = useHistory();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(SESSION_ID) && queryParams.has(USER_ID)) {
      setCookie(SESSION_ID, queryParams.get(SESSION_ID));
      setCookie(USER_ID, queryParams.get(USER_ID));
      queryParams.delete(SESSION_ID);
      queryParams.delete(USER_ID);
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history]);
}
