import { ItemSwitchVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const ItemSwitchVariant = (props: ItemSwitchVariantProps) => {
  return (
    <Box
      sx={{ py: 2 }}
      className="p-main-spacing"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="body2" color="primary.main">
          {props.title}
        </Typography>
      </Box>
      <Box>{props.switch}</Box>
    </Box>
  );
};

export default ItemSwitchVariant;
