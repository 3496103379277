import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { DeleteBroker } from 'services/apiServices';
import { DeleteBrokerResult, Errors } from 'types/ApiServicesTypes';

export default function useDeleteBroker(id: number) {
  const breadcrumb = useBreadcrumbManager();

  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<DeleteBrokerResult, Errors>(
    ['DeleteBroker', id],
    () =>
      DeleteBroker(id)
        .then(res => {
          if (res) {
            if (res && res.success) breadcrumb.goBackPathPage('/broker');
            else if (res && res.error) showAlert!(res.error);
            else showAlert!('Something went wrong. Please try again.');
          }
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
