import { MarketItemProps } from './types';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import {
  encodeUrlData,
  formatNumber,
  isBlankHtmlString,
} from 'utils/GenericFunctions';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import MuiChip from 'app/design/uiComponents/MuiChip';
import BotHistoryGraph from 'app/components/Common/Charts/BotHistoryGraph';
import { riskTypes } from '../ComponentItem/Bots/utils';
import ShowMoreText from 'react-show-more-text';
import Ribbon from 'app/components/Common/Ribbon';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

function TraderInfoBox({
  title,
  titleColor,
  subTitle,
}: {
  title: string;
  titleColor?: string;
  subTitle: string;
}) {
  return (
    <Box
      sx={{
        mt: 1,
        flex: 1,
        padding: 2,
        borderRadius: 0.5,
        textAlign: 'center',
        backgroundColor: 'grey.100',
      }}
    >
      <Typography variant="h6" fontWeight={500} color={titleColor}>
        {title}
      </Typography>
      <Typography variant="body2" color={'primary.light'}>
        {subTitle}
      </Typography>
    </Box>
  );
}

const TraderItem = ({ bot }: MarketItemProps) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();

  const riskType = (
    bot.risk_type
      ? riskTypes[bot.risk_type]
        ? riskTypes[bot.risk_type]
        : bot.risk_type
      : ''
  ) as string;

  return (
    <Card elevation={2} sx={{ height: '100%', overflow: 'visible' }}>
      <CardActionArea
        className="p-main-spacing"
        onClick={() =>
          breadcrumb.push(
            `/traders/details/${bot.id}/${encodeUrlData(bot.name)}`,
            PAGES.TRADERS,
            `/traders`,
            t('traders.page_title.traders'),
          )
        }
        sx={{
          cursor: 'pointer',
          py: 2,
          height: '100%',
          position: 'relative',
          pointerEvents: bot.state === 'coming_soon' ? 'none' : 'auto',
        }}
      >
        {bot.state === 'coming_soon' && (
          <Ribbon>
            <FormattedMessage id="traders.coming_soon" />
          </Ribbon>
        )}
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent="space-between"
          flexWrap={'wrap'}
        >
          <Typography variant="h6" color="text.primary" fontWeight={500}>
            {bot.name}
          </Typography>
          {bot.risk_type && (
            <MuiChip
              size="xsmall"
              color={
                riskType === 'High Risk'
                  ? 'errorlight'
                  : riskType === 'Moderate Risk'
                  ? 'infolight'
                  : 'successlight'
              }
              label={riskType}
            />
          )}
        </Box>
        {bot.description && !isBlankHtmlString(bot.description) && (
          <ShowMoreText
            lines={1}
            more={
              <span style={{ visibility: 'hidden', height: '0px' }}>Show</span>
            }
            less=""
            className="show-more"
            anchorClass="anchor-show-more"
            expanded={false}
            truncatedEndingComponent={'...'}
          >
            <Typography
              variant={'body2'}
              sx={{ fontWeight: 400, color: 'text.secondary' }}
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: bot.description,
              }}
            ></Typography>
          </ShowMoreText>
        )}
        <Box display={'flex'}>
          {bot.last_month_return !== undefined && (
            <TraderInfoBox
              title={`${bot.last_month_return > 0 ? '+' : ''}${formatNumber(
                bot.last_month_return,
                false,
                1,
              )}%`}
              titleColor={
                bot.last_month_return > 0
                  ? 'success.main'
                  : bot.last_month_return < 0
                  ? 'error.light'
                  : 'text.primary'
              }
              subTitle={t('traders.last_month_return')}
            />
          )}
          {bot.graph_data.length > 0 && bot.graph_data[0].length >= 8 && (
            <Box ml={1} flex={1} maxHeight={'88px'}>
              <BotHistoryGraph
                data={bot.graph_data[0]}
                xAxis={false}
                yAxis={false}
                tooltip={false}
                grid={false}
                useYAxisDomain={false}
              />
            </Box>
          )}
        </Box>
        <TraderInfoBox
          title={bot.followers!.toString()}
          subTitle={t('traders.subscribers')}
        />
        <Box display={'flex'} gap={1}>
          <TraderInfoBox
            title={`${bot.leverage}x`}
            subTitle={t('traders.default_leverage')}
          />
          <TraderInfoBox
            title={`${bot.currency_symbol}${bot.initial_capital}`}
            subTitle={t('traders.min_capital')}
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default TraderItem;
