import { memo } from 'react';
import { Box } from '@mui/material';
import { createBotSignalClosePositionTableRows } from './util';
import CommonTable from 'app/components/Common/CommonTable';
import { TodaysPositionsProps } from 'types/Position';

const BotSignalTodaysClosePositionsTable = ({
  positions,
  handlePositionClick,
  isDebugMode,
}: TodaysPositionsProps & {
  isDebugMode?: boolean;
}) => {
  const { rows, columns } = createBotSignalClosePositionTableRows(
    positions,
    handlePositionClick,
    isDebugMode,
  );

  return (
    <>
      {positions.length > 0 && (
        <Box className="p-main-spacing">
          <CommonTable rows={rows} columns={columns} />
        </Box>
      )}
    </>
  );
};

export default memo(BotSignalTodaysClosePositionsTable);
