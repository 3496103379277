import { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  decodeUrlData,
  parseFormattedPhoneNumber,
} from 'utils/GenericFunctions';
import { Box, TextField, Typography } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { ToastContext } from 'app/components/Context/ToastContext';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import PageHeader from 'app/design/speedBot/PageHeader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Service
import {
  EditProfileParams,
  EditProfileResult,
  GetOtpParams,
  GetOtpResult,
  Errors,
} from 'types/ApiServicesTypes';
import { EditProfile, GetOtp } from 'services/apiServices';
import { useDoSingOut } from 'services/Auth';
import MuiPhoneNumber from 'material-ui-phone-number';

export function ProfileEditPage() {
  const otptimerDefault = 120;
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const { showAlert } = useContext(ToastContext);
  const { doSignOut } = useDoSingOut();

  const [otpReceived, setOtpReceived] = useState<boolean>(false);
  const [otptimer, setOtptimer] = useState(otptimerDefault);
  const [otpSent, setOtpSent] = useState(false);

  let { name, email, gstin, ccode, mobile } = useParams<{
    name?: string;
    email?: string;
    ccode?: string;
    mobile?: string;
    gstin?: string;
  }>();
  name = decodeUrlData(name!);
  email = decodeUrlData(email!);
  mobile = decodeUrlData(mobile!);
  ccode = decodeUrlData(ccode!);
  gstin = decodeUrlData(gstin!);
  const [isMobileChanged, setIsMobileChanged] = useState<boolean>(
    mobile ? false : true,
  );
  const fieldName: string = name
    ? t('menu.name')
    : email
    ? t('menu.email')
    : gstin || gstin == '-'
    ? 'GSTIN'
    : t('menu.mobile');

  const handleLogout = (msg?: string) => {
    doSignOut(msg);
  };

  // Api calling for edit name in profile
  const mutation = useMutation<
    EditProfileResult,
    Errors,
    Pick<
      EditProfileParams,
      'name' | 'email' | 'mobile' | 'gstin' | 'otp' | 'country_code'
    >
  >('EditProfile', values =>
    EditProfile(
      values.name,
      values.email,
      values.gstin,
      values.mobile,
      values.otp,
      values.country_code,
    ).then(res => {
      if (res.error) {
        showAlert!(res.error);
      } else {
        if (fieldName === 'Email') {
          handleLogout('Email address changed successfully. Login Now.');
        } else {
          breadcrumb.goBackPathPage('/profile');
        }
      }
      return res;
    }),
  );

  const mutation_get_otp = useMutation<
    GetOtpResult,
    Errors,
    Pick<GetOtpParams, 'mobile' | 'country_code'>
  >('GetOtp', values =>
    GetOtp(values.mobile, values.country_code)
      .then(res => {
        if (res.error) {
          setOtpSent(false);
          showAlert!(res.error);
        } else {
          setOtpReceived(true);
        }
        return res;
      })
      .catch(err => {
        setOtpSent(false);
        return err;
      }),
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (otptimer > 0) {
      timeout = setTimeout(() => setOtptimer(otptimer - 1), 1000);
    } else {
      setOtpSent(false);
      setOtptimer(0);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [otptimer]);

  const isLoading = mutation.isLoading || mutation_get_otp.isLoading;
  const gstinRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  return (
    <>
      <Helmet>
        <title>Profile Page</title>
        <meta name="description" content="Profile Page" />
      </Helmet>
      <Formik
        initialValues={{
          country_code: ccode,
          name: name,
          email: email,
          gstin: gstin,
          mobile: mobile,
          otp: '',
        }}
        validationSchema={Yup.object().shape({
          name: name
            ? Yup.string()
                .trim('The text cannot include leading and trailing spaces')
                .required('Name is required')
            : Yup.string(),
          email: email
            ? Yup.string().email().required('Email is required')
            : Yup.string(),
          mobile: mobile
            ? Yup.string()
                .phone(
                  undefined,
                  undefined,
                  'Please enter a valid phone number.',
                )
                .required('Phone number is required.')
            : Yup.string(),
          gstin: gstin
            ? Yup.string()
                .matches(gstinRegex, 'Please enter a valid GSTIN.')
                .required('GSTIN is required.')
            : Yup.string(),
        })}
        onSubmit={(values, actions) => {
          const { mobile, country_code } = values;
          const cleanedMobile = mobile.replace(`+${country_code}`, '');

          if (
            fieldName.toLowerCase() === 'mobile' &&
            !otpReceived &&
            values.country_code == '91'
          ) {
            setOtpSent(true);
            setOtptimer(otptimerDefault);
            mutation_get_otp.mutate({ ...values, mobile: cleanedMobile });
          } else {
            mutation.mutate({ ...values, mobile: cleanedMobile });
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
        }) => (
          <>
            <CircularLoader open={mutation.isLoading} />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <PageHeader
                variant="inactive"
                buttonText={<FormattedMessage id="menu.page_title.profile" />}
                title={fieldName}
                buttonHandleClick="/profile"
                breadcrumb={<Breadcrumbs page={PAGES.PROFILE_EDIT} data={{}} />}
              />
              {fieldName.toLowerCase() === 'mobile' ? (
                <Box className="p-main-spacing">
                  <MuiPhoneNumber
                    sx={{
                      mt: 1,
                      mb: 1,
                      '& .MuiPhoneNumber-flagButton': { width: '30px' },
                    }}
                    fullWidth
                    label="Phone"
                    name="phone"
                    defaultCountry={ccode == '91' ? 'in' : 'us'}
                    disableAreaCodes={true}
                    disableCountryCode={false}
                    autoFormat={true}
                    error={Boolean(
                      touched.mobile && (errors.mobile || errors.mobile),
                    )}
                    value={`${ccode}${mobile}`}
                    onBlur={handleBlur}
                    onChange={(
                      formattedNumber:
                        | string
                        | React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >,
                      country?: {
                        name: string;
                        dialCode: string;
                        countryCode: string;
                      },
                      // event?: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      if (country) {
                        const phoneData = parseFormattedPhoneNumber(
                          formattedNumber as string,
                          country.dialCode,
                        );
                        setFieldValue('mobile', phoneData.phone, false);
                        setFieldValue(
                          'country_code',
                          phoneData.countryCode,
                          false,
                        );
                        setFieldValue(
                          'mobile',
                          '+' + phoneData.countryCode + phoneData.phone,
                          false,
                        );

                        if (
                          phoneData.phone !== mobile ||
                          phoneData.countryCode !== ccode
                        ) {
                          setIsMobileChanged(true);
                        } else {
                          setIsMobileChanged(false);
                        }
                      }
                    }}
                    // className='m-main-spacing'
                    variant="outlined"
                  />

                  {fieldName.toLowerCase() === 'mobile' && otpReceived && (
                    <>
                      <TextField
                        fullWidth
                        type="password"
                        name="otp"
                        autoComplete="on"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={t('menu.verification_code')}
                        sx={{ mt: 2 }}
                      />
                      <Box sx={{ mt: 2.5 }}>
                        {otpSent ? (
                          <Typography variant="caption">
                            Get verification code in {otptimer} Sec(s)
                          </Typography>
                        ) : (
                          <MuiButton
                            size="small"
                            variant="text"
                            sx={{ px: '0 !important' }}
                            onClick={() => {
                              setOtpSent(true);
                              setOtptimer(otptimerDefault);
                              mutation_get_otp.mutate(values);
                            }}
                          >
                            <FormattedMessage id="did_not_receive_code" />
                          </MuiButton>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                <>
                  <Box className="m-main-spacing" sx={{ mb: 1, flex: 1 }}>
                    <TextField
                      fullWidth
                      name={fieldName.toLowerCase()}
                      value={values[fieldName.toLowerCase().trim()] as string}
                      {...(fieldName.toLowerCase() === 'mobile' && {
                        inputProps: { maxLength: '10' },
                      })}
                      onBlur={handleBlur}
                      onChange={event => {
                        // if (event.target.name.toLowerCase() === 'mobile') {
                        //   if (event.target.value !== mobile) {
                        //     setIsMobileChanged(true);
                        //   } else {
                        //     setIsMobileChanged(false);
                        //   }
                        //

                        handleChange(event);
                      }}
                      error={Boolean(
                        touched[fieldName.toLowerCase()] &&
                          errors[fieldName.toLowerCase()],
                      )}
                      helperText={
                        (touched[fieldName.toLowerCase()] as string) &&
                        (errors[fieldName.toLowerCase()] as string)
                      }
                    />
                  </Box>
                </>
              )}
              <Box className="m-main-spacing" sx={{ mb: 0 }}>
                <MuiLoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mb: 2 }}
                  type="submit"
                  loading={isLoading}
                  loadingPosition="start"
                  disabled={
                    mutation.isLoading ||
                    Boolean(errors.name || errors.email) ||
                    (otpSent &&
                      fieldName.toLowerCase() === 'mobile' &&
                      !otpReceived) ||
                    !isMobileChanged
                  }
                >
                  {fieldName.toLowerCase() === 'mobile' && !otpReceived ? (
                    <FormattedMessage id="buttons.verify_mobile" />
                  ) : (
                    t('buttons.update', { type: fieldName })
                  )}
                </MuiLoadingButton>

                <MuiButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  // component={RouterLink}
                  // to="/profile"
                  onClick={() => breadcrumb.goBackPathPage('/profile')}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
