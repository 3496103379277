import i18next from 'i18next';
import {
  PAGES,
  NoBreadcrumbData,
  MarketplacePositionsBreadcrumbData,
} from '../types';

export const marketplaceDetail = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: NoBreadcrumbData,
) => {
  return [
    {
      page: PAGES.MARKETPLACE,
      link: `/discover/bots`,
      label: i18next.t('market.page_title.market'),
    },
  ];
};

export const marketplaceDetailOrderPositions = (
  data: MarketplacePositionsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.MARKETPLACE,
      link: `/discover/bots`,
      label: i18next.t('market.page_title.market'),
    },
    {
      page: PAGES.MARKETPLACE_DETAIL,
      link: `/discover/bot/${data.botId}/${data.botName}`,
      label: data.botName,
    },
  ];
};
