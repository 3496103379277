import { Box } from '@mui/material';
import { ReactNode } from 'react';

const SigninPageContentLayout = ({
  children,
  autoHeight,
}: {
  children: ReactNode;
  autoHeight?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        height: autoHeight ? 'auto' : '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default SigninPageContentLayout;
