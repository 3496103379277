import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createContractSelectionOptions } from 'app/components/Builder/OptionsStrategy/FieldsData';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiToggleButton from 'app/design/uiComponents/MuiToggleButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import { SignalStraddleValueType } from './types';
import {
  SignalPositionTypes,
  SignalProductTypes,
  SignalTransactionTypes,
} from '../util';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const BotGenerateSignalTemplateStraddle = ({
  niftySymbol,
  handleGenerateSignal,
  getAtmInstrument,
  handleClickCancel,
}: {
  niftySymbol: string;
  handleGenerateSignal: (type: string, values: SignalStraddleValueType) => void;
  getAtmInstrument: (atmValue: number) => number;
  handleClickCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [valAtmCEPrice, setValAtmCEPrice] = useState(0);
  const [valAtmPEPrice, setValAtmPEPrice] = useState(0);

  const contractSelectionOptions = useMemo(() => {
    return createContractSelectionOptions(niftySymbol, 'strike', 'call');
  }, [niftySymbol]);

  useEffect(() => {
    const difValCEStrikePrice = getAtmInstrument(0);
    const difValPEStrikePrice = getAtmInstrument(0);
    setValAtmCEPrice(difValCEStrikePrice ? difValCEStrikePrice : 0);
    setValAtmPEPrice(difValPEStrikePrice ? difValPEStrikePrice : 0);
  }, [getAtmInstrument]);

  return (
    <>
      <Box sx={{ height: '100%', overflow: 'auto', display: 'grid', pt: 1 }}>
        <Formik
          initialValues={
            {
              value: 0,
              lots: 1,
              transactionType: 'BUY',
              productType: 'MIS',
              orderTag: '',
              tradeId: '',
              positionType: 'EN',
            } as SignalStraddleValueType
          }
          validationSchema={Yup.object().shape({
            lots: Yup.number()
              .min(1, t('bot_deploy_modal.valid_msg.valid', { type: 'Lots' }))
              .required(t('bot_deploy_modal.valid_msg.req', { type: 'Lots' })),
          })}
          onSubmit={(values, actions) => {
            handleGenerateSignal('STRADDLE', values);
            actions.resetForm();
          }}
        >
          {({
            values,
            setFieldValue,
            isValid,
            submitForm,
            resetForm,
            touched,
            errors,
            handleBlur,
            handleChange,
          }) => {
            return (
              <>
                <Box
                  component="form"
                  noValidate
                  // onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    pt: 1,
                    position: 'relative',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <InputLabel id="label-value">
                            {`${t('bot_signals.template_form.value')} [${t(
                              'bot_signals.template_form.pe',
                            )}/${t('bot_signals.template_form.ce')}]`}
                          </InputLabel>
                          <Select
                            labelId="label-value"
                            name="value"
                            value={values.value}
                            label={`${t(
                              'bot_signals.template_form.value',
                            )} [${t('bot_signals.template_form.pe')}/${t(
                              'bot_signals.template_form.ce',
                            )}]`}
                            onChange={e => {
                              const val = e.target.value as number;
                              setFieldValue('value', val);
                              const newStrikePriceCE = getAtmInstrument(val);
                              const newStrikePricePE = getAtmInstrument(
                                -1 * val,
                              );
                              setValAtmCEPrice(newStrikePriceCE);
                              setValAtmPEPrice(newStrikePricePE);
                            }}
                          >
                            {contractSelectionOptions.map(interval => (
                              <MenuItem value={interval.key} key={interval.key}>
                                {interval.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Box className="extra-label">
                            <Box
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <Typography variant="body2">
                                PE {valAtmPEPrice}
                              </Typography>
                              <Typography variant="body2">
                                CE {valAtmCEPrice}
                              </Typography>
                            </Box>
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.lots && errors.lots)}
                          variant="outlined"
                          sx={{ mb: 3 }}
                        >
                          <InputLabel htmlFor="outlined-adornment-lots">
                            {t('bot_signals.template_form.lots')}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-lots"
                            type="number"
                            name="lots"
                            value={values.lots}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{
                              min: 1,
                              style: { textAlign: 'center' },
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setFieldValue('lots', values.lots + 1)
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={() =>
                                    setFieldValue(
                                      'lots',
                                      values.lots <= 1 ? 1 : values.lots - 1,
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t('bot_signals.template_form.lots')}
                          />
                          {touched.lots && errors.lots && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.lots}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ mb: 3 }}
                        >
                          <InputLabel htmlFor="label-tradeId">
                            <FormattedMessage id="bot_signals.form.trade_id_optional" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-tradeId"
                            name="tradeId"
                            value={values.tradeId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={t('bot_signals.form.trade_id_optional')}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ mb: 3 }}>
                          <Typography sx={{ mb: 1 }} fontWeight={500}>
                            {t('bot_signals.form.transaction_type')}
                          </Typography>
                          <Box display="flex" gap={2}>
                            {SignalTransactionTypes.map(item => (
                              <Fragment key={item.value}>
                                <MuiToggleButton
                                  label={item.label}
                                  value={item.value}
                                  autoWidth={true}
                                  isSelected={
                                    item.value === values.transactionType
                                  }
                                  setValue={val => {
                                    setFieldValue('transactionType', val);
                                  }}
                                />
                              </Fragment>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ mb: 3 }}>
                          <Typography sx={{ mb: 1 }} fontWeight={500}>
                            {t('bot_signals.form.product_type')}
                          </Typography>
                          <Box display="flex" gap={2}>
                            {SignalProductTypes.map(item => (
                              <Fragment key={item.value}>
                                <MuiToggleButton
                                  label={item.label}
                                  value={item.value}
                                  autoWidth={true}
                                  isSelected={item.value === values.productType}
                                  setValue={val => {
                                    setFieldValue('productType', val);
                                  }}
                                />
                              </Fragment>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Box sx={{ mb: 3 }}>
                          <Typography sx={{ mb: 1 }} fontWeight={500}>
                            {t('bot_signals.form.position_type')}
                          </Typography>
                          <Box display="flex" gap={2}>
                            {SignalPositionTypes.map(item => (
                              <Fragment key={item.value}>
                                <MuiToggleButton
                                  label={item.label}
                                  value={item.value}
                                  autoWidth={true}
                                  isSelected={
                                    item.value === values.positionType
                                  }
                                  setValue={val => {
                                    setFieldValue('positionType', val);
                                  }}
                                />
                              </Fragment>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    sx={{
                      textAlign: 'center',
                      display: { xs: 'block', md: 'flex' },
                      justifyContent: 'end',
                      flexDirection: 'row-reverse',
                      gap: 2,
                    }}
                  >
                    <MuiLoadingButton
                      // type="submit"
                      type="button"
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                      // loading={mutation.isLoading}
                      sx={{ mb: 2, width: { xs: '100%', lg: 'auto' } }}
                      onClick={submitForm}
                    >
                      <FormattedMessage id={'buttons.add_basket'} />
                    </MuiLoadingButton>
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleClickCancel();
                        resetForm();
                      }}
                      sx={{ mb: 2, width: { xs: '100%', lg: 'auto' } }}
                    >
                      <FormattedMessage id="buttons.cancel" />
                    </MuiButton>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default memo(BotGenerateSignalTemplateStraddle);
