import { useMutation } from 'react-query';
import { PostTradeIdeasData } from 'services/apiServices';
import { GetTradeIdeasResult } from 'types/ApiServicesTypes';

export type TradeIdeasFilterParam = Record<string, string>;
export default function usePostTradeIdeasData() {
  const objQuery = useMutation<
    GetTradeIdeasResult,
    Error,
    TradeIdeasFilterParam
  >('PostTradeIdeas', values => PostTradeIdeasData(values).then(res => res));

  return objQuery;
}
