import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import ProfitLossByDateReport from 'app/design/speedBot/ComponentItem/Common/ProfitLossByDateReport';

export function PortfolioProfitLossByDateReportPage() {
  const {
    id,
    ptype,
    title: botName,
  } = useParams<{ id: string; ptype: string; title: string }>();

  const baseUrl = `portfolio/${id}`;

  return (
    <>
      <Helmet>
        <title>Profit And Loss By Date Report</title>
        <meta
          name="description"
          content="Profit And Loss By Date Report Page"
        />
      </Helmet>
      <ProfitLossByDateReport
        baseUrl={baseUrl}
        pageHeadButtonText={botName}
        breadCrumbPage={PAGES.PORTFOLIO_DETAIL_STATISTIC_PROFITLOSS}
        breadCrumbData={{ botName: botName, ptype: ptype, subId: id }}
      />
    </>
  );
}
