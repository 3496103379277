import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import PortfolioScripts from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioScripts';
import PageHeader from 'app/design/speedBot/PageHeader';
import StatusMessage from 'app/components/Common/StatusMessage';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Services
import useGetStatistics from 'services/Statistics/useGetStatistics';

export function MarketplaceSymbolsPage() {
  const { id, title: botName } = useParams<{ id: string; title: string }>();

  const { data, isLoading } = useGetStatistics(`marketplace/bots/${id}`);
  const scripts = data?.statistics.scripts || [];
  const history = useHistory();
  const botId = parseInt(id);

  const isFromTrader = window.location.pathname.startsWith('/trader');

  return (
    <Fragment>
      <Helmet>
        <title>{!isFromTrader ? 'Discover' : ''} Symbols</title>
        <meta
          name="description"
          content={`${!isFromTrader ? 'Discover' : ''} Symbols Page`}
        />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        title={<FormattedMessage id="bots.page_title.symbols" />}
        buttonText={`${botName}`}
        buttonHandleClick={() => {
          history.goBack();
        }}
        breadcrumb={
          <Breadcrumbs
            page={
              isFromTrader ? PAGES.TRADERS_SYMBOLS : PAGES.MARKETPLACE_SYMBOLS
            }
            data={{ botName, botId }}
          />
        }
      />
      {scripts.length > 0 ? (
        <PortfolioScripts scripts={scripts} />
      ) : (
        <StatusMessage
          title={<FormattedMessage id="bots.symbols.status_msg.title" />}
          subtitle={<FormattedMessage id="bots.symbols.status_msg.sub_title" />}
        />
      )}
    </Fragment>
  );
}
