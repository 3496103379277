import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  OutlinedInput,
  TextField,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { StrategyBuilderPortfolioFormData } from './types';
import {
  HoldingTypeData,
  OrderTypeData,
  PositioningSizingData,
  positionSizingInfoModalData,
} from './util';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import InfoModal from 'app/components/Common/InfoModal';
import { useCallback, useState } from 'react';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { isUserLoggedIn } from 'utils/auth';
import SignInModal from 'app/components/Auth/SignInModal';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

export default function StrategyBuilderPortfolioTab({
  portfolioData,
  setPortfolio,
  handleBack,
}: {
  portfolioData: StrategyBuilderPortfolioFormData;
  setPortfolio: (values: StrategyBuilderPortfolioFormData) => void;
  handleBack: (values: number) => void;
}) {
  const { t } = useTranslation();
  const isLoggedIn = isUserLoggedIn();
  const isMdUp = useGetMediaQueryUp('md');
  const isEquityBotBuilderLimitOrder = useIsFeatureFlag([
    FEATURES.IS_EQUITY_BOTBUILDER_LIMIT_ORDER,
  ]);

  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const infoClickHandler = useCallback(() => {
    setOpenInfo(true);
  }, []);
  const [isLoginModal, setIsLoginModal] = useState<boolean>(false);
  const handleInfoClose = useCallback(() => {
    setOpenInfo(false);
  }, []);

  return (
    <>
      <Formik
        initialValues={
          portfolioData && portfolioData.orderType
            ? portfolioData
            : ({
                positioningSizing: '',
                positionValue: undefined,
                maxRiskAmount: undefined,
                orderType: '',
                limitOrderValid: undefined,
                holdingType: '',
                // squareOffTime: formatDateString(new Date(), 'HH:mm'),
                squareOffTime: '15:15',
              } as StrategyBuilderPortfolioFormData)
        }
        validationSchema={Yup.object().shape({
          positioningSizing: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.position_sizing'),
            }),
          ),
          positionValue: Yup.number()
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_bot_startegy.form.position_value'),
              }),
            )
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('user_bot_startegy.form.position_value'),
              }),
            ),
          // maxRiskAmount: Yup.string().required(
          //   'Max Risk Amout for strategy is requried',
          // ),
          orderType: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.order_type'),
            }),
          ),
          limitOrderValid: Yup.number().when('orderType', {
            is: value => value === 'limit',
            then: Yup.number()
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: t('user_bot_startegy.form.limit_order_valid'),
                }),
              )
              .positive()
              .min(
                0,
                t('user_backtesting.valid_msg.valid', {
                  name: t('user_bot_startegy.form.limit_order_valid'),
                }),
              ),
          }),
          holdingType: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.holding_type'),
            }),
          ),
        })}
        onSubmit={values => {
          if (!values.maxRiskAmount) values.maxRiskAmount = 0;
          setPortfolio(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          isValid,
        }) => (
          <Box
            className="m-main-spacing"
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box sx={{ mb: 2, flex: 1 }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    sx={{ mb: 3 }}
                    error={Boolean(
                      touched.positioningSizing && errors.positioningSizing,
                    )}
                  >
                    <InputLabel id="label-positioningSizing">
                      <FormattedMessage id="user_bot_startegy.form.position_sizing" />
                    </InputLabel>
                    <Select
                      labelId="label-positioningSizing"
                      name="positioningSizing"
                      value={values.positioningSizing}
                      label={t('user_bot_startegy.form.position_sizing')}
                      onChange={e => {
                        setFieldValue('positioningSizing', e.target.value);
                      }}
                    >
                      {PositioningSizingData &&
                        PositioningSizingData.map(positioningSizing => (
                          <MenuItem
                            value={positioningSizing.value}
                            key={positioningSizing.value}
                          >
                            {positioningSizing.label}
                          </MenuItem>
                        ))}
                    </Select>
                    <Box
                      className="extra-label"
                      sx={{ color: 'text.primary', right: '1rem' }}
                    >
                      <Typography variant="body2">
                        <ItemHead
                          variant="largeWithIcon"
                          title=""
                          icon={
                            <IconButton
                              sx={{ p: 0.75, color: 'action.active' }}
                              onClick={infoClickHandler}
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                          }
                        />
                      </Typography>
                    </Box>
                    {touched.positioningSizing && errors.positioningSizing && (
                      <FormHelperText error>
                        {errors.positioningSizing}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.positionValue && errors.positionValue,
                    )}
                    variant="outlined"
                    sx={{ mb: 3 }}
                  >
                    <InputLabel htmlFor="label-positionValue">
                      <FormattedMessage id="user_bot_startegy.form.position_value" />
                    </InputLabel>
                    <OutlinedInput
                      id="label-positionValue"
                      type="number"
                      name="positionValue"
                      value={values.positionValue}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label={t('user_bot_startegy.form.position_value')}
                      inputProps={{ min: 0, step: '100' }}
                      endAdornment={
                        <InputAdornment position="end">
                          <CustomTooltip tooltipKey={'eq_position_value'}>
                            {({ isTooltipAvailable }) =>
                              isTooltipAvailable ? (
                                <IconButton>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              ) : (
                                <></>
                              )
                            }
                          </CustomTooltip>
                        </InputAdornment>
                      }
                    />
                    {touched.positionValue && errors.positionValue && (
                      <FormHelperText error>
                        {errors.positionValue}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.maxRiskAmount && errors.maxRiskAmount)}
                variant="outlined"
                sx={{ mb: 3 }}
              >
                <InputLabel htmlFor="label-maxRiskAmount">
                  Max Risk Amount
                </InputLabel>
                <OutlinedInput
                  id="label-maxRiskAmount"
                  type="number"
                  name="maxRiskAmount"
                  value={values.maxRiskAmount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Max Risk Amount"
                  inputProps={{ min: 0, step: '100' }}
                />
                {touched.maxRiskAmount && errors.maxRiskAmount && (
                  <FormHelperText error>{errors.maxRiskAmount}</FormHelperText>
                )}
              </FormControl>
              </Grid> */}

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    sx={{ mb: 3 }}
                    error={Boolean(touched.orderType && errors.orderType)}
                  >
                    <InputLabel id="label-orderType">
                      <FormattedMessage id="user_bot_startegy.form.order_type" />
                    </InputLabel>
                    <Select
                      labelId="label-orderType"
                      name="orderType"
                      value={values.orderType}
                      label={t('user_bot_startegy.form.order_type')}
                      onChange={e => {
                        setFieldValue('orderType', e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          <CustomTooltip tooltipKey={'eq_order_type'}>
                            {({ isTooltipAvailable }) =>
                              isTooltipAvailable ? (
                                <IconButton>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              ) : (
                                <></>
                              )
                            }
                          </CustomTooltip>
                        </InputAdornment>
                      }
                    >
                      {OrderTypeData.map(orderType => {
                        if (
                          !isEquityBotBuilderLimitOrder &&
                          orderType.value === 'limit'
                        )
                          return null;
                        else
                          return (
                            <MenuItem
                              value={orderType.value}
                              key={orderType.value}
                            >
                              {orderType.label}
                            </MenuItem>
                          );
                      })}
                    </Select>
                    {touched.orderType && errors.orderType && (
                      <FormHelperText error>{errors.orderType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {values.orderType === 'limit' && (
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        touched.limitOrderValid && errors.limitOrderValid,
                      )}
                      variant="outlined"
                      sx={{ mb: 3 }}
                    >
                      <InputLabel htmlFor="label-limitOrderValid">
                        <FormattedMessage id="user_bot_startegy.form.limit_order_valid" />
                        (<FormattedMessage id="user_bot_startegy.in_minutes" />)
                      </InputLabel>
                      <OutlinedInput
                        id="label-limitOrderValid"
                        type="number"
                        name="limitOrderValid"
                        value={values.limitOrderValid}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={
                          t('user_bot_startegy.form.limit_order_valid') +
                          ' (' +
                          t('user_bot_startegy.in_minutes') +
                          ')'
                        }
                        inputProps={{ min: 0 }}
                      />
                      {touched.limitOrderValid && errors.limitOrderValid && (
                        <FormHelperText error>
                          {errors.limitOrderValid}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    sx={{ mb: 3 }}
                    error={Boolean(touched.holdingType && errors.holdingType)}
                  >
                    <InputLabel id="label-holdingType">
                      <FormattedMessage id="user_bot_startegy.form.holding_type" />
                    </InputLabel>
                    <Select
                      labelId="label-holdingType"
                      name="holdingType"
                      value={values.holdingType}
                      label={t('user_bot_startegy.form.holding_type')}
                      onChange={e => {
                        setFieldValue('holdingType', e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end" sx={{ mr: 1 }}>
                          <CustomTooltip tooltipKey={'eq_holding_type'}>
                            {({ isTooltipAvailable }) =>
                              isTooltipAvailable ? (
                                <IconButton>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              ) : (
                                <></>
                              )
                            }
                          </CustomTooltip>
                        </InputAdornment>
                      }
                    >
                      {HoldingTypeData.map(holdingType => (
                        <MenuItem
                          value={holdingType.value}
                          key={holdingType.value}
                        >
                          {holdingType.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.holdingType && errors.holdingType && (
                      <FormHelperText error>
                        {errors.holdingType}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {values.holdingType === 'mis' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="label-squareOffTime"
                      label={t('user_bot_startegy.form.square_off_time')}
                      type="time"
                      defaultValue={'15:15'}
                      value={values.squareOffTime}
                      onBlur={handleBlur}
                      onChange={e =>
                        setFieldValue('squareOffTime', e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{ mb: 3 }}
                      error={Boolean(
                        touched.squareOffTime && errors.squareOffTime,
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              sx={{
                mb: 0,
                textAlign: 'center',
                display: { xs: 'block', md: 'flex' },
                justifyContent: 'center',
                flexDirection: 'row-reverse',
              }}
            >
              {isLoggedIn ? (
                <MuiButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth={!isMdUp}
                  sx={{
                    mb: { xs: 2, md: 0 },
                  }}
                  disabled={!isValid}
                >
                  <FormattedMessage id="buttons.continue" />
                </MuiButton>
              ) : (
                <MuiButton
                  onClick={() => setIsLoginModal(true)}
                  variant="contained"
                  color="primary"
                  fullWidth={!isMdUp}
                  sx={{
                    mb: { xs: 2, md: 0 },
                  }}
                  disabled={!isValid}
                >
                  <FormattedMessage id="buttons.login_and_continue" />
                </MuiButton>
              )}
              <MuiButton
                variant="contained"
                color="secondary"
                fullWidth={!isMdUp}
                onClick={handleBack}
                sx={{
                  mr: { xs: 0, md: 2 },
                }}
              >
                <FormattedMessage id="buttons.back" />
              </MuiButton>
            </Box>
          </Box>
        )}
      </Formik>

      <InfoModal
        data={positionSizingInfoModalData}
        open={openInfo}
        handleClose={handleInfoClose}
        closeBtn={true}
      />
      {!isLoggedIn && (
        <SignInModal
          handleClose={() => setIsLoginModal(false)}
          open={isLoginModal}
        />
      )}
    </>
  );
}
