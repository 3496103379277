import {
  Box,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Fragment, useState } from 'react';
import useGetWalletTransactions from 'services/Menu/useGetWalletTransactions';
import { formatDateString, getColorByNum } from 'utils/GenericFunctions';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

function WalletTransactionList() {
  const [page, setPage] = useState(1);
  const {
    data: walletTransactions,
    isLoading,
    isFetching,
  } = useGetWalletTransactions(page, 10);
  const isMdUp = useGetMediaQueryUp('md');

  const totalPages =
    walletTransactions && walletTransactions.length > 0
      ? Number(walletTransactions[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <Box>
      <CircularLoader open={isLoading || isFetching} />
      <Box className="p-main-spacing">
        <Divider />
        <Typography variant="h6" fontWeight={500} sx={{ mt: 2 }}>
          <FormattedMessage id="menu.transactions" />
        </Typography>
        <TableContainer>
          <Table
            sx={{
              '& th': { whiteSpace: 'nowrap' },
              '& td': {
                fontWeight: 400,
                verticalAlign: { xs: 'top', md: 'middle' },
              },
              '& th,td': {
                '&:first-child': {
                  pl: 0,
                },
                '&:last-child': {
                  pr: 0,
                },
              },
            }}
          >
            <TableHead>
              {isMdUp ? (
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Credits</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>
                    Name
                    <br />
                    Date
                  </TableCell>
                  <TableCell align="right">Credits</TableCell>
                </TableRow>
              )}
            </TableHead>
            {walletTransactions && walletTransactions.length > 0 ? (
              <TableBody>
                {walletTransactions.map(transaction => (
                  <Fragment key={transaction.id}>
                    {isMdUp ? (
                      <TableRow key={transaction.id}>
                        <TableCell>
                          {formatDateString(transaction.date, 'DD MMM, YYYY')}
                        </TableCell>
                        <TableCell>{transaction.name}</TableCell>
                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography
                            variant="body2"
                            color={getColorByNum(transaction.balance)}
                          >
                            {transaction.balance}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={transaction.id}>
                        <TableCell>
                          {transaction.name}
                          <br />
                          {formatDateString(transaction.date, 'DD MMM, YYYY')}
                        </TableCell>
                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography
                            variant="body2"
                            color={getColorByNum(transaction.balance)}
                          >
                            {transaction.balance}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </Box>
      {walletTransactions && walletTransactions.length > 0 ? (
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePagination}
          color="primary"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '1.5rem',
            pb: 3,
          }}
        />
      ) : (
        <Box textAlign={'center'} mt={2}>
          <Typography
            variant="subtitle1"
            fontStyle={'italic'}
            color={'text.secondary'}
          >
            <FormattedMessage id="menu.no_transaction_msg" />
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default WalletTransactionList;
