import React, { useCallback, useEffect, useState } from 'react';
// import _ from 'lodash';
import {
  ControlsType,
  FieldParam,
  FieldParamValue,
  OptionLegParam,
  ValidateState,
} from '../types';
import {
  FormControl,
  TextField,
  Grid,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { cloneDeep } from 'lodash';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

export const InputFieldRenderer = (props: Props) => {
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, string>;
  const { name, controls, parentKey, myStyle, addClass, width } = param;
  const globaltheme = useTheme();
  const theme = React.useMemo(() => {
    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              color: globaltheme.palette.text.secondary,
            },
            root: {
              width: width ? `${width}px` : '100%',
              fontSize: '16px',
            },
            notchedOutline: {
              borderColor: globaltheme.palette.text.secondary,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: '16px',
              top: '7px',
              color: globaltheme.palette.text.secondary,
            },
            shrink: {
              top: '0px',
            },
          },
        },
      },
    });
  }, [width, globaltheme]);

  const validate = useCallback(
    (formData: Record<string, string>) => {
      let validStatus = {
        status: false,
        error: '',
      };
      Object.keys(formData).forEach((item, index) => {
        if (controls[index].isRequired && !formData[item]) {
          validStatus = {
            status: true,
            error: 'Please enter valid data',
          };
        }
      });
      return validStatus;
    },
    [controls],
  );
  const [userInput, setUserInput] = useState(cloneDeep(selected));

  useEffect(() => {
    const validStatus = validate(selected);
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);
  }, [validate, parentKey, sendValidation, selected]);

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    control: ControlsType,
  ) => {
    userInput[control.key] = event.target.value;
    const validStatus = validate(cloneDeep(userInput));
    setIsValidStatus(validStatus);
    setUserInput(cloneDeep(userInput));
  };

  const onFocusOutHandler = (control: ControlsType) => {
    selected[control.key] = userInput[control.key];
    const validStatus = validate(selected);
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };

  return (
    <Grid item className={addClass} style={{ marginBottom: '24px' }}>
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: myStyle?.small
            ? myStyle.small
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('md')]: myStyle?.medium
            ? myStyle.medium
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('lg')]: myStyle?.large
            ? myStyle.large
            : myStyle?.root
            ? myStyle.root
            : myStyle,
        }}
      />
      <ThemeProvider theme={theme}>
        <FormControl
          variant="outlined"
          className="comman-input"
          style={width ? { width: `${width}px` } : {}}
        >
          {controls.map((control, index) => (
            <TextField
              key={index}
              label={name}
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(event, control)
              }
              onBlur={() => {
                onFocusOutHandler(control);
              }}
              value={userInput[control.key]}
              size={'small'}
              error={isValidStatus.status}
              disabled={isReadOnly}
              InputProps={
                control.prefix
                  ? {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="add-input-prefix"
                        >
                          {control.prefix}
                          <KeyboardArrowDownIcon
                            sx={{ marginLeft: 'auto', marginRight: '5px' }}
                          />
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          ))}
          {/* {isValidStatus.status && (
            <FormHelperText error={isValidStatus.status}>
              {isValidStatus.error}
            </FormHelperText>
          )} */}
        </FormControl>
      </ThemeProvider>
    </Grid>
  );
};
