import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { groupBy, decodeUrlData } from 'utils/GenericFunctions';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PositionsListLayout from 'app/design/layouts/PositionsListLayout';
import BotHistoryPositionsModal from 'app/components/Bots/BotHistory/BotHistoryPositionsModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import { Position } from 'types/ApiServicesTypes';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import useGetBotPositions from 'services/Bots/useGetBotPositions';

export function BotHistoryPositionsPage() {
  const { bid, sid, title, hsid, subtitle } = useParams<{
    bid: string;
    sid: string;
    title: string;
    hsid: string;
    subtitle: string;
  }>();

  const titleData = decodeUrlData(title);
  const subtitleData = decodeUrlData(subtitle);

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();
  const [page, setPage] = React.useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(
    null,
  );

  const baseApiUrl = `bots/${bid}/history/${hsid}`;

  // For change tab
  const handleChange = (newValue: number) => {
    const path = `/bots/${bid}/history/${sid}/orders/${hsid}/${title}/${subtitle}`;
    if (newValue === 1) {
      history.replace({ pathname: path });
    }
  };

  const botId = parseInt(bid);
  const {
    data: botPositions,
    isLoading,
    refetch,
    isSuccess,
  } = useGetBotPositions(botId, page, baseApiUrl);

  const botGrpPositions: PositionsListLayoutProps = useMemo(() => {
    if (isSuccess && botPositions) {
      const data = groupBy(
        botPositions,
        'exit_time',
        true,
      ) as PositionsListLayoutProps;
      return data;
    } else return [] as PositionsListLayoutProps;
  }, [isSuccess, botPositions]);

  const totalPages =
    botPositions && botPositions.length > 0
      ? Number(botPositions[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const handleModalOpen = (positionData: Position) => {
    setSelectedPosition(positionData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedPosition(null);
  };

  return (
    <>
      <Helmet>
        <title>Bot History Positions</title>
        <meta name="description" content="Bot History Positions Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={
          <>
            {titleData}
            {subtitleData && subtitleData.indexOf('→') === -1
              ? ' | ' + subtitleData
              : ''}
            {subtitleData && subtitleData.indexOf('→') !== -1 ? (
              <>
                {' | '}
                {subtitleData.split('→')[0]}
                <ArrowForwardIcon sx={{ fontSize: '1.25rem', mx: 1 }} />
                {subtitleData.split('→')[1]}
              </>
            ) : (
              <></>
            )}
          </>
        }
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={0}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.position') },
              { label: t('buttons.order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_HISTORY_POSITIONS}
            data={{
              sid,
              botId,
              botName: title,
              historySubId: hsid,
              title: subtitle,
            }}
          />
        }
      />
      <PositionsListLayout
        data={botGrpPositions}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleClick={handleModalOpen}
        handleChangeTab={handleChange}
      />
      <BotHistoryPositionsModal
        open={openModal}
        handleClose={handleModalClose}
        data={selectedPosition || null}
        title={<FormattedMessage id="buttons.position" />}
      />
    </>
  );
}
