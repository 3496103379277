import { Box, Typography } from '@mui/material';
import { PageTitleCommonVariantProps } from '../types';
import { Suspense } from 'react';
import AppbarNotification from 'app/components/Layouts/MainLayout/AppbarNotification';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import useGetProfile from 'services/Menu/useGetProfile';

const PageTitleCommonVariant = ({ title }: PageTitleCommonVariantProps) => {
  const isSideBarView = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);
  const { data: profile } = useGetProfile();

  return (
    <Box
      className="p-main-spacing"
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Typography variant="h5">{title}</Typography>
      {isSideBarView && (
        <Suspense fallback={<div>Loading..</div>}>
          <AppbarNotification activeNav={''} profile={profile} />
        </Suspense>
      )}
    </Box>
  );
};

export default PageTitleCommonVariant;
