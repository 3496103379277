import { useState, useEffect, memo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import BackTestingLogsModal from './BacktestingLogsModal';

const UserBacktestLoader = ({ botId }: { botId: string }) => {
  const [message, setMessage] = useState<string>(
    'user_backtesting.backtesting_in_progress',
  );
  const [open, setOpen] = useState<boolean>(false);
  const isLogs = useIsFeatureFlag([FEATURES.IS_LOGS]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setMessage('user_backtesting.backtesting_is_still_in_progress');
    }, 60 * 1000); //1 minute
  }, []);

  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress color="primary" />
      </Box>
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography variant="body1" fontWeight={400}>
          <FormattedMessage id={message} />
        </Typography>
        <Typography
          variant="body2"
          fontWeight={400}
          sx={{ mt: 1 }}
          color="text.secondary"
        >
          <FormattedMessage id="user_backtesting.wait_for_backtesting_to_complete" />
        </Typography>
        {isLogs ? (
          <MuiButton
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="buttons.show_logs" />
          </MuiButton>
        ) : (
          <></>
        )}
      </Box>
      <BackTestingLogsModal
        open={open}
        botId={botId}
        type="backtesting"
        handleClose={handleClose}
      />
    </Box>
  );
};
export default memo(UserBacktestLoader);
