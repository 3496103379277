import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { FieldParam } from '../types';
import useClasses from '../../utils/useClasses';

// Style const
const styles = () => ({
  container: {
    display: 'flex',
    marginBottom: '10px',
  },
  label: {
    fontWeight: 'bold',
  },
});
type Param = Omit<FieldParam, 'renderer' | 'key' | 'type'>;
interface Props {
  param: Param;
  selected?: string | number;
  onChange: (value: string | number) => void;
}

export function InputFieldRenderer(props: Props) {
  const classes = useClasses(styles);
  const { param, selected, onChange } = props;
  const { name, defaultValue } = param;
  // const globaltheme = useTheme();
  // const theme = useMemo(() => {
  //   return createTheme({
  //     components: {

  //       MuiOutlinedInput: {
  //         styleOverrides: {
  //           input: {
  //             color: globaltheme.palette.text.primary,
  //           },
  //           notchedOutline: {
  //             border: 'none',
  //             paddingRight: 0,
  //           },
  //         },
  //       },
  //       MuiInputLabel: {
  //         styleOverrides: {
  //           root: {
  //             top: '7px',
  //           },
  //           shrink: {
  //             top: '0px',
  //           },
  //         },
  //       },
  //     },
  //   });
  // }, [ globaltheme]);

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <div className={classes.container}>
      <FormControl variant="outlined" size={'small'}>
        <TextField
          type={'type'}
          label={name}
          variant="outlined"
          onChange={onInputChange}
          defaultValue={selected || defaultValue}
          size={'small'}
        />
      </FormControl>
    </div>
  );
}
