import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageHeader from 'app/design/speedBot/PageHeader';
import { decodeUrlData } from 'utils/GenericFunctions';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

const BotDetailPageHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { bid, sid, title } =
    useParams<{ bid: string; sid: string; title: string }>();

  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const titleText = isNaN(parseInt(sid)) ? sid : title;

  const { data: botDetails } = useGetBotDetails(parseInt(bid), subId);
  const isPortfolio = titleText === t('portfolio.page_title.portfolio');

  const getBackBtnTxt = () =>
    titleText ? decodeUrlData(titleText) : t('bots.page_title.bots');
  const backBtnClick = isPortfolio ? () => history.goBack() : '/bots';

  if (!botDetails) return <></>;
  switch (botDetails.category) {
    case 'inactive':
      return (
        <PageHeader
          variant="inactive"
          buttonText={getBackBtnTxt()}
          title={botDetails.name}
          buttonHandleClick={backBtnClick}
          breadcrumb={<Breadcrumbs page={PAGES.BOT_DETAIL} data={{}} />}
        />
      );

    case 'active':
      return (
        <PageHeader
          variant="paperTesting"
          buttonText={getBackBtnTxt()}
          title={botDetails.name}
          buttonHandleClick={backBtnClick}
          // eslint-disable-next-line
          value={botDetails.time}
          chipProps={{
            label: <FormattedMessage id="bot_status.live" />,
            variant: 'filled',
            color: 'infolight',
          }}
          breadcrumb={<Breadcrumbs page={PAGES.BOT_DETAIL} data={{}} />}
        />
      );

    case 'paper':
      return (
        <PageHeader
          variant="paperTesting"
          buttonText={getBackBtnTxt()}
          title={botDetails.name}
          buttonHandleClick={backBtnClick}
          // eslint-disable-next-line
          value={botDetails.time}
          chipProps={{
            label: <FormattedMessage id="bot_status.paper" />,
            variant: 'filled',
            color: 'greylight',
          }}
          breadcrumb={<Breadcrumbs page={PAGES.BOT_DETAIL} data={{}} />}
        />
      );

    default:
      return (
        <PageHeader
          variant="inactive"
          buttonText={getBackBtnTxt()}
          title={botDetails.name}
          buttonHandleClick={backBtnClick}
          breadcrumb={<Breadcrumbs page={PAGES.BOT_DETAIL} data={{}} />}
        />
      );
  }
};

export default BotDetailPageHeader;
