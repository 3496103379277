import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { MARKET_FEES } from 'constants/common';
import { formatNumber } from 'utils/GenericFunctions';

const SubscriptionFeesInfo = ({
  fees,
  visibility,
  infoMessage,
}: {
  fees: string;
  visibility?: string;
  infoMessage?: ReactElement;
}) => {
  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1">
          <FormattedMessage id="sell_on_market.subscription_fees" />{' '}
          <Typography variant="body2" component="span" color="text.secondary">
            /<FormattedMessage id="menu.month" />
          </Typography>
        </Typography>

        <Box
          display="flex"
          justifyContent="space-beetween"
          sx={{ mt: 2 }}
          alignItems="center"
        >
          <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
            <FormattedMessage id="sell_on_market.market_fees" />
          </Typography>
          <Typography variant="body2" fontWeight={400}>
            ₹{formatNumber(MARKET_FEES)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-beetween"
          sx={{ mt: 2 }}
          alignItems="center"
        >
          <Typography variant="body2" color="text.secondary" sx={{ flex: 1 }}>
            <FormattedMessage id="sell_on_market.your_fees" />
          </Typography>
          <Typography variant="body2">
            {fees ? `₹${formatNumber(Number(fees))}` : '-'}
          </Typography>
        </Box>
      </Box>
      {visibility && (
        <Box display="flex" alignItems="center" sx={{ pb: 1 }}>
          <Typography variant="body1" fontWeight={400} sx={{ pr: 0.5 }}>
            <FormattedMessage
              id={
                visibility === 'public'
                  ? `sell_on_market.public`
                  : visibility === 'private'
                  ? `sell_on_market.private`
                  : 'sell_on_market.invisible'
              }
            />
          </Typography>
          {visibility === 'public' ? (
            <PublicIcon />
          ) : visibility === 'private' ? (
            <PersonIcon />
          ) : (
            <VisibilityOffIcon />
          )}
        </Box>
      )}
      {infoMessage && (
        <Typography variant="body2" color="text.secondary" fontWeight="400">
          {infoMessage}
        </Typography>
      )}
    </Box>
  );
};

export default SubscriptionFeesInfo;
