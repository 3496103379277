import {
  Box,
  Typography,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

export default function MaketplaceSearchTextbox({
  headerTitle,
  hint,
  txtKeyword,
  handleChangeSearch,
}: {
  hint?: string;
  headerTitle: string;
  txtKeyword: string;
  handleChangeSearch: (value: string) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleClickClear = () => handleChangeSearch('');
  return (
    <>
      <Box
        className="m-main-spacing"
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          pt: { md: 0, lg: 0.5 },
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' }, zIndex: 1 }}>
          <Typography variant="h5" color="common.black" sx={{ mr: 8 }}>
            {headerTitle}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            [theme.breakpoints.up('lg')]: {
              position: 'absolute',
              width: '100%',
              zIndex: 0,
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          <Box>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                [theme.breakpoints.up('lg')]: {
                  width: '30rem',
                },
                maxWidth: '30rem',
              }}
            >
              <OutlinedInput
                autoFocus={true}
                value={txtKeyword}
                placeholder={hint || t('bots.search')}
                onChange={e => handleChangeSearch(e.target.value)}
                size="small"
                sx={{ '& input': { fontWeight: 400 } }}
                {...(txtKeyword && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickClear}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                })}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
    </>
  );
}
