import React from 'react';
import { FieldData } from './fields/types';
import { FieldKeyToField } from './fields/fieldsData';
import { IconButton, useTheme } from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import {
  strategyRules,
  getNextKeys,
  FinalMatchResult,
  validateMathDescFindErrorIndex,
  validateMathCondition,
} from './utils/StrategyValidation';
import useClasses from './utils/useClasses';
import { StrategyMode } from './utils/strategy-data';

interface Props {
  fieldData: FieldData;
  fieldIndex: number;
  onRemove: (fieldIndex: number) => void;
  onAddFieldAtPosition: (fieldIndex: number) => void;
  onEdit?: (fieldIndex: number, fieldData: FieldData) => void;
  mode: string;
  isValid: {
    status: boolean;
    errorIndex: number;
  };
}

export function MathFieldWrapper(props: Props) {
  const theme = useTheme();
  const styles = () => ({
    container: {
      marginBottom: '8px',
      display: 'flex',
    },
    addFieldIcon: {
      display: 'none',
      position: 'absolute',
      top: '-28px',
      padding: 0,
      color: theme.palette.grey[700],
      left: '-5px',
    },
    seperatar: {
      display: 'flex',
      justifyContent: 'center',
      height: '44px',
      width: '16px',
      backgroundColor: theme.palette.grey[100],
      borderRadius: '8px',
      position: 'relative',
      border: `1px dashed ${theme.palette.grey[500]}`,
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      '&:hover .addFieldIcon': {
        display: 'block !important',
      },
    },
    invalid: {
      border: `1px dashed ${theme.palette.error.main} !important`,
    },
  });
  const classes = useClasses(styles);
  const {
    fieldData,
    fieldIndex,
    onRemove,
    onEdit,
    onAddFieldAtPosition,
    mode,
    isValid,
  } = props;
  const { key } = fieldData;
  const FieldComponent = FieldKeyToField()[key].component;
  const onDone = React.useCallback(
    (fd: FieldData) => {
      onEdit && onEdit(fieldIndex, fd);
    },
    [fieldIndex, onEdit],
  );

  const onRemoveClick = React.useCallback(() => {
    onRemove && onRemove(fieldIndex);
  }, [fieldIndex, onRemove]);

  const createAddFieldIconClick = React.useCallback(
    (addFieldIndex: number) => {
      return () => onAddFieldAtPosition(addFieldIndex);
    },
    [onAddFieldAtPosition],
  );

  const getPossibleValues = (conditionString: string) => {
    const matchResult = strategyRules.match(
      conditionString,
    ) as FinalMatchResult;
    return getNextKeys(matchResult);
  };

  return (
    <div className={classes.container}>
      {mode === StrategyMode.EDIT ? (
        <div
          className={`${classes.seperatar} ${
            isValid && isValid.status && isValid.errorIndex === fieldIndex - 1
              ? classes.invalid
              : ''
          }`}
        >
          <div className={classes.addFieldLine} />
          <IconButton
            className={`${classes.addFieldIcon} addFieldIcon`}
            color="secondary"
            onClick={createAddFieldIconClick(fieldIndex)}
          >
            <AddCircleSharpIcon fontSize={'medium'} />
          </IconButton>
        </div>
      ) : (
        <></>
      )}
      <FieldComponent
        onEdit={() => ({})}
        onRemove={onRemoveClick}
        fieldData={fieldData}
        getPossibleValues={getPossibleValues}
        mode={mode}
        onEditDone={onDone}
        validateMathDescFindErrorIndex={validateMathDescFindErrorIndex}
        validateMathCondition={validateMathCondition}
        isValid={isValid}
        fieldIndex={fieldIndex}
      />
    </div>
  );
}
