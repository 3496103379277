import { useQuery } from 'react-query';
import { GetUserActiveStatus } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';

export default function useGetUserActiveStatus(
  strategy_id: number,
  type: string,
) {
  const objQuery = useQuery<{ success: boolean }, Errors>(
    [strategy_id, type],
    () => GetUserActiveStatus(strategy_id),
    {
      refetchInterval: 5 * 60 * 1000,
      enabled: type == 'code',
    },
  );
  return objQuery;
}
