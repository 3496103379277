import {
  FormControl,
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { ProfileCapability } from 'types/ApiServicesTypes';
import { SubscriptionTransformPlan } from 'types/SubscriptionPlans';
import PlanInfo from './PlanInfo';
import { useContext } from 'react';
import { themeContext } from 'app/components/Context/ToggleThemeContext';

export type PlanItemRadioProps = {
  variant: 'grey' | 'info' | 'success' | 'purple';
  radioLabel: string;
  value: number;
  checked?: boolean;
  price: number;
  monthToMonth: number;
  capabilities: ProfileCapability[];
  disabled?: boolean;
  currencySymbol: string;
};

const PlanItemRadio = ({
  variant,
  radioLabel,
  value,
  checked,
  price,
  monthToMonth,
  capabilities,
  disabled,
  currencySymbol,
}: PlanItemRadioProps) => {
  const { isDarkTheme } = useContext(themeContext);
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        borderTop: '3px solid',
        borderColor: disabled ? 'grey.400' : `${variant}.dark`,
        backgroundColor: checked
          ? variant === 'grey'
            ? 'grey.300'
            : `${variant}light.main`
          : `grey.100`,
        borderRadius: 1,
        color: 'black',
      }}
    >
      {disabled ? (
        <Typography variant="body1" sx={{ py: '7px', mb: 1 }}>
          {radioLabel}
        </Typography>
      ) : (
        <FormControlLabel
          value={value}
          control={
            <Radio
              sx={{ py: '7px' }}
              color={isDarkTheme ? 'secondary' : 'primary'}
            />
          }
          label={radioLabel}
          sx={{ mb: 1 }}
        />
      )}

      <PlanInfo
        currencySymbol={currencySymbol}
        price={price}
        monthToMonth={monthToMonth}
        capabilities={capabilities}
        disabled={disabled}
      />
    </Box>
  );
};

type PlanRadioGroup = {
  data: SubscriptionTransformPlan[];
  value: number;
  handleChangePlan: (val) => void;
  existingPlanId?: number | undefined;
  currencySymbol: string;
};

const PlanRadioGroup = ({
  data,
  value,
  existingPlanId,
  handleChangePlan,
  currencySymbol,
}: PlanRadioGroup) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup name="plans-radio-buttons-group" value={value}>
        {data.map((plan, i) => (
          <Box
            sx={{ mb: 1.5, cursor: 'pointer' }}
            key={i}
            onClick={() => {
              if (
                plan.plan_id_monthly !== existingPlanId &&
                plan.plan_id_yearly !== existingPlanId
              )
                handleChangePlan(plan.id);
            }}
          >
            <PlanItemRadio
              variant={
                (i + 1) % 3 === 0
                  ? 'purple'
                  : (i + 1) % 3 === 1
                  ? 'success'
                  : 'info'
              }
              radioLabel={plan.plan_name}
              value={plan.id}
              checked={plan.id === value}
              price={plan.recurring_price_yearly}
              monthToMonth={plan.recurring_price_monthly}
              capabilities={plan.capabilities}
              disabled={
                plan.plan_id_monthly === existingPlanId ||
                plan.plan_id_yearly === existingPlanId
              }
              currencySymbol={currencySymbol}
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PlanRadioGroup;
