import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  OptionLegExecutionParam,
  OptionLegParam,
} from 'app/components/Builder/OptionsStrategy/types';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ExecutionReadonlyView from './ExecutionReadonlyView';

interface ExecutionProps {
  ExecutionData: OptionLegParam;
  saveData: (values: Record<string, OptionLegExecutionParam | string>) => void;
  isReadonly: boolean;
  handleClose: (success: boolean) => void;
  positionType: string;
}
interface ExecutionModalProps extends ExecutionProps {
  open: boolean;
}

const ExecutionSettingModal = ({
  ExecutionData,
  saveData,
  open,
  handleClose,
  isReadonly,
  positionType,
}: ExecutionModalProps) => {
  return (
    <MuiFullScreenModal
      open={open}
      width="530px"
      handleClose={handleClose}
      body={
        <ExecutionSettingWrapper
          positionType={positionType}
          handleClose={handleClose}
          ExecutionData={ExecutionData}
          saveData={saveData}
          isReadonly={isReadonly}
        />
      }
      footer={<></>}
    />
  );
};

export const ExecutionCommanForm = ({
  type,
  setFieldValue,
  values,
  error,
  touched,
  handleBlur,
  isReadOnly,
}: {
  type: string;
  setFieldValue: (formValue: OptionLegExecutionParam, type: string) => void;
  values: OptionLegExecutionParam;
  error: FormikErrors<OptionLegExecutionParam> | undefined;
  touched: FormikTouched<OptionLegExecutionParam> | undefined;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  isReadOnly: boolean;
}) => {
  const order_select_type = ['Limit', 'Market'];
  const buffer_select_type = ['Points', 'Percentage'];
  const [isConvertCheckbox, setIsConvertCheckbox] = useState(
    values.convert_to_market_after === 0 ? false : true,
  );
  const isShow = values.order_type === 'limit';
  const keyName =
    type === 'Entry' ? 'entry_execution_settings' : 'exit_execution_settings';
  return (
    <Grid
      container
      direction={isReadOnly ? 'row' : 'column'}
      rowGap={2}
      columnGap={1}
    >
      <Grid item>
        <FormControl fullWidth>
          <InputLabel disabled={!isShow || isReadOnly}>Order Type</InputLabel>
          <Select
            label={`Order Type`}
            onChange={event => {
              setFieldValue(
                { ...values, order_type: event.target.value },
                type,
              );
            }}
            name="order_type"
            value={values.order_type}
            disabled={isReadOnly}
            style={{
              fontSize: '16px',
            }}
          >
            {order_select_type.map(value => {
              return (
                <MenuItem key={value} value={value.toLowerCase()}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel disabled={!isShow || isReadOnly}>Buffer Type</InputLabel>
          <Select
            label={`Buffer Type`}
            onChange={event => {
              setFieldValue(
                { ...values, buffer_type: event.target.value },
                type,
              );
            }}
            name="buffer_type"
            value={values.buffer_type}
            disabled={!isShow || isReadOnly}
            style={{
              fontSize: '16px',
            }}
          >
            {buffer_select_type.map(value => {
              return (
                <MenuItem key={value} value={value.toLowerCase()}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            label={'Limit Buffer'}
            variant="outlined"
            // name={`${keyName}.limit_buffer`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // void setFieldValue('limit_buffer', event.target.value);
              setFieldValue(
                { ...values, limit_buffer: Number(event.target.value) },
                type,
              );
            }}
            type="number"
            value={values.limit_buffer.toString()}
            disabled={!isShow || isReadOnly}
            onBlur={handleBlur}
          />
          {touched?.limit_buffer && error?.limit_buffer && (
            <FormHelperText error>{error.limit_buffer}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            label={'Trigger Buffer'}
            variant="outlined"
            name={`${keyName}.trigger_buffer`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                { ...values, trigger_buffer: Number(event.target.value) },
                type,
              );
            }}
            value={values.trigger_buffer.toString()}
            type="number"
            disabled={!isShow || isReadOnly}
            onBlur={handleBlur}
          />
          {touched?.trigger_buffer && error?.trigger_buffer && (
            <FormHelperText error>{error.trigger_buffer}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      {!isReadOnly && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox checked={isConvertCheckbox} disabled={isReadOnly} />
            }
            label={
              <Box display={'flex'}>
                <Box alignSelf={'center'}>{'convert to market'}</Box>
              </Box>
            }
            disabled={!isShow || isReadOnly}
            onChange={() => {
              setIsConvertCheckbox(!isConvertCheckbox);
              if (!isConvertCheckbox) {
                setFieldValue(
                  {
                    ...values,
                    convert_to_market_after: 10,
                  },
                  type,
                );
              } else {
                setFieldValue(
                  {
                    ...values,
                    convert_to_market_after: 0,
                  },
                  type,
                );
              }
            }}
          />
        </Grid>
      )}
      <Grid item>
        <FormControl fullWidth>
          <TextField
            label={'Convert to Market After'}
            variant="outlined"
            name={`${keyName}.convert_to_market_after`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                {
                  ...values,
                  convert_to_market_after: Number(event.target.value),
                },
                type,
              );
            }}
            type="number"
            disabled={!isShow || isReadOnly || !isConvertCheckbox}
            value={values.convert_to_market_after.toString()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>sec</Typography>
                </InputAdornment>
              ),
            }}
            onBlur={handleBlur}
          />
          {touched?.convert_to_market_after &&
            error?.convert_to_market_after && (
              <FormHelperText error>
                {error.convert_to_market_after}
              </FormHelperText>
            )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const ExecutionSettingWrapper = ({
  ExecutionData,
  saveData,
  handleClose,
  isReadonly,
  positionType,
}: ExecutionProps) => {
  const { t } = useTranslation();
  const isMdup = useGetMediaQueryUp('md');
  const { values, setFieldValue, handleSubmit, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        order_product_type: ExecutionData.order_product_type as string,
        // positionType === 'intraday'
        //   ? (ExecutionData.order_product_type as string)
        //   : 'cnc',
        entry_execution_settings:
          (ExecutionData.entry_execution_settings as OptionLegExecutionParam) ||
          {},
        exit_execution_settings:
          (ExecutionData.exit_execution_settings as OptionLegExecutionParam) ||
          {},
      },
      onSubmit: values => {
        saveData(values);
      },
      validationSchema: Yup.object().shape({
        entry_execution_settings: Yup.object().shape({
          order_type: Yup.string().required(),
          buffer_type: Yup.string().required(),
          limit_buffer: Yup.number().when('orderType', {
            is: (order_type: string) => order_type === 'limit',
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.req', {
                  name: 'Limit Buffer',
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: 'Limit Buffer',
                }),
              ),
          }),
          trigger_buffer: Yup.number().when('orderType', {
            is: (order_type: string) => order_type === 'limit',
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.req', {
                  name: 'Trigger Buffer',
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: 'Trigger Buffer',
                }),
              ),
          }),
          convert_to_market_after: Yup.number().when('orderType', {
            is: (order_type: string) => order_type === 'limit',
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.req', {
                  name: 'Convert to market after',
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: 'Convert to market after',
                }),
              ),
          }),
        }),
        exit_execution_settings: Yup.object().shape({
          order_type: Yup.string().required(),

          buffer_type: Yup.string().required(),
          limit_buffer: Yup.number().when('orderType', {
            is: (order_type: string) => order_type === 'limit',
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.req', {
                  name: 'Limit Buffer',
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: 'Limit Buffer',
                }),
              ),
          }),
          trigger_buffer: Yup.number().when('orderType', {
            is: (order_type: string) => order_type === 'limit',
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.req', {
                  name: 'Trigger Buffer',
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: 'Trigger Buffer',
                }),
              ),
          }),
          convert_to_market_after: Yup.number().when('orderType', {
            is: (order_type: string) => order_type === 'limit',
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.req', {
                  name: 'Convert to market after',
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: 'Convert to market after',
                }),
              ),
          }),
        }),
      }),
    });
  const setField = (fieldValue: OptionLegExecutionParam, type: string) => {
    if (type === 'Entry') {
      void setFieldValue('entry_execution_settings', fieldValue);
    } else if (type === 'Exit') {
      void setFieldValue('exit_execution_settings', fieldValue);
    }
  };
  const [active, setActive] = useState(values.order_product_type);
  const handleActive = (value: string) => {
    setActive(value);
  };
  return (
    <Box className={`${!isReadonly && 'm-main-spacing'}`}>
      {!isReadonly && (
        <Box>
          <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
            <FormattedMessage id="user_bot_startegy.form.execution_settings" />
          </Typography>
        </Box>
      )}
      <Grid
        container
        alignContent={isReadonly ? 'center' : 'flex-end'}
        sx={{
          py: 2,
        }}
        justifyContent={isReadonly ? 'flex-start' : 'space-between'}
        gap={1}
      >
        <Grid item sm={12} md={12} lg={12}>
          <Box
            display={'flex'}
            flexDirection={isReadonly ? 'column' : isMdup ? 'row' : 'column'}
            // justifyContent={'space-between'}
            alignItems={isReadonly ? '' : isMdup ? 'center' : ''}
          >
            <Box flex={!isReadonly ? 1 : 0}>
              <Typography
                variant="body1"
                sx={{ pb: 2, fontWeight: `${!isReadonly && 600}` }}
              >
                Order Product Type
              </Typography>
            </Box>
            <ButtonGroup
              disabled={positionType !== 'intraday' || isReadonly}
              size="small"
              aria-label="small button group"
              orientation="horizontal"
              sx={{
                mb: 2,
                flex: !isReadonly ? 1 : undefined,
                display: 'flex',
                justifyContent: isReadonly ? '' : 'center',
                '& .MuiButton-root': {
                  fontWeight: 'bold',
                  textTransform: 'none',
                  padding: '6px 16px',
                },
              }}
            >
              <Button
                variant={active === 'mis' ? 'contained' : 'outlined'}
                sx={{
                  flex: !isReadonly ? 1 : undefined,
                }}
                size="small"
                onClick={async () => {
                  ExecutionData['productTypeModified'] = 'mis';
                  handleActive('mis');
                  await setFieldValue('order_product_type', 'mis');
                }}
              >
                MIS
              </Button>
              <Button
                variant={active === 'cnc' ? 'contained' : 'outlined'}
                sx={{
                  flex: !isReadonly ? 1 : undefined,
                }}
                size="small"
                onClick={async () => {
                  ExecutionData['productTypeModified'] = 'cnc';
                  await setFieldValue('order_product_type', 'cnc');
                  handleActive('cnc');
                }}
              >
                CNC/NRML
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
        <Grid item sm={!isReadonly && 12} md={!isReadonly && 5}>
          <Typography
            variant="body1"
            sx={{ pb: 2, fontWeight: `${!isReadonly && 600}` }}
          >
            <FormattedMessage id="user_bot_startegy.form.entry_execution" />
          </Typography>
          {!isReadonly ? (
            <ExecutionCommanForm
              type="Entry"
              values={values.entry_execution_settings}
              setFieldValue={setField}
              error={errors.entry_execution_settings}
              touched={touched.entry_execution_settings}
              handleBlur={handleBlur}
              isReadOnly={isReadonly}
            />
          ) : (
            <ExecutionReadonlyView values={values.entry_execution_settings} />
          )}
        </Grid>
        {!isReadonly && isMdup && (
          <ItemSeparator orientation="vertical" variant="2px" />
        )}
        <Grid item sm={!isReadonly && 12} md={!isReadonly && 5}>
          <Typography
            variant="body1"
            sx={{ pb: 2, fontWeight: `${!isReadonly && 600}` }}
          >
            <FormattedMessage id="user_bot_startegy.form.exit_execution" />
          </Typography>
          {!isReadonly ? (
            <ExecutionCommanForm
              type="Exit"
              values={values.exit_execution_settings}
              setFieldValue={setField}
              error={errors.exit_execution_settings}
              touched={touched.exit_execution_settings}
              handleBlur={handleBlur}
              isReadOnly={isReadonly}
            />
          ) : (
            <ExecutionReadonlyView values={values.exit_execution_settings} />
          )}
        </Grid>
      </Grid>
      {!isReadonly && (
        <Box
          sx={{
            textAlign: 'center',
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ mb: { xs: 2, md: 0 } }}
          >
            SAVE
          </MuiButton>
          <MuiButton
            onClick={() => handleClose(true)}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              mr: { xs: 0, md: 2 },
            }}
          >
            Cancel
          </MuiButton>
        </Box>
      )}
    </Box>
  );
};

export default ExecutionSettingModal;
