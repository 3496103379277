import { memo } from 'react';
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { InfoModalProps } from 'types/ComponentTypes';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

const InfoModal = ({ data, open, handleClose, closeBtn }: InfoModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{ '& .MuiPaper-root': { borderRadius: 3, m: 2 } }}
    >
      <DialogContent>
        {data &&
          data
            /* Note : Alphabetical Sorting logic of info block, keep it for future use if any. */
            // .sort((a, b) => {
            //   if (a.title < b.title) {
            //     return -1;
            //   }
            //   if (a.title > b.title) {
            //     return 1;
            //   }
            //   return 0;
            // })
            .map((item, i) => (
              <Box
                key={Math.random()}
                sx={{ mb: data.length - 1 !== i ? 2 : 0 }}
              >
                <Typography
                  variant="body2"
                  fontWeight={400}
                  color="primary.main"
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={400}
                  color="primary.light"
                  sx={{ mt: 0.5 }}
                >
                  {item.desc}
                </Typography>
              </Box>
            ))}
      </DialogContent>
      {closeBtn && (
        <DialogActions>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClose}
            sx={{ boxShadow: 'none', transition: 'none' }}
          >
            <FormattedMessage id="buttons.close" />
          </MuiButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(InfoModal);
