import React, { memo, Fragment } from 'react';
import { useGetTickData } from 'app/hooks/useGetTickData';
import { GetPortfolioBotDetailScripts } from 'types/ApiServicesTypes';
import SymbolsList from 'app/components/Portfolio/SymbolsList';
import SymbolsTable from 'app/design/speedBot/ComponentItem/Portfolio/SymbolsTable';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const PortfolioScripts = ({
  scripts,
}: {
  scripts: GetPortfolioBotDetailScripts[];
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const symbols = React.useMemo(
    () => scripts.map(symbol => symbol.symbol),
    [scripts],
  );
  const liveFeedData = useGetTickData(symbols);
  return (
    <Fragment>
      {isMdUp ? (
        <SymbolsTable symbols={scripts} liveFeedData={liveFeedData} />
      ) : (
        <SymbolsList symbols={scripts} liveFeedData={liveFeedData} />
      )}
    </Fragment>
  );
};

export default memo(PortfolioScripts);
