import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { PostUserBotIsExist } from 'services/apiServices';
import {
  Errors,
  PostUserBotIsExistParams,
  PostUserBotIsExistResult,
} from 'types/ApiServicesTypes';

export default function useGetUserBotsIsExist() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostUserBotIsExistResult,
    Errors,
    Pick<PostUserBotIsExistParams, 'name'>
  >('PostUserBotIsExist', values =>
    PostUserBotIsExist(values)
      .then(res => {
        if (!res || res.status_code !== 200 || res.error)
          showAlert!(
            res.error ? res.error : 'Something went wrong. Please try again.',
          );
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
