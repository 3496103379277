import { memo } from 'react';
import { Typography, Box } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import SubscriptionFeesInfo from './SubscriptionFeesInfo';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';

const SellOnMarketEditConfirmModal = ({
  fees,
  visibility,
  open,
  handleClose,
}: {
  fees: number;
  visibility: string;
  open: boolean;
  handleClose: () => void;
}) => {
  return (
    <>
      <MuiFullScreenModal
        open={open}
        handleClose={handleClose}
        body={
          <>
            <Box className="m-main-spacing">
              <Box sx={{ pb: 3 }}>
                <Typography variant="h6" sx={{ pb: 4 }}>
                  <FormattedMessage id="sell_on_market.all_done" />
                </Typography>
                <Typography variant="body1" fontWeight={400} sx={{ pb: 3 }}>
                  <FormattedMessage id="sell_on_market.subscription_fees_updated" />
                </Typography>
                <SubscriptionFeesInfo
                  fees={fees.toString()}
                  visibility={visibility}
                  infoMessage={
                    <FormattedMessage id="sell_on_market.bot_is_available_to_everyone" />
                  }
                />
              </Box>
            </Box>
          </>
        }
        footer={
          <Box>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.done" />
            </MuiButton>
          </Box>
        }
      />
    </>
  );
};
export default memo(SellOnMarketEditConfirmModal);
