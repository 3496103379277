import { FormControlLabel, Radio, Typography } from '@mui/material';

const SelectableRadio = ({
  checked,
  label,
  value,
}: {
  checked: boolean;
  label: string;
  value: string | number;
}) => {
  return (
    <FormControlLabel
      sx={{
        ...(checked && {
          color: 'text.primary',
        }),
        width: '100%',
      }}
      value={value}
      control={
        <Radio
          checked={checked}
          color="default"
          size="small"
          sx={{
            color: checked ? 'text.primary' : 'text.secondary',
          }}
        />
      }
      label={
        <Typography
          variant="body2"
          color={checked ? 'text.primary' : 'text.secondary'}
        >
          {label}
        </Typography>
      }
    />
  );
};

export default SelectableRadio;
