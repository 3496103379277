import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { GetReportUserActivity } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';

export default function useReportUserAcitivity(
  activity: string,
  data?: Record<string, string>,
  cacheTimeInHours = 2,
  executeOnMount = true,
) {
  const objQuery = useQuery<{ success: boolean }, Errors>(
    [activity, data],
    () => GetReportUserActivity(activity, data),
    {
      staleTime: cacheTimeInHours * 60 * 60 * 1000,
      enabled: executeOnMount,
    },
  );
  const { isStale, refetch } = objQuery;

  const handleReportActivity = useCallback(() => {
    if (isStale) void refetch();
  }, [isStale, refetch]);

  return { ...objQuery, handleReportActivity };
}
