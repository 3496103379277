import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Autocomplete,
  TextField,
  createFilterOptions,
  Typography,
  Theme,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SignalOrderTypes,
  SignalPositionTypes,
  SignalProductTypes,
  SignalTransactionTypes,
  SignalPositionSizingTypes,
  getValueFromLocalStorage,
  persistValueInLocalStorage,
  useGetPositionSizingFeatureFlags,
  OptionsTypes,
  CallPutTypes,
} from './util';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { Instrument } from 'types/Instrument';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import {
  BotSignal,
  BotSignalParams,
  PostBotSignalParams,
} from 'types/ApiServicesTypes';
import { SignalModalTitleData } from './BotGenerateSignalModal';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BotGenerateSignalTemplateContents from './BotGenerateSignalTemplates/BotGenerateSignalTemplateContents';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import {
  checkIsMultipleOf,
  formatNumber,
  getArrowDirByNum,
} from 'utils/GenericFunctions';
import { v4 as uuid } from 'uuid';
import {
  SIGNAL_INSTRUMENT_TYPE,
  SIGNAL_LEVERAGE,
  SIGNAL_ORDER_TYPE,
  SIGNAL_POSITION_SIZING,
} from 'constants/common';
import { useGetTickData } from 'app/hooks/useGetTickData';
import BotSignalUnitBaseComponent from './BotGenerateSignalTemplates/BotSignalUnitBaseComponent';
import { ExpiryTypeValueOptions } from 'app/components/Builder/OptionsStrategy/FieldsData';
import {
  getNextWeekDate,
  NiftySymbols,
} from './BotGenerateSignalTemplates/utils';
// import { useGetTickData } from 'app/hooks/useGetTickData';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

// const filterOptions = createFilterOptions({
//   // matchFrom: 'start',
//   stringify: (option: Instrument) => option.trading_symbol, // make it one for it
//   limit: 500,
// });

const BotGenerateSignalCommonForm = ({
  handleClose,
  handleChangeTitleData,
  isLoading,
  signal,
  type,
  instruments,
  isCrypto,
  isModify,
  isDebugMode,
  onSave,
  signalData,
  isModifyBasket,
}: {
  isCrypto: boolean;
  handleClose: () => void;
  handleChangeTitleData: (data: SignalModalTitleData) => void;
  isLoading: boolean;
  signal?: BotSignal | null;
  type: string;
  instruments: Instrument[];
  isModify: boolean;
  isDebugMode?: boolean;
  onSave: (data: BotSignalParams[], successCallback?: () => void) => void;
  signalData?: BotSignalParams;
  isModifyBasket?: boolean;
}) => {
  const isAdvanceSymbolSelect = useIsFeatureFlag([
    FEATURES.IS_ADVANCE_SYMBOL_SELECT,
  ]);
  const isLots = useIsFeatureFlag([FEATURES.IS_LOTS_FOR_SIGNAL]);
  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option: Instrument) => option.trading_symbol, // make it one for it
    limit: 100,
  });
  const { t } = useTranslation();
  const [signalFormData, setSignalFormData] = useState<BotSignalParams[]>([]);
  const [expiry, setExpiry] = useState('weekly_0');
  const isLotSizeValidation = useIsFeatureFlag([
    FEATURES.IS_LOT_SIZE_VALIDATION,
  ]);

  // const liveFeedData = useGetTickData(fetchSocket ? [fetchSocket] : [], true);
  const isModifySignal = useIsFeatureFlag([FEATURES.IS_MODIFY_SIGNAL]);
  const isSLEntrySignal = useIsFeatureFlag([FEATURES.IS_SL_ENTRY_SIGNAL]);
  const {
    isAbsoluteCapitalSignal,
    isAvailableBalanceSignal,
    isDeployedCapitalSignal,
  } = useGetPositionSizingFeatureFlags();
  const isStopLossMarket = useIsFeatureFlag([FEATURES.IS_STOP_LOSS_MARKET]);
  const selectedInstrument = useMemo(() => {
    if (!signal && !signalData) return null;

    const titleData: SignalModalTitleData = {
      symbol: '',
      qty: signal ? signal.quantity : signalData?.quantity || 1,
      transactionType: signal
        ? signal.transaction_type
        : signalData?.transaction_type || 'BUY',
    };

    if (!instruments) {
      handleChangeTitleData(titleData);
      return null;
    }
    let currentInstrument = instruments.filter(
      x => x.trading_symbol === signal?.trading_symbol,
    )[0];
    if (signalData) {
      currentInstrument = instruments.filter(
        x => x.instrument_id === Number(signalData.instrument_id),
      )[0];
    }
    if (currentInstrument) titleData.symbol = currentInstrument.trading_symbol;

    handleChangeTitleData(titleData);

    return currentInstrument;
  }, [signal, instruments, handleChangeTitleData, signalData]);

  // useEffect(() => {
  //   delete liveFeedData[niftySymbol];
  //   setFetchSocket(niftySymbol);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [niftySymbol]);

  useEffect(() => {
    if (type === 'SINGLE') {
      setSignalFormData([]);
    }
  }, [type]);

  const maxLeverageSize = 125;

  const filteredPositionSizingTypes = SignalPositionSizingTypes.filter(item => {
    if (
      (item.value === 'absolute_capital' && !isAbsoluteCapitalSignal) ||
      (item.value == 'deployed_capital' && !isDeployedCapitalSignal) ||
      (item.value == 'available_balance' && !isAvailableBalanceSignal)
    ) {
      return false;
    }
    return true;
  });
  const [niftySymbol, setNiftySymbol] = useState(NiftySymbols[0].symbol);
  const [fetchSocket, setFetchSocket] = useState<string | null>(niftySymbol);
  const [ltp, setLtp] = useState(
    selectedInstrument ? selectedInstrument.trading_symbol : '',
  );
  const [ltpcsocket, setLtpSocket] = useState<string | null>(ltp);

  const liveFeedData = useGetTickData(fetchSocket ? [fetchSocket] : []);
  const liveLtpData = useGetTickData(ltpcsocket ? [ltpcsocket] : []);
  useEffect(() => {
    delete liveFeedData[niftySymbol];
    setFetchSocket(niftySymbol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [niftySymbol]);
  useEffect(() => {
    delete liveLtpData[ltp];
    setLtpSocket(ltp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltp]);
  const selectedSymbol = useMemo(() => {
    return NiftySymbols.filter(x => x.symbol === niftySymbol)[0];
  }, [niftySymbol]);
  const nextWeekDate = useMemo(() => {
    const instrumentData = instruments.filter(x =>
      selectedSymbol.checkingSymbols.includes(x.name),
    );
    // return getFutureNextDayDate('thursday', expiry === 'weekly_1');
    return getNextWeekDate(
      instrumentData,
      expiry === 'weekly_0'
        ? 0
        : expiry === 'weekly_1'
        ? 1
        : expiry == 'monthly_0'
        ? 2
        : 3,
    );
  }, [expiry, instruments, selectedSymbol.checkingSymbols]);
  const filterNiftySymbols =
    niftySymbol === 'NIFTY_SPACE_50'
      ? 'NIFTY'
      : niftySymbol === 'NIFTY_SPACE_BANK'
      ? 'BANKNIFTY'
      : niftySymbol === 'NIFTY_SPACE_FIN_SPACE_SERVICE'
      ? 'FINNIFTY'
      : niftySymbol === 'SENSEX'
      ? 'SENSEX'
      : niftySymbol === 'BANKEX'
      ? 'BANKEX'
      : 'MIDCPNIFTY';
  const memoFilter = useMemo(() => {
    return instruments
      .filter(
        instrument =>
          instrument.name === filterNiftySymbols &&
          instrument.expiry === nextWeekDate &&
          instrument.instrument_type === 'pe',
      )
      .sort((a, b) => {
        return a.strike_price - b.strike_price;
      });
  }, [filterNiftySymbols, nextWeekDate, instruments]);
  const filterCallback = useCallback(
    (exp, strike, cepe: string) => {
      return instruments.filter(instrument => {
        return (
          exp === instrument.expiry &&
          instrument.name == filterNiftySymbols &&
          instrument.strike_price === strike &&
          instrument.instrument_type === cepe.toLocaleLowerCase()
        );
      })[0];
    },
    [instruments, filterNiftySymbols],
  );
  // const nextWeekDate=()=>{
  //   const instrumentData = instruments.filter(x =>
  //     selectedSymbol.checkingSymbols.includes(x.name),
  //   );
  //   // return getFutureNextDayDate('thursday', expiry === 'weekly_1');
  //    getNextWeekDate(instrumentData, expiry === 'weekly_0' ? 0 : 1);
  // }
  // useEffect(() => {
  //   if (fetchSocket && liveFeedData && liveFeedData[fetchSocket]) {
  //     setSymbolData(prev => {
  //       return {
  //         ...prev,
  //         [fetchSocket]: liveFeedData[fetchSocket],
  //       };
  //     });
  //     setFetchSocket(null);
  //   }
  // }, [fetchSocket, liveFeedData]);

  // const contractSelectionOptions = useMemo(() => {
  //   return createContractSelectionOptions(niftySymbol, 'strike', 'call');
  // }, [niftySymbol]);
  // const filters = (date, value, cp) => {
  //   return instruments.filter(instrument => {
  //     return (
  //       date === instrument.expiry &&
  //       instrument.strike_price === value &&
  //       instrument.instrument_type ===
  //         // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //         cp.toLocaleLowerCase()
  //     );
  //   });
  // };

  // Now, uniqueStrikePrices is a Set containing unique strike prices
  // If you need it as an array, you can convert it back
  const filteredOrderType = SignalOrderTypes.filter(item => {
    return isStopLossMarket
      ? SignalOrderTypes
      : item.value !== 'STOP_LOSS_MARKET';
  });
  return (
    <Formik
      enableReinitialize
      initialValues={{
        signalId:
          signalData?.signal_id || signal?.signal_id
            ? signalData?.signal_id || signal?.signal_id
            : '',
        symbol: selectedInstrument,
        value: selectedInstrument?.strike_price || 0,
        productType: signalData?.product_type || signal?.product_type || 'MIS',
        leverage:
          signal?.leverage ||
          signalData?.leverage ||
          parseInt(getValueFromLocalStorage(SIGNAL_LEVERAGE)) ||
          25,
        optionType:
          selectedInstrument?.instrument_type === 'eq'
            ? 'Equity'
            : selectedInstrument?.instrument_type === 'ce' ||
              selectedInstrument?.instrument_type === 'pe'
            ? 'Option'
            : selectedInstrument?.instrument_type === 'fut'
            ? 'Future'
            : getValueFromLocalStorage(SIGNAL_INSTRUMENT_TYPE) || 'Equity',
        callPutType:
          selectedInstrument?.instrument_type.toLocaleUpperCase() || 'PE',
        positionSizing:
          signal?.position_sizing ||
          signalData?.position_sizing ||
          getValueFromLocalStorage(SIGNAL_POSITION_SIZING) ||
          'quantity',
        orderType:
          signal?.order_type ||
          signalData?.order_type ||
          getValueFromLocalStorage(SIGNAL_ORDER_TYPE) ||
          'MARKET',
        transactionType:
          signal?.transaction_type || signalData?.transaction_type || 'BUY',
        quantity: isLots
          ? (signal?.quantity || 1) / (selectedInstrument?.lot_size || 1) ||
            (signalData?.quantity || 1) / (selectedInstrument?.lot_size || 1)
          : signal?.quantity || signalData?.quantity || 1,
        orderTag: signal?.tag || signalData?.tag || '',
        tradeId: signal?.trade_id || signalData?.trade_id || '',
        positionType:
          signal?.position_type || signalData?.position_type || 'EN',
        orderValidity: 0,
        price: signal?.order_price || signalData?.order_price || 0,
        order_trigger_price:
          signal?.order_trigger_price || signalData?.order_trigger_price || 0,
        type: type,
        isTemplate: false,
        stoploss: {
          exit_order_type: 'STOP_LOSS',
          enabled: false,
          price: 0,
          trigger: 0,
          ...signal?.stoploss,
          ...signalData?.stoploss,
        },
        target: {
          exit_order_type: 'LIMIT',
          enabled: false,
          price: 0,
          trigger: 0,
          ...signal?.target,
          ...signalData?.target,
        },
      }}
      validationSchema={Yup.object().shape({
        // signalId: Yup.string().required('Signal ID is required'),
        tradeId: Yup.string().required('Trade ID is required'),
        symbol: Yup.object().nullable().required('Symbol is required'),
        leverage: Yup.number().min(1).max(maxLeverageSize),
        positionSizing: Yup.string(),
        quantity: Yup.number().when(
          ['symbol', 'positionSizing'],
          (...params) => {
            const symbol = params[0] as Instrument;
            const isQuantityBased = (params[1] as string) === 'quantity';
            const schema = params[2] as Yup.NumberSchema;
            const validationParam = isQuantityBased
              ? t('bot_signals.form.quantity')
              : t('bot_signals.template_form.value');
            if (isLotSizeValidation && symbol != null && isQuantityBased) {
              return schema
                .min(
                  isLots ? 1 : symbol.lot_size,
                  t('bot_signals.form.invalid_quantity', {
                    quantity: isLots ? 1 : symbol.lot_size,
                  }),
                )
                .test(
                  'is-multiplier',
                  t('bot_signals.form.invalid_quantity_multiple', {
                    quantity: isLots ? 1 : symbol.lot_size,
                  }),
                  value => {
                    return isCrypto
                      ? true
                      : checkIsMultipleOf(
                          value || 0,
                          isLots ? 1 : symbol.lot_size,
                        );
                  },
                )
                .required(
                  t('user_backtesting.valid_msg.req', {
                    name: validationParam,
                  }),
                );
            }
            return schema
              .min(
                0,
                t('user_backtesting.valid_msg.valid', {
                  name: validationParam,
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: validationParam,
                }),
              );
          },
        ),
        // orderTag: Yup.string().required(
        //   t('user_backtesting.valid_msg.req', {
        //     name: t('bot_signals.form.order_tag'),
        //   }),
        // ),
        orderValidity: Yup.number()
          .min(
            0,
            t('user_backtesting.valid_msg.valid', {
              name: t('bot_signals.form.order_validity'),
            }),
          )
          .required(
            t('user_backtesting.valid_msg.req', {
              name: t('bot_signals.form.order_validity'),
            }),
          ),
        price: Yup.number().when('orderType', {
          is: (orderType: string) =>
            ['MARKET', 'STOP_LOSS_MARKET'].includes(orderType),
          then: Yup.number()
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('bot_signals.form.price'),
              }),
            )
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('bot_signals.form.price'),
              }),
            ),
        }),
        order_trigger_price: Yup.number().when('orderType', {
          is: (orderType: string) =>
            !['MARKET', 'STOP_LOSS_MARKET'].includes(orderType),
          then: Yup.number()
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('bot_signals.form.trigger_price'),
              }),
            )
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('bot_signals.form.trigger_price'),
              }),
            ),
        }),
        target: Yup.object().shape({
          enabled: Yup.boolean(),
          price: Yup.number().when(['enabled', 'exit_order_type'], {
            is: (enabled: boolean, exit_order_type: string) =>
              enabled && !['STOP_LOSS_MARKET'].includes(exit_order_type),
            then: Yup.number()
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: t('summary_labels.value'),
                }),
              )
              .test({
                test: value => value !== 0,
                message: t('user_backtesting.valid_msg.valid', {
                  name: t('summary_labels.value'),
                }),
              }),
            otherwise: Yup.number(),
          }),
          trigger: Yup.number().when(['enabled', 'exit_order_type'], {
            is: (enabled: boolean, exit_order_type: string) =>
              enabled && ['STOP_LOSS_MARKET'].includes(exit_order_type),
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.valid', {
                  name: t('summary_labels.value'),
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: t('summary_labels.value'),
                }),
              ),
            otherwise: Yup.number().nullable(),
          }),
        }),
        stoploss: Yup.object().shape({
          enabled: Yup.boolean(),
          price: Yup.number().when(['enabled', 'exit_order_type'], {
            is: (enabled: boolean, exit_order_type: string) =>
              enabled && !['STOP_LOSS_MARKET'].includes(exit_order_type),
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.valid', {
                  name: t('summary_labels.value'),
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: t('summary_labels.value'),
                }),
              )
              .test({
                test: value => value !== 0,
                message: t('user_backtesting.valid_msg.valid', {
                  name: t('summary_labels.value'),
                }),
              }),
            otherwise: Yup.number(),
          }),
          trigger: Yup.number().when('enabled', {
            is: enabled => enabled,
            then: Yup.number()
              .positive(
                t('user_backtesting.valid_msg.valid', {
                  name: t('summary_labels.value'),
                }),
              )
              .required(
                t('user_backtesting.valid_msg.req', {
                  name: t('summary_labels.value'),
                }),
              ),
          }),
        }),
      })}
      onSubmit={(values, actions) => {
        const req: PostBotSignalParams = {
          signals: [
            {
              signal_id: values.signalId || uuid(),
              instrument_id: values.symbol!.instrument_id as unknown as string,
              symbol: values.symbol!.trading_symbol,
              product_type: isCrypto ? '' : values.productType,
              order_type: values.orderType,
              trade_id: values.tradeId,
              transaction_type: values.transactionType,
              order_duration: 'DAY',
              quantity: isLots
                ? values.quantity * values.symbol!.lot_size
                : values.quantity || 0,
              tag: values.orderTag
                ? values.orderTag
                : `${values.tradeId} ${values.positionType}`,
              position_type: values.positionType,
              validity: values.orderValidity
                ? values.orderValidity.toString()
                : '',
              order_price: values.price ? values.price.toString() : '',
              target: values.target,
              stoploss: values.stoploss,
              leverage: values.leverage,
              position_sizing: values.positionSizing,
              order_trigger_price: values.order_trigger_price
                ? values.order_trigger_price.toString()
                : '',
            },
          ],
        };
        persistValueInLocalStorage(SIGNAL_INSTRUMENT_TYPE, values.optionType);
        persistValueInLocalStorage(SIGNAL_ORDER_TYPE, values.orderType);
        persistValueInLocalStorage(SIGNAL_LEVERAGE, values.leverage.toString());
        persistValueInLocalStorage(
          SIGNAL_POSITION_SIZING,
          values.positionSizing,
        );
        onSave(req.signals, () => {
          actions.resetForm();
        });
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange,
        touched,
        isValid,
        resetForm,
        validateForm,
      }) => {
        const isSingle = type === 'SINGLE';
        const isSLOrderType = values.orderType === 'STOP_LOSS';
        const quantityLabel =
          values.positionSizing === 'quantity'
            ? values.symbol?.lot_size !== undefined &&
              values.symbol.lot_size > 0
              ? `${!isLots ? 'Quantity ' : 'Lots '}(1 Lot = ${
                  values.symbol.lot_size
                } Qty)`
              : !isLots
              ? 'Quantity '
              : 'Lots '
            : 'Value';
        return (
          <>
            <Box
              className="m-main-spacing"
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 2.25rem)',
                pt: 1,
                position: 'relative',
              }}
            >
              <CircularLoader open={isLoading} />
              <FeatureFlag
                features={[FEATURES.IS_SIGNAL_TEMPLATE]}
                fallback={<></>}
              >
                {type === 'MULTIPLE' && (
                  <FormGroup sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox name="isTemplate" onChange={handleChange} />
                      }
                      checked={values.isTemplate}
                      label={`${t('bot_signals.create_using_template')}`}
                    />
                  </FormGroup>
                )}
              </FeatureFlag>

              {!values.isTemplate ? (
                <Box
                  sx={{
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.optionType && errors.optionType,
                          )}
                          variant="outlined"
                          sx={{ mb: 3 }}
                          disabled={isModify}
                        >
                          <InputLabel htmlFor="label-options">
                            <FormattedMessage id="bot_signals.form.options" />
                          </InputLabel>
                          <Select
                            id="label-options"
                            onChange={e => {
                              setFieldValue('optionType', e.target.value);
                              setFieldValue('symbol', selectedInstrument);
                            }}
                            name={'optionType'}
                            value={values.optionType}
                            label={t('bot_signals.form.options')}
                            endAdornment={
                              <InputAdornment position="end" sx={{ mr: 1 }}>
                                <CustomTooltip
                                  tooltipKey={'signal_instrument_type'}
                                >
                                  {({ isTooltipAvailable }) =>
                                    isTooltipAvailable ? (
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </CustomTooltip>
                              </InputAdornment>
                            }
                          >
                            {OptionsTypes.map(item => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.optionType && errors.optionType && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.optionType}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {!isModify &&
                      values.optionType === 'Option' &&
                      isAdvanceSymbolSelect ? (
                        <>
                          <Grid item xs={12} md={6} lg={12}>
                            <FormControl
                              fullWidth
                              sx={{
                                mb: { xs: 3, md: 2 },
                                position: 'relative',
                              }}
                            >
                              <InputLabel id="label-value1">
                                {t('bot_signals.form.underlying')}
                              </InputLabel>
                              <Select
                                labelId="label-value1"
                                name="value1"
                                value={niftySymbol}
                                disabled={isModify}
                                label={t('bot_signals.form.underlying')}
                                onChange={e => {
                                  setNiftySymbol(e.target.value);
                                  // setFieldValue('')

                                  setFieldValue('value', e.target.value);
                                }}
                              >
                                {NiftySymbols.map(symbol => (
                                  <MenuItem
                                    value={symbol.symbol}
                                    key={symbol.symbol}
                                  >
                                    {symbol.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {liveFeedData &&
                                liveFeedData[niftySymbol] &&
                                liveFeedData[niftySymbol].Close && (
                                  <Box
                                    className="extra-label"
                                    sx={{
                                      color: liveFeedData[niftySymbol]
                                        ? liveFeedData[niftySymbol].Close -
                                            liveFeedData[niftySymbol].Open >
                                          0
                                          ? 'success.main'
                                          : 'error.main'
                                        : 'text.primary',
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {liveFeedData[niftySymbol]
                                        ? `${getArrowDirByNum(
                                            liveFeedData[niftySymbol].Close -
                                              liveFeedData[niftySymbol].Open,
                                          )} ₹${formatNumber(
                                            liveFeedData[niftySymbol].Close,
                                            false,
                                            2,
                                          )}`
                                        : `₹0`}
                                    </Typography>
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="label-expiry">
                                {t('bot_signals.template_form.expiry')}
                              </InputLabel>
                              <Select
                                labelId="label-expiry"
                                name="expiry"
                                value={expiry}
                                label={t('bot_signals.template_form.expiry')}
                                onChange={e => {
                                  const val = e.target.value;
                                  setExpiry(() => {
                                    const instrumentData = instruments.filter(
                                      x =>
                                        selectedSymbol.checkingSymbols.includes(
                                          x.name,
                                        ),
                                    );
                                    const updatedNextWeekDate = getNextWeekDate(
                                      instrumentData,
                                      val === 'weekly_0'
                                        ? 0
                                        : val == 'weekly_1'
                                        ? 1
                                        : val == 'monthly_0'
                                        ? 2
                                        : 3,
                                    );

                                    const res = setFieldValue(
                                      'symbol',
                                      filterCallback(
                                        updatedNextWeekDate,
                                        values.value,
                                        values.callPutType,
                                      ),
                                    );
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
                                    (res as any).then(() => {
                                      void validateForm();
                                    });
                                    // Rest of your code...

                                    return val;
                                  });
                                }}
                                endAdornment={
                                  <InputAdornment position="end" sx={{ mr: 1 }}>
                                    <CustomTooltip tooltipKey={'signal_expiry'}>
                                      {({ isTooltipAvailable }) =>
                                        isTooltipAvailable ? (
                                          <IconButton>
                                            <InfoOutlinedIcon />
                                          </IconButton>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </CustomTooltip>
                                  </InputAdornment>
                                }
                              >
                                {ExpiryTypeValueOptions.map(interval => (
                                  <MenuItem
                                    value={interval.key}
                                    key={interval.key}
                                  >
                                    {getNextWeekDate(
                                      instruments.filter(x =>
                                        selectedSymbol.checkingSymbols.includes(
                                          x.name,
                                        ),
                                      ),
                                      interval.key == 'weekly_0'
                                        ? 0
                                        : interval.key == 'weekly_1'
                                        ? 1
                                        : interval.key == 'monthly_0'
                                        ? 2
                                        : 3,
                                    )}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Box className="extra-label">
                                <Typography variant="body2"></Typography>
                              </Box>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                              <InputLabel id="label-value">
                                {`${t('bot_signals.form.strike_price')}`}
                              </InputLabel>
                              <Select
                                labelId="label-value"
                                name="value"
                                value={values.value}
                                label={`${t('bot_signals.form.strike_price')}`}
                                onChange={e => {
                                  const val = e.target.value as number;

                                  // const newSymbol = instruments.filter(
                                  //   instrument => {
                                  //     // eslint-disable-next-line no-console

                                  //     return (
                                  //       nextWeekDate === instrument.expiry &&
                                  //       instrument.name == filterNiftySymbols &&
                                  //       instrument.strike_price === val &&
                                  //       instrument.instrument_type ===
                                  //         values.callPutType.toLocaleLowerCase()
                                  //     );
                                  //   },
                                  // )[0];
                                  const newSymbol = filterCallback(
                                    nextWeekDate,
                                    val,
                                    values.callPutType,
                                  );
                                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                  setFieldValue('value', val);
                                  setFieldValue('symbol', newSymbol);
                                  setFieldValue(
                                    'quantity',
                                    isLots ? 1 : newSymbol.lot_size,
                                  );
                                  setLtp(newSymbol.trading_symbol);
                                }}
                                endAdornment={
                                  <InputAdornment position="end" sx={{ mr: 1 }}>
                                    <CustomTooltip
                                      tooltipKey={'signal_strike_price'}
                                    >
                                      {({ isTooltipAvailable }) =>
                                        isTooltipAvailable ? (
                                          <IconButton>
                                            <InfoOutlinedIcon />
                                          </IconButton>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </CustomTooltip>
                                  </InputAdornment>
                                }
                              >
                                {memoFilter.map(interval => {
                                  return (
                                    <MenuItem
                                      value={interval.strike_price}
                                      key={interval.instrument_id}
                                    >
                                      {interval.strike_price}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            {/* <ButtonGroup
                              fullWidth
                              size="large"
                              variant="outlined"
                              orientation="horizontal"
                              sx={{ height: 'auto', minHeight: '3.4375em' }}
                            >
                              <Button>PE</Button>
                              <Button>CE</Button>
                            </ButtonGroup> */}
                            <Box sx={{ mb: 3 }}>
                              <Box display="flex" gap={2}>
                                {values.value !== 0 &&
                                  CallPutTypes.map(item => (
                                    <MuiButton
                                      onClick={() => {
                                        setFieldValue(
                                          'callPutType',
                                          item.value,
                                        );
                                        const newSymbol = filterCallback(
                                          nextWeekDate,
                                          values.value,
                                          item.value,
                                        );
                                        setFieldValue('symbol', newSymbol);
                                        setFieldValue(
                                          'quantity',
                                          isLots ? 1 : newSymbol.lot_size,
                                        );
                                        setLtp(newSymbol.trading_symbol);
                                        // handleChangeTitleData({
                                        //   qty: values.quantity ? values.quantity : 0,
                                        //   symbol: values.symbol
                                        //     ? values.symbol.trading_symbol
                                        //     : '',
                                        //   transactionType: item.value,
                                        // });
                                      }}
                                      key={item.value}
                                      variant="outlined"
                                      color="secondary"
                                      sx={{
                                        flex: 1,
                                        height: 'auto',
                                        minHeight: '3.5rem',
                                        color: 'primary.main',
                                        borderColor: (theme: Theme) =>
                                          item.value === values.callPutType
                                            ? `${theme.palette.primary.main} !important`
                                            : 'grey.200',
                                        maxWidth: {
                                          xs: 'inherit',
                                          md: 'fit-content',
                                        },
                                      }}
                                      disabled={
                                        isModify &&
                                        item.value !== values.callPutType
                                      }
                                    >
                                      {item.label}
                                    </MuiButton>
                                  ))}
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        !isModify &&
                        values.optionType === 'Future' &&
                        isAdvanceSymbolSelect && (
                          <>
                            <Grid item xs={12} md={6} lg={12}>
                              <FormControl
                                fullWidth
                                sx={{
                                  mb: { xs: 3, md: 2 },
                                  position: 'relative',
                                }}
                              >
                                <InputLabel id="label-value1">
                                  {t('bot_signals.form.underlying')}
                                </InputLabel>
                                <Select
                                  labelId="label-value1"
                                  name="value1"
                                  value={niftySymbol}
                                  label={t('bot_signals.form.underlying')}
                                  onChange={e => {
                                    setNiftySymbol(e.target.value);
                                    setExpiry('');
                                    setFieldValue('symbol', selectedInstrument);
                                  }}
                                >
                                  {NiftySymbols.map(symbol => (
                                    <MenuItem
                                      value={symbol.symbol}
                                      key={symbol.symbol}
                                    >
                                      {symbol.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {liveFeedData &&
                                  liveFeedData[niftySymbol] &&
                                  liveFeedData[niftySymbol].Close && (
                                    <Box
                                      className="extra-label"
                                      sx={{
                                        color: liveFeedData[niftySymbol]
                                          ? liveFeedData[niftySymbol].Close -
                                              liveFeedData[niftySymbol].Open >
                                            0
                                            ? 'success.main'
                                            : 'error.main'
                                          : 'text.primary',
                                      }}
                                    >
                                      <Typography variant="body2">
                                        {liveFeedData[niftySymbol]
                                          ? `${getArrowDirByNum(
                                              liveFeedData[niftySymbol].Close -
                                                liveFeedData[niftySymbol].Open,
                                            )} ₹${formatNumber(
                                              liveFeedData[niftySymbol].Close,
                                              false,
                                              0,
                                            )}`
                                          : `₹0`}
                                      </Typography>
                                    </Box>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="label-expiry">
                                  {t('bot_signals.template_form.expiry')}
                                </InputLabel>
                                <Select
                                  labelId="label-expiry"
                                  name="expiry"
                                  value={expiry}
                                  label={t('bot_signals.template_form.expiry')}
                                  onChange={e => {
                                    const val = e.target.value;
                                    setExpiry(() => {
                                      const instrumentData = instruments.filter(
                                        x =>
                                          selectedSymbol.checkingSymbols.includes(
                                            x.name,
                                          ),
                                      );
                                      const updatedNextWeekDate =
                                        getNextWeekDate(
                                          instrumentData,
                                          val === 'weekly_0'
                                            ? 0
                                            : val == 'weekly_1'
                                            ? 1
                                            : val == 'monthly_0'
                                            ? 2
                                            : 3,
                                        );

                                      const res = setFieldValue(
                                        'symbol',
                                        instruments.filter(instrument => {
                                          return (
                                            instrument.instrument_type ===
                                              'fut' &&
                                            updatedNextWeekDate ===
                                              instrument.expiry
                                          );
                                        })[0],
                                      );
                                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
                                      (res as any).then(() => {
                                        void validateForm();
                                      });
                                      // Rest of your code...

                                      return val;
                                    });
                                  }}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{ mr: 1 }}
                                    >
                                      <CustomTooltip
                                        tooltipKey={'signal_expiry'}
                                      >
                                        {({ isTooltipAvailable }) =>
                                          isTooltipAvailable ? (
                                            <IconButton>
                                              <InfoOutlinedIcon />
                                            </IconButton>
                                          ) : (
                                            <></>
                                          )
                                        }
                                      </CustomTooltip>
                                    </InputAdornment>
                                  }
                                >
                                  {ExpiryTypeValueOptions.map(interval => (
                                    <MenuItem
                                      value={interval.key}
                                      key={interval.key}
                                    >
                                      {getNextWeekDate(
                                        instruments.filter(x =>
                                          selectedSymbol.checkingSymbols.includes(
                                            x.name,
                                          ),
                                        ),
                                        interval.key == 'weekly_0'
                                          ? 0
                                          : interval.key == 'weekly_1'
                                          ? 1
                                          : interval.key == 'monthly_0'
                                          ? 2
                                          : 3,
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box className="extra-label">
                                  <Typography variant="body2"></Typography>
                                </Box>
                              </FormControl>
                            </Grid>
                          </>
                        )
                      )}
                      <Grid item xs={12}>
                        {
                          <FormControl
                            fullWidth
                            sx={{ mb: 3 }}
                            error={Boolean(touched.symbol && errors.symbol)}
                            disabled={isModify}
                          >
                            <Autocomplete
                              fullWidth
                              blurOnSelect
                              // endAdornment={
                              //   <InputAdornment position='end'>
                              //     <SearchOff />
                              //   </InputAdornment>
                              // }

                              value={values.symbol}
                              getOptionLabel={option =>
                                ` ${option.trading_symbol}  ( ${option.name} )`
                              }
                              filterOptions={filterOptions}
                              onChange={(
                                event: React.SyntheticEvent,
                                newSymbol: Instrument | null,
                              ) => {
                                setLtp(
                                  newSymbol?.trading_symbol
                                    ? newSymbol?.trading_symbol
                                    : '',
                                );
                                if (
                                  isLotSizeValidation &&
                                  newSymbol != null &&
                                  values.positionSizing === 'quantity'
                                ) {
                                  setFieldValue(
                                    'quantity',
                                    isLots ? 1 : newSymbol.lot_size,
                                  );
                                }
                                setFieldValue('symbol', newSymbol);
                                handleChangeTitleData({
                                  qty: values.quantity ? values.quantity : 0,
                                  symbol: newSymbol
                                    ? newSymbol.trading_symbol
                                    : '',
                                  transactionType: values.transactionType,
                                });
                              }}
                              options={
                                isAdvanceSymbolSelect &&
                                values.optionType === 'Option'
                                  ? instruments.filter(instrument => {
                                      return (
                                        nextWeekDate === instrument.expiry &&
                                        instrument.strike_price ===
                                          values.value &&
                                        instrument.instrument_type ===
                                          values.callPutType.toLocaleLowerCase() &&
                                        instrument.name === filterNiftySymbols
                                      );
                                    })
                                  : isAdvanceSymbolSelect &&
                                    values.optionType === 'Future'
                                  ? instruments.filter(
                                      instrument =>
                                        instrument.instrument_type === 'fut' &&
                                        nextWeekDate === instrument.expiry,
                                    )
                                  : values.optionType === 'Equity'
                                  ? instruments.filter(
                                      instrument =>
                                        instrument.instrument_type === 'eq',
                                    )
                                  : values.optionType === 'Option'
                                  ? instruments.filter(
                                      instrument =>
                                        instrument.instrument_type === 'pe' ||
                                        instrument.instrument_type === 'ce',
                                    )
                                  : instruments.filter(
                                      instrument =>
                                        instrument.instrument_type === 'fut',
                                    )
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={t('user_bot_startegy.form.symbol')}
                                  error={Boolean(
                                    touched.symbol && errors.symbol,
                                  )}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {liveLtpData &&
                                          liveLtpData[
                                            values.symbol?.trading_symbol
                                              ? values.symbol?.trading_symbol
                                              : ''
                                          ] &&
                                          liveLtpData[
                                            values.symbol?.trading_symbol
                                              ? values.symbol?.trading_symbol
                                              : ''
                                          ].Close && (
                                            <Box
                                              className="extra-label"
                                              sx={{
                                                color: liveLtpData[
                                                  values.symbol?.trading_symbol
                                                    ? values.symbol
                                                        ?.trading_symbol
                                                    : ''
                                                ]
                                                  ? liveLtpData[
                                                      values.symbol
                                                        ?.trading_symbol
                                                        ? values.symbol
                                                            ?.trading_symbol
                                                        : ''
                                                    ].Close -
                                                      liveLtpData[
                                                        values.symbol
                                                          ?.trading_symbol
                                                          ? values.symbol
                                                              ?.trading_symbol
                                                          : ''
                                                      ].Open >
                                                    0
                                                    ? 'success.main'
                                                    : 'error.main'
                                                  : 'text.primary',
                                              }}
                                            >
                                              <Typography variant="body2">
                                                {liveLtpData[
                                                  values.symbol?.trading_symbol
                                                    ? values.symbol
                                                        ?.trading_symbol
                                                    : ''
                                                ]
                                                  ? `${getArrowDirByNum(
                                                      liveLtpData[
                                                        values.symbol
                                                          ?.trading_symbol
                                                          ? values.symbol
                                                              ?.trading_symbol
                                                          : ''
                                                      ].Close -
                                                        liveLtpData[
                                                          values.symbol
                                                            ?.trading_symbol
                                                            ? values.symbol
                                                                ?.trading_symbol
                                                            : ''
                                                        ].Open,
                                                    )} ₹${formatNumber(
                                                      liveLtpData[
                                                        values.symbol
                                                          ?.trading_symbol
                                                          ? values.symbol
                                                              ?.trading_symbol
                                                          : ''
                                                      ].Close,
                                                      false,
                                                      2,
                                                    )}`
                                                  : `₹0`}
                                              </Typography>
                                            </Box>
                                          )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.instrument_id}>
                                    {`${option.trading_symbol} ( ${option.name} )`}
                                  </li>
                                );
                              }}
                              disabled={isModify}
                            />
                            {touched.symbol && errors.symbol && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.symbol}
                              </FormHelperText>
                            )}
                          </FormControl>
                        }
                      </Grid>

                      <FeatureFlag
                        features={[FEATURES.IS_LEVERAGE_SIGNAL]}
                        fallback={<></>}
                      >
                        <Grid item xs={12} lg={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.leverage && errors.leverage)}
                            variant="outlined"
                            sx={{ mb: 3 }}
                            disabled={isModify}
                          >
                            <InputLabel htmlFor="label-leverage">
                              <FormattedMessage id="bot_signals.form.leverage" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-leverage"
                              name="leverage"
                              type="number"
                              value={values.leverage}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={t('bot_signals.form.leverage')}
                              inputProps={{
                                min: 1,
                                max: maxLeverageSize,
                                style: { textAlign: 'center' },
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'leverage',
                                        values.leverage >= maxLeverageSize
                                          ? maxLeverageSize
                                          : values.leverage + 1,
                                      )
                                    }
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              startAdornment={
                                <InputAdornment position="start">
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'leverage',
                                        values.leverage <= 1
                                          ? 1
                                          : values.leverage - 1,
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {touched.leverage && errors.leverage && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.leverage}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </FeatureFlag>
                      {filteredPositionSizingTypes.length > 1 && (
                        <Grid item xs={6} lg={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.positionSizing && errors.positionSizing,
                            )}
                            variant="outlined"
                            sx={{ mb: 3 }}
                            disabled={isModify}
                          >
                            <InputLabel htmlFor="label-position-sizing">
                              <FormattedMessage id="bot_signals.form.position_sizing" />
                            </InputLabel>
                            <Select
                              id="label-position-sizing"
                              onChange={handleChange}
                              name={'positionSizing'}
                              value={values.positionSizing}
                              label={t('bot_signals.form.position_sizing')}
                            >
                              {filteredPositionSizingTypes.map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.positionSizing && errors.positionSizing && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.positionSizing}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={isSLOrderType ? 12 : 6}
                        lg={isSLOrderType ? 3 : 4}
                      >
                        <FormControl
                          fullWidth
                          error={Boolean(touched.quantity && errors.quantity)}
                          variant="outlined"
                          sx={{ mb: 3 }}
                          disabled={isModify}
                        >
                          <InputLabel htmlFor="label-quantity">
                            {quantityLabel}
                          </InputLabel>
                          <OutlinedInput
                            id="label-quantity"
                            name="quantity"
                            type="number"
                            value={values.quantity}
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e);
                              handleChangeTitleData({
                                qty: e.target.value
                                  ? parseInt(e.target.value)
                                  : 0,
                                symbol: values.symbol
                                  ? values.symbol.trading_symbol
                                  : '',
                                transactionType: values.transactionType,
                              });
                            }}
                            label={quantityLabel}
                            inputProps={{
                              ...(isLotSizeValidation
                                ? {
                                    min: '0',
                                    step:
                                      (values.positionSizing == 'quantity' &&
                                        !isLots &&
                                        values.symbol?.lot_size) ||
                                      1,
                                  }
                                : { min: '1' }),
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <CustomTooltip tooltipKey={'signal_quantity'}>
                                  {({ isTooltipAvailable }) =>
                                    isTooltipAvailable ? (
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </CustomTooltip>
                              </InputAdornment>
                            }
                          />
                          {touched.quantity && errors.quantity && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.quantity}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {(isSLOrderType ||
                        values.orderType == 'STOP_LOSS_MARKET') && (
                        <Grid item xs={6} lg={isSLOrderType ? 3 : 4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.order_trigger_price &&
                                errors.order_trigger_price,
                            )}
                            variant="outlined"
                            sx={{ mb: 3 }}
                          >
                            <InputLabel htmlFor="label-order-limit-price">
                              <FormattedMessage id="bot_signals.form.trigger_price" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-order-trigger-price"
                              name="order_trigger_price"
                              type="number"
                              value={values.order_trigger_price}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={t('bot_signals.form.trigger_price')}
                              inputProps={{ min: '0' }}
                              disabled={isModify}
                            />
                            {touched.order_trigger_price &&
                              errors.order_trigger_price && (
                                <FormHelperText error sx={{ mx: 0 }}>
                                  {errors.order_trigger_price}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      )}
                      {values.orderType !== 'STOP_LOSS_MARKET' && (
                        <Grid item xs={6} lg={isSLOrderType ? 3 : 4}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.price && errors.price)}
                            variant="outlined"
                            sx={{ mb: 3 }}
                          >
                            <InputLabel htmlFor="label-price">
                              <FormattedMessage id="bot_signals.form.limit_price" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-price"
                              name="price"
                              type="number"
                              value={values.price}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={t('bot_signals.form.limit_price')}
                              inputProps={{ min: '0' }}
                              disabled={
                                ['MARKET', 'STOP_LOSS_MARKET'].includes(
                                  values.orderType,
                                ) || isModify
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <CustomTooltip tooltipKey={'signal_price'}>
                                    {({ isTooltipAvailable }) =>
                                      isTooltipAvailable ? (
                                        <IconButton>
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  </CustomTooltip>
                                </InputAdornment>
                              }
                            />
                            {touched.price && errors.price && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.price}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} lg={isSLOrderType ? 3 : 4}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.tradeId && errors.tradeId)}
                          variant="outlined"
                          sx={{ mb: 3 }}
                          disabled={isModify}
                        >
                          <InputLabel htmlFor="label-tradeId">
                            <FormattedMessage id="bot_signals.form.trade_id" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-tradeId"
                            name="tradeId"
                            value={values.tradeId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={t('bot_signals.form.trade_id')}
                            endAdornment={
                              <InputAdornment position="end">
                                <CustomTooltip tooltipKey={'signal_trade_id'}>
                                  {({ isTooltipAvailable }) =>
                                    isTooltipAvailable ? (
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </CustomTooltip>
                              </InputAdornment>
                            }
                          />
                          {touched.tradeId && errors.tradeId && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.tradeId}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {isDebugMode && (
                        <Grid item xs={12} md={6} lg={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.signalId && errors.signalId)}
                            variant="outlined"
                            sx={{ mb: 3 }}
                            disabled={isModify}
                          >
                            <InputLabel htmlFor="label-signal-id">
                              <FormattedMessage id="bot_signals.form.signal_id" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-signal-id"
                              name="signalId"
                              value={values.signalId}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={t('bot_signals.form.signal_id')}
                              // endAdornment={
                              //   <InputAdornment position="end">
                              //         <CustomTooltip tooltipKey={'eq_stoploss'}>
                              //           {({ isTooltipAvailable }) =>
                              //             isTooltipAvailable ? (
                              //               <IconButton>
                              //                 <InfoOutlinedIcon />
                              //               </IconButton>
                              //             ) : (
                              //               <></>
                              //             )
                              //           }
                              //         </CustomTooltip>
                              //       </InputAdornment>
                              // }
                            />
                            {touched.signalId && errors.signalId && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.signalId}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                      {/* <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.orderTag && errors.orderTag)}
                          variant="outlined"
                          sx={{ mb: 3 }}
                          disabled={isModify}
                        >
                          <InputLabel htmlFor="label-orderTag">
                            <FormattedMessage id="bot_signals.form.order_tag_optional" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-orderTag"
                            name="orderTag"
                            value={values.orderTag}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={t('bot_signals.form.order_tag_optional')}
                          />
                          {touched.orderTag && errors.orderTag && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.orderTag}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                      {/* <Grid item xs={12} md={6} lg={4}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.orderValidity && errors.orderValidity,
                          )}
                          variant="outlined"
                          sx={{ mb: 3 }}
                          disabled={isModify}
                        >
                          <InputLabel htmlFor="label-orderValidity">
                            <FormattedMessage id="bot_signals.form.order_validity_minutes" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-orderValidity"
                            name="orderValidity"
                            type="number"
                            value={values.orderValidity}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={t('bot_signals.form.order_validity_minutes')}
                            inputProps={{ min: '0' }}
                          />
                          {touched.orderValidity && errors.orderValidity && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.orderValidity}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                    </Grid>
                    <Grid container columnSpacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display={isCrypto ? 'none' : undefined}
                      >
                        <Box sx={{ mb: 3 }}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography fontWeight={500}>
                              {t('bot_signals.form.product_type')}
                            </Typography>
                            <CustomTooltip tooltipKey={'signal_product_type'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </Box>
                          <Box display="flex" gap={2} mt={1}>
                            {SignalProductTypes.map(item => (
                              <MuiButton
                                onClick={() =>
                                  setFieldValue('productType', item.value)
                                }
                                key={item.value}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  flex: 1,
                                  color: 'primary.main',
                                  borderColor: (theme: Theme) =>
                                    item.value === values.productType
                                      ? `${theme.palette.primary.main} !important`
                                      : 'grey.200',
                                  maxWidth: {
                                    xs: 'inherit',
                                    md: 'fit-content',
                                  },
                                }}
                                disabled={
                                  isModify && item.value !== values.positionType
                                }
                              >
                                {item.label}
                              </MuiButton>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 3 }}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography fontWeight={500}>
                              {t('bot_signals.form.order_type')}
                            </Typography>
                            <CustomTooltip tooltipKey={'signal_order_type'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </Box>
                          <Box display="flex" gap={2} mt={1}>
                            {filteredOrderType.map(item => {
                              if (
                                !isSLEntrySignal &&
                                item.value === 'STOP_LOSS'
                              )
                                return null;
                              return (
                                <MuiButton
                                  onClick={() => {
                                    setFieldValue('orderType', item.value);
                                    if (
                                      ['MARKET', 'STOP_LOSS_MARKET'].includes(
                                        item.value,
                                      )
                                    )
                                      setFieldValue('price', 0);
                                    setFieldValue('trigger_limit_price', 0);
                                  }}
                                  key={item.value}
                                  variant="outlined"
                                  color="secondary"
                                  sx={{
                                    flex: '1 1 auto',
                                    color: 'primary.main',
                                    borderColor: (theme: Theme) =>
                                      item.value === values.orderType
                                        ? `${theme.palette.primary.main} !important`
                                        : 'grey.200',
                                    maxWidth: {
                                      xs: 'inherit',
                                      md: 'fit-content',
                                    },
                                  }}
                                  disabled={
                                    isModify && item.value !== values.orderType
                                  }
                                >
                                  {item.label}
                                </MuiButton>
                              );
                            })}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 3 }}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography fontWeight={500}>
                              {t('bot_signals.form.transaction_type')}
                            </Typography>
                            <CustomTooltip
                              tooltipKey={'signal_transaction_type'}
                            >
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </Box>
                          <Box display="flex" gap={2} mt={1}>
                            {SignalTransactionTypes.map(item => (
                              <MuiButton
                                onClick={() => {
                                  setFieldValue('transactionType', item.value);
                                  handleChangeTitleData({
                                    qty: values.quantity ? values.quantity : 0,
                                    symbol: values.symbol
                                      ? values.symbol.trading_symbol
                                      : '',
                                    transactionType: item.value,
                                  });
                                }}
                                key={item.value}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  flex: 1,
                                  color: 'primary.main',
                                  borderColor: (theme: Theme) =>
                                    item.value === values.transactionType
                                      ? `${theme.palette.primary.main} !important`
                                      : 'grey.200',
                                  maxWidth: {
                                    xs: 'inherit',
                                    md: 'fit-content',
                                  },
                                }}
                                disabled={
                                  isModify &&
                                  item.value !== values.transactionType
                                }
                              >
                                {item.label}
                              </MuiButton>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 3 }}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography fontWeight={500}>
                              {t('bot_signals.form.position_type')}
                            </Typography>
                            <CustomTooltip tooltipKey={'signal_position_type'}>
                              {({ isTooltipAvailable }) =>
                                isTooltipAvailable ? (
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <></>
                                )
                              }
                            </CustomTooltip>
                          </Box>
                          <Box display="flex" gap={2} mt={1}>
                            {SignalPositionTypes.map(item => {
                              if (!isModifySignal && item.value === 'MODIFY')
                                return null;
                              return (
                                <MuiButton
                                  onClick={() =>
                                    setFieldValue('positionType', item.value)
                                  }
                                  key={item.value}
                                  variant="outlined"
                                  color="secondary"
                                  sx={{
                                    flex: 1,
                                    color: 'primary.main',
                                    borderColor: (theme: Theme) =>
                                      item.value === values.positionType
                                        ? `${theme.palette.primary.main} !important`
                                        : 'grey.200',
                                    maxWidth: {
                                      xs: 'inherit',
                                      md: 'fit-content',
                                    },
                                  }}
                                  disabled={
                                    isModify &&
                                    item.value !== values.positionType
                                  }
                                >
                                  {item.label}
                                </MuiButton>
                              );
                            })}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <FeatureFlag
                      features={[FEATURES.IS_SL_TARGET_SIGNAL]}
                      fallback={<></>}
                    >
                      <Grid container columnSpacing={2}>
                        <BotSignalUnitBaseComponent
                          buysell={values.transactionType}
                          setFieldValue={setFieldValue}
                          values={values.stoploss}
                          handleBlur={handleBlur}
                          symbolPrice={
                            liveLtpData &&
                            liveLtpData[
                              values.symbol?.trading_symbol
                                ? values.symbol?.trading_symbol
                                : ''
                            ] &&
                            liveLtpData[
                              values.symbol?.trading_symbol
                                ? values.symbol?.trading_symbol
                                : ''
                            ].Close
                          }
                          touched={touched}
                          handleChange={handleChange}
                          errors={errors}
                          type="stoploss"
                          tooltipKey="signal_sl_price"
                          isModify={isModify}
                        />
                        <BotSignalUnitBaseComponent
                          buysell={values.transactionType}
                          setFieldValue={setFieldValue}
                          values={values.target}
                          handleBlur={handleBlur}
                          symbolPrice={
                            liveLtpData &&
                            liveLtpData[
                              values.symbol?.trading_symbol
                                ? values.symbol?.trading_symbol
                                : ''
                            ] &&
                            liveLtpData[
                              values.symbol?.trading_symbol
                                ? values.symbol?.trading_symbol
                                : ''
                            ].Close
                          }
                          touched={touched}
                          errors={errors}
                          type="target"
                          tooltipKey="signal_target_price"
                          isModify={isModify}
                        />
                      </Grid>
                    </FeatureFlag>
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      display: { xs: 'block', md: 'flex' },
                      justifyContent: 'end',
                      flexDirection: 'row-reverse',
                      gap: 2,
                      pb: 2,
                    }}
                  >
                    <MuiLoadingButton
                      type={'submit'}
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                      loading={isLoading}
                      sx={{
                        mb: { sm: 2, md: 0 },
                        width: { xs: '100%', lg: 'auto' },
                      }}
                    >
                      <FormattedMessage
                        id={
                          isSingle
                            ? 'buttons.generate_signal'
                            : isModifyBasket
                            ? 'buttons.save'
                            : 'buttons.add_basket'
                        }
                      />
                    </MuiLoadingButton>

                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        resetForm();
                        handleClose();
                      }}
                      sx={{
                        mb: { sm: 2, md: 0 },
                        width: { xs: '100%', lg: 'auto' },
                      }}
                    >
                      <FormattedMessage id="buttons.cancel" />
                    </MuiButton>
                  </Box>
                </Box>
              ) : (
                <BotGenerateSignalTemplateContents
                  handleChangeTitleData={handleChangeTitleData}
                  instruments={instruments}
                  handleAddToBasket={(signals: BotSignalParams[]) => {
                    setSignalFormData([...signalFormData, ...signals]);
                  }}
                  handleClickCancel={handleClose}
                />
              )}
              {/* {(isSingle) && ( */}

              {/* )} */}
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default memo(BotGenerateSignalCommonForm);
