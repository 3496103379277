import {
  Backdrop,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import ShowMoreText from 'react-show-more-text';
import { Link as RouterLink } from 'react-router-dom';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FEATURES } from 'types/Feature';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { isBlankHtmlString } from 'utils/GenericFunctions';
import { useCallback, useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import OnlyIframe from 'app/components/Common/OnlyIframe';
import { themeContext } from 'app/components/Context/ToggleThemeContext';

const BotDescription = ({
  desc,
  image,
  author,
  authorLink,
  expandedDesc,
  youTubeUrl,
  showMoreLength,
  authorSubscriberCount,
}: {
  desc: string;
  expandedDesc?: boolean;
  image?: string | null;
  showMoreLength?: number;
  author?: string;
  youTubeUrl?: string;
  authorLink?: string;
  authorSubscriberCount?: number;
}) => {
  const isLgUp = useGetMediaQueryUp('lg');
  const [isOpenImage, setIsOpenImage] = useState(false);
  const isDescription = !isBlankHtmlString(desc);
  const theme = useTheme();
  const isAuthorProfileFlag = useIsFeatureFlag([FEATURES.IS_AUTHOR_PROFILE]);

  const handleCloseImage = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') setIsOpenImage(false);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleCloseImage);
    return () => window.removeEventListener('keydown', handleCloseImage);
  }, [handleCloseImage]);
  const { isDarkTheme } = useContext(themeContext);
  return (
    <Box>
      <>
        {isDescription && (
          <Box
            className="m-main-spacing"
            sx={{
              mt: 2,
              mb: author && isAuthorProfileFlag ? 0 : 2,
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems={['stretch', 'flex-start']}
              justifyContent="space-between"
              sx={{ direction: { xs: 'column-reverse', lg: 'row' } }}
            >
              <Grid
                item
                xs={12}
                lg={
                  isLgUp && image && youTubeUrl
                    ? 4
                    : isLgUp && !image && !youTubeUrl
                    ? 12
                    : 8
                }
                order={{ xs: 3, md: 3, lg: 1 }}
                className="conditions"
              >
                <Typography
                  style={{ filter: `invert(${isDarkTheme ? 1 : 0})` }}
                >
                  <ShowMoreText
                    lines={showMoreLength}
                    more="Read More"
                    less="Less"
                    className="show-more"
                    anchorClass="anchor-show-more"
                    expanded={expandedDesc}
                    truncatedEndingComponent={'... '}
                  >
                    <Typography
                      variant={'body2'}
                      sx={{ fontWeight: 400 }}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: desc,
                      }}
                    ></Typography>
                  </ShowMoreText>
                </Typography>
              </Grid>

              {image && (
                <Grid
                  item
                  xs={12}
                  lg={isLgUp ? 4 : 12}
                  order={{ xs: 2, md: 2, lg: 3 }}
                >
                  <Box
                    onClick={() => {
                      if (isLgUp) setIsOpenImage(true);
                    }}
                    sx={{
                      aspectRatio: '16/9',
                      // maxWidth: '100%',
                      maxWidth: { xs: '100%', lg: '97%' },
                      maxHeight: '450px',
                      mb: { xs: 2, lg: 0 },
                      ml: { xs: 0, lg: '10px' },
                      cursor: isLgUp ? 'zoom-in' : 'default',
                    }}
                  >
                    <img
                      // component="img"

                      src={image}
                      alt="Image"
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '12px',
                      }}
                    />
                  </Box>
                </Grid>
              )}
              {youTubeUrl && (
                // <Grid
                //   item
                //   xs={12}
                //   lg={isLgUp ? 4 : 12}
                //   order={{ md: 1, lg: 2 }}
                // >
                <OnlyIframe title="Templates" url={youTubeUrl} />
                // </Grid>
              )}
            </Grid>
          </Box>
        )}
      </>
      {author && isAuthorProfileFlag && (
        <Box className="m-main-spacing" sx={{ mt: 2, mb: 2 }}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={400}
              >
                <FormattedMessage id="market.bot_detail.author" />
              </Typography>
              <RouterLink to={authorLink}>
                <Typography variant="body2" fontWeight={500}>
                  {author}
                </Typography>
              </RouterLink>
            </Box>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={400}
              >
                <FormattedMessage id="market.bot_detail.subscribers" />
              </Typography>
              <Typography variant="body2" fontWeight={500} textAlign="right">
                {authorSubscriberCount}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {image && isLgUp && (
        <Backdrop
          open={isOpenImage}
          onClick={() => setIsOpenImage(false)}
          sx={{
            color: theme.palette.custom.hoverText,
            zIndex: theme => theme.zIndex.drawer + 2,
          }}
        >
          <IconButton
            onClick={() => {
              setIsOpenImage(false);
            }}
            sx={{
              color: theme.palette.common.white,
              position: 'absolute',
              top: '16px',
              right: '24px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              maxHeight: '80vh',
              aspectRatio: '16/9',
              mx: 'auto',
            }}
            onClick={e => e.stopPropagation()}
          >
            <img
              src={image}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Backdrop>
      )}
    </Box>
  );
};

export default BotDescription;
