import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import ProfitLossByDateReport from 'app/design/speedBot/ComponentItem/Common/ProfitLossByDateReport';

export function BotBackTestingProfitLossByDateReportPage() {
  const {
    bid,
    sid,
    title: botName,
  } = useParams<{ bid: string; sid: string; title: string }>();
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const botId = parseInt(bid);
  const baseUrl = `bots/${bid}/backtest`;

  return (
    <>
      <Helmet>
        <title>Profit And Loss By Date Report</title>
        <meta
          name="description"
          content="Profit And Loss By Date Report Page"
        />
      </Helmet>
      <ProfitLossByDateReport
        baseUrl={baseUrl}
        pageHeadButtonText={botName}
        breadCrumbPage={PAGES.BOT_BACK_TESTING_PROFITLOSS}
        breadCrumbData={{ subId, botId, botName }}
      />
    </>
  );
}
