import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import IndicatorAlertCopyMsgImg from 'assets/images/signalguide/alert_coppy_msg.png';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import StrategyAlertCopyMsgImg from 'assets/images/signalguide/tradingview_copy_msg_ps.png';

const CopyJsonMessage = ({
  signalType,
  bId,
  setName,
  indicator_img_url,
  strategy_img_url,
  tradingview_type,
}: {
  signalType?: string;
  bId?: number;
  setName?: string;
  indicator_img_url?: string;
  tradingview_type?: string;
  strategy_img_url?: string;
}) => {
  const [clicked, setClicked] = useState(0);
  const [clickedPineMsg, setClickedPineMsg] = useState(0);
  const jsonObj = {
    strategy_id: bId,
    signal_type: signalType === 'Entry' ? 101 : 102,
    set_name: setName,
  };
  const msgPineScript = '{{strategy.order.alert_message}}';
  const handleCoppy = () => {
    void navigator.clipboard.writeText(JSON.stringify(jsonObj));
    setClicked(clicked => clicked + 1);
  };
  const handlePineScriptmsgCopy = () => {
    void navigator.clipboard.writeText(msgPineScript);
    setClickedPineMsg(clickedPineMsg => clickedPineMsg + 1);
  };

  return (
    <Box className="m-main-spacing">
      <Typography variant="body1" textAlign={'left'} py={1} fontWeight={600}>
        Copy JSON Data, Paste under “Message” in Settings in TradingView
      </Typography>
      <ItemSeparator variant="1px" orientation="horizontal" />
      {tradingview_type === 'strategy' ? (
        <Box display={'flex'} flex={1} pt={1} flexDirection={'column'}>
          <Box
            display={'flex'}
            flex={1}
            flexDirection={{ sm: 'column-reverse', md: 'row' }}
          >
            <Box flex={1}>
              <Typography textAlign={'justify'} pb={1}>
                Create alerts using your set name and signal type, such as{' '}
                <span style={{ fontWeight: 'bold' }}>
                  &quot;{jsonObj.set_name}_{signalType}&quot;
                </span>
              </Typography>
              <Typography textAlign={'justify'}>
                {
                  'Navigate TradingView and click on “Add Alerts”. Once the pop-up opens, paste the JSON Data in the "Message" section as shown in the image here.'
                }
              </Typography>
              <Box
                sx={{
                  border: '1px solid black',
                  borderRadius: 4,
                  textAlign: 'left',
                  p: 1,
                  mt: 2,
                  backgroundColor: '#f5f5f5',
                }}
                padding={0}
              >
                <pre style={{ margin: 0 }}>{msgPineScript}</pre>
              </Box>
              <MuiButton
                variant="contained"
                color="primary"
                onClick={handlePineScriptmsgCopy}
                sx={{ my: 3 }}
              >
                {clickedPineMsg === 0 ? 'Copy message' : 'Copy Again'}
              </MuiButton>
            </Box>
            <Box
              display={'flex'}
              flex={2}
              alignItems={'center'}
              sx={{
                mt: 1,
                aspectRatio: '1/1',
                maxHeight: { sm: '250px', md: '400px' },
              }}
            >
              <img
                className="p-main-spacing"
                src={
                  strategy_img_url ? strategy_img_url : StrategyAlertCopyMsgImg
                }
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </Box>
          <Box display={'flex'} flex={1} flexDirection="column">
            <Box
              sx={{
                border: '1px solid black',
                borderRadius: 4,
                textAlign: 'left',
                p: 1,
                my: 2,
                backgroundColor: '#f5f5f5',
                width: '100%',
              }}
              padding={0}
            >
              {signalType === 'Entry' ? (
                <Box sx={{ margin: 0, height: 'auto' }}>
                  <Typography
                    variant="body2"
                    color={'#9598a1'}
                  >{`//entry signal using strategy.entry method with alert_message`}</Typography>
                  <span style={{ color: '#2962ff' }}>strategy.entry</span>
                  <span>(</span>
                  <span style={{ color: '#388e3c' }}>{`"long",`}&nbsp;</span>
                  <span style={{ color: '#cc2929' }}>strategy.long</span>
                  <span style={{ color: '#388e3c' }}>,&nbsp;</span>
                  <span>alert_message&nbsp;</span>
                  <span style={{ color: '#22ab94' }}>=&nbsp;</span>
                  <span style={{ color: '#388e3c' }}>{`'${JSON.stringify(
                    jsonObj,
                  )}'`}</span>
                  <span>)</span>
                </Box>
              ) : (
                <Box sx={{ margin: 0, height: 'auto' }}>
                  <Typography
                    variant="body2"
                    color={'#9598a1'}
                  >{`//you can exit with strategy.close and strategy.exit method with alert_message`}</Typography>
                  <Box>
                    <span style={{ color: '#2962ff' }}>strategy.close</span>
                    <span>(</span>
                    <span style={{ color: '#388e3c' }}>{`"long",`}&nbsp;</span>
                    <span style={{ color: '#cc2929' }}>strategy.long</span>
                    <span style={{ color: '#388e3c' }}>,&nbsp;</span>
                    <span>alert_message&nbsp;</span>
                    <span style={{ color: '#22ab94' }}>=&nbsp;</span>
                    <span style={{ color: '#388e3c' }}>{`'${JSON.stringify(
                      jsonObj,
                    )}'`}</span>
                    <span>)</span>
                  </Box>
                  <Box>
                    <span style={{ color: '#2962ff' }}>strategy.exit</span>
                    <span>(</span>
                    <span style={{ color: '#388e3c' }}>{`"long",`}&nbsp;</span>
                    <span style={{ color: '#cc2929' }}>strategy.long</span>
                    <span style={{ color: '#388e3c' }}>,&nbsp;</span>
                    <span>alert_message&nbsp;</span>
                    <span style={{ color: '#22ab94' }}>=&nbsp;</span>
                    <span style={{ color: '#388e3c' }}>{`'${JSON.stringify(
                      jsonObj,
                    )}'`}</span>
                    <span>)</span>
                  </Box>
                </Box>
              )}
            </Box>
            <Box flex={1}>
              <Typography textAlign={'justify'}>
                {` TradingView Pine Script strategies, you can add a custom alert message by including it within the 'alert_messages' variable of the ${
                  signalType === 'Entry'
                    ? 'strategy.entry()'
                    : 'strategy.exit() and strategy.close()'
                } method.
              `}
              </Typography>
              <Box
                sx={{
                  border: '1px solid black',
                  borderRadius: 4,
                  textAlign: 'left',
                  p: 1,
                  mt: 2,
                  backgroundColor: '#f5f5f5',
                }}
                padding={0}
              >
                <pre style={{ margin: 0 }}>
                  {JSON.stringify(jsonObj, null, 1)}
                </pre>
              </Box>
              <MuiButton
                variant="contained"
                color="primary"
                onClick={handleCoppy}
                sx={{ my: 3 }}
              >
                {clicked === 0 ? 'Copy message' : 'Copy Again'}
              </MuiButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display={'flex'}
          flex={1}
          pt={1}
          flexDirection={{ sm: 'column-reverse', md: 'row' }}
        >
          <Box flex={1}>
            <Typography textAlign={'justify'} pb={1}>
              Create alerts using your set name and signal type, such as{' '}
              <span style={{ fontWeight: 'bold' }}>
                &quot;{jsonObj.set_name}_{signalType}&quot;
              </span>
            </Typography>
            <Typography textAlign={'justify'}>
              {
                'Navigate TradingView and click on “Add Alerts”. Once the pop-up opens, paste the JSON Data in the "Message" section as shown in the image here.'
              }
            </Typography>
            <Box
              sx={{
                border: '1px solid black',
                borderRadius: 4,
                textAlign: 'left',
                p: 1,
                mt: 2,
                backgroundColor: '#f5f5f5',
              }}
              padding={0}
            >
              <pre style={{ margin: 0 }}>
                {JSON.stringify(jsonObj, null, 1)}
              </pre>
            </Box>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={handleCoppy}
              sx={{ my: 3 }}
            >
              {clicked === 0 ? 'Copy message' : 'Copy Again'}
            </MuiButton>
          </Box>
          <Box
            display={'flex'}
            flex={2}
            alignItems={'center'}
            sx={{
              mt: 1,
              aspectRatio: '1/1',
              maxHeight: { sm: '400px', md: '600px' },
            }}
          >
            <img
              className="p-main-spacing"
              src={
                indicator_img_url ? indicator_img_url : IndicatorAlertCopyMsgImg
              }
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CopyJsonMessage;
