import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useParams, useHistory } from 'react-router-dom';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import OrdersListLayout from 'app/design/layouts/OrdersListLayout';
import BotHistoryOrdersModal from 'app/components/Bots/BotHistory/BotHistoryOrdersModal';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import PageHeader from 'app/design/speedBot/PageHeader';
import { decodeUrlData } from 'utils/GenericFunctions';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import { Order } from 'types/ApiServicesTypes';
import useGetBotOrders from 'services/Bots/useGetBotOrders';

export function BotBackTestingOrdersPage() {
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const baseApiUrl = `bots/${bid}/backtest`;
  const handleChange = (newValue: number) => {
    if (newValue === 0) {
      history.replace({
        pathname: `/bots/${bid}/backtest/${sid}/positions/${title}`,
      });
    }
  };
  const botId = parseInt(bid);

  const {
    data: botBackTestingOrdersData,
    refetch,
    isLoading,
  } = useGetBotOrders(botId, page, baseApiUrl);

  const totalPages =
    botBackTestingOrdersData && botBackTestingOrdersData.length > 0
      ? Number(botBackTestingOrdersData[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleModalOpen = (orderData: Order) => {
    setSelectedOrder(orderData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  return (
    <>
      <Helmet>
        <title>Backtesting Positions</title>
        <meta name="description" content="Backtesting Positions" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${decodeUrlData(title)} | Backtesting`}
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={1}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.position') },
              { label: t('buttons.order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_BACK_TESTING_ORDERS}
            data={{
              sid,
              botId,
              botName: title,
            }}
          />
        }
      />
      <OrdersListLayout
        data={botBackTestingOrdersData}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleClick={handleModalOpen}
        handleChangeTab={handleChange}
      />
      {selectedOrder && (
        <BotHistoryOrdersModal
          open={openModal}
          handleClose={handleModalClose}
          data={selectedOrder}
          title="Orders"
        />
      )}
    </>
  );
}
