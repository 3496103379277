import React from 'react';
import { destroySocket, useSocket } from 'app/components/Context/SocketContext';
import { SESSION_ID, ACTIVE_USER, USER_NAME, USER_ID } from 'constants/common';
import { removeCookie, setCookie } from 'typescript-cookie';
import { useHistory } from 'react-router-dom';
import { UserInfo } from 'types/User';
import { useUserDetails } from 'app/components/Context/UserContext';
import { clearQueryCache } from 'services/QueryClient';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useLogout } from './useLogout';

export function cleanUpAuth() {
  window.ReactNativeWebView
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.ReactNativeWebView.postMessage('logout')
    : null;
  destroySocket();
  localStorage.removeItem(SESSION_ID);
  localStorage.removeItem(ACTIVE_USER);
  removeCookie(SESSION_ID);
  removeCookie(USER_NAME);
  removeCookie(USER_ID);
  setCookie('session_id', '');
  clearQueryCache();
}

export const useDoSingIn = (ref?: string) => {
  const { createNewSocket } = useSocket();
  const { setUserDetails } = useUserDetails();
  const history = useHistory();
  const path = location.pathname + location.search;
  const doSignIn = React.useCallback(
    (userDetails?: UserInfo) => {
      if (userDetails && userDetails.session_id) {
        setUserDetails({
          ...userDetails,
          capabilities: { features: { liveTrading: true } },
        });
      }
      createNewSocket();
      window.ReactNativeWebView
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          window.ReactNativeWebView.postMessage(
            `type=login;userId=${userDetails ? userDetails.uid : ''}`,
          )
        : null;
      history.push(ref ? ref : path);
    },
    [createNewSocket, history, ref, setUserDetails, path],
  );

  const doCleanUp = React.useCallback(() => {
    cleanUpAuth();
  }, []);

  return { doSignIn, doCleanUp };
};

export const useDoSingOut = () => {
  const history = useHistory();
  const isTemplates = useIsFeatureFlag([
    FEATURES.IS_TEMPLATES,
    FEATURES.IS_ALLOW_PUBLIC_PAGES,
  ]);
  const logout = useLogout();
  const isHomePage = useIsFeatureFlag([FEATURES.IS_HOME_PAGE]);
  const doSignOut = React.useCallback(
    (msg?: string) => {
      cleanUpAuth();
      logout.mutate();

      const state = msg
        ? {
            msg: 'Password changed successfully. Login Now.',
          }
        : {};
      history.push({
        pathname: isHomePage ? '/home' : isTemplates ? '/templates' : 'signin',
        state,
      });
    },
    [history, isHomePage, isTemplates, logout],
  );

  return { doSignOut };
};

export const handleUnauthorized = () => {
  cleanUpAuth();
  const refPath = window.location.pathname + window.location.search;
  let redirectUrl = '/home';
  if (refPath) redirectUrl = `/signin?ref=${refPath}`;
  // if (refPath) window.open(`/signin?ref=${refPath}`, '_self');
  // else window.open(`/signin`, '_self');
  window.location.assign(redirectUrl);
};
