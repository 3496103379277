import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PageHeader from 'app/design/speedBot/PageHeader';
import Item from 'app/design/speedBot/EntityItem/Item';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';

export function ReportsPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Reports Page</title>
        <meta name="description" content="Reports Page" />
      </Helmet>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <PageHeader
            variant="inactive"
            title={t('reports.page_title.reports')}
            buttonHandleClick="/menu"
            buttonText={'Grab The Up Trend'}
          />
        </Box>
        <Item
          variant="itemSubtextIcon"
          title={t('reports.page_title.profit_and_loss')}
          subtext={t('reports.by_date')}
          icon={<ChevronRightIcon />}
        />
        <ItemSeparator variant="1px" className="m-main-spacing" />
        <Item
          variant="itemSubtextIcon"
          title={t('reports.page_title.profit_and_loss')}
          subtext={t('reports.by_symbol')}
          icon={<ChevronRightIcon />}
        />
        <ItemSeparator variant="1px" className="m-main-spacing" />
        <Item
          variant="itemSubtextIcon"
          title={t('reports.page_title.profit_and_loss')}
          subtext={t('reports.description')}
          icon={<ChevronRightIcon />}
        />
      </Box>
    </>
  );
}
