import { CircularProgress, Backdrop, useTheme } from '@mui/material';

const BackdropProgress = ({
  open,
  zIndex,
  position,
}: {
  open: boolean;
  zIndex?: number;
  position?: 'fixed' | 'absolute' | undefined;
}) => {
  const theme = useTheme();
  return (
    <Backdrop
      sx={{
        color: theme.palette.common.white,
        zIndex: theme => (zIndex ? zIndex : theme.zIndex.drawer + 2),
        position: position ? position : 'fixed',
      }}
      open={open}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default BackdropProgress;
