import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ReportSearchParams } from 'types/ApiServicesTypes';
import { getQueryParam } from 'utils/GenericFunctions';

export default function useGetQueryFilters() {
  const history = useHistory();

  const STRATEGY = useMemo(
    () =>
      getQueryParam('startegy') ? getQueryParam('strategy').split(',') : [],
    [],
  );
  const BROKERS = useMemo(
      () =>
        getQueryParam('brokers') ? getQueryParam('brokers').split(',') : [],
      [],
    ),
    EXCHANGES = useMemo(
      () =>
        getQueryParam('exchanges') ? getQueryParam('exchanges').split(',') : [],
      [],
    ),
    DEPLOYMENT = useMemo(
      () =>
        getQueryParam('deployment') ? getQueryParam('deployment') : undefined,
      [],
    ),
    START_DATE = useMemo(
      () =>
        getQueryParam('startDate')
          ? getQueryParam('startdate')
          : moment().subtract(1, 'month').format('YYYY-MM-DD'),
      [],
    ),
    END_DATE = useMemo(
      () =>
        getQueryParam('endDate')
          ? getQueryParam('endDate')
          : moment().format('YYYY-MM-DD'),
      [],
    );

  const setQueryParams = useCallback(
    (newParams: ReportSearchParams) => {
      const urlParams = new URLSearchParams();
      Object.keys(newParams).forEach(key => {
        if (
          newParams[key] &&
          Array.isArray(newParams[key]) &&
          newParams[key].length
        ) {
          const values = newParams[key] as string[];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
          urlParams.set(
            key,
            key === 'riskTypes'
              ? values.join(',').toLowerCase()
              : values.join(','),
          );
        } else if (newParams[key] && typeof newParams[key] === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          urlParams.set(key, newParams[key]);
        }
      });
      history.replace({
        search: urlParams.toString(),
      });
    },
    [history],
  );

  return {
    EXCHANGES,
    DEPLOYMENT,
    setQueryParams,
    START_DATE,
    END_DATE,
    STRATEGY,
    BROKERS,
  };
}
