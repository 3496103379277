import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import i18next from 'i18next';
import { SymbolOptionChainDataType } from 'types/ApiServicesTypes';
import { PositionBaseDataType, PositionData } from '../types';
import { getLots } from '../utils';
import WarningAmber from '@mui/icons-material/WarningAmber';
import BuySellButton from './BuySellButton';
import { Theme } from '@mui/material';

//#region Option chain buysell button and table

// eslint-disable-next-line react/display-name

// , [handleClick, date]);

const optionRows = (
  rowData: SymbolOptionChainDataType,
  strike: string,
  isATM: boolean,
  isITM: boolean,
  isOTM: boolean,
  isGreeks: boolean,
  handleClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void,
  isCE: boolean,
  date: string,
  theme: Theme,
  cePositions: Record<string, PositionBaseDataType>,
  pePositions: Record<string, PositionBaseDataType>,
): TableRowCellsData[] => {
  const ceLTP = rowData.CE.close ? rowData.CE.close.toFixed(2) : 0;
  const peLTP = rowData.PE.close ? rowData.PE.close.toFixed(2) : 0;

  const isShowBS = !isGreeks
    ? Number(ceLTP) > 0
      ? true
      : false
    : isCE
    ? Number(ceLTP) > 0
      ? true
      : false
    : Number(peLTP) > 0
    ? true
    : false;
  return [
    {
      content: rowData.CE.delta ? rowData.CE.delta.toFixed(2) : '-',
      backgroudColor: isATM
        ? theme.component.optionChainColors.atmBg
        : isITM
        ? theme.component.optionChainColors.itmBg
        : '',
      hide: isGreeks,
    },
    {
      content: isGreeks ? (
        isCE ? (
          Number(ceLTP) > 0 ? (
            ceLTP
          ) : (
            <WarningAmber />
          )
        ) : Number(peLTP) > 0 ? (
          peLTP
        ) : (
          <WarningAmber />
        )
      ) : Number(ceLTP) > 0 ? (
        ceLTP
      ) : (
        <WarningAmber />
      ),
      align: 'center',
      color: isGreeks
        ? isCE
          ? Number(ceLTP) > 0
            ? ''
            : '#FFB200'
          : Number(peLTP) > 0
          ? ''
          : '#FFB200'
        : Number(ceLTP) > 0
        ? ''
        : '#FFB200',
      backgroudColor: isATM
        ? theme.component.optionChainColors.atmBg
        : isITM
        ? theme.component.optionChainColors.itmBg
        : '',
    },
    {
      content: isShowBS ? (
        <BuySellButton
          handleClick={handleClick}
          strike={strike}
          contractDetails={
            !isGreeks ? rowData.CE : isCE ? rowData.CE : rowData.PE
          }
          contractType={!isGreeks ? 'ce' : isCE ? 'ce' : 'pe'}
          position={
            !isGreeks
              ? cePositions && cePositions[date]
              : isCE
              ? cePositions && cePositions[date]
              : pePositions && pePositions[date]
          }
          date={date}
          lots={
            !isGreeks
              ? getLots(cePositions && Object.values(cePositions), date).lots
              : isCE
              ? getLots(cePositions && Object.values(cePositions), date).lots
              : getLots(pePositions && Object.values(pePositions), date).lots
          }
          trade_type="option"
        />
      ) : (
        ''
      ),
      backgroudColor: isATM ? '#0782da4d' : isITM ? '#fffbe5' : '',
    },
    {
      content: Number(strike),
      fontWeight: 700,
      backgroudColor: isATM ? theme.component.optionChainColors.atmBg : '',
      align: 'center',
    },
    {
      content: isCE
        ? rowData.CE.iv
          ? rowData.CE.iv.toFixed(2)
          : 0
        : rowData.PE.iv
        ? rowData.PE.iv.toFixed(2)
        : 0,
      backgroudColor: isATM ? theme.component.optionChainColors.atmBg : '',
      hide: !isGreeks,
    },
    {
      content:
        Number(peLTP) > 0 ? (
          <BuySellButton
            handleClick={handleClick}
            strike={strike}
            contractDetails={rowData.PE}
            contractType={'pe'}
            position={pePositions && pePositions[date]}
            date={date}
            lots={getLots(pePositions && Object.values(pePositions), date).lots}
            trade_type={'option'}
          />
        ) : (
          ''
        ),
      backgroudColor: isATM ? '#0782da4d' : isOTM ? '#fffbe5' : '',
      hide: isGreeks,
    },
    {
      content: rowData.PE.close ? (
        rowData.PE.close.toFixed(2)
      ) : (
        <WarningAmber />
      ),
      backgroudColor: isATM
        ? theme.component.optionChainColors.atmBg
        : isOTM
        ? theme.component.optionChainColors.itmBg
        : '',
      hide: isGreeks,
      align: 'center',
      color: !rowData.PE.close
        ? theme.component.optionChainColors.yellowColor
        : '',
    },
    {
      content: isCE
        ? rowData.CE.delta
          ? rowData.CE.delta.toFixed(2)
          : '-'
        : rowData.PE.delta
        ? rowData.PE.delta.toFixed(2)
        : '-',
      backgroudColor: isATM
        ? theme.component.optionChainColors.atmBg
        : isOTM
        ? theme.component.optionChainColors.itmBg
        : '',
      hide: !isGreeks,
    },
    {
      content: isCE
        ? rowData.CE.theta
          ? rowData.CE.theta.toFixed(2)
          : '-'
        : rowData.PE.theta
        ? rowData.PE.theta.toFixed(2)
        : '-',
      backgroudColor: isATM ? theme.component.optionChainColors.atmBg : '',
      hide: !isGreeks,
    },
    {
      content: isCE
        ? rowData.CE.vega
          ? rowData.CE.vega.toFixed(2)
          : '-'
        : rowData.PE.vega
        ? rowData.PE.vega.toFixed(2)
        : '-',
      backgroudColor: isATM ? theme.component.optionChainColors.atmBg : '',
      hide: !isGreeks,
    },
    {
      content: isCE
        ? rowData.CE.gamma
          ? rowData.CE.gamma.toFixed(2)
          : '-'
        : rowData.PE.gamma
        ? rowData.PE.gamma.toFixed(2)
        : '-',
      backgroudColor: isATM ? theme.component.optionChainColors.atmBg : '',
      hide: !isGreeks,
    },
    {
      content: rowData.PE.delta ? rowData.PE.delta.toFixed(2) : '-',
      backgroudColor: isATM
        ? theme.component.optionChainColors.atmBg
        : isOTM
        ? theme.component.optionChainColors.itmBg
        : '',
      hide: isGreeks,
    },
  ];
};
// import { Theme } from './createTheme';

export const createOptionTableRows = (
  optionChainData: Record<string, SymbolOptionChainDataType>,
  handleOptionClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void,
  isGreeks: boolean,
  currentStrike: number,
  positions: PositionData,
  isCE: boolean,
  date: string,
  theme: Theme,
): { rows: TableRow<SymbolOptionChainDataType>[]; columns: TableColumns[] } => {
  const rows: TableRow<SymbolOptionChainDataType>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('simulator.table_column.delta'), hide: isGreeks },
    {
      text: isGreeks
        ? isCE
          ? i18next.t('simulator.table_column.call_ltp')
          : i18next.t('simulator.table_column.put_ltp')
        : i18next.t('simulator.table_column.call_ltp'),
      align: 'center',
    },
    { text: i18next.t('simulator.table_column.lots') },
    { text: i18next.t('simulator.table_column.strike'), align: 'center' },
    { text: i18next.t('simulator.table_column.iv'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.lots'), hide: isGreeks },
    {
      text: i18next.t('simulator.table_column.put_ltp'),
      hide: isGreeks,
      align: 'center',
    },
    {
      text: isGreeks
        ? isCE
          ? i18next.t('simulator.table_column.call_delta')
          : i18next.t('simulator.table_column.put_delta')
        : i18next.t('simulator.table_column.call_delta'),
      hide: !isGreeks,
    },
    { text: i18next.t('simulator.table_column.theta'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.vega'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.gamma'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.delta'), hide: isGreeks },
  ];

  if (!optionChainData) return { rows, columns };

  // eslint-disable-next-line no-console
  console.log('option chain called', positions);
  const strikePrices = Object.keys(optionChainData);
  strikePrices.map((keyStrike: string) => {
    const strike = Number(keyStrike);
    const strikeDiff = Number(strikePrices[1]) - Number(strikePrices[0]);
    const atmStrike = Math.round(currentStrike / strikeDiff) * strikeDiff;
    const isATM = atmStrike === strike;
    const isITM = atmStrike >= strike;
    const isOTM = atmStrike <= strike;
    const cePositions = positions && positions[`CE_${keyStrike}`];
    const pePositions = positions && positions[`PE_${keyStrike}`];
    (rowCellsData = optionRows(
      optionChainData[keyStrike],
      keyStrike,
      isATM,
      isITM,
      isOTM,
      isGreeks,
      handleOptionClick,
      isCE,
      date,
      theme,
      cePositions && cePositions.positionCandleWise,
      pePositions && pePositions.positionCandleWise,
    )),
      rows.push({
        rowCellsData,
        rowData: optionChainData[keyStrike],
        pt: 0.5,
        pb: 0.5,
        visibleOnHover: true,
      });
  });
  return { rows, columns };
};

//#endregion
