import { Box, Divider, Typography, Grid } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { BotAdvanceStatsData } from 'types/ApiServicesTypes';
import {
  formatNumber,
  getColorByNum,
  decimalFormat,
} from 'utils/GenericFunctions';
import InfoItemWrapper from '../../Common/InfoItemWrapper';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const InfoListAdvanceStats = ({
  data,
  initialCapital,
  isShowDDInAmount,
  baseApiUrl,
  currencySymbol,
}: {
  data: BotAdvanceStatsData;
  initialCapital: number;
  isShowDDInAmount: boolean;
  baseApiUrl: string;
  currencySymbol: string;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const isFromDiscover = baseApiUrl.startsWith('marketplace');
  let isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);
  isShowResult = isFromDiscover ? isShowResult : true;

  data.maximumclosedtradedrawdown = data.maximumclosedtradedrawdown
    ? data.maximumclosedtradedrawdown
    : 0;
  const drawdown = isShowDDInAmount
    ? (data.maximumclosedtradedrawdown / initialCapital) * 100
    : data.st_drawdown;

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <Box display={{ xs: 'block', md: 'flex' }}>
            <Box flex={1}>
              <InfoItemWrapper pb={2}>
                <Typography
                  variant="body3"
                  color="text.secondary"
                  sx={{ pt: { md: 0.5 } }}
                >
                  <FormattedMessage id="summary_labels.holdings" />
                </Typography>
                <Typography
                  variant={isMdUp ? 'body2' : 'body3'}
                  fontWeight={{ xs: 500, md: 400 }}
                >
                  {data.holdings}
                </Typography>
              </InfoItemWrapper>
            </Box>
            <Box flex={1}>
              <InfoItemWrapper pb={2}>
                <Typography
                  variant="body3"
                  color="text.secondary"
                  sx={{ pt: { md: 0.5 } }}
                >
                  <FormattedMessage id="summary_labels.unrealized" />
                </Typography>
                <Typography
                  variant={isMdUp ? 'body2' : 'body3'}
                  fontWeight={{ xs: 500, md: 400 }}
                >
                  {data.unrealized}
                </Typography>
              </InfoItemWrapper>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.ending_capital" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >{`${currencySymbol}${formatNumber(
              data.equity,
              false,
              0,
            )}`}</Typography>
          </InfoItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.volume" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >{`${currencySymbol}${formatNumber(
              data.volume,
              false,
              0,
            )}`}</Typography>
          </InfoItemWrapper>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={0}>
        {isShowResult && (
          <Grid item xs={12} md={4}>
            <InfoItemWrapper pb={2}>
              <Typography
                variant="body3"
                color="text.secondary"
                sx={{ pt: { md: 0.5 } }}
              >
                <FormattedMessage id="summary_labels.car" />
              </Typography>
              <Typography
                variant={isMdUp ? 'body2' : 'body3'}
                fontWeight={{ xs: 500, md: 400 }}
                color={getColorByNum(data.st_compoundingannualreturn)}
              >{`${decimalFormat(
                data.st_compoundingannualreturn,
              )}%`}</Typography>
            </InfoItemWrapper>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.mdd" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
              color={drawdown > 0 ? 'error.main' : 'text.primary'}
            >
              {isShowDDInAmount
                ? `${currencySymbol}${
                    data.maximumclosedtradedrawdown
                  } (${decimalFormat(drawdown)}%)`
                : `${decimalFormat(drawdown)}%`}
            </Typography>
          </InfoItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.car_short" /> /{' '}
              <FormattedMessage id="summary_labels.mdd_short" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >
              {data.car_mdd}
            </Typography>
          </InfoItemWrapper>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.sharp_ratio" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >
              {data.tssharperatios}
            </Typography>
          </InfoItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.probablistic_sharp_ratio" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >
              {data.probabilistic_sharpe_ratio}
            </Typography>
          </InfoItemWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoItemWrapper pb={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.sortino_ratio" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >
              {data.sortinoratios}
            </Typography>
          </InfoItemWrapper>
        </Grid>
      </Grid>
      <Divider />
      <Box display={{ xs: 'block', md: 'flex' }}>
        <Box flex={1} sx={{ pt: 2 }}>
          <InfoItemWrapper pb={isMdUp ? 3 : 2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.profit_loss_ratio" />{' '}
              <FormattedMessage id="summary_labels.day_wise" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >
              {data.profitlossratio}
            </Typography>
          </InfoItemWrapper>
          <InfoItemWrapper pb={isMdUp ? 0 : 2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.win_loss_ratio" />{' '}
              <FormattedMessage id="summary_labels.day_wise" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
            >
              {data.winlossratio}
            </Typography>
          </InfoItemWrapper>
        </Box>
        {isMdUp ? (
          <Box sx={{ mx: 2, pb: 2, pt: 3 }} display="flex">
            <Divider orientation="vertical" />
          </Box>
        ) : (
          <Divider sx={{ mb: 2 }} />
        )}
        <Box flex={1} sx={{ pt: isMdUp ? 2 : 0 }}>
          <InfoItemWrapper pb={isMdUp ? 3 : 2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.win_rate" />{' '}
              <FormattedMessage id="summary_labels.day_wise" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
              color={getColorByNum(data.winrate)}
            >
              {data.winrate}%
            </Typography>
          </InfoItemWrapper>
          <InfoItemWrapper pb={isMdUp ? 0 : 2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.loss_rate" />{' '}
              <FormattedMessage id="summary_labels.day_wise" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
              color="error.main"
            >
              {data.lossrate}%
            </Typography>
          </InfoItemWrapper>
        </Box>
        {isMdUp ? (
          <Box sx={{ mx: 2, pb: 2, pt: 3 }} display="flex">
            <Divider orientation="vertical" />
          </Box>
        ) : (
          <Divider sx={{ mb: 2 }} />
        )}
        <Box flex={1} sx={{ pt: isMdUp ? 2 : 0 }}>
          <InfoItemWrapper pb={isMdUp ? 3 : 2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.mfe" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
              color={getColorByNum(data.largestMFE)}
            >
              {data.largestMFE}
            </Typography>
          </InfoItemWrapper>
          <InfoItemWrapper>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ pt: { md: 0.5 } }}
            >
              <FormattedMessage id="summary_labels.mae" />
            </Typography>
            <Typography
              variant={isMdUp ? 'body2' : 'body3'}
              fontWeight={{ xs: 500, md: 400 }}
              color={getColorByNum(data.largestMAE)}
            >
              {data.largestMAE}
            </Typography>
          </InfoItemWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoListAdvanceStats;
