import { useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { encodeUrlData, decodeUrlData } from 'utils/GenericFunctions';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';

// Service
import PageHeader from 'app/design/speedBot/PageHeader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import SignalListLayout from 'app/design/layouts/SignalListLayout';
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import useGetBotSignals from 'services/Bots/useGetBotSignals';
import { BotSignal } from 'types/ApiServicesTypes';
import BotGenerateSignalModal from 'app/components/Bots/BotSignal/BotGenerateSignalModal';

export function PortfolioSignalsPage() {
  const { id, title, ptype, bid } =
    useParams<{ id: string; title: string; ptype?: string; bid: string }>();
  const { t } = useTranslation();
  const titleData = decodeUrlData(title);
  const history = useHistory();
  const [selectedSignal, setSelectedSignal] = useState<BotSignal | null>(null);
  const [isModify, setIsModify] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const handleSelectSignal = useCallback(
    (val: BotSignal, isModify: boolean) => {
      const signalData: BotSignal = {
        ...val,
        position_type: isModify ? 'MODIFY' : val.position_type,
      };
      setIsModify(isModify);
      setSelectedSignal(signalData);
      setOpenModal(true);
    },
    [],
  );
  // For change tab
  const handleChange = (newValue: number) => {
    const path = `/${'portfolio'}/${ptype}/${id}/orders/${encodeUrlData(
      titleData,
    )}/${bid}`;
    if (newValue === 1) {
      history.replace({ pathname: path });
    }
    if (newValue === 0) {
      history.replace({
        pathname: `/${'portfolio'}/${ptype}/${id}/positions/${encodeUrlData(
          titleData,
        )}/${bid}`,
      });
    }
  };
  const handleCloseModal = useCallback(() => {
    setIsModify(false);
    setSelectedSignal(null);
    setOpenModal(false);
  }, []);

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const tabsData = [
    { label: t('buttons.position') },
    { label: t('buttons.order') },
    { label: 'Signals' },
  ];

  //   const isManual = manualInUrl();
  //   if (isManual) {
  //     tabsData.push({ label: 'Signals' });
  //   }
  const { data: botDetails } = useGetBotDetails(Number(bid), Number(id));
  const { data, isLoading } = useGetBotSignals(Number(bid), page);
  return (
    <>
      <Helmet>
        <title>Portfolio Signals</title>
        <meta name="description" content="Portfolio Positions Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${titleData}`}
        buttonHandleClick={() => history.goBack()}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.PORTFOLIO_DETAIL_POSITIONS}
            data={{ botName: title, ptype: ptype, subId: id }}
          />
        }
        tabs={
          <ContentTabs
            value={2}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={tabsData}
          />
        }
      />
      {botDetails && data && (
        <>
          <SignalListLayout
            exchanges={botDetails.exchanges}
            data={data}
            page={page}
            totalPages={data && data.length ? data[0].total_pages : 0}
            handleChangePagination={handleChangePagination}
            handleSelectSignal={handleSelectSignal}
          />
          <BotGenerateSignalModal
            isModify={isModify}
            exchanges={botDetails.exchanges}
            open={openModal}
            handleClose={handleCloseModal}
            botId={Number(bid)}
            signal={selectedSignal}
          />
        </>
      )}
    </>
  );
}
