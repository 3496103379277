import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import useGetNotifications from 'services/Portfolio/useGetNotifications';
import NotificationModal from './NotificationModal';
import NotificationPopover from './NotificationPopover';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { PopoverContext } from '../Context/NotificationPopoverContext';

const NotificationButton = () => {
  const history = useHistory();
  const { open, toggle: toggleNotification } = useContext(PopoverContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMdUp = useGetMediaQueryUp('md');

  const handleOpenNotification = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      toggleNotification?.();
      setAnchorEl(event.currentTarget);
    },
    [toggleNotification],
  );

  const handleCloseNotification = useCallback(() => {
    toggleNotification?.();
    setAnchorEl(null);
  }, [toggleNotification]);

  const objQuery = useGetNotifications();

  const {
    data: notificationsData,
    refetch,
    isLoading: isNotificationLoading,
  } = objQuery;

  const unreadCount = notificationsData ? notificationsData.unreadCount : 0;

  // Message handler for refresh notification
  const onMessageReceivedFromIframe = useCallback(
    event => {
      if (event.data && event.data.payload) {
        if (event.data.payload.event === 'RELOAD_PORTFOLIO') {
          const currentPath = history.location.pathname;
          history.replace('/reload');
          history.replace(currentPath);
        } else if (event.data.payload.event === 'REFRESH_NOTIFICATION') {
          void refetch();
        }
      }
    },
    [history, refetch],
  );

  // add message event listner for refresh notification
  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);
  // const openPopover = useContext(OpenNotificationPopover);
  // eslint-disable-next-line no-console
  // console.log(open, 'from button');

  return (
    <>
      <IconButton
        color="primary"
        size="medium"
        onClick={handleOpenNotification}
      >
        {unreadCount > 0 ? (
          <Badge
            badgeContent={unreadCount}
            color="primary"
            sx={{
              wordBreak: 'normal',
              '& .MuiBadge-badge': { top: '-5px', right: '0' },
            }}
          >
            <NotificationsIcon />
          </Badge>
        ) : (
          <NotificationsNoneIcon />
        )}
      </IconButton>
      {!isMdUp ? (
        <NotificationModal
          open={!!open}
          handleClose={handleCloseNotification}
          notificationsData={notificationsData?.data}
          unreadCount={unreadCount}
          loading={isNotificationLoading}
        />
      ) : (
        open && (
          <NotificationPopover
            open={open}
            handleClose={handleCloseNotification}
            notificationsData={notificationsData?.data}
            unreadCount={unreadCount}
            loading={isNotificationLoading}
            anchorEl={anchorEl}
          />
        )
      )}
    </>
  );
};

export default memo(NotificationButton);
