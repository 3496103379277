import MuiChip from 'app/design/uiComponents/MuiChip';

const BetaChip = () => {
  return (
    <MuiChip
      sx={{ alignSelf: 'center' }}
      size={'small'}
      label={'Beta'}
      color={'greylight'}
    />
  );
};

export default BetaChip;
