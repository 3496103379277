import { Box } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import HoldingBanner from 'app/design/uiComponents/HoldingBanner';
import HoldingBanner2 from '../../../../../assets/images/banner/HoldingBanner2.png';

const BotOnboardingInfo = ({ link }: { link: string }) => {
  return (
    <Box sx={{ mb: 2, px: 1.5, backgroundColor: 'grey.100' }}>
      <HoldingBanner
        position="start"
        text={
          <>
            <FormattedMessage id="market.bot_detail.banner.test" />
            <br />
            <FormattedMessage id="market.bot_detail.banner.to" />
            <br />
            <FormattedMessage id="market.bot_detail.banner.measure" />
          </>
        }
        image={HoldingBanner2}
        link={link}
      />
    </Box>
  );
};

export default BotOnboardingInfo;
