import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { CAPABILITIES } from 'types/Capability';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

// Services
import useGetProfile from 'services/Menu/useGetProfile';

const PlanUpgradeButton = ({ capability }: { capability?: CAPABILITIES }) => {
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');

  const { data: profileDetails } = useGetProfile();

  const redirectPage =
    profileDetails && profileDetails.user_plan && profileDetails.user_plan.plan
      ? '/plan-change'
      : '/plan-upgrade';

  let message = '';
  if (capability) {
    const capabilityDetails =
      profileDetails &&
      profileDetails.user_plan &&
      profileDetails.user_plan.capabilities
        ? profileDetails.user_plan.capabilities.filter(
            x => x.key === capability,
          )[0]
        : null;

    const capacity = capabilityDetails ? capabilityDetails.credit : 0;

    if (capability === CAPABILITIES.BACK_TESTING)
      message = `${t('maximum_allowed_limit_backtests')} ${capacity}`;
    else if (capability === CAPABILITIES.LIVE_TRADING)
      message = `${t('maximum_allowed_limit_live_bots')} ${capacity}`;
    else if (capability === CAPABILITIES.PAPER_TESTING)
      message = `${t('maximum_allowed_limit_paper_bots')} ${capacity}`;
  }

  return (
    <Box>
      {capability && message && (
        <Typography variant="body2" fontWeight={400} sx={{ my: 0.5 }}>
          {message}
        </Typography>
      )}
      <MuiButton
        fullWidth
        variant="contained"
        color="primary"
        size="xlarge"
        sx={{ mb: 2, justifyContent: 'space-between' }}
        endIcon={<UpgradeIcon />}
        component={RouterLink}
        to={isMdUp ? '/menu/plan-details/open' : redirectPage}
      >
        <FormattedMessage id="buttons.upgrade_plan" />
      </MuiButton>
    </Box>
  );
};

export default memo(PlanUpgradeButton);
