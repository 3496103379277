import { ItemVariantProps } from '../types';
import {
  ClickableSmallSubtextVariant,
  ClickableSmallVariant,
  ItemIconVariant,
  ItemSubtextIconVariant,
  ItemSwitchVariant,
} from './ItemVariants';
import ItemValueIconVariant from './ItemVariants/ItemValueIconVariant';

const Item = <V extends ItemVariantProps>(props: V) => {
  const { variant } = props;
  switch (variant) {
    case 'clickableSmallSubtext':
      return (
        <ClickableSmallSubtextVariant
          title={props.title}
          subtext={props.subtext}
          color={props.color}
          handleClick={props.handleClick}
          description={props.description}
        />
      );
    case 'clickableSmall':
      return (
        <ClickableSmallVariant
          title={props.title}
          subtitle={props.subtitle}
          handleClick={props.handleClick}
        />
      );
    case 'itemIcon':
      return (
        <ItemIconVariant
          title={props.title}
          icon={props.icon}
          linkProps={props.linkProps}
          titleColor={props.titleColor as string}
          handleClick={props.handleClick}
        />
      );
    case 'itemValueIcon':
      return (
        <ItemValueIconVariant
          title={props.title}
          value={props.value}
          icon={props.icon}
          linkProps={props.linkProps}
          handleClick={props.handleClick}
        />
      );
    case 'itemSubtextIcon':
      return <ItemSubtextIconVariant {...props} />;
    case 'itemSwitch':
      return <ItemSwitchVariant title={props.title} switch={props.switch} />;
    default:
      return <></>;
  }
};

export default Item;
