import { BillingItemProps } from './types';
import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BillingItem = ({
  orderDate,
  amount,
  fromDate,
  toDate,
  subValue,
}: BillingItemProps) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box display="flex" justifyContent="space-between" sx={{ pb: 0.5 }}>
        <Box flex={1} display="flex">
          <Typography variant="body3" color="text.disabled">
            {orderDate}
          </Typography>
        </Box>
        <Box sx={{ pl: 2 }}>
          <Typography variant="body2">₹{amount}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box flex={1}>
          <Typography
            variant="body2"
            color="text.secondary"
            alignItems="center"
            display="flex"
          >
            {fromDate}
            <ArrowForwardIcon sx={{ fontSize: '12px', mx: 0.5 }} />
            {toDate}
          </Typography>
        </Box>
        <Box sx={{ pl: 2 }}>
          <Typography variant="body2" fontWeight={400} color="text.secondary">
            {subValue}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BillingItem;
