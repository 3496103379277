import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import PageHeader from 'app/design/speedBot/PageHeader';
import { decodeUrlData, getQueryParam } from 'utils/GenericFunctions';
import BotHistoryOrdersModal from 'app/components/Bots/BotHistory/BotHistoryOrdersModal';
import OrdersListLayout from 'app/design/layouts/OrdersListLayout';
import { useState } from 'react';
import { Order } from 'types/ApiServicesTypes';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Box } from '@mui/material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Service
import useGetBotTodaysOrders from 'services/Bots/useGetBotTodaysOrders';

export function BotSignalsTodaysOrderPage() {
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  // Hooks
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();
  const { t } = useTranslation();

  const isDebugMode = getQueryParam('debug', 'false') === 'true';

  const handleChange = (newValue: number) => {
    if (newValue === 0) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/${title}`,
        search: isDebugMode ? '?debug=true' : '',
      });
    }
    if (newValue === 1) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/todays-positions/${title}`,
        search: isDebugMode ? '?debug=true' : '',
      });
    }
  };

  const botId = parseInt(bid);
  const { data, isLoading } = useGetBotTodaysOrders(botId);

  const handleModalOpen = (orderData: Order) => {
    setSelectedOrder(orderData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  return (
    <>
      <Helmet>
        <title>Bot Signals Todays Orders</title>
        <meta name="description" content="Bot Signals Todays Orders" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${decodeUrlData(title)}`}
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={2}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.signals') },
              { label: t('buttons.todays_position') },
              { label: t('buttons.todays_order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_SIGNALS_TODAYS_ORDERS}
            data={{ sid, botId, botName: title }}
          />
        }
      />
      {data && data.today_orders && (
        <>
          <OrdersListLayout
            data={data.today_orders}
            page={0}
            totalPages={0}
            handleClick={handleModalOpen}
            handleChangeTab={handleChange}
            isHidePagination={true}
          />
          <BotHistoryOrdersModal
            open={openModal}
            handleClose={handleModalClose}
            data={selectedOrder || null}
            title="Orders"
          />
        </>
      )}

      <Box sx={{ textAlign: 'right', mt: 2, mb: 1 }} className="p-main-spacing">
        <MuiButton
          endIcon={<ChevronRightIcon />}
          onClick={() => {
            breadcrumb.push(
              `/bots/${bid}/signals/${sid}/orders/${title}`,
              PAGES.BOT_SIGNALS_TODAYS_ORDERS,
              `/bots/${bid}/signals/${sid}/todays-orders/Today's Positions`,
              title,
            );
          }}
        >
          <FormattedMessage id="buttons.order_history" />
        </MuiButton>
      </Box>
      {/* 
      <Box
        className="p-main-spacing"
        display="flex"
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Alert severity="info">
          <Typography variant="body2" color="text.secondary" fontWeight={400}>
            <FormattedMessage id="bot_signals.note" />
          </Typography>
        </Alert>
      </Box> */}
    </>
  );
}
