import { useQuery } from 'react-query';
import { GetBotTodaysOrders } from 'services/apiServices';
import { Errors, GetBotTodaysOrdersResult } from 'types/ApiServicesTypes';

export default function useGetBotTodaysOrders(botId: number) {
  const queryKey = ['GetBotTodaysOrders', botId];
  const objQuery = useQuery<GetBotTodaysOrdersResult, Errors>(queryKey, () =>
    GetBotTodaysOrders(botId),
  );
  return objQuery;
}
