import { useContext } from 'react';
import { useMutation } from 'react-query';
import { SetReferral } from 'services/apiServices';
import { ToastContext } from 'app/components/Context/ToastContext';

import { Errors, SetReferralResult } from 'types/ApiServicesTypes';

export default function useSetReferral() {
  const { showAlert } = useContext(ToastContext);

  const mutation = useMutation<
    SetReferralResult,
    Errors,
    { referralCode: string }
  >('SetReferral', value =>
    SetReferral(value.referralCode)
      .then(res => {
        if (res.error) {
          showAlert!(res.error);
        } else {
          res.message && showAlert!(res.message, 'success');
          return res;
        }
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );
  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }
  return mutation;
}
