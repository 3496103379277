import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useTranslation } from 'react-i18next';

type IndividualOnboardingProps = {
  type: string;
};

const IndividualOnboarding = ({ type }: IndividualOnboardingProps) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const isTypeActive: boolean = type.toLowerCase() === 'active';
  const isOptionBuilderFeature = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER,
  ]);

  return (
    <Box className="m-main-spacing">
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ mt: 3, mb: 0.5, textAlign: { xs: 'left', md: 'center' } }}
      >
        <FormattedMessage id="portfolio.boarding.no_bots.title" />
      </Typography>
      <Typography
        variant="body1"
        fontWeight={400}
        color="text.secondary"
        sx={{ mb: 3, textAlign: { xs: 'left', md: 'center' } }}
      >
        {isTypeActive ? (
          <FormattedMessage id="portfolio.boarding.no_bots.desc.live" />
        ) : (
          <FormattedMessage id="portfolio.boarding.no_bots.desc.paper" />
        )}
      </Typography>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ mt: 2, textAlign: { xs: 'left', md: 'center' } }}
      >
        {isTypeActive ? (
          <FormattedMessage id="portfolio.boarding.no_bots.todo.title.live" />
        ) : (
          <FormattedMessage id="portfolio.boarding.no_bots.todo.title.paper" />
        )}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        fontWeight={400}
        sx={{ mt: 1, textAlign: { xs: 'left', md: 'center' } }}
      >
        <FormattedMessage id="common.select_option" />
      </Typography>
      <Box
        sx={{
          mx: 'auto',
          mt: 4,
          // , maxWidth: { md: '20rem' }
        }}
      >
        {/* Temporary comment out as this feature we have not enabled. */}

        {/* <FeatureFlag features={['liveTrading']}> */}
        {/*
        <MuiButton
          color="secondary"
          size="xlarge"
          variant="contained"
          fullWidth
          sx={{ justifyContent: 'space-between' }}
          component={RouterLink}
          to={''}
          endIcon={<ChevronRightIcon />}
        >
          {isTypeActive ? (
            <FormattedMessage
              id="portfolio.boarding.no_bots.todo.go_your.live"
            />
          ) : (
            <FormattedMessage
              id="portfolio.boarding.no_bots.todo.go_from.paper"
            />
          )}
        </MuiButton> */}
        <Grid container spacing={{ xs: 2, lg: 3 }} justifyContent="center">
          <Grid item xs={12} md={isOptionBuilderFeature ? 12 : 6} lg={4}>
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{ justifyContent: 'space-between' }}
              component={RouterLink}
              // to={'/discover/bots'}
              to={'/templates'}
              endIcon={<ChevronRightIcon />}
            >
              {isTypeActive ? (
                <FormattedMessage id="portfolio.boarding.no_bots.todo.go_from.live" />
              ) : (
                <FormattedMessage id="portfolio.boarding.no_bots.todo.go_from.paper" />
              )}
            </MuiButton>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{ justifyContent: 'space-between' }}
              // component={RouterLink}
              // to={'/bot-builder?type=equity&source=Portfolio'}
              endIcon={<ChevronRightIcon />}
              onClick={() => {
                breadcrumb.push(
                  '/bot-builder?type=equity&source=Portfolio',
                  PAGES.PORTFOLIO,
                  `/portfolio?ptype=${type.toLowerCase()}`,
                  t('portfolio.page_title.portfolio'),
                );
              }}
            >
              <FormattedMessage id="buttons.create_equity_bot" />
            </MuiButton>
          </Grid>
          {isOptionBuilderFeature && (
            <Grid item xs={12} md={6} lg={4}>
              <MuiButton
                color="secondary"
                size="xlarge"
                variant="contained"
                fullWidth
                sx={{ justifyContent: 'space-between' }}
                // component={RouterLink}
                // to={'/bot-builder?type=option&source=Portfolio'}
                endIcon={<ChevronRightIcon />}
                onClick={() => {
                  breadcrumb.push(
                    '/bot-builder?type=option&source=Portfolio',
                    PAGES.PORTFOLIO,
                    `/portfolio?ptype=${type.toLowerCase()}`,
                    t('portfolio.page_title.portfolio'),
                  );
                }}
              >
                <FormattedMessage id="buttons.create_option_bot" />
              </MuiButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default IndividualOnboarding;
