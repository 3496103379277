import { useQuery } from 'react-query';
import { GetStrategyParameterUrl } from 'services/apiServices';
import {
  Errors,
  GetStrategtParameterKeyValueResult,
} from 'types/ApiServicesTypes';

export default function useGetStrategyParameterUrl(
  isUrl: boolean,
  url?: string,
  exchange?: string,
) {
  const objQuery = useQuery<GetStrategtParameterKeyValueResult, Errors>(
    ['GetStrategyParameterUrl', url, exchange],
    () => GetStrategyParameterUrl(url, exchange),
    {
      enabled: !!isUrl,
    },
  );
  return objQuery;
}
