import { useQuery } from 'react-query';
import { GetLogs } from 'services/apiServices';
import { Errors, GetBotsLogResult } from 'types/ApiServicesTypes';

export default function useGetBotLogs(bid: number, type: string) {
  const objQuery = useQuery<GetBotsLogResult, Errors>(
    ['GetBotLogs', bid, type],
    () => GetLogs(bid, type),
  );

  return objQuery;
}
