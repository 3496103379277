import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { EditNotificationSettings } from 'services/apiServices';
import { EditNotificationParams, Errors } from 'types/ApiServicesTypes';

export default function useEditNotificationSettings() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<boolean, Errors, EditNotificationParams>(
    'EditNotificationSettings',
    values =>
      EditNotificationSettings({ settings: values.settings })
        .then(res => {
          showAlert!('Settings updated successfully.', 'success');
          return res;
        })
        .catch(err => {
          //   showAlert!(err.message as string);
          return err;
        }),
  );

  // if (mutation.isError && mutation.error) {
  //   showAlert!(mutation.error.message);
  // }

  return mutation;
}
