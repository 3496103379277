import { memo } from 'react';
import { Typography, Box, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { PositionItem } from 'app/design/speedBot/EntityItem';
import PageHeader from 'app/design/speedBot/PageHeader';
import { Position } from 'types/ApiServicesTypes';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const TodaysPositionHelpModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dummyPosition: Position = {
    id: 0,
    entry_time: '',
    exit_time: '',
    profit_loss: 0,
    totalfees: 0,
    status: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    entry_Price: 0,
    exit_price: 0,
    quantity: 0,
    direction: '',
    duration: '',
    percent_change: 0,
    position_value: 0,
    mae_percent: 0,
    mfe_percent: 0,
    mae: 0,
    mfe: 0,
    instrument_id: '',
    trading_symbol: '',
    total_pages: 0,
    currency_symbol: '',
    currency_name: '',
    count: 0,
    percentage_change: 0,
    PandLPercent: 0,
  };

  const isMdUp = useGetMediaQueryUp('md');
  const isXlUp = useGetMediaQueryUp('xl');

  return (
    <MuiFullScreenModal
      open={open}
      handleClose={handleClose}
      body={
        <Box>
          <Box>
            {isMdUp && !isXlUp ? (
              <PageHeader
                variant="smallWithIcon"
                title={<FormattedMessage id="todays_position.title.main" />}
                iconProps={{
                  handleClick: () => handleClose(),
                  icon: <CloseIcon fontSize="small" />,
                }}
              />
            ) : (
              <PageHeader
                variant="small"
                title={<FormattedMessage id="todays_position.title.main" />}
              />
            )}
          </Box>
          <Divider
            className="m-main-spacing"
            sx={{ borderColor: 'grey.200', borderBottomWidth: '2px', mb: 1 }}
          />
          <Typography
            className="m-main-spacing"
            variant="body2"
            color="text.secondary"
            fontWeight={400}
            sx={{ mb: 3 }}
          >
            <FormattedMessage id="todays_position.help.desc" />
          </Typography>

          <Typography
            className="m-main-spacing"
            variant="body2"
            color="text.secondary"
            sx={{ mb: 1 }}
          >
            <FormattedMessage id="todays_position.help.open_pos.title" />
          </Typography>
          <Typography
            className="m-main-spacing"
            variant="body2"
            color="text.secondary"
            fontWeight={400}
            sx={{ mb: 2 }}
          >
            <FormattedMessage id="todays_position.help.open_pos.desc" />
          </Typography>
          {!isMdUp && (
            <Box
              sx={{
                backgroundColor: 'grey.100',
                mb: 3,
              }}
            >
              <PositionItem
                position={dummyPosition}
                handleClick={() => {
                  return false;
                }}
                demo={true}
              />
            </Box>
          )}
          <Typography
            className="m-main-spacing"
            variant="body2"
            color="text.secondary"
            sx={{ mb: 1 }}
          >
            <FormattedMessage id="todays_position.help.close_pos.title" />
          </Typography>
          <Typography
            className="m-main-spacing"
            variant="body2"
            color="text.secondary"
            fontWeight={400}
            sx={{ mb: 2 }}
          >
            <FormattedMessage id="todays_position.help.close_pos.desc" />
          </Typography>
          {!isMdUp && (
            <>
              <Box
                sx={{
                  backgroundColor: 'grey.100',
                  mb: 3,
                }}
              >
                <PositionItem
                  position={dummyPosition}
                  handleClick={() => {
                    return false;
                  }}
                  demo={true}
                  closed={true}
                />
              </Box>
            </>
          )}
          <Typography
            className="m-main-spacing"
            variant="body2"
            color="text.secondary"
            sx={{ mb: 1 }}
          >
            <FormattedMessage id="todays_position.help.pos_dir.title" />
          </Typography>
          <Box
            sx={{
              backgroundColor: 'grey.100',
              py: 2,
            }}
          >
            <Box className="m-main-spacing" display="flex" alignItems="center">
              <MuiChip
                label="L"
                color="infolight"
                variant="filled"
                size="singleLetter"
              />
              <Typography variant="body2" color="text.primary" sx={{ ml: 1 }}>
                <FormattedMessage id="todays_position.help.pos_dir.long" />
              </Typography>
            </Box>
            <Box
              className="m-main-spacing"
              display="flex"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <MuiChip
                label="S"
                color="purplelight"
                variant="filled"
                size="singleLetter"
              />
              <Typography variant="body2" color="text.primary" sx={{ ml: 1 }}>
                <FormattedMessage id="todays_position.help.pos_dir.short" />
              </Typography>
            </Box>
          </Box>
        </Box>
      }
    ></MuiFullScreenModal>
  );
};

export default memo(TodaysPositionHelpModal);
