import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Condition, Strategy } from './types';
import { ConditionItem } from './ConditionItem';
import { ConditionText } from './ConditionText';
import { ConditionOperataor } from './ConditionOperataor';
import { FieldData } from './fields/types';
import {
  addFieldInStrategy,
  editFieldInStrategy,
  addNewConditionInStrategy,
  getConditionsForBackendFromStrategy,
  getStrategyFromConditions,
  removeConditionInStrategy,
  removeFieldInStrategy,
  pasteConditionInStrategy,
  getConditionsForFrontEndFromStrategy,
  StrategyMode,
} from './utils/strategy-data';

import { cloneDeep } from 'lodash';
import { checkConditions, getNextKeys } from './utils/StrategyValidation';

interface Props {
  inputConditions?: FieldData[];
  copiedStrategy?: Condition;
  onSave?: (conditionsForBackend: FieldData[]) => void;
  setStrategy?: (conditionsForBackend: FieldData[]) => void;
  updateCopiedStrategy?: (copiedStrategy: Condition) => void;
  mode: string;
  isValid: boolean;
  defaultResolution?: string;
}

const getEmptyStrategy = (): Strategy => ({
  condition_key: '',
  conditions: [
    {
      name: 'Long Entry Condition 1',
      id: '76d8b9d9-acb9-4e40-9758-cdc36373f62c',
      type: 'condition',
      fields: [],
    },
  ],
});

export default function StrategyBuilder(props: Props) {
  const {
    onSave,
    setStrategy,
    copiedStrategy,
    updateCopiedStrategy,
    mode,
    defaultResolution,
    inputConditions,
  } = props;

  const inputStrategy: Strategy =
    inputConditions && inputConditions.length > 0
      ? getStrategyFromConditions(inputConditions)
      : getEmptyStrategy();

  inputStrategy.conditions = getConditionsForFrontEndFromStrategy(
    inputStrategy || {},
  );
  const [outputStrategy, setOutputStrategy] = useState<Strategy>(
    inputStrategy || {},
  );
  const { conditions } = outputStrategy;
  const updateOutputStrategy = React.useCallback(
    (strategy: Strategy) => {
      setOutputStrategy({ ...strategy });
      if (setStrategy) {
        setStrategy(getConditionsForBackendFromStrategy(strategy));
      }
    },
    [setOutputStrategy, setStrategy],
  );

  React.useEffect(() => {
    setOutputStrategy({ ...inputStrategy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputConditions]);

  const onAddField = React.useCallback(
    (conditionIndex: number, field: FieldData, fieldIndex: number) => {
      updateOutputStrategy(
        addFieldInStrategy(outputStrategy, conditionIndex, field, fieldIndex),
      );
    },
    [outputStrategy, updateOutputStrategy],
  );
  // const onAddFieldAtPosition = React.useCallback(
  //   (conditionIndex: number, field: FieldData, fieldIndex: number) => {
  //     updateOutputStrategy(
  //       addFieldAtPositionInStrategy(
  //         outputStrategy,
  //         conditionIndex,
  //         field,
  //         fieldIndex,
  //       ),
  //     );
  //   },
  //   [outputStrategy, updateOutputStrategy],
  // );
  const onRemoveField = React.useCallback(
    (conditionIndex: number, fieldIndex: number) => {
      updateOutputStrategy(
        removeFieldInStrategy(outputStrategy, conditionIndex, fieldIndex),
      );
    },
    [outputStrategy, updateOutputStrategy],
  );
  const onEditField = React.useCallback(
    (conditionIndex: number, field: FieldData, fieldIndex: number) => {
      updateOutputStrategy(
        editFieldInStrategy(outputStrategy, conditionIndex, field, fieldIndex),
      );
    },
    [outputStrategy, updateOutputStrategy],
  );

  const onConditionOperatorChange = React.useCallback(
    (conditionIndex: number, key: string) => {
      const { conditions } = outputStrategy;
      const condition = conditions[conditionIndex];
      const newFields = [
        {
          key,
          type: 'condition',
          params: {
            type: 'outer',
          },
        },
      ];
      condition.fields = newFields;
      updateOutputStrategy(outputStrategy);
    },
    [outputStrategy, updateOutputStrategy],
  );

  const onAddCondition = () => {
    updateOutputStrategy(
      addNewConditionInStrategy(
        outputStrategy,
        outputStrategy.conditions.length,
      ),
    );
  };

  const onRemoveCondition = React.useCallback(
    (conditionIndex: number) => {
      updateOutputStrategy(
        removeConditionInStrategy(outputStrategy, conditionIndex),
      );
    },
    [outputStrategy, updateOutputStrategy],
  );

  const onCopyCondition = (conditionIndex: number) => {
    if (updateCopiedStrategy) {
      updateCopiedStrategy(
        cloneDeep(outputStrategy.conditions[conditionIndex]),
      );
    }
  };

  const onPasteCondition = React.useCallback(
    (conditionIndex: number) => {
      if (copiedStrategy) {
        updateOutputStrategy(
          pasteConditionInStrategy(
            outputStrategy,
            conditionIndex,
            copiedStrategy,
          ),
        );
      }
    },
    [copiedStrategy, outputStrategy, updateOutputStrategy],
  );

  const onSaveButtonClick = React.useCallback(() => {
    const conditionsForBackend =
      getConditionsForBackendFromStrategy(outputStrategy);
    if (onSave) {
      onSave(conditionsForBackend);
    }
  }, [outputStrategy, onSave]);
  let conditionCounter = 1;

  const getConditionKey = (condition: Condition) => {
    if (condition && condition.fields && condition.fields[0]) {
      return condition.fields[0].key;
    }
    return '';
  };

  const isOrPresent = conditions.some((condition: Condition) => {
    return getConditionKey(condition) === 'or';
  });

  const getPossibleValues = (index: number, fields: FieldData[]) => {
    const preConditions = fields.slice(0, index);
    const matchResult = checkConditions(preConditions);
    return getNextKeys(matchResult);
  };

  return (
    <div>
      {conditions.map((condition, index) => {
        if (condition.type === 'condition') {
          if (mode === StrategyMode.TEXT) {
            return (
              <Box
                marginLeft={
                  isOrPresent &&
                  (getConditionKey(conditions[index + 1]) === 'and' ||
                    getConditionKey(conditions[index - 1]) === 'and')
                    ? '60px'
                    : '0px'
                }
                key={condition.id}
              >
                <ConditionText
                  key={condition.id}
                  condition={condition}
                  index={conditionCounter++}
                />
              </Box>
            );
          } else {
            return (
              <Box
                key={condition.id}
                marginLeft={
                  isOrPresent &&
                  (getConditionKey(conditions[index + 1]) === 'and' ||
                    getConditionKey(conditions[index - 1]) === 'and')
                    ? '60px'
                    : '0px'
                }
              >
                <ConditionItem
                  condition={condition}
                  conditionIndex={index}
                  onAddField={onAddField}
                  // onAddFieldAtPosition={onAddFieldAtPosition}
                  onRemoveField={onRemoveField}
                  onEditField={onEditField}
                  onRemoveCondition={onRemoveCondition}
                  onCopyCondition={onCopyCondition}
                  onPasteCondition={onPasteCondition}
                  getPossibleValues={getPossibleValues}
                  copiedStrategy={copiedStrategy}
                  mode={mode}
                  index={conditionCounter++}
                  defaultResolution={defaultResolution}
                />
              </Box>
            );
          }
        } else {
          return (
            <Box
              marginLeft={
                isOrPresent && getConditionKey(conditions[index]) === 'and'
                  ? '60px'
                  : '0px'
              }
              key={condition.id}
            >
              <ConditionOperataor
                key={condition.id}
                condition={condition}
                conditionIndex={index}
                onConditionOperatorChange={onConditionOperatorChange}
                mode={mode}
              />
            </Box>
          );
        }
      })}
      {mode === StrategyMode.EDIT ? (
        <MuiButton
          variant="contained"
          color="secondary"
          onClick={onAddCondition}
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
          // sx={{
          //   color: '#FFFFFF',
          //   backgroundColor: '#616161',
          //   borderRadius: '20px',
          //   marginTop: '16px',
          // }}
        >
          Add
        </MuiButton>
      ) : (
        <></>
      )}
      {onSave && <Button onClick={onSaveButtonClick}>Save</Button>}
    </div>
  );
}
