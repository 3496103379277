import i18next from 'i18next';
import {
  PAGES,
  NoBreadcrumbData,
  BotBacktestHistorySymbolsBreadcrumbData,
  BotHistoryDetailBreadcrumbData,
} from '../types';

export const botDetail = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: NoBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
  ];
};

export const botBacktestHistorySymbols = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
    {
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    },
  ];
};

export const botSignalsPositions = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
    {
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    },
    {
      page: PAGES.BOT_SIGNALS_POSITIONS,
      link: `/bots/${data.botId}/signals/${
        data.subId ? data.subId : 0
      }/todays-positions/${data.botName}`,
      label: i18next.t('bot_signals.signal_positions'),
    },
  ];
};

export const botSignalsOrders = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
    {
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    },
    {
      page: PAGES.BOT_SIGNALS_ORDERS,
      link: `/bots/${data.botId}/signals/${
        data.subId ? data.subId : 0
      }/todays-orders/${data.botName}`,
      label: i18next.t('bot_signals.signal_orders'),
    },
  ];
};

export const botBacktestOrdersPositionsSymbols = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
    {
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    },
    {
      page: PAGES.BOT_BACK_TESTING,
      link: `/bots/${data.botId}/backtest/${data.subId}/${data.botName}`,
      label: i18next.t('statistics'),
    },
  ];
};

export const botHistoryDetails = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
    {
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    },
    {
      page: PAGES.BOT_HISTORY,
      link: `/bots/${data.botId}/history/${data.subId}/${data.botName}`,
      label: i18next.t('bots.page_title.history'),
    },
  ];
};

export const botHistorySymbolsOrderPositions = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotHistoryDetailBreadcrumbData,
) => {
  return [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
    {
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    },
    {
      page: PAGES.BOT_HISTORY,
      link: `/bots/${data.botId}/history/${data.subId}/${data.botName}`,
      label: i18next.t('bots.page_title.history'),
    },
    {
      page: PAGES.BOT_HISTORY_DETAIL,
      link: `/bots/${data.botId}/history/${data.subId}/${data.historySubId}/${data.botName}`,
      label: data.title,
    },
  ];
};

export const botStrategyBuilder = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  const links = [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
  ];

  if (data.botId) {
    links.push({
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    });
  }
  return links;
};

export const botBacktestForm = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: BotBacktestHistorySymbolsBreadcrumbData,
) => {
  const links = [
    {
      page: PAGES.BOTS,
      link: `/bots`,
      label: i18next.t('bots.page_title.bots'),
    },
  ];

  if (data.botId) {
    links.push({
      page: PAGES.BOT_DETAIL,
      link: `/bots/${data.botId}/details${
        data.subId ? `/${data.subId}` : ''
      }/Bots`,
      label: data.botName,
    });
  }
  return links;
};
