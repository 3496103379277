import { useState } from 'react';
import { useMutation } from 'react-query';
import { PostPortfolioShare } from 'services/apiServices';
import {
  Errors,
  PostPortfolioShareParams,
  PostPortfolioShareResults,
} from 'types/ApiServicesTypes';

export default function usePostPortfolioShare() {
  const [data, setData] = useState<PostPortfolioShareResults>();
  const mutation = useMutation<
    PostPortfolioShareResults,
    Errors,
    PostPortfolioShareParams
  >('PostPortfolioShare', values =>
    PostPortfolioShare(values)
      .then(res => {
        // eslint-disable-next-line no-console
        setData(res);
        return res;
      })
      .catch(err => {
        return err;
      }),
  );
  return { ...mutation, ...data };
}
