import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import BrokerList from 'app/components/Menu/BrokerList';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function BrokersPage() {
  return (
    <>
      <Helmet>
        <title>Brokers</title>
        <meta name="description" content="Profile Page" />
      </Helmet>

      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.menu" />}
        title={<FormattedMessage id="menu.page_title.brokers" />}
        buttonHandleClick="/menu"
        breadcrumb={<Breadcrumbs page={PAGES.BROKER} data={{}} />}
      />

      <BrokerList />
    </>
  );
}
