import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { GetMarketplaceBotsBySearch } from 'services/apiServices';
import {
  Errors,
  GetMarketplaceBotsBySearchResult,
} from 'types/ApiServicesTypes';

import debounce from 'lodash/debounce';

const WAIT_INTERVAL = 500;
export const MIN_TEXT_SEARCH = 3;

export default function useGetMarketplaceBots(isTraders = false) {
  const { keyword: paramKeyword } = useParams<{ keyword?: string }>();
  const [keyword, setKeyword] = React.useState<string>(paramKeyword || '');

  const marketPlaceQueryKeys = ['GetMarketplaceBots', 'marketplace', keyword];
  const tradersQueryKeys = ['GetTradersBotsSearch', 'traders', keyword];
  const objQuery = useQuery<GetMarketplaceBotsBySearchResult, Errors>(
    isTraders ? tradersQueryKeys : marketPlaceQueryKeys,
    async () => {
      if (!keyword || keyword.trim() === '' || keyword.length < MIN_TEXT_SEARCH)
        return [];
      return await GetMarketplaceBotsBySearch(
        isTraders ? 'traders' : 'marketplace',
        'GET',
        keyword,
      );
    },
    { enabled: false },
  );
  const { refetch } = objQuery;

  const debouncedRefetch = React.useMemo(() => {
    return debounce(refetch, WAIT_INTERVAL);
  }, [refetch]);

  const handleSearchKeywordChange = React.useCallback(
    (value: string) => {
      setKeyword(value);
      if (value.length >= MIN_TEXT_SEARCH) {
        void debouncedRefetch();
      }
    },
    [debouncedRefetch],
  );

  return { ...objQuery, keyword, handleSearchKeywordChange };
}
