import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import PortfolioScripts from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioScripts';
import PageHeader from 'app/design/speedBot/PageHeader';
import StatusMessage from 'app/components/Common/StatusMessage';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Services
import useGetPortfolioBotDetail from 'services/Portfolio/useGetPortfolioBotDetail';

export function PortfolioSymbolsPage() {
  const {
    id,
    ptype,
    title: botName,
  } = useParams<{ id: string; ptype: string; title: string }>();

  const { data, isLoading } = useGetPortfolioBotDetail(id);
  const scripts = data?.data.scripts || [];
  const history = useHistory();

  return (
    <Fragment>
      <Helmet>
        <title>Portfolio Symbols</title>
        <meta name="description" content="Portfolio Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        title={<FormattedMessage id="bots.page_title.symbols" />}
        buttonText={`${botName}`}
        buttonHandleClick={() => {
          history.goBack();
        }}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.PORTFOLIO_DETAIL_SYMBOLS}
            data={{ botName: botName, ptype: ptype, subId: id }}
          />
        }
      />
      {scripts.length > 0 ? (
        <PortfolioScripts scripts={scripts} />
      ) : (
        <StatusMessage
          title={<FormattedMessage id="bots.symbols.status_msg.title" />}
          subtitle={<FormattedMessage id="bots.symbols.status_msg.sub_title" />}
        />
      )}
    </Fragment>
  );
}
