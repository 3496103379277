import { useSocketNotification } from 'app/hooks/useSocketNotification';
import { SOCKET_NOTIFICATION_MESSAGE_TYPES } from 'app/hooks/useSocketNotification/types';
import { useQueryClient } from 'react-query';
import { porfolioBotQueryKey } from './matchers';

const CacheClearMessageTypes = [SOCKET_NOTIFICATION_MESSAGE_TYPES.ORDER];
const MessageTypeToQueryKeyMap = {
  [SOCKET_NOTIFICATION_MESSAGE_TYPES.ORDER]: [
    'GetPortfolio',
    'GetNotifications',
    porfolioBotQueryKey(['GetPortfolioBotDetail']),
    porfolioBotQueryKey(['GetBotPositions', 'bots']),
    porfolioBotQueryKey(['GetBotOrders', 'bots']),
    'GetBacktestQueue',
    'GetBotTodaysPositions',
    'GetBotTodaysOrders',
  ],
};

export const QueryCacheInvalidateOnSocketNotification = () => {
  const queryClient = useQueryClient();

  const notifications = useSocketNotification(CacheClearMessageTypes);
  notifications.forEach(notification => {
    const messageType = notification.msg_type;
    const queryKeys = MessageTypeToQueryKeyMap[messageType];

    if (queryKeys) {
      queryKeys.forEach(key => {
        if (typeof key === 'string') {
          void queryClient.invalidateQueries(key, {
            refetchActive: true,
            refetchInactive: false,
          });
        } else if (typeof key === 'function') {
          const queryKey = key(notification);
          if (queryKey) {
            void queryClient.invalidateQueries(queryKey, {
              refetchActive: true,
              refetchInactive: false,
            });
          }
        }
      });
    }
  });

  return null;
};
