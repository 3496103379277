import { ToastContext } from 'app/components/Context/ToastContext';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { DownloadInvoice } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';

export default function useDownloadInvoice() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<boolean, Errors, { id: number; name: string }>(
    'DownloadInvoice',
    values =>
      DownloadInvoice(values.id)
        .then(res => {
          saveAs(
            new Blob([res], { type: 'application/pdf' }),
            `${values.name}.pdf`,
          );
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }
  return mutation;
}
