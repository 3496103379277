import { FEATURES } from 'types/Feature';
import { FeatureFlag } from '../Common/FeatureFlag';
import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
import { useCallback, useState } from 'react';
import useSubscribePlan from 'services/Menu/useSubscribePlan';
import useGetAllPlans from 'services/Menu/useGetAllPlans';
import CreditUsageInfo from './CreditUsageInfo';
import { UserPlanType } from 'types/User';
import { useTranslation } from 'react-i18next';

function ActivatePayAsYouGo() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const { data: plans } = useGetAllPlans();
  const payAsYouGoPlan =
    plans &&
    plans.filter(plan => plan.template_type === UserPlanType.PAY_AS_YOU_GO)[0];
  const planId = payAsYouGoPlan && payAsYouGoPlan.plan_id;
  const subscribePlanSuccessCallback = useCallback(() => {
    // nothing
  }, []);

  const mutationSubscribePlan = useSubscribePlan(subscribePlanSuccessCallback);

  return (
    <>
      {planId && (
        <FeatureFlag
          features={[FEATURES.IS_PAY_AS_YOU_GO_PLAN]}
          fallback={<></>}
        >
          <Box
            sx={{
              gap: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant={'body1'}>
              {t('menu.upgrade_to', {
                planName: payAsYouGoPlan.plan_name,
              })}
            </Typography>
            <MuiButton
              onClick={() => setOpen(true)}
              variant="contained"
              disabled={mutationSubscribePlan.isLoading}
            >
              <FormattedMessage id="menu.switch_now" />
            </MuiButton>
          </Box>
          <SnakbarMessageModal
            open={open}
            handleClose={handleClose}
            body={
              <Box>
                <Typography variant="body1">
                  Are you sure want to switch to{' '}
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    component={'span'}
                  >
                    {payAsYouGoPlan.plan_name}
                  </Typography>
                  ?
                </Typography>
                {/* <Alert severity="warning" sx={{ mt: 1 }}>
                  <Typography variant="body2">
                    {t('menu.change_plan_warn')}
                  </Typography>
                </Alert> */}
                <CreditUsageInfo capabilities={payAsYouGoPlan.capabilities} />
              </Box>
            }
            footer={
              <>
                <Box display="block">
                  <MuiButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      mutationSubscribePlan.mutate({
                        plan_id: planId,
                        coupon_code: '',
                        end_date: '28/03/2024',
                        payment_acquirer: '',
                        payment_type: '',
                      });
                      setOpen(false);
                    }}
                  >
                    <FormattedMessage id="menu.switch_now" />
                  </MuiButton>
                  <MuiButton
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    sx={{ mt: 1 }}
                  >
                    <FormattedMessage id="buttons.cancel" />
                  </MuiButton>
                </Box>
              </>
            }
          />
        </FeatureFlag>
      )}
    </>
  );
}

export default ActivatePayAsYouGo;
