import { Box, Typography } from '@mui/material';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import { StatisticsData, GetBotDetailsResult } from 'types/ApiServicesTypes';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { formatNumber, getAnnualReturn } from 'utils/GenericFunctions';
import { positionTypes, riskTypes } from './utils';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const BotAttributes = ({
  data,
}: {
  data: StatisticsData | GetBotDetailsResult;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);
  const isShowMDD = useIsFeatureFlag([FEATURES.IS_SHOW_MDD]);
  const isShowLastMonthReturn = useIsFeatureFlag([
    FEATURES.IS_SHOW_LAST_MONTH_RETURN,
  ]);

  const riskType = (
    data.risk_type
      ? riskTypes[data.risk_type]
        ? riskTypes[data.risk_type]
        : data.risk_type.toString()
      : ''
  ) as string;
  const positionType = (
    data.position_type
      ? positionTypes[data.position_type]
        ? positionTypes[data.position_type]
        : data.position_type.toString()
      : ''
  ) as string;

  const annualReturn = getAnnualReturn(
    data.start_date,
    data.end_date,
    data.net_profit_percentage,
  );

  return (
    <Box className="p-main-spacing" sx={{ mt: 1, mb: 3 }}>
      <ItemSeparator variant="2px" />
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: 2,
        }}
      >
        {data.risk_type && (
          <MuiChip
            size="xsmall"
            color={
              riskType === 'High Risk'
                ? 'errorlight'
                : riskType === 'Moderate Risk'
                ? 'infolight'
                : 'successlight'
            }
            label={riskType}
          />
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'flex' }, my: { xs: 0, md: 2 } }}>
        <Box
          sx={{
            flex: { md: 1 },
            display: 'flex',
            flexDirection: { xs: 'row', md: 'column' },
            my: { xs: 1, md: 0 },
          }}
        >
          <Typography
            variant="body3"
            color="text.secondary"
            sx={{ flex: 1, order: { xs: 1, md: 2 }, mt: { xs: 0, md: 0.5 } }}
          >
            <FormattedMessage id="bot_attr.performance" />
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            sx={{ order: { xs: 2, md: 1 } }}
          >
            {isShowResult && (
              <>
                <Typography
                  variant={isMdUp ? 'body2' : 'body3'}
                  color="text.secondary"
                >
                  <FormattedMessage id="summary_labels.annual_return" />
                </Typography>
                <Typography
                  variant={isMdUp ? 'h6' : 'body2'}
                  color="text.primary"
                  sx={{ mx: { xs: 0.5, md: 1 } }}
                >{`${
                  annualReturn > 0 ? '↑' : annualReturn < 0 ? '↓' : ''
                }${formatNumber(annualReturn, false, 1)}%${
                  isMdUp ? '' : ','
                }`}</Typography>
              </>
            )}
            {isShowLastMonthReturn && data.last_month_return !== undefined && (
              <>
                <Typography
                  variant={isMdUp ? 'body2' : 'body3'}
                  color="text.secondary"
                >
                  <FormattedMessage id="bot_attr.last_month_return" />
                </Typography>
                <Typography
                  variant={isMdUp ? 'h6' : 'body2'}
                  color="text.primary"
                  sx={{ [isShowMDD ? 'mx' : 'ml']: { xs: 0.5, md: 1 } }}
                >
                  {data.last_month_return > 0 ? '+' : ''}
                  {formatNumber(data.last_month_return, false, 1)}%
                  {!isMdUp && isShowMDD ? ',' : ''}
                </Typography>
              </>
            )}
            {isShowMDD && (
              <>
                <Typography
                  variant={isMdUp ? 'body2' : 'body3'}
                  color="text.secondary"
                >
                  {isMdUp ? (
                    <FormattedMessage id="bot_attr.max_dd" />
                  ) : (
                    <FormattedMessage id="bot_attr.mdd" />
                  )}
                </Typography>
                <Typography
                  variant={isMdUp ? 'h6' : 'body2'}
                  color="text.primary"
                  sx={{ ml: { xs: 0.5, md: 1 } }}
                >{`${
                  // data.drawdown > 0 ? '↑' : data.drawdown < 0 ? '↓' : ''
                  data.drawdown !== 0 ? '↓' : ''
                }${formatNumber(data.drawdown, false, 1)}%`}</Typography>
              </>
            )}
          </Box>
        </Box>
        <ItemSeparator
          variant="1px"
          orientation={isMdUp ? 'vertical' : 'horizontal'}
        />
        <Box
          sx={{
            display: { xs: 'contents', md: 'block' },
            width: { md: '327px' },
            mt: { xs: 1, md: 0 },
            ml: { xs: 0, md: 2 },
          }}
        >
          {data.risk_type && (
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <MuiChip
                  size="xsmall"
                  color={
                    riskType === 'High Risk'
                      ? 'errorlight'
                      : riskType === 'Moderate Risk'
                      ? 'infolight'
                      : 'successlight'
                  }
                  label={riskType}
                />
              </Box>
              <ItemSeparator variant="1px" />
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{ mt: 1, mb: { xs: 2, md: 0 } }}
          >
            <Typography variant="body3" color="text.secondary" sx={{ flex: 1 }}>
              <FormattedMessage id="bot_attr.position_type" />
            </Typography>
            {data.position_type && (
              <Typography
                variant={isMdUp ? 'body2' : 'body3'}
                color="text.primary"
                fontWeight={500}
              >
                {positionType}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <ItemSeparator variant="2px" />
    </Box>
  );
};

export default BotAttributes;
