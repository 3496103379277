import React from 'react';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { InfoType } from 'types/ComponentTypes';

export const botTabs: Array<{
  name: string;
  label: string | React.ReactElement;
}> = [
  {
    name: 'live',
    label: <FormattedMessage id="bot_status.live" />,
  },
  {
    name: 'paper',
    label: <FormattedMessage id="bot_status.paper" />,
  },
  // {
  //   name: 'inactive',
  //   label: <FormattedMessage id="bot_status.inactive" />,
  // },
  {
    name: 'draft',
    label: <FormattedMessage id="bot_status.my_bots" />,
  },
];

export const botAdvanceStatsInfoModalData: InfoType[] = [
  {
    title: 'Holdings',
    desc: 'At the end of Backtest Period, if the bot has any holding based on the entry condition and not got exited yet, this will show Total Value of such Holdings based on Entry Price.',
  },
  {
    title: 'Unrealized',
    desc: 'Unrealized profit/loss of holding which is not exited yet at the end of Backtest Period.',
  },
  {
    title: 'Ending Capital',
    desc: 'After all profit earned and loss realized in the Backtest Period, final value of the Capital at the end of the Backtest.',
  },
  {
    title: 'Volume',
    desc: 'Total of Buy and Sell transaction values.',
  },
  {
    title: 'Profit Loss Ratio',
    desc: 'Ratio of Average Profit to Average Loss. You can say average Reward to Risk Ratio.',
  },
  {
    title: 'Win Loss Ratio',
    desc: 'Ratio of Number of Winning Trade to Number of Lossing Trades.',
  },
  {
    title: 'Sharpe Ratio',
    desc: 'The Sharpe ratio is used to guage the return of an investment compared to its risk. A high Sharpe ratio is good when compared to similar portfolios or funds with lower returns.',
  },
  {
    title: 'Probablistic Sharpe Ratio',
    desc: 'Probabilistic Sharpe Ratio is a measure of strategy performance expressed in terms of probability of skill beyond a 1.0 Sharpe Ratio.',
  },
  {
    title: 'Sortino Ratio',
    desc: 'Ratio to measure risk-adjusted return.',
  },
  {
    title: 'Win Rate',
    desc: 'Ratio of number of winning trades out of total trades.',
  },
  {
    title: 'Loss Rate',
    desc: 'Ratio of number of lossing trades out of total trades.',
  },
  {
    title: 'Compound Annual Return (CAR)',
    desc: 'The rate of return, as a percentage, which represents the cumulative effect that a series of gains or losses has on an original capital during the Backtest Period.',
  },
  {
    title: 'Drawdown',
    desc: 'Maximum portfolio value decline from a historical peak.',
  },
  {
    title: 'Largest MFE',
    desc: 'MFE means Maximum Favorable Excursion. MFE is lowest price during a short trade and the highest price during a long trade. Largest MFE is maximum of MFE of all trades.',
  },
  {
    title: 'Largest MAE',
    desc: 'MAE means Maximum Adverse Excursion. MAE is lowest price during a long trade and the highest price during a short trade. Largest MAE is maximum of MAE of all trades.',
  },
];

export const botTradeStatsInfoModalData: InfoType[] = [
  {
    title: 'Total Trades',
    desc: 'Total Number of Closed Winning or Loosing or All Trades in the Backtest Period.',
  },
  {
    title: 'Max Consicutive Trades',
    desc: 'Max Winning or Lossing Trades occured consecutively during the Backtest Duration.',
  },
  {
    title: 'Total Profit/Loss',
    desc: 'Sum of profit earned from all winning trades and loss realized in all loosing trades.',
  },
  {
    title: 'Average Profit/Loss',
    desc: 'Average of profit earned in all winning trades and loss realized in all loosing trades.',
  },
  {
    title: 'Largest Profit/Loss',
    desc: 'Biggest profit earned in Backtest Period & Biggest loss realized in Backtest Period.',
  },
  {
    title: 'Average Trade Duration',
    desc: 'Average of duration for which all winning trades and lossing trades were hold in Backtest Period.',
  },
];

export const UserBacktestingInfoModalData: InfoType[] = [
  {
    title: <FormattedMessage id="user_backtesting.modal.initial_cap.title" />,
    desc: <FormattedMessage id="user_backtesting.modal.initial_cap.desc" />,
  },
  {
    title: <FormattedMessage id="user_backtesting.modal.start_date.title" />,
    desc: <FormattedMessage id="user_backtesting.modal.start_date.desc" />,
  },
  {
    title: <FormattedMessage id="user_backtesting.modal.end_date.title" />,
    desc: <FormattedMessage id="user_backtesting.modal.end_date.desc" />,
  },
  {
    title: <FormattedMessage id="user_backtesting.modal.entry_time.title" />,
    desc: <FormattedMessage id="user_backtesting.modal.entry_time.desc" />,
  },
  {
    title: <FormattedMessage id="user_backtesting.modal.exit_time.title" />,
    desc: <FormattedMessage id="user_backtesting.modal.exit_time.desc" />,
  },
  {
    title: <FormattedMessage id="user_backtesting.modal.interval.title" />,
    desc: <FormattedMessage id="user_backtesting.modal.interval.desc" />,
  },
];
