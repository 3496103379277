import { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ReferralValidate } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import { ReferralValidateResult } from 'types/ApiServicesTypes';
import _ from 'lodash';

export default function useReferralValidate(initialReferralCode: string) {
  const [referralCode, setReferralCode] = useState<string>(initialReferralCode);

  const debouncedRefetch = useRef(
    _.debounce(() => {
      if (referralCode.trim().length > 3) {
        void refetchReferral();
      }
    }, 1000),
  ).current;

  const {
    data: referralValidate,
    isLoading,
    isError,
    refetch: refetchReferral,
  } = useQuery<ReferralValidateResult, Errors>(
    ['ReferralValidate', referralCode],
    () => ReferralValidate(referralCode),
    {
      enabled: !!referralCode.trim() && referralCode.trim().length > 3,
    },
  );

  useEffect(() => {
    debouncedRefetch();
    return () => debouncedRefetch.cancel(); // Cleanup debounce on component unmount
  }, [referralCode, debouncedRefetch]);

  return {
    referralCode,
    setReferralCode,
    debouncedRefetch,
    referralValidate,
    isLoading,
    isError,
  };
}
