import { Box, Typography } from '@mui/material';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { PageTitleWithSubtitleVariantProps } from '../types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const PageTitleWithSubtitleVariant = ({
  title,
  subtitle,
  breadcrumbLinks,
  breadcrumb,
  showMdUp,
}: PageTitleWithSubtitleVariantProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      {!isMdUp || showMdUp ? (
        <Box className="p-main-spacing" sx={{ py: 2 }}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="h5" fontWeight={600} sx={{ pt: 1 }}>
            {subtitle}
          </Typography>
        </Box>
      ) : breadcrumb !== undefined ? (
        <Box className="m-main-spacing" sx={{ pt: 3, pb: 0 }}>
          {breadcrumb}
        </Box>
      ) : (
        <Box className="m-main-spacing" sx={{ pt: 3, pb: 0 }}>
          {/* eslint-disable-next-line */}
          <MuiBreadcrumbs links={breadcrumbLinks!} />
        </Box>
      )}
    </>
  );
};

export default PageTitleWithSubtitleVariant;
