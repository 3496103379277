import moment from 'moment';
import { YAxisProps } from 'recharts';
import Typography from '@mui/material/Typography';

import GreyBgCard from 'app/design/uiComponents/BoxGrey';
import {
  calculateAmountUnitWise,
  formatDateString,
  decimalFormat,
} from 'utils/GenericFunctions';

import { capitalFormatter } from '../ChartUtils';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export type GraphItem = {
  date: string;
  capital: number;
};

export type GraphPoint = {
  date: Date;
  capital: number;
  x: number;
  y: number;
};

export type GraphData = GraphItem[];

export const convertGraphDataToPoints = (
  graphData: GraphData,
  amountUnit: string,
): GraphPoint[] => {
  const points = [] as GraphPoint[];

  graphData.forEach(item => {
    const date = getDateForPoint(item);
    points.push({
      date,
      capital: item.capital,
      x: date.getTime(),
      y:
        parseFloat(
          decimalFormat(calculateAmountUnitWise(item['capital'], amountUnit)),
        ) || 0,
    });
  });

  return points;
};

export const getDateForPoint = (item: GraphItem) => {
  const dateString = item.date;
  return moment.utc(dateString).toDate();
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<Payload<ValueType, NameType>>;
  label?: string;
  amountUnit: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload, amountUnit } = props;
  if (active && payload && payload.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const point = payload[0].payload;
    const capital = capitalFormatter(point.y as number, amountUnit);
    return (
      <GreyBgCard padding={1}>
        <Typography variant="body2" sx={{ fontSize: '0.625rem' }}>
          {`Date : ${formatDateString(
            point.date as string,
            'DD MMM YYYY HH:MM:SS',
          )}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: '0.625rem' }}
        >{`Net Capital : ${capital}`}</Typography>
      </GreyBgCard>
    );
  }

  return null;
};

export const calculateYAxisDomain = (
  points: GraphPoint[],
  amountUnit: string,
): YAxisProps['domain'] => {
  const yPoints = points.map(point => point.y);
  const min = Math.min(...yPoints);
  const max = Math.max(...yPoints);

  if (amountUnit === 'K') {
    let multiplier = 10;
    if (max > 100) {
      multiplier = 1;
    }
    const minRound = Math.floor(min * multiplier) / multiplier;
    const maxRound = Math.ceil(max * multiplier) / multiplier;
    return [minRound, maxRound];
  } else if (amountUnit === 'L') {
    const minRound = Math.floor(min * 10) / 10;
    const maxRound = Math.ceil(max * 10) / 10;
    return [minRound, maxRound];
  }

  return [min, max];
};
