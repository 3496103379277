import { useQuery } from 'react-query';
import { GetMarketplaceBotDetail } from 'services/apiServices';
import { Errors, StatisticsData } from 'types/ApiServicesTypes';

export default function useGetMarketplaceBotDetail(
  botId: number,
  isTrader = false,
) {
  const objQuery = useQuery<StatisticsData, Errors>(
    ['GetMarketplaceBotDetail', 'marketplace', botId],
    () =>
      GetMarketplaceBotDetail(
        `marketplace`,
        'GET',
        botId,
        isTrader ? 'traders' : '',
      ),
  );

  return objQuery;
}
