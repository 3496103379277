import { MediumVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const MediumVariant = (props: MediumVariantProps) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2 }}
      display="flex"
      alignItems="center"
    >
      <Typography variant="body1" color="primary.main">
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography variant="body1" color={props.subtitleColor} sx={{ ml: 2 }}>
          {props.subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default MediumVariant;
