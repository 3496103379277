import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import { FormikErrors } from 'formik';

const MaximumEntriesInput = ({
  maxEntries,
  setMaxEntries,
  errors,
  type,
}: {
  maxEntries?: number;
  setMaxEntries?: (maxEntries: number) => void;
  errors?: FormikErrors<string>;
  type?: string;
}) => {
  return (
    <Box mt={5} className="m-main-spacing">
      <Typography variant="body1" py={1} fontWeight={600}>
        Change Maximum {type === 'Entry' ? 'Entries' : 'Exits'}
      </Typography>
      <ItemSeparator variant="1px" orientation="horizontal" />
      <FormControl variant="outlined" fullWidth sx={{ mt: 3 }}>
        <InputLabel htmlFor="label-max-signal">
          Maximum Signal {type === 'Entry' ? 'Entries' : 'Exits'}
        </InputLabel>
        <OutlinedInput
          type="number"
          id="label-max-signal"
          name="max_signal"
          label={`Maximum Signal ${type === 'Entry' ? 'Entries' : 'Exits'}`}
          value={maxEntries!.toString() || '1'}
          onChange={event => {
            setMaxEntries!(Number(event.target.value));
          }}
          readOnly={type === 'Exit'}
          fullWidth
        />
        {errors && <FormHelperText error>{errors}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default MaximumEntriesInput;
