import { memo } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { getDaysBetweenDates, getDateDataByMonth } from './utils';
import moment from 'moment';
import ListViewCalendarDateRow from './ListViewCalendarDateRow';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import { ProfitLossByDateData } from 'types/ApiServicesTypes';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';

const CustomTable = styled(Box)(({ theme }) => ({
  '& table': {
    borderCollapse: 'inherit',
    width: '100%',

    '& thead > tr > th': {
      borderBottom: '1px solid',
      borderColor: theme.palette.grey[200],
      color: theme.palette.text.disabled,
      paddingBottom: theme.spacing(1),
    },

    '& tbody > tr > td': {
      paddingBottom: theme.spacing(1),
    },

    '& tbody > tr:first-of-type > td': {
      paddingTop: theme.spacing(1.5),
    },
  },
}));

const ListViewCalendar = ({
  data,
  initialCapital,
  currencySymbol,
}: {
  data: ProfitLossByDateData;
  initialCapital: number;
  currencySymbol: string;
}) => {
  // getting all months
  const months = getDaysBetweenDates(
    new Date(data.startDate),
    new Date(data.endDate),
  );

  return (
    <CustomTable>
      <Box sx={{ mb: 2 }}>
        {months.map((monthData, index) => {
          const curMonthData = getDateDataByMonth(
            data.transformProfitLossData,
            monthData.month,
            monthData.year,
          );

          if (!curMonthData || !curMonthData.length) return <></>;

          const totalPL = curMonthData.reduce((a, c) => a + c.profit_loss, 0);
          const totalPLPer =
            initialCapital > 0 ? (totalPL / initialCapital) * 100 : 0;
          return (
            <Box key={index}>
              <ItemHead
                variant="medium"
                title={`${moment()
                  .month(monthData.month - 1)
                  .format('MMM')} ${monthData.year}`}
                subtitle={`${currencySymbol}${formatNumber(
                  totalPL,
                  false,
                  0,
                )} (${totalPLPer > 0 ? '+' : ''}${formatNumber(
                  totalPLPer,
                  false,
                  2,
                )}%)`}
                subtitleColor={getColorByNum(totalPLPer)}
              />
              <Box className="m-main-spacing">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2} align="left">
                        <Typography
                          variant="body3"
                          fontWeight={500}
                          component="div"
                        >
                          Date
                        </Typography>
                      </th>
                      <th align="center">
                        <Typography
                          variant="body3"
                          fontWeight={500}
                          component="div"
                        >
                          Trades
                        </Typography>
                      </th>
                      <th colSpan={2} align="center">
                        <Typography
                          variant="body3"
                          fontWeight={500}
                          component="div"
                        >
                          P/L
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ListViewCalendarDateRow
                      currencySymbol={currencySymbol}
                      data={curMonthData}
                    />
                  </tbody>
                </table>
              </Box>
            </Box>
          );
        })}
      </Box>
    </CustomTable>
  );
};

export default memo(ListViewCalendar);
