import i18next from 'i18next';
import { PAGES, NoBreadcrumbData } from '../types';

export const tradersDetails = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: NoBreadcrumbData,
) => {
  return [
    {
      page: PAGES.TRADERS,
      link: `/traders`,
      label: i18next.t('traders.page_title.traders'),
    },
  ];
};

export const traderHistoryOrdersPositions = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: NoBreadcrumbData,
) => {
  return [
    {
      page: PAGES.TRADERS,
      link: `/traders`,
      label: i18next.t('traders.page_title.traders'),
    },
    {
      page: PAGES.TRADERS_DETAIL,
      link: `/traders/details/${data.botId}/${data.botName}`,
      label: data.botName,
    },
  ];
};
