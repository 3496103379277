import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  IconButton,
} from '@mui/material';
import useGetBacktestQueue from 'services/Bots/useGetBacktestQueue';
import usePutCancelBacktestQueue from 'services/Bots/usePutCancelBacktestQueue';
import { formatDateString } from 'utils/GenericFunctions';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { Fragment, useState } from 'react';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { BacktestQueue } from 'types/ApiServicesTypes';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import BackTestingLogsModal from './BacktestingLogsModal';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { Terminal } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const BacktestingQueueTable = ({ botId }: { botId: string }) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const isMdDown = useGetMediaQueryDown('md');
  const { data: backtestQueue, isLoading } = useGetBacktestQueue();
  const mutation = usePutCancelBacktestQueue();
  const [open, setOpen] = useState(false);
  const isLogs = useIsFeatureFlag([FEATURES.IS_LOGS]);

  const handleCancelClick = (id: number) => {
    mutation.mutate({ id });
  };

  const handleSeeLogsClick = () => {
    setOpen(true);
  };
  const handleCloseSeeLogsModal = () => {
    setOpen(false);
  };
  const handleShowReportClick = (queue: BacktestQueue) => {
    breadcrumb.push(
      `/bots/${queue.strategy_id}/backtest/${queue.bot_id}/${queue.strategy_name}`,
      PAGES.BOT_BACK_TESTING_FORM,
      location.pathname,
      t('backtesting.title'),
    );
  };
  const theme = useTheme();
  return (
    <Box className="m-main-spacing" sx={{ mt: 4, position: 'relative' }}>
      <CircularLoader
        open={isLoading || mutation.isLoading}
        position="absolute"
      />
      <TableContainer>
        <Table
          sx={{
            '& th': { whiteSpace: 'nowrap' },
            '& td': { fontWeight: 400 },
            '& th,td': {
              '&:first-child': {
                pl: 0,
              },
              '&:last-child': {
                pr: 0,
              },
            },
          }}
        >
          <TableHead>
            {!isMdDown && (
              <TableRow>
                <TableCell>Strategy</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="left"> </TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            )}
            {isMdDown && (
              <TableRow>
                <TableCell>
                  Strategy
                  <br />
                  Status
                </TableCell>
                <TableCell align="right">
                  Start Date
                  <br />
                  End Date
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {backtestQueue &&
              backtestQueue.data.length > 0 &&
              backtestQueue.data.map((queue, i) => (
                <Fragment key={i}>
                  {!isMdDown && (
                    <TableRow>
                      <TableCell>{queue.strategy_name}</TableCell>
                      <TableCell>
                        {formatDateString(queue.start_date, 'DD MMM YYYY')}
                      </TableCell>
                      <TableCell>
                        {formatDateString(queue.end_date, 'DD MMM YYYY')}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {queue.status === 'executed' ? 'running' : queue.status}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                        {queue.status === 'executed' ? (
                          <div style={{ width: 20 }}>
                            {queue.percentage !== undefined &&
                              queue.percentage > 0 && (
                                <CircularProgressbar
                                  strokeWidth={50}
                                  styles={buildStyles({
                                    pathColor:
                                      theme.component.circularProgressBar
                                        .pathColor,
                                    trailColor:
                                      theme.component.circularProgressBar
                                        .trailColor,
                                    strokeLinecap: 'butt',
                                  })}
                                  value={queue.percentage}
                                />
                              )}
                            {isLogs ? (
                              <IconButton
                                color="primary"
                                onClick={() => handleSeeLogsClick()}
                                sx={{ cursor: 'pointer', ml: 2 }}
                              >
                                <Terminal />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                        {['pending', 'executed'].includes(queue.status) ? (
                          <>
                            <Link
                              color="primary"
                              onClick={() => handleCancelClick(queue.id)}
                              sx={{ cursor: 'pointer' }}
                            >
                              Cancel
                            </Link>
                          </>
                        ) : queue.status === 'completed' ? (
                          <Link
                            color="primary"
                            onClick={() => handleShowReportClick(queue)}
                            sx={{ cursor: 'pointer' }}
                          >
                            Show Report
                          </Link>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {isMdDown && (
                    <>
                      <TableRow>
                        <TableCell
                          {...(['pending', 'executed', 'completed'].includes(
                            queue.status,
                          ) && {
                            sx: {
                              borderBottomStyle: 'none',
                              pb: 1,
                            },
                          })}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingRight: 0,
                          }}
                        >
                          {queue.strategy_name}
                          <br />
                          {queue.status === 'executed'
                            ? 'running'
                            : queue.status}
                          {queue.status === 'executed' ? (
                            <div
                              style={{
                                width: 20,
                                alignSelf: 'center',
                                marginLeft: '1.7em',
                              }}
                            >
                              {queue.percentage !== undefined &&
                                queue.percentage > 0 && (
                                  <CircularProgressbar
                                    strokeWidth={50}
                                    styles={buildStyles({
                                      pathColor:
                                        theme.component.circularProgressBar
                                          .pathColor,
                                      trailColor:
                                        theme.component.circularProgressBar
                                          .trailColor,
                                      strokeLinecap: 'butt',
                                    })}
                                    value={queue.percentage}
                                  />
                                )}
                              {isLogs ? (
                                <IconButton
                                  color="primary"
                                  onClick={() => handleSeeLogsClick()}
                                  sx={{ cursor: 'pointer', p: 0 }}
                                >
                                  <Terminal />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          {...(['pending', 'executed', 'completed'].includes(
                            queue.status,
                          ) && {
                            sx: {
                              borderBottomStyle: 'none',
                              pb: 1,
                            },
                          })}
                          style={{ paddingLeft: 0 }}
                        >
                          {formatDateString(queue.start_date, 'DD MMM YYYY')}
                          <br />
                          {formatDateString(queue.end_date, 'DD MMM YYYY')}
                        </TableCell>
                      </TableRow>
                      {['pending', 'executed'].includes(queue.status) && (
                        <TableRow>
                          <TableCell colSpan={2} align="center" sx={{ pt: 0 }}>
                            <Link
                              color="error"
                              onClick={() => handleCancelClick(queue.id)}
                              sx={{ cursor: 'pointer' }}
                            >
                              Cancel
                            </Link>
                          </TableCell>
                        </TableRow>
                      )}
                      {['completed'].includes(queue.status) && (
                        <>
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              align="center"
                              sx={{ pt: 0 }}
                            >
                              <Link
                                color="error"
                                onClick={() => handleShowReportClick(queue)}
                                sx={{ cursor: 'pointer' }}
                              >
                                Show Report
                              </Link>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </>
                  )}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BackTestingLogsModal
        open={open}
        handleClose={handleCloseSeeLogsModal}
        botId={botId}
        type="backtesting"
      />
    </Box>
  );
};

export default BacktestingQueueTable;
