import { Link as RouterLink } from 'react-router-dom';
import { ItemIconVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const ItemIconVariant = (props: ItemIconVariantProps) => {
  const { title, titleColor, icon, linkProps, handleClick } = props;

  return (
    <Box
      sx={{ py: 2, cursor: 'pointer' }}
      className="p-main-spacing"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      component={
        linkProps && linkProps.link
          ? linkProps.isExternal
            ? 'a'
            : RouterLink
          : 'div'
      }
      {...(handleClick && { onClick: handleClick })}
      {...(linkProps && { to: linkProps.link ? linkProps.link : undefined })}
      {...(linkProps &&
        linkProps.isExternal && {
          href: linkProps.link ? linkProps.link : undefined,
          target: linkProps.target ? linkProps.target : undefined,
        })}
      {...(linkProps && linkProps.target && { target: linkProps.target })}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body2"
          color={`${titleColor ? titleColor : 'primary.main'}`}
        >
          {title}
        </Typography>
      </Box>
      <Box>{icon}</Box>
    </Box>
  );
};

export default ItemIconVariant;
