import { Box, Typography } from '@mui/material';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { PageTitleWithChipWithSubtitleVariantProps } from '../types';

const PageTitleWithChipWithSubtitleVariant = ({
  title,
  subtitle,
  chipProps,
}: PageTitleWithChipWithSubtitleVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ pt: 1, pb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ flex: '1' }}>
          {title}
        </Typography>
        <MuiChip
          label={chipProps.label}
          sx={{ flex: 'inherit', ml: 1 }}
          color={chipProps.color}
          variant={chipProps.variant}
          size="small"
        />
      </Box>
      <Typography
        variant="body2"
        fontWeight={400}
        sx={{ pt: 1 }}
        color="text.secondary"
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default PageTitleWithChipWithSubtitleVariant;
