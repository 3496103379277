import { createContractSelectionOptions } from 'app/components/Builder/OptionsStrategy/FieldsData';
import {
  NIFTY_SYMBOL,
  BANK_NIFTY_SYMBOL,
  FIN_NIFTY_SYMBOL,
  SENSEX,
  BANKEX,
  MID_CAP_NIFTY_SYMBOL,
} from 'constants/common';
import { BotSignalParams } from 'types/ApiServicesTypes';
import { Instrument } from 'types/Instrument';
import { SymbolData } from 'types/Symbols';
import {
  NiftySymbolsType,
  SignalIronCondorValueType,
  SignalIronFlyValueType,
  SignalSpreadValueType,
  SignalStraddleValueType,
  SignalStrangleValueType,
  SignalTeplateValueType,
} from './types';
import { groupBy, cloneDeep, sortBy } from 'lodash';

export const NiftySymbols: NiftySymbolsType[] = [
  {
    label: 'NIFTY',
    name: 'NIFTY',
    symbol: NIFTY_SYMBOL,
    stepSize: 50,
    checkingSymbols: ['NIFTY'],
  },
  {
    label: 'BANK NIFTY',
    name: 'BANKNIFTY',
    symbol: BANK_NIFTY_SYMBOL,
    stepSize: 100,
    checkingSymbols: ['BANKNIFTY'],
  },
  {
    label: 'FIN NIFTY',
    name: 'FINNIFTY',
    symbol: FIN_NIFTY_SYMBOL,
    stepSize: 100,
    checkingSymbols: ['FINNIFTY'],
  },
  {
    label: 'SENSEX',
    name: 'SENSEX',
    symbol: SENSEX,
    stepSize: 100,
    checkingSymbols: ['SENSEX'],
  },
  {
    label: 'BANKEX',
    name: 'BANKEX',
    symbol: BANKEX,
    stepSize: 100,
    checkingSymbols: ['BANKEX'],
  },
  {
    label: 'MIDCAP NIFTY',
    name: 'MIDCPNIFTY',
    symbol: MID_CAP_NIFTY_SYMBOL,
    stepSize: 25,
    checkingSymbols: ['MIDCPNIFTY'],
  },
];

const getClosestInstrument = (arr: Instrument[], num: number) => {
  let current = arr[0];
  arr.forEach(val => {
    if (Math.abs(num - val.strike_price) < Math.abs(num - current.strike_price))
      current = val;
  });
  return current;
};
export const getInstrumentByAtmValue = (
  selectedInstruments: Instrument[],
  atmValue: number,
  symbolFeedData: SymbolData,
) => {
  try {
    const price = symbolFeedData.Close;
    const colsestInstrument = getClosestInstrument(selectedInstruments, price);
    const newAtmPrice =
      colsestInstrument.strike_price + atmValue * colsestInstrument.lot_size;
    const atmInstrument = selectedInstruments.filter(
      x => x.strike_price === newAtmPrice,
    )[0];
    return atmInstrument;
  } catch {
    return null;
  }
};

export const getInstrumentByStrikePrice = (
  selectedInstruments: Instrument[],
  strikePrice: number,
  instrumentType?: string,
) => {
  try {
    if (instrumentType)
      return selectedInstruments.filter(
        x =>
          x.strike_price === strikePrice &&
          x.instrument_type === instrumentType,
      );
    return selectedInstruments.filter(x => x.strike_price === strikePrice);
  } catch {
    return null;
  }
};

const getAtmInstrument = (
  atmValue: number,
  zeroAtmInstrument: Instrument | null,
) => {
  if (!zeroAtmInstrument) return 0;
  return zeroAtmInstrument.strike_price + atmValue * zeroAtmInstrument.lot_size;
};

export const generateStraddleTemplateSignal = (
  values: SignalStraddleValueType,
  zeroAtmInstrumentData: Instrument,
  selectedInstruments: Instrument[],
  stepSize: number,
  commonSignalData: BotSignalParams,
) => {
  const atmValues = createContractSelectionOptions(
    NIFTY_SYMBOL,
    'strike',
    'call',
  );

  const atmSelectedValue = atmValues.filter(atmValue => {
    return atmValue.key === values.value.toString();
  })[0];

  const values1PEStrikePrice = getAtmInstrument(
    -1 * values.value,
    zeroAtmInstrumentData,
  );
  const values1CEStrikePrice = getAtmInstrument(
    values.value,
    zeroAtmInstrumentData,
  );
  const atmPEInstrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values1PEStrikePrice,
    'pe',
  );
  const atmCEInstrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values1CEStrikePrice,
    'ce',
  );

  if (
    !atmPEInstrument ||
    !atmCEInstrument ||
    !atmPEInstrument.length ||
    !atmCEInstrument.length
  )
    return null;

  const instrumentPE = atmPEInstrument[0];
  const instrumentCE = atmCEInstrument[0];

  if (!instrumentPE || !instrumentCE) {
    return null;
  }

  const signals: BotSignalParams[] = [
    {
      ...commonSignalData,
      transaction_type: values.transactionType,
      instrument_id: instrumentPE.instrument_id.toString(),
      symbol: instrumentPE.trading_symbol,
      quantity: values.lots * stepSize,
      tag: `${instrumentPE.instrument_id}.${atmSelectedValue.label}.PE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}Straddle.${
        atmSelectedValue.label
      }.PE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      transaction_type: values.transactionType,
      instrument_id: instrumentCE.instrument_id.toString(),
      symbol: instrumentCE.trading_symbol,
      quantity: values.lots * stepSize,
      tag: `${instrumentCE.instrument_id}.${atmSelectedValue.label}.CE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}Straddle.${
        atmSelectedValue.label
      }.CE.${values.positionType}`,
    },
  ];
  return signals;
};

export const generateStrangleTemplateSignal = (
  values: SignalStrangleValueType,
  zeroAtmInstrumentData: Instrument,
  selectedInstruments: Instrument[],
  stepSize: number,
  commonSignalData: BotSignalParams,
) => {
  const atmValues = createContractSelectionOptions(
    NIFTY_SYMBOL,
    'strike',
    'call',
  );

  const atmSelectedValue1 = atmValues.filter(atmValue => {
    return atmValue.key === values.value1.toString();
  })[0];

  const atmSelectedValue2 = atmValues.filter(atmValue => {
    return atmValue.key === values.value2.toString();
  })[0];

  const values1PEStrikePrice = getAtmInstrument(
    -1 * values.value1,
    zeroAtmInstrumentData,
  );
  const values2CEStrikePrice = getAtmInstrument(
    values.value2,
    zeroAtmInstrumentData,
  );
  const atmPEInstrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values1PEStrikePrice,
    'pe',
  );
  const atmCEInstrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values2CEStrikePrice,
    'ce',
  );

  if (
    !atmPEInstrument ||
    !atmCEInstrument ||
    !atmPEInstrument.length ||
    !atmCEInstrument.length
  )
    return null;

  const instrumentPE = atmPEInstrument[0];
  const instrumentCE = atmCEInstrument[0];

  if (!instrumentPE || !instrumentCE) {
    return null;
  }

  const signals: BotSignalParams[] = [
    {
      ...commonSignalData,
      transaction_type: values.transactionType,
      instrument_id: instrumentPE.instrument_id.toString(),
      symbol: instrumentPE.trading_symbol,
      quantity: values.lots * stepSize,
      tag: `${instrumentPE.instrument_id}.${atmSelectedValue1.label}.PE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}Strangle.${
        atmSelectedValue1.label
      }.PE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      transaction_type: values.transactionType,
      instrument_id: instrumentCE.instrument_id.toString(),
      symbol: instrumentCE.trading_symbol,
      quantity: values.lots * stepSize,
      tag: `${instrumentCE.instrument_id}.${atmSelectedValue2.label}.CE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}Strangle.${
        atmSelectedValue2.label
      }.CE.${values.positionType}`,
    },
  ];
  return signals;
};

export const generateSpreadTemplateSignal = (
  values: SignalSpreadValueType,
  zeroAtmInstrumentData: Instrument,
  selectedInstruments: Instrument[],
  stepSize: number,
  commonSignalData: BotSignalParams,
) => {
  const atmValues = createContractSelectionOptions(
    NIFTY_SYMBOL,
    'strike',
    'call',
  );

  const atmSelectedValue1 = atmValues.filter(atmValue => {
    return atmValue.key === values.value1.toString();
  })[0];

  const atmSelectedValue2 = atmValues.filter(atmValue => {
    return atmValue.key === values.value2.toString();
  })[0];

  const values1StrikePrice = getAtmInstrument(
    (values.type === 'call' ? 1 : -1) * values.value1,
    zeroAtmInstrumentData,
  );
  const values2StrikePrice = getAtmInstrument(
    (values.type === 'call' ? 1 : -1) * values.value2,
    zeroAtmInstrumentData,
  );
  const atm1Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values1StrikePrice,
    values.type === 'call' ? 'ce' : 'pe',
  );
  const atm2Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values2StrikePrice,
    values.type === 'call' ? 'ce' : 'pe',
  );

  if (
    !atm1Instrument ||
    !atm2Instrument ||
    !atm1Instrument.length ||
    !atm2Instrument.length
  )
    return null;

  const instrument1 = atm1Instrument[0];
  const instrument2 = atm2Instrument[0];

  if (!instrument1 || !instrument2) {
    return null;
  }

  const signals = [
    {
      ...commonSignalData,
      transaction_type: values.transactionType,
      instrument_id: instrument1.instrument_id.toString(),
      symbol: instrument1.trading_symbol,
      quantity: values.lots * stepSize,
      tag: `${instrument1.instrument_id}.${atmSelectedValue1.label}.${
        values.type === 'call' ? 'CE' : 'PE'
      }.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}Spread.${
        atmSelectedValue1.label
      }.${values.type === 'call' ? 'CE' : 'PE'}.${values.positionType}`,
    },
    {
      ...commonSignalData,
      transaction_type: values.transactionType === 'BUY' ? 'SELL' : 'BUY',
      instrument_id: instrument2.instrument_id.toString(),
      symbol: instrument2.trading_symbol,
      quantity: values.lots * stepSize,
      tag: `${instrument2.instrument_id}.${atmSelectedValue2.label}.${
        values.type === 'call' ? 'CE' : 'PE'
      }.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}Spread.${
        atmSelectedValue2.label
      }.${values.type === 'call' ? 'CE' : 'PE'}.${values.positionType}`,
    },
  ];
  return signals;
};

export const generateIronFlyTemplateSignal = (
  values: SignalIronFlyValueType,
  zeroAtmInstrumentData: Instrument,
  selectedInstruments: Instrument[],
  stepSize: number,
  commonSignalData: BotSignalParams,
) => {
  const atmValues = createContractSelectionOptions(
    NIFTY_SYMBOL,
    'strike',
    'call',
  );

  const atmSelectedValue1 = atmValues.filter(atmValue => {
    return atmValue.key === values.value1.toString();
  })[0];

  const atmSelectedValue2 = atmValues.filter(atmValue => {
    return atmValue.key === values.value2.toString();
  })[0];

  const atmSelectedValue3 = atmValues.filter(atmValue => {
    return atmValue.key === values.value3.toString();
  })[0];

  const values1StrikePrice = getAtmInstrument(
    -1 * values.value1,
    zeroAtmInstrumentData,
  );
  const values2PEStrikePrice = getAtmInstrument(
    -1 * values.value2,
    zeroAtmInstrumentData,
  );
  const values2CEStrikePrice = getAtmInstrument(
    values.value2,
    zeroAtmInstrumentData,
  );
  const values3StrikePrice = getAtmInstrument(
    values.value3,
    zeroAtmInstrumentData,
  );

  const atmVal1Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values1StrikePrice,
    'pe',
  );
  const atmVal2PEInstrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values2PEStrikePrice,
    'pe',
  );
  const atmVal2CEInstrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values2CEStrikePrice,
    'ce',
  );
  const atmVal3Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values3StrikePrice,
    'ce',
  );

  if (
    !atmVal1Instrument ||
    !atmVal2PEInstrument ||
    !atmVal2CEInstrument ||
    !atmVal3Instrument ||
    !atmVal1Instrument.length ||
    !atmVal2PEInstrument.length ||
    !atmVal2CEInstrument.length ||
    !atmVal3Instrument.length
  )
    return null;

  const instrument1 = atmVal1Instrument[0];
  const instrument2PE = atmVal2PEInstrument[0];
  const instrument2CE = atmVal2CEInstrument[0];
  const instrument3 = atmVal3Instrument[0];

  if (!instrument1 || !instrument2PE || !instrument2CE || !instrument3) {
    return null;
  }

  const signals = [
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument3
        : instrument2CE
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument3 : instrument2CE)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'BUY',
      tag: `${
        (values.type === 'short' ? instrument3 : instrument2CE).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue3 : atmSelectedValue2).label
      }.CE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronFly.${
        (values.type === 'short' ? atmSelectedValue3 : atmSelectedValue2).label
      }.CE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument2CE
        : instrument3
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument2CE : instrument3)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'SELL',
      tag: `${
        (values.type === 'short' ? instrument2CE : instrument3).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue2 : atmSelectedValue3).label
      }.CE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronFly.${
        (values.type === 'short' ? atmSelectedValue2 : atmSelectedValue3).label
      }.CE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument1
        : instrument2PE
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument1 : instrument2PE)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'BUY',
      tag: `${
        (values.type === 'short' ? instrument1 : instrument2PE).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue1 : atmSelectedValue2).label
      }.PE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronFly.${
        (values.type === 'short' ? atmSelectedValue1 : atmSelectedValue2).label
      }.PE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument2PE
        : instrument1
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument2PE : instrument1)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'SELL',
      tag: `${
        (values.type === 'short' ? instrument2PE : instrument1).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue2 : atmSelectedValue1).label
      }.PE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronFly.${
        (values.type === 'short' ? atmSelectedValue2 : atmSelectedValue1).label
      }.PE.${values.positionType}`,
    },
  ];
  return signals;
};

export const generateIronCondorTemplateSignal = (
  values: SignalIronCondorValueType,
  zeroAtmInstrumentData: Instrument,
  selectedInstruments: Instrument[],
  stepSize: number,
  commonSignalData: BotSignalParams,
) => {
  const atmValues = createContractSelectionOptions(
    NIFTY_SYMBOL,
    'strike',
    'call',
  );

  const atmSelectedValue1 = atmValues.filter(atmValue => {
    return atmValue.key === values.value1.toString();
  })[0];

  const atmSelectedValue2 = atmValues.filter(atmValue => {
    return atmValue.key === values.value2.toString();
  })[0];

  const atmSelectedValue3 = atmValues.filter(atmValue => {
    return atmValue.key === values.value3.toString();
  })[0];

  const atmSelectedValue4 = atmValues.filter(atmValue => {
    return atmValue.key === values.value4.toString();
  })[0];

  const values1StrikePrice = getAtmInstrument(
    -1 * values.value1,
    zeroAtmInstrumentData,
  );
  const values2StrikePrice = getAtmInstrument(
    -1 * values.value2,
    zeroAtmInstrumentData,
  );
  const values3StrikePrice = getAtmInstrument(
    values.value3,
    zeroAtmInstrumentData,
  );
  const values4StrikePrice = getAtmInstrument(
    values.value4,
    zeroAtmInstrumentData,
  );

  const atmVal1Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values1StrikePrice,
    'pe',
  );
  const atmVal2Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values2StrikePrice,
    'pe',
  );
  const atmVal3Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values3StrikePrice,
    'ce',
  );
  const atmVal4Instrument = getInstrumentByStrikePrice(
    selectedInstruments,
    values4StrikePrice,
    'ce',
  );

  if (
    !atmVal1Instrument ||
    !atmVal2Instrument ||
    !atmVal3Instrument ||
    !atmVal4Instrument ||
    !atmVal1Instrument.length ||
    !atmVal2Instrument.length ||
    !atmVal3Instrument.length ||
    !atmVal4Instrument.length
  )
    return null;

  const instrument1 = atmVal1Instrument[0];
  const instrument2 = atmVal2Instrument[0];
  const instrument3 = atmVal3Instrument[0];
  const instrument4 = atmVal4Instrument[0];

  if (!instrument1 || !instrument2 || !instrument3 || !instrument4) {
    return null;
  }

  const signals = [
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument4
        : instrument3
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument4 : instrument3)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'BUY',
      tag: `${
        (values.type === 'short' ? instrument4 : instrument3).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue4 : atmSelectedValue3).label
      }.CE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronCondor.${
        (values.type === 'short' ? atmSelectedValue4 : atmSelectedValue3).label
      }.CE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument3
        : instrument4
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument3 : instrument4)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'SELL',
      tag: `${
        (values.type === 'short' ? instrument3 : instrument4).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue3 : atmSelectedValue4).label
      }.CE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronCondor.${
        (values.type === 'short' ? atmSelectedValue3 : atmSelectedValue4).label
      }.CE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument1
        : instrument2
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument1 : instrument2)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'BUY',
      tag: `${
        (values.type === 'short' ? instrument1 : instrument2).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue1 : atmSelectedValue2).label
      }.PE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronCondor.${
        (values.type === 'short' ? atmSelectedValue1 : atmSelectedValue2).label
      }.PE.${values.positionType}`,
    },
    {
      ...commonSignalData,
      instrument_id: (values.type === 'short'
        ? instrument2
        : instrument1
      ).instrument_id.toString(),
      symbol: (values.type === 'short' ? instrument2 : instrument1)
        .trading_symbol,
      quantity: values.lots * stepSize,
      transaction_type: 'SELL',
      tag: `${
        (values.type === 'short' ? instrument2 : instrument1).instrument_id
      }.${
        (values.type === 'short' ? atmSelectedValue2 : atmSelectedValue1).label
      }.PE.${values.positionType}`,
      trade_id: `${values.tradeId ? values.tradeId + '.' : ''}IronCondor.${
        (values.type === 'short' ? atmSelectedValue2 : atmSelectedValue1).label
      }.PE.${values.positionType}`,
    },
  ];
  return signals;
};

export const generateTemplateSignal = (
  type: string,
  val: SignalTeplateValueType,
  zeroAtmInstrument: Instrument | null,
  selectedInstruments: Instrument[],
  selectedSymbol: NiftySymbolsType,
) => {
  if (!zeroAtmInstrument) return null;

  const stepSize = selectedSymbol.stepSize;
  const zeroAtmInstrumentData = { ...zeroAtmInstrument } as Instrument;

  const commonSignalData: BotSignalParams = {
    signal_id: '',
    product_type: val.productType,
    trade_id: val.tradeId,
    tag: val.orderTag,
    position_type: val.positionType,
    order_type: 'MARKET',
    order_duration: 'DAY',
    validity: '0',
    order_price: '0',
    instrument_id: '',
    transaction_type: '',
    quantity: 0,
  };

  if (type === 'STRADDLE') {
    const values = val as SignalStraddleValueType;

    const signals = generateStraddleTemplateSignal(
      values,
      zeroAtmInstrumentData,
      selectedInstruments,
      stepSize,
      commonSignalData,
    );

    return signals;
  } else if (type === 'STRANGLE') {
    const values = val as SignalStrangleValueType;

    const signals = generateStrangleTemplateSignal(
      values,
      zeroAtmInstrumentData,
      selectedInstruments,
      stepSize,
      commonSignalData,
    );

    return signals;
  } else if (type === 'SPREAD') {
    const values = val as SignalSpreadValueType;

    const signals = generateSpreadTemplateSignal(
      values,
      zeroAtmInstrumentData,
      selectedInstruments,
      stepSize,
      commonSignalData,
    );

    return signals;
  } else if (type === 'IRON_FLY') {
    const values = val as SignalIronFlyValueType;

    const signals = generateIronFlyTemplateSignal(
      values,
      zeroAtmInstrumentData,
      selectedInstruments,
      stepSize,
      commonSignalData,
    );

    return signals;
  } else if (type === 'IRON_CONDOR') {
    const values = val as SignalIronCondorValueType;

    const signals = generateIronCondorTemplateSignal(
      values,
      zeroAtmInstrumentData,
      selectedInstruments,
      stepSize,
      commonSignalData,
    );

    return signals;
  }
  return null;
};

export const getNextWeekDate = (
  instruments: Instrument[],
  weekNumber: number,
) => {
  const instrumentData = cloneDeep(instruments);
  let expiries = instrumentData.map(x => {
    return x.expiry;
  });
  expiries = sortBy(expiries);
  const grpExpiries = groupBy(expiries);
  return Object.keys(grpExpiries)[weekNumber];
};
