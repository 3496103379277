import { Box } from '@mui/material';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { PageTitleWithIconWithDescriptionWithBackVariantProps } from '../types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import PageTitleBackVariant from './PageTitleBackVariant';
import PageTitleWithIconWithDescriptionVariant from './PageTitleWithIconWithDescriptionVariant';

const PageTitleWithIconWithDescriptionWithBackVariant = ({
  title,
  iconProps,
  buttonText,
  buttonHandleClick,
  breadcrumbLinks,
  breadcrumb,
  description,
  descriptionSubItem,
}: PageTitleWithIconWithDescriptionWithBackVariantProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <Box>
      {!isMdUp ? (
        <PageTitleBackVariant
          buttonText={buttonText}
          buttonHandleClick={buttonHandleClick}
        />
      ) : breadcrumb !== undefined ? (
        <Box className="m-main-spacing" sx={{ pt: 3, pb: 0 }}>
          {breadcrumb}
        </Box>
      ) : (
        <Box className="m-main-spacing" sx={{ pt: 3, pb: 0 }}>
          <MuiBreadcrumbs links={breadcrumbLinks!} />
        </Box>
      )}
      <PageTitleWithIconWithDescriptionVariant
        title={title}
        iconProps={iconProps}
        description={description}
        descriptionSubItem={descriptionSubItem}
      />
    </Box>
  );
};

export default PageTitleWithIconWithDescriptionWithBackVariant;
