import { MediumWithIconVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const MediumWithIconVariant = (props: MediumWithIconVariantProps) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2 }}
      display="flex"
      alignItems="center"
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="body1" color="primary.main">
          {props.title}
        </Typography>
      </Box>
      <Box>{props.icon}</Box>
    </Box>
  );
};

export default MediumWithIconVariant;
