import { Breadcrumbs as MaterialBreadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import {
  BreadcrumbData,
  GeneratorPages,
} from 'app/components/Common/Breadcrumb/types';

const Breadcrumbs = ({
  page,
  data,
}: {
  page: GeneratorPages;
  data: BreadcrumbData;
}) => {
  const breadcrumb = useBreadcrumbManager();
  const breadcrumbData = breadcrumb.getBreadcrumpPath(page, data);

  return (
    <MaterialBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-li:before,.MuiBreadcrumbs-separator:before ': {
          content: 'none',
        },
      }}
    >
      {breadcrumbData &&
        breadcrumbData.map((item, i) => (
          <Typography
            variant="body1"
            color="text.primary"
            sx={{
              ':hover': { textDecoration: 'underline' },
              cursor: 'pointer',
            }}
            fontWeight={400}
            key={i}
            onClick={() => {
              breadcrumb.clickBreadcrumb(i, item.link);
            }}
          >
            {item.label}
          </Typography>
        ))}
    </MaterialBreadcrumbs>
  );
};

export default Breadcrumbs;
