import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Grid,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ItemHead, ItemSeparator } from 'app/design/speedBot/EntityItem';
import { StrategyBuilderEntryExitFormData } from './types';
import StrategyBuilder from 'app/components/Builder/Strategy/StrategyBuilder';
import {
  FieldData,
  FieldDataConditions,
  FieldDataWithValidate,
} from 'app/components/Builder/Strategy/fields/types';
import { StrategyMode } from 'app/components/Builder/Strategy/utils/strategy-data';
import { validateConditions } from 'app/components/Builder/Strategy/utils/StrategyValidation';
import { Condition } from 'app/components/Builder/Strategy/types';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
import { isValidConditions, PositionDirectionData, RiskTypeData } from './util';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

export const PositionDirectionCondition = ({
  title,
  conditionsData,
  isConditionValid,
  setConditionsDataWithValid,
  error,
  updateCopiedStrategy,
  copiedStrategy,
  tooltipKey,
  defaultResolution,
}: {
  title: string;
  tooltipKey?: string;
  conditionsData: FieldData[];
  isConditionValid: boolean;
  setConditionsDataWithValid: (values: FieldDataWithValidate) => void;
  error?: string;
  updateCopiedStrategy: (strategy: Condition) => void;
  copiedStrategy: Condition | undefined;
  defaultResolution?: string;
}) => {
  const setConditions = (conditions: FieldData[]) => {
    const isCorrect = validateConditions(conditions);
    setConditionsDataWithValid({
      conditions: conditions,
      isConditionValid: isCorrect,
    });
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Box display="flex" alignItems={'center'}>
          <ItemHead variant="small" title={title} />
          <CustomTooltip tooltipKey={tooltipKey}>
            {({ isTooltipAvailable }) =>
              isTooltipAvailable ? (
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              ) : (
                <></>
              )
            }
          </CustomTooltip>
        </Box>
        <Box className="m-main-spacing">
          <ItemSeparator variant="1px" />
        </Box>
      </Box>
      <Box className="m-main-spacing" sx={{ mb: 1 }}>
        <StrategyBuilder
          setStrategy={setConditions}
          inputConditions={conditionsData}
          updateCopiedStrategy={updateCopiedStrategy}
          copiedStrategy={copiedStrategy}
          mode={StrategyMode.EDIT}
          isValid={isConditionValid}
          defaultResolution={defaultResolution}
        />
      </Box>
      {error && (
        <Box className="m-main-spacing">
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </Box>
  );
};

export default function StrategyBuilderEntryExitTab({
  entryExitData,
  setEntryExit,
  handleBack,
  defaultResolution,
}: {
  entryExitData: StrategyBuilderEntryExitFormData;
  setEntryExit: (values: StrategyBuilderEntryExitFormData) => void;
  handleBack: (values: number) => void;
  defaultResolution?: string;
}) {
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const handleContinue = (values: StrategyBuilderEntryExitFormData) => {
    setEntryExit(values);
  };

  const [copiedStrategy, setCopiedStrategy] = React.useState<Condition>();

  const [invalidConditionDirection, setInvalidConditionDirection] =
    React.useState<string>('');
  const handleInvalidConditionDirection = () => {
    setInvalidConditionDirection('');
  };

  return (
    <>
      <Formik
        initialValues={{
          positionDirection: entryExitData.positionDirection
            ? entryExitData.positionDirection
            : '',
          targetType: entryExitData.targetType ? entryExitData.targetType : '',
          target:
            entryExitData && entryExitData.target
              ? entryExitData.target
              : undefined,
          stoploss: entryExitData.stoploss ? entryExitData.stoploss : undefined,
          isTrailStoploss: entryExitData.isTrailStoploss
            ? entryExitData.isTrailStoploss
            : false,
          trailStoploss: entryExitData.trailStoploss
            ? entryExitData.trailStoploss
            : undefined,
          shortEntryConditions: entryExitData.shortEntryConditions
            ? entryExitData.shortEntryConditions
            : ({
                conditions: [],
                isConditionValid: true,
              } as FieldDataWithValidate),
          shortExitConditions: entryExitData.shortExitConditions
            ? entryExitData.shortExitConditions
            : ({
                conditions: [],
                isConditionValid: true,
              } as FieldDataWithValidate),
          longEntryConditions: entryExitData.longEntryConditions
            ? entryExitData.longEntryConditions
            : ({
                conditions: [],
                isConditionValid: true,
              } as FieldDataWithValidate),
          longExitConditions: entryExitData.longExitConditions
            ? entryExitData.longExitConditions
            : ({
                conditions: [],
                isConditionValid: true,
              } as FieldDataWithValidate),
        }}
        validationSchema={Yup.object().shape({
          isTrailStoploss: Yup.boolean(),
          positionDirection: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.position_direction'),
            }),
          ),
          targetType: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.target_stop_loss_type'),
            }),
          ),
          target: Yup.number()
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_bot_startegy.form.target'),
              }),
            )
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('user_bot_startegy.form.target'),
              }),
            ),
          stoploss: Yup.number()
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_bot_startegy.form.stoploss'),
              }),
            )
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('user_bot_startegy.form.stoploss'),
              }),
            ),
          trailStoploss: Yup.number().when('isTrailStoploss', {
            is: true,
            then: Yup.number().required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_bot_startegy.form.trail_stoploss'),
              }),
            ),
          }),
          shortEntryConditions: Yup.object().when('positionDirection', {
            is: value => value === 'short' || value === 'both',
            then: Yup.object().shape({
              conditions: Yup.array()
                .min(
                  1,
                  t('user_backtesting.valid_msg.req', {
                    name: t('user_bot_startegy.form.short_entry_condition'),
                  }),
                )
                .required(
                  t('user_backtesting.valid_msg.req', {
                    name: t('user_bot_startegy.form.short_entry_condition'),
                  }),
                ),
            }),
          }),
          longEntryConditions: Yup.object().when('positionDirection', {
            is: value => value === 'long' || value === 'both',
            then: Yup.object().shape({
              conditions: Yup.array()
                .min(
                  1,
                  t('user_backtesting.valid_msg.req', {
                    name: t('user_bot_startegy.form.long_entry_condition'),
                  }),
                )
                .required(
                  t('user_backtesting.valid_msg.req', {
                    name: t('user_bot_startegy.form.long_entry_condition'),
                  }),
                ),
            }),
          }),
        })}
        onSubmit={values => {
          // If confirmation modal open then we have to proceed for next step
          if (invalidConditionDirection === '') {
            // Check conditions and valid
            //Open confirm modal if invalid otherwise proceed for next step
            if (
              values.positionDirection === 'short' ||
              values.positionDirection === 'both'
            ) {
              if (!isValidConditions(values.shortEntryConditions, 'entry')) {
                setInvalidConditionDirection(
                  t('user_bot_startegy.short_entry'),
                );
                return;
              }
              if (!isValidConditions(values.shortExitConditions, 'exit')) {
                setInvalidConditionDirection(t('user_bot_startegy.short_exit'));
                return;
              }
            }
            if (
              values.positionDirection === 'long' ||
              values.positionDirection === 'both'
            ) {
              if (!isValidConditions(values.longEntryConditions, 'entry')) {
                setInvalidConditionDirection(t('user_bot_startegy.long_entry'));
                return;
              }
              if (!isValidConditions(values.longExitConditions, 'exit')) {
                setInvalidConditionDirection(t('user_bot_startegy.long_exit'));
                return;
              }
            }
          }
          handleContinue(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          isValid,
        }) => {
          const targetType = values.targetType
            ? values.targetType === 'percentage'
              ? '%'
              : t('user_bot_startegy.form.points')
            : '';
          return (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box sx={{ mb: 2, flex: 1 }}>
                <Box className="m-main-spacing">
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                      <FormControl
                        fullWidth
                        sx={{ mb: 3 }}
                        error={Boolean(
                          touched.positionDirection && errors.positionDirection,
                        )}
                      >
                        <InputLabel id="label-positionDirection">
                          <FormattedMessage id="user_bot_startegy.form.position_direction" />
                        </InputLabel>
                        <Select
                          labelId="label-positionDirection"
                          name="positionDirection"
                          value={values.positionDirection}
                          label={t('user_bot_startegy.form.position_direction')}
                          onChange={e => {
                            setFieldValue('positionDirection', e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end" sx={{ mr: 1 }}>
                              <CustomTooltip
                                tooltipKey={'eq_position_direction'}
                              >
                                {({ isTooltipAvailable }) =>
                                  isTooltipAvailable ? (
                                    <IconButton>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                }
                              </CustomTooltip>
                            </InputAdornment>
                          }
                        >
                          {PositionDirectionData.map(item => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.positionDirection &&
                          errors.positionDirection && (
                            <FormHelperText error>
                              {errors.positionDirection}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                {(values.positionDirection === 'long' ||
                  values.positionDirection === 'both') && (
                  <>
                    <PositionDirectionCondition
                      title={`${t(
                        'user_bot_startegy.form.long_entry_condition',
                      )}`}
                      tooltipKey="eq_long_entry_condition"
                      conditionsData={values.longEntryConditions.conditions}
                      isConditionValid={
                        values.longEntryConditions.isConditionValid
                      }
                      setConditionsDataWithValid={(
                        values: FieldDataWithValidate | FieldDataConditions,
                      ) => setFieldValue('longEntryConditions', values)}
                      error={
                        errors.longEntryConditions
                          ? (errors.longEntryConditions.conditions as string)
                          : ''
                      }
                      updateCopiedStrategy={setCopiedStrategy}
                      copiedStrategy={copiedStrategy}
                      defaultResolution={defaultResolution}
                    />
                    <PositionDirectionCondition
                      title={`${t(
                        'user_bot_startegy.form.long_exit_condition',
                      )}(${t('user_bot_startegy.optional')})`}
                      tooltipKey="eq_long_exit_condition"
                      conditionsData={values.longExitConditions.conditions}
                      isConditionValid={
                        values.longExitConditions.isConditionValid
                      }
                      setConditionsDataWithValid={(
                        values: FieldDataWithValidate | FieldDataConditions,
                      ) => setFieldValue('longExitConditions', values)}
                      updateCopiedStrategy={setCopiedStrategy}
                      copiedStrategy={copiedStrategy}
                      defaultResolution={defaultResolution}
                    />
                  </>
                )}
                {(values.positionDirection === 'short' ||
                  values.positionDirection === 'both') && (
                  <>
                    <PositionDirectionCondition
                      title={`${t(
                        'user_bot_startegy.form.short_entry_condition',
                      )}`}
                      tooltipKey="eq_short_entry_condition"
                      conditionsData={values.shortEntryConditions.conditions}
                      isConditionValid={
                        values.shortEntryConditions.isConditionValid
                      }
                      setConditionsDataWithValid={(
                        values: FieldDataWithValidate | FieldDataConditions,
                      ) => setFieldValue('shortEntryConditions', values)}
                      error={
                        errors.shortEntryConditions
                          ? (errors.shortEntryConditions.conditions as string)
                          : ''
                      }
                      updateCopiedStrategy={setCopiedStrategy}
                      copiedStrategy={copiedStrategy}
                      defaultResolution={defaultResolution}
                    />
                    <PositionDirectionCondition
                      title={`${t(
                        'user_bot_startegy.form.short_exit_condition',
                      )}(${t('user_bot_startegy.optional')})`}
                      tooltipKey="eq_short_exit_condition"
                      conditionsData={values.shortExitConditions.conditions}
                      isConditionValid={
                        values.shortExitConditions.isConditionValid
                      }
                      setConditionsDataWithValid={(
                        values: FieldDataWithValidate | FieldDataConditions,
                      ) => setFieldValue('shortExitConditions', values)}
                      updateCopiedStrategy={setCopiedStrategy}
                      copiedStrategy={copiedStrategy}
                      defaultResolution={defaultResolution}
                    />
                  </>
                )}

                <Box className="m-main-spacing">
                  <Box sx={{ mb: 3 }}>
                    <ItemHead
                      variant="medium"
                      title={t('user_bot_startegy.form.target_and_stoploss')}
                    />
                    <ItemSeparator variant="2px" />
                  </Box>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControl
                        fullWidth
                        sx={{ mb: 3 }}
                        error={Boolean(touched.targetType && errors.targetType)}
                      >
                        <InputLabel id="label-targetType">
                          <FormattedMessage id="user_bot_startegy.form.target_stop_loss_type" />
                        </InputLabel>
                        <Select
                          labelId="label-targetType"
                          name="targetType"
                          value={values.targetType}
                          label={t(
                            'user_bot_startegy.form.target_stop_loss_type',
                          )}
                          onChange={e => {
                            setFieldValue('targetType', e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end" sx={{ mr: 1 }}>
                              <CustomTooltip tooltipKey={'eq_sl_target_type'}>
                                {({ isTooltipAvailable }) =>
                                  isTooltipAvailable ? (
                                    <IconButton>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                }
                              </CustomTooltip>
                            </InputAdornment>
                          }
                        >
                          {RiskTypeData.map(item => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.targetType && errors.targetType && (
                          <FormHelperText error>
                            {errors.targetType}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: { xs: 'block', lg: 'none' } }}
                    ></Grid>
                    <Grid item xs={6} lg={4}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.target && errors.target)}
                        variant="outlined"
                        sx={{ mb: 3 }}
                      >
                        <InputLabel htmlFor="label-target">
                          {t('user_bot_startegy.form.target')} {targetType}
                        </InputLabel>
                        <OutlinedInput
                          id="label-target"
                          type="number"
                          name="target"
                          value={values.target}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`${t(
                            'user_bot_startegy.form.target',
                          )} ${targetType}`}
                          inputProps={{ min: 0 }}
                          endAdornment={
                            <InputAdornment position="end">
                              <CustomTooltip tooltipKey={'eq_target'}>
                                {({ isTooltipAvailable }) =>
                                  isTooltipAvailable ? (
                                    <IconButton>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                }
                              </CustomTooltip>
                            </InputAdornment>
                          }
                        />
                        {touched.target && errors.target && (
                          <FormHelperText error>{`${t(
                            'user_bot_startegy.form.target',
                          )} ${targetType} ${t(
                            'user_backtesting.valid_msg.is_required',
                          )}`}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.stoploss && errors.stoploss)}
                        variant="outlined"
                        sx={{ mb: 3 }}
                      >
                        <InputLabel htmlFor="label-stoploss">
                          {t('user_bot_startegy.form.stoploss')} {targetType}
                        </InputLabel>
                        <OutlinedInput
                          id="label-stoploss"
                          type="number"
                          name="stoploss"
                          value={values.stoploss}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`Stoploss ${targetType}`}
                          inputProps={{ min: 0 }}
                          endAdornment={
                            <InputAdornment position="end">
                              <CustomTooltip tooltipKey={'eq_stoploss'}>
                                {({ isTooltipAvailable }) =>
                                  isTooltipAvailable ? (
                                    <IconButton>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                }
                              </CustomTooltip>
                            </InputAdornment>
                          }
                        />
                        {touched.stoploss && errors.stoploss && (
                          <FormHelperText error>
                            {`${t(
                              'user_bot_startegy.form.stoploss',
                            )} ${targetType} ${t(
                              'user_backtesting.valid_msg.is_required',
                            )}`}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <FeatureFlag
                      features={[FEATURES.IS_TRAILING_STOPLOSS]}
                      fallback={<></>}
                    >
                      <Grid item xs={6} lg={4}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: { xs: 'center', md: 'start' },
                          }}
                        >
                          <FormControlLabel
                            sx={{ mb: { xs: 3, md: 0 } }}
                            control={
                              <Checkbox
                                sx={{ p: '7px' }}
                                checked={values.isTrailStoploss}
                                onChange={e =>
                                  setFieldValue(
                                    'isTrailStoploss',
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label={`${t(
                              'user_bot_startegy.form.trail_stoploss',
                            )} ${targetType}`}
                          />
                        </Box>
                      </Grid>

                      {values.isTrailStoploss && (
                        <Grid item xs={6} lg={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.trailStoploss && errors.trailStoploss,
                            )}
                            variant="outlined"
                            sx={{ mb: 3 }}
                          >
                            <InputLabel htmlFor="label-trailStoploss">
                              {t('user_bot_startegy.form.trail_stoploss')}{' '}
                              {targetType}
                            </InputLabel>
                            <OutlinedInput
                              id="label-trailStoploss"
                              type="number"
                              name="trailStoploss"
                              value={values.trailStoploss}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={`Trail Stoploss ${targetType}`}
                              inputProps={{ min: 0, step: '0.25' }}
                            />
                            {touched.trailStoploss && errors.trailStoploss && (
                              <FormHelperText error>
                                {`${t(
                                  'user_bot_startegy.form.trail_stoploss',
                                )} ${targetType} ${t(
                                  'user_backtesting.valid_msg.is_required',
                                )} `}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )}
                    </FeatureFlag>
                  </Grid>
                </Box>
              </Box>
              <Box
                className="m-main-spacing"
                sx={{
                  mb: 0,
                  textAlign: 'center',
                  display: { xs: 'block', md: 'flex' },
                  justifyContent: 'center',
                  flexDirection: 'row-reverse',
                }}
              >
                <MuiButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth={!isMdUp}
                  sx={{
                    mb: { xs: 2, md: 0 },
                  }}
                  disabled={!isValid}
                >
                  <FormattedMessage id="buttons.continue" />
                </MuiButton>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth={!isMdUp}
                  onClick={handleBack}
                  sx={{
                    mr: { xs: 0, md: 2 },
                  }}
                >
                  <FormattedMessage id="buttons.back" />
                </MuiButton>
              </Box>

              <SnakbarMessageModal
                open={invalidConditionDirection !== ''}
                handleClose={() => handleInvalidConditionDirection()}
                body={t('user_bot_startegy.entered_invalid_condition', {
                  name: invalidConditionDirection,
                })}
                // body={`You have not entered a valid ${invalidConditionDirection} condition.`}
                footer={
                  <>
                    <Box display="flex" justifyContent="end">
                      <Box>
                        <MuiButton
                          variant="contained"
                          color="secondary"
                          sx={{ mr: 1 }}
                          onClick={handleInvalidConditionDirection}
                        >
                          <FormattedMessage id="buttons.cancel" />
                        </MuiButton>
                      </Box>
                      <Box>
                        <MuiButton
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          <FormattedMessage id="buttons.continue_anyway" />
                        </MuiButton>
                      </Box>
                    </Box>
                  </>
                }
              />
            </Box>
          );
        }}
      </Formik>
    </>
  );
}
