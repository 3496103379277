import { Box, Button, IconButton, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { PRODUCT_TOUR } from 'constants/common';
import { useIsFeatureFlag } from '../FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useTheme } from '@mui/material/styles';

type ProductTourProps = {
  steps: Array<Step>;
  tour_page: string;
};

const ProductTour = ({ steps, tour_page }: ProductTourProps) => {
  const isShowProductTour = useIsFeatureFlag([FEATURES.IS_SHOW_PRODUCT_TOUR]);
  const theme = useTheme();
  const handleCallBack = (data: CallBackProps) => {
    if (data.status === 'finished' || data.status === 'skipped') {
      const localData = JSON.parse(
        localStorage.getItem(PRODUCT_TOUR) || '',
      ) as Record<string, string>;
      localStorage.setItem(
        PRODUCT_TOUR,
        JSON.stringify({ ...localData, [`${tour_page}`]: true }),
      );
    }
  };
  return (
    <>
      {isShowProductTour && (
        <Joyride
          steps={steps}
          continuous={true}
          floaterProps={{
            disableAnimation: true,
          }}
          callback={handleCallBack}
          styles={{
            options: {
              arrowColor: theme.component.productTour.bgColor,
            },
          }}
          spotlightClicks={true}
          showSkipButton={true}
          tooltipComponent={({
            continuous,
            index,
            step,
            backProps,
            closeProps,
            primaryProps,
            skipProps,
            tooltipProps,
            size,
          }) => (
            <Box
              {...tooltipProps}
              sx={{
                bgcolor: theme.component.productTour.bgColor,
                borderRadius: '12px',
                p: 1,
                color: theme.palette.common.white,
              }}
            >
              {step.title && <Typography>{step.title}</Typography>}
              <Typography>{step.content}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    color: theme.palette.common.white,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {index > 0 && (
                    <IconButton
                      {...backProps}
                      sx={{ color: theme.palette.common.white }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                  <Typography>
                    {index + 1}
                    {'/'}
                    {size}
                  </Typography>
                </Box>
                <Box>
                  {continuous && (
                    <Button
                      size="small"
                      {...skipProps}
                      sx={{
                        bgcolor: theme.palette.common.white,
                        borderRadius: '5px',
                        mx: '1px',
                      }}
                    >
                      <FormattedMessage id="skip" />
                    </Button>
                  )}
                  {continuous && (
                    <Button
                      size="small"
                      {...primaryProps}
                      sx={{
                        bgcolor: theme.palette.common.white,
                        borderRadius: '5px',
                        mx: '1px',
                      }}
                    >
                      <FormattedMessage id="next" />
                    </Button>
                  )}
                </Box>
                {!continuous && (
                  <Button {...closeProps} size="small">
                    <FormattedMessage id="close" />
                  </Button>
                )}
              </Box>
            </Box>
          )}
        />
      )}
    </>
  );
};

export default ProductTour;
