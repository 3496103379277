import { Box } from '@mui/material';
import CodeStragyLogsContent from './CodeStrategyLogsContent';
import { useParams } from 'react-router-dom';

const CodeStrategyLogsPage = () => {
  const { botId, type } = useParams<{ botId: string; type: string }>();

  return (
    <Box>
      <CodeStragyLogsContent botId={botId} from="editorPage" type={type} />
    </Box>
  );
};

export default CodeStrategyLogsPage;
