import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { PostCreditTransfer } from 'services/apiServices';
import {
  Errors,
  PostCreditTransferData,
  PostCreditTransferResult,
} from 'types/ApiServicesTypes';

export const usePostCreditTransfer = (successCallback: () => void) => {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostCreditTransferResult,
    Errors,
    Pick<PostCreditTransferData, 'action' | 'amount' | 'to_email'>
  >('PostCreditTransfer', value =>
    PostCreditTransfer(value.action, value.amount, value.to_email)
      .then(res => {
        if (!res || res.error)
          showAlert!(
            res.error
              ? res.error
              : 'Something went wrong. Yout credit not transfered. Please try again.',
          );
        else if (res.success) {
          successCallback();
          showAlert!(
            res.success
              ? 'Credit Transfer Successfully'
              : 'Credit Not Transfered',
            'success',
          );
        }
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
};
