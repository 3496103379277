import { Box, Typography } from '@mui/material';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useTranslation } from 'react-i18next';
import SocialIcon from 'app/components/Menu/SocialIcon';
import MuiChip from 'app/design/uiComponents/MuiChip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { openInNewTab } from 'utils/GenericFunctions';
import { useState } from 'react';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
const Footer = () => {
  const { t } = useTranslation();
  const { data: siteInfo } = useGetSiteInfo();
  const policyData = [
    { title: 'Refund Policy', url: siteInfo?.refund_policy },
    { title: 'Privacy Policy', url: siteInfo?.privacy_policy },
    { title: 'Terms & Conditions', url: siteInfo?.terms_and_conditions },
  ];
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const isShowPolicyButtons = useIsFeatureFlag([
    FEATURES.IS_SHOW_POLICY_BUTTON,
  ]);
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      {siteInfo && (
        <Box
          sx={{
            py: 3,
            mt: 3,
            '& p': { m: 0 },
            backgroundColor: 'grey.100',
            textAlign: 'justify',
          }}
          className="p-main-spacing"
        >
          {isShowPolicyButtons && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={1}
              mb={1}
            >
              {policyData.flatMap((policy, index) => {
                return policy.url
                  ? [
                      <Box
                        sx={{ flexGrow: !isMdUp ? 1 : 0 }}
                        key={index}
                        onClick={() => openInNewTab(policy.url as string)}
                      >
                        <MuiChip
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          sx={{ cursor: 'pointer' }}
                          label={
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              gap={1}
                            >
                              <Typography variant="body2">
                                {policy.title}
                              </Typography>
                              <OpenInNewIcon
                                color="action"
                                sx={{ fontSize: '16px' }}
                              />
                            </Box>
                          }
                          color={
                            hoveredIndex === index ? 'infolight' : 'secondary'
                          }
                          size="small"
                          // icon={<OpenInNewIcon />}
                        />
                      </Box>,
                    ]
                  : [];
              })}
            </Box>
          )}
          {/* <Box sx={{ mb: 4 }}>
                    <ItemSeparator variant="2px" />
                  </Box> */}
          <Typography
            variant="body3"
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __html: siteInfo.disclaimer,
            }}
          ></Typography>
          {siteInfo.social_telegram ||
          siteInfo.social_twitter ||
          siteInfo.social_youtube ||
          siteInfo.social_instagram ||
          siteInfo.social_tiktok ? (
            <Box
              sx={{
                mt: 1,
                gap: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography>{t('menu.follow_us_on')}</Typography>
              <SocialIcon name={'Twitter'} link={siteInfo.social_twitter} />
              <SocialIcon name={'YouTube'} link={siteInfo.social_youtube} />
              <SocialIcon name={'Telegram'} link={siteInfo.social_telegram} />
              <SocialIcon name={'Instagram'} link={siteInfo.social_instagram} />
              <SocialIcon name={'Tiktok'} link={siteInfo.social_tiktok} />
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default Footer;
