import { useEffect, useMemo } from 'react';
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  Line,
  ComposedChart,
  Brush,
} from 'recharts';
import { Position } from './blacknScholes';
import { PositionMetaData } from '../OptionChain/types';
import CustomTooltip from './CustomTooltip/CustomToolTipPayoff';
import { generateDataPoint, generateData } from './utils';
import { useTheme } from '@mui/material';
import { FutureDataType } from 'types/ApiServicesTypes';

const PayoffChart = ({
  positions,
  setMaxProfitLoss,
  date,
  lotSize,
  spot_price,
  futureData,
}: {
  positions: PositionMetaData[];
  date: string;
  setMaxProfitLoss: (value: {
    max_profit: string | number;
    max_loss: string | number;
    breakevenPoints: number[];
  }) => void;
  lotSize: number;
  spot_price: number | undefined;
  futureData: Record<string, FutureDataType>;
}) => {
  // Calculate strikes, min and max strike prices
  const strikes = positions.flatMap(p =>
    p.contractType === 'fut'
      ? Object.values(p.positionCandleWise).map(position =>
          Number(position.premium),
        )
      : Number(p.strike),
  );
  const minStrike = Math.min(...strikes) - 1500;
  const maxStrike = Math.max(...strikes) + 1500;
  const {
    component: {
      optionChainGraphColors: {
        areaNegative,
        areaPositive,
        cartesianGrid,
        line,
        yaxis,
      },
    },
  } = useTheme();

  // Generate spot prices
  const spot_prices = useMemo(
    () => generateDataPoint({ minStrike: minStrike, maxStrike: maxStrike }),
    [minStrike, maxStrike],
  );

  // Calculate expiry and real-time payoffs
  const {
    expiry_payoffs,
    real_time_payoffs,
  }: { expiry_payoffs: number[]; real_time_payoffs: number[] } = useMemo(() => {
    const expiry_payoffs = new Array(spot_prices.length).fill(0);
    const real_time_payoffs = new Array(spot_prices.length).fill(0);

    positions.forEach(pos_data => {
      Object.values(pos_data.positionCandleWise || {}).forEach(position => {
        const p = new Position({
          strike: pos_data.strike,
          contract_type: pos_data.contractType,
          direction: position.direction,
          premium: position.premium,
          quantity: Number(position.lots) * lotSize,
          entry_time: position.entryTime,
          expiry: pos_data.expiryDate,
          candle_time: date,
          risk_free_interest_rate: 0.070797, // default risk_free_interest_rate
          iv: Number(position.contractDetails.iv) / 100, // default iv
          spot_price_at_entry: 0,
          current_spot_price: spot_price || 1,
          current_contract_price:
            pos_data.contractType === 'fut'
              ? futureData[`${pos_data.strike}`]
                ? futureData[`${pos_data.strike}`].close
                : 1
              : 1,
        });
        const positionExpiryPayoffs = p.payoff(spot_prices);
        const positionRealTimePayoffs = p.real_time_payoff(spot_prices);

        positionExpiryPayoffs.forEach((payoff, idx) => {
          expiry_payoffs[idx] += payoff;
        });

        positionRealTimePayoffs.forEach((payoff, idx) => {
          real_time_payoffs[idx] += payoff;
        });
      });
    });

    return { expiry_payoffs, real_time_payoffs };
  }, [positions, spot_prices, date, lotSize, futureData, spot_price]);

  // Generate data
  const data = useMemo(
    () => generateData(expiry_payoffs, real_time_payoffs, minStrike, maxStrike),
    [expiry_payoffs, real_time_payoffs, minStrike, maxStrike],
  );
  // const [state, setState] = useState({
  //   data: data.slice(),
  //   refAreaLeft: '',
  //   refAreaRight: '',
  //   left: 'dataMin',
  //   right: 'dataMax',
  //   top: 'dataMax+1',
  //   bottom: 'dataMin',
  //   top2: 'dataMax+50',
  //   bottom2: 'dataMin+50',
  // });

  // // Calculate max profit and max loss
  // const data = generateData(
  //   expiry_payoffs,
  //   real_time_payoffs,
  //   minStrike,
  //   maxStrike,
  // );

  const { maxProfit, maxLoss } = useMemo(() => {
    let maxProfit = Math.max(...expiry_payoffs);
    let maxLoss = Math.min(...expiry_payoffs);

    if (data.length > 0) {
      const firstPayoff = data[0].payoff;
      const lastPayoff = data[data.length - 1].payoff;

      if (
        firstPayoff === maxProfit &&
        data.slice(1).every(point => point.payoff !== firstPayoff)
      ) {
        maxProfit = Infinity;
      }
      if (
        firstPayoff === maxLoss &&
        data.slice(1).every(point => point.payoff !== firstPayoff)
      ) {
        maxLoss = -Infinity;
      }

      if (
        lastPayoff === maxProfit &&
        data.slice(0, -1).every(point => point.payoff !== lastPayoff)
      ) {
        maxProfit = Infinity;
      }
      if (
        lastPayoff === maxLoss &&
        data.slice(0, -1).every(point => point.payoff !== lastPayoff)
      ) {
        maxLoss = -Infinity;
      }

      const maxPayoff = Math.max(...data.map(point => point.payoff));
      if (
        firstPayoff === lastPayoff &&
        firstPayoff === maxPayoff &&
        data.slice(1, -1).every(point => point.payoff !== firstPayoff)
      ) {
        maxProfit = Infinity;
      }

      const minPayoff = Math.min(...data.map(point => point.payoff));
      if (
        firstPayoff === lastPayoff &&
        firstPayoff === minPayoff &&
        data.slice(1, -1).every(point => point.payoff !== firstPayoff)
      ) {
        maxLoss = -Infinity;
      }
    }

    return { maxProfit, maxLoss };
  }, [expiry_payoffs, data]);

  const breakevenPoints = useMemo(() => {
    const points: number[] = [];
    for (let i = 1; i < data.length; i++) {
      const previousPayoff = data[i - 1].payoff;
      const currentPayoff = data[i].payoff;

      if (
        (previousPayoff > 0 && currentPayoff < 0) ||
        (previousPayoff < 0 && currentPayoff > 0)
      ) {
        points.push(data[i].underlyingPrice);
      }
    }
    return points;
  }, [data]);

  useEffect(() => {
    setMaxProfitLoss({
      max_loss: maxLoss,
      max_profit: maxProfit,
      breakevenPoints: breakevenPoints,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxLoss, maxProfit, setMaxProfitLoss]);
  // function zoom() {
  //   let { refAreaLeft, refAreaRight } = state;
  //   const { data } = state;

  //   if (state.refAreaLeft === state.refAreaRight || refAreaRight === '') {
  //     setState({ ...state, refAreaRight: '', refAreaLeft: '' });
  //     return;
  //   }

  //   // xAxis domain
  //   if (refAreaLeft > refAreaRight)
  //     [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

  //   // yAxis domain
  //   // const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'cost', 1);
  //   // const [bottom2, top2] = getAxisYDomain(
  //   //   refAreaLeft,
  //   //   refAreaRight,
  //   //   'impression',
  //   //   50,
  //   // );

  //   // setState(() => ({
  //   //   refAreaLeft: '',
  //   //   refAreaRight: '',
  //   //   data: data.slice(),
  //   //   left: refAreaLeft,
  //   //   right: refAreaRight,
  //   //   bottom,
  //   //   top,
  //   //   bottom2,
  //   //   top2,
  //   // }));
  // }
  // const ref = useRef<>();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{ top: 20, right: 10, left: 10, bottom: 10 }}
      >
        <CartesianGrid
          stroke={cartesianGrid.stroke}
          fill={cartesianGrid.fill}
          strokeWidth={2}
        />
        <XAxis
          dataKey="underlyingPrice"
          tick={{ fontFamily: 'Inter, sans-serif', fontWeight: '900' }}
        />
        <YAxis
          // domain={[maxProfit.toFixed(2), maxProfit.toFixed(2)]}
          dataKey={'payoff'}
          tick={{
            fontFamily: 'Inter, sans-serif',
            fontWeight: '900',
            color: yaxis,
          }}
        />
        <Tooltip
          position={{ y: 0 }}
          isAnimationActive={false}
          cursor={{ stroke: yaxis, strokeWidth: 0.5, strokeDasharray: 6 }}
          content={<CustomTooltip active={false} label="" payload={[]} />}
        />
        <Area
          type="monotone"
          dataKey="positive"
          stroke={areaPositive.stroke}
          fill={areaPositive.fill}
          fillOpacity={0.5}
          strokeWidth={2.5}
        />
        <Area
          strokeWidth={2.5}
          type="monotone"
          dataKey="negative"
          stroke={areaNegative.stroke}
          fill={areaNegative.fill}
          fillOpacity={0.5}
        />
        <Line
          strokeWidth={2}
          type="monotone"
          strokeDasharray={4}
          strokeLinecap="round"
          dataKey="currentPayoff"
          stroke={line}
          dot={false}
        />
        <ReferenceLine y={0} stroke={yaxis} />
        {/* {state.refAreaLeft && state.refAreaRight ? (
          <ReferenceArea
            yAxisId="1"
            x1={state.refAreaLeft}
            x2={state.refAreaRight}
            strokeOpacity={0.3}
          />
        ) : null} */}

        <Brush dataKey="underlyingPrice" height={30} stroke="#8884d8" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default PayoffChart;
