import { Box } from '@mui/material';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { PageTitleBackVariant, PageTitleWithIconVariant } from '.';
import { PageTitleWithIconWithBackVariantProps } from '../types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const PageTitleWithIconWithBackVariant = ({
  title,
  iconProps,
  subButton,
  buttonText,
  buttonHandleClick,
  breadcrumbLinks,
  breadcrumb,
  secondButton,
}: PageTitleWithIconWithBackVariantProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <Box>
      {!isMdUp ? (
        <PageTitleBackVariant
          buttonText={buttonText}
          buttonHandleClick={buttonHandleClick}
        />
      ) : breadcrumb !== undefined ? (
        <Box className="m-main-spacing" sx={{ pt: 3, pb: 0 }}>
          {breadcrumb}
        </Box>
      ) : (
        <Box className="m-main-spacing" sx={{ pt: 3, pb: 0 }}>
          <MuiBreadcrumbs links={breadcrumbLinks!} />
        </Box>
      )}
      <PageTitleWithIconVariant
        title={title}
        iconProps={iconProps}
        subButton={subButton}
        secondButton={secondButton}
      />
    </Box>
  );
};

export default PageTitleWithIconWithBackVariant;
