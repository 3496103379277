import { Alert, Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useTranslation } from 'react-i18next';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { ProfieUserPlan } from 'types/ApiServicesTypes';
import { UserPlanType } from 'types/User';
import { formatNumber, getDate } from 'utils/GenericFunctions';

function ActivePlanInfo({
  userPlan,
  walletBalance,
  isFromBilling,
}: {
  userPlan: ProfieUserPlan;
  walletBalance?: number;
  isFromBilling?: boolean;
}) {
  const { t } = useTranslation();
  const planType = userPlan.plan.plan_type;
  const { data: siteInfo } = useGetSiteInfo();

  return (
    <>
      <Box>
        <Box
          sx={{
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <Box width={'100%'}>
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{ pb: 0.5 }}
              color="text.secondary"
            >
              <FormattedMessage id="menu.plan_details" />
            </Typography>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'space-between', md: 'flex-start' },
                gap: 1,
              }}
            >
              <Typography variant="h6" fontWeight={500}>
                {userPlan && userPlan.plan ? userPlan.plan.plan_name : 'Free'}
              </Typography>
              <MuiChip
                label={t(`menu.plan_status.${userPlan.plan.plan_status}`)}
                variant="filled"
                color={
                  userPlan.plan.plan_status === 'active'
                    ? 'infolight'
                    : 'warninglight'
                }
                size="small"
              />
            </Box>
            {userPlan.is_closed && (
              <Alert severity="warning" sx={{ my: 1 }}>
                <Typography variant="body2">
                  Your plan has been cancelled, but it remains valid until{' '}
                  {userPlan.end_date}
                </Typography>
              </Alert>
            )}
          </Box>
          {planType === UserPlanType.PAY_AS_YOU_GO &&
            walletBalance !== undefined && (
              <Box
                sx={{
                  mt: { xs: 1, md: 0 },
                  display: { xs: 'flex', md: 'block' },
                  flexDirection: { xs: 'row-reverse' },
                  justifyContent: { xs: 'space-between', md: 'right' },
                  textAlign: 'right',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color={walletBalance < 0 ? 'error.main' : 'text.primary'}
                >
                  {walletBalance < 0 && '-'}
                  {formatNumber(walletBalance)}
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                  Credit Balance
                </Typography>
              </Box>
            )}
        </Box>
        {isFromBilling && (
          <Typography
            variant="body1"
            display="flex"
            alignItems="end"
            fontWeight={400}
            color={'text.primary'}
            pt={0.5}
          >
            {siteInfo?.currency_symbol || ''}
            {formatNumber(userPlan.recurring_price)}
            <Typography
              variant="body1"
              fontWeight={400}
              color={'text.secondary'}
              sx={{ lineHeight: '24px', pl: 0.5 }}
            >
              / {userPlan.plan.period === 'Years' ? 'year' : 'month'}
            </Typography>
          </Typography>
        )}
      </Box>
      {isFromBilling && (
        <Box sx={{ pt: 3 }}>
          <Typography
            variant="body1"
            fontWeight={400}
            sx={{ pb: 0.5 }}
            color="text.secondary"
          >
            <FormattedMessage id="menu.next_billing_date" />
          </Typography>
          <Typography variant="body1">
            {getDate(userPlan.end_date, false)}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default ActivePlanInfo;
