import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { Authenticate } from 'services/apiServices';
import { Errors, AuthenticateResult } from 'types/ApiServicesTypes';
import { Values } from 'types/SigninPageTypes';

export default function useAuthenticate() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    AuthenticateResult,
    Errors,
    Pick<Values, 'userid' | 'password'>
  >('Authenticate', values =>
    Authenticate(values.userid, values.password)
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
