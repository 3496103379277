import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { PAGES } from '../Common/Breadcrumb/types';

const ModelHeading = ({ label, click }: { label: string; click: string }) => {
  const breadcrumb = useBreadcrumbManager();
  const handleMenuItemclick = (value: string) => {
    breadcrumb.push(
      value !== 'template' ? '/bot-builder?type=' + value : '/templates',
      value === 'template' ? PAGES.TEMPLATES : PAGES.BOTS,
      `/home`,
      'Home',
    );
  };
  return (
    <Box
      display={'flex'}
      sx={{ cursor: 'pointer' }}
      alignItems={'center'}
      onClick={() => handleMenuItemclick(click)}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Typography variant="h6" sx={{ mr: 2 }}>
        {label}
      </Typography>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
          See More
        </Typography>
        <ChevronRightIcon />
      </Box>
    </Box>
  );
};

export default ModelHeading;
