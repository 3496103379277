import React, { createContext } from 'react';
import socketio, { Socket } from 'socket.io-client';

import { getAuthDetails, isUserLoggedIn } from 'utils/auth';

const SOCKET_URL = process.env.SOCKET_URL!;

const getToken = () => {
  const authDetails = getAuthDetails();
  return `session_id=${authDetails.sessionId}; sessionId=${authDetails.sessionId}; userName=${authDetails.userName}; userId=${authDetails.userId}`;
};

let lastTokenUsed: string;
let socket: Socket | undefined;
export const createSocket = (): Socket => {
  const newToken = getToken();
  if (lastTokenUsed !== newToken || !socket) {
    destroySocket();
    socket = socketio(SOCKET_URL, {
      path: '/v2/socket.io',
      auth: { token: getToken(), clientType: 'app' },
      transports: ['websocket', 'polling'],
    });
    lastTokenUsed = newToken;
  }
  return socket;
};

export const destroySocket = () => {
  if (socket) {
    socket.disconnect();
    socket = undefined;
    lastTokenUsed = '';
  }
};

type SocketContextType = {
  socket: Socket | undefined;
  createNewSocket: () => void;
  disconnectSocket: () => void;
};
const initialValue = {
  socket: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  createNewSocket: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnectSocket: () => {},
};

const SocketContext = createContext<SocketContextType>(initialValue);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = React.useState<Socket>();

  const createNewSocket = React.useCallback(() => {
    setSocket(createSocket());
  }, []);

  const disconnectSocket = React.useCallback(() => {
    if (socket) {
      destroySocket();
    }
  }, [socket]);

  React.useEffect(() => {
    if (isUserLoggedIn()) {
      setSocket(createSocket());
    }
  }, []);

  return (
    <SocketContext.Provider
      value={{ socket, createNewSocket, disconnectSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socketContext = React.useContext(SocketContext);
  return socketContext;
};
