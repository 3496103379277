import { GetAllBots } from 'types/ApiServicesTypes';
import BotsCard from './BotsCard';
import { Divider } from '@mui/material';

const BotsListData = ({
  // title,
  bots,
  // type,
  isOwnBot,
}: {
  // title: ReactElement;
  bots: GetAllBots[] | undefined;
  // type: 'active' | 'paper' | 'inactive' | 'draft';
  isOwnBot: boolean;
}) => {
  if (isOwnBot) bots = bots?.filter(x => x.is_own_bot === true);

  return (
    <>
      {bots && bots.length > 0 && (
        <>
          {/* <ItemHead variant="large" title={title} />
        {bots.map((bot, i) => {
          return (
            <Fragment key={i}>
              <Item
                variant="clickableSmallSubtext"
                title={bot.name}
                subtext={bot.days ? bot.days.replace(/ /g, '') : ''}
                {...((bot.is_own_bot || type === 'draft') && {
                  color: 'info.dark',
                })}
                handleClick={() => {
                  // history.push(
                  //   `bots/${bot.botid}/details${
                  //     bot.sub_id ? `/${bot.sub_id}` : ''
                  //   }/Bots`,
                  // );
                  breadcrumb.push(
                    `bots/${bot.botid}/details${
                      bot.sub_id ? `/${bot.sub_id}` : ''
                    }/Bots`,
                    PAGES.BOTS,
                    `/bots`,
                    t('bots.page_title.bots'),
                  );
                }}
              />
              {bots.length - 1 !== i && <Divider />}
            </Fragment>
          );
        })} */}

          {bots.map((bot, i) => {
            return (
              <>
                <Divider sx={{ flex: 1, borderColor: 'grey.200', mx: 2 }} />
                <BotsCard bots={bot} key={i} />
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default BotsListData;
