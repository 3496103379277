import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';

const BotDeployModalFeatureFallbackContent = ({
  botName,
  handleClose,
}: {
  botName: string;
  handleClose: (isSuccess: boolean, message?: string) => void;
}) => {
  return (
    <Box
      className="m-main-spacing"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box flex={1}>
        <Typography variant="body1" sx={{ pb: 4, fontWeight: 400 }}>
          Are you sure you want to deploy <b>{botName}</b> Live?
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          What is Live Trading?
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          <ul style={{ marginTop: 0, marginBottom: 24 }}>
            <li>Let bot trades on behalf of you!</li>
            <li>
              Bot will place trades automatically, you don&apos;t need to do
              anything.
            </li>
            <li>Bot is totally in your control. You can stop them anytime.</li>
          </ul>
          <b>
            This feature is still in active development and we will release it
            very soon.
          </b>
        </Typography>
      </Box>
      <Box>
        <MuiButton
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleClose}
        >
          Cancel
        </MuiButton>
      </Box>
    </Box>
  );
};

export default BotDeployModalFeatureFallbackContent;
