import {
  //  Divider,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { UseGetPortfolioRes } from 'services/Portfolio/useGetPortfolioActivePaper';
import {
  decimalFormat,
  formatNumber,
  getArrowDirByNum,
  getColorByNum,
} from 'utils/GenericFunctions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useCalcuatePortfolioSummaryUIData } from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioSummary';
import { GetSiteInfoResult } from 'types/ApiServicesTypes';
type SummaryCardProps = {
  portfolioData: UseGetPortfolioRes;
  siteInfo: GetSiteInfoResult;
};
const SummaryCard = ({ portfolioData, siteInfo }: SummaryCardProps) => {
  const cardValue = portfolioData && portfolioData?.active?.data;
  const uiData = useCalcuatePortfolioSummaryUIData(
    portfolioData.active,
    siteInfo,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        border: '1px solid #9898983d',
        borderRadius: '0.5em',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'stretch', md: 'center' },
        justifyContent: 'space-between',
        padding: 2,
        minHeight: '82px',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', md: 'column' },
          alignItems: 'flex-start',
          justifyContent: { xs: 'space-between', md: undefined },
        }}
      >
        <Typography variant="body3" fontWeight={500}>
          Today’s change
        </Typography>
        {cardValue && (
          <Typography
            variant={'body3'}
            display="inline"
            color={getColorByNum(uiData.todays_change)}
          >
            {`${getArrowDirByNum(uiData.todays_change)} ${
              portfolioData?.active.bots[0]?.currency_symbol
            }${formatNumber(uiData.todays_change, false, 0)}`}
          </Typography>
        )}
      </Box>
      {/* <Divider
        sx={{ borderRight: '1px solid #9898983d' }}
        orientation="vertical"
        flexItem
      /> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', md: 'column' },
          alignItems: 'flex-start',
          justifyContent: { xs: 'space-between', md: undefined },
        }}
      >
        <Typography variant="body3" fontWeight={500}>
          Realized P&L
        </Typography>
        {portfolioData && (
          <Typography
            variant={'body3'}
            color={getColorByNum(
              portfolioData?.active?.data?.total_profit_loss,
            )}
          >
            {`${getArrowDirByNum(uiData.total_profit_loss)} ${
              portfolioData?.active?.bots[0]?.currency_symbol
            }${formatNumber(
              uiData.total_profit_loss,
              false,
              0,
            )} (${decimalFormat(uiData.total_profit_loss_per)}%)`}
          </Typography>
        )}
      </Box>
      {/* <Divider
        sx={{ borderRight: '1px solid #9898983d' }}
        orientation="vertical"
        flexItem
      /> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', md: 'column' },
          alignItems: 'flex-start',
          justifyContent: { xs: 'space-between', md: undefined },
        }}
      >
        <Typography variant="body3" fontWeight={500}>
          Portfolio Value
        </Typography>
        <Box display={'flex'}>
          {cardValue && (
            <Typography variant={'body3'} color="text.secondary">
              {portfolioData?.active.bots[0]?.currency_symbol}
              {formatNumber(uiData.initial_capital, true)}
            </Typography>
          )}
          <ArrowForwardIcon sx={{ fontSize: '12px', mx: 0.5 }} />
          {cardValue && (
            <Typography
              variant={'body3'}
              color={getColorByNum(cardValue?.total_profit_loss)}
            >{`${portfolioData?.active.bots[0]?.currency_symbol}${formatNumber(
              uiData.portfolio_value,
              true,
            )}`}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryCard;
