const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  payload: { name: string; color: string; value: number }[];
  label: string;
}) => {
  if (active && payload && payload.length) {
    const expiryValue = (
      payload.find(p => p.name === 'positive')?.value ||
      payload.find(p => p.name === 'negative')?.value ||
      0
    ).toFixed(2);
    const currentPayoff = payload
      .find(p => p.name === 'currentPayoff')
      ?.value.toFixed(2);

    return (
      <div className="tooltip-container">
        <div>Underlying price</div>
        <div className="value">
          {label}
          <span className="currency">&#8377;</span>
        </div>
        {currentPayoff && (
          <>
            <div>
              <span
                className="legend"
                style={{
                  color: payload.find(p => p.name === 'currentPayoff')!.color,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 8"
                  width="8"
                  height="8"
                >
                  <path
                    stroke="currentColor"
                    strokeDasharray="1 3"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M1 7L8 0"
                  ></path>
                </svg>
              </span>
              Current P&L
            </div>
            <div className="value">
              {currentPayoff}
              <span className="currency">&#8377;</span>
            </div>
          </>
        )}
        <div>
          <span
            className="legend"
            style={{
              color:
                payload.find(p => p.name === 'positive')?.color ||
                payload.find(p => p.name === 'negative')?.color,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 8"
              width="8"
              height="8"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M1 7L8 0"
              ></path>
            </svg>
          </span>
          Expiration P&L
        </div>
        <div className="value">
          {expiryValue}
          <span className="currency">&#8377;</span>
        </div>
      </div>
    );
  }

  return null;
};
export default CustomTooltip;
