export const HomePageProductSteps = [
  {
    target: '.create-bot',
    content: 'Create your equity or options bot here easily.',
    title: 'Step 1',
    disableBeacon: true,
  },
  {
    target: '.default-template',
    content:
      'Use our default bot template to easily create your own customized bot.',
    title: 'Step 2',
    disableBeacon: true,
  },
];

export const BotPageProductSteps = (isBots: boolean) => {
  const steps = [
    {
      target: '.build-strategy',
      content: 'Build Equity or Option Strategy',
      title: 'Step 1',
      disableBeacon: true,
    },
    {
      target: '.forward-testing',
      content: 'Start trading with dummy money',
      title: 'Step 2',
      disableBeacon: true,
    },
    {
      target: '.bot-positions',
      content:
        'This is for switching between your bots and positions list view.',
      title: 'Step 3',
      disableBeacon: true,
    },
    {
      target: '.all-bots',
      content: 'Track Your Bots',
      title: 'Step 4',
      disableBeacon: true,
    },
  ];
  if (isBots) {
    steps.shift();
    return steps.map((step, index) => {
      return { ...step, title: `Step ${index + 1}` };
    });
  }
  return steps;
};

export const TemplatePageProductSteps = [
  {
    target: '.ready-strategy',
    content: 'You can use Ready-to-Use Strategy Templates',
    title: 'Step 1',
    disableBeacon: true,
  },
];

export const BotTemplateDetailsPageSteps = [
  {
    target: '.conditions',
    content:
      'Carefully review this condition. The bot will execute your trade in these specific situations.',
    title: 'Step 1',
    disableBeacon: true,
  },
  {
    target: '.build-button',
    content: 'Click here to customize this condition or keep it as is.',
    title: 'Step 2',
    disableBeacon: true,
  },
];

export const ReportPageProductSteps = [
  {
    target: '.report-filter',
    content: 'You can filter out your P&L Report',
    title: 'Step 1',
    disableBeacon: true,
  },
];

export const BrokerAddSteps = [
  {
    target: '.add-broker-button',
    content: 'You can now add a new broker easily.',
    title: 'Step 1',
    disableBeacon: true,
  },
];

export const PlanPageSteps = [
  {
    target: '.plans',
    content:
      'Choosing the perfect plan is quick and easy. Find the one that fits your needs in just a few clicks!',
    title: 'Step 1',
    disableBeacon: true,
  },
];
