import { memo, MouseEvent, useCallback, useMemo, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MuiBuilderButton from 'app/design/uiComponents/MuiBuilderButton';
import OptionSetStraddleModal from './OptionSetStraddleModal';
import OptionSetStrangleModal from './OptionSetStrangleModal';
import OptionSetSpreadModal from './OptionSetSpreadModal';
import OptionSetIronFlyModal from './OptionSetIronFlyModal';
import OptionSetIronCondorModal from './OptionSetIronCondorModal';
import { OptionSetTeplateValueType } from './types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  createContractSelectionOptions,
  ExpiryTypeValueOptions,
} from '../FieldsData';
import { FIN_NIFTY_SYMBOL, NIFTY_SYMBOL } from 'constants/common';
import { DropdownFieldValue } from '../types';

export type OptionSetTemplatesProps = {
  isFinNify: boolean;
  handleAddOptionLeg: (
    source: string,
    value: OptionSetTeplateValueType,
  ) => void;
};

const OptionSetTemplates = ({
  handleAddOptionLeg,
  isFinNify,
}: OptionSetTemplatesProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  const [openStraddleModal, setOpenStraddleModal] = useState(false);
  const [openStrangleModal, setOpenStrangleModal] = useState(false);
  const [openSpreadModal, setOpenSpreadModal] = useState(false);
  const [openIronFlyModal, setOpenIronFlyModal] = useState(false);
  const [openIronCondorModal, setOpenIronCondorModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseStraddle = useCallback(
    () => setOpenStraddleModal(false),
    [],
  );
  const handleCloseStrangle = useCallback(
    () => setOpenStrangleModal(false),
    [],
  );
  const handleCloseSpread = useCallback(() => setOpenSpreadModal(false), []);
  const handleCloseIronFly = useCallback(() => setOpenIronFlyModal(false), []);
  const handleCloseIronCondor = useCallback(
    () => setOpenIronCondorModal(false),
    [],
  );

  const handleApplyTemplate = useCallback(
    (source: string, value: OptionSetTeplateValueType) => {
      handleAddOptionLeg(source, value);
    },
    [handleAddOptionLeg],
  );

  const contractSelectionOptions = useMemo(
    () =>
      createContractSelectionOptions(
        isFinNify ? FIN_NIFTY_SYMBOL : NIFTY_SYMBOL,
        'strike',
        'call',
      ),
    [isFinNify],
  );

  const expiryTypeValueOptions = useMemo(() => {
    return ExpiryTypeValueOptions as DropdownFieldValue[];
  }, []);

  return (
    <>
      {isMdUp ? (
        <>
          <MuiBuilderButton
            startIcon={<AddIcon />}
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={() => setOpenStraddleModal(true)}
          >
            Straddle
          </MuiBuilderButton>
          <MuiBuilderButton
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpenStrangleModal(true)}
          >
            Strangle
          </MuiBuilderButton>
          <MuiBuilderButton
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpenSpreadModal(true)}
          >
            Spread
          </MuiBuilderButton>
          <MuiBuilderButton
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpenIronFlyModal(true)}
          >
            Iron Fly
          </MuiBuilderButton>
          <MuiBuilderButton
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpenIronCondorModal(true)}
          >
            Iron Condor
          </MuiBuilderButton>
        </>
      ) : (
        <>
          <MuiBuilderButton
            startIcon={<AddIcon />}
            endIcon={<ArrowDropDownIcon />}
            variant="outlined"
            sx={{ ml: 1 }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
          >
            Template
          </MuiBuilderButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          >
            <MenuItem onClick={() => setOpenStraddleModal(true)}>
              Straddle
            </MenuItem>
            <MenuItem onClick={() => setOpenStrangleModal(true)}>
              Strangle
            </MenuItem>
            <MenuItem onClick={() => setOpenSpreadModal(true)}>Spread</MenuItem>
            <MenuItem onClick={() => setOpenIronFlyModal(true)}>
              Iron Fly
            </MenuItem>
            <MenuItem onClick={() => setOpenIronCondorModal(true)}>
              Iron Condor
            </MenuItem>
          </Menu>
        </>
      )}
      <OptionSetStraddleModal
        open={openStraddleModal}
        handleClose={handleCloseStraddle}
        handleApplyTemplate={handleApplyTemplate}
        contractSelectionOptions={contractSelectionOptions}
        expiryTypeValueOptions={expiryTypeValueOptions}
      />
      <OptionSetStrangleModal
        open={openStrangleModal}
        handleClose={handleCloseStrangle}
        handleApplyTemplate={handleApplyTemplate}
        contractSelectionOptions={contractSelectionOptions}
        expiryTypeValueOptions={expiryTypeValueOptions}
      />
      <OptionSetSpreadModal
        open={openSpreadModal}
        handleClose={handleCloseSpread}
        handleApplyTemplate={handleApplyTemplate}
        contractSelectionOptions={contractSelectionOptions}
        expiryTypeValueOptions={expiryTypeValueOptions}
      />
      <OptionSetIronFlyModal
        open={openIronFlyModal}
        handleClose={handleCloseIronFly}
        handleApplyTemplate={handleApplyTemplate}
        contractSelectionOptions={contractSelectionOptions}
        expiryTypeValueOptions={expiryTypeValueOptions}
      />
      <OptionSetIronCondorModal
        open={openIronCondorModal}
        handleClose={handleCloseIronCondor}
        handleApplyTemplate={handleApplyTemplate}
        contractSelectionOptions={contractSelectionOptions}
        expiryTypeValueOptions={expiryTypeValueOptions}
      />
    </>
  );
};

export default memo(OptionSetTemplates);
