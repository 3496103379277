import { useState, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import PlanRadioGroup from './PlanRadioGroup';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { SubscriptionTransformPlan } from 'types/SubscriptionPlans';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

const PlanChangePageContent = ({
  plans,
  setPlanInfo,
  existingPlanId,
  handleBack,
}: {
  plans: SubscriptionTransformPlan[] | undefined;
  setPlanInfo: (value: SubscriptionTransformPlan) => void;
  existingPlanId?: number | undefined;
  handleBack?: () => void;
}) => {
  const { data: siteInfo } = useGetSiteInfo();
  const isMdUp = useGetMediaQueryUp('md');
  const [selectedPlan, setSelectedPlan] = useState<number>(0);

  const handleChangePlan = (val: number) => {
    setSelectedPlan(val);
  };

  const handleContinueClick = () => {
    if (plans && selectedPlan) {
      const plan = plans.filter(x => x.id === selectedPlan)[0];
      setPlanInfo(plan);
    }
  };

  const currencySymbol = siteInfo?.currency_symbol || '';

  return (
    <>
      {!isMdUp && (
        <PageHeader
          variant="common"
          title={<FormattedMessage id="menu.page_title.plan_change" />}
        />
      )}
      <Box sx={{ flex: 1 }}>
        <Box className="m-main-spacing" sx={{ mb: 1, flex: 1 }}>
          <Typography variant="body1" fontWeight={400}>
            <FormattedMessage id="menu.select_your_plan" />
          </Typography>
          {/* {existingPlanId !== undefined && existingPlanId > 0 && (
            <Alert severity="warning" sx={{ mt: 1 }}>
              <Typography variant="body2">
                <FormattedMessage id="menu.change_plan_warn" />
              </Typography>
            </Alert>
          )} */}
        </Box>

        <Box className="m-main-spacing" sx={{ mb: 2, flex: 1 }}>
          <Box sx={{ py: 2 }}>
            <Typography variant="h6" fontWeight={400} sx={{ pb: 1 }}>
              <FormattedMessage id="menu.lets_get_started" />
            </Typography>
            <Typography variant="body1" fontWeight={400}>
              <FormattedMessage id="common.select_option" />
            </Typography>
          </Box>
          {plans && (
            <PlanRadioGroup
              currencySymbol={currencySymbol}
              data={plans}
              value={selectedPlan}
              handleChangePlan={handleChangePlan}
              existingPlanId={existingPlanId}
            />
          )}
        </Box>
      </Box>
      <Box className="m-main-spacing" sx={{ mb: 0 }}>
        <MuiButton
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          disabled={!selectedPlan}
          onClick={handleContinueClick}
        >
          <FormattedMessage id="buttons.continue" />
        </MuiButton>
        <MuiButton
          fullWidth
          variant="contained"
          color="secondary"
          {...(handleBack && { onClick: handleBack })}
          {...(!handleBack && {
            component: props => <RouterLink {...props} />,
            to: '/menu/plan-details',
          })}
        >
          <FormattedMessage id="buttons.cancel" />
        </MuiButton>
      </Box>
    </>
  );
};
export default memo(PlanChangePageContent);
