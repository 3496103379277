import { memo, useCallback, useContext, useState } from 'react';
import { BotSignalParams } from 'types/ApiServicesTypes';
import { SignalFormDataContext } from './BotGenerateSignalModal';
import SignalModalListLayot from './SignalModalListLayot';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { Box } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AddIcon from '@mui/icons-material/Add';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import BotBasketSignalFormModal from './BotBasketSignalFormModal';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import nodatafound from '../../../../assets/images/No_data_image.png';
import StatusMessage from 'app/components/Common/StatusMessage';
import { useTranslation } from 'react-i18next';

const BotBasketSignalList = () => {
  const { handleClose, isLoading, onSave, handleChangeTitleData } = useContext(
    SignalFormDataContext,
  );
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [signalFormData, setSignalFormData] = useState<BotSignalParams[]>([]);
  const [modifyBasket, setModifyBasket] = useState<boolean>(false);
  const [updateSignalFormData, setUpdateSignalFormData] =
    useState<BotSignalParams>({} as BotSignalParams);
  const [editIndex, setEditIndex] = useState<number>(-1);

  const handleCloseModal = () => {
    setOpen(false);
    setUpdateSignalFormData({} as BotSignalParams);
  };
  const handleClickReplicate = (signalData: BotSignalParams) => {
    setSignalFormData([...signalFormData, signalData]);
  };

  const handleSave = useCallback(
    (data: BotSignalParams[]) => {
      if (signalFormData[editIndex] === undefined) {
        setSignalFormData([...signalFormData, ...data]);
      } else {
        signalFormData[editIndex] = data[0];
        setSignalFormData(signalFormData);
        setEditIndex(-1);
      }

      handleChangeTitleData({
        symbol: '',
        qty: 1,
        transactionType: 'BUY',
      });
      setOpen(false);
      setModifyBasket(false);
    },
    [handleChangeTitleData, editIndex, signalFormData],
  );

  const handleClickDelete = (i: number) => {
    signalFormData.splice(i, 1);
    setSignalFormData([...signalFormData]);

    // if (signalFormData.length === 0) {
    //   // setIsShowList(false);
    // }
  };

  return (
    <Box
      className="m-main-spacing"
      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      <Box
        sx={{
          textAlign: 'center',
          display: { xs: 'block', md: 'flex' },
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <CircularLoader open={isLoading} />
        <MuiButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ mb: 2, mr: 1, width: { xs: '100%', lg: 'auto' } }}
          onClick={() => setOpen(true)}
        >
          <FormattedMessage id={'buttons.add_signal'} />
        </MuiButton>
      </Box>
      {signalFormData.length > 0 ? (
        <>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <SignalModalListLayot
              signalData={signalFormData}
              handleReplicateSelectSignal={handleClickReplicate}
              handleEditSelectSignal={(signalData, i) => {
                setOpen(true);
                setUpdateSignalFormData(signalData);
                setModifyBasket(true);
                setEditIndex(i);
              }}
              handleDeleteSelectSignal={handleClickDelete}
            />
          </Box>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'end',
              flexDirection: 'row-reverse',
              gap: 2,
            }}
          >
            <MuiLoadingButton
              variant="contained"
              color="primary"
              sx={{ mb: 2, mr: 1, width: { xs: '100%', lg: 'auto' } }}
              onClick={() => {
                onSave(signalFormData);
              }}
              loading={isLoading}
            >
              <FormattedMessage id={'buttons.generate_signals'} />
            </MuiLoadingButton>
            <MuiButton
              variant="contained"
              color="secondary"
              onClick={() => {
                handleClose();
              }}
              sx={{ mb: 2, width: { xs: '100%', lg: 'auto' } }}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            // justifyContent: 'center',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            mt: 10,
          }}
        >
          <img src={nodatafound} style={{ width: '150px', height: '150px' }} />
          <StatusMessage
            subtitle={t('bot_signals.empty_basket')}
            fontStyle="italic"
          />
        </Box>
      )}
      <BotBasketSignalFormModal
        handleClose={handleCloseModal}
        open={open}
        onSave={handleSave}
        signalData={updateSignalFormData}
        isModifyBasket={modifyBasket}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default memo(BotBasketSignalList);
