import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import {
  formatNumber,
  formatStringToDateString,
  convertTimeTo12H,
} from 'utils/GenericFunctions';
import { IntervalsData } from '../StrategyBuilder/util';

const UserBackTestingInfo = ({
  initial_capital,
  start_date,
  end_date,
  trading_start_time,
  trading_stop_time,
  resolution,
  currency_symbol,
}: {
  currency_symbol: string;
  initial_capital: number;
  start_date: string;
  end_date: string;
  trading_start_time?: string;
  trading_stop_time?: string;
  resolution?: string;
}) => {
  return (
    <Box sx={{ my: 2 }}>
      <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
        <Typography variant="body3">
          <FormattedMessage id="user_backtesting.form.initial_capital" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {currency_symbol}
          {formatNumber(initial_capital, false, 0)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
        <Typography variant="body3">
          <FormattedMessage id="user_backtesting.form.start_date" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {formatStringToDateString(start_date, 'YYYY-MM-DD', 'DD MMM YYYY')}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ pb: !trading_start_time ? 0 : 2 }}
      >
        <Typography variant="body3">
          <FormattedMessage id="user_backtesting.form.end_date" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {formatStringToDateString(end_date, 'YYYY-MM-DD', 'DD MMM YYYY')}
        </Typography>
      </Box>
      {trading_start_time && (
        <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
          <Typography variant="body3">
            <FormattedMessage id="user_backtesting.form.entry_time" />
          </Typography>
          <Typography variant="body3" fontWeight={500}>
            {convertTimeTo12H(`${trading_start_time}`)}
          </Typography>
        </Box>
      )}
      {trading_stop_time && (
        <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
          <Typography variant="body3">
            <FormattedMessage id="user_backtesting.form.exit_time" />
          </Typography>
          <Typography variant="body3" fontWeight={500}>
            {convertTimeTo12H(`${trading_stop_time}`)}
          </Typography>
        </Box>
      )}
      {resolution && (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body3">
            <FormattedMessage id="user_backtesting.form.interval" />
          </Typography>
          <Typography variant="body3" fontWeight={500}>
            {IntervalsData.filter(x => x.value === resolution)[0]?.label}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserBackTestingInfo;
