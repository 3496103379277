import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PageTitleCloseVariantProps } from '../types';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

const PageTitleCloseVariant = ({
  buttonHandleClick,
}: PageTitleCloseVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ pt: 3, pb: 2 }}>
      <MuiButton
        size="small"
        onClick={buttonHandleClick}
        startIcon={<CloseIcon />}
        sx={{ px: '0 !important' }}
      >
        <FormattedMessage id="buttons.close" />
      </MuiButton>
    </Box>
  );
};

export default PageTitleCloseVariant;
