// Service
import { TodaysPositionsProps } from 'types/Position';
import { createOpenPositionTableRows } from './utils';
import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';

const PortfolioOpenPositionsTable = ({
  positions,
  handlePositionClick,
  liveFeedData,
}: TodaysPositionsProps) => {
  const { rows, columns } = createOpenPositionTableRows(
    positions,
    liveFeedData,
    handlePositionClick,
  );

  return (
    <>
      {positions.length > 0 && (
        <Box sx={{ px: 3, mx: 1 }}>
          <CommonTable rows={rows} columns={columns} />
        </Box>
      )}
    </>
  );
};

export default PortfolioOpenPositionsTable;
