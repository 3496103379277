import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ActivateFreeTrial } from 'services/apiServices';
import { ActivateFreeTrialResult, Errors } from 'types/ApiServicesTypes';

export default function useActivateFreeTrial() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<ActivateFreeTrialResult, Errors>(
    'ActivateFreeTrial',
    () =>
      ActivateFreeTrial()
        .then(res => {
          if (res.success) {
            showAlert!('Free trial activated!', 'success');
          } else {
            showAlert!('Something went wrong. Please try again.');
          }
        })
        .catch(err => {
          showAlert!('Something went wrong, please try again.');
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
