import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuthDetails } from './auth';

export function logFirebaseEventEntry<T>(eventName: string, params?: T) {
  const userDetails = getAuthDetails();
  let args = {};
  if (userDetails.userId && params)
    args = {
      ...params,
      user_id: userDetails.userId,
      user_name: userDetails.userName,
    };
  else if (params) args = { ...params };
  else if (userDetails.userId)
    args = { user_id: userDetails.userId, user_name: userDetails.userName };

  const firebaseAnalytics = getAnalytics();
  logEvent(firebaseAnalytics, eventName, args);
}
