import { useQuery } from 'react-query';
import { GetPortfolioShare } from 'services/apiServices';
import { Errors, GetPortfolioShareResult } from 'types/ApiServicesTypes';

export default function useGetPortfolioShare(userId: number, date: string) {
  const objQuery = useQuery<GetPortfolioShareResult, Errors>(
    ['GetPortfolioShare', date],
    () => GetPortfolioShare(userId, date),
    {
      keepPreviousData: true,
    },
  );
  return objQuery;
}
