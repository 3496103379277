import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostCreditTransfer } from 'services/Menu/usePostCreditTransfer';
import * as Yup from 'yup';

type TransferCreditModalProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
};

type FormValue = {
  amount: number;
  transferUserAccount: string;
};
function TransferCreditModal({ open, handleClose }: TransferCreditModalProps) {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [buttonAmount, setButtonAmount] = useState<number>(1000);
  const [transferUserAccount, setTransferUserAccount] = useState<string>('');
  const [formData, setFormData] = useState<FormValue>();
  const handleAmountButtonClick = (amount: number) => {
    setButtonAmount(() => buttonAmount + amount);
  };
  const handleCreditModal = () => {
    handleClose(true);
    setTransferUserAccount('');
    setButtonAmount(1000);
    setStep(1);
  };
  const transferCreditAction = usePostCreditTransfer(handleCreditModal);
  const addAmount = [100, 200, 1000, 2000];
  const handleCreditTransferConformation = () => {
    transferCreditAction.mutate({
      action: 'transfer',
      amount: formData!.amount,
      to_email: formData!.transferUserAccount,
    });
  };

  return (
    <>
      <MuiFullScreenModal
        open={open}
        handleClose={() => handleClose(true)}
        body={
          <Formik
            enableReinitialize
            initialValues={{
              amount: buttonAmount,
              transferUserAccount: transferUserAccount,
            }}
            validationSchema={Yup.object().shape({
              amount: Yup.number()
                .positive()
                .required(
                  t('menu.required', {
                    type: t('menu.wallet_modal.amount'),
                  }),
                ),
              transferUserAccount: Yup.string().required(
                t('menu.required', {
                  type: t('menu.email'),
                }),
              ),
            })}
            onSubmit={(values: FormValue) => {
              if (step === 1) {
                setFormData({
                  ...formData,
                  amount: values.amount,
                  transferUserAccount: values.transferUserAccount,
                });
                setStep(2);
              }
            }}
          >
            {({ values, errors, handleSubmit, handleBlur, touched }) => {
              return (
                <Box
                  className="m-main-spacing"
                  component="form"
                  id="credit-amount-form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 2.25rem)',
                    pt: 1,
                    position: 'relative',
                  }}
                >
                  {step === 1 ? (
                    <Box sx={{ mb: 2, flex: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ pb: 4, fontWeight: 500 }}
                      >
                        <FormattedMessage id="menu.transfer_credit_modal_title" />
                      </Typography>
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12} order={1}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.amount && errors.amount)}
                            variant="outlined"
                            sx={{ mb: 3 }}
                          >
                            <InputLabel htmlFor="label-amount">
                              <FormattedMessage id="menu.wallet_modal.credits" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-amount"
                              name="amount"
                              type="number"
                              value={values.amount}
                              onBlur={handleBlur}
                              onChange={e =>
                                setButtonAmount(Number(e.target.value))
                              }
                              label={t('menu.wallet_modal.credits')}
                              inputProps={{ min: 1, step: 500 }}
                            />
                            {touched.amount && errors.amount && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.amount}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.transferUserAccount &&
                                errors.transferUserAccount,
                            )}
                            variant="outlined"
                            sx={{ mb: 3 }}
                          >
                            <InputLabel htmlFor="label-transferUserAccount">
                              <FormattedMessage id="menu.enter_user_email" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-transferUserAccount"
                              name="User Email"
                              type="email"
                              value={values.transferUserAccount}
                              onBlur={handleBlur}
                              onChange={e =>
                                setTransferUserAccount(
                                  e.target.value.toString(),
                                )
                              }
                              label={t('menu.enter_user_email')}
                              inputProps={{ min: 1, step: 500 }}
                            />
                            {touched.transferUserAccount &&
                              errors.transferUserAccount && (
                                <FormHelperText error sx={{ mx: 0 }}>
                                  {errors.transferUserAccount}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap ',
                              justifyContent: 'space-between',
                            }}
                          >
                            {addAmount.map((value, index) => (
                              <MuiButton
                                key={index}
                                variant="contained"
                                sx={{ minWidth: 85, mb: 2 }}
                                onClick={() => handleAmountButtonClick(value)}
                              >
                                {
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography>+{value}</Typography>
                                    <Typography>Credits</Typography>
                                  </div>
                                }
                              </MuiButton>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {step === 2 ? (
                    <Box sx={{ mb: 2, flex: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ pb: 1, fontWeight: 500 }}
                      >
                        {t('menu.transfer_credit_conformation')}
                      </Typography>
                      <Alert severity="info" sx={{ mb: 2 }}>
                        <Typography variant="body2">
                          {t('menu.transfer_credit_info')}
                        </Typography>
                      </Alert>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ pb: 4, fontWeight: 500 }}
                        >
                          {t('menu.wallet_modal.amount')}:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ pl: 1, fontWeight: 900 }}
                        >
                          {formData?.amount}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ pb: 4, fontWeight: 500 }}
                        >
                          {t('menu.email')}:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ pl: 1, fontWeight: 900 }}
                        >
                          {formData?.transferUserAccount}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              );
            }}
          </Formik>
        }
        footer={
          <>
            <>
              {step === 1 ? (
                <MuiButton
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                  type="submit"
                  form="credit-amount-form"
                >
                  <FormattedMessage id="menu.transfer_credit" />
                </MuiButton>
              ) : (
                <></>
              )}
              {step === 2 ? (
                <MuiLoadingButton
                  fullWidth
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={handleCreditTransferConformation}
                  form="credit-amount-form"
                  loading={transferCreditAction.isLoading}
                >
                  <FormattedMessage id="menu.proceed" />
                </MuiLoadingButton>
              ) : (
                <></>
              )}
              <MuiButton
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  if (step === 1) {
                    handleClose(true);
                    setTransferUserAccount('');
                    setButtonAmount(1000);
                  } else if (step === 2) {
                    setStep(1);
                  }
                }}
              >
                <FormattedMessage id="buttons.cancel" />
              </MuiButton>
            </>
          </>
        }
        handleBackDropClick={() => {
          setTransferUserAccount('');
          setButtonAmount(1000);
          setStep(1);
        }}
      />
    </>
  );
}

export default TransferCreditModal;
