import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { SubscribePlanStatus } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import {
  SubscribePlanStatusParams,
  SubscribePlanStatusResult,
} from 'types/SubscriptionPlans';

export default function useSubscribePlanStatus(
  successCallback: (res: SubscribePlanStatusResult) => void,
) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    SubscribePlanStatusResult,
    Errors,
    Pick<
      SubscribePlanStatusParams,
      | 'razorpay_payment_id'
      | 'razorpay_subscription_id'
      | 'razorpay_signature'
      | 'razorpay_order_id'
      | 'invoice_id'
      | 'subscription_id'
    >
  >('SubscribePlanStatus', values =>
    SubscribePlanStatus(
      values.razorpay_payment_id,
      values.razorpay_subscription_id,
      values.razorpay_signature,
      values.razorpay_order_id,
      values.invoice_id,
      values.subscription_id,
    )
      .then(res => {
        if (res.error) {
          showAlert!(res.error);
        } else if (!res || !res.status)
          showAlert!('Something went wrong. Please try again.');
        else successCallback(res);
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
