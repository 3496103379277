import { useState, useCallback, createContext, memo } from 'react';

export type ToggleViewState = {
  open: boolean;
  toggle: () => void;
};

export type ToggleViewContextProps = {
  toggle?: () => void;
  open?: boolean;
};

export const ToggleContext = createContext<ToggleViewContextProps>({
  open: false,
  toggle: () => {
    //
  },
});

const ToggleViewProviderComp = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    // eslint-disable-next-line no-console
    setOpen(!open);
  }, [open]);

  return (
    <ToggleContext.Provider value={{ open, toggle }}>
      {children}
    </ToggleContext.Provider>
  );
};

export const ToggleContextProvider = memo(ToggleViewProviderComp);
