import { ArrowDropUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useState } from 'react';
import { FutureDataType } from 'types/ApiServicesTypes';
import { formatDateString } from 'utils/GenericFunctions';
import { PositionBaseDataType } from '../types';
import BuySellButton from './BuySellButton';

type CustomFutureSelection = {
  handleSelect: (selected: string) => void;
  options: Array<string> | undefined;
  selected: string;
  futureData: FutureDataType;
  handleNewFutPosition: (
    newPosition: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void;
  entryTime: string;
};
const CustomFutureSelection = ({
  handleSelect,
  selected,
  options,
  futureData,
  handleNewFutPosition,
  entryTime,
}: CustomFutureSelection) => {
  const handleSelection = (selection: string) => {
    handleSelect(selection);
  };
  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(showMenu);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setShowMenu(null);
  };

  return (
    <>
      <Button
        onClick={handleMenuClick}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        variant="outlined"
      >
        <Typography variant="body2" fontWeight={'200'}>
          Fut ({formatDateString(selected, 'DD MMM')})
        </Typography>
        <Typography variant="body1"> {futureData.close}</Typography>
      </Button>
      <Paper sx={{ width: 'auto' }}>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          sx={{ width: '420px' }}
          anchorEl={showMenu}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'grey.300',
              px: 1,
            }}
          >
            {['Expiry', 'LTP', 'Lots'].map(header => (
              <Typography key={header} variant="body1">
                {header}
              </Typography>
            ))}
          </Box>
          <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
          {options &&
            options.map((option, index) => {
              return (
                <MenuItem
                  onClick={() => {
                    handleSelection(option);
                    handleMenuClose();
                  }}
                  key={index}
                  sx={{
                    paddingX: 1,
                    width: '370px',
                    py: 0,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        py: 1,
                      }}
                    >
                      <Box
                        flex={2}
                        display={'flex'}
                        justifyContent={'space-between'}
                      >
                        <Typography variant="body2">
                          {formatDateString(option, 'DD MMM YY')}
                        </Typography>
                        <Typography variant="body2">
                          {futureData.close}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: 'relative',
                          flex: 1,
                        }}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <BuySellButton
                          contractDetails={{
                            close: futureData.close,
                            delta: 0,
                            gamma: 0,
                            iv: 0,
                            rho: 0,
                            theta: 0,
                            vega: 0,
                            contractType: 'fut',
                          }}
                          contractType="fut"
                          date={entryTime}
                          handleClick={handleNewFutPosition}
                          strike={option}
                          trade_type="fut"
                        />
                      </Box>
                    </Box>
                    {index !== options.length - 1 && (
                      <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
                    )}
                  </Box>
                </MenuItem>
              );
            })}
        </Menu>
      </Paper>
    </>
  );
};

export default CustomFutureSelection;
