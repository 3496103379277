import { memo } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import useGetProfile from 'services/Menu/useGetProfile';
import { formatDateString } from 'utils/GenericFunctions';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

type PlanPaymentInfo = {
  paymentId: string;
  method: string;
  mobile: string;
};

const PlanConfirmModal = ({
  type,
  billingType,
  selectedPlan,
  paymentInfo,
  open,
  handleClose,
}: {
  type: 'upgrade' | 'change';
  selectedPlan: string;
  billingType: string;
  paymentInfo?: PlanPaymentInfo;
  open: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data: profileDetails } = useGetProfile();
  const { data: stiteInfo } = useGetSiteInfo();

  return (
    <>
      <MuiModal
        open={open}
        handleClose={handleClose}
        header={
          <Box className="text-ellipsis">
            <PageHeader variant="close" buttonHandleClick={handleClose} />
          </Box>
        }
        body={
          <>
            <Box className="m-main-spacing">
              <Box sx={{ pb: 3 }}>
                <Typography variant="h6" sx={{ pb: 0.5 }}>
                  <FormattedMessage id="menu.thanks_for_choosing" />
                </Typography>
                <Typography variant="h5" fontWeight={600} sx={{ pb: 4 }}>
                  {stiteInfo && stiteInfo.name}
                </Typography>

                <Typography variant="body1" fontWeight={400} sx={{ pb: 3 }}>
                  <FormattedMessage id="menu.you_all_set" />
                </Typography>

                <Typography variant="body1" fontWeight={400} sx={{ pb: 0.5 }}>
                  {t('menu.plan_has_to', {
                    type: type === 'upgrade' ? 'upgraded' : 'changed',
                  })}
                </Typography>
                <Typography variant="h6" sx={{ pb: 3 }}>
                  {selectedPlan}
                </Typography>

                {profileDetails && profileDetails.user_plan && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      sx={{ pb: 0.5 }}
                    >
                      <FormattedMessage id="menu.this_change_takes" />
                    </Typography>
                    <Typography variant="body1">
                      {formatDateString(
                        profileDetails.user_plan.start_date,
                        'dddd, MMMM Do YYYY',
                      )}
                    </Typography>
                  </Box>
                )}
                <Typography variant="body1" fontWeight={400}>
                  <FormattedMessage id="menu.you_can_view_plan" />
                </Typography>
              </Box>
              {paymentInfo && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1" sx={{ pb: 2 }}>
                    <FormattedMessage id="menu.payment_details" />
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ pb: 2 }}
                  >
                    <Typography variant="body3">
                      <FormattedMessage id="menu.payment_id" />
                    </Typography>
                    <Typography variant="body3" fontWeight={500}>
                      {paymentInfo.paymentId}
                    </Typography>
                  </Box>
                  {/* <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ pb: 2 }}
                  >
                    <Typography variant="body3">
                      <FormattedMessage id="menu.method" />
                    </Typography>
                    <Typography variant="body3" fontWeight={500}>
                      {paymentInfo.method}
                    </Typography>
                  </Box> */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ pb: 2 }}
                  >
                    <Typography variant="body3">
                      <FormattedMessage id="menu.mobile" />
                    </Typography>
                    <Typography variant="body3" fontWeight={500}>
                      {paymentInfo.mobile}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body3">
                      <FormattedMessage id="menu.billing_cycle" />
                    </Typography>
                    <Typography variant="body3" fontWeight={500}>
                      <FormattedMessage id={`menu.${billingType}`} />
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        }
        footer={
          <Box className="m-main-spacing" sx={{ pb: 2 }}>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.done" />
            </MuiButton>
          </Box>
        }
      />
    </>
  );
};
export default memo(PlanConfirmModal);
