import { useState } from 'react';
import BotDeployTermConditions from './BotDeployTermConditions';
import BotDeployForm from './BotDeployForm';
import BotDeploySummary from './BotDeploySummary';
import { CapabilityFlag } from 'app/components/Common/CapabilityFlag';
import { CAPABILITIES } from 'types/Capability';
import BotDeployCapabilityFallback from './BotDeployCapabilityFallback';
import useGetProfile from 'services/Menu/useGetProfile';
import { UserPlanType } from 'types/User';
import CreditEstimation from 'app/components/Common/CreditEstimation';
import BotDeployCreditFallback from './BotDeployCreditFallback';

export type GoLiveFormProps = {
  broker: string;
  duration: string;
  amount?: string;
  durationType?: string;
  capital: string;
  multiple?: string;
  botName?: string;
};

export default function BotDeployModalContent({
  botName,
  openFrom,
  handleClose,
  botId,
  skipTerms,
  exchanges,
  isCrypto,
  isAddMoreLots,
}: {
  botId: number;
  openFrom: string;
  botName: string;
  skipTerms?: boolean;
  exchanges?: string[];
  isCrypto?: boolean;
  handleClose: (isSuccess: boolean, message?: string) => void;
  isAddMoreLots?: boolean;
}) {
  const { data: profileDetails } = useGetProfile();
  const [step, setStep] = useState<number>(skipTerms ? 2 : 1);
  const [formValues, setFormValues] = useState<GoLiveFormProps | null>(null);
  const setStepNumber = (val: number) => setStep(val);
  const setFormData = (values: GoLiveFormProps | null) => setFormValues(values);

  const onclose = () => {
    setStepNumber(1);
    setFormData(null);
    handleClose(true);
  };

  const isPayAsYouGoPlan =
    profileDetails &&
    profileDetails.user_plan?.plan.plan_type === UserPlanType.PAY_AS_YOU_GO;

  return (
    <>
      {isPayAsYouGoPlan ? (
        <CreditEstimation action="go_live" data={{}}>
          {({ isSufficientCredit, requiredCredits }) => (
            <>
              {isSufficientCredit ? (
                <>
                  {step === 1 && (
                    <BotDeployTermConditions
                      botName={botName}
                      setStepNumber={setStepNumber}
                      onclose={onclose}
                    />
                  )}
                  {step === 2 && (
                    <BotDeployForm
                      exchanges={exchanges}
                      botName={botName}
                      setStepNumber={setStepNumber}
                      setFormData={setFormData}
                      onclose={onclose}
                      botId={botId}
                      isCrypto={isCrypto}
                    />
                  )}
                  {step === 3 && formValues && (
                    <BotDeploySummary
                      botId={botId}
                      openFrom={openFrom}
                      botName={botName}
                      summary={formValues}
                      onclose={onclose}
                      isPayAsYouGo={isPayAsYouGoPlan}
                      isAddMoreLots={isAddMoreLots}
                    />
                  )}
                </>
              ) : (
                <BotDeployCreditFallback
                  variant="live"
                  botName={botName}
                  onclose={onclose}
                  requiredCredits={requiredCredits}
                  walletBalance={profileDetails.wallet_balance}
                />
              )}
            </>
          )}
        </CreditEstimation>
      ) : (
        <CapabilityFlag
          capabilities={[CAPABILITIES.LIVE_TRADING]}
          fallback={data => {
            const capability = data.userCapabilities.filter(
              x => x.key === CAPABILITIES.LIVE_TRADING,
            )[0];
            const credit = capability ? capability.credit : 0;
            const planName =
              data.profileDetails && data.profileDetails.user_plan
                ? data.profileDetails.user_plan.plan.plan_name.replace(
                    'Plan',
                    '',
                  )
                : '';
            return (
              <BotDeployCapabilityFallback
                botName={botName}
                onclose={onclose}
                credit={credit}
                planName={planName}
                variant="live"
              />
            );
          }}
        >
          {() => (
            <>
              {step === 1 && (
                <BotDeployTermConditions
                  botName={botName}
                  setStepNumber={setStepNumber}
                  onclose={onclose}
                />
              )}
              {step === 2 && (
                <BotDeployForm
                  exchanges={exchanges}
                  botName={botName}
                  setStepNumber={setStepNumber}
                  setFormData={setFormData}
                  onclose={onclose}
                  botId={botId}
                  isCrypto={isCrypto}
                />
              )}
              {step === 3 && formValues && (
                <BotDeploySummary
                  botId={botId}
                  openFrom={openFrom}
                  botName={botName}
                  summary={formValues}
                  onclose={onclose}
                  isPayAsYouGo={isPayAsYouGoPlan}
                />
              )}
            </>
          )}
        </CapabilityFlag>
      )}
    </>
  );
}
