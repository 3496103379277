import { useQuery } from 'react-query';
import { GetNotificationSettings } from 'services/apiServices';
import { Errors, GetNotificationSettingsResult } from 'types/ApiServicesTypes';

export default function useGetNotificationSettings() {
  const objQuery = useQuery<GetNotificationSettingsResult, Errors>(
    'GetNotificationSettings',
    () => GetNotificationSettings(),
  );
  return objQuery;
}
