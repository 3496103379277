import { useQuery } from 'react-query';
import { GetOptionChainMetadata } from 'services/apiServices';
import { GetOptionChainMetadataResult } from 'types/ApiServicesTypes';

export default function useGetOptionChainMetadata(date: string) {
  const objQuery = useQuery<GetOptionChainMetadataResult, Error>(
    ['GetOptionChainMetadata', date],
    async () => await GetOptionChainMetadata(date),
  );

  return objQuery;
}
