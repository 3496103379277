import { Typography } from '@mui/material';
import moment from 'moment';

const DayAgo = ({
  botsCreateDate,
}: {
  botsCreateDate: { create_date: string; write_date: string };
}) => {
  const createDate = moment
    .utc(
      botsCreateDate.write_date != ''
        ? botsCreateDate.write_date
        : botsCreateDate.create_date,
    )
    .local();

  const currentDate = moment();
  let hoursAgo;
  if (currentDate.isSame(createDate, 'day')) {
    hoursAgo = `${currentDate.diff(createDate, 'hours')}h`;
    // console.log(`${hoursAgo} hours ago`);
  } else {
    hoursAgo = `${currentDate.diff(createDate, 'days')}d`;
    // console.log(`${daysAgo} days ago`);
  }
  return (
    <Typography
      mr={1}
      variant={'caption'}
      // fontSize={13} mr={1} width={'auto'}
    >
      {botsCreateDate.write_date !== '' ? 'Last modified' : 'Created'}{' '}
      {hoursAgo} ago
    </Typography>
  );
};
export default DayAgo;
