import { useQuery } from 'react-query';
import { GetWebHookAuthKey } from 'services/apiServices';
import { GetWebHookAuthKeyResult } from 'types/ApiServicesTypes';

export default function useGetWebHookAuthKey() {
  const objQuery = useQuery<GetWebHookAuthKeyResult>(
    ['GetWebHookAuthKey'],
    async () => {
      const data = await GetWebHookAuthKey();
      return data;
    },
    {
      staleTime: 8 * 60 * 60 * 1000,
    },
  );
  return objQuery;
}
