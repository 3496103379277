import { useQuery } from 'react-query';
import { GetLogsFileData } from 'services/apiServices';
import { Errors, GetBotsLogFilesDataResult } from 'types/ApiServicesTypes';

export default function useGetBotLogFiles(id: number, file: string) {
  const objQuery = useQuery<GetBotsLogFilesDataResult, Errors>(
    ['GetBotLogFiles', id, file],
    () => GetLogsFileData(id, file),
  );
  return objQuery;
}
