import { Box } from '@mui/material';

interface InfoItemProps {
  pb?: number;
  children: JSX.Element | JSX.Element[];
}

const InfoItemWrapper = (props: InfoItemProps) => {
  const { pb, children } = props;
  return (
    <Box
      display={'flex'}
      justifyContent={{ xs: 'space-between', md: 'start' }}
      flexDirection={{ xs: 'row', md: 'column-reverse' }}
      sx={{ pb: pb ? pb : 0 }}
    >
      {children}
    </Box>
  );
};

export default InfoItemWrapper;
