import ohm, { MatchResult } from 'ohm-js';
import {
  IndicatorsKeys,
  ComparatorsKeys,
  OperatorsKeys,
  MathFunctionsKeys,
} from '../fields/fieldsData';

import { FieldData } from '../fields/types';

export const strategyRules = ohm.grammar(`
  Arithmatic {
    Exp = Exp logicalOperator Exp --mainRecursion
          | logicalExpWBr | logicalExpWOBr

  logicalExpWOBr = logicalExpWOBr logicalOperator logicalExpWOBr --logWOBr
                    | indicatorExpBrackets
                    | indicatorExpWithoutBrackets
  
  logicalExpWBr =  ob logicalExpWBr logicalOperator logicalExpWBr cb --logWithBr
                    | ob logicalExpWBr cb --logicalbracket
                    | indicatorExpBrackets
                    | indicatorExpWithoutBrackets
  
    /* indicatorExpBrackets & indicatorExpWithoutBrackets are valid expressions with comparator */

    indicatorExpBrackets = ob indicatorExp comparator indicatorExp cb
    indicatorExpWithoutBrackets = indicatorExp comparator indicatorExp
    
    /* indicatorExp is an indicator */

    indicatorExp = ob indicator operator indicator cb --brackets
                | indicator operator indicator  --expwithindicator
                | ob indicatorExp cb --indicatorExpBracket
                | indicator
    
    indicator = ob indicator cb --brackets
                | ${IndicatorsKeys}
                | mathTwoOpExp
                | mathPeriodExp
                | mathSingleOpExp
                 
    comparator = ${ComparatorsKeys}
    operator = ${OperatorsKeys}
      
    mathTwoOpExp = mathTwoOp ob indicatorExp comma indicatorExp cb
    mathSingleOpExp = mathSingleOp ob indicatorExp cb
    mathPeriodExp = mathPeriod ob digit+ comma indicatorExp cb
      
    mathTwoOp = "max" | "min"
    mathSingleOp = "abs" | "floor" | "ceil"
    mathPeriod = "period_min" | "period_max"

    ob = "("
    cb = ")"
    logicalOperator = "and" | "or"
    comma = ","
  }
`);

export const strategyRulesForMathFunction = ohm.grammar(`
  Arithmatic {
    Exp = mathTwoOpExp | mathSingleOpExp | mathPeriodExp | indicatorExp

    /* indicatorExp is an indicator */

    indicatorExp = ob indicator operator indicator cb --brackets
                | indicator operator indicator  --expwithindicator
                | ob indicatorExp cb --indicatorExpBracket
                | indicator
    
    indicator = ob indicator cb --brackets
                | ${IndicatorsKeys}
                | mathTwoOpExp
                | mathPeriodExp
                | mathSingleOpExp

                
    mathTwoOpExp = mathTwoOp ob indicatorExp comma indicatorExp cb
    mathSingleOpExp = mathSingleOp ob indicatorExp cb
    mathPeriodExp = mathPeriod ob digit+ comma indicatorExp cb


    comparator = ${ComparatorsKeys}
    operator = ${OperatorsKeys}
      
    mathTwoOp = "max" | "min"
    mathSingleOp = "abs" | "floor" | "ceil"
    mathPeriod = "period_min" | "period_max"

    ob = "("
    cb = ")"
    logicalOperator = "and" | "or"
    comma = ","
  }
`);

export const strategyRulesWithoutMath = ohm.grammar(`
  Arithmatic {
    Exp = Exp logicalOperator Exp --mainRecursion
          | logicalExpWBr | logicalExpWOBr
  
   logicalExpWOBr = logicalExpWOBr logicalOperator logicalExpWOBr --logWOBr
                    | indicatorExpBrackets
                    | indicatorExpWithoutBrackets
   
   logicalExpWBr =  ob logicalExpWBr logicalOperator logicalExpWBr cb --logWithBr
   					        |ob logicalExpWBr cb --logicalbracket
                    | indicatorExpBrackets
                    | indicatorExpWithoutBrackets
   
    /* indicatorExpBrackets & indicatorExpWithoutBrackets are valid expressions with comparator */

    indicatorExpBrackets = ob indicatorExp comparator indicatorExp cb
    indicatorExpWithoutBrackets = indicatorExp comparator indicatorExp
    
    /* indicatorExp is an indicator */
    /* mathFunction is an indicator */

    indicatorExp = ob indicator operator indicator cb --brackets
                | indicator operator indicator  --expwithindicator
                | ob indicatorExp cb --indicatorExpBracket
                | indicator
    
    indicator = ob indicator cb --brackets
                | ${IndicatorsKeys}
                | ${MathFunctionsKeys}

    comparator = ${ComparatorsKeys}
    operator = ${OperatorsKeys}

    ob = "("
    cb = ")"
    logicalOperator = "and" | "or"
  }
`);

export type Failure = {
  text: string;
};

export type FinalMatchResult = MatchResult & {
  getRightmostFailures: () => Failure[];
  getRightmostFailurePosition: () => number;
};

const createConditionsString = (conditions: FieldData[]) => {
  let keysString = '';
  conditions.forEach(condition => {
    if (condition.type === 'mathfunctions') {
      let paramKey = '';
      const keys = Object.keys(condition.params);
      if (
        condition.key === 'abs' ||
        condition.key === 'ceil' ||
        condition.key === 'floor'
      ) {
        const params = condition.params['param1'] as FieldData[];
        params && (paramKey += createConditionsString(params));
        // params.forEach((param: any) => {
        //   paramKey += param.key;
        // });
      } else {
        if (condition.key === 'period_min' || condition.key === 'period_max') {
          paramKey += `${condition.params['period']},`;
          const params = condition.params['param1'] as FieldData[];
          params && (paramKey += createConditionsString(params));
          // params.forEach((param: any) => {
          //   paramKey += param.key;
          // });
        } else {
          keys.forEach((key: string) => {
            const params = condition.params[key] as FieldData[];
            params && (paramKey += createConditionsString(params));
            // params.forEach((param: any) => {
            //   paramKey += param.key;
            // });
            paramKey += ',';
          });
          paramKey = paramKey.slice(0, paramKey.length - 1); //Remove last comma
        }
      }
      keysString += condition.key + '(' + paramKey + ')';
    } else {
      keysString += condition.key;
    }
  });
  return keysString;
};

const createConditionsStringWithoutFuncDesc = (conditions: FieldData[]) => {
  const keysStringArray: Array<string> = [];
  let keysString = '';
  conditions.forEach(condition => {
    keysString += condition.key;
    keysStringArray.push(condition.key);
  });
  return {
    keysString,
    keysStringArray,
  };
};

const createConditionStringWithFuncDesc = (condition: FieldData) => {
  const MathFuncKeysStringArray: Array<string> = [];
  let MathFuncKeysString = '';
  if (condition.type === 'mathfunctions') {
    let paramKey = '';
    const paramKeyArray: Array<string> = [];
    const keys = Object.keys(condition.params);
    if (
      condition.key === 'abs' ||
      condition.key === 'ceil' ||
      condition.key === 'floor'
    ) {
      const params = condition.params['param1'] as FieldData[];
      if (params && params.length > 0) {
        for (const paramItem of params) {
          const { MathFuncKeysString, MathFuncKeysStringArray } =
            createConditionStringWithFuncDesc(paramItem);
          paramKey += MathFuncKeysString;
          paramKeyArray.push(...MathFuncKeysStringArray);
        }
      }
    } else {
      if (condition.key === 'period_min' || condition.key === 'period_max') {
        paramKey += `${condition.params['period']},`;
        paramKeyArray.push(`${condition.params['period']},`);
        const params = condition.params['param1'] as FieldData[];
        if (params && params.length > 0) {
          for (const paramItem of params) {
            const { MathFuncKeysString, MathFuncKeysStringArray } =
              createConditionStringWithFuncDesc(paramItem);
            paramKey += MathFuncKeysString;
            paramKeyArray.push(...MathFuncKeysStringArray);
          }
        }
      } else {
        keys.forEach((key: string) => {
          const params = condition.params[key] as FieldData[];
          if (params && params.length > 0) {
            for (const paramItem of params) {
              const { MathFuncKeysString, MathFuncKeysStringArray } =
                createConditionStringWithFuncDesc(paramItem);
              paramKey += MathFuncKeysString;
              paramKeyArray.push(...MathFuncKeysStringArray);
            }
          }
          paramKey += ',';
          paramKeyArray.push(',');
        });
        paramKey = paramKey.slice(0, paramKey.length - 1); //Remove last comma
        paramKeyArray.pop();
      }
    }
    MathFuncKeysString += condition.key + '(' + paramKey + ')';
    MathFuncKeysStringArray.push(condition.key, '(', ...paramKeyArray, ')');
  } else {
    MathFuncKeysString += condition.key;
    MathFuncKeysStringArray.push(condition.key);
  }
  return {
    MathFuncKeysString,
    MathFuncKeysStringArray,
  };
};

export const validateMathDescFindErrorIndex = (conditions: FieldData[]) => {
  const keysStringArray: Array<string> = [];
  let keysString = '';
  let mathErrorIndex = -1;
  conditions.forEach(condition => {
    const { MathFuncKeysString, MathFuncKeysStringArray } =
      createConditionStringWithFuncDesc(condition);
    keysString += MathFuncKeysString;
    keysStringArray.push(...MathFuncKeysStringArray);
  });
  const mathFuncMatchResult: FinalMatchResult =
    strategyRulesForMathFunction.match(keysString) as FinalMatchResult;
  if (!mathFuncMatchResult.succeeded()) {
    mathErrorIndex = findErrorIndexFromConditions(
      mathFuncMatchResult,
      keysStringArray,
    );
  }
  return {
    status: mathFuncMatchResult.succeeded(),
    mathErrorIndex,
  };
};

const findErrorIndexFromConditions = (
  matchResult: FinalMatchResult,
  keysStringArray: Array<string>,
) => {
  const errorIndex = matchResult.getRightmostFailurePosition();
  let count = 0;
  let errIndex = -1;
  let specialCharCount = 0;
  keysStringArray.findIndex((item: string, index: number) => {
    if (item === ')' || item === ',' || item === '(') {
      specialCharCount += 1;
    }
    count += item.length;
    if (count === errorIndex) {
      errIndex = index - specialCharCount;
      return true;
    }
  });
  return errIndex;
};

export const checkConditions = (conditions: FieldData[]) => {
  const keysString = createConditionsString(conditions);
  return strategyRules.match(keysString) as FinalMatchResult;
};

export const getNextKeys = (matchResult: FinalMatchResult) => {
  const nextEntries = matchResult.getRightmostFailures();
  return nextEntries.map((entry: Failure) => entry.text);
};

export const getDefaultNextKeys = () => {
  const matchResult: FinalMatchResult = checkConditions([]);
  return getNextKeys(matchResult);
};

export const validateConditions = (conditions: FieldData[]) => {
  const matchResult: MatchResult = checkConditions(conditions);
  return matchResult.succeeded();
};

export const validateMathCondition = (condition: FieldData) => {
  const { MathFuncKeysString }: { MathFuncKeysString: string } =
    createConditionStringWithFuncDesc(condition);
  const mathFuncMatchResult =
    strategyRulesForMathFunction.match(MathFuncKeysString);
  return mathFuncMatchResult.succeeded();
};

export const validateMathWithoutDescConditions = (conditions: FieldData[]) => {
  const { keysString }: { keysString: string } =
    createConditionsStringWithoutFuncDesc(conditions);
  const mathFuncMatchResult = strategyRulesForMathFunction.match(keysString);
  return mathFuncMatchResult.succeeded();
};

export const getValidationObject = (conditions: FieldData[]) => {
  return conditions.length > 0 ? validateConditions(conditions) : true;
};

export const getValidateErrorIndex = (conditions: FieldData[]) => {
  const returnObj = {
    status: false,
    errorIndex: -1,
  };
  const {
    keysString,
    keysStringArray,
  }: { keysString: string; keysStringArray: string[] } =
    createConditionsStringWithoutFuncDesc(conditions);
  const matchResult: FinalMatchResult = strategyRulesWithoutMath.match(
    keysString,
  ) as FinalMatchResult;
  if (matchResult.succeeded()) {
    return returnObj;
  } else {
    returnObj.status = true;
    returnObj.errorIndex = findErrorIndexFromConditions(
      matchResult,
      keysStringArray,
    );
    return returnObj;
  }
};
