import { useQuery } from 'react-query';
import { GetBrokers } from 'services/apiServices';
import { Errors, GetBrokersResult } from 'types/ApiServicesTypes';

export default function useGetBrokers(params?: string) {
  const objQuery = useQuery<GetBrokersResult, Errors>(
    ['GetBrokers', params],
    () => GetBrokers(params),
  );
  return objQuery;
}
