import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ShowMoreText from 'react-show-more-text';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import { isBlankHtmlString } from 'utils/GenericFunctions';

const BotDetailDescription = () => {
  const { bid, sid } = useParams<{ bid: string; sid: string }>();
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const { data: botDetails } = useGetBotDetails(parseInt(bid), subId);

  return botDetails &&
    botDetails.description &&
    !isBlankHtmlString(botDetails.description) ? (
    <Box sx={{ mb: 2 }} className="m-main-spacing">
      <ShowMoreText
        lines={2}
        more="Read More"
        less="Less"
        className="show-more"
        anchorClass="anchor-show-more"
        expanded={false}
        truncatedEndingComponent={'... '}
      >
        <Typography
          variant={'body2'}
          sx={{ fontWeight: 400, color: 'text.secondary' }}
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __html: botDetails.description,
          }}
        ></Typography>
      </ShowMoreText>
    </Box>
  ) : null;
};

export default BotDetailDescription;
