import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import NotificationContentBody from 'app/components/Portfolio/NotificationContentBody';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { openInNewTab } from 'utils/GenericFunctions';

// Service
import { GetNotificationsResult } from 'types/ApiServicesTypes';
import useReadNotifications from 'services/common/useReadNotifications';
import useReadAllNotifications from 'services/common/useReadAllNotifications';
import useGetBrokerAuthUrl from 'services/Menu/useGetBrokerAuthUrl';

const NotificationModal = ({
  open,
  handleClose,
  unreadCount,
  notificationsData,
  loading,
}: {
  open: boolean;
  handleClose: () => void;
  notificationsData: GetNotificationsResult | undefined;
  unreadCount: number;
  loading: boolean;
}) => {
  const breadcrumb = useBreadcrumbManager();
  const history = useHistory();

  // Api calling for read notification
  const mutation = useReadNotifications();
  // Api calling for read all notification
  const mutationReadAll = useReadAllNotifications();
  const mutationGetBrokerAuthUrl = useGetBrokerAuthUrl();

  const handleCloseModal = () => {
    handleClose();
  };

  const handleItemClick = (
    id: number,
    type?: string,
    subscriptionId?: number,
    redirectUrl?: string,
    notificationType?: string,
    brokerId?: number,
  ) => {
    handleClose();
    breadcrumb.resetBreadcrumpPaths();
    mutation.mutate({ id: id });

    switch (notificationType) {
      case '0':
      case '1':
        history.push(`/portfolio/${type}/${subscriptionId}`);
        break;
      case '2':
      case '6':
      case '7':
        if (redirectUrl) history.push(redirectUrl);
        break;
      case '3':
      case '4':
      case '9':
        if (redirectUrl) {
          if (redirectUrl.startsWith('http')) openInNewTab(redirectUrl);
          else history.push(redirectUrl);
        }
        break;
      case '5':
        if (brokerId) mutationGetBrokerAuthUrl.mutate({ id: brokerId });
        break;
    }
  };

  const handleReadAll = () => {
    mutationReadAll.mutate();
  };

  return (
    <>
      <CircularLoader open={loading} zIndex={1302} />
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <NotificationContentBody
            loading={loading}
            unreadCount={unreadCount}
            handleReadAll={handleReadAll}
            notificationsData={notificationsData}
            handleItemClick={handleItemClick}
            handleCloseModal={handleCloseModal}
          />
        }
      />
    </>
  );
};

export default memo(NotificationModal);
