import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { SubscribePlan } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import {
  SubscribePlanParams,
  SubscribePlanResult,
} from 'types/SubscriptionPlans';

export default function useSubscribePlan(
  successCallback: (res: SubscribePlanResult, plan_id: number) => void,
) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    SubscribePlanResult,
    Errors,
    Pick<
      SubscribePlanParams,
      | 'plan_id'
      | 'end_date'
      | 'payment_type'
      | 'payment_acquirer'
      | 'coupon_code'
    >
  >('SubscribePlan', values =>
    SubscribePlan(
      values.plan_id,
      values.end_date,
      values.payment_type,
      values.payment_acquirer,
      values.coupon_code,
    )
      .then(res => {
        if (res && res.status == 'error' && res.message) {
          // showAlert!(res?.message);
          showAlert!(res.message);
        } else if (!res || !res.status)
          showAlert!('Something went wrong. Please try again.');
        else successCallback(res, values.plan_id);
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
