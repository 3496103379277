import { Box, Divider, Typography } from '@mui/material';
import {
  calculateProfitLossAndPerChange,
  getCurrentSymbolPrice,
} from 'app/design/speedBot/ComponentItem/Portfolio/utils';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import {
  GetBotTodaysPositionsQueryResult,
  Position,
} from 'types/ApiServicesTypes';
import { SymbolsData } from 'types/Symbols';
import { getColorByNum, getArrowDirByNum } from 'utils/GenericFunctions';

const formatDecimalPrecision = (num: number) => {
  return new Intl.NumberFormat('en-IN', {
    maximumSignificantDigits: 2,
  }).format(num);
};

const calculateTodaysChange = (
  liveFeedData: SymbolsData,
  positions: Position[],
) => {
  let totalProfitLoss = 0;
  positions.forEach(position => {
    const currentPrice = getCurrentSymbolPrice(
      position.exit_price,
      position.entry_Price,
      liveFeedData,
      position.trading_symbol,
    );
    const { profitLoss } = calculateProfitLossAndPerChange(
      currentPrice,
      position,
      true,
    );
    totalProfitLoss += profitLoss;
  });

  return totalProfitLoss;
};

const BotSignalTodaysChange = ({
  data,
  liveFeedData,
}: {
  data: GetBotTodaysPositionsQueryResult;
  liveFeedData: SymbolsData;
}) => {
  const { openPositions, closePositions } = data;

  const isMdUp = useGetMediaQueryUp('md');

  const todays_change = calculateTodaysChange(liveFeedData, [
    ...openPositions,
    ...closePositions,
  ]);

  const currencySymbol =
    openPositions[0]?.currency_symbol ||
    closePositions[0]?.currency_symbol ||
    '';

  return (
    <Box className="p-main-spacing" sx={{ mt: 1 }}>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          justifyContent: { xs: 'space-between', md: 'start' },
          mb: { xs: 1.5, md: 0 },
        }}
      >
        <Typography variant="body1" color="text.secondary">
          <FormattedMessage id="summary_detail.todays_change" />
        </Typography>
        <Typography
          variant="body1"
          color={getColorByNum(todays_change)}
          fontWeight={500}
        >
          {`${getArrowDirByNum(
            todays_change,
          )} ${currencySymbol}${formatDecimalPrecision(todays_change)}`}
        </Typography>
      </Box>
      {!isMdUp && <Divider sx={{ borderColor: 'grey.300' }} />}
    </Box>
  );
};

export default BotSignalTodaysChange;
