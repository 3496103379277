import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import SellOnMarketForm from 'app/components/Bots/SellOnMarket/SellOnMarketForm';

export function SellOnMarketUpdatePage() {
  const history = useHistory();
  const { id, title } = useParams<{ id: string; title: string }>();

  return (
    <>
      <Helmet>
        <title>Sell On Discover Edit</title>
        <meta name="description" content="Sell On Discover Page Edit" />
      </Helmet>
      <CircularLoader open={false} />

      <Box>
        <PageHeader
          variant="inactive"
          buttonText={'Sell On Discover'}
          title={
            <FormattedMessage id="sell_on_market.page_title.sell_on_market_edit" />
          }
          buttonHandleClick={() => history.goBack()}
        />
      </Box>
      <SellOnMarketForm mode="edit" id={Number(id)} botName={title} />
    </>
  );
}
