import { debounce } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { GetPaidAmount } from 'services/apiServices';
import { Errors, GetPaidAmountResult } from 'types/ApiServicesTypes';

const WAIT_INTERVAL = 300;

export default function useGetPaidAmount(credit_amount: number) {
  const objQuery = useQuery<GetPaidAmountResult, Errors>(
    ['GetPaidAmount'],
    async () => {
      return await GetPaidAmount(credit_amount);
    },
  );

  const { refetch } = objQuery;

  const debouncedRefetch = React.useMemo(() => {
    return debounce(refetch, WAIT_INTERVAL);
  }, [refetch]);

  //   const handleAmountChange = React.useCallback(() => {
  //     void debouncedRefetch();
  //   }, [debouncedRefetch]);
  React.useEffect(() => {
    void debouncedRefetch();
  }, [credit_amount, debouncedRefetch]);

  return objQuery;
}
