import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { EditUserBot } from 'services/apiServices';
import {
  Errors,
  SaveUserBotParams,
  SaveUserBotResult,
} from 'types/ApiServicesTypes';

export default function useEditUserBot(
  botId: number,
  successCallback: (res?: SaveUserBotResult, name?: string) => void,
) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    SaveUserBotResult,
    Errors,
    Pick<
      SaveUserBotParams,
      'name' | 'sb_strategy' | 'strategy_id' | 'is_backtest'
    >
  >('EditUserBot', values =>
    EditUserBot(botId, values)
      .then(res => {
        if (!res || res.status_code !== 200 || res.error)
          showAlert!(
            res.error ? res.error : 'Something went wrong. Please try again.',
          );
        else successCallback(res, values.name);

        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
