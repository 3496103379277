import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  ReactNode,
  useContext,
} from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PortfolioSummary from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioSummary';
import PortfolioListItem from 'app/design/itemsComponent/PortfolioListItem';
import {
  PortfolioBot,
  PortfolioBotsData,
  Position,
} from 'types/ApiServicesTypes';
import { useGetTickData } from 'app/hooks/useGetTickData';
import { PortfolioBotType } from 'types/ComponentTypes';
import { calculateLiveFeedPostitionSum } from './utils';
import PortfolioTable from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioTable';
import { AllBotOpenPositions } from 'services/Portfolio/useGetPortfolioActivePaper';
import {
  handleTouchMoveHandle,
  handleTouchStartHandle,
} from 'app/design/layouts/swipeUtil';
import AddIcon from '@mui/icons-material/Add';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useHistory } from 'react-router-dom';
import PortfolioShareIcons from './PortfolioShareIcons';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { getQueryParam } from 'utils/GenericFunctions';
import IndividualOnboarding from 'app/design/speedBot/ComponentItem/Portfolio/IndividualOnboarding';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import ManualBotModal from './ManualBotModal';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiSwitchUnstyled from 'app/design/uiComponents/MuiUnstyledSwitch';
import { ToggleContext } from '../Context/ToggleViewContext';

export const ACTIVE = 'ACTIVE';
export const PAPER = 'PAPER';
export const MANUAL = 'MANUAL';
export const MYBOTS = 'MYBOTS';
type ActivePaperTabItemProps = {
  type: PortfolioBotType;
  isLoading: boolean;
  manualData?: PortfolioBot[] | undefined;
  portfolioData: PortfolioBotsData | undefined;
  symbols: string[];
  openPositions: Position[] | AllBotOpenPositions[];
  handleChangeTab: (newValue: number) => void;
  selectedTab: string;
};

let eventDir: string | null = null;

// eslint-disable-next-line react/display-name
const RefWrapper = forwardRef<HTMLDivElement, { children: ReactNode }>(
  (props, ref) => {
    return <Box ref={ref}>{props.children}</Box>;
  },
);

const ActivePaperTabItem: React.FC<ActivePaperTabItemProps> = props => {
  const { open, toggle } = useContext(ToggleContext);
  const queryType = getQueryParam('ptype');
  const isMdUp = useGetMediaQueryUp('md');
  const { type, isLoading, symbols, openPositions, selectedTab } = props;
  const portfolioBots = props.portfolioData?.bots;
  const isSharePortfolio = useIsFeatureFlag([FEATURES.IS_SHARE_PORTFOLIO]);
  const query = getQueryParam('ptype');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const liveFeedData = useGetTickData(symbols);
  const isViewAllPositions = useIsFeatureFlag([FEATURES.IS_VIEW_ALL_POSITIONS]);
  const history = useHistory();
  let liveFeedTodaysPosData: { [id: number]: number } = {};

  if (openPositions && openPositions.length > 0) {
    openPositions.forEach((posData: Position | AllBotOpenPositions) => {
      const sum = calculateLiveFeedPostitionSum(liveFeedData, posData);
      liveFeedTodaysPosData = {
        ...liveFeedTodaysPosData,
        [posData.id]: sum,
      };
    });
  }

  const [isSpaceAvailable, setIsSpaceAvailable] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (
        containerRef.current &&
        containerRef.current.getClientRects().length > 0
      ) {
        const spaceThreshold = isMdUp ? 100 : 174;
        const isSpaceAvail =
          window.innerHeight - containerRef.current.getClientRects()[0].bottom >
          spaceThreshold;
        setIsSpaceAvailable(isSpaceAvail);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial calculation

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, isMdUp, type, selectedTab]);

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    handleTouchStartHandle(e);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const eventDirRes = handleTouchMoveHandle(e);
    if (eventDirRes) eventDir = eventDirRes;
  };

  const handleTouchEnd = () => {
    if (!isMdUp && eventDir === 'left') {
      props.handleChangeTab(type === 'PAPER' || type === 'MYBOTS' ? 0 : 1);
      eventDir = '';
    }
    if (!isMdUp && eventDir === 'right') {
      props.handleChangeTab(type === 'ACTIVE' || type === 'MYBOTS' ? 1 : 2);
      eventDir = '';
    }
    if (
      !isMdUp &&
      eventDir === 'right' &&
      queryType.toLocaleLowerCase() === 'mybot'
    ) {
      history.replace({
        search: 'ptype=paper',
      });
    }
  };

  return (
    <Box
      onTouchStart={(touchStartEvent: React.TouchEvent<HTMLElement>) =>
        handleTouchStart(touchStartEvent)
      }
      onTouchMove={(touchMoveEvent: React.TouchEvent<HTMLElement>) =>
        handleTouchMove(touchMoveEvent)
      }
      onTouchEnd={() => handleTouchEnd()}
      sx={{ height: '100%' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CircularLoader open={isLoading} />
        {!isLoading && portfolioBots && portfolioBots.length > 0 ? (
          <>
            {type == 'PAPER' || type == 'ACTIVE' ? (
              <PortfolioSummary type={type} />
            ) : (
              <Box
                sx={{ my: 1, ml: 'auto', textAlign: 'right' }}
                className="p-main-spacing"
              >
                <MuiButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenModal(true)}
                >
                  Create Bot
                </MuiButton>
              </Box>
            )}
            <Box>
              {type == 'PAPER' || type == 'ACTIVE' ? (
                isMdUp ? (
                  <RefWrapper ref={containerRef}>
                    <PortfolioTable
                      bots={portfolioBots}
                      ptype={type === PAPER ? 'paper' : 'active'}
                      liveFeedTodaysPosData={liveFeedTodaysPosData}
                    />
                  </RefWrapper>
                ) : (
                  <RefWrapper ref={containerRef}>
                    {!isMdUp &&
                      isViewAllPositions &&
                      (query === 'active' || query === 'paper') && (
                        <Box
                          flex={1}
                          mt={1}
                          display="flex"
                          justifyContent="flex-end"
                          className="p-main-spacing"
                          alignItems="center"
                          gap={1}
                        >
                          <Typography variant="body2">
                            {/* {toTitleCase(query === 'active' ? 'Live' : query)}{' '} */}
                            Bots
                          </Typography>
                          <Tooltip
                            title={!open ? 'See all Positions' : 'See all bots'}
                          >
                            <MuiSwitchUnstyled
                              checked={open}
                              onChange={toggle}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {/* {toTitleCase(query === 'active' ? 'Live' : query)}{' '} */}
                            Positions
                          </Typography>
                        </Box>
                      )}
                    <PortfolioListItem
                      bots={portfolioBots}
                      ptype={type === PAPER ? 'paper' : 'active'}
                      liveFeedTodaysPosData={liveFeedTodaysPosData}
                    />
                  </RefWrapper>
                )
              ) : isMdUp ? (
                <PortfolioTable
                  bots={portfolioBots}
                  ptype={'manual'}
                  liveFeedTodaysPosData={liveFeedTodaysPosData}
                />
              ) : (
                <PortfolioListItem
                  bots={portfolioBots}
                  ptype={'manual'}
                  liveFeedTodaysPosData={liveFeedTodaysPosData}
                />
              )}
            </Box>
            {isSharePortfolio && query && (
              <Box
                sx={{
                  py: 2,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  flexShrink: 0,
                  gap: 1,
                  mt: isSpaceAvailable ? 0 : 3,
                  mx: isSpaceAvailable ? 'auto' : undefined,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: isMdUp ? 'row' : 'column-reverse',
                  position: isSpaceAvailable ? 'absolute' : 'relative',
                  bottom: isSpaceAvailable ? (isMdUp ? 20 : 74) : undefined,
                }}
              >
                <Typography variant="body2" color={'text.secondary'} mr={1}>
                  <FormattedMessage id={'portfolio.share_pnl'} />
                </Typography>
                <PortfolioShareIcons
                  title=""
                  variant="medium"
                  whichIconYouWant={[
                    'twitter',
                    'facebook',
                    'whatsapp',
                    'email',
                    'telegram',
                    'copy',
                  ]}
                />
              </Box>
            )}
          </>
        ) : !isLoading ? (
          <IndividualOnboarding type={type} />
        ) : (
          <></>
        )}
      </Box>
      <ManualBotModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </Box>
  );
};

export default ActivePaperTabItem;
