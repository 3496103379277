import { RestRequest } from 'services/apiServices';
import {
  GetBotTemplateDetailResult,
  GetBotTemplatesCategoryResult,
  GetBotTemplatesResult,
  PaginationApiParams,
} from 'types/ApiServicesTypes';

export async function GetBotTemplates(type: {
  type?: string;
}): Promise<GetBotTemplatesResult['data']> {
  const path =
    type.type === ''
      ? 'templates/strategies'
      : `templates/strategies?type=${type.type}`;
  const response = await RestRequest<
    PaginationApiParams,
    GetBotTemplatesResult
  >(path, undefined, 'GET', 'text/html');

  if (response.result?.success) {
    return response.result.data;
  } else if (response.result?.error) {
    throw new Error(response.result.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotTemplateDetail(
  id: string,
): Promise<GetBotTemplateDetailResult['data']> {
  const response = await RestRequest<
    PaginationApiParams,
    GetBotTemplateDetailResult
  >(`templates/strategies/${id}/details`, undefined, 'GET', 'text/html');

  if (response.result?.success) {
    return response.result.data;
  } else if (response.result?.error) {
    throw new Error(response.result.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}

export async function GetBotCategorySearch(
  page: number,
  pageSize: number,
  search: string,
  categories: string,
): Promise<GetBotTemplatesCategoryResult['data']> {
  const params = {
    search,
    categories,
    page: page.toString(),
    page_size: pageSize.toString(),
  };
  Object.keys(params).forEach(key => {
    if (!params[key]) delete params[key];
  });
  const response = await RestRequest<
    PaginationApiParams,
    GetBotTemplatesCategoryResult
  >(
    `templates/strategies?${new URLSearchParams(params).toString()}`,
    undefined,
    'GET',
    'text/html',
  );

  if (response.result?.success) {
    return response.result.data;
  } else if (response.result?.error) {
    throw new Error(response.result.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
