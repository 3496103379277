import { CloseOutlined } from '@mui/icons-material';
import {
  Alert,
  AlertColor,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useTranslation } from 'react-i18next';
import { deepPurple } from '@mui/material/colors';
interface HintAlertProps {
  severity?: AlertColor;
  backgroundColor?: string;
  textColor?: string;
  iconColor?: string;
  hintTextKey?: string;
  icon?: JSX.Element;
}
const HintAlert = ({
  severity = 'info',
  backgroundColor = deepPurple['50'],
  textColor = deepPurple['900'],
  hintTextKey = 'backtesting.hint_text',
  icon = <AutoAwesomeIcon sx={{ color: deepPurple['A700'] }} />,
}: HintAlertProps) => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  return (
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        }
        sx={{
          backgroundColor: backgroundColor,
          color: textColor,
        }}
        icon={icon}
      >
        <Typography variant="body2">{t(hintTextKey)}</Typography>
      </Alert>
    </Collapse>
  );
};

export default HintAlert;
