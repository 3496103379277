import i18next from 'i18next';
import { NoBreadcrumbData, PAGES } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const categoryAndSearch = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.TEMPLATES,
      link: `/templates`,
      label: i18next.t('templates.page_title.templates'),
    },
  ];
};
