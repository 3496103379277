import React from 'react';
import throttle from 'lodash/throttle';
import intersection from 'lodash/intersection';
import { Socket } from 'socket.io-client';

import {
  State,
  NotificationCallback,
  NotificationMessage,
  SOCKET_NOTIFICATION_MESSAGE_TYPES,
} from './types';
import { SOCKET_CONSTANTS } from 'constants/socket';
import { useSocket } from 'app/components/Context/SocketContext';

const NOTICATION_THROTTLE_TIME = 1 * 1000;

export class SocketNotificationManager {
  private state: State;
  private socket: Socket;
  constructor(socket: Socket) {
    this.state = {
      newMessages: [],
      registredComponents: {},
    };
    this.socket = socket;
    this.socket.on(
      SOCKET_CONSTANTS.NOTIFICATION_CHANNEL,
      this.onNotification.bind(this),
    );
  }

  private resetNewMessages() {
    this.state.newMessages = [];
  }

  private addNewMessages(messages: NotificationMessage[]) {
    this.state.newMessages = this.state.newMessages.concat(messages);
  }

  private getNewMessageTypes() {
    return Array.from(new Set(this.state.newMessages.map(m => m.msg_type)));
  }

  private callComponentsCallback() {
    const newMessages = this.state.newMessages;
    const registredComponents = this.state.registredComponents;
    const newMessageTypes = this.getNewMessageTypes();

    Object.entries(registredComponents).forEach(entry => {
      const componentData = entry[1];
      const { messageTypes: componentMessageTypes, callback } = componentData;

      const isComponentCallbackRequired =
        intersection(componentMessageTypes, newMessageTypes).length > 0;
      if (isComponentCallbackRequired) {
        callback(newMessages);
      }
    });
  }

  throttleCallComponentsCallback = throttle(() => {
    this.callComponentsCallback();
    this.resetNewMessages();
  }, NOTICATION_THROTTLE_TIME);

  private onNotification(messages: NotificationMessage[]) {
    this.addNewMessages(messages);
    this.throttleCallComponentsCallback();
  }

  public unRegisterComponent(componentId: string) {
    const registredComponents = this.state.registredComponents;
    delete registredComponents[componentId];
  }

  public registerComponent(
    componentId: string,
    messageTypes: SOCKET_NOTIFICATION_MESSAGE_TYPES[],
    callback: NotificationCallback,
  ) {
    const registredComponents = this.state.registredComponents;

    if (registredComponents[componentId]) {
      this.unRegisterComponent(componentId);
    }

    registredComponents[componentId] = { messageTypes, callback };
  }
}

export const useSocketNotificationManager = () => {
  const { socket } = useSocket();
  const [notificationManager, setNotificationManager] =
    React.useState<SocketNotificationManager>();

  React.useEffect(() => {
    if (socket) {
      setNotificationManager(new SocketNotificationManager(socket));
    }
  }, [socket]);

  return notificationManager;
};
