/* eslint-disable no-console */
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { pickBy } from 'lodash';
import { InfoType } from 'types/ComponentTypes';
import { ComboBox } from 'types/Generic';
import { GeneratorPages, PAGES } from '../Common/Breadcrumb/types';
import moment from 'moment';
import {
  MonthYearData,
  ProfitLossByDateTransform,
} from 'app/design/uiComponents/Calendar/types';
import {
  ProfitLossByDate,
  ReportDropDownValue,
  ReportTransfromPnlData,
} from 'types/ApiServicesTypes';
import { formatStringToDateString } from 'utils/GenericFunctions';

export const infoModalData: InfoType[] = [
  {
    title: <FormattedMessage id="backtesting.info.modal.initial_cap.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.initial_cap.desc" />,
  },
  {
    title: (
      <FormattedMessage id="backtesting.info.modal.candle_interval.title" />
    ),
    desc: <FormattedMessage id="backtesting.info.modal.candle_interval.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.period.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.period.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.duration.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.duration.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.positions.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.positions.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.total_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.total_prof.desc" />,
  },
  {
    title: <FormattedMessage id="brokerage_fees" />,
    desc: <FormattedMessage id="backtesting.info.modal.brokerage.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.net_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.net_prof.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.car.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.car.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.mdd.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.mdd.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.sharp_ratio.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.sharp_ratio.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.avg_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.avg_prof.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.avg_loss.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.avg_loss.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.max_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.max_prof.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.max_loss.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.max_loss.desc" />,
  },
];

export const SubscriptionFeesValues: ComboBox[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Free',
    value: 'free',
  },
  {
    label: 'Paid',
    value: 'paid',
  },
];

export const PriceRangeValues: ComboBox[] = [
  {
    label: 'Any',
    value: 'any',
  },
  {
    label: 'Under ₹ 5,000',
    value: '0,5000',
  },
  {
    label: 'Under ₹ 25,000',
    value: '5001,25000',
  },
  {
    label: 'Under ₹ 50,000',
    value: '250001,50000',
  },
];
export const Deployment: ComboBox[] = [
  {
    label: 'Both',
    value: 'both',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Forward',
    value: 'paper',
  },
];

export const VolatilityValues: string[] = ['Low', 'Moderate', 'High'];

export const convertToCheckBoxValues = (
  data: ReportDropDownValue[],
  selected: Array<string | number>,
) => {
  return data?.reduce((acc, crr) => {
    acc[crr.label] = selected.includes(crr.label) ? true : false;
    // console.log(acc);
    return acc;
  }, {} as Record<string | number, boolean>);
};

export const getCheckedValues = (data: Record<string | number, boolean>) => {
  return Object.keys(pickBy(data, Boolean));
};
// console.log(getCheckedValues())

type PageUtils = {
  title: string;
  positionPage: GeneratorPages;
  orderPage: GeneratorPages;
  symbolPage: GeneratorPages;
  pnlPage: GeneratorPages;
};

export const getCurrentPageUtilsForPath = (path: string): PageUtils => {
  switch (path) {
    case 'marketplace':
      return {
        title: 'Marketplace',
        positionPage: PAGES.MARKETPLACE_POSITIONS,
        orderPage: PAGES.MARKETPLACE_ORDERS,
        symbolPage: PAGES.MARKETPLACE_SYMBOLS,
        pnlPage: PAGES.MARKETPLACE_PROFITLOSS,
      };
    case 'trader':
      return {
        title: 'Traders',
        positionPage: PAGES.TRADERS_POSITIONS,
        orderPage: PAGES.TRADERS_ORDERS,
        symbolPage: PAGES.TRADERS_SYMBOLS,
        pnlPage: PAGES.TRADERS_PROFITLOSS,
      };
    default:
      return {
        // title: 'Discover',
        // positionPage: PAGES.DISCOVER_POSITIONS,
        // orderPage: PAGES.DISCOVER_ORDERS,
        // symbolPage: PAGES.DISCOVER_SYMBOLS,
        // pnlPage: PAGES.DISCOVER_PROFITLOSS,
        title: 'Traders',
        positionPage: PAGES.TRADERS_POSITIONS,
        orderPage: PAGES.TRADERS_ORDERS,
        symbolPage: PAGES.TRADERS_SYMBOLS,
        pnlPage: PAGES.TRADERS_PROFITLOSS,
      };
  }
};
export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);

  const now = toDate.clone(),
    months: MonthYearData[] = [];

  while (now.format('MM/YYYY') !== fromDate.format('MM/YYYY')) {
    months.push({
      month: parseInt(now.format('MM')),
      year: parseInt(now.format('YYYY')),
    });
    now.subtract(1, 'months');
  }
  months.push({
    month: parseInt(fromDate.format('MM')),
    year: parseInt(fromDate.format('YYYY')),
  });

  return months;
};

const getColorForPercentage = (percentage: number, profitLoss: number) => {
  let opacity = 1;
  if (Math.abs(percentage) <= 20) opacity = 0.2;
  else if (Math.abs(percentage) <= 40) opacity = 0.4;
  else if (Math.abs(percentage) <= 60) opacity = 0.6;
  else if (Math.abs(percentage) <= 80) opacity = 0.8;

  return { color: profitLoss >= 0 ? 'success.main' : 'error.main', opacity };
};

export const getDateDataByMonth = (
  data: ReportTransfromPnlData[],
  month: number,
  year: number,
) => {
  return data.filter(e => {
    const [day, mnth, yr] = e.date.split(' ');
    day.length;
    const monthIndex = moment().month(mnth).format('M');

    return month === parseInt(monthIndex) && year === parseInt(yr);
  });
};
export const transformProfitLossByDateData = (
  data: ProfitLossByDate[],
): ProfitLossByDateTransform[] => {
  //   const posData = data.filter(x => x.profit_loss >= 0);
  //   const negData = data.filter(x => x.profit_loss < 0);
  //   const minPLPos = Math.min(...posData.map(x => x.profit_loss));
  //   const maxPLPos = Math.max(...posData.map(x => x.profit_loss));
  //   const minPLNeg = Math.min(...negData.map(x => x.profit_loss));
  //   const maxPLNeg = Math.max(...negData.map(x => x.profit_loss));

  const min = Math.min(...data.map(x => Math.abs(x.profit_loss)));
  const max = Math.max(...data.map(x => Math.abs(x.profit_loss)));
  const minPLPos = min;
  const maxPLPos = max;
  const minPLNeg = max === 0 ? 0 : max * -1;
  const maxPLNeg = min === 0 ? 0 : min * -1;

  const resultData: ProfitLossByDateTransform[] = data.map(x => {
    // Get percentage which is return 20 to 100
    const profitLossPercentage =
      x.profit_loss === 0
        ? 0
        : x.profit_loss > 0
        ? 20 + ((x.profit_loss - minPLPos) / (maxPLPos - minPLPos)) * 80
        : 20 + ((x.profit_loss - maxPLNeg) / (minPLNeg - maxPLNeg)) * 80;

    const data: ProfitLossByDateTransform = {
      ...x,
      date: formatStringToDateString(x.date, 'DD MMM YYYY', 'YYYY-MM-DD'),
      profitLossPercentage: profitLossPercentage,
      color: getColorForPercentage(profitLossPercentage, x.profit_loss),
    };
    return data;
  });

  return resultData;
};

export const durationValueFromDate = (startDate: string, endDate: string) => {
  if (endDate === moment().format('YYYY-MM-DD')) {
    if (startDate === moment().subtract(1, 'month').format('YYYY-MM-DD')) {
      return 'Last 1 Month';
    } else if (
      startDate === moment().subtract(3, 'month').format('YYYY-MM-DD')
    ) {
      return 'Last 3 Months';
    } else if (
      startDate === moment().subtract(6, 'month').format('YYYY-MM-DD')
    ) {
      return 'Last 6 Months';
    } else if (
      startDate === moment().subtract(1, 'year').format('YYYY-MM-DD')
    ) {
      return 'Last 1 Year';
    }
  } else {
    return 'Custom';
  }
};
