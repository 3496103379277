import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { PostClearError } from 'services/apiServices';
import { Errors, PostClearErrorResult } from 'types/ApiServicesTypes';

export default function usePostClearError(subId: number) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostClearErrorResult,
    Errors,
    Pick<{ errorId: number }, 'errorId'>
  >(['PostClearError'], values =>
    PostClearError(subId, values.errorId)
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
