import { useQuery } from 'react-query';
import { GetEvents } from 'services/apiServices';
import { Errors, GetEventsResult } from 'types/ApiServicesTypes';

export default function useGetEvents(event?: string, platform?: boolean) {
  const objQuery = useQuery<GetEventsResult, Errors>(
    ['useGetEvents', event, platform],
    () => GetEvents(event, platform),
  );
  return objQuery;
}
