import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

const HoldingBanner = ({
  position,
  text,
  image,
  link,
  bgColor,
  borderRadius,
  handleClick,
}: {
  position: string;
  text: React.ReactElement;
  image: string;
  link?: string;
  bgColor?: string;
  borderRadius?: number;
  handleClick?: () => void;
}): React.ReactElement => {
  return (
    <Box
      component={link ? RouterLink : 'div'}
      {...(link && { to: link })}
      {...(handleClick && { onClick: handleClick })}
      sx={{
        mb: 1,
        ...(link && { display: 'block' }),
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: position,
          px: 1.5,
          py: 2,
          backgroundColor: bgColor ? bgColor : 'grey.100',
          borderRadius: borderRadius ? borderRadius : 0,
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="500"
          fontSize="15px"
          sx={{
            flex: 1,
            mr: 1,
            lineHeight: '26px',
            letterSpacing: '0.46px',
          }}
        >
          {text}
        </Typography>
        <img src={image} />
      </Box>
    </Box>
  );
};

export default HoldingBanner;
