export enum CAPABILITIES {
  LIVE_TRADING = 'go_live',
  PAPER_TESTING = 'go_paper',
  BACK_TESTING = 'backtesting',
}

export type Capability = {
  capabilityId: number;
  capabilityKey: CAPABILITIES;
  name: string;
  description: string;
};

export type UserCapabilityBalance = {
  capabilityId: number;
  capabilityKey: CAPABILITIES;
  balance: number;
};
