import { useMemo } from 'react';
import { css, CSSObject } from '@emotion/css';
import { Theme, useTheme } from '@mui/material/styles';

interface DynamicObject {
  [key: string]: string;
}

type StylesElement =
  | DynamicObject
  | ((theme: Theme) => Record<string, unknown>);

const useClasses = (stylesElement: StylesElement) => {
  const theme: Theme = useTheme();
  return useMemo(() => {
    const rawClasses =
      typeof stylesElement === 'function'
        ? stylesElement(theme)
        : stylesElement;
    const prepared: DynamicObject = {};

    Object.entries(rawClasses).forEach(([key, value = {}]) => {
      prepared[key] = css(value as CSSObject);
    });

    return prepared;
  }, [stylesElement, theme]);
};

export default useClasses;
