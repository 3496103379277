import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import OrdersListLayout from 'app/design/layouts/OrdersListLayout';
import BotHistoryOrdersModal from 'app/components/Bots/BotHistory/BotHistoryOrdersModal';
import { encodeUrlData, decodeUrlData } from 'utils/GenericFunctions';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import PageHeader from 'app/design/speedBot/PageHeader';

// Service
import { Order } from 'types/ApiServicesTypes';
import useGetBotOrders from 'services/Bots/useGetBotOrders';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function MarketplaceOrdersPage() {
  const { id, title } = useParams<{ id: string; title: string }>();
  const { t } = useTranslation();
  const titleData = decodeUrlData(title);
  const history = useHistory();
  const [page, setPage] = React.useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const isFromTrader = window.location.pathname.startsWith('/trader');

  const baseApiUrl = `marketplace/bots/${id}`;

  // For change tab
  const handleChange = (newValue: number) => {
    const path = `/${
      isFromTrader ? 'trader' : 'discover'
    }/${id}/positions/${encodeUrlData(titleData)}`;
    if (newValue === 0) {
      history.replace({ pathname: path });
    }
  };

  const botId = parseInt(id);
  const { data: botOrdersData, isLoading } = useGetBotOrders(
    botId,
    page,
    baseApiUrl,
  );

  const totalPages =
    botOrdersData && botOrdersData.length > 0
      ? Number(botOrdersData[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleModalOpen = (orderData: Order) => {
    setSelectedOrder(orderData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  return (
    <>
      <Helmet>
        <title>Discover Orders</title>
        <meta name="description" content="Discover Orders Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${titleData}`}
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={1}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.position') },
              { label: t('buttons.order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={
              isFromTrader ? PAGES.TRADERS_ORDERS : PAGES.MARKETPLACE_ORDERS
            }
            data={{ botName: title, botId: botId }}
          />
        }
      />
      <OrdersListLayout
        data={botOrdersData}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleClick={handleModalOpen}
        handleChangeTab={handleChange}
      />
      <BotHistoryOrdersModal
        open={openModal}
        handleClose={handleModalClose}
        data={selectedOrder || null}
        title="Orders"
      />
    </>
  );
}
