import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  TextField,
  FormHelperText,
  Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { DEFAULT_COUNTRY_CODE } from 'constants/common';

// service
import { useDoSingIn } from 'services/Auth';
import useVerifyOtp from 'services/Auth/useVerifyOtp';
import useVerifyPhone from 'services/Auth/useVerifyPhone';
import useAuthenticate from 'services/Auth/useAuthenticate';
import useAuthenticateWithGoogle from 'services/Auth/useAuthenticateWithGoogle';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import { getQueryParam } from 'utils/GenericFunctions';
import { ObjProps } from './SignInModal';
import SpeedBotLogo from '../Common/SpeedBotLogo';
import { FEATURES } from 'types/Feature';
import { useIsFeatureFlag } from '../Common/FeatureFlag';

export function SignupVerifyCodePage({
  propsData,
  onAuthPageChange,
}: {
  propsData: ObjProps;
  onAuthPageChange: (view: string) => void;
}) {
  const timerDefault = 120;
  // const { ccode, phone } = useParams<{ ccode: string; phone: string }>();
  const { ccode, pnum, email, password } = propsData;
  const [otptimer, setOtptimer] = useState(timerDefault);
  const [otpSent, setOtpSent] = useState(true);
  // const password: string = password;
  const emailId: string = email;
  // const googleState: AuthValues = props.location.state as AuthValues;
  const otpVarificationFlag = useIsFeatureFlag([FEATURES.IS_OTP_VERIFICATION]);

  const queryParam = getQueryParam('ref');
  const history = useHistory();
  const { doSignIn } = useDoSingIn(queryParam);

  const otpValidationSchema = Yup.object().shape({
    otp: Yup.number().required('Otp number is required'),
  });

  const mutation = useVerifyOtp(ccode, pnum);

  const isMobileNumberLogin = useIsFeatureFlag([
    FEATURES.IS_MOBILE_NUMBER_LOGIN,
  ]);

  // Api calling for signin
  const mutation_auto_signin = useAuthenticate();

  // Api calling for signin with google
  const mutation_authenticate_google = useAuthenticateWithGoogle();

  const mutation_resend_otp = useVerifyPhone(email);

  useEffect(() => {
    if (otptimer > 0) {
      setTimeout(() => setOtptimer(otptimer - 1), 1000);
    } else {
      setOtpSent(false);
      setOtptimer(0);
    }
  }, [otptimer]);
  const handlSignUpPage = () => {
    onAuthPageChange('Sign Up');
  };
  const isLoading =
    mutation.isLoading ||
    mutation_auto_signin.isLoading ||
    mutation_authenticate_google.isLoading ||
    mutation_resend_otp.isLoading;
  return (
    <React.Fragment>
      <Helmet>
        <title>Signup Verify Otp Page</title>
        <meta name="description" content="Signup verify otp page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <SigninPageContentLayout>
        {/* Must have max two child element */}
        <Box>
          <SpeedBotLogo />
          <Box sx={{ mb: 4, paddingInline: 2.5 }}>
            <MuiButton
              variant="text"
              startIcon={<ArrowBackIcon />}
              size="small"
              color="primary"
              onClick={() => handlSignUpPage()}
              sx={{ fontWeight: 600, px: '0 !important' }}
            >
              Back
            </MuiButton>
          </Box>
          <Typography
            variant="body1"
            color="primary.dark"
            sx={{
              my: 3,
              textAlign: 'center',
              maxWidth: '22rem',
              margin: '0 auto',
            }}
          >
            <FormattedMessage
              id={
                otpVarificationFlag
                  ? 'sent_you_code_phone'
                  : 'sent_you_code_email'
              }
            />
          </Typography>
          <Formik
            initialValues={{ otp: '' }}
            validationSchema={otpValidationSchema}
            onSubmit={values => {
              mutation.mutate(values, {
                onSuccess: res => {
                  // Log entry for init app
                  logFirebaseEventEntry('APP_SIGNUP_VERIFICATION_SUCCESS', res);
                  if (res.success) {
                    password
                      ? mutation_auto_signin.mutate(
                          {
                            userid: isMobileNumberLogin ? pnum : email,
                            password,
                          },
                          {
                            onSuccess: res => {
                              if (!res.error) {
                                doSignIn();
                              }
                            },
                          },
                        )
                      : mutation_authenticate_google.mutate(
                          {
                            email: '',
                            access_token: '',
                            token_type: '',
                            expires_in: '',
                            scope: '',
                          },
                          {
                            onSuccess: res => {
                              if (res) {
                                if (res.session_id) {
                                  doSignIn();
                                } else if (res.email) {
                                  history.push(
                                    `/signup/verifyphone/${res.email}`,
                                  );
                                }
                              }
                            },
                          },
                        );
                  }
                },
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 2, maxWidth: '20rem', margin: '4px auto' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 400, mb: 2 }}>
                  <FormattedMessage id="enter_number_to_verify" />{' '}
                  {ccode === DEFAULT_COUNTRY_CODE ? pnum : emailId}
                </Typography>
                <TextField
                  fullWidth
                  label="Verification code"
                  type="password"
                  name="otp"
                  autoComplete="on"
                  error={Boolean(touched.otp && errors.otp)}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.otp && errors.otp && (
                  <FormHelperText error>{errors.otp}</FormHelperText>
                )}
                {mutation.data && mutation.data.error && (
                  <Alert sx={{ mt: 2 }} severity="error">
                    {mutation.data.error}
                  </Alert>
                )}
                {mutation_auto_signin.data && mutation_auto_signin.data.error && (
                  <Alert sx={{ mt: 2 }} severity="error">
                    {mutation_auto_signin.data.error}
                  </Alert>
                )}
                <MuiButton
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  disabled={isLoading}
                  type="submit"
                  sx={{ mt: 4 }}
                >
                  <FormattedMessage id="verify" />
                </MuiButton>
                <Box sx={{ mt: 2 }}>
                  {otpSent ? (
                    <Typography variant="caption">
                      Retry in {otptimer} Sec(s)
                    </Typography>
                  ) : (
                    <MuiButton
                      size="small"
                      variant="text"
                      sx={{ px: '0 !important' }}
                      onClick={() => {
                        setOtpSent(true);
                        setOtptimer(timerDefault);
                        setFieldValue('otp', '');
                        mutation_resend_otp.mutate({
                          country_code: ccode,
                          phone: pnum,
                        });
                      }}
                    >
                      <FormattedMessage id="did_not_receive_code" />
                    </MuiButton>
                  )}
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
        {/* <Box>
          <MuiButton
            size="large"
            variant="contained"
            color="primary"
            fullWidth={true}
            component={RouterLink}
            to="/signup/verifycode"
            
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="next" />
          </MuiButton>
        </Box> */}
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default SignupVerifyCodePage;
