import { Box, Typography } from '@mui/material';
import HoldingBanner from 'app/design/uiComponents/HoldingBanner';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import HoldingBanner1 from '../../../../../assets/images/banner/HoldingBanner1.png';
import HoldingBanner3 from '../../../../../assets/images/banner/HoldingBanner3.png';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const CommonOnboarding = ({ type }: { type: string }) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();

  const isTraders = useIsFeatureFlag([FEATURES.IS_TRADERS]);
  const isDiscover = useIsFeatureFlag([FEATURES.IS_DISCOVER]);
  const isOptionBotBuilder = useIsFeatureFlag([FEATURES.IS_OPTION_BOTBUILDER]);
  const isEquityBotBuilder = useIsFeatureFlag([FEATURES.IS_EQUITY_BOTBUILDER]);

  return (
    <Box sx={{ mx: { xs: 3, md: 'auto' }, mt: 3, maxWidth: '640px' }}>
      <Typography
        variant="h6"
        color="text.priamry"
        sx={{ textAlign: { xs: 'left', md: 'center' } }}
      >
        <FormattedMessage id="portfolio.boarding.welcome" />
      </Typography>
      <Typography
        variant="body1"
        sx={{ mt: 1, textAlign: { xs: 'left', md: 'center' } }}
        color="text.primary"
      >
        {t('portfolio.boarding.welcome_desc', {
          name:
            isTraders && isDiscover
              ? 'bot or trader'
              : isTraders
              ? 'trader'
              : 'bot',
        })}
      </Typography>
      {isEquityBotBuilder && isOptionBotBuilder && (
        <>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ mt: 4, textAlign: { xs: 'left', md: 'center' } }}
          >
            <FormattedMessage id="portfolio.boarding.lets_start" />
          </Typography>
          <FeatureFlag
            features={[FEATURES.IS_TRADERS]}
            fallback={
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ mt: 1, textAlign: { xs: 'left', md: 'center' } }}
              >
                <FormattedMessage id="common.select_option" />
              </Typography>
            }
          >
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ mt: 1, textAlign: { xs: 'left', md: 'center' } }}
            >
              <FormattedMessage id="portfolio.boarding.head_over_to_traders" />
            </Typography>
          </FeatureFlag>
        </>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ mt: 4, mx: 'auto', maxWidth: { md: '20rem' } }}
      >
        <FeatureFlag features={[FEATURES.IS_TRADERS]} fallback={<></>}>
          <HoldingBanner
            position="end"
            text={
              <>
                <FormattedMessage id="portfolio.boarding.subscribe" />
                <br />
                <FormattedMessage id="portfolio.boarding.from_traders" />
              </>
            }
            image={HoldingBanner1}
            link="/traders"
            bgColor="grey.200"
            borderRadius={1}
          />
        </FeatureFlag>
        <FeatureFlag features={[FEATURES.IS_DISCOVER]} fallback={<></>}>
          <HoldingBanner
            position="end"
            text={
              <>
                <FormattedMessage id="portfolio.boarding.subscribe" />
                <br />
                <FormattedMessage id="portfolio.boarding.from_market" />
              </>
            }
            image={HoldingBanner1}
            link="/discover/bots"
            bgColor="grey.200"
            borderRadius={1}
          />
        </FeatureFlag>
        <Box className="build-strategy">
          <FeatureFlag
            features={[FEATURES.IS_EQUITY_BOTBUILDER, FEATURES.IS_BACKTESTING]}
            fallback={<></>}
          >
            <HoldingBanner
              position="end"
              text={
                <>
                  <FormattedMessage id="portfolio.boarding.create" />
                  <br />
                  <FormattedMessage id="portfolio.boarding.own_equity_bot" />
                </>
              }
              image={HoldingBanner3}
              // link="/bot-builder"
              bgColor="grey.200"
              borderRadius={1}
              handleClick={() => {
                breadcrumb.push(
                  '/bot-builder?type=equity&source=Portfolio',
                  PAGES.PORTFOLIO,
                  `/portfolio?ptype=${type.toLowerCase()}`,
                  t('portfolio.page_title.portfolio'),
                );
              }}
            />
          </FeatureFlag>
          <FeatureFlag
            features={[FEATURES.IS_OPTION_BOTBUILDER, FEATURES.IS_BACKTESTING]}
            fallback={<></>}
          >
            <HoldingBanner
              position="end"
              text={
                <>
                  <FormattedMessage id="portfolio.boarding.create" />
                  <br />
                  <FormattedMessage id="portfolio.boarding.own_option_bot" />
                </>
              }
              image={HoldingBanner3}
              // link="/bot-builder"
              bgColor="grey.200"
              borderRadius={1}
              handleClick={() => {
                breadcrumb.push(
                  '/bot-builder?type=option&source=Portfolio',
                  PAGES.PORTFOLIO,
                  `/portfolio?ptype=${type.toLowerCase()}`,
                  t('portfolio.page_title.portfolio'),
                );
              }}
            />
          </FeatureFlag>
        </Box>
      </Box>
    </Box>
  );
};

export default CommonOnboarding;
