import React from 'react';
import {
  AppMessage,
  AppStateMessage,
  APP_MESSAGE_TYPE,
} from 'types/AppMessages';

type ContextType = {
  message: AppMessage | undefined;
};

const initialValue = {
  message: undefined,
};

const AppMessageContext = React.createContext<ContextType>(initialValue);

export const AppMessageProvider = ({ children }) => {
  const [message, setMessage] = React.useState<AppMessage>();

  const onMessage = React.useCallback((message: MessageEvent) => {
    if (typeof message.data === 'string') {
      const dataString = message.data;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: React.SetStateAction<AppStateMessage | undefined> =
        JSON.parse(dataString);
      setMessage(data);
    } else if (message.data === 'active') {
      setMessage({
        type: APP_MESSAGE_TYPE.APP_STATE,
        data: {
          state: 'active',
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: React.SetStateAction<AppStateMessage | undefined> =
        message.data;
      setMessage(data);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  return (
    <AppMessageContext.Provider value={{ message }}>
      {children}
    </AppMessageContext.Provider>
  );
};

export function useAppMessage() {
  const context = React.useContext(AppMessageContext);
  return context.message;
}
