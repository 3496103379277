import { InfoOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import {
  // FieldDataWithValidate,
  FieldDataConditions,
} from 'app/components/Builder/Strategy/fields/types';
import { Condition } from 'app/components/Builder/Strategy/types';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { PositionDirectionCondition } from '../StrategyBuilder/StrategyBuilderEntryExitTab';
import { IntervalsData } from '../StrategyBuilder/util';
import { callingBuilderType } from 'app/components/Builder/Strategy/fields/fieldsData';

type CapitalChangeModalProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
  saveData: (values: FieldDataConditions) => void;
  genericMessage: string;
  legIndex?: number;
  error?: string;
  title: string;
  optionData: FieldDataConditions;
};
const IndicatorConditonsModal = ({
  open,
  title,
  handleClose,
  genericMessage,
  saveData,
  optionData,

  error,
}: CapitalChangeModalProps) => {
  const [copiedStrategy, setCopiedStrategy] = useState<Condition>();
  const formikValue = {
    isConditionValid: true,
    entry_conditions: [],
    exit_conditions: [],
    resolution: '00:10:00',
  } as FieldDataConditions;
  const isMdUp = useGetMediaQueryUp('md');

  useEffect(() => {
    callingBuilderType('option');
  }, []);
  const formik = useFormik({
    initialValues: {
      ...(optionData ? optionData : formikValue),
    },

    onSubmit: values => {
      //  saveData(values.indicator_extras)
      handleClose(true);
      // eslint-disable-next-line no-console
      // console.log('vals', values);
      saveData({
        ...values,
        resolution: values.resolution ? values.resolution : '00:10:00',
      });
    },
  });
  const handleDiscard = () => {
    saveData({
      isConditionValid: false,
      entry_conditions: [],
      exit_conditions: [],
      resolution: '00:10:00',
    } as FieldDataConditions);
    void formik.setFieldValue('entry_conditions', []);

    void formik.setFieldValue('exit_conditions', []);
    // {
    //   isConditionValid: false,
    //   entry_conditions: [],
    //   exit_conditions: [],
    //   resolution: '00:10:00',
    // } as FieldDataConditions);
  };

  return (
    <MuiModal
      open={open}
      maxWidth="lg"
      isFullScreen={!isMdUp}
      handleClose={() => handleClose(true)}
      body={
        <Box sx={{ width: '100%', flex: 1 }}>
          <Box className="m-main-spacing">
            <Box>
              <Typography variant="body1" my={2}>
                {genericMessage}
              </Typography>
              <Alert severity="info" icon={<InfoOutlined fontSize="small" />}>
                {title}
              </Alert>
              {genericMessage === 'Set Conditions' && (
                <FormControl
                  fullWidth
                  sx={{ mt: 3 }}
                  // error={Boolean(touched.interval && errors.interval)}
                >
                  <InputLabel id="label-interval">Resolution</InputLabel>
                  <Select
                    labelId="label-interval"
                    name="Resolution"
                    value={
                      formik.values.resolution ||
                      optionData.resolution ||
                      '00:10:00'
                    }
                    label={'Resolution'}
                    onChange={e => {
                      void formik.setFieldValue('resolution', e.target.value);
                    }}
                  >
                    {IntervalsData.map(interval => (
                      <MenuItem value={interval.value} key={interval.value}>
                        {interval.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {touched.interval && errors.interval && (
                  <FormHelperText error>{errors.interval}</FormHelperText>
                )} */}
                </FormControl>
              )}
            </Box>
          </Box>
          <PositionDirectionCondition
            title={'Entry Condition'}
            conditionsData={formik.values.entry_conditions}
            isConditionValid={formik.values.isConditionValid}
            setConditionsDataWithValid={values => {
              // callBackForFormik(values);

              void formik.setFieldValue('entry_conditions', values.conditions);
            }}
            error={error}
            updateCopiedStrategy={setCopiedStrategy}
            copiedStrategy={copiedStrategy}
            defaultResolution={formik.values.resolution || '00:10:00'}
          />
          <PositionDirectionCondition
            title={'Exit Condition'}
            conditionsData={formik.values.exit_conditions}
            isConditionValid={formik.values.isConditionValid}
            setConditionsDataWithValid={values => {
              // callBackForFormik(values);

              void formik.setFieldValue('exit_conditions', values.conditions);
            }}
            error={error}
            updateCopiedStrategy={setCopiedStrategy}
            copiedStrategy={copiedStrategy}
            defaultResolution={formik.values.resolution || '00:10:00'}
          />
        </Box>
      }
      footer={
        <Box
          sx={{
            mb: 2,
            textAlign: 'center',
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <MuiButton
            variant="contained"
            color="primary"
            fullWidth={!isMdUp}
            onClick={formik.handleSubmit}
            sx={{ mb: { xs: 2, md: 0 } }}
          >
            Save
          </MuiButton>
          <MuiButton
            onClick={handleDiscard}
            variant="contained"
            color="secondary"
            fullWidth={!isMdUp}
            sx={{
              mr: { xs: 0, md: 2 },
            }}
          >
            Remove
          </MuiButton>
          <MuiButton
            onClick={handleClose}
            variant="contained"
            color="secondary"
            fullWidth={!isMdUp}
            sx={{
              mr: { xs: 0, md: 2 },
            }}
          >
            Cancel
          </MuiButton>
        </Box>
      }
    />
  );
};

export default IndicatorConditonsModal;
