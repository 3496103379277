import { Pause, PlayArrow } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

const TimeLineButtonStack = ({
  date,
  setDate,
}: {
  date: string;
  setDate: (predate: string) => void;
}) => {
  const [play, setPlay] = useState(false);
  const [count, setCount] = useState(0);
  const [second, setSecond] = useState(1);
  const [minute, setMinute] = useState(1);
  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(showMenu);

  const timerRef = useRef<ReturnType<typeof setInterval>>();

  const menuMinute = [1, 5, 15, 30, 60];

  const groupedOptions = [
    {
      label: 'marketTime',
      options: menuMinute,
    },
    {
      label: 'callTime',
      options: [1, 2, 5],
    },
  ];
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setShowMenu(null);
  };

  useEffect(() => {
    if (count > 1) setDate(increaseMinute(date, minute));
    if (moment(date).hour() === 15 && moment(date).minute() === 30) {
      setPlay(!play);
      clearInterval(Number(timerRef.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const handlePlayPause = () => {
    setPlay(!play);
    if (!play) {
      timerRef.current = setInterval(
        () => setCount(count => count + 1),
        1000 * second,
      );
    } else {
      clearInterval(Number(timerRef.current));
    }
  };
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {/* <Typography>{moment(date).format('HH:mm')}</Typography> */}
      <IconButton
        onClick={() => {
          setDate(moment(date).hour(9).minute(16).format('YYYY-MM-DD HH:mm'));
        }}
      >
        <Typography>SOD</Typography>
      </IconButton>
      {menuMinute.reverse().map((item, i) => (
        <IconButton
          key={i}
          onClick={() => {
            setDate(decreaseMinute(date, item));
          }}
        >
          <Typography>-{item === 60 ? '1h' : `${item}m`}</Typography>
        </IconButton>
      ))}
      {menuMinute.reverse().map((item, i) => (
        <IconButton
          key={i}
          onClick={() => {
            setDate(increaseMinute(date, item));
          }}
        >
          <Typography>+{item === 60 ? '1h' : `${item}m`}</Typography>
        </IconButton>
      ))}
      <IconButton
        onClick={() => {
          setDate(moment(date).hour(15).minute(30).format('YYYY-MM-DD HH:mm'));
        }}
      >
        <Typography>EOD</Typography>
      </IconButton>
      <MuiButton
        variant="outlined"
        size="small"
        onClick={handlePlayPause}
        startIcon={play ? <Pause sx={{ fontSize: '5px' }} /> : <PlayArrow />}
        // sx={{ mx: 1 }}
      >
        <Typography variant="body2">Play</Typography>
      </MuiButton>

      <MuiButton
        size="small"
        variant="outlined"
        onClick={handleMenuClick}
        disabled={play}
      >
        <Typography variant="body2">
          {minute === 60 ? '1h' : `${minute}min`}/{second}sec
        </Typography>
      </MuiButton>
      <Paper sx={{ width: 'auto' }}>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={showMenu}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <ListSubheader>Move</ListSubheader>
          {groupedOptions[0].options.map(option => {
            return (
              <MenuItem
                onClick={() => {
                  setMinute(Number(option));
                }}
                key={option}
              >
                <Radio
                  sx={{ height: '15px', width: '15px', mr: 1 }}
                  checked={option === minute}
                />
                <ListItemText>
                  {option === 60 ? '1 h' : `${option} min`}
                </ListItemText>
              </MenuItem>
            );
          })}
          <ListSubheader>Every</ListSubheader>
          {groupedOptions[1].options.map(option => {
            return (
              <MenuItem
                onClick={() => {
                  setSecond(Number(option));
                  handleMenuClose();
                }}
                key={option}
              >
                <Radio
                  sx={{ height: '15px', width: '15px', mr: 1 }}
                  checked={option === second}
                />
                <ListItemText>{option} sec</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </Paper>
    </Box>
  );
};

export default TimeLineButtonStack;

function decreaseMinute(date: string, minute: number): string {
  const updatedDate = moment(date)
    .minute(
      moment(date).hour() > 9 ||
        (moment(date).hour() === 9 && moment(date).minute() - minute >= 16)
        ? moment(date).minute() - minute
        : 16,
    )
    .format('YYYY-MM-DD HH:mm');
  return updatedDate;
}

function increaseMinute(date: string, minute: number): string {
  const updatedDate = moment(date)
    .minute(
      moment(date).hour() < 15 ||
        (moment(date).hour() === 15 && moment(date).minute() + minute <= 30)
        ? moment(date).minute() + minute
        : 30,
    )
    .format('YYYY-MM-DD HH:mm');

  return updatedDate;
}
