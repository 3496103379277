import { Box, Divider, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { BotTradeStatsData } from 'types/ApiServicesTypes';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';

const InfoListTradeStats = ({
  data,
  currencySymbol,
}: {
  data: BotTradeStatsData;
  currencySymbol: string;
}) => {
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box>
        <Typography
          variant="body2"
          color="text.disabled"
          fontWeight={400}
          sx={{ pb: 2 }}
        >
          <FormattedMessage id="summary_labels.trades" />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.total" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {Number(data.numberofwinningtrades) +
            Number(data.numberoflosingtrades)}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Typography
        variant="body2"
        color="text.disabled"
        fontWeight={400}
        sx={{ pb: 2 }}
      >
        <FormattedMessage id="summary_labels.gross_profit_loss" />
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.total" />
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          color={getColorByNum(
            data.totalprofit_loss ? data.totalprofit_loss : 0,
          )}
        >
          {/* {data.totalprofit_loss
            ? `${
                data.totalprofit_loss > 0
                  ? '₹' + formatNumber(data.totalprofit_loss)
                  : '-₹' + formatNumber(data.totalprofit_loss * -1)
              }`
            : '-'} */}
          {currencySymbol}
          {data.totalprofit_loss ? formatNumber(data.totalprofit_loss) : '-'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.avg.title" />
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          color={getColorByNum(
            data.averageprofitloss ? data.averageprofitloss : 0,
          )}
        >
          {/* {data.averageprofitloss
            ? `${
                data.averageprofitloss > 0
                  ? '₹' + formatNumber(data.averageprofitloss)
                  : '-₹' + formatNumber(data.averageprofitloss * -1)
              }`
            : '-'} */}
          {currencySymbol}
          {data.averageprofitloss ? formatNumber(data.averageprofitloss) : '-'}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box>
        <Typography
          variant="body2"
          color="text.disabled"
          fontWeight={400}
          sx={{ pb: 2 }}
        >
          <FormattedMessage id="summary_labels.trade_duration" />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.avg.title" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.averagetradeduration ? data.averagetradeduration : '-'}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoListTradeStats;
