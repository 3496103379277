import { useQuery } from 'react-query';
import { getProfileFeatures } from 'services/apiServices';
import { Errors, GetProfileFeaturesResult } from 'types/ApiServicesTypes';
import { isUserLoggedIn } from 'utils/auth';

// Stale time is 8 hours.
const STATE_TIME = 8 * 60 * 60 * 1000;

export default function useGetProfileFeatures() {
  const isLoggedIn = isUserLoggedIn();
  const objQuery = useQuery<GetProfileFeaturesResult, Errors>(
    ['getProfileFeatures', isLoggedIn],
    () => getProfileFeatures(),
    {
      staleTime: STATE_TIME,
    },
  );
  return objQuery;
}
