import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import {} from '@mui/material/colors';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import {
  calculateProfitLossAndPerChange,
  getCurrentSymbolPrice,
} from 'app/design/speedBot/ComponentItem/Portfolio/utils';
import MuiChip from 'app/design/uiComponents/MuiChip';
import i18next from 'i18next';
import { BotSignal, BotSignalParams, Position } from 'types/ApiServicesTypes';
import { ComboBox } from 'types/Generic';
import { SymbolsData } from 'types/Symbols';
import {
  formatNumber,
  decimalFormat,
  getColorByNum,
  getArrowDirByNum,
} from 'utils/GenericFunctions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export const SignalProductTypes: ComboBox[] = [
  { label: 'MIS', value: 'MIS' },
  { label: 'CNC', value: 'CNC' },
  { label: 'NRML', value: 'NRML' },
];

export const SignalOrderTypes: ComboBox[] = [
  { label: 'MARKET', value: 'MARKET' },
  { label: 'LIMIT', value: 'LIMIT' },
  { label: 'STOPLOSS', value: 'STOP_LOSS' },
  { label: 'SL-M', value: 'STOP_LOSS_MARKET' },
  // { label: 'SL-M', value: 'STOP_LOSS_MARKET' },
];

export const SignalTransactionTypes: ComboBox[] = [
  { label: 'BUY', value: 'BUY' },
  { label: 'SELL', value: 'SELL' },
];
export const CallPutTypes: ComboBox[] = [
  { label: 'PE', value: 'PE' },
  { label: 'CE', value: 'CE' },
];

export const SignalPositionTypes: ComboBox[] = [
  { label: 'ENTRY', value: 'EN' },
  { label: 'MODIFY', value: 'MODIFY' },
  { label: 'EXIT', value: 'EX' },
];

export const SignalPositionSizingTypes: ComboBox[] = [
  { label: 'Quantity', value: 'quantity' },
  { label: 'Available Balance (%)', value: 'available_balance' },
  { label: 'Deployed Capital (%)', value: 'deployed_capital' },
  { label: 'Absolute Capital Based', value: 'absolute_capital' },
];
export const OptionsTypes: ComboBox[] = [
  { label: 'Equity', value: 'Equity' },
  { label: 'Option', value: 'Option' },
  { label: 'Future', value: 'Future' },
];

export const positionSizingLabels = SignalPositionSizingTypes.reduce(
  (acc, crr) => {
    acc[crr.value] = crr.label;
    return acc;
  },
  {} as Record<string, string>,
);

// const isLocationPathnameSignals = () => {
//   return window.location.pathname.includes('/signals');
// };
const isLocationPathnamePortfolio = () => {
  return window.location.pathname.startsWith('/portfolio');
};

export const createBotSignalOpenPositionTableRows = (
  positions: Position[],
  liveFeedData: SymbolsData | undefined,
  handlePositionClick: (position: Position) => void,
  handleClickSelectAll: () => void,
  handleClickSelect?: (position: Position) => void,
  selectedItems?: number[] | null,
  isDebugMode?: boolean,
): { rows: TableRow<unknown>[]; columns: TableColumns[] } => {
  // const isSignal = isLocationPathnameSignals();
  const isPortfolio = isLocationPathnamePortfolio();
  const rows: TableRow<unknown>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    {
      text: (
        <Checkbox
          checked={
            selectedItems ? selectedItems.length === positions.length : false
          }
          onClick={() => handleClickSelectAll()}
          sx={{ p: 0 }}
        />
      ),
      align: 'center',
    },
    { text: i18next.t('summary_labels.symbol') },
    { text: i18next.t('summary_labels.type'), align: 'center' },
    {
      text: i18next.t('bot_signals.form.signal_id'),
      align: 'center',
      hide: !isDebugMode,
    },
    {
      text: i18next.t('summary_labels.trade_id'),
      align: 'center',
      hide: isPortfolio,
    },
    {
      text: i18next.t('summary_labels.tag'),
      align: 'center',
      hide: isPortfolio,
    },
    { text: i18next.t('summary_labels.qty'), align: 'center' },
    { text: i18next.t('summary_labels.entry_price'), align: 'right' },
    { text: i18next.t('summary_labels.last_price'), align: 'right' },
    { text: i18next.t('summary_detail.todays_change'), align: 'right' },
    // {
    //   text: i18next.t('summary_labels.market_value'),
    //   align: 'right',
    //   hide: isSignal,
    // },
    // { text: i18next.t('summary_labels.market_value'), align: 'right' },
  ];

  positions.map(position => {
    rowCellsData = [];
    const currentPrice = getCurrentSymbolPrice(
      position.exit_price,
      position.entry_Price,
      liveFeedData,
      position.trading_symbol,
    );
    const isBuy = position.direction === '0';
    const { profitLoss, percentChange } = calculateProfitLossAndPerChange(
      currentPrice,
      position,
      true,
    );

    rowCellsData = [
      {
        content: (
          <Checkbox
            checked={
              selectedItems ? selectedItems.includes(position.id) : false
            }
            onChange={() => handleClickSelect?.(position)}
            onClick={e => e.stopPropagation()}
            sx={{ p: 0 }}
          />
        ),
        align: 'center',
      },
      {
        content: position.trading_symbol,
        fontWeight: 500,
      },
      {
        content: (
          <MuiChip
            label={isBuy ? 'L' : 'S'}
            color={isBuy ? 'infolight' : 'purplelight'}
            size="singleLetter"
            variant="filled"
            sx={{ ml: 1 }}
          />
        ),
        align: 'center',
      },
      { content: position.signal_id, hide: !isDebugMode },
      {
        content: position.trade_id,
        hide: isPortfolio,
      },
      {
        content: position.tag,
        hide: isPortfolio,
      },
      {
        content: position.quantity,
        color: isBuy ? 'info.main' : 'purple?.[500]',
        align: 'center',
      },
      {
        content: position.entry_Price
          ? `${position.currency_symbol}${formatNumber(position.entry_Price)}`
          : '',
        align: 'right',
      },
      {
        content: `${position.currency_symbol}${formatNumber(currentPrice)}`,
        align: 'right',
      },
      // {
      //   content: `${percentChange > 0 ? '+' : ''}${decimalFormat(
      //     percentChange,
      //   )}%`,
      //   textVariant: 'body3',
      //   align: 'right',
      //   color: getColorByNum(profitLoss),
      // },
      {
        content: (
          <>
            <Box>
              <Box>
                <Typography
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="body2"
                  fontWeight={500}
                >
                  {getArrowDirByNum(profitLoss)} {position.currency_symbol}
                  {formatNumber(profitLoss, true)}
                </Typography>
              </Box>
              <Typography variant="body3">
                ({percentChange > 0 ? '+' : ''}
                {decimalFormat(percentChange)}%)
              </Typography>
            </Box>
          </>
        ),
        // textVariant: 'body3',
        align: 'right',
        color: getColorByNum(profitLoss),
      },
      // {
      //   content: `${getArrowDirByNum(profitLoss)} ${
      //     position.currency_symbol
      //   }${formatNumber(profitLoss, true)}`,
      //   align: 'right',
      //   color: getColorByNum(profitLoss),
      //   fontWeight: 500,
      //   hide: isSignal,
      // },
      // {
      //   content: (
      //     <span style={{ whiteSpace: 'nowrap' }}>
      //       {getArrowDirByNum(profitLoss)} ₹{formatNumber(profitLoss, true)}
      //     </span>
      //   ),
      //   align: 'right',
      //   color: getColorByNum(profitLoss),
      //   fontWeight: 500,
      // },
    ];
    rows.push({
      rowCellsData: rowCellsData,
      rowData: [],
      handleClick: () => {
        handlePositionClick(position);
      },
    });
  });

  return { rows, columns };
};

export const createBotSignalClosePositionTableRows = (
  positions: Position[],
  handlePositionClick: (position: Position) => void,
  isDebugMode?: boolean,
): { rows: TableRow<Position>[]; columns: TableColumns[] } => {
  const rows: TableRow<Position>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  // const theme = useTheme()
  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.symbol') },
    { text: i18next.t('summary_labels.type'), align: 'center' },
    {
      text: i18next.t('bot_signals.form.signal_id'),
      align: 'center',
      hide: !isDebugMode,
    },
    { text: i18next.t('summary_labels.trade_id'), align: 'center' },
    { text: i18next.t('summary_labels.tag'), align: 'center' },
    { text: i18next.t('summary_labels.qty'), align: 'center' },
    { text: i18next.t('summary_labels.entry_price'), align: 'right' },
    { text: i18next.t('summary_labels.exit_price'), align: 'right' },
    { text: `${i18next.t('summary_detail.p_or_l')} %`, align: 'right' },
    { text: i18next.t('summary_labels.profit_loss'), align: 'right' },
  ];

  positions.map(position => {
    rowCellsData = [];
    const isBuy = position.direction === '0';
    const { profitLoss, percentChange } = calculateProfitLossAndPerChange(
      0,
      position,
      true,
    );

    rowCellsData = [
      {
        content: position.trading_symbol,
        fontWeight: 500,
      },
      {
        content: (
          <MuiChip
            label={isBuy ? 'L' : 'S'}
            color={isBuy ? 'infolight' : 'purplelight'}
            size="singleLetter"
            variant="filled"
            sx={{ ml: 1 }}
          />
        ),
        align: 'center',
      },
      {
        content: position.signal_id,
        hide: !isDebugMode,
      },
      {
        content: position.trade_id,
      },
      {
        content: position.tag,
      },
      {
        content: position.quantity,
        color: isBuy ? 'info.main' : 'purple?.[500]',
        align: 'center',
      },
      {
        content: position.entry_Price
          ? `${position.currency_symbol}${formatNumber(position.entry_Price)}`
          : '',
        align: 'right',
      },
      {
        content: `${position.currency_symbol}${formatNumber(
          position.exit_price,
        )}`,
        align: 'right',
      },
      {
        content: `${percentChange > 0 ? '+' : ''}${decimalFormat(
          percentChange,
        )}%`,
        textVariant: 'body3',
        align: 'right',
        color: getColorByNum(profitLoss),
      },
      {
        content: (
          <span style={{ whiteSpace: 'nowrap' }}>
            {getArrowDirByNum(profitLoss)} {position.currency_symbol}
            {formatNumber(profitLoss, true)}
          </span>
        ),
        align: 'right',
        color: getColorByNum(profitLoss),
        fontWeight: 500,
      },
    ];

    rows.push({
      rowCellsData,
      rowData: position,
      handleClick: () => {
        handlePositionClick(position);
      },
    });
  });

  return { rows, columns };
};

export const createBotSignalModalTableRows = (
  signalData: BotSignalParams[],
  handleMenuClick: (
    event: React.MouseEvent<HTMLElement>,
    signal: BotSignalParams,
    index: number,
  ) => void,
): { rows: TableRow<BotSignalParams>[]; columns: TableColumns[] } => {
  const rows: TableRow<BotSignalParams>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('bot_signals.form.symbol') },
    { text: i18next.t('bot_signals.form.trade_id') },
    { text: i18next.t('bot_signals.form.quantity'), align: 'right' },
    { text: i18next.t('bot_signals.form.order_tag') },
    { text: i18next.t('bot_signals.form.product_type') },
    { text: i18next.t('bot_signals.form.transaction_type') },
    { text: i18next.t('bot_signals.form.order_type') },
    { text: i18next.t('bot_signals.form.position_type') },
    { text: '' },
  ];

  if (!signalData) return { rows, columns };

  signalData.map((signal: BotSignalParams, i: number) => {
    rowCellsData = [
      {
        content: signal.symbol,
        fontWeight: 500,
      },
      {
        content: signal.trade_id,
      },
      {
        content: signal.quantity,
        align: 'right',
      },
      { content: signal.tag },
      { content: signal.product_type },
      { content: signal.transaction_type },
      { content: signal.order_type },
      { content: signal.position_type },
      {
        content: (
          <IconButton onClick={e => handleMenuClick(e, signal, i)}>
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ];

    rows.push({ rowCellsData, rowData: signal });
  });

  return { rows, columns };
};

export const getValueFromLocalStorage = (key: string) => {
  let value = '';
  const localValue = localStorage.getItem(key);
  if (localValue) value = localValue;
  return value;
};

export const persistValueInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
  return value;
};

export const convertPositionToSignal = (
  position: Position,
  copySignalId = false,
) => {
  return {
    signal_id: copySignalId ? position.signal_id : '',
    trading_symbol: position.trading_symbol,
    trade_id:
      position.metadata && position.metadata?.unique_identifier
        ? position.metadata.unique_identifier
        : position && position.metadata && position.metadata.trade_id
        ? position.metadata.trade_id
        : '',
    transaction_type: position.direction == '0' ? 'BUY' : 'SELL',
    quantity: position.quantity,
    tag: position && position.tag ? position.tag : '',
    product_type: position.metadata?.order_product
      ? position.metadata.order_product.toUpperCase()
      : position.metadata?.product_type
      ? position.metadata.product_type
      : 'MIS',
    position_type:
      position.metadata && position.metadata.position_type
        ? position.metadata.position_type
        : 'EN',
    order_type: 'MARKET',
  } as BotSignal;
};

export function useGetPositionSizingFeatureFlags() {
  const isAbsoluteCapitalSignal = useIsFeatureFlag([
    FEATURES.IS_ABSOLUTE_CAPITAL_SIGNAL,
  ]);
  const isDeployedCapitalSignal = useIsFeatureFlag([
    FEATURES.IS_DEPLOYED_CAPITAL_SIGNAL,
  ]);
  const isAvailableBalanceSignal = useIsFeatureFlag([
    FEATURES.IS_AVAILABLE_BALANCE_SIGNAL,
  ]);

  return {
    isAbsoluteCapitalSignal,
    isDeployedCapitalSignal,
    isAvailableBalanceSignal,
  };
}
