import { useQuery } from 'react-query';
import { GetBotSignals } from 'services/apiServices';
import { Errors, GetBotSignalsResult } from 'types/ApiServicesTypes';

export default function useGetBotSignals(
  botId: number,
  page: number,
  maxPage = 10,
) {
  const queryKey = ['GetBotSignals', botId, page, maxPage];
  const objQuery = useQuery<GetBotSignalsResult, Errors>(queryKey, () =>
    GetBotSignals(botId, page, maxPage),
  );
  return objQuery;
}
