import { Divider, Grid, useMediaQuery } from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import BotTemplateItem from '../speedBot/EntityItem/BotTemplateItem';
import { BotTemplate } from 'types/ApiServicesTypes';

function TemplateItems({
  templates,
}: {
  templates: BotTemplate[];
  isViewFullItem?: boolean;
}) {
  const isMdUp = useGetMediaQueryUp('md');
  const isLgUp = useGetMediaQueryUp('lg');
  const is1600Up = useMediaQuery('(min-width:1600px)');
  const totalTemplates = templates.length;
  const highestCount = Math.max(
    ...templates.map(templateitem => templateitem.count),
  );
  return (
    <>
      {templates.map((template, j) => (
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          xl={is1600Up ? 4 : 6}
          key={template.id}
          className={j == 0 ? 'ready-strategy' : ''}
        >
          <BotTemplateItem template={template} highestCount={highestCount} />
          {is1600Up &&
            ((totalTemplates % 3 == 0 && totalTemplates - 3 > j) ||
              (totalTemplates % 3 == 1 && totalTemplates - 1 > j) ||
              (totalTemplates % 3 == 2 && totalTemplates - 2 > j)) && (
              <Divider sx={{ borderColor: 'grey.200', mx: isMdUp ? 3 : 0 }} />
            )}
          {isLgUp &&
            !is1600Up &&
            ((totalTemplates % 2 == 0 && totalTemplates - 2 > j) ||
              (totalTemplates % 2 == 1 && totalTemplates - 1 > j)) && (
              <Divider sx={{ borderColor: 'grey.200', mx: isMdUp ? 3 : 0 }} />
            )}

          {!isLgUp && totalTemplates - 1 !== j && (
            <Divider sx={{ borderColor: 'grey.200', mx: isMdUp ? 3 : 0 }} />
          )}
        </Grid>
      ))}
    </>
  );
}

export default TemplateItems;
