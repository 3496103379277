import { memo, useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { Typography, Box, Grid } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';

// Service
import { Position, Errors } from 'types/ApiServicesTypes';
import { GetPortfolioBotDetail } from 'services/apiServices';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import usePostBotExitAllAndStop from 'services/Bots/usePostBotExitAllAndStop';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useSocket } from '../Context/SocketContext';
import { ToastContext } from '../Context/ToastContext';
import usePortfolioExitStrategyPositions from 'services/Portfolio/usePortfolioExitStrategyPositions';

const ExitAllAndStopModal = ({
  open,
  // openFrom,
  bid,
  slave,
  sid,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  botName,
  handleClose,
  oPositions,
}: {
  slave: boolean;
  open: boolean;
  openFrom: string;
  bid: number;
  sid: number;
  botName: string;
  oPositions: number[];

  handleClose: (isSuccess, message?: string) => void;
  category: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_selectedPos, setSelectedPos] = useState<Position | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_openModal, setOpenModal] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const { showAlert } = useContext(ToastContext);

  const { data: positions, isLoading } = useQuery<Position[] | null, Errors>(
    ['GetPortfolioBotDetail', bid, sid],
    async () => {
      return await GetPortfolioBotDetail(`portfolio/${sid}`, 'GET').then(
        res => {
          if (res && res.today_positions && res.today_positions.length > 0) {
            const positionsFilterData = res.today_positions.filter(
              x => x.exit_price === 0,
            );
            return positionsFilterData;
          }
          return null;
        },
      );
    },
    { enabled: open },
  );

  const mutationExitAllAndStop = usePostBotExitAllAndStop(sid, 'marketplace');

  // if (mutation.isSuccess && open) {
  //   // handleClose(true, 'Bot is successfully stopped');

  //   // Log entry for stop subscription paper/live
  //   logFirebaseEventEntry('BOT_STOP_' + subscriptionCategory.toUpperCase(), {
  //     bot_id: bid,
  //   });

  //   handleClose(true);
  //   mutation.reset();
  // }

  // if (mutation.error && open) {
  //   mutation.reset();
  //   setOpenMessage(true);
  // }
  const { socket } = useSocket();
  const handleExitWithSocket = () => {
    // Loop through the array of position IDs

    // Emit socket event for each position ID
    socket?.emit('control', {
      bot_id: sid,
      position_id: '',
      msg_type: 12,
      msg_action: 'all_exit',
      msg_data: {},
    });
    showAlert!('Exit signals are sent successfully', 'success');
  };
  const mutatePortfolioStrategyPositions =
    usePortfolioExitStrategyPositions(sid);
  const handleExitPositions = useCallback((positionIds: number[]) => {
    mutatePortfolioStrategyPositions.mutate(
      { positionIds },
      {
        onSuccess: res => {
          if (res.success) {
            showAlert!('Exit signals are sent successfully', 'success');
          }
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExitAllclicked = () => {
    if (slave) {
      handleExitPositions(oPositions);
    } else {
      handleExitWithSocket();
    }
    handleClose(true);
  };
  const handleCloseModal = () => {
    handleClose(true);
  };

  return (
    <>
      <CircularLoader open={isLoading} zIndex={1302} />
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <>
            <Box className="m-main-spacing">
              <Typography variant="body1" sx={{ pb: 4, fontWeight: 400 }}>
                Are you sure you want to exit all Positions ?
              </Typography>
            </Box>
          </>
        }
        footer={
          <>
            <Box display={'flex'}>
              {positions && positions.length > 0 && (
                <FeatureFlag
                  features={[FEATURES.IS_EXIT_ALL_AND_STOP]}
                  fallback={<></>}
                >
                  <MuiLoadingButton
                    fullWidth
                    variant="outlined"
                    color="error"
                    sx={{ m: 1 }}
                    onClick={handleExitAllclicked}
                    loading={mutationExitAllAndStop.isLoading}
                    loadingPosition="start"
                  >
                    {/* <FormattedMessage id="buttons.exit_all_and_stop" />
                     */}
                    Exit All
                  </MuiLoadingButton>
                </FeatureFlag>
              )}

              <MuiButton
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ m: 1 }}
                onClick={handleClose}
              >
                <FormattedMessage id="buttons.cancel" />
              </MuiButton>
            </Box>
          </>
        }
      />
      <SnakbarMessageModal
        open={openMessage}
        handleClose={() => setOpenMessage(false)}
        body={<FormattedMessage id="snackbar_msg_modal.paper_testing_failed" />}
        footer={
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenMessage(false)}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default memo(ExitAllAndStopModal);
