import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { GetAccountDelete } from 'services/apiServices';
import { GetAccountDeleteResult } from 'types/ApiServicesTypes';

export const useGetAccountDelete = (successCallback: () => void) => {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<GetAccountDeleteResult, Error>(
    'GetAccountDelete',
    () =>
      GetAccountDelete()
        .then(res => {
          if (!res || res.error)
            showAlert!(
              res.error
                ? res.error
                : 'Something went wrong. Your Account not Deleted. Please try again.',
            );
          else if (res.success) {
            successCallback();
            showAlert!(
              res.success
                ? 'Account Deleted Succefully'
                : 'Account not Deleted',
              'success',
            );
          }
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
};
