import { useAppMessage } from 'app/components/Context/AppMessage';
import { useQueryClient } from 'react-query';
import { APP_MESSAGE_TYPE } from 'types/AppMessages';

const queryKeys = [
  ['GetPortfolio'],
  ['GetPortfolioBotDetail'],
  ['GetBotPositions'],
  ['GetBotOrders'],
  ['GetNotifications'],
  ['GetBacktestQueue'],
];

export const QueryCacheInvalidationOnAppState = () => {
  const queryClient = useQueryClient();

  const message = useAppMessage();
  if (
    message?.type === APP_MESSAGE_TYPE.APP_STATE &&
    message.data.state === 'active'
  ) {
    queryKeys.forEach(key => {
      void queryClient.invalidateQueries(key, {
        refetchActive: true,
        refetchInactive: false,
      });
    });
  }

  return null;
};
