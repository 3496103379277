import { memo } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  YAxisProps,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import {
  GRAPH_COLOR,
  STROKE_COLOR,
} from 'app/design/uiComponents/Charts/constants';

import {
  calculateYAxisDomain,
  convertGraphDataToPoints,
  CustomTooltip,
  GraphData,
} from './utils';

import {
  calculateXAxisTicks,
  calculateAmountUnit,
  getGroupBy,
  renderXAxisTick,
  xAxisTickFormatter,
  yAxisTickFormatter,
} from '../ChartUtils';

import './styles.scss';

const minDataPointsRequired = 5;
interface BotHistoryGraphProps {
  data: GraphData;
  xAxis: boolean;
  yAxis: boolean;
  tooltip: boolean;
  grid: boolean;
  useYAxisDomain: boolean;
  minHeight?: string;
}

const BotHistoryGraph = (props: BotHistoryGraphProps) => {
  const {
    data: graphData,
    grid,
    xAxis,
    yAxis,
    useYAxisDomain,
    tooltip,
    minHeight,
  } = props;

  if (!graphData || graphData.length < minDataPointsRequired) {
    //TODO Render not enough data to render graph image.
    return null;
  }

  const dateStrings = graphData.map(item => item.date);
  const amounts = graphData.map(item => item.capital);
  const groupBy = getGroupBy(dateStrings);
  const amountUnit = calculateAmountUnit(amounts);
  const points = convertGraphDataToPoints(graphData, amountUnit);
  const pointDates = points.map(p => p.date);
  const xAxisTicks = xAxis ? calculateXAxisTicks(pointDates, groupBy) : [];
  const yAxisDomain = useYAxisDomain
    ? calculateYAxisDomain(points, amountUnit)
    : ([0, 'auto'] as YAxisProps['domain']);
  const showDynamicColors =
    graphData[0].capital < graphData[graphData.length - 1].capital
      ? GRAPH_COLOR
      : '#fe1a1a81';
  const showDynamicStrokeColors =
    graphData[0].capital < graphData[graphData.length - 1].capital
      ? STROKE_COLOR
      : '#b30000bb';
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      {...(minHeight && { minHeight: minHeight })}
    >
      <AreaChart data={points}>
        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="25%"
              stopColor={showDynamicColors}
              stopOpacity={99.77}
            />
            <stop
              offset="100%"
              stopColor={showDynamicColors}
              stopOpacity={-30.03}
            />
          </linearGradient>
        </defs>
        {xAxis && (
          <XAxis
            padding={{ left: 0 }}
            scale={'time'}
            type={'number'}
            domain={['dataMin', 'dataMax']}
            dataKey="x"
            ticks={xAxisTicks}
            tickSize={20}
            interval={'preserveStartEnd'}
            tickFormatter={xAxisTickFormatter(groupBy)}
            tick={renderXAxisTick(groupBy)(xAxisTicks)}
            minTickGap={10}
          />
        )}
        {yAxis && (
          <YAxis
            tickSize={0}
            tickMargin={5}
            tickFormatter={yAxisTickFormatter(amountUnit)}
            domain={yAxisDomain}
            width={30}
            orientation={'right'}
          />
        )}

        {grid && <CartesianGrid />}
        {tooltip && (
          <Tooltip content={<CustomTooltip amountUnit={amountUnit} />} />
        )}
        <Area
          type="monotone"
          dataKey="y"
          stroke={showDynamicStrokeColors}
          fillOpacity={1}
          fill="url(#gradientFill)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

BotHistoryGraph.defaultProps = {
  xAxis: true,
  yAxis: true,
  tooltip: true,
  grid: true,
  useYAxisDomain: true,
};

export default memo(BotHistoryGraph);
