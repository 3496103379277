import { useQuery } from 'react-query';
import { GetBotTodaysPositions } from 'services/apiServices';
import { getUniqueSymbolsForActive } from 'services/Portfolio/useGetPortfolioBotDetail';
import {
  Errors,
  GetBotTodaysPositionsQueryResult,
  GetBotTodaysPositionsResult,
  Position,
} from 'types/ApiServicesTypes';

// process response data
export function processResponseData(res: GetBotTodaysPositionsResult) {
  let arrOpenPositions: Array<Position> = [],
    arrClosePositions: Array<Position> = [];
  let symbols: Array<string> = [];
  if (res && res.today_positions) {
    arrOpenPositions = res.today_positions.filter(x =>
      Boolean(x.exit_price === 0),
    );
    arrClosePositions = res.today_positions.filter(x =>
      Boolean(x.exit_price > 0),
    );
    symbols = getUniqueSymbolsForActive(res.today_positions);
  }
  return {
    data: res,
    openPositions: arrOpenPositions,
    closePositions: arrClosePositions,
    symbols,
  };
}

export default function useGetBotTodaysPositions(botId: number) {
  const queryKey = ['GetBotTodaysPositions', botId];
  const objQuery = useQuery<GetBotTodaysPositionsQueryResult, Errors>(
    queryKey,
    async () => {
      return await GetBotTodaysPositions(botId).then(res => {
        return processResponseData(res);
      });
    },
  );
  return objQuery;
}
