import moment from 'moment';
import { RestRequest } from 'services/apiServices';
import {
  // GetMarketplaceResult,
  // PaginationApiParams,
  GetReportResult,
  ReportSearchParams,
} from 'types/ApiServicesTypes';
import { Metadata } from './useGetReportMetaData';

export async function GetMarketplace(
  params: ReportSearchParams,
): Promise<GetReportResult['data']> {
  const selectedItems =
    params.exchanges && Metadata
      ? Metadata?.value.exchanges.filter(item =>
          (params.exchanges ? params.exchanges : []).includes(item.label),
        )
      : [];
  const newExchangesParams = selectedItems.map(item => item.value);
  const selectStrategy =
    params.strategy && Metadata
      ? Metadata?.value.strategies.filter(item =>
          (params.strategy ? params.strategy : []).includes(item.label),
        )
      : [];
  const newStrategyParams = selectStrategy.map(item => item.value);
  const selectBroker =
    params.brokers && Metadata
      ? Metadata?.value.broker_accounts.filter(item =>
          (params.brokers ? params.brokers : []).includes(item.label),
        )
      : [];
  const newBrokerParams = selectBroker.map(item => item.value);
  const threeYearsAgo = moment().subtract(3, 'month').format('YYYY-MM-DD');

  let path = `bots/history/report?with_fees=1&start_date=${
    params.startDate || threeYearsAgo
  }&end_date=${params.endDate || moment().format('YYYY-MM-DD')}`;
  if (params.search) path += `&search=${params.search}`;

  // if (params.startDate)
  //   path += `start_date=${params.startDate || '05/05/2023'}`;
  // if (params.endDate) path += `&end_date=${params.endDate || '10/10/2023'}`;
  if (params.deployment?.length)
    path += `&deployment_type=${params.deployment}`;
  if (params.exchanges?.length) {
    // eslint-disable-next-line no-console
    path += `&exchanges=${newExchangesParams}`;
  }
  if (params.strategy?.length) path += `&strategies=${newStrategyParams}`;
  if (params.brokers?.length) path += `&broker_accounts=${newBrokerParams}`;

  const response = await RestRequest<undefined, GetReportResult>(
    path,
    undefined,
    'GET',
    'text/html',
  );

  if (response.result?.success) {
    return response.result.data;
  } else if (response.result?.error) {
    throw new Error(response.result.error);
  } else {
    throw new Error('Unknown error. Please try again.');
  }
}
