import { AllBotOpenPositions } from 'services/Portfolio/useGetPortfolioActivePaper';
import { GetNotificationsResult, Position } from 'types/ApiServicesTypes';
import { SymbolsData } from 'types/Symbols';
import { decimalFormat } from 'utils/GenericFunctions';
import { CategoryNotification } from './NotificationContentBody';

export const calculateLiveFeedPostitionSum = (
  liveFeedData: SymbolsData,
  posData: AllBotOpenPositions | Position,
) => {
  const posDataAll = posData as AllBotOpenPositions;
  let sum = posDataAll.todays_change ? Number(posDataAll.todays_change) : 0;

  if (
    posDataAll.openPositions &&
    posDataAll.openPositions.length > 0 &&
    liveFeedData
  ) {
    posDataAll.openPositions.map(position => {
      const isBuy = position.direction === '0';
      const currentPrice =
        liveFeedData && liveFeedData[position.trading_symbol]
          ? Number(liveFeedData[position.trading_symbol].Close)
          : 0;
      let profitLoss =
        currentPrice === 0
          ? position.profit_loss
          : isBuy
          ? (currentPrice - position.entry_Price) * Math.abs(position.quantity)
          : (position.entry_Price - currentPrice) * Math.abs(position.quantity);
      profitLoss = Number(decimalFormat(profitLoss));
      sum += profitLoss;
    });
  }
  return sum;
};

export const transformCategoryNotifications = (
  notificationsData: GetNotificationsResult | undefined,
  unreadCount: number,
) => {
  if (notificationsData) {
    const categoryNotifications: CategoryNotification = {
      All: { notifications: notificationsData },
      Live: {
        notifications: notificationsData.filter(
          x => x.type === '0' && x.category && x.category === 'Live',
        ),
      },
      Paper: {
        notifications: notificationsData.filter(
          x => x.type === '0' && x.category && x.category === 'Paper',
        ),
      },
      Other: { notifications: notificationsData.filter(x => x.type !== '0') },
    };

    if (
      categoryNotifications.All.notifications &&
      categoryNotifications.All.notifications.length
    )
      categoryNotifications.All.isUnread = unreadCount > 0;
    if (
      categoryNotifications.Paper.notifications &&
      categoryNotifications.Paper.notifications.length
    )
      categoryNotifications.Paper.isUnread =
        categoryNotifications.Paper.notifications.findIndex(x => !x.read) !==
        -1;
    if (
      categoryNotifications.Live.notifications &&
      categoryNotifications.Live.notifications.length
    )
      categoryNotifications.Live.isUnread =
        categoryNotifications.Live.notifications.findIndex(x => !x.read) !== -1;
    if (
      categoryNotifications.Other.notifications &&
      categoryNotifications.Other.notifications.length
    )
      categoryNotifications.Other.isUnread =
        categoryNotifications.Other.notifications.findIndex(x => !x.read) !==
        -1;

    return categoryNotifications;
  }

  return undefined;
};
