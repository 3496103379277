import { Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BorderedIconButton from 'app/design/uiComponents/BorderedIconButton';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { openInNewTab } from 'utils/GenericFunctions';
import { Telegram } from '@mui/icons-material';
import usePostPortfolioShare from 'services/Portfolio/usePostPortfolioShare';
import moment from 'moment';

const PortfolioShareIcons = ({
  style,
  variant,
  title,
  whichIconYouWant,
}: {
  style?: React.CSSProperties;
  variant: 'small' | 'medium' | 'large';
  title: string;
  whichIconYouWant: string[];
}) => {
  const { showAlert } = useContext(ToastContext);
  const isLgUp = useGetMediaQueryUp('md');
  const portfolioShareMutation = usePostPortfolioShare();

  const handleShare = (socialmedia: string) => {
    portfolioShareMutation.mutate(
      { action: 'share' },
      {
        onSuccess: res => {
          const sharedDate = moment(res.date).format('YYYY-MM-DD');
          const shareURL = `${location.origin}/portfoliopnl/share/${res.user_id}?date=${sharedDate}`;
          if (socialmedia === 'COPY') {
            if (window.clipboardData)
              void window.clipboardData.setData('Text', shareURL);
            else void navigator.clipboard.writeText(shareURL);
            showAlert!('Link Copied!', 'success');
            return;
          }

          let shareLink = '';
          if (socialmedia === 'FACEBOOK')
            shareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}&quote=${title}`;
          else if (socialmedia === 'TWITTER')
            shareLink = `https://twitter.com/intent/tweet?text=${title}&url=${shareURL}`;
          else if (socialmedia === 'WHATSAPP') {
            if (isLgUp) {
              shareLink = `https://web.whatsapp.com/send?text=${shareURL} ${title}`;
            } else {
              shareLink = `https://api.whatsapp.com/send?text=${shareURL} ${title}`;
            }
          } else if (socialmedia === 'EMAIL')
            shareLink = `mailto:?subject=${title}&body=${shareURL}`;
          else if (socialmedia === 'TELEGRAM')
            shareLink = `https://telegram.me/share/url?url=${shareURL}&text=${title}`;

          if (shareLink) {
            if (isLgUp)
              window.open(
                shareLink,
                socialmedia,
                `width=800, height=440, left=${screen.width / 2 - 400}, top=${
                  screen.height / 2 - 220
                }`,
              );
            else openInNewTab(shareLink);
          }
        },
      },
    );
  };

  return (
    <Box display="flex" alignItems="center">
      {whichIconYouWant.includes('facebook') && (
        <BorderedIconButton
          style={style}
          size={variant}
          sx={[{ mr: 1.5 }]}
          onClick={() => handleShare('FACEBOOK')}
        >
          <FacebookIcon />
        </BorderedIconButton>
      )}
      {whichIconYouWant.includes('twitter') && (
        <BorderedIconButton
          style={style}
          size={variant}
          sx={{ mr: 1.5, height: '50px' }}
          onClick={() => handleShare('TWITTER')}
        >
          {/* <TwitterIcon /> */}
          <div style={{ fontWeight: '500' }}>&#120143;</div>
        </BorderedIconButton>
      )}
      {whichIconYouWant.includes('whatsapp') && (
        <BorderedIconButton
          style={style}
          size={variant}
          sx={{ mr: 1.5 }}
          onClick={() => handleShare('WHATSAPP')}
        >
          <WhatsAppIcon />
        </BorderedIconButton>
      )}
      {whichIconYouWant.includes('email') && (
        <BorderedIconButton
          style={style}
          size={variant}
          sx={{ mr: 1.5 }}
          onClick={() => handleShare('EMAIL')}
        >
          <EmailIcon />
        </BorderedIconButton>
      )}
      {whichIconYouWant.includes('telegram') && (
        <BorderedIconButton
          style={style}
          size={variant}
          sx={{ mr: 1.5 }}
          onClick={() => handleShare('TELEGRAM')}
        >
          <Telegram />
        </BorderedIconButton>
      )}
      {whichIconYouWant.includes('copy') && (
        <BorderedIconButton size={variant} onClick={() => handleShare('COPY')}>
          <ContentCopyIcon />
        </BorderedIconButton>
      )}
    </Box>
  );
};

export default PortfolioShareIcons;
