import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { ToastContext } from 'app/components/Context/ToastContext';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';

const ShareSellOnMarketBot = ({ sharePath }: { sharePath: string }) => {
  const { showAlert } = useContext(ToastContext);

  return (
    <>
      <Box
        sx={{
          p: 1,
          mb: 2,
          border: '1px dashed rgba(0, 0, 0, 0.23)',
          cursor: 'pointer',
          borderRadius: theme => theme.spacing(0.5),
          backgroundColor: 'grey.100',
        }}
        onClick={() => {
          if (window.clipboardData)
            // eslint-disable-next-line
            void window.clipboardData.setData('Text', sharePath);
          else void navigator.clipboard.writeText(sharePath);
          showAlert!('Link Copied!', 'success');
        }}
      >
        <Typography
          variant="body2"
          fontWeight="400"
          color={'purple.500'}
          className="text-ellipsis"
        >
          {sharePath}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <MuiButton
          variant="contained"
          color="secondary"
          sx={{ justifyContent: 'space-between' }}
          endIcon={<ShareIcon />}
        >
          <FormattedMessage id="buttons.share" />
        </MuiButton>
      </Box>
    </>
  );
};

export default ShareSellOnMarketBot;
