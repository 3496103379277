import { Position } from './ApiServicesTypes';
import { SymbolsData } from './Symbols';

export type TodaysPositionsProps = {
  positions: Position[];
  handlePositionClick: (position: Position) => void;
  liveFeedData?: SymbolsData;
  isTopBorder?: boolean;
  selectedItems?: number[] | null;
};

export const MCXSYMBOLEMULTIPLAYER = {
  ALUMINI: 1,
  ALUMINIUM: 1,
  COPPER: 1,
  COTTONCNDY: 1,
  CRUDEOILM: 1,
  CRUDEOIL: 1,
  GOLDM: 0.1,
  GOLDGUINEA: 0.125,
  GOLD: 0.1,
  GOLDPETAL: 1,
  KAPAS: 1,
  LEAD: 1,
  LEADMINI: 1,
  MCXBULLDEX: 1,
  MCXMETLDEX: 1,
  MENTHAOIL: 1,
  NATGASMINI: 1,
  NATURALGAS: 1,
  NICKEL: 1,
  SILVERM: 1,
  SILVERMIC: 1,
  SILVER: 1,
  ZINC: 1,
  ZINCMINI: 1,
};
