import { Fragment } from 'react';
import { GetNotificationsResult } from 'types/ApiServicesTypes';
import { Divider } from '@mui/material';
import { NotificationItem } from '../speedBot/EntityItem';

const NotificationListItem = ({
  items,
  handleItemClick,
}: {
  items: GetNotificationsResult;
  handleItemClick: (
    id: number,
    type?: string,
    subscriptionId?: number,
    redirectUrl?: string,
    notificationType?: string,
    brokerId?: number,
  ) => void;
}) => {
  return (
    <Fragment>
      <Divider className="m-main-spacing" sx={{ p: 0 }} />
      {items &&
        items.map((notification, i) => (
          <Fragment key={i}>
            <NotificationItem
              notification={notification}
              handleClick={handleItemClick}
            />
            {items.length - 1 !== i && (
              <Divider className="m-main-spacing" sx={{ py: 0 }} />
            )}
          </Fragment>
        ))}
    </Fragment>
  );
};

export default NotificationListItem;
