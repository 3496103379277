import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface ScrollbarProps {
  children: ReactNode;
}

const NoScrollbar = ({ children }: ScrollbarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: 2,
        overflowX: 'auto',
        overflowY: 'hidden',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        padding: '1px',
      }}
    >
      {children}
    </Box>
  );
};

export default NoScrollbar;
