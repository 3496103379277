import { useQuery } from 'react-query';
import { GetBotOrders } from 'services/apiServices';
import { Errors, GetBotOrdersResult } from 'types/ApiServicesTypes';

export default function useGetBotOrders(
  botId: number,
  page: number,
  baseApiUrl: string,
  maxPage = 10,
) {
  const queryKey = ['GetBotOrders', baseApiUrl, botId, page, maxPage];
  const objQuery = useQuery<GetBotOrdersResult, Errors>(queryKey, () =>
    GetBotOrders(baseApiUrl, 'GET', botId, page, maxPage),
  );

  const processedData =
    objQuery.data?.map(item => {
      if (typeof item.message === 'string') {
        let modifiedMessage = item.message;
        modifiedMessage = modifiedMessage.replace(
          /silentquantconnect/gi,
          'speedbotEngine',
        );
        modifiedMessage = modifiedMessage.replace(/quantconnect/gi, 'engine');
        return {
          ...item,
          message: modifiedMessage,
        };
      }
      return item;
    }) || [];
  return { ...objQuery, data: processedData };
}
