import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { VerifyPhone } from 'services/apiServices';
import { Errors, VerifyPhoneResult } from 'types/ApiServicesTypes';

export default function useVerifyPhone(email: string) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    VerifyPhoneResult,
    Errors,
    Pick<{ country_code: string; phone: string }, 'phone' | 'country_code'>
  >('VerifyPhone', values =>
    VerifyPhone(values.country_code, values.phone, email)
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
