import {
  ControlsType,
  FieldParam,
  FieldParamValue,
  OptionLegParam,
  ValidateState,
} from '../types';
import { Stack, Grid } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import _ from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { v4 } from 'uuid';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: '13px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '30px',
          width: '300px',
          fontSize: '13px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          top: '-2px',
        },
      },
    },
  },
});

export const DateRenderer = (props: Props) => {
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, string>;
  const { name, controls, parentKey, myStyle, addClass, width } = param;

  const validate = useCallback(
    (formData: Record<string, string>) => {
      let validStatus = {
        status: false,
        error: '',
      };
      Object.keys(formData).forEach((item, index) => {
        if (controls[index].isRequired && !formData[item]) {
          validStatus = {
            status: true,
            error: 'Please enter valid data',
          };
        }
      });
      return validStatus;
    },
    [controls],
  );

  useEffect(() => {
    const validStatus = validate(selected);
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);
  }, [validate, sendValidation, selected, parentKey]);

  const handleChange = (newValue: Date | null, control: ControlsType) => {
    selected[control.key] = moment(newValue).format('DD/MM/YYYY');
    const validStatus = validate(selected);
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };

  return (
    <Grid
      className={addClass}
      item
      style={
        width
          ? { marginBottom: '24px', width: `${width}px` }
          : { marginBottom: '24px' }
      }
    >
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: myStyle?.small
            ? myStyle.small
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('md')]: myStyle?.medium
            ? myStyle.medium
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('lg')]: myStyle?.large
            ? myStyle.large
            : myStyle?.root
            ? myStyle.root
            : myStyle,
        }}
      />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack component="form" noValidate>
            {controls.map(control => (
              <DesktopDatePicker
                key={v4()}
                label={name}
                format="DD/MM/YYYY"
                value={moment(selected[control.key], 'DD/MM/YYYY').toDate()}
                onChange={event => {
                  handleChange(event, control);
                }}
                slotProps={{
                  textField: {
                    style: width ? { width: `${width - 10}px` } : {},
                    size: 'small',
                    error: isValidStatus.status,
                  },
                }}
                disabled={isReadOnly}
              />
            ))}
          </Stack>
        </LocalizationProvider>
      </ThemeProvider>
      {/* {isValidStatus.status && (
        <FormHelperText error={isValidStatus.status}>
          {isValidStatus.error}
        </FormHelperText>
      )} */}
    </Grid>
  );
};
