import { memo, useContext } from 'react';
import { Typography, Box } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// Service
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import useDeleteBot from 'services/Bots/useDeleteBot';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useTranslation } from 'react-i18next';

const BotArchiveModal = ({
  open,
  bid,
  botName,
  handleClose,
  category,
}: {
  open: boolean;
  bid: number;
  botName: string;
  handleClose: (isSuccess, message?: string) => void;
  category: string;
}) => {
  const history = useHistory();
  const mutation = useDeleteBot(bid);
  const { showAlert } = useContext(ToastContext);
  const { t } = useTranslation();

  const handleStopClick = () => {
    mutation.mutate(undefined, {
      onSuccess: res => {
        if (res.success) {
          showAlert!(t('bot_archive_modal.archive_success'), 'success');
          history.push('/bots');
          logFirebaseEventEntry('BOT_ARCHIVE_' + category.toUpperCase(), {
            bot_id: bid,
          });

          handleClose(true);
        }
      },
    });
  };

  const handleCloseModal = () => {
    handleClose(true);
  };

  return (
    <>
      <CircularLoader open={mutation.isLoading} zIndex={1302} />
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <Box className="m-main-spacing">
            <Typography variant="body1" sx={{ pb: 4, fontWeight: 400 }}>
              <FormattedMessage id="bot_archive_modal.are_you_sure" />{' '}
              <b>{botName}</b>?
            </Typography>
          </Box>
        }
        footer={
          <>
            <MuiLoadingButton
              fullWidth
              variant="outlined"
              color="error"
              sx={{ mb: 2 }}
              onClick={handleStopClick}
              loading={mutation.isLoading}
              loadingPosition="start"
            >
              <FormattedMessage id="buttons.archive_bot" />
            </MuiLoadingButton>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </>
        }
      />
    </>
  );
};

export default memo(BotArchiveModal);
