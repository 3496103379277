import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { FEATURES } from 'types/Feature';
import BotDeployModalContent from './BotDeployModalContent';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import BotDeployModalFeatureFallbackContent from './BotDeployModalFeatureFallbackContent';

export type GoLiveFormProps = {
  broker: string;
  duration: string;
  amount?: string;
  durationType?: string;
  capital: string;
  multiple?: string;
  Multiple?: string;
  // exchanges?: string;
};

export default function BotDeployModal({
  open,
  openFrom,
  botName,
  handleClose,
  botId,
  skipTerms,
  exchanges,
  isAddMoreLots,
}: {
  open: boolean;
  openFrom: string;
  botId: number;
  botName: string;
  skipTerms?: boolean;
  exchanges?: string[];
  handleClose: (isSuccess: boolean, message?: string) => void;
  isAddMoreLots?: boolean;
}) {
  const handleCloseModal = () => {
    handleClose(true);
  };

  return (
    <>
      <MuiFullScreenModal
        isDisableEnforceFocus={true}
        isDisableEscapeKeyDown={true}
        open={open}
        handleClose={handleCloseModal}
        body={
          <FeatureFlag
            features={[FEATURES.IS_GOLIVE]}
            fallback={
              <BotDeployModalFeatureFallbackContent
                botName={botName}
                handleClose={handleClose}
              />
            }
          >
            <BotDeployModalContent
              exchanges={exchanges}
              botId={botId}
              botName={botName}
              handleClose={handleClose}
              openFrom={openFrom}
              skipTerms={skipTerms}
              isCrypto={exchanges?.includes('CCXT')}
              isAddMoreLots={isAddMoreLots}
            />
          </FeatureFlag>
        }
      />
    </>
  );
}
