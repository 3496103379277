import { useContext } from 'react';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useMutation } from 'react-query';
import { CreateBroker } from 'services/apiServices';
import {
  CreateBrokerParams,
  CreateBrokerResult,
  Errors,
} from 'types/ApiServicesTypes';
import { manipulateAddBrokerRef } from 'app/components/Bots/BotDetail/util';

export default function useCreateBroker() {
  const { showAlert } = useContext(ToastContext);
  const breadcrumb = useBreadcrumbManager();

  const mutation = useMutation<CreateBrokerResult, Errors, CreateBrokerParams>(
    'CreateBroker',
    values =>
      CreateBroker({
        name: values.name, //selectedBrokerData?.name,
        broker: values.broker,
        exchange: [],
        auth1: values.auth1,
        auth2: values.auth2,
        auth3: values.auth3,
        auth4: values.auth4,
        auth5: values.auth5,
        auth6: values.auth6,
        auth7: values.auth7,
        uuid: values.uuid,
      })
        .then(res => {
          const redirectUrl = manipulateAddBrokerRef('get');
          if (res && res.success && res.loginUrl)
            window.location.assign(res.loginUrl);
          else if (res && res.success) {
            if (redirectUrl) {
              manipulateAddBrokerRef('remove');
              window.location.assign(redirectUrl);
            } else {
              breadcrumb.goBackPathPage('/broker');
            }
          } else if (res && res.error) showAlert!(res.error);
          else showAlert!('Something went wrong. Please try again.');
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
