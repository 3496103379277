import { ClickableSmallVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const ClickableSmallVariant = (props: ClickableSmallVariantProps) => {
  return (
    <Box
      display="flex"
      className="p-main-spacing"
      alignItems="center"
      sx={{ py: 2.75, cursor: 'pointer' }}
    >
      <Typography
        variant="body2"
        color="primary.main"
        sx={{ minWidth: 'min-content' }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="body3"
        fontWeight={500}
        color="primary.light"
        sx={{ minWidth: 'min-content' }}
      >
        {props.subtitle}
      </Typography>
    </Box>
  );
};

export default ClickableSmallVariant;
