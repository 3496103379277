import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
type PositionRemoveAlertProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
};

const PositionRemoveAlert = ({
  open,
  handleClose,
}: PositionRemoveAlertProps) => {
  return (
    <SnakbarMessageModal
      open={open}
      handleClose={() => handleClose(false)}
      body={
        <Box>
          <Typography variant="body1" sx={{ pb: 1, fontWeight: 600 }}>
            Are You Sure?
          </Typography>
          <Typography variant="body2">
            You&lsquo;re about to clear all your progress. If you continue, this
            can&lsquo;t be undone. Are you sure you want to proceed?
          </Typography>
        </Box>
      }
      footer={
        <>
          <MuiButton
            fullWidth
            variant="contained"
            sx={{ mb: 2 }}
            onClick={() => handleClose(true)}
          >
            <FormattedMessage id="buttons.continue" />
          </MuiButton>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClose(false)}
          >
            <FormattedMessage id="buttons.cancel" />
          </MuiButton>
        </>
      }
    />
  );
};

export default PositionRemoveAlert;
