import moment from 'moment';
import { alpha } from '@mui/material/styles';
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ProfitLossByDateData } from 'types/ApiServicesTypes';
import { getColorByNum } from 'utils/GenericFunctions';
import { ProfitLossByDateTransform } from './types';
import { Fragment, memo } from 'react';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: 1,
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: `${theme.spacing(1)} !important`,
    marginTop: `${theme.spacing(1)} !important`,
  },
}));

const getTooltipTitle = (dateData: ProfitLossByDateTransform) => {
  const tootltipTitle = dateData ? (
    <Box>
      <Typography variant="body3" component="div">
        Trades:{' '}
        <Typography variant="body3" fontWeight={500}>
          {dateData.trades}
        </Typography>
      </Typography>
      <Typography variant="body3" component="div">
        Profit Loss:{' '}
        <Typography
          variant="body3"
          fontWeight={500}
          color={getColorByNum(dateData.profit_loss)}
        >
          {dateData.profit_loss}
        </Typography>
      </Typography>
    </Box>
  ) : (
    ''
  );

  return tootltipTitle;
};

const DataRowComp = ({
  dateData,
  day,
  openTooltip,
  handleTooltipClose,
  handleTooltipOpen,
}: {
  dateData: ProfitLossByDateTransform;
  day: string;
  openTooltip: string;
  handleTooltipClose: () => void;
  handleTooltipOpen: (dt: string) => void;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const tootltipTitle = getTooltipTitle(dateData);

  return (
    <Box
      component="td"
      className={`${day ? '' : 'past-future-date'}`}
      sx={{
        ...(dateData &&
          dateData.color && {
            cursor: 'pointer',
            background: theme =>
              `${
                dateData.color.color === 'green'
                  ? alpha(theme.palette.success.main, dateData.color?.opacity)
                  : alpha(theme.palette.error.main, dateData.color.opacity)
              } !important`,
          }),
      }}
      {...(!isMdUp &&
        dateData && { onClick: () => handleTooltipOpen(dateData.date) })}
    >
      {isMdUp ? (
        <Box>
          <CustomTooltip title={tootltipTitle}>
            <Box>{day}</Box>
          </CustomTooltip>
        </Box>
      ) : (
        <Box>
          <CustomTooltip
            title={tootltipTitle}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => handleTooltipClose()}
            open={Boolean(
              dateData && openTooltip && openTooltip === dateData.date,
            )}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Box>{day}</Box>
          </CustomTooltip>
        </Box>
      )}
    </Box>
  );
};

const DataRow = memo(DataRowComp);

//function to get all days by week
const CalendarDateRow = ({
  month,
  year,
  data,
  openTooltip,
  handleTooltipClose,
  handleTooltipOpen,
}: {
  month: number;
  year: number;
  data: ProfitLossByDateData;
  openTooltip: string;
  handleTooltipClose: () => void;
  handleTooltipOpen: (dt: string) => void;
}) => {
  const calendar: string[][] = [];

  const startDate = moment([year, month])
    .clone()
    .startOf('month')
    .startOf('week');

  const endDate = moment([year, month]).clone().endOf('month');

  const day = startDate.clone().subtract(1, 'day');

  // looping a month by a week
  while (day.isBefore(endDate, 'day')) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone().format('DD')),
    );
  }

  if (calendar.length) {
    calendar[0] = calendar[0].map(x => {
      if (x.startsWith('0')) return x;
      return '';
    });
    calendar[calendar.length - 1] = calendar[calendar.length - 1].map(x => {
      if (x.startsWith('0')) return '';
      return x;
    });
  }

  const dMonth = month + 1;
  return (
    <>
      {calendar.length > 0 &&
        calendar.map((week: string[], index: number) => (
          <tr key={index}>
            {week.map((day: string, j) => {
              const dateData = data.transformProfitLossData.filter(
                x =>
                  x.date ===
                  `${year}-${dMonth <= 9 ? `0${dMonth}` : `${dMonth}`}-${day}`,
              )[0];

              return (
                <Fragment key={`${index}_${day}_${j}`}>
                  <DataRow
                    dateData={dateData}
                    day={day}
                    openTooltip={openTooltip}
                    handleTooltipClose={handleTooltipClose}
                    handleTooltipOpen={handleTooltipOpen}
                  />
                </Fragment>
              );
            })}
          </tr>
        ))}
    </>
  );
};

export default memo(CalendarDateRow);
