import { Route, Switch, useLocation } from 'react-router-dom';
import { lazyLoad } from 'utils/loadable';
import SignInLayout from 'app/components/Layouts/SignInLayout';

// project imports
import GuestGuard from 'routes/route-guard/GuestGuard';
import AppleOAuthPage from 'app/pages/Auth/AppleOAuthPage';

// signin routing
const SigninPage = lazyLoad(
  () => import('app/pages/Auth/SigninPage'),
  module => module.SigninPage,
);

const HomePage = lazyLoad(
  () => import('app/pages/Auth/HomePage'),
  module => module.HomePage,
);

const SignupStartPage = lazyLoad(
  () => import('app/pages/Auth/SignupStartPage'),
  module => module.SignupStartPage,
);

const SignupVerifyPhonePage = lazyLoad(
  () => import('app/pages/Auth/SignupVerifyPhonePage'),
  module => module.SignupVerifyPhonePage,
);

const SignupVerifyCodePage = lazyLoad(
  () => import('app/pages/Auth/SignupVerifyCodePage'),
  module => module.SignupVerifyCodePage,
);

const ForgotPassPage = lazyLoad(
  () => import('app/pages/Auth/ForgotPassPage'),
  module => module.ForgotPassPage,
);

const ResetConfirmMethodPage = lazyLoad(
  () => import('app/pages/Auth/ResetConfirmMethodPage'),
  module => module.ResetConfirmMethodPage,
);

const ResetConfirmCodePage = lazyLoad(
  () => import('app/pages/Auth/ResetConfirmCodePage'),
  module => module.ResetConfirmCodePage,
);

const ResetPassPage = lazyLoad(
  () => import('app/pages/Auth/ResetPassPage'),
  module => module.ResetPassPage,
);

const GoogleOAuthPage = lazyLoad(
  () => import('app/pages/Auth/GoogleOAuthPage'),
  module => module.GoogleOAuthPage,
);

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
  const location = useLocation();

  return (
    <Route path={['/signIn', '/signup', '/forgotpass']}>
      <SignInLayout>
        <Switch location={location} key={location.pathname}>
          <GuestGuard>
            <>
              <Route exact path="/signin" component={SigninPage} />
              <Route exact path="/signup" component={HomePage} />
              <Route exact path="/signup/start" component={SignupStartPage} />
              <Route
                exact
                path="/signup/verifyphone/:email"
                component={SignupVerifyPhonePage}
              />
              <Route
                exact
                path="/signup/verifycode/:ccode/:phone?"
                component={SignupVerifyCodePage}
              />
              <Route exact path="/forgotpass" component={ForgotPassPage} />
              <Route
                exact
                path="/forgotpass/resetconfirmmethod"
                component={ResetConfirmMethodPage}
              />
              <Route
                exact
                path="/forgotpass/resetconfirmcode/:ccode/:phone/:source?"
                component={ResetConfirmCodePage}
              />
              <Route
                exact
                path="/forgotpass/resetpass/:ccode/:phone/:otp"
                component={ResetPassPage}
              />
              <Route exact path="/signin/google" component={GoogleOAuthPage} />
              <Route exact path="/signin/apple" component={AppleOAuthPage} />
            </>
          </GuestGuard>
        </Switch>
      </SignInLayout>
    </Route>
  );
};

export default LoginRoutes;
