import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PlanSubscribeFlow from 'app/components/Menu/PlanSubscribeFlow';
import PlanSubscribeModal from 'app/components/Menu/PlanSubscribeModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

export function PlanUpgradePage() {
  const isMdUp = useGetMediaQueryUp('md');
  const [open, setOpen] = useState<boolean>(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Plan Upgrade Page</title>
        <meta name="description" content="Plan Upgrade Page" />
      </Helmet>

      {isMdUp ? (
        <PlanSubscribeModal open={open} handleClose={handleClose} />
      ) : (
        <PlanSubscribeFlow type="upgrade" />
      )}
    </>
  );
}
