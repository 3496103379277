import { ReactElement } from 'react';
import { Box, Breakpoint, Dialog } from '@mui/material';

export default function MuiModal({
  open,
  handleClose,
  header,
  body,
  footer,
  isDisableEnforceFocus,
  isDisableEscapeKeyDown,
  isFullScreen,
  isAutoHeight,
  maxWidth = 'xs',
}: {
  open: boolean;
  handleClose: (isSuccess, message?: string) => void;
  header?: ReactElement;
  body?: ReactElement;
  footer?: ReactElement;
  isDisableEnforceFocus?: boolean;
  isDisableEscapeKeyDown?: boolean;
  isFullScreen?: boolean;
  isAutoHeight?: boolean;
  maxWidth?: Breakpoint;
}) {
  return (
    <>
      <Dialog
        disableEnforceFocus={isDisableEnforceFocus === false ? false : true}
        disableEscapeKeyDown={isDisableEscapeKeyDown === false ? false : true}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        scroll={isAutoHeight ? 'body' : 'paper'}
        PaperProps={{
          sx: {
            width: {
              md:
                maxWidth === 'lg'
                  ? '64rem'
                  : maxWidth === 'md'
                  ? '40rem'
                  : '30rem',
            },
            maxWidth: { md: 'initial' },
            ...(!isAutoHeight && { height: '100%' }),
            ...(isFullScreen && {
              m: 0,
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: 0,
              display: 'flex',
              flex: 1,
            }),
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {header ? <Box>{header}</Box> : <></>}
          <Box sx={{ overflow: 'auto', flex: 1 }}>{body}</Box>
          {footer ? <Box>{footer}</Box> : <></>}
        </Box>
      </Dialog>
    </>
  );
}
