import { useQuery } from 'react-query';
import { GetPortfolio } from 'services/apiServices';
import {
  Errors,
  Position,
  PortfolioBotsData,
  GetPortfolioBotResult,
  PortfolioBot,
} from 'types/ApiServicesTypes';
import { PortfolioBotType } from 'types/ComponentTypes';

export type AllBotOpenPositions = {
  id: number;
  todays_change: number;
  openPositions: Position[];
};
import { removeCookie, setCookie } from 'typescript-cookie';
import { IS_PORTFOLIO_PAPER_AVAIL } from 'constants/common';
import { isUserLoggedIn } from 'utils/auth';

// return open positions and position data
export function getAllBotOpenPositions(
  portfolioBots: PortfolioBot[] | undefined,
): {
  arrOpenPositionsData: AllBotOpenPositions[] | Position[];
  arrOpenPos: Position[];
} {
  const arrOpenPositionsData: AllBotOpenPositions[] = [];
  let arrOpenPos: Position[] = [];
  if (portfolioBots) {
    portfolioBots.map(portfolio => {
      if (
        portfolio.bot_info &&
        portfolio.bot_info.today_positions &&
        portfolio.bot_info.today_positions.length > 0
      ) {
        const openPositions = portfolio.bot_info.today_positions.filter(
          x => x.exit_price === 0,
        );
        if (openPositions && openPositions.length > 0) {
          arrOpenPos = arrOpenPos.concat(openPositions);
        }
        arrOpenPositionsData.push({
          id: portfolio.id,
          todays_change: portfolio.bot_info.todays_change
            ? portfolio.bot_info.todays_change
            : 0,
          openPositions: openPositions,
        });
      }
    });
  }

  return { arrOpenPositionsData, arrOpenPos };
}

// return unique symbols
export function getUniqueSymbols(arrPos: Position[]) {
  const symbols = arrPos
    .map(pos => {
      return pos.trading_symbol;
    })
    .filter((v, i, self) => {
      return i == self.indexOf(v);
    });

  return symbols;
}

// process response data
export function processResponseData(
  res: GetPortfolioBotResult,
  type: PortfolioBotType,
) {
  let arrPos: Position[] = [];
  let symbols: Array<string> = [];
  let arrPositions: Array<Position> | Array<AllBotOpenPositions> = [];
  if (
    res &&
    res.bots &&
    ((type === 'PAPER' && res.bots.paper) ||
      (type === 'ACTIVE' && res.bots.active))
  ) {
    const objPos = getAllBotOpenPositions(
      type === 'ACTIVE' ? res.bots.active : res.bots.paper,
    );
    arrPos = objPos.arrOpenPos;
    arrPositions = objPos.arrOpenPositionsData;

    // unique symbols
    symbols = getUniqueSymbols(arrPos);
  }
  const bots = (type === 'ACTIVE' ? res?.bots.active : res?.bots.paper) || [];

  return {
    data: res,
    bots: [
      ...bots.map((item: PortfolioBot) => ({
        ...item,
        instrument: item.instrument.map((anotherItem: string) =>
          anotherItem.replace(/_SPACE/g, ''),
        ),
      })),
    ],
    positionData: arrPositions,
    symbols,
  };
}

export type UseGetPortfolioRes = {
  paper: PortfolioBotsData;
  active: PortfolioBotsData;
};

export type ProcessPortfolioBots = {
  bots: PortfolioBot[];
  positionData: Position[] | AllBotOpenPositions[];
  symbols: string[];
};

export function processPositionsSymbols(bots: PortfolioBot[]) {
  // filter open positions
  const objPos = getAllBotOpenPositions(bots);
  const arrPositions = objPos.arrOpenPositionsData;

  // unique symbols
  const symbols = getUniqueSymbols(objPos.arrOpenPos);

  return { bots, positionData: arrPositions, symbols };
}

// process response data
export function processResponseData2(res: GetPortfolioBotResult) {
  const activeBots = res && res.bots.active ? res.bots.active : [];
  const paperBots = res && res.bots.paper ? res.bots.paper : [];

  const activeData = processPositionsSymbols(activeBots);
  const paperData = processPositionsSymbols(paperBots);

  return { data: res, active: activeData, paper: paperData };
}

export function useGetPortfolio() {
  const isLoggedIn = isUserLoggedIn();
  const objQuery = useQuery<UseGetPortfolioRes, Errors>(
    ['GetPortfolio', isLoggedIn],
    async () => {
      return isLoggedIn
        ? await GetPortfolio().then(res => {
            if (
              res &&
              res.bots &&
              res.bots.paper &&
              res.bots.paper.length > 0
            ) {
              setCookie(IS_PORTFOLIO_PAPER_AVAIL, true.toString());
            } else {
              removeCookie(IS_PORTFOLIO_PAPER_AVAIL);
            }

            return {
              paper: processResponseData(res, 'PAPER'),
              active: processResponseData(res, 'ACTIVE'),
            };
          })
        : ({} as UseGetPortfolioRes);
    },
  );
  return objQuery;
}

export function useCheckPortfolioDataExist() {
  const objQuery = useGetPortfolio();
  const isLoading = objQuery.isLoading;
  const isError = objQuery.isError;
  const error = objQuery.error;

  const activeData = objQuery.data?.active.bots;
  const paperData = objQuery.data?.paper.bots;

  const isPaperDataExist = paperData ? paperData.length > 0 : false;
  const isActiveDataExist = activeData ? activeData.length > 0 : false;
  const isDataExist = isPaperDataExist || isActiveDataExist;

  const isPaperWarning =
    paperData &&
    paperData.length &&
    paperData.filter(x => x.bot_info && x.bot_info.errors.length).length
      ? true
      : false;
  const isActiveWarning =
    activeData &&
    activeData.length &&
    activeData.filter(x => x.bot_info && x.bot_info.errors.length).length
      ? true
      : false;

  return {
    isPaperDataExist,
    isActiveDataExist,
    isPaperWarning,
    isActiveWarning,
    isLoading,
    isDataExist,
    isError,
    error,
    refatch: objQuery.refetch,
  };
}
