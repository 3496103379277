import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import OrdersListLayout from 'app/design/layouts/OrdersListLayout';
import BotHistoryOrdersModal from 'app/components/Bots/BotHistory/BotHistoryOrdersModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { decodeUrlData } from 'utils/GenericFunctions';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import { Order } from 'types/ApiServicesTypes';
import useGetBotOrders from 'services/Bots/useGetBotOrders';

export function BotHistoryOrdersPage() {
  const { bid, sid, title, hsid, subtitle } = useParams<{
    bid: string;
    sid: string;
    title: string;
    hsid: string;
    subtitle: string;
  }>();

  const titleData = decodeUrlData(title);
  const subtitleData = decodeUrlData(subtitle);

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();
  const [page, setPage] = React.useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const baseApiUrl = `bots/${bid}/history/${hsid}`;

  // For change tab
  const handleChange = (newValue: number) => {
    const path = `/bots/${bid}/history/${sid}/positions/${hsid}/${title}/${subtitle}`;
    if (newValue === 0) {
      history.replace({ pathname: path });
    }
  };

  const botId = parseInt(bid);

  const {
    data: botHistoryOrdersData,
    refetch,
    isLoading,
  } = useGetBotOrders(botId, page, baseApiUrl);

  const totalPages =
    botHistoryOrdersData && botHistoryOrdersData.length > 0
      ? Number(botHistoryOrdersData[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const handleModalOpen = (orderData: Order) => {
    setSelectedOrder(orderData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  return (
    <>
      <Helmet>
        <title>Bot History Orders</title>
        <meta name="description" content="Bot History Orders Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={
          <>
            {titleData}
            {subtitleData && subtitleData.indexOf('→') === -1
              ? ' | ' + subtitleData
              : ''}
            {subtitleData && subtitleData.indexOf('→') !== -1 ? (
              <>
                {' | '}
                {subtitleData.split('→')[0]}
                <ArrowForwardIcon sx={{ fontSize: '1.25rem', mx: 1 }} />
                {subtitleData.split('→')[1]}
              </>
            ) : (
              <></>
            )}
          </>
        }
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={1}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.position') },
              { label: t('buttons.order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_HISTORY_ORDERS}
            data={{
              sid,
              botId,
              botName: title,
              historySubId: hsid,
              title: subtitle,
            }}
          />
        }
      />
      <OrdersListLayout
        data={botHistoryOrdersData}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleClick={handleModalOpen}
        handleChangeTab={handleChange}
      />
      <BotHistoryOrdersModal
        open={openModal}
        handleClose={handleModalClose}
        data={selectedOrder || null}
        title={<FormattedMessage id="buttons.order" />}
      />
    </>
  );
}
