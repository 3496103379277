import { Box, Typography } from '@mui/material';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { BotSignal } from 'types/ApiServicesTypes';
import { ItemSeparator } from '../../EntityItem';
import { formatNumber } from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { positionSizingLabels } from 'app/components/Bots/BotSignal/util';

const BotSignalRowDetail = ({
  signal,
  handleClose,
}: {
  signal: BotSignal;
  handleClose: () => void;
}) => {
  return (
    <Box sx={{ backgroundColor: 'grey.100' }}>
      <Box sx={{ p: 2 }} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="body2" fontWeight={400} color="text.primary">
            {positionSizingLabels[signal.position_sizing] || '-'}
          </Typography>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="bot_signals.form.position_sizing" />
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" fontWeight={400} color="text.primary">
            {['LIMIT', 'STOPLOSS_LIMIT'].includes(signal.order_type) &&
            signal.order_price
              ? `${signal.currency_symbol}${formatNumber(
                  parseFloat(signal.order_price),
                )}`
              : '-'}
          </Typography>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="bot_signals.form.limit_price" />
          </Typography>
        </Box>
        {signal.order_type === 'STOP_LOSS' && (
          <Box>
            <Typography variant="body2" fontWeight={400} color="text.primary">
              {signal.order_trigger_price
                ? `${signal.currency_symbol}${formatNumber(
                    parseFloat(signal.order_trigger_price),
                  )}`
                : '-'}
            </Typography>
            <Typography variant="body3" color="text.secondary">
              <FormattedMessage id="bot_signals.form.trigger_price" />
            </Typography>
          </Box>
        )}
        <FeatureFlag features={[FEATURES.IS_SL_TARGET_SIGNAL]} fallback={<></>}>
          <Box>
            <Typography variant="body2" fontWeight={400} color="text.primary">
              {signal.stoploss?.enabled
                ? `${signal.currency_symbol}${formatNumber(
                    signal.stoploss.trigger,
                  )}`
                : '-'}
            </Typography>
            <Typography variant="body3" color="text.secondary">
              {signal.order_type === 'STOP_LOSS' && (
                <>
                  <FormattedMessage id="bot_signals.form.stoploss" />{' '}
                </>
              )}
              <FormattedMessage id="bot_signals.form.trigger" />{' '}
              <FormattedMessage id="bot_signals.form.price" />
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontWeight={400} color="text.primary">
              {signal.stoploss?.enabled
                ? `${signal.currency_symbol}${formatNumber(
                    signal.stoploss.price,
                  )}`
                : '-'}
            </Typography>
            <Typography variant="body3" color="text.secondary">
              <FormattedMessage id="bot_signals.form.stoploss" />{' '}
              <FormattedMessage id="bot_signals.form.price" />
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontWeight={400} color="text.primary">
              {signal.target?.enabled
                ? `${signal.currency_symbol}${formatNumber(
                    signal.target.price,
                  )}`
                : '-'}
            </Typography>
            <Typography variant="body3" color="text.secondary">
              <FormattedMessage id="bot_signals.form.target" />{' '}
              <FormattedMessage id="bot_signals.form.price" />
            </Typography>
          </Box>
        </FeatureFlag>

        {/* {order.broker_order_id && (
          <Box>
            <Typography variant="body2" fontWeight={400} color="text.primary">
              {order.broker_order_id ? order.broker_order_id : '-'}
            </Typography>
            <Typography variant="body3" color="text.secondary">
              <FormattedMessage id="summary_labels.broker_order_id" />
            </Typography>
          </Box>
        )} */}
        {/* <Box>
          <Box>
            <MuiChip
              icon={<>{orderStatus[order.status].icon}</>}
              label={<>{orderStatus[order.status].value}</>}
              size="xsmallicon"
              color={orderStatus[order.status].chipColor as ChipColor}
            />
          </Box>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="summary_labels.status" />
          </Typography>
        </Box> */}
        <Box textAlign="right">
          <Box>
            <MuiChip label={signal.tag} size="xsmall" color="greylight" />
          </Box>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="summary_labels.tag" />
          </Typography>
        </Box>
      </Box>
      <ItemSeparator variant="1px" />
      <ItemSeparator variant="1px" />
      <Box
        sx={{ textAlign: 'center', cursor: 'pointer' }}
        onClick={() => handleClose()}
      >
        <KeyboardArrowUp />
      </Box>
    </Box>
  );
};

export default BotSignalRowDetail;
