import { Link as RouterLink } from 'react-router-dom';
import { ItemSubtextIconVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const ItemSubtextIconVariant = (props: ItemSubtextIconVariantProps) => {
  const {
    title,
    subtext,
    icon,
    bgColor,
    chip,
    linkProps,
    handleClick,
    bottomChip,
  } = props;
  return (
    <Box
      sx={{
        py: 2,
        cursor: 'pointer',
        backgroundColor: bgColor ? bgColor : 'transparent',
      }}
      className="p-main-spacing"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      component={
        linkProps && linkProps.link
          ? linkProps.isExternal
            ? 'a'
            : RouterLink
          : 'div'
      }
      {...(handleClick && { onClick: handleClick })}
      {...(linkProps && { to: linkProps.link ? linkProps.link : undefined })}
      {...(linkProps &&
        linkProps.isExternal && {
          href: linkProps.link ? linkProps.link : undefined,
          target: linkProps.target ? linkProps.target : undefined,
        })}
      {...(linkProps && linkProps.target && { target: linkProps.target })}
    >
      <Box>
        <Typography variant="body2" color="primary.main">
          {title}
          {chip}
        </Typography>
        {subtext && (
          <Typography
            variant="body2"
            fontWeight={400}
            color="primary.light"
            sx={{ mt: 0.5 }}
          >
            {subtext}
          </Typography>
        )}
        {bottomChip}
      </Box>
      {icon}
    </Box>
  );
};

export default ItemSubtextIconVariant;
