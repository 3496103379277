import { useQuery } from 'react-query';
import { GetSiteInfo } from 'services/apiServices';
import { Errors, GetSiteInfoResult } from 'types/ApiServicesTypes';

// Stale time is 8 hours.
const STALE_TIME = 8 * 60 * 60 * 1000;

export default function useGetSiteInfo() {
  const objQuery = useQuery<GetSiteInfoResult, Errors>(
    'GetSiteInfo',
    () => GetSiteInfo(),
    {
      staleTime: STALE_TIME,
    },
  );
  return objQuery;
}
