import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { ItemHead, ItemSeparator } from 'app/design/speedBot/EntityItem';

type InfoItem = {
  key: string | ReactElement;
  value: string | number | ReactElement;
};

export const InfoItem = ({ item }: { item: InfoItem }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body3" color="text.secondary">
        {item.key}
      </Typography>
      <Typography variant="body3" fontWeight="500">
        {item.value}
      </Typography>
    </Box>
  );
};

const InfoList = ({
  title,
  items,
}: {
  title: string | ReactElement;
  items: InfoItem[];
}) => {
  return (
    <>
      <ItemHead variant="medium" title={title} />
      <Box className="m-main-spacing">
        <ItemSeparator variant="1px" />
      </Box>
      {items.length > 0 && (
        <Box className="m-main-spacing" sx={{ py: 2 }}>
          {items.map((item, i) => (
            <Box sx={{ mb: items.length - 1 !== i ? 2 : 0 }} key={i}>
              <InfoItem item={item} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default InfoList;
