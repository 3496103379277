import i18next from 'i18next';
import { Box, Typography } from '@mui/material';
import { PortfolioItemProps } from './types';
import InstrumentString from 'app/design/itemsComponent/InstrumentString';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const PortfolioItem = ({
  todaysChange,
  ptype,
  item,
  isDisplayUser,
}: PortfolioItemProps) => {
  const breadcrumb = useBreadcrumbManager();

  const todaysChangePer =
    item.initial_capital === 0
      ? 0
      : (todaysChange / item.initial_capital) * 100;
  return (
    <Box className="p-main-spacing" sx={{ py: 1, pb: 2 }} width={1}>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() =>
          breadcrumb.push(
            `/portfolio/${ptype}/${item.id}`,
            PAGES.PORTFOLIO,
            `/portfolio?ptype=${ptype}`,
            i18next.t('portfolio.page_title.portfolio'),
          )
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box flexShrink={0}>
            <Typography variant="body3" color="text.secondary" display="block">
              {item.days ? item.days : ''}
            </Typography>
          </Box>

          {isDisplayUser && (item.trading_username || item.broker_name) && (
            <MuiChip
              label={item.broker_name || item.trading_username}
              color={item.is_logged_in ? 'successlight' : 'errorlight'}
              size="small"
              variant="filled"
              sx={{ ml: 1 }}
            />
          )}

          <MuiChip
            label={`${item.open_position_count} / ${item.position_count} Pos.`}
            size="xsmall"
            variant="filled"
            color="greylight"
            sx={{ fontWeight: 400, flex: 'inherit', whiteSpace: 'nowrap' }}
          />
        </Box>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ mt: 0.5, display: 'flex' }}
        >
          {item.bot_info && item.bot_info.errors.length > 0 && (
            <Box sx={{ mr: 0.5, display: 'flex' }}>
              <WarningAmberIcon color="warning" />
            </Box>
          )}
          {item.name}
        </Typography>
        <Typography
          color="text.secondary"
          display="block"
          variant="body3"
          sx={{ mt: 0.5 }}
        >
          {item.instrument.length > 0 ? (
            <InstrumentString instrument={item.instrument} />
          ) : undefined}
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
          <Box sx={{ flex: 1 }}>
            <Typography color="text.secondary" display="block" variant="body3">
              <FormattedMessage id="todays_change" />
            </Typography>
          </Box>
          <Box sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}>
            <Typography
              variant="body2"
              sx={{
                color: getColorByNum(todaysChange),
              }}
            >{`${todaysChange === 0 ? '' : todaysChange > 0 ? '▲' : '▼'} ${
              item.currency_symbol
            }${formatNumber(todaysChange, true)} (${
              todaysChangePer > 0 ? '+' : ''
            }${formatNumber(todaysChangePer)}%)`}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
          <Box sx={{ flex: 1 }}>
            <Typography color="text.secondary" display="block" variant="body3">
              <FormattedMessage id="profit_loss" />
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{ flex: 'inherit', whiteSpace: 'nowrap' }}
          >
            <Typography variant="body3" display="block" fontWeight={500}>
              {item.currency_symbol}
              {formatNumber(item.initial_capital, true)}
            </Typography>
            <ArrowForwardIcon sx={{ fontSize: '12px', ml: 0.5 }} />
            <Typography
              variant="body3"
              fontWeight={500}
              color={getColorByNum(item.bot_info!.net_profit)}
              sx={{ ml: 0.5 }}
            >{`${item.currency_symbol}${formatNumber(
              item.portfolio_value!,
              true,
            )} (${item.bot_info!.net_profit_per > 0 ? '+' : ''}${
              item.bot_info?.net_profit_per
            }%)`}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioItem;
