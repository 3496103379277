import i18next from 'i18next';
import { PAGES, NoBreadcrumbData } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const brokerAndProfile = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.MENU,
      link: `/menu`,
      label: i18next.t('menu.page_title.menu'),
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const brokerDetail = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.MENU,
      link: `/menu`,
      label: i18next.t('menu.page_title.menu'),
    },
    {
      page: PAGES.BROKER,
      link: `/broker`,
      label: i18next.t('menu.page_title.brokers'),
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const profileEdit = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.MENU,
      link: `/menu`,
      label: i18next.t('menu.page_title.menu'),
    },
    {
      page: PAGES.PROFILE,
      link: `/profile`,
      label: i18next.t('menu.page_title.profile'),
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const billingDetails = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.MENU,
      link: `/menu`,
      label: i18next.t('menu.page_title.menu'),
    },
    {
      page: PAGES.PLAN_DETAIL,
      link: `/menu/plan-details`,
      label: i18next.t('menu.plan'),
    },
  ];
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ticketDetails = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.MENU,
      link: `/menu`,
      label: i18next.t('menu.page_title.menu'),
    },
    {
      page: PAGES.HELP,
      link: `/menu/help`,
      label: i18next.t('menu.page_title.help'),
    },
  ];
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const tradeIdeas = (data: NoBreadcrumbData) => {
  return [
    {
      page: PAGES.TRADE_OPTIONS,
      link: `/trade-options`,
      label: 'Trade Ideas',
    },
  ];
};
