import * as React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

const ToastrAlert = ({
  type,
  message,
  setMessage,
}: {
  type: AlertColor | undefined;
  message: string | null;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}): React.ReactElement => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(message)}
      autoHideDuration={5000}
      onClose={() => {
        setMessage('');
      }}
    >
      <Alert
        onClose={() => {
          setMessage('');
        }}
        severity={type}
        variant="filled"
        sx={{ width: '100%' }}
      >
        <span
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __html: message ? message : '',
          }}
        ></span>
      </Alert>
    </Snackbar>
  );
};

export default ToastrAlert;
