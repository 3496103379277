import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import PageHeader from 'app/design/speedBot/PageHeader';
import { decodeUrlData, getQueryParam } from 'utils/GenericFunctions';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import SignalListLayout from 'app/design/layouts/SignalListLayout';
import BotGenerateSignalModal from 'app/components/Bots/BotSignal/BotGenerateSignalModal';
import { Box } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';

// Service
import useGetBotSignals from 'services/Bots/useGetBotSignals';
import { BotSignal, BotSignalParams } from 'types/ApiServicesTypes';
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import usePostBotSignal from 'services/Bots/usePostBotSignal';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function BotSignalsPage() {
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();

  const isDebugMode = getQueryParam('debug', 'false') === 'true';

  const handleChange = (newValue: number) => {
    if (newValue === 1) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/todays-positions/${title}`,
        search: isDebugMode ? '?debug=true' : '',
      });
    }
    if (newValue === 2) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/todays-orders/${title}`,
        search: isDebugMode ? '?debug=true' : '',
      });
    }
  };

  const botId = parseInt(bid);
  const subId = parseInt(sid);

  const [selectedSignal, setSelectedSignal] = useState<BotSignal | null>(null);
  const [isModify, setIsModify] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState(false);
  const [instrumentLoading, setInstrumentLoading] = useState<
    boolean | undefined
  >(false);
  const { data: botDetails } = useGetBotDetails(botId, subId);
  const { data, isLoading } = useGetBotSignals(botId, page);
  const mutation = usePostBotSignal(botId);
  const [toggleSelection, setToggleSelection] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[] | null>(null);
  const [selectableItems, setSelectableItems] = useState<number[] | null>(null);
  const [selectedSignals, setSelectedSignals] = useState<
    BotSignalParams[] | null
  >(null);
  const isSelectedSignalExit = useIsFeatureFlag([
    FEATURES.IS_SELECTED_SIGNAL_EXIT,
  ]);
  const handleSelectSignal = useCallback(
    (val: BotSignal, isModify: boolean) => {
      const signalData: BotSignal = {
        ...val,
        position_type: isModify ? 'MODIFY' : val.position_type,
      };
      setIsModify(isModify);
      setSelectedSignal(signalData);
      setOpenModal(true);
    },
    [],
  );

  const handleClickExit = () => {
    if (!selectedSignals || selectedSignals.length === 0) return;
    mutation.mutate({ signals: selectedSignals });
  };

  const handleCloseModal = useCallback(() => {
    setIsModify(false);
    setSelectedSignal(null);
    setOpenModal(false);
  }, []);

  const handleChangePagination = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );
  // Handle the "select all" click
  const handleClickSelectAll = useCallback(() => {
    if (
      !selectedItems ||
      selectedItems.length < (selectableItems! && selectableItems?.length)
    ) {
      setToggleSelection(true);
      // Select all if not all are selected
      setSelectedItems(selectableItems || []);
      setSelectedSignals(
        selectableItems
          ?.map(id => {
            const signal = data?.find(item => item.id === id);
            return signal
              ? {
                  ...signal,
                  transaction_type:
                    signal.transaction_type === 'BUY' ? 'SELL' : 'BUY',
                  position_type: 'EX',
                  validity: '',
                }
              : null;
          })
          .filter(Boolean) as BotSignalParams[],
      );
    } else {
      // Deselect all
      setToggleSelection(false);

      setSelectedItems([]);
      setSelectedSignals([]);
    }
  }, [selectableItems, selectedItems, data]);

  const handleSetSelectedItems = useCallback(
    (id: number, signal: BotSignal, selectAll = false) => {
      if (selectAll && selectableItems) {
        // If selectAll is true, we handle the "select all" logic separately
        if (!selectedItems || selectedItems.length < selectableItems?.length) {
          // Select all
          setSelectedItems(selectableItems || []);
          setSelectedSignals(
            selectableItems
              ?.map(itemId => {
                const signalData = data?.find(item => item.id === itemId);
                return signalData
                  ? {
                      ...signalData,
                      transaction_type:
                        signalData.transaction_type === 'BUY' ? 'SELL' : 'BUY',
                      position_type: 'EX',
                      validity: '',
                    }
                  : null;
              })
              .filter(Boolean) as BotSignalParams[],
          );
        } else {
          // Deselect all
          setSelectedItems([]);
          setSelectedSignals([]);
        }
        return;
      }

      const items = selectedItems ? [...selectedItems] : [];
      const signals = selectedSignals ? [...selectedSignals] : [];
      const index = items.findIndex(x => x === id);
      if (index >= 0) {
        items.splice(index, 1);
        signals.splice(index, 1);
      } else {
        items.push(id);
        signals.push({
          ...signal,
          transaction_type: signal.transaction_type === 'BUY' ? 'SELL' : 'BUY',
          position_type: 'EX',
          validity: '',
        });
      }
      setSelectedItems(items);
      setSelectedSignals(signals);
    },
    [selectedItems, selectedSignals, selectableItems, data],
  );
  const handleSignalClick = useCallback(
    (signal: BotSignal) => {
      handleSetSelectedItems(signal.id, signal);
    },
    [handleSetSelectedItems],
  );

  useEffect(() => {
    if (data) {
      setSelectableItems(
        data?.filter(item => item.position_type === 'EN').map(item => item.id),
      );
    }
  }, [data]);
  // const handleClickSelectAll=()=>{

  // }
  return (
    <>
      <Helmet>
        <title>Bot Signals</title>
        <meta name="description" content="Bot Signals" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${decodeUrlData(title)}`}
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={0}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.signals') },
              { label: t('buttons.todays_position') },
              { label: t('buttons.todays_order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_SIGNALS}
            data={{ sid, botId, botName: title }}
          />
        }
      />

      {data && botDetails && (
        <>
          <Box sx={{ mt: 1, textAlign: 'right' }} className="p-main-spacing">
            {isSelectedSignalExit && (
              <MuiButton
                variant="contained"
                onClick={() =>
                  handleClickSelectAll?.() as VoidFunction | undefined
                }
                sx={{ mx: 1 }}
              >
                {!toggleSelection ? 'Select All' : 'Cancel'}
              </MuiButton>
            )}
            <MuiButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenModal(true)}
            >
              Test Signal
            </MuiButton>
            {selectedItems && selectedItems.length > 0 && (
              <MuiLoadingButton
                variant="contained"
                endIcon={<LogoutIcon />}
                onClick={handleClickExit}
                loading={mutation.isLoading}
                sx={{ mx: 1 }}
              >
                <FormattedMessage id="buttons.exit" />
              </MuiLoadingButton>
            )}
          </Box>
          <SignalListLayout
            exchanges={botDetails.exchanges}
            data={data}
            page={page}
            totalPages={data && data.length ? data[0].total_pages : 0}
            handleChangePagination={handleChangePagination}
            handleSelectSignal={handleSelectSignal}
            instrumentLoading={instrumentLoading}
            handleClickSelectSignal={handleSignalClick}
            selectedItems={selectedItems}
            selectableItems={selectableItems}
            handleClickSelectAll={handleClickSelectAll}
          />

          <BotGenerateSignalModal
            isModify={isModify}
            exchanges={botDetails.exchanges}
            open={openModal}
            handleClose={handleCloseModal}
            botId={botId}
            signal={selectedSignal}
            isDebugMode={isDebugMode}
            setInstrumentLoading={setInstrumentLoading}
          />
        </>
      )}
    </>
  );
}
