import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import MuiButton from 'app/design/uiComponents/MuiButton';
import BotArchiveModal from 'app/components/Bots/BotDetail/BotArchiveModal';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import BotGenerateDuplicateModal from './BotGenerateDuplicateModal';

const BotArchiveAction = () => {
  const { bid, sid } = useParams<{ bid: string; sid: string }>();
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);

  const [openStopModal, setOpenStopModal] = useState<boolean>(false);
  const [openGenerateDuplicateBotModal, setOpenGenerateDuplicateBotModal] =
    useState<boolean>(false);

  const { data: botDetails, refetch } = useGetBotDetails(parseInt(bid), subId);

  const handleStopModalOpen = useCallback(() => {
    setOpenStopModal(true);
  }, [setOpenStopModal]);

  const handleStopModalClose = useCallback(
    (isSuccess: boolean) => {
      if (isSuccess) void refetch();
      setOpenStopModal(false);
    },
    [setOpenStopModal, refetch],
  );

  const handleGenerateDuplicateBotModalOpen = useCallback(() => {
    setOpenGenerateDuplicateBotModal(true);
  }, [setOpenGenerateDuplicateBotModal]);

  const handleGenerateDuplicateBotModalClose = useCallback(
    (isSuccess: boolean) => {
      if (isSuccess) void refetch();
      setOpenGenerateDuplicateBotModal(false);
    },
    [setOpenGenerateDuplicateBotModal, refetch],
  );

  if (!botDetails || !botDetails.is_own_bot) return <></>;
  return (
    <>
      <FeatureFlag features={[FEATURES.IS_DUPLICATE_BOT]} fallback={<></>}>
        <MuiButton
          variant="outlined"
          fullWidth
          sx={{ maxWidth: { md: '192px' } }}
          onClick={handleGenerateDuplicateBotModalOpen}
        >
          <FormattedMessage id="buttons.duplicate_bot" />
        </MuiButton>
        <BotGenerateDuplicateModal
          open={openGenerateDuplicateBotModal}
          bid={parseInt(bid)}
          handleClose={handleGenerateDuplicateBotModalClose}
          category={botDetails.category}
        />
      </FeatureFlag>
      <FeatureFlag features={[FEATURES.IS_DELETE_BOT]} fallback={<></>}>
        <MuiButton
          variant="outlined"
          color="error"
          fullWidth
          sx={{ maxWidth: { md: '192px' } }}
          onClick={handleStopModalOpen}
        >
          <FormattedMessage id="buttons.archive_bot" />
        </MuiButton>
        <BotArchiveModal
          open={openStopModal}
          bid={parseInt(bid)}
          botName={botDetails.name}
          handleClose={handleStopModalClose}
          category={botDetails.category}
        />
      </FeatureFlag>
    </>
  );
};

export default BotArchiveAction;
