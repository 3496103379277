import { PublicOff } from '@mui/icons-material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import usePostPortfolioShare from 'services/Portfolio/usePostPortfolioShare';
import { getQueryParam } from 'utils/GenericFunctions';

const UnShareButton = () => {
  const isMdDown = useGetMediaQueryDown('max');
  const mutation = usePostPortfolioShare();
  const query = getQueryParam('date');

  return (
    <MuiButton
      fullWidth={isMdDown ? true : false}
      variant="contained"
      onClick={() => mutation.mutate({ action: 'unshare', shared_date: query })}
      startIcon={<PublicOff />}
    >
      <FormattedMessage id="portfolio.public.unshare" />
    </MuiButton>
  );
};

export default UnShareButton;
