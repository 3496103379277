import { memo } from 'react';
import { useQuery } from 'react-query';
import { GetMarketplacEquityGraph } from 'services/apiServices';
import { GetMarketplacEquityGraphResult, Errors } from 'types/ApiServicesTypes';
import BotHistoryGraph from 'app/components/Common/Charts/BotHistoryGraph';
import { Box } from '@mui/material';
import { useIsFeatureFlag } from '../../FeatureFlag';
import { FEATURES } from 'types/Feature';

interface Props {
  id: number;
  baseApiUrl: string;
  minHeight?: string;
}

const PortfolioGraph = (props: Props) => {
  const { minHeight, baseApiUrl } = props;
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  // Api calling for fetch graph data
  const { data: portfolioGraphData } = useQuery<
    GetMarketplacEquityGraphResult,
    Errors
  >(['GetMarketplacEquityGraph', baseApiUrl], () =>
    GetMarketplacEquityGraph(baseApiUrl, !isChargesAndProfit).then(res => {
      return res;
    }),
  );

  return (
    <>
      {portfolioGraphData && portfolioGraphData.length >= 5 && (
        <Box sx={{ minHeight: minHeight }}>
          <BotHistoryGraph
            data={portfolioGraphData || []}
            minHeight={minHeight}
          />
        </Box>
      )}
    </>
  );
};

export default memo(PortfolioGraph);
