import { Typography, Box } from '@mui/material';
import { FieldData } from './fields/types';
import { FieldKeyToField } from './fields/fieldsData';
import { Condition } from './types';
import { v4 } from 'uuid';
import { formatTimeForIndicatorTextView } from 'utils/GenericFunctions';

interface Props {
  condition: Condition;
  index: number;
}

export function ConditionText(props: Props) {
  const { condition, index } = props;

  const getConditionTextDesc = (data: FieldData) => {
    const field = FieldKeyToField()[data.key];
    let fieldText = '';
    if (field && field.conditionTextDesc) {
      fieldText = field.conditionTextDesc;
      if (data.type === 'mathfunctions') {
        if (data.key === 'min' || data.key === 'max') {
          let param1String = '';
          const param1 = data.params['param1'] as FieldData[];
          param1.forEach((item: FieldData) => {
            param1String += getConditionTextDesc(item);
          });
          let param2String = '';
          const param2 = data.params['param2'] as FieldData[];
          param2.forEach((item: FieldData) => {
            param2String += getConditionTextDesc(item);
          });
          fieldText = fieldText.replace(`%param1%`, param1String);
          fieldText = fieldText.replace(`%param2%`, param2String);
        } else if (
          data.key === 'abs' ||
          data.key === 'floor' ||
          data.key === 'ceil'
        ) {
          let param1String = '';
          const param1 = data.params['param1'] as FieldData[];
          param1.forEach((item: FieldData) => {
            param1String += getConditionTextDesc(item);
          });
          fieldText = fieldText.replace(`%param1%`, param1String);
        } else {
          let param1String = '';
          const param1 = data.params['param1'] as FieldData[];
          param1.forEach((item: FieldData) => {
            param1String += getConditionTextDesc(item);
          });
          fieldText = fieldText.replace(`%param1%`, param1String);
          fieldText = fieldText.replace(
            `%period%`,
            data.params['period'] as string,
          );
        }
      } else {
        fieldText = getConditionTextForIndicators(data);
      }
    }
    return fieldText;
  };

  const getConditionTextForIndicators = (data: FieldData) => {
    let fieldText = '';
    if (
      FieldKeyToField()[data.key] &&
      FieldKeyToField()[data.key].conditionTextDesc
    ) {
      fieldText = FieldKeyToField()[data.key].conditionTextDesc;
      if (fieldText && data.params) {
        const paramsKeys = Object.keys(data.params);
        paramsKeys.forEach(key => {
          if (key === 'resolution')
            fieldText = fieldText.replace(
              `%${key.toLowerCase()}%`,
              formatTimeForIndicatorTextView(data.params[key] as string),
            );
          fieldText = fieldText.replace(
            `%${key.toLowerCase()}%`,
            data.params[key] as string,
          );
        });
      }
    }
    return fieldText;
  };

  const getInnerHTML = (data: FieldData) => {
    const key = '__html';
    return { [key]: getConditionTextDesc(data) };
  };

  return (
    <Box>
      <Box
        sx={{
          border: '1px dashed rgba(224, 224, 224, 1)',
          borderRadius: '8px',
        }}
        padding={'8px'}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '14px' }}
        >
          <Typography variant="body2" color="text.secondary" mr={'10px'}>
            {index}.
          </Typography>
          <Typography variant="body3" color="text.disabled">
            Condition
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {condition.fields.map((field: FieldData) => {
            return (
              <Typography
                variant="body2"
                key={v4()}
                mr={'5px'}
                color="text.secondary"
              >
                <span dangerouslySetInnerHTML={getInnerHTML(field)}></span>
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
