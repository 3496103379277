import { Link as RouterLink, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box, Divider, Grid, Typography } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import GreyBgCard from 'app/design/uiComponents/BoxGrey';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { formatNumber } from 'utils/GenericFunctions';
import SubscriptionFeesInfo from 'app/components/Bots/SellOnMarket/SubscriptionFeesInfo';
import { ChevronRightOutlined } from '@mui/icons-material';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import ShareSellOnMarketBot from 'app/components/Bots/SellOnMarket/ShareSellOnMarketBot';

// Services
// import useGetBreadcrumbUrls from 'services/common/useGetBreadcrumbUrls';

export function SellOnMarketPage() {
  const history = useHistory();
  const { id, title } = useParams<{ id: string; title: string }>();

  return (
    <>
      <Helmet>
        <title>Sell On Discover</title>
        <meta name="description" content="Sell On Discover Page" />
      </Helmet>
      <CircularLoader open={false} />

      <Box>
        <PageHeader
          variant="inactive"
          buttonText={'Sell On Discover'}
          title={
            <FormattedMessage id="sell_on_market.page_title.sell_on_market" />
          }
          buttonHandleClick={() => history.goBack()}
        />
      </Box>
      <Box sx={{ mt: 1 }} className="m-main-spacing">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GreyBgCard padding={1.5}>
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                {12}
              </Typography>
              <Typography
                variant="body2"
                fontWeight="400"
                color="text.secondary"
              >
                <FormattedMessage id="sell_on_market.subscribers" />
              </Typography>
            </GreyBgCard>
          </Grid>
          <Grid item xs={6}>
            <GreyBgCard padding={1.5}>
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                ₹{formatNumber(1200)}
              </Typography>
              <Typography
                variant="body2"
                fontWeight="400"
                color="text.secondary"
              >
                <FormattedMessage id="sell_on_market.revenue" />
              </Typography>
            </GreyBgCard>
          </Grid>
        </Grid>
      </Box>
      <Box className="m-main-spacing" sx={{ mt: 2, mb: 3 }}>
        <ShareSellOnMarketBot sharePath={location.href} />
      </Box>
      <Box className="m-main-spacing">
        <SubscriptionFeesInfo
          fees={'250'}
          visibility={'public'}
          infoMessage={
            <FormattedMessage id="sell_on_market.bot_is_available_to_everyone" />
          }
        />
      </Box>
      <Box className="m-main-spacing">
        <Box
          sx={{
            my: 3,
            borderRadius: 1,
            overflow: 'hidden',
          }}
        >
          <MuiButton
            color="secondary"
            size="xxlarge"
            variant="contained"
            fullWidth
            sx={{
              justifyContent: 'space-between',
              borderRadius: 0,
            }}
            endIcon={<ChevronRightOutlined />}
            component={RouterLink}
            to={`/bots/${id}/sell-on-discover/edit/${title}/edit`}
          >
            <FormattedMessage id="sell_on_market.change_fees_and_visibility" />
          </MuiButton>
          <Divider sx={{ borderColor: 'grey.300' }} />
          <MuiButton
            color="secondary"
            size="xxlarge"
            variant="contained"
            fullWidth
            sx={{
              justifyContent: 'space-between',
              borderRadius: 0,
            }}
            endIcon={<ChevronRightOutlined />}
            component={RouterLink}
            to={`/bots/${id}/sell-on-discover/${title}/billing-details`}
          >
            <FormattedMessage id="sell_on_market.billing" />
          </MuiButton>
        </Box>
        <Box>
          <MuiLoadingButton
            fullWidth
            variant="outlined"
            color="error"
            loading={false}
            loadingPosition="start"
          >
            <FormattedMessage id="buttons.stop_selling" />
          </MuiLoadingButton>
        </Box>
      </Box>
    </>
  );
}
