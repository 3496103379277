import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import StrategyBuilder from '../../components/Builder/Strategy/StrategyBuilder';
import { Condition } from '../../components/Builder/Strategy/types';
import { FieldData } from '../../components/Builder/Strategy/fields/types';

import { StrategyMode } from '../../components/Builder/Strategy/utils/strategy-data';

export function StrategyBuilderPage() {
  const [longEntryConditions, setLongEntryConditions] = useState<FieldData[]>(
    [],
  );
  const [mode, setMode] = useState<string>(StrategyMode.EDIT);
  const [copiedStrategy, setCopiedStrategy] = React.useState<Condition>();
  const setConditions = (conditions: FieldData[]) => {
    setLongEntryConditions(conditions);
  };

  const updateCopiedStrategy = (copiedStrategy: Condition) => {
    setCopiedStrategy(copiedStrategy);
  };

  const switchMode = (mode: string) => {
    setMode(mode);
  };

  return (
    <>
      <Helmet>
        <title>Create Strategy</title>
        <meta name="description" content="Create Strategy Page" />
      </Helmet>
      <Box margin={'20px 0px 20px 30px'}>
        <button onClick={() => switchMode(StrategyMode.EDIT)}>EDIT</button>
        <button onClick={() => switchMode(StrategyMode.VIEW)}>VIEW</button>
        <button onClick={() => switchMode(StrategyMode.TEXT)}>TEXT</button>
      </Box>
      <Box ml={'30px'}>
        <StrategyBuilder
          setStrategy={setConditions}
          inputConditions={longEntryConditions}
          updateCopiedStrategy={updateCopiedStrategy}
          copiedStrategy={copiedStrategy}
          mode={mode}
          isValid={true}
        />
      </Box>
    </>
  );
}
