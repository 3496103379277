import { Fragment } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { Instrument } from 'types/Instrument';

export default function StrategyBuilderSymbolItem({
  symbols,
  deleteSymbol,
}: {
  symbols: Instrument[];
  deleteSymbol: (id: number) => void;
}) {
  return (
    <>
      <Box>
        {symbols.map((symbol, i) => (
          <Fragment key={i}>
            <Box display="flex" alignItems="center" sx={{ py: 1.5 }}>
              <Box flex="1">
                <Typography variant="body2" sx={{ pb: 0.5 }}>
                  {symbol.trading_symbol}
                </Typography>
                <Typography
                  variant="body3"
                  color="text.disabled"
                  component="div"
                >
                  {symbol.name}
                </Typography>
              </Box>
              <IconButton onClick={() => deleteSymbol(i)}>
                <DeleteIcon />
              </IconButton>
              {/* <ItemMenu id={i} handleDelete={deleteSymbol} /> */}
            </Box>
            {symbols.length - 1 !== i && <ItemSeparator variant="1px" />}
          </Fragment>
        ))}
      </Box>
    </>
  );
}
