import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, TextField, Typography, FormHelperText } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// service
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import useForgotPass from 'services/Auth/useForgotPass';
import { ForgotPassPageProps, ResetPageProps } from './SignInModal';
import SpeedBotLogo from '../Common/SpeedBotLogo';

export function ResetConfirmCodePage({
  propsData,
  onAuthPageChange,
  getDataForReset,
}: {
  propsData: ForgotPassPageProps;
  onAuthPageChange: (view: string) => void;
  getDataForReset: (data: ResetPageProps) => void;
}) {
  const otptimerDefault = 120;
  const { code, emailOrPhone, type } = propsData;

  // const { ccode, phone, source } =
  //   useParams<{ ccode: string; phone: string; source?: string }>();

  const [otptimer, setOtptimer] = useState(otptimerDefault);
  const [otpSent, setOtpSent] = useState(true);

  const forgotPasswordMutation = useForgotPass();
  const handleSignInClick = () => {
    onAuthPageChange('Sign In');
  };
  const handleResetPassClick = () => {
    onAuthPageChange('Reset Password');
  };
  const handleBackClick = () => {
    onAuthPageChange('Forgot Password');
  };
  useEffect(() => {
    if (otptimer > 0) {
      setTimeout(() => setOtptimer(otptimer - 1), 1000);
    } else {
      setOtpSent(false);
      setOtptimer(0);
    }
  }, [otptimer]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Verify Phone Page</title>
        <meta name="description" content="Signup verify phone page" />
      </Helmet>
      <CircularLoader
        open={
          forgotPasswordMutation.isLoading || forgotPasswordMutation.isLoading
        }
      />
      <SigninPageContentLayout>
        {/* Must have max two child element */}
        <Box>
          <SpeedBotLogo />
          <Box sx={{ mb: 4, paddingInline: 2.5 }}>
            <MuiButton
              variant="text"
              startIcon={<ArrowBackIcon />}
              // to={undefined}
              size="small"
              color="primary"
              onClick={() => handleBackClick()}
              // component={RouterLink}
              sx={{ fontWeight: 600, px: '0 !important' }}
            >
              Back
            </MuiButton>
          </Box>
          <Typography
            variant="body1"
            color="primary.dark"
            sx={{
              my: 3,
              textAlign: 'center',
              maxWidth: '22rem',
              margin: '0 auto',
            }}
          >
            <FormattedMessage
              id={
                type === 'phone' && code !== '0'
                  ? 'sent_you_code_phone'
                  : 'sent_you_code_email'
              }
            />
          </Typography>
          <Formik
            initialValues={{
              otp: '',
            }}
            validationSchema={Yup.object().shape({
              otp: Yup.string().required('Confirmation otp is required.'),
            })}
            onSubmit={values => {
              forgotPasswordMutation.mutate(
                {
                  action: 'validate',
                  auth_type:
                    type === 'email' && code === '0' ? 'email' : 'mobile',
                  country_code: code,
                  email: emailOrPhone,
                  mobile: emailOrPhone,
                  otp: values.otp,
                },
                {
                  onSuccess: res => {
                    if (res.success) {
                      // history.push(
                      //   `/forgotpass/resetpass/${code}/${emailOrPhone}/${values.otp}`,
                      // );
                      getDataForReset({
                        code: code,
                        emailOrPhone: emailOrPhone,
                        otp: values.otp,
                      });
                      handleResetPassClick();
                    }
                  },
                },
              );
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                id="reset_pass_confirm_code"
                sx={{ mt: 2, maxWidth: '20rem', margin: '0 auto' }}
              >
                <Typography variant="body2" sx={{ fontWeight: 400, my: 2 }}>
                  <FormattedMessage id="enter_code_to_verify_account" />
                </Typography>
                <TextField
                  fullWidth
                  label="Verification code"
                  type="password"
                  name="otp"
                  autoComplete="on"
                  error={Boolean(touched.otp && errors.otp)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.otp && errors.otp && (
                  <FormHelperText error>{errors.otp}</FormHelperText>
                )}
                {forgotPasswordMutation.data &&
                  forgotPasswordMutation.data.error && (
                    <FormHelperText error>
                      {forgotPasswordMutation.data.error}
                    </FormHelperText>
                  )}
                <Box sx={{ mt: 2.5 }}>
                  {otpSent ? (
                    <Typography variant="caption">
                      Retry in {otptimer} Sec(s)
                    </Typography>
                  ) : (
                    <MuiLoadingButton
                      fullWidth
                      size="small"
                      variant="text"
                      sx={{ px: '0 !important' }}
                      onClick={() => {
                        setOtpSent(true);
                        setOtptimer(otptimerDefault);
                        forgotPasswordMutation.mutate({
                          action: 'send',
                          country_code: code,
                          mobile: emailOrPhone,
                          email: emailOrPhone,
                          auth_type: type,
                        });
                      }}
                      loading={forgotPasswordMutation.isLoading}
                      loadingPosition="start"
                    >
                      <FormattedMessage id="did_not_receive_code" />
                    </MuiLoadingButton>
                  )}
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="primary"
            fullWidth={true}
            form="reset_pass_confirm_code"
            type="submit"
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="next" />
          </MuiButton>
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="secondary"
            fullWidth={true}
            // component={RouterLink}
            // to={undefined}
            onClick={() => handleSignInClick()}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="cancel" />
          </MuiButton>
        </Box>
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default ResetConfirmCodePage;
