import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useTranslation } from 'react-i18next';
import { BrokerFormBrokerData } from 'types/ApiServicesTypes';

const Image = styled('img')({
  width: '3.25rem',
  height: '2.25rem',
  objectFit: 'contain',
});

function BrokerItem({ name, image_url, is_auth }: BrokerFormBrokerData) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        gap: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {image_url && <Image src={image_url} />}
      <Typography variant="body1">{name}</Typography>
      {is_auth && (
        <MuiChip
          sx={{ ml: 'auto' }}
          label={t('menu.broker.daily_login_required')}
          size="xsmall"
          color="infolight"
          variant="filled"
        />
      )}
    </Box>
  );
}

export default BrokerItem;
