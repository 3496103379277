import { memo } from 'react';
import { Box } from '@mui/material';

// Service
import { TodaysPositionsProps } from 'types/Position';
import { createClosePositionTableRows } from './utils';
import CommonTable from 'app/components/Common/CommonTable';

const PortfolioClosePositionsTable = ({
  positions,
  handlePositionClick,
}: TodaysPositionsProps) => {
  const { rows, columns } = createClosePositionTableRows(
    positions,
    handlePositionClick,
  );

  return (
    <>
      {positions.length > 0 && (
        <Box sx={{ px: 3, mx: 1 }}>
          <CommonTable rows={rows} columns={columns} />
        </Box>
      )}
    </>
  );
};

export default memo(PortfolioClosePositionsTable);
