import { Box, IconButton } from '@mui/material';
import { ScheduledEvent } from 'types/ApiServicesTypes';
import MuiButton from 'app/design/uiComponents/MuiButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { addEventToCookies } from './utils';
import { openInNewTab } from 'utils/GenericFunctions';
import useReportUserAcitivity from 'services/UserActivity/useReportUserActivity';
import { UserActivityTypes } from 'services/UserActivity/types';

export default function EventItem({
  event: scheduledEvent,
  handleCloseEventModal,
  companyName,
}: {
  companyName?: string;
  event: ScheduledEvent;
  handleCloseEventModal: () => void;
}) {
  const { handleReportActivity } = useReportUserAcitivity(
    UserActivityTypes.EVENT_VIEW,
    { event_id: scheduledEvent.id.toString() },
    2,
    false,
  );
  const handleRemindMe = () => {
    const dt = new Date();
    dt.setHours(dt.getHours() + 1);
    addEventToCookies({ id: scheduledEvent.id, expired: dt });
    handleCloseEventModal();
  };
  const handleClickNoted = () => {
    const dt = new Date();
    dt.setDate(dt.getDate() + 7);
    addEventToCookies({ id: scheduledEvent.id, expired: dt });
    handleReportActivity();
    handleCloseEventModal();
  };
  const handleClickEvent = () => {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() + 1);
    addEventToCookies({ id: scheduledEvent.id, expired: dt });
    handleReportActivity();
    handleCloseEventModal();
  };

  return (
    <>
      <IconButton
        onClick={handleCloseEventModal}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <CloseIcon />
      </IconButton>
      {scheduledEvent.eventURL &&
      !scheduledEvent.eventURL.startsWith('http') ? (
        <RouterLink
          to={scheduledEvent.eventURL}
          onClick={() => {
            handleClickEvent();
          }}
        >
          <img
            src={scheduledEvent.imageURL}
            alt={companyName}
            style={{ width: '100%', height: 'auto' }}
          />
        </RouterLink>
      ) : scheduledEvent.eventURL &&
        scheduledEvent.eventURL.startsWith('http') ? (
        <Box
          onClick={() => {
            handleClickEvent();
            openInNewTab(scheduledEvent.eventURL);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <img
            src={scheduledEvent.imageURL}
            alt={companyName}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
      ) : (
        <img
          src={scheduledEvent.imageURL}
          alt={companyName}
          style={{ width: '100%', height: 'auto' }}
        />
      )}
      <Box sx={{ p: 1, display: 'flex' }}>
        <MuiButton
          fullWidth
          sx={{ mr: 2, whiteSpace: 'nowrap' }}
          onClick={handleRemindMe}
        >
          <FormattedMessage id="remind_me_later" />
        </MuiButton>
        <MuiButton
          fullWidth
          onClick={handleClickNoted}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <FormattedMessage id="i_noted" />
        </MuiButton>
      </Box>
    </>
  );
}
