import { Theme } from '@mui/material';
import MuiButton from './MuiButton';

const MuiToggleButton = ({
  value,
  label,
  isSelected,
  setValue,
  autoWidth,
}: {
  value: string;
  label: string;
  isSelected: boolean;
  setValue: (val: string) => void;
  autoWidth?: boolean;
}) => {
  return (
    <MuiButton
      onClick={() => setValue(value)}
      variant="outlined"
      color="secondary"
      sx={{
        flex: 1,
        color: 'primary.main',
        borderColor: (theme: Theme) =>
          isSelected ? `${theme.palette.primary.main} !important` : 'grey.200',
        ...(autoWidth && { maxWidth: { xs: 'inherit', md: 'fit-content' } }),
      }}
    >
      {label}
    </MuiButton>
  );
};

export default MuiToggleButton;
