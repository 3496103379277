import React, { useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
  Alert,
  Divider,
  Button,
  ButtonGroup,
} from '@mui/material';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { getCookie } from 'typescript-cookie';
import * as Yup from 'yup';
import { Formik } from 'formik';
// import ReCAPTCHA from 'react-google-recaptcha';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { getQueryParam } from 'utils/GenericFunctions';
// import { GoogleLogin } from 'react-google-login';
import Google from 'assets/images/icons/social-google.svg';
// import { AuthValues, Values, Captch, Props } from 'types/SigninPageTypes';

// service
import { SESSION_ID, USER_NAME } from 'constants/common';
import { useDoSingIn } from 'services/Auth';
import useAuthenticateDefault from 'services/Auth/useAuthenticateDefualt';
import useAuthenticateWithGoogle from 'services/Auth/useAuthenticateWithGoogle';
import useAuthenticate from 'services/Auth/useAuthenticate';
import { useTranslation } from 'react-i18next';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useGoogleLogin } from '@react-oauth/google';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import { useAppleAuthentication, useIsGoogleAuthEnabled } from 'utils/auth';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import AppleIcon from '@mui/icons-material/Apple';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import {
  IndianFlagSvg,
  USAFlagSvg,
} from '../../components/SvgComponents/SvgComponents';
import { useTheme } from '@mui/material/styles';

export function SigninPage({
  onAuthPageChange,
}: {
  onAuthPageChange: (view: string) => void;
}) {
  const { t } = useTranslation();
  // const getModalParam = getQueryParam('modal');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const theme = useTheme();
  const ref = getQueryParam('ref');
  const { doSignIn } = useDoSingIn(ref);

  const { data: stiteInfo } = useGetSiteInfo();

  // handle toggle show/hide password texts
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const history = useHistory();
  const routeChange = (url: string, value: string) => {
    const path = `${url}?modal=${value}`;
    // history.push(path);
    window.open(path, '_self', 'noopener,noreferrer');
  };
  const sessionId: string = getCookie(SESSION_ID) as string;
  let userName: string = (getCookie(USER_NAME) as string) || '';
  if (!userName) {
    userName = '';
  }

  // Api calling for activation of user account

  // Api calling for auto signin
  const mutation_authenticate = useAuthenticateDefault(sessionId);

  // Api calling for signin with google
  const mutation_authenticate_google = useAuthenticateWithGoogle();

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      mutation_authenticate_google.mutate(
        {
          access_token: tokenResponse.access_token,
          email: '',
          expires_in: tokenResponse.expires_in.toString(),
          scope: tokenResponse.scope,
          token_type: tokenResponse.token_type,
        },
        {
          onSuccess: res => {
            if (res) {
              if (res.session_id) {
                doSignIn();
              }
            }
          },
        },
      );
    },
  });
  const isMobileNumberLogin = useIsFeatureFlag([
    FEATURES.IS_MOBILE_NUMBER_LOGIN,
  ]);
  const handleGoogleLogin = useCallback(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('googleSignIn');
    } else {
      googleLogin();
    }
  }, [googleLogin]);

  // Api calling for signin
  const mutation = useAuthenticate();

  // useEffect(() => {
  //   if (props.token && props.login) {
  //     mutation_activation.mutate();
  //   }
  //   if (sessionId && userName) {
  //     mutation_authenticate.mutate(void '', {
  //       onSuccess: res => {
  //         if (res) {
  //           doSignIn({ uid: res.user_id } as UserInfo);
  //         }
  //       },
  //     });
  //   }
  // }, [
  //   props,
  //   sessionId,
  //   userName,
  //   mutation_activation,
  //   mutation_authenticate,
  //   doSignIn,
  // ]);

  // useEffect(() => {
  //   showAlert!(props.location?.state?.msg as string, 'success');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.location?.state?.msg]);
  const checkUrlConatiansIndAndUsa =
    // location.href.includes('app' || 'localhost') &&
    stiteInfo?.name === 'SpeedBot'
      ? 'india'
      : stiteInfo?.name === 'SpeedBot US'
      ? 'usa'
      : 'india';
  const checkMarketIsIndiaOrUsa =
    // (location.href.includes('app' || 'localhost') &&
    stiteInfo?.name === 'SpeedBot' ||
    // (location.href.includes('us') &&
    stiteInfo?.name === 'SpeedBot US';
  const [market, setMarket] = useState(checkUrlConatiansIndAndUsa);
  const handleMarketChange = (newMarket: string) => {
    if (newMarket !== null) {
      setMarket(newMarket);
    }
  };
  const isChooseYourMarket = useIsFeatureFlag([FEATURES.IS_CHOOSE_YOUR_MARKET]);
  const isGoogleAuthEnabled = useIsGoogleAuthEnabled();
  const { isShowAppleLogin, handleAppleLogin } = useAppleAuthentication();
  const showOAuthIcons = isGoogleAuthEnabled && isShowAppleLogin;

  const isMdUp = useGetMediaQueryUp('md');
  const isLoading =
    mutation_authenticate.isLoading ||
    mutation_authenticate_google.isLoading ||
    mutation.isLoading;
  const handleSignUpclick = () => {
    onAuthPageChange('Sign Up');
  };
  const handleForgotClick = () => {
    onAuthPageChange('Forgot Password');
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Signin Page</title>
        <meta name="description" content="Signin page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      {stiteInfo && stiteInfo.signin_screen && (
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          {!isMdUp && (
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              {stiteInfo && stiteInfo.logo && (
                <RouterLink to="/">
                  <img src={stiteInfo.logo} />
                </RouterLink>
              )}
            </Box>
          )}
          {/* {stiteInfo.signin_screen.top_subtitle_1 && (
            <Typography sx={{ mb: 1 }} variant="body2">
              {stiteInfo.signin_screen.top_subtitle_1}
            </Typography>
          )}
          {stiteInfo.signin_screen.top_subtitle_2 && (
            <Typography sx={{ mb: 1 }} variant="body2">
              {stiteInfo.signin_screen.top_subtitle_2}
            </Typography>
          )} */}
        </Box>
      )}
      <SigninPageContentLayout autoHeight>
        {/* Must have max two child element */}
        <Box alignSelf="center">
          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mt: 4, textAlign: 'center' }}
          >
            {stiteInfo && stiteInfo.name
              ? t('sign_in_to_speedbot', {
                  seteName: stiteInfo.name,
                })
              : t('sign_in')}
          </Typography>
          <Formik
            initialValues={{
              userid: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              userid: Yup.string().max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={(values, actions) => {
              // if (isVerifiedCaptcha.data === '') {
              //   setCaptchaError('Please confirm that you are not a robot');
              // } else {
              // setLogin(values.userid);
              mutation.mutate(values, {
                onSuccess: res => {
                  if (!res.error) {
                    // Log entry for init app
                    logFirebaseEventEntry('APP_LOGIN_SUCCESS', res);

                    doSignIn(res);
                  }
                },
              });
              // }
              actions.setSubmitting(false);
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 2, maxWidth: '20rem', marginX: { md: 'auto' } }}
              >
                {mutation.data && mutation.data.error ? (
                  <Box sx={{ mb: 2 }}>
                    {/* {mutation.data.error === 'Something went wrong' ? (
                      <Alert severity="error">
                        <FormattedMessage id="unable_to_connect" />
                        {mutation.data.error}
                      </Alert>
                    ) : mutation.data.error.includes('does not') ? (
                      <Alert severity="error">
                        <FormattedMessage id="unable_to_connect" />
                        {mutation.data.error}
                      </Alert>
                    ) : mutation.data.error === 'Access denied' ? (
                      <Alert severity="error">
                        <FormattedMessage id="invalid_email" />
                        {mutation.data.error}
                      </Alert>
                    ) : mutation.data.error.includes(
                        'AccessDeniedForInactiveUser',
                      ) ? (
                      <Alert severity="error">
                        <FormattedMessage id="confirm_email_to_activate_account" />
                        {mutation.data.error}
                        <RouterLink
                          to={`/check-mail-invitaion?login=${Login}&token=${isVerifiedCaptcha.data}`}
                        >
                          <FormattedMessage id="resend_invitation" />
                        </RouterLink>
                      </Alert>
                    ) : mutation.data.error.includes('InvalidCaptcha:') ? (
                      <Alert severity="error">
                        <FormattedMessage id="captach" />
                        {mutation.data.error}
                      </Alert>
                    ) : mutation.data.error.includes('CaptchaTimeOut') ? (
                      <Alert severity="error">
                        <FormattedMessage id="captcha_again" />
                        {mutation.data.error}
                      </Alert> 
                    ) : null}  */}
                    <Alert severity="error">
                      {/* <FormattedMessage id="unable_to_connect" /> */}
                      {mutation.data.error}
                    </Alert>
                  </Box>
                ) : null}
                {/* <Button onClick={routeChange}>USA</Button> */}
                {checkMarketIsIndiaOrUsa && isChooseYourMarket && (
                  <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
                    <Typography
                      variant="overline"
                      sx={{ marginBottom: 2, fontWeight: '700' }}
                    >
                      Choose your market
                    </Typography>
                    <ButtonGroup
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: 44,
                        marginBottom: 4,
                      }}
                    >
                      <Button
                        variant="contained"
                        color={market === 'india' ? 'primary' : 'inherit'}
                        onClick={() => {
                          routeChange(
                            'https://app.speedbot.tech/home',
                            'india',
                          );
                          handleMarketChange('india');
                        }}
                        sx={{
                          border: '0.5px solid black',

                          borderRadius: '1rem',
                          padding: '12px 24px',
                          transition: 'all 0.4s ease',
                          boxShadow:
                            market === 'india'
                              ? '0 4px 14px rgba(0, 0, 0, 0.2)'
                              : 'none',
                          backgroundColor:
                            market === 'india'
                              ? theme.palette.common.black
                              : theme.palette.common.white,
                          color:
                            market === 'india'
                              ? theme.palette.common.white
                              : theme.palette.common.black,
                          '&:hover': {
                            backgroundColor:
                              market === 'india'
                                ? theme.palette.custom.hoverOnDark
                                : theme.palette.custom.hoverOnLight,
                            transform:
                              market === 'india' ? 'scale(1.05)' : 'none',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            mr: 1,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <IndianFlagSvg />
                        </Box>
                        India
                      </Button>
                      <Button
                        variant="contained"
                        color={market === 'usa' ? 'primary' : 'inherit'}
                        onClick={() => {
                          routeChange('https://us.speedbot.tech/home', 'usa');
                          handleMarketChange('usa');
                        }}
                        sx={{
                          border: '0.5px solid black',

                          borderRadius: '1rem',
                          padding: '12px 24px',
                          transition: 'all 0.4s ease',
                          boxShadow:
                            market === 'usa'
                              ? '0 4px 14px rgba(0, 0, 0, 0.2)'
                              : 'none',
                          backgroundColor:
                            market === 'usa'
                              ? theme.palette.common.black
                              : theme.palette.common.white,
                          color:
                            market === 'usa'
                              ? theme.palette.common.white
                              : theme.palette.common.black,
                          marginLeft: 2,
                          '&:hover': {
                            backgroundColor:
                              market === 'usa'
                                ? theme.palette.custom.hoverOnDark
                                : theme.palette.custom.hoverOnLight,
                            transform:
                              market === 'usa' ? 'scale(1.05)' : 'none',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            mr: 1,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <USAFlagSvg />
                        </Box>
                        USA
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                <TextField
                  fullWidth
                  error={Boolean(touched.userid && errors.userid)}
                  variant="outlined"
                  type="text"
                  name="userid"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label={isMobileNumberLogin ? 'Phone or email' : 'Email'}
                />
                {touched.userid && errors.userid && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-userid-login"
                  >
                    {errors.userid}
                  </FormHelperText>
                )}
                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  <InputLabel htmlFor="outlined-adornment-password-login">
                    <FormattedMessage id="password_title" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-login"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <Box>
                    <ReCAPTCHA
                      sitekey="6LdS6JIdAAAAAEHnW6o3sxKWBEHxjOA8mqIBfo8h"
                      onChange={recaptcha}
                      size="normal"
                    />
                    <div style={{ color: 'red' }}>{captchaError}</div>
                  </Box>
                </Box> */}
                <MuiButton
                  className="loginflowBtn"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 4 }}
                  disabled={isLoading}
                  type="submit"
                >
                  <FormattedMessage id="sign_in" />
                </MuiButton>
              </Box>
            )}
          </Formik>
          <Box sx={{ mt: 1, textAlign: 'right' }}>
            <MuiButton
              className="loginflowBtn"
              size="small"
              variant="text"
              onClick={() => handleForgotClick()}
            >
              <FormattedMessage id="forgot_password_popup" />
            </MuiButton>
          </Box>
          {(isGoogleAuthEnabled || isShowAppleLogin) && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
                <Typography
                  variant="overline"
                  sx={{ mx: 1, textTransform: 'lowercase' }}
                >
                  <FormattedMessage id="or_sign_in_using" />
                </Typography>
                <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
              </Box>
              <Box mt={showOAuthIcons ? 0 : 2} textAlign={'center'}>
                {isGoogleAuthEnabled && !isShowAppleLogin && (
                  <MuiButton
                    className="loginflowBtn"
                    size="large"
                    variant="outlined"
                    color="primary"
                    sx={{ borderColor: 'primary.main', mt: 2 }}
                    fullWidth={true}
                    startIcon={<img src={Google} alt="google" />}
                    onClick={handleGoogleLogin}
                  >
                    <FormattedMessage id="sign_in_with_google" />
                  </MuiButton>
                )}
                {isGoogleAuthEnabled && isShowAppleLogin && (
                  <IconButton size="large" onClick={handleGoogleLogin}>
                    <img src={Google} alt="google" />
                  </IconButton>
                )}
                {isShowAppleLogin && (
                  <IconButton
                    size="large"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={handleAppleLogin}
                  >
                    <AppleIcon />
                  </IconButton>
                )}
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: showOAuthIcons || !isGoogleAuthEnabled ? 2 : 4,
            }}
          >
            <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
          </Box>
          <Typography variant="body1" color="primary.dark" sx={{ mt: 3 }}>
            <FormattedMessage id="signup_link" />
          </Typography>
          <Box sx={{ mt: 2 }}>
            <MuiButton
              to
              className="loginflowBtn"
              size="large"
              variant="outlined"
              color="primary"
              component={RouterLink}
              // to={undefined}
              // process.env.IS_GOOGLE_USER_ENABLE
              //   ? `/signup?ref=${ref}`
              //   : `/signup/start?ref=${ref}`

              sx={{ borderColor: 'primary.main' }}
              fullWidth={true}
              onClick={() => handleSignUpclick()}
            >
              <FormattedMessage id="sign_up" />
            </MuiButton>
          </Box>
          {stiteInfo && stiteInfo.signin_screen && (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              {stiteInfo.signin_screen.bottom_subtitle_1 && (
                <Box
                  display="flex"
                  sx={{ mb: 2 }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body2">
                    {stiteInfo.signin_screen.bottom_subtitle_1.label}
                  </Typography>
                  <MuiButton
                    href={stiteInfo.signin_screen.bottom_subtitle_1.button_link}
                    target="_blank"
                    variant="contained"
                    color="secondary"
                    sx={{ ml: 1 }}
                    size="small"
                  >
                    {stiteInfo.signin_screen.bottom_subtitle_1.button_label}
                  </MuiButton>
                </Box>
              )}
              {stiteInfo.signin_screen.bottom_subtitle_2 && (
                <Typography
                  variant="body2"
                  sx={{ mb: 1 }}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: stiteInfo.signin_screen.bottom_subtitle_2,
                  }}
                ></Typography>
              )}
            </Box>
          )}
        </Box>
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default SigninPage;
