import { Box, Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PositionMetaData } from '../OptionChain/types';
import { OptionChainWithExpiryType } from 'app/pages/Simulator';
import { calaculatePopFunction } from './utils';
import { useEffect, useState } from 'react';
const FinancialSummary = ({
  optionChainData,
  maxProfitLoss,
  positionData,
  totalMTM,
  lotSize,
}: {
  optionChainData: OptionChainWithExpiryType;
  totalMTM: number;
  maxProfitLoss: {
    max_profit: string | number;
    max_loss: string | number;
    breakevenPoints: number[];
  };
  lotSize: number;
  positionData: PositionMetaData[];
}) => {
  // let pop = 0;
  const [pop, setPop] = useState<number>(0);

  // eslint-disable-next-line no-console
  // console.info('this is financial summary', financialPositionData);
  const { delta, gamma, theta, vega } = getGreeks(positionData);
  // const calculatedGreeks = new PortfolioGreeksCalculator(positionData);
  // const financialGreeks = calculatedGreeks.calculateGreeks();
  const greeksData = [
    { label: 'Delta', value: delta },
    { label: 'Gamma', value: gamma },
    { label: 'Theta', value: theta * lotSize },
    { label: 'Vega', value: vega * lotSize },
  ];
  // eslint-disable-next-line no-console
  // console.log('this is financial greeks', financialGreeks);
  useEffect(() => {
    if (
      maxProfitLoss &&
      maxProfitLoss.breakevenPoints.length > 0 &&
      Object.keys(optionChainData).length
    ) {
      setPop(() =>
        calaculatePopFunction(
          optionChainData,
          maxProfitLoss.breakevenPoints,
          maxProfitLoss.max_profit,
          maxProfitLoss.max_loss,
        ),
      );
    }
  }, [maxProfitLoss, optionChainData, pop]);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          px: 2,
          mb: 1,
          // borderBottom: 1,
          // borderColor: 'divider',
          // borderRadius: '1',
        }}
      >
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption" color="text.primary">
              Total MTM
            </Typography>
            <Typography
              variant="body3"
              color="success.main"
              fontWeight={'bold'}
            >
              ₹{totalMTM.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption" color="text.primary">
              Max Profit
            </Typography>
            <Typography variant="body3" fontWeight="bold" color="success.main">
              ₹
              {Math.abs(maxProfitLoss.max_profit as number) === Infinity
                ? 'Unlimited'
                : Number(maxProfitLoss.max_profit).toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption" color="text.primary">
              Max Loss
            </Typography>
            <Typography variant="body3" fontWeight="bold" color="error.main">
              ₹
              {Math.abs(maxProfitLoss.max_loss as number) === Infinity
                ? 'Unlimited'
                : Number(maxProfitLoss.max_loss).toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={3}>
          <Box
            display="flex"
            flexDirection={{ xs: 'row', md: 'column' }}
            gap={2}
            borderBottom={{ xs: 1, md: 0 }}
            borderColor="divider"
            pb={{ xs: 2, md: 0 }}
          >
            <Typography variant="caption" color="text.primary">
              Margin Approx
            </Typography>
            <Typography variant="body3" fontWeight="bold">
              ₹ 42,398
            </Typography>
          </Box>
        </Grid> */}
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption" color="text.primary">
              POP
            </Typography>
            <Typography variant="body3" fontWeight="bold">
              {pop}%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption" color="text.primary">
              Risk/Reward
            </Typography>
            <Typography variant="body3" fontWeight="bold">
              {Math.round(
                Math.abs(
                  Number(maxProfitLoss.max_loss) /
                    Number(maxProfitLoss.max_profit),
                ),
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption" color="text.primary">
              Breakeven
            </Typography>
            <Box display="flex" gap={2} overflow="auto">
              {maxProfitLoss.breakevenPoints.map((point, index) => (
                <Typography
                  key={index}
                  variant="body3"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {point}
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: { xs: 'none', md: 'grid' },
          gridTemplateColumns: 'repeat(5, 1fr)',
          borderTop: '1px solid rgb(0 0 0 / 23%)',
          p: 1,
          //   backgroundColor: 'white',
          //   px: 4,
          //   textAlign: 'left',
        }}
      >
        <Grid container spacing={1} sx={{ gridColumn: 'span 3' }}>
          {greeksData.slice(0, 2).map((greek, index) => (
            <Grid item xs={6} key={index}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="caption" color="text.primary">
                  {greek.label}:
                </Typography>
                <InfoOutlinedIcon fontSize="small" color="primary" />
                <Typography variant="caption">
                  {greek.value.toFixed(2)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1} sx={{ gridColumn: 'span 2' }}>
          {greeksData.slice(2).map((greek, index) => (
            <Grid item xs={6} key={index}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="caption" color="text.primary">
                  {greek.label}:
                </Typography>
                <InfoOutlinedIcon fontSize="small" color="primary" />
                <Typography variant="caption">
                  {greek.value.toFixed(2)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default FinancialSummary;
// export {};

const getGreeks = (positions: PositionMetaData[]) => {
  let delta = 0;
  let gamma = 0;
  let theta = 0;
  let vega = 0;

  positions.map(position => {
    if (position.positionCandleWise) {
      Object.values(position.positionCandleWise).map(positionData => {
        delta += positionData.contractDetails.delta;
        gamma += positionData.contractDetails.gamma;
        theta += positionData.contractDetails.theta;
        vega += positionData.contractDetails.vega;
      });
    }
  });

  return { delta, gamma, theta, vega };
};
