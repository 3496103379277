import { Table, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MuiTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const MuiTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-head': {
    borderTop: `2px solid ${theme.palette.grey[100]}`,
    borderBottom: `2px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    wordBreak: 'initial',
  },
  '& .MuiTableCell-body': {
    borderColor: theme.palette.grey[100],
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    wordBreak: 'initial',
  },
  '& .MuiTableBody-root>.MuiTableRow-root:last-child>.MuiTableCell-root': {
    border: 'none',
  },
}));

export default MuiTable;
