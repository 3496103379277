import { Box, Grid } from '@mui/material';
import PortfolioSymbolItem from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioSymbolItem';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useGetTickData } from 'app/hooks/useGetTickData';
import { useMemo, memo } from 'react';
import { useGetPortfolio } from 'services/Portfolio/useGetPortfolioActivePaper';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import SummaryCard from './SummaryCard';

function PortfolioSummary() {
  const isLgUp = useGetMediaQueryUp('lg');
  const { data: siteInfo } = useGetSiteInfo();
  const { data: portfolioData } = useGetPortfolio();

  const portfolioSymbols = useMemo(() => {
    return (
      (siteInfo && siteInfo.portfolio_symbols?.slice(0, isLgUp ? 3 : 2)) || []
    );
  }, [siteInfo, isLgUp]);

  const symbolsWithNifty = useMemo(
    () => [
      'NIFTY_SYMBOL',
      'BANK_NIFTY_SYMBOL',
      'FIN_NIFTY_SYMBOL',
      ...portfolioSymbols.map(i => i.value),
    ],
    [portfolioSymbols],
  );
  const liveFeedData = useGetTickData(symbolsWithNifty);
  const showSummary = portfolioData?.active.bots.length && siteInfo;
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} lg={showSummary ? 6 : 12}>
        <Box sx={{ border: '1px solid #9898983d', borderRadius: '0.5em' }}>
          <Box>
            <Box
              display={'flex'}
              alignItems="center"
              sx={{
                p: 2,
                height: 80,
                justifyContent: { xs: 'space-between' },
              }}
            >
              {portfolioSymbols &&
                portfolioSymbols.map((symbol, i) => (
                  <Box
                    key={symbol.value}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      minHeight: '50px',
                    }}
                  >
                    <PortfolioSymbolItem
                      home
                      liveFeedData={liveFeedData}
                      symbol={symbol}
                      isLast={i === portfolioSymbols.length - 1}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Grid>
      {!!showSummary && (
        <Grid item xs={12} lg={6}>
          {portfolioData && (
            <SummaryCard portfolioData={portfolioData} siteInfo={siteInfo} />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default memo(PortfolioSummary);
