import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { PostTicketMessagedata } from 'services/apiServices';
import {
  Errors,
  PostTicketMessageData,
  PostTicketMessageDataResponse,
} from 'types/ApiServicesTypes';

export const usePostTicketMessageData = (id: number) => {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostTicketMessageDataResponse,
    Errors,
    Pick<PostTicketMessageData, 'message' | 'attachments' | 'action'>
  >('PostTicketMessageDatas', value =>
    PostTicketMessagedata(value.message, id, value.attachments, value.action)
      .then(res => {
        if (!res || res.errors)
          showAlert!(res.errors ? res.errors[0] : 'Something went wrong. ');
        else if (res.success) {
          showAlert!(
            res.success ? 'Your Request has Been Registered' : '',
            'success',
          );
        }
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
};
