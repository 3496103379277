import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// Services
import {
  formatDateString,
  decodeUrlData,
  ifFutureDate,
} from 'utils/GenericFunctions';
import PageHeader from 'app/design/speedBot/PageHeader';
import Statistics from 'app/components/Marketplace/Statistics';
import useGetBotHistoryDetail from 'services/Bots/useGetBotHistoryDetail';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';

export function BotHistoryDetailPage() {
  const breadcrumb = useBreadcrumbManager();
  const { bid, sid, hsid, title } = useParams<{
    bid: string;
    sid: string;
    hsid: string;
    title: string;
  }>();
  const history = useHistory();
  const { t } = useTranslation();

  const { data: botHistoryDetailData, isLoading } = useGetBotHistoryDetail(
    bid,
    hsid,
  );

  const fromDate = botHistoryDetailData
    ? formatDateString(botHistoryDetailData.start_date, 'DD MMM') +
      ' ' +
      (formatDateString(botHistoryDetailData.start_date, 'YYYY') !==
      formatDateString(botHistoryDetailData.end_date, 'YYYY')
        ? formatDateString(botHistoryDetailData.start_date, 'YYYY')
        : '')
    : '';
  const toDate = botHistoryDetailData
    ? formatDateString(botHistoryDetailData.end_date, 'DD MMM YYYY')
    : '';

  return (
    <>
      <Helmet>
        <title>Bot History Detail</title>
        <meta name="description" content="Bot History Detail Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        buttonText={`${t('bots.page_title.history')} ${
          title ? '| ' + decodeUrlData(title) : ''
        }`}
        title={
          botHistoryDetailData ? (
            <>
              <Typography color="text.secondary" variant="h6" component="span">
                {fromDate} <ArrowForwardIcon />
              </Typography>{' '}
              {ifFutureDate(toDate)}
            </>
          ) : (
            <></>
          )
        }
        buttonHandleClick={() => history.goBack()}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_HISTORY_DETAIL}
            data={{
              subId: sid,
              botId: bid,
              botName: title,
              historySubId: hsid,
            }}
          />
        }
      />
      {botHistoryDetailData && (
        <Statistics
          id={parseInt(bid)}
          source="HISTORY"
          subId={hsid}
          baseApiUrl={`bots/${bid}/history/${hsid}`}
          title={title}
          positionsOnClick={() =>
            breadcrumb.push(
              `/bots/${bid}/history/${sid}/positions/${hsid}/${title}/${decodeUrlData(
                fromDate + ' → ' + ifFutureDate(toDate),
              )}`,
              PAGES.BOT_HISTORY_DETAIL,
              `/bots/${bid}/history/${sid}/${hsid}/${title}`,
              decodeUrlData(fromDate + ' → ' + ifFutureDate(toDate)),
            )
          }
          ordersOnClick={() =>
            breadcrumb.push(
              `/bots/${bid}/history/${sid}/orders/${hsid}/${title}/${decodeUrlData(
                fromDate + ' → ' + ifFutureDate(toDate),
              )}`,
              PAGES.BOT_HISTORY_DETAIL,
              `/bots/${bid}/history/${sid}/${hsid}/${title}`,
              decodeUrlData(fromDate + ' → ' + ifFutureDate(toDate)),
            )
          }
          symbolsOnClick={() =>
            breadcrumb.push(
              `/bots/${bid}/history/${sid}/symbols/${hsid}/${title}/${decodeUrlData(
                fromDate + ' → ' + ifFutureDate(toDate),
              )}`,
              PAGES.BOT_HISTORY_DETAIL,
              `/bots/${bid}/history/${sid}/${hsid}/${title}`,
              decodeUrlData(fromDate + ' → ' + ifFutureDate(toDate)),
            )
          }
          profitLossOnClick={() =>
            breadcrumb.push(
              `/bots/${bid}/history/${sid}/profitloss/${hsid}/${title}/${decodeUrlData(
                fromDate + ' → ' + ifFutureDate(toDate),
              )}`,
              PAGES.PORTFOLIO_DETAIL_STATISTIC,
              `/bots/${bid}/history/${sid}/${hsid}/${title}`,
              decodeUrlData(fromDate + ' → ' + ifFutureDate(toDate)),
            )
          }
        />
      )}
    </>
  );
}
