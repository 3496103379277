import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import SubscriptionFeesInfo from './SubscriptionFeesInfo';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SellOnMarketConfirmModal from './SellOnMarketConfirmModal';
import SellOnMarketEditConfirmModal from './SellOnMarketEditConfirmModal';

export type SellOnMarketFormData = {
  fees: string;
  visibility: string;
};

export default function SellOnMarketForm({
  mode,
  id,
  botName,
}: {
  mode: 'add' | 'edit';
  id: number;
  botName: string;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [confirmModalData, setConfirmModalData] =
    useState<SellOnMarketFormData | null>(null);

  const handleCloseConfirmModal = () => {
    setConfirmModalData(null);
    history.push(`/bots/${id}/sell-on-discover/${botName}`);
  };

  const handleOpenConfirmModal = (values: SellOnMarketFormData) => {
    setConfirmModalData(values);
  };

  return (
    <>
      <Formik
        initialValues={{
          fees: '',
          visibility: 'public',
        }}
        validationSchema={Yup.object().shape({
          fees: Yup.number()
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('sell_on_market.your_subscription_fees'),
              }),
            )
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('sell_on_market.your_subscription_fees'),
              }),
            ),
        })}
        onSubmit={values => {
          handleOpenConfirmModal(values);
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          isValid,
        }) => (
          <Box
            className="m-main-spacing"
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <CircularLoader open={false} />
            <Box sx={{ mb: 2, flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.fees && errors.fees)}
                    sx={{ mb: 2 }}
                  >
                    <InputLabel htmlFor="outlined-adornment-fees">
                      <FormattedMessage id="sell_on_market.your_subscription_fees" />
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-fees"
                      value={values.fees}
                      type="number"
                      onChange={e => setFieldValue('fees', e.target.value)}
                      onBlur={handleBlur}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                      label={t('sell_on_market.your_subscription_fees')}
                      inputProps={{ min: 0 }}
                    />
                    {touched.fees && errors.fees && (
                      <FormHelperText error>{errors.fees}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <SubscriptionFeesInfo fees={values.fees} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="label-visibility">
                      <FormattedMessage id="sell_on_market.visibility" />
                    </InputLabel>
                    <Select
                      labelId="label-visibility"
                      name="visibility"
                      value={values.visibility}
                      label={
                        <FormattedMessage id="sell_on_market.visibility" />
                      }
                      onChange={e => {
                        setFieldValue('visibility', e.target.value);
                      }}
                    >
                      <MenuItem value="public">
                        <FormattedMessage id="sell_on_market.public" />{' '}
                        <PublicIcon />
                      </MenuItem>
                      <MenuItem value="private">
                        <FormattedMessage id="sell_on_market.private" />{' '}
                        <PersonIcon />
                      </MenuItem>
                      <MenuItem value="private">
                        <FormattedMessage id="sell_on_market.invisible" />{' '}
                        <VisibilityOffIcon />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight="400"
              >
                <FormattedMessage id="sell_on_market.bot_is_available_to_everyone" />{' '}
              </Typography>
            </Box>
            <Box sx={{ mb: 0 }}>
              <MuiButton
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                sx={{ mb: 2 }}
                disabled={!isValid}
              >
                <FormattedMessage
                  id={mode === 'add' ? 'buttons.publish' : 'buttons.update2'}
                />
              </MuiButton>
              <MuiButton
                variant="contained"
                color="secondary"
                fullWidth
                onClick={onclose}
              >
                <FormattedMessage id="buttons.cancel" />
              </MuiButton>
            </Box>
          </Box>
        )}
      </Formik>

      {mode === 'edit' ? (
        <SellOnMarketEditConfirmModal
          fees={confirmModalData ? Number(confirmModalData.fees) : 0}
          visibility={confirmModalData ? confirmModalData.visibility : ''}
          open={confirmModalData ? true : false}
          handleClose={handleCloseConfirmModal}
        />
      ) : (
        <SellOnMarketConfirmModal
          fees={confirmModalData ? Number(confirmModalData.fees) : 0}
          visibility={confirmModalData ? confirmModalData.visibility : ''}
          open={confirmModalData ? true : false}
          handleClose={handleCloseConfirmModal}
          id={id}
          botName={botName}
        />
      )}
    </>
  );
}
