import {
  Button,
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useState, useRef, useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  OptionFutureLegFieldsGroupWise,
  OptionLegFieldsGroupWise,
} from './FieldsData';
import {
  FieldParam,
  OptionLegParam,
  FieldParamValue,
  OptionSetParam,
  CopiedStrategyParam,
  OptionLegExecutionParam,
} from './types';
import { createEmptyOptionLeg, generateOptionValues } from './Utils/utils';
import { cloneDeep } from 'lodash';
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { v4 } from 'uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MuiBuilderButton from 'app/design/uiComponents/MuiBuilderButton';
import { useTranslation } from 'react-i18next';

import { FieldDataConditions } from 'app/components/Builder/Strategy/fields/types';

import IndicatorConditonsModal from 'app/components/Bots/Strategy/IndicatorConditionsModal';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { PositionCondition } from 'app/components/Bots/Strategy/PositionDirectionConditions';
import { StrategyMode } from '../../Builder/Strategy/utils/strategy-data';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { signal } from '@preact/signals-react';
import ExecutionSettingModal, {
  ExecutionSettingWrapper,
} from 'app/components/Bots/Strategy/ExecutionSettingModal';
import MuiChip from 'app/design/uiComponents/MuiChip';
import BetaChip from '../BetaChip';
interface Props {
  optionLeg: OptionLegParam;
  legIndex: number;
  strategyIndex: number;
  strategyName: string;
  onChangeOptionLeg: (index: number, optionLeg: OptionLegParam) => void;
  optionSet: OptionSetParam;
  onDeleteLeg: (index: number) => void;
  PlValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  handleCopyStrategy: (
    strategyName: string,
    strategyData: OptionLegParam,
  ) => void;
  handlePasteStrategy: (
    strategyName: string,
    optionSetIndex?: number,
    optionLegIndex?: number,
    optionHedgeIndex?: number,
  ) => void;
  copiedOptionStrategy: CopiedStrategyParam;
  isLegNameExist: (name: string, index: number) => boolean;
  hasIndicators: (val: boolean) => void;
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '200px',
          fontSize: '16px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          top: '7px',
        },
        shrink: {
          top: '0px',
        },
      },
    },
  },
});
export const entry = signal<boolean>(false);
export function OptionLeg(props: Props) {
  const {
    optionLeg,
    legIndex,
    onChangeOptionLeg,
    optionSet,
    onDeleteLeg,
    PlValidation,
    isReadOnly,
    strategyIndex,
    strategyName,
    handleCopyStrategy,
    handlePasteStrategy,
    copiedOptionStrategy,
    hasIndicators,
  } = props;
  const validationDataRef = useRef({});
  const isAddHedgeButtonAvailable = useIsFeatureFlag([
    FEATURES.IS_ADD_HEDGE_ENABLED,
  ]);
  const isConditionInOption = useIsFeatureFlag([
    FEATURES.IS_CONDITION_IN_OPTION,
  ]);
  const isIndicatorAtleg = useIsFeatureFlag([FEATURES.IS_INDICATOR_AT_LEG]);
  const isExecutionSetting = useIsFeatureFlag([FEATURES.IS_EXECUTION_SETTING]);

  const { t } = useTranslation();

  const conditions = optionLeg.indicator_extras as FieldDataConditions;

  const [indicatorModal, setIndicatorModal] = useState<boolean>(false);
  const [executionSettingModal, setExecutionSettingModal] =
    useState<boolean>(false);
  const [entryOnUnderlying] = useState<boolean | undefined>(
    conditions.entry_on_underlying,
  );
  const [exitOnUnderlying] = useState<boolean | undefined>(
    conditions.exit_on_underlying,
  );

  const [formikValue, setFormikValue] =
    useState<Partial<FieldDataConditions>>(conditions);
  // const formik = useFormik({
  //   initialValues: {
  //     indicator_extras: optionLeg.indicator_extras
  //       ? (optionLeg.indicator_extras as FieldDataWithValidate)
  //       : initialFormikState,
  //   },
  //   onSubmit: values => {
  //     alert(JSON.stringify(values, null, 2));
  //   },
  // });
  delete formikValue.isConditionValid;
  useEffect(() => {
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      PlValidation(
        `lag${legIndex}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      PlValidation(`lag${legIndex}`, true);
    }
  }, [validationDataRef, PlValidation, legIndex]);

  const onClickAddHedgeLeg = () => {
    const reduceWith =
      optionLeg.legEnabled == 'futureleg' || optionLeg.legEnabled == 'equityleg'
        ? OptionFutureLegFieldsGroupWise
        : OptionLegFieldsGroupWise;
    const { hedge_legs } = optionLeg;
    (hedge_legs as OptionLegParam[]).push(
      createEmptyOptionLeg(
        reduceWith,
        (hedge_legs as OptionLegParam[]).length,
        optionLeg['legEnabled'] as string,
      ),
    );
    const fieldDataValidation = reduceWith.reduce((acc, crr) => {
      acc = { ...acc, [crr.parentKey]: crr.validation };
      return acc;
    }, {});
    (optionLeg.hedge_legs as OptionLegParam[]).map(
      (leg: OptionLegParam, i: number, dataArray: OptionLegParam[]) => {
        const returnVal = Object.keys(leg).map(item => {
          if (fieldDataValidation[item]) {
            if (fieldDataValidation[item].isRequired) {
              if (leg[item]) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return true;
          }
        });
        hedgeValidtion(
          `hedge${dataArray.length === 1 ? -1 : i}`,
          !returnVal.includes(false),
        );
      },
    );
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      PlValidation(
        `lag${legIndex}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      PlValidation(`lag${legIndex}`, true);
    }
    onChangeOptionLeg(
      legIndex,
      cloneDeep({
        ...optionLeg,
        indicator_extras: {
          ...formikValue,
          entry_on_underlying: entryOnUnderlying ? entryOnUnderlying : false,
          exit_on_underlying: exitOnUnderlying ? exitOnUnderlying : false,
        },
      }),
    );
  };
  const onChangeMainLeg = (
    i: number,
    key: string,
    value: Record<string, FieldParamValue>,
  ) => {
    if (
      key === 'parent_re_entry' &&
      optionSet['parent_square_off_all_legs']['square_off_all_legs'] === 'true'
    )
      value = {
        reentry_count: 0,
        reentry_type: 'none',
        toggleStatus: true,
      };

    optionLeg[key] = value;
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      PlValidation(
        `lag${legIndex}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      PlValidation(`lag${legIndex}`, true);
    }
    onChangeOptionLeg(
      legIndex,
      cloneDeep({
        ...optionLeg,
        indicator_extras: {
          ...formikValue,
          entry_on_underlying: entryOnUnderlying ? entryOnUnderlying : false,
          exit_on_underlying: exitOnUnderlying ? exitOnUnderlying : false,
        },
      }),
    );
  };

  const onChangeHedgeLeg = (
    i: number,
    key: string,
    value: Record<string, FieldParamValue>,
  ) => {
    const { hedge_legs } = optionLeg;
    hedge_legs[i][key] = value;
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      PlValidation(
        `lag${legIndex}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      PlValidation(`lag${legIndex}`, true);
    }
    onChangeOptionLeg(
      legIndex,
      cloneDeep({
        ...optionLeg,
        indicator_extras: {
          ...formikValue,
          entry_on_underlying: entryOnUnderlying ? entryOnUnderlying : false,
          exit_on_underlying: exitOnUnderlying ? exitOnUnderlying : false,
        },
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendValidation = (i: number, key: string, isValid: boolean) => {
    // code
  };

  const [isEditName, setEditName] = useState(false);
  const onChangeLegName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    props.optionLeg.name = value;
  };
  const onKeyPressLegName = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setEditName(false);
      onChangeOptionLeg(
        legIndex,
        cloneDeep({
          ...optionLeg,
          indicator_extras: {
            ...formikValue,
            entry_on_underlying: entryOnUnderlying ? entryOnUnderlying : false,
            exit_on_underlying: exitOnUnderlying ? exitOnUnderlying : false,
          },
        }),
      );
    }
  };
  const onBlurLetName = () => {
    setEditName(false);
    onChangeOptionLeg(
      legIndex,
      cloneDeep({
        ...optionLeg,
        indicator_extras: {
          ...formikValue,
          entry_on_underlying: entryOnUnderlying ? entryOnUnderlying : false,
          exit_on_underlying: exitOnUnderlying ? exitOnUnderlying : false,
        },
      }),
    );
  };

  const onClickDeleteLeg = (legIndex: number) => {
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      delete validationDataRef.current[`hedge${legIndex}`];
      Object.keys(validationDataRef.current).forEach(elem => {
        if (elem.includes('hedge')) {
          const elemIndex = parseInt(elem.split('hedge')[1]);
          if (elemIndex && !isNaN(elemIndex) && legIndex < elemIndex) {
            validationDataRef.current[`hedge${elemIndex - 1}`] =
              validationDataRef.current[elem] as boolean;
            delete validationDataRef.current[elem];
          }
        }
      });
    }
    if (legIndex >= 0) {
      onDeleteLeg(legIndex);
    } else {
      if (
        validationDataRef.current &&
        Object.keys(validationDataRef.current).length > 0
      ) {
        PlValidation(
          `lag${legIndex}`,
          !Object.values(validationDataRef.current).includes(false),
        );
      } else {
        PlValidation(`lag${legIndex}`, true);
      }
      (optionLeg.hedge_legs as OptionLegParam[]).splice(legIndex, 1);
      onChangeOptionLeg(
        legIndex,
        cloneDeep({
          ...optionLeg,
          indicator_extras: {
            ...formikValue,
            entry_on_underlying: entryOnUnderlying ? entryOnUnderlying : false,
            exit_on_underlying: exitOnUnderlying ? exitOnUnderlying : false,
          },
        }),
      );
    }
  };

  const hedgeValidtion = (key: string, isValid: boolean) => {
    validationDataRef.current = {
      ...validationDataRef.current,
      [key]: isValid,
    };
  };
  useEffect(() => {
    if (
      formikValue &&
      formikValue.entry_conditions &&
      formikValue.exit_conditions
    ) {
      void hasIndicators(
        conditions.entry_conditions.length > 0 ||
          conditions.exit_conditions.length > 0 ||
          formikValue.entry_conditions.length > 0 ||
          formikValue.exit_conditions.length > 0,
      );
    }
  }, [conditions, hasIndicators, formikValue]);
  const isErrorExistName = useMemo(() => {
    return props.isLegNameExist(optionLeg.name as string, legIndex);
  }, [legIndex, optionLeg.name, props]);
  // eslint-disable-next-line no-console
  // console.log({ ...optionLeg, indicator_extras: formik.values });
  const optionsetPosition = (
    optionSet['parent_position_type'] as OptionSetParam
  ).position_type as string;
  return (
    <>
      <Box
        style={{
          border: '1px dashed #ababab',
          padding: '24px',
          marginLeft: '0px',
          borderRadius: '10px',
          position: 'relative',
          marginBottom: '24px',
          ...(isErrorExistName && {
            borderColor: 'error.main',
          }),
        }}
        className="option-leg-parent"
      >
        <Box sx={{ mb: 3 }}>
          <MuiChip
            color="secondary"
            sx={{ marginBottom: 1 }}
            size="xsmall"
            label={
              optionLeg['legEnabled'] === 'futureleg'
                ? 'Future'
                : optionLeg['legEnabled'] == 'equityleg'
                ? 'Equity'
                : 'Option'
            }
          />
          <Box
            style={{
              position: 'relative',
              left: '0px',
              top: '0px',
              width: 'auto',
              paddingLeft: '0px',
              paddingRight: '0px',
              display: 'flex',
              alignItems: 'center',
            }}
            className="edit-hover"
          >
            <span
              style={{
                fontSize: '16px',
                lineHeight: '32px',
                // color: 'rgba(0, 0, 0, 0.6)',
                marginRight: '8px',
              }}
            >
              {`${(strategyIndex + 1).toString()}.${(legIndex + 1).toString()}`}
            </span>
            {isEditName ? (
              <ThemeProvider theme={theme}>
                <FormControl variant="outlined" className="comman-input">
                  <TextField
                    label="Leg Name"
                    defaultValue={props.optionLeg.name || ''}
                    variant="outlined"
                    size={'small'}
                    onChange={e => onChangeLegName(e)}
                    onKeyPress={e => onKeyPressLegName(e)}
                    onBlur={onBlurLetName}
                    disabled={isReadOnly}
                    autoFocus={true}
                    inputProps={{ color: theme.palette.text.secondary }}
                    sx={{ color: theme.palette.text.secondary }}
                  />
                </FormControl>
              </ThemeProvider>
            ) : (
              <>
                <span
                  style={{
                    fontSize: '16px',
                    marginTop: '0px',
                    lineHeight: '32px',
                    // color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                  {`${strategyName}, ${props.optionLeg.name}`}
                </span>
                {isReadOnly ? (
                  <></>
                ) : (
                  <Button
                    onClick={() => setEditName(s => !s)}
                    variant="outlined"
                    style={{
                      width: '24px',
                      minWidth: '24px',
                      height: '24px',
                      padding: '0px',
                      fontSize: '10px',
                      marginLeft: '5px',
                      border: 'none',
                    }}
                    disabled={isReadOnly}
                  >
                    <EditIcon
                      style={{
                        fontSize: '14px',
                      }}
                    />
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box>
          <OptionHedgeLeg
            optionLeg={props.optionLeg}
            onChange={onChangeMainLeg}
            index={legIndex}
            optionSet={optionSet}
            onDeleteLeg={onClickDeleteLeg}
            sendValidation={sendValidation}
            PlValidation={hedgeValidtion}
            isReadOnly={isReadOnly}
            setEditName={setEditName}
            copiedOptionStrategy={copiedOptionStrategy}
            handleCopyStrategy={(strategyLegData: OptionLegParam) => {
              handleCopyStrategy('leg', strategyLegData);
            }}
            handlePasteStrategy={() => {
              handlePasteStrategy('leg', strategyIndex, legIndex);
            }}
          />
          {isExecutionSetting &&
            (optionLeg.legEnabled == 'optionleg' ||
              optionLeg.legEnabled == undefined) && (
              <>
                {isReadOnly ? (
                  <>
                    <ExecutionSettingWrapper
                      positionType={optionsetPosition}
                      isReadonly={isReadOnly}
                      ExecutionData={optionLeg}
                      handleClose={() => {
                        //
                      }}
                      saveData={() => {
                        //
                      }}
                    />
                  </>
                ) : (
                  // <Box display={'flex'} >
                  <MuiButton
                    sx={{ left: '-5px' }}
                    size="medium"
                    onClick={() => setExecutionSettingModal(true)}
                  >
                    {optionLeg.entry_execution_settings['order_type'] !==
                      'market' ||
                    optionLeg.exit_execution_settings['order_type'] !==
                      'market' ? (
                      <EditIcon sx={{ mr: 1, p: 0 }} fontSize="small" />
                    ) : (
                      <AddCircleOutlinedIcon
                        sx={{ mr: 1, p: 0 }}
                        fontSize="small"
                      />
                    )}
                    Execution Settings
                    <BetaChip />
                  </MuiButton>
                )}
              </>
            )}
          {isIndicatorAtleg && isConditionInOption && (
            <>
              {!isReadOnly &&
                (optionLeg.legEnabled == 'optionleg' ||
                  optionLeg.legEnabled == undefined) && (
                  <MuiButton
                    sx={{ left: '-5px' }}
                    size="medium"
                    onClick={() => setIndicatorModal(true)}
                  >
                    {!conditions.entry_conditions.length ? (
                      <AddCircleOutlinedIcon
                        sx={{ mr: 1, p: 0 }}
                        fontSize="small"
                      />
                    ) : (
                      <EditIcon sx={{ mr: 1, p: 0 }} fontSize="small" />
                    )}{' '}
                    Indicators
                  </MuiButton>
                )}
              {!!conditions.entry_conditions.length && isReadOnly && (
                <PositionCondition
                  direction="indicator_condition"
                  mode={StrategyMode.TEXT}
                  // conditions={optionLeg.indicator_extras.conditions as FieldData[]}
                  conditions={conditions.entry_conditions}
                />
              )}
              {!!conditions.exit_conditions.length && isReadOnly && (
                <PositionCondition
                  direction="indicator_condition"
                  mode={StrategyMode.TEXT}
                  // conditions={optionLeg.indicator_extras.conditions as FieldData[]}
                  conditions={conditions.exit_conditions}
                />
              )}
            </>
          )}
          {isConditionInOption &&
            isIndicatorAtleg &&
            optionLeg.legEnabled == 'optionleg' && (
              <Box sx={{ ml: 1, mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isReadOnly ? true : false}
                      checked={entryOnUnderlying}
                      onChange={e =>
                        onChangeOptionLeg(
                          legIndex,
                          cloneDeep({
                            ...optionLeg,
                            indicator_extras: {
                              ...formikValue,
                              entry_on_underlying: e.target.checked,
                            },
                          }),
                        )
                      }
                      name="entry_on_underlying"
                      color="primary"
                    />
                  }
                  label="Entry on Underlying"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isReadOnly ? true : false}
                      checked={exitOnUnderlying}
                      onChange={e =>
                        onChangeOptionLeg(
                          legIndex,
                          cloneDeep({
                            ...optionLeg,
                            indicator_extras: {
                              ...formikValue,

                              exit_on_underlying: e.target.checked,
                            },
                          }),
                        )
                      }
                      name="exit_on_underlying"
                      color="primary"
                    />
                  }
                  label="Exit on Underlying"
                />
              </Box>
            )}
        </Box>

        {isAddHedgeButtonAvailable &&
        !isReadOnly &&
        optionLeg['legEnabled'] == 'optionleg' ? (
          <MuiBuilderButton
            variant="outlined"
            onClick={onClickAddHedgeLeg}
            startIcon={<AddIcon />}
          >
            Hedge leg
          </MuiBuilderButton>
        ) : (
          <></>
        )}
        {(optionLeg['legEnabled'] == 'optionleg'
          ? (optionLeg.hedge_legs as OptionLegParam[])
          : []
        ).map((leg: OptionLegParam, i: number) => {
          return (
            <Box
              style={{
                border: '1px dashed #ababab',
                padding: '72px 24px 0px',
                marginLeft: '0px',
                borderRadius: '12px',
                marginTop: '32px',
                position: 'relative',
              }}
              // key={`hedge-leg-${index}-${i}`}
              key={v4()}
              className="option-leg-parent"
            >
              <OptionHedgeLeg
                optionLeg={leg}
                onChange={onChangeHedgeLeg}
                index={i}
                optionSet={optionSet}
                onDeleteLeg={onClickDeleteLeg}
                sendValidation={sendValidation}
                PlValidation={hedgeValidtion}
                isReadOnly={isReadOnly}
                setEditName={setEditName}
                copiedOptionStrategy={copiedOptionStrategy}
                handleCopyStrategy={(strategyHedgeLegData: OptionLegParam) => {
                  handleCopyStrategy('hedge', strategyHedgeLegData);
                }}
                handlePasteStrategy={() => {
                  handlePasteStrategy('hedge', strategyIndex, legIndex, i);
                }}
              />
            </Box>
          );
        })}

        {isErrorExistName && (
          <Typography
            className="m-main-spacing"
            variant="body2"
            sx={{ color: 'error.main', fontWeight: 400 }}
          >
            Please define the unique name for each legs within set
          </Typography>
        )}
      </Box>
      {isIndicatorAtleg && (
        <IndicatorConditonsModal
          handleClose={() => setIndicatorModal(false)}
          open={indicatorModal}
          saveData={(values: FieldDataConditions) => {
            onChangeOptionLeg(
              legIndex,
              cloneDeep({
                ...optionLeg,
                indicator_extras: {
                  ...values,
                  entry_on_underlying: entryOnUnderlying
                    ? entryOnUnderlying
                    : false,
                  exit_on_underlying: exitOnUnderlying
                    ? exitOnUnderlying
                    : false,
                },
              }),
            );
            setFormikValue(values);
          }}
          title={t('user_bot_startegy.modal.leg_note')}
          error={''}
          optionData={optionLeg.indicator_extras as FieldDataConditions}
          genericMessage={'Leg Conditions'}
        />
      )}
      {isExecutionSetting && (
        <ExecutionSettingModal
          key={legIndex}
          open={executionSettingModal}
          handleClose={() => setExecutionSettingModal(false)}
          ExecutionData={optionLeg}
          isReadonly={isReadOnly}
          positionType={optionsetPosition}
          saveData={(
            value: Record<string, OptionLegExecutionParam | string>,
          ) => {
            onChangeOptionLeg(
              legIndex,
              cloneDeep({
                ...optionLeg,
                order_product_type: value.order_product_type,
                entry_execution_settings: value.entry_execution_settings,
                exit_execution_settings: value.exit_execution_settings,
              }),
            );
            setExecutionSettingModal(false);
          }}
        />
      )}
    </>
  );
}

interface HedgeProps {
  optionLeg: OptionLegParam;
  index: number;
  onChange: (
    index: number,
    key: string,
    value: Record<string, FieldParamValue>,
  ) => void;
  sendValidation: (index: number, key: string, isValid: boolean) => void;
  optionSet: OptionSetParam;
  onDeleteLeg: (index: number) => void;
  PlValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  setEditName: (editName: boolean) => void;
  copiedOptionStrategy: CopiedStrategyParam;
  handleCopyStrategy: (strategyData: OptionLegParam) => void;
  handlePasteStrategy: () => void;
}

function OptionHedgeLeg(props: HedgeProps) {
  const {
    optionLeg,
    onDeleteLeg,
    index,
    PlValidation,
    isReadOnly,
    optionSet,
    setEditName,
    copiedOptionStrategy,
    handleCopyStrategy,
    handlePasteStrategy,
  } = props;
  const validationDataRef = useRef({});
  const isReEntryReExection = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_REENTRY_REEXECUTION,
  ]);
  const isRangeBreakout = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_RANGE_BREAKOUT,
  ]);

  const isOptionBotBuilderPositionalLeg = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_POSITIONAL_LEG,
  ]);
  const isDTEenabledUSA = useIsFeatureFlag([FEATURES.IS_DTE_ENABLED_USA]);
  const isDeltaInContractType = useIsFeatureFlag([
    FEATURES.IS_DELTA_IN_CONTRACT_TYPE,
  ]);
  const isShowDeltaAndStrikeInTGSL = useIsFeatureFlag([
    FEATURES.IS_SHOW_DELTA_AND_STRIKE_IN_TGSL,
  ]);
  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(showMenu);

  useEffect(() => {
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      PlValidation(
        `hedge${index}`,
        !Object.values(validationDataRef.current).includes(false),
      );
    } else {
      PlValidation(`hedge${index}`, true);
    }
  }, [validationDataRef, PlValidation, index]);

  const sendValidation = (key: string, isValid: boolean) => {
    validationDataRef.current = {
      ...validationDataRef.current,
      [key]: isValid,
    };
  };

  const renderOptionLegFields = (groupwiseFields: FieldParam[]) => {
    const resultData = groupwiseFields.reduce(
      (
        returnData: { currData: JSX.Element[]; finalResult: JSX.Element[] },
        field: FieldParam,
        index: number,
        fieldArray: FieldParam[],
      ) => {
        field = generateOptionValues(
          field,
          optionLeg,
          optionSet,
          {},
          {
            reEntryEnabled: isReEntryReExection,
            DTEenabled: isDTEenabledUSA,
            deltaEnabled: isDeltaInContractType,
            strikeDeltaEnabled: isShowDeltaAndStrikeInTGSL,
          },
        );
        if (
          !(
            (
              ([
                'parent_range_breakout',
                'parent_breakout_on_underlying',
              ].includes(field.parentKey) &&
                !isRangeBreakout) ||
              (field.parentKey === 'parent_order_product_type' &&
                !isOptionBotBuilderPositionalLeg)
            )
            //    ||
            // (field.par entKey === 'parent_days_to_expiration' &&
            //   !isDTEenabledUSA)
          )
        ) {
          if (!field.hide) {
            returnData.currData.push(
              <field.renderer
                param={field as Omit<FieldParam, 'renderer' | 'type'>}
                onChange={(
                  key: string,
                  value: Record<string, FieldParamValue>,
                ) => {
                  if (
                    validationDataRef.current &&
                    Object.keys(validationDataRef.current).length > 0
                  ) {
                    PlValidation(
                      `hedge${props.index}`,
                      !Object.values(validationDataRef.current).includes(false),
                    );
                  } else {
                    PlValidation(`hedge${props.index}`, true);
                  }
                  props.onChange(props.index, key, value);
                }}
                key={`hedge-leg-field-${props.index}-${field.parentKey}`}
                selected={
                  optionLeg[field.parentKey] as Record<string, FieldParamValue>
                }
                isReadOnly={isReadOnly}
                // sendValidation={(key: string, isValid: boolean) =>
                //   props.sendValidation(props.index, key, isValid)
                // }
                sendValidation={sendValidation}
                optionLeg={optionLeg}
              />,
            );
          }
        }
        if (
          (fieldArray[index + 1] &&
            fieldArray[index + 1].group !== field.group) ||
          index === fieldArray.length - 1
        ) {
          returnData.finalResult.push(
            <Grid
              container
              columnSpacing={2}
              spacing={2}
              key={`hedge-leg-grid-${props.index}-${field.parentKey}`}
            >
              {returnData.currData}
            </Grid>,
          );
          returnData.currData = [];
        }
        return returnData;
      },
      { currData: [], finalResult: [] },
    );
    return resultData.finalResult;
  };

  const options = useMemo(() => {
    return [
      {
        text: 'Copy',
        icon: <ContentCopy fontSize="small" />,
        handler: () => {
          handleCopyStrategy(optionLeg);
        },
      },
      {
        text: 'Replace with paste',
        icon: <ContentPaste fontSize="small" />,
        handler: () => {
          handlePasteStrategy();
        },
      },
      {
        text: 'Rename',
        icon: <DriveFileRenameOutlineIcon fontSize="small" />,
        handler: () => setEditName(true),
      },
      {
        text: 'Delete',
        icon: <DeleteIcon fontSize="small" />,
        handler: index => onDeleteLeg(index as number),
      },
    ];
  }, [
    onDeleteLeg,
    setEditName,
    handleCopyStrategy,
    handlePasteStrategy,
    optionLeg,
  ]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setShowMenu(null);
  };

  return (
    <>
      {isReadOnly ? (
        <></>
      ) : (
        <>
          <IconButton
            style={{
              width: '24px',
              minWidth: '24px',
              height: '24px',
              fontSize: '16px',
              border: 'none',
              position: 'absolute',
              right: '24px',
              top: '24px',
            }}
            disabled={isReadOnly}
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Paper sx={{ width: 320, maxWidth: '100%' }}>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={showMenu}
              open={open}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  width: '20ch',
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {options.map(option => {
                let disabled = false;
                if (
                  option.text === 'Replace with paste' &&
                  Object.keys(copiedOptionStrategy['leg']).length <= 0
                  //  ||
                  //   Object.keys(copiedOptionStrategy['hedge']).length <= 0
                ) {
                  disabled = true;
                }
                return (
                  <MenuItem
                    onClick={() => {
                      option.handler(index);
                      handleMenuClose();
                    }}
                    key={option.text}
                    disabled={disabled}
                  >
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText>{option.text}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </Paper>
        </>
      )}
      {renderOptionLegFields(
        optionLeg['legEnabled'] == 'futureleg' ||
          optionLeg['legEnabled'] == 'equityleg'
          ? OptionFutureLegFieldsGroupWise
          : OptionLegFieldsGroupWise,
      )}
    </>
  );
}
