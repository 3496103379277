import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import {
  Fragment,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useReadNotifications from 'services/common/useReadNotifications';
import useGetBrokerAuthUrl from 'services/Menu/useGetBrokerAuthUrl';
import useGetNotifications from 'services/Portfolio/useGetNotifications';
import { FEATURES } from 'types/Feature';
import { openInNewTab } from 'utils/GenericFunctions';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { PopoverContext } from '../Context/NotificationPopoverContext';
import { transformCategoryNotifications } from '../Portfolio/utils';
import { Close } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NotificationItem } from 'app/design/speedBot/EntityItem';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const NotificationUi = () => {
  const [hiddenNotificationIds, setHiddenNotificationIds] = useState(
    localStorage.getItem(HIDE_NOTIFICATION),
  );

  const isMdUp = useGetMediaQueryUp('md');
  const { toggle: toggleNotification } = useContext(PopoverContext);
  const history = useHistory();
  const mutation = useReadNotifications();
  const mutationGetBrokerAuthUrl = useGetBrokerAuthUrl();
  const objQuery = useGetNotifications();

  const { data: notificationsData } = objQuery;
  const unreadCount = notificationsData ? notificationsData.unreadCount : 0;
  const isNotificationOnHomePage = useIsFeatureFlag([
    FEATURES.IS_NOTIFICATION_ON_HOME_PAGE,
  ]);

  const breadcrumb = useBreadcrumbManager();
  const handleItemClick = (
    id: number,
    type?: string,
    subscriptionId?: number,
    redirectUrl?: string,
    notificationType?: string,
    brokerId?: number,
  ) => {
    breadcrumb.resetBreadcrumpPaths();
    mutation.mutate({ id });

    switch (notificationType) {
      case '0':
      case '1':
        history.push(`/portfolio/${type}/${subscriptionId}`);
        break;
      case '2':
      case '6':
      case '7':
      case '9':
        if (redirectUrl) history.push(redirectUrl);
        break;
      case '3':
      case '4':
        if (redirectUrl) {
          if (redirectUrl.startsWith('http')) openInNewTab(redirectUrl);
          else history.push(redirectUrl);
        }
        break;
      case '5':
        if (brokerId) mutationGetBrokerAuthUrl.mutate({ id: brokerId });
        break;
    }
  };
  const notifications = useMemo(() => {
    return transformCategoryNotifications(notificationsData?.data, unreadCount);
  }, [notificationsData?.data, unreadCount]);

  const errorNotifications =
    (notifications &&
      notifications['All'].notifications
        .filter(
          notification =>
            notification.type == '1' && notification.read === false,
        )
        .slice(0, 5)) ||
    [];

  const handleReadAll = () => {
    const hiddenIds = errorNotifications
      .map(notification => notification.id)
      .join(',');
    localStorage.setItem(HIDE_NOTIFICATION, hiddenIds);
    setHiddenNotificationIds(hiddenIds);
  };

  const isHideNotification =
    hiddenNotificationIds ===
    errorNotifications.map(notification => notification.id).join(',');

  useEffect(() => {
    const handleChangeNotificationIds = (e: StorageEvent) => {
      if (e.key === HIDE_NOTIFICATION) {
        setHiddenNotificationIds(e.newValue);
      }
    };
    window.addEventListener('storage', handleChangeNotificationIds);

    return () =>
      window.removeEventListener('storage', handleChangeNotificationIds);
  }, []);
  return (
    <>
      {isNotificationOnHomePage &&
        !isHideNotification &&
        errorNotifications.length > 0 && (
          <>
            <Box
              sx={{
                mt: 2,
                mb: 1,
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" sx={{ mr: 2 }}>
                {'Execution Errors'}
              </Typography>
              {isMdUp && (
                <Box sx={{ gap: 1, display: 'flex' }}>
                  <MuiButton
                    variant="outlined"
                    onClick={handleReadAll}
                    startIcon={<Close />}
                    size="small"
                  >
                    Hide
                  </MuiButton>
                  <MuiButton
                    size="small"
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    onClick={toggleNotification}
                  >
                    See All
                  </MuiButton>
                </Box>
              )}
            </Box>
            <Box
              flexDirection={isMdUp ? 'row' : 'column'}
              sx={{
                display: 'flex',
                mt: 1,
                p: 1,
                flexWrap: 'wrap',
                border: '1px solid #9898983d',
                borderRadius: '0.5em',
              }}
            >
              <>
                {/* {!isMdUp && (
                  <Box
                    sx={{
                      ml: 'auto',
                      p: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                    }}
                  >
                    <MuiButton onClick={handleReadAll}>
                      <Close fontSize="small" />
                      Hide
                    </MuiButton>
                    <MuiButton onClick={toggleNotification}>
                      See All
                    </MuiButton>
                  </Box>
                )} */}
                {errorNotifications.map((notification, i) => (
                  <Fragment key={i}>
                    <NotificationItem
                      notification={notification}
                      handleClick={handleItemClick}
                    />
                    {!isMdUp && errorNotifications.length - 1 !== i && (
                      <Divider className="m-main-spacing" sx={{ py: 0 }} />
                    )}
                  </Fragment>
                ))}
                {/* {isMdUp && (
                  <Box
                    sx={{
                      ml: 'auto',
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <MuiButton variant="outlined" onClick={handleReadAll}>
                      <Close fontSize="small" /> Hide
                    </MuiButton>
                    <MuiButton
                      variant="outlined"
                      onClick={toggleNotification}
                    >
                      See All
                    </MuiButton>
                  </Box>
                )} */}
              </>
            </Box>
            {!isMdUp && (
              <Box sx={{ gap: 1, display: 'flex', mt: 1 }}>
                <MuiButton
                  fullWidth
                  variant="outlined"
                  onClick={handleReadAll}
                  startIcon={<Close />}
                  size="small"
                >
                  Hide
                </MuiButton>
                <MuiButton
                  fullWidth
                  size="small"
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                  onClick={toggleNotification}
                >
                  See All
                </MuiButton>
              </Box>
            )}
          </>
        )}
    </>
  );
};

export default memo(NotificationUi);
