import { styled, IconButton } from '@mui/material';

const BorderedIconButton = styled(IconButton)(() => ({
  borderRadius: '0.5rem',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  '&.MuiIconButton-sizeMedium': {
    height: '2.5rem',
    width: '2.5rem',
  },
}));

export default BorderedIconButton;
