import CustomTooltip from 'app/components/Simulator/PayoffChart/CustomTooltip/CustomToolTipPayoff';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

type TradePayoffChartProps =
  | Array<{
      underlyingPrice: number;
      payoff: number;
      currentPayoff: number;
      positive: number | boolean;
      negative: number | boolean;
    }>
  | undefined;

const TradePayoffChart = ({
  dataPoints,
}: {
  dataPoints: TradePayoffChartProps;
}) => {
  return (
    <>
      {dataPoints && (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={dataPoints}
            margin={{ top: 10, right: 10, left: -15, bottom: 10 }}
          >
            <CartesianGrid stroke="#F0F3FA" fill="#FFFFFF" strokeWidth={2} />
            <XAxis
              dataKey="underlyingPrice"
              tick={{ fontFamily: 'Inter, sans-serif', fontWeight: '900' }}
            />
            <YAxis
              dataKey={'payoff'}
              tick={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: '900',
                color: 'black',
              }}
            />
            <Tooltip
              position={{ y: 0 }}
              isAnimationActive={false}
              cursor={{ stroke: '#000', strokeWidth: 0.5, strokeDasharray: 6 }}
              content={<CustomTooltip active={false} label="" payload={[]} />}
            />
            <Area
              type="monotone"
              dataKey="positive"
              stroke="#62B49B"
              fill="#e9f6f5"
              fillOpacity={0.5}
              strokeWidth={2.5}
            />
            <Area
              strokeWidth={2.5}
              type="monotone"
              dataKey="negative"
              stroke="#E45464"
              fill="#F9E8EF"
              fillOpacity={0.5}
            />
            {/* <Line
          strokeWidth={2}
          type="monotone"
          strokeDasharray={4}
          strokeLinecap="round"
          dataKey="currentPayoff"
          stroke="#2962FE"
          dot={false}
        /> */}
            <ReferenceLine y={0} stroke="black" />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default TradePayoffChart;
