import { Box } from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { Link as RouterLink } from 'react-router-dom';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

const SpeedBotLogo = () => {
  const { data: siteInfo } = useGetSiteInfo();
  const isMdUp = useGetMediaQueryUp('md');

  return (
    <>
      {!isMdUp && (
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          {siteInfo && siteInfo.logo && (
            <RouterLink to="/">
              <img src={siteInfo.logo} />
            </RouterLink>
          )}
        </Box>
      )}
    </>
  );
};

export default SpeedBotLogo;
