import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useGetBrokerAuthUrl from 'services/Menu/useGetBrokerAuthUrl';
import { getQueryParam, openInNewTab } from 'utils/GenericFunctions';

export type NotificationData = {
  id: number;
  instrument_id: string;
  order_date: string;
  trading_symbol: string;
  last_fill_time: string;
  type: string;
  quantity: number;
  price: number;
  value: number;
  status: string;
  tag: string;
  direction: string;
  sub_id: string;
  sub_type: string;
  url: string;
  redirect_url?: string;
  user_broker_info_id: number;
};

export function NotificationControllerPage() {
  const history = useHistory();
  const mutationGetBrokerAuthUrl = useGetBrokerAuthUrl();

  useEffect(() => {
    const data = getQueryParam('data');
    if (!data) history.push('/portfolio');

    const notification = JSON.parse(data) as NotificationData;
    if (!notification || !notification.type) history.push('/portfolio');

    switch (notification.type) {
      case '0':
      case '1':
        history.push(
          `/portfolio/${notification.sub_type}/${notification.sub_id}`,
        );
        break;
      case '2':
      case '6':
      case '7':
      case '9':
        if (notification.redirect_url) history.push(notification.redirect_url);
        break;
      case '3':
        if (notification.redirect_url) openInNewTab(notification.redirect_url);
        break;
      case '4':
        if (notification.redirect_url) {
          if (notification.redirect_url.startsWith('http'))
            openInNewTab(notification.redirect_url);
          else window.location.assign(notification.redirect_url);
        }
        break;
      case '5':
        if (notification.user_broker_info_id) {
          mutationGetBrokerAuthUrl.mutate({
            id: notification.user_broker_info_id,
          });
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return <></>;
}
