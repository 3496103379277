import { useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Input } from '@mui/material';
import OptionsBuilder from '../../components/Builder/OptionsStrategy/OptionsBuilder';
import { createEmptyOptionBuilder } from '../../components/Builder/OptionsStrategy/Utils/utils';
import { SbStrategy } from 'app/components/Bots/StrategyBuilder/types';
import { OptionStrategyFields } from '../../components/Builder/OptionsStrategy/FieldsData';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import UploadIcon from '@mui/icons-material/Upload';
import MuiButton from 'app/design/uiComponents/MuiButton';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import EditOffIcon from '@mui/icons-material/EditOff';

export function OptionsBuilderPage() {
  const [optionBuilderData, setOptionBuilderData] = useState<SbStrategy>(
    createEmptyOptionBuilder(OptionStrategyFields),
  );
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  // const readOnlyMode = false;

  const handleDownloadJSON = () => {
    if (optionBuilderData && Object.keys(optionBuilderData).length) {
      const fileName = 'data.json';
      const fileToSave = new Blob([JSON.stringify(optionBuilderData)], {
        type: 'application/json',
      });
      saveAs(fileToSave, fileName);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files,
      f = files && (files[0] as Blob);
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        const data = JSON.parse(e.target.result as string) as SbStrategy;

        setOptionBuilderData({ ...data });
      }
    };
    if (f) {
      reader.readAsText(f);
    }
  };

  const onChangeBuilderData = (
    data: SbStrategy,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isValid: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isValidSetLegs: boolean,
  ) => {
    setOptionBuilderData(data);
  };

  return (
    <>
      <Helmet>
        <title>Create Options Strategy</title>
        <meta name="description" content="Create Options Strategy Page" />
      </Helmet>
      <Box>
        <Box
          className="p-main-spacing"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: 3,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: '1' }}>
            <Typography fontWeight={500} sx={{ pl: 1 }}>
              {'Bots'}
            </Typography>
          </Box>
        </Box>
        <Box
          className="p-main-spacing"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pb: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: '1' }}>
            <Typography variant="h5" fontWeight={600} sx={{ pl: 1 }}>
              <FormattedMessage id="user_bot_startegy.page_title3" />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box ml={'30px'} mr={'30px'} mt={'20px'}>
        <Box>
          <FormControl
            variant="outlined"
            className="comman-input"
            sx={{ width: '480px' }}
          >
            <TextField
              label="Bot Name"
              defaultValue="Name"
              variant="outlined"
              fullWidth
              onChange={() => {
                return 0;
              }}
              onBlur={() => {
                return 0;
              }}
              sx={{ width: '100%' }}
              error={false}
            />
          </FormControl>
          <FormControlLabel
            sx={{ float: 'right' }}
            value={<FormattedMessage id="buttons.read_only_mode" />}
            control={
              <Switch
                color="primary"
                onChange={event => {
                  setReadOnlyMode(event.target.checked);
                }}
              />
            }
            label={<FormattedMessage id="buttons.read_only_mode" />}
            labelPlacement="start"
          />
        </Box>
        <OptionsBuilder
          setValidationMessage={() => {
            //
          }}
          optionBuilderData={optionBuilderData}
          onChangeBuilderData={(
            data: SbStrategy,
            isValid: boolean,
            isValidSetLegs: boolean,
          ) => onChangeBuilderData(data, isValid, isValidSetLegs)}
          isReadOnly={readOnlyMode}
        />
      </Box>
      <Box sx={{ py: 1, textAlign: 'right', mb: 2 }} className="m-main-spacing">
        <MuiButton
          variant="contained"
          color="secondary"
          sx={{ zIndex: 1, flex: 1, ml: 2 }}
        >
          <UploadIcon />
          <FormattedMessage id="buttons.upload_json" />
          <Input
            type="file"
            style={{
              opacity: '0',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
            onChange={handleFileChange}
            inputProps={{ accept: 'image/*' }}
          />
        </MuiButton>

        <MuiButton
          sx={{
            flex: 1,
            ml: 2,
          }}
          variant="contained"
          color="secondary"
          startIcon={<DownloadIcon />}
          onClick={() => {
            handleDownloadJSON();
          }}
        >
          <FormattedMessage id="buttons.download_json" />
        </MuiButton>
        <MuiButton
          sx={{
            flex: 1,
            ml: 2,
          }}
          variant="contained"
          color="secondary"
          startIcon={<EditOffIcon />}
          onClick={() => setReadOnlyMode(!readOnlyMode)}
        >
          <FormattedMessage id="buttons.read_only_mode" />
        </MuiButton>
      </Box>
    </>
  );
}
