import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid, Alert } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { PostBotActionParams } from 'types/ApiServicesTypes';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
import { CapabilityFlag } from 'app/components/Common/CapabilityFlag';
import PlanUpgradeButton from './PlanUpgradeButton';
import { CAPABILITIES } from 'types/Capability';

// Service
import usePostBotAction from 'services/Bots/usePostBotAction';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import useGetProfile from 'services/Menu/useGetProfile';
import { UserPlanType } from 'types/User';
import CreditEstimation from 'app/components/Common/CreditEstimation';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber } from 'utils/GenericFunctions';
import useGetBrokers from 'services/Menu/useGetBrokers';
import BotPapercontinueModal from './BotPapercontinueModal';
import BotPaperDurationModal from './BotPaperDurationModal';
import moment from 'moment';

export default function BotPaperTestModal({
  open,
  openFrom,
  id,
  // sid,
  botName,
  handleClose,
}: {
  open: boolean;
  openFrom: string;
  id: number;
  sid: number;
  botName: string;
  handleClose: (isSuccess: boolean, message?: string) => void;
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [openMessage, setOpenMessage] = useState<boolean>(false);

  const { data: profileDetails } = useGetProfile();
  const mutation = usePostBotAction(id, 'discover');
  const { data: brokers } = useGetBrokers();
  const isPlans = useIsFeatureFlag([FEATURES.IS_PLANS]);
  const [step, setStep] = useState(0);
  const [bringData, setBringData] = useState<{
    duration: string;
    durationType: string;
    amount: string;
  }>({ amount: '', duration: '', durationType: '' });
  const saveData = (data: {
    duration: string;
    durationType: string;
    amount: string;
  }) => {
    setBringData(data);
  };
  const curDate = moment(new Date());
  let durationDays = 0;
  const duration = bringData.duration.split(' ');
  if (bringData.duration === 'Manually') {
    const dt = moment().add(
      Number(bringData.amount),
      bringData.durationType.toLowerCase() as moment.unitOfTime.DurationConstructor,
    );
    durationDays = dt.diff(curDate, 'days');
  } else if (duration[1] === 'Days' || duration[1] === 'Day') {
    durationDays = parseInt(duration[0]);
  } else if (duration[1] === 'Weeks' || duration[1] === 'Week') {
    durationDays = parseInt(duration[0]) * 7;
  } else if (duration[1] === 'Months' || duration[1] === 'Month') {
    const dt = moment(new Date()).add(parseInt(duration[0]), 'months');
    durationDays = dt.diff(curDate, 'days');
  }

  const handlePaperTestClick = () => {
    setOpenMessage(false);
    const objReq: PostBotActionParams = {
      type: 'paper',
      multiplier: 1,
      duration: durationDays,
    };
    mutation.mutate(objReq);
  };

  if (mutation.isSuccess && open) {
    // handleClose(true, 'Bot is successfully started');
    handleClose(true);
    const newId: number = mutation.data.sub_id;
    mutation.reset();

    // Log entry for subscribe paper
    logFirebaseEventEntry('BOT_START_PAPER', {
      bot_id: id,
      sub_id: mutation.data.sub_id,
    });

    // Redirect to new id which is found
    if (newId !== id && openFrom === 'bots') {
      history.push(
        // `/bots/${newId}/Bots/?successMessage=Bot is successfully started`,
        `/bots/${id}/details/${newId}/${t('bots.page_title.bots')}`,
      );
    }
  }

  if (mutation.error && open) {
    mutation.reset();
    setOpenMessage(true);
  }

  const handleCloseModal = () => {
    setStep(0);
    handleClose(true);
  };

  // const paperTestTerms = getPaperTestTerms();

  const isPayAsYouGoPlan =
    profileDetails &&
    profileDetails.user_plan?.plan.plan_type === UserPlanType.PAY_AS_YOU_GO;
  return (
    <>
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <>
            <Box className="m-main-spacing">
              <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
                <FormattedMessage id="common.are_you_sure" />
              </Typography>
              <Typography
                variant="body1"
                sx={{ pb: 1, display: 'flex', fontWeight: 600 }}
                component="span"
              >
                <FormattedMessage id="bot_status.paper" />
                <Typography
                  variant="body1"
                  sx={{ pl: 0.5, fontWeight: 400 }}
                  component="span"
                >
                  <FormattedMessage id="common.with" />
                </Typography>
              </Typography>
              <Typography variant="body1" sx={{ pb: 4 }}>
                {botName}?
              </Typography>

              {isPayAsYouGoPlan ? (
                <CreditEstimation action="go_paper">
                  {({
                    walletBalance,
                    requiredCredits,
                    isSufficientCredit,
                    actionInfo,
                  }) => (
                    <>
                      {isSufficientCredit ? (
                        // <Box>
                        //   <Typography
                        //     variant="body2"
                        //     color="text.secondary"
                        //     sx={{ pb: 1 }}
                        //   >
                        //     <FormattedMessage id="bot_paper_modal.what_is" />
                        //   </Typography>
                        //   <Typography
                        //     variant="body2"
                        //     sx={{ fontWeight: 400 }}
                        //     color="text.secondary"
                        //   >
                        //     <ul
                        //       style={{
                        //         marginTop: 0,
                        //         marginBottom: 24,
                        //         paddingLeft: 24,
                        //       }}
                        //     >
                        //       {paperTestTerms &&
                        //         paperTestTerms.map((term, i) => (
                        //           <li key={i}>{term}</li>
                        //         ))}
                        //     </ul>
                        //   </Typography>
                        //   <ItemSeparator variant="2px" />
                        //   <Box
                        //     display="flex"
                        //     justifyContent="space-between"
                        //     sx={{ py: 2 }}
                        //   >
                        //     <Typography variant="body2">
                        //       <FormattedMessage id="menu.credit_balance" />
                        //     </Typography>
                        //     <Typography
                        //       variant="body2"
                        //       fontWeight={500}
                        //       color={
                        //         walletBalance < 0
                        //           ? 'error.main'
                        //           : 'text.primary'
                        //       }
                        //     >
                        //       {walletBalance < 0 ? '-' : ''}
                        //       {formatNumber(walletBalance)}
                        //     </Typography>
                        //   </Box>
                        //   <Alert severity="info">
                        //     <Typography variant="body2">
                        //       <Typography
                        //         variant="body2"
                        //         fontWeight={600}
                        //         component={'span'}
                        //       >
                        //         {requiredCredits}
                        //       </Typography>{' '}
                        //       credits
                        //       <Typography
                        //         variant="body2"
                        //         component={'span'}
                        //         mx={0.5}
                        //       >
                        //         {actionInfo}
                        //       </Typography>
                        //       will be deducted
                        //     </Typography>
                        //   </Alert>
                        // <MuiLoadingButton
                        //   fullWidth
                        //   variant="contained"
                        //   color="primary"
                        //   sx={{ my: 2 }}
                        //   onClick={() => handlePaperTestClick()}
                        //   loading={mutation.isLoading}
                        //   loadingPosition="start"
                        // >
                        //   <FormattedMessage id="buttons.paper" />
                        // </MuiLoadingButton>
                        // </Box>
                        <>
                          {!step ? (
                            <BotPapercontinueModal
                              setStep={setStep}
                              requiredCredits={requiredCredits}
                              walletBalance={walletBalance}
                              actionInfo={actionInfo}
                              step={step}
                            />
                          ) : (
                            <BotPaperDurationModal
                              saveData={saveData}
                              brokersData={brokers}
                            />
                          )}
                          {!!step && (
                            <MuiLoadingButton
                              fullWidth
                              disabled={brokers?.length === 0}
                              variant="contained"
                              color="primary"
                              sx={{ my: 2 }}
                              onClick={() => handlePaperTestClick()}
                              loading={mutation.isLoading}
                              loadingPosition="start"
                            >
                              <FormattedMessage id="buttons.paper" />
                            </MuiLoadingButton>
                          )}
                        </>
                      ) : (
                        <>
                          <Alert severity="error">
                            You do not have sufficient credits to go paper.
                            Please add credit from{' '}
                            <RouterLink
                              to="/menu/wallet"
                              style={{ textDecoration: 'underline' }}
                            >
                              <FormattedMessage id="bot_deploy_modal.here" />
                            </RouterLink>{' '}
                            and try again.
                          </Alert>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{ pt: 2 }}
                          >
                            <Typography variant="body2" color="text.primary">
                              <FormattedMessage id={'menu.credit_balance'} />
                            </Typography>
                            <Typography
                              variant="body2"
                              color={
                                walletBalance < 0
                                  ? 'error.main'
                                  : 'text.primary'
                              }
                            >
                              {walletBalance < 0 && '-'}
                              {formatNumber(walletBalance)}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{ pt: 1, pb: 2 }}
                          >
                            <Typography variant="body2" color="text.primary">
                              Required Credits
                            </Typography>
                            <Typography variant="body2">
                              {formatNumber(requiredCredits)}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </CreditEstimation>
              ) : (
                <CapabilityFlag
                  capabilities={[CAPABILITIES.PAPER_TESTING]}
                  fallback={data => {
                    const capability = data.userCapabilities.filter(
                      x => x.key === CAPABILITIES.PAPER_TESTING,
                    )[0];
                    const credit = capability ? capability.credit : 0;

                    return isPlans ? (
                      <Alert severity="warning" sx={{ mb: 4 }}>
                        <Typography variant="body2">
                          Forward Testing limit reached
                        </Typography>
                        <Typography variant="body2" fontWeight={400}>
                          {data.profileDetails?.user_plan?.plan.plan_name.replace(
                            'Plan',
                            '',
                          )}{' '}
                          plan is limited to {credit} Forward Testing. Unlock
                          more Forward Testing by upgrading your plan.
                        </Typography>
                      </Alert>
                    ) : (
                      <Alert severity="error" sx={{ mb: 4 }}>
                        <Typography variant="body2">
                          Forward Testing limit reached
                        </Typography>
                        <Typography variant="body2" fontWeight={400}>
                          You have reached the maximum capacity for{' '}
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            component={'span'}
                          >
                            Forward Testing
                          </Typography>
                          . Please contact your Administrator for more details.
                        </Typography>
                      </Alert>
                    );
                  }}
                >
                  {() =>
                    // <Box>
                    //   <Typography
                    //     variant="body2"
                    //     color="text.secondary"
                    //     sx={{ pb: 1 }}
                    //   >
                    //     <FormattedMessage id="bot_paper_modal.what_is" />
                    //   </Typography>
                    //   <Typography
                    //     variant="body2"
                    //     sx={{ fontWeight: 400 }}
                    //     color="text.secondary"
                    //   >
                    //     <ul
                    //       style={{
                    //         marginTop: 0,
                    //         marginBottom: 24,
                    //         paddingLeft: 24,
                    //       }}
                    //     >
                    //       {paperTestTerms &&
                    //         paperTestTerms.map((term, i) => (
                    //           <li key={i}>{term}</li>
                    //         ))}
                    //     </ul>
                    //   </Typography>
                    // </Box>
                    !step ? (
                      <BotPapercontinueModal
                        setStep={setStep}
                        step={step}

                        // requiredCredits={requiredCredits}
                        // walletBalance={walletBalance}
                        // actionInfo={actionInfo}
                      />
                    ) : (
                      <BotPaperDurationModal
                        saveData={saveData}
                        brokersData={brokers}
                      />
                    )
                  }
                </CapabilityFlag>
              )}
            </Box>
          </>
        }
        footer={
          <>
            {!isPayAsYouGoPlan && (
              <CapabilityFlag
                capabilities={[CAPABILITIES.PAPER_TESTING]}
                fallback={() =>
                  isPlans ? (
                    <>
                      <PlanUpgradeButton
                        capability={CAPABILITIES.PAPER_TESTING}
                      />
                    </>
                  ) : null
                }
              >
                {() =>
                  !!step && (
                    <MuiLoadingButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mb: 2 }}
                      onClick={() => handlePaperTestClick()}
                      loading={mutation.isLoading}
                      loadingPosition="start"
                      disabled={brokers?.length === 0}
                    >
                      <FormattedMessage id="buttons.paper" />
                    </MuiLoadingButton>
                  )
                }
              </CapabilityFlag>
            )}

            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleCloseModal}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </>
        }
      />
      <SnakbarMessageModal
        open={openMessage}
        handleClose={() => setOpenMessage(false)}
        body={<FormattedMessage id="snackbar_msg_modal.paper_testing_failed" />}
        footer={
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenMessage(false)}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Grid>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handlePaperTestClick()}
                >
                  <FormattedMessage id="buttons.retry" />
                </MuiButton>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
}
