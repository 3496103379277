import { v4 as uuid } from 'uuid';
import { Condition, ConditionType, Strategy } from '../types';
import { FieldData } from '../fields/types';

export const getConditionsForBackendFromStrategy = (
  strategy: Strategy,
): FieldData[] => {
  const strategyConditions = strategy.conditions;
  if (strategyConditions.length === 1) {
    return strategyConditions[0].fields;
  } else {
    return strategy.conditions.reduce((accumulator, condition) => {
      if (condition.type === 'condition') {
        accumulator = accumulator.concat([
          {
            key: '(',
            type: 'brackets',
            params: {},
          },
          ...condition.fields,
          {
            key: ')',
            type: 'brackets',
            params: {},
          },
        ]);
      } else {
        accumulator = accumulator.concat([...condition.fields]);
      }

      return accumulator;
    }, [] as FieldData[]);
  }
};

export const getConditionsForFrontEndFromStrategy = (
  strategy: Strategy,
): Condition[] => {
  const strategyConditions = strategy.conditions;
  const retData: Condition[] = [];
  for (let i = 0; i < strategyConditions.length; i++) {
    const outerOperatorIndex: number[] = [];
    const condition = strategyConditions[i];
    condition.fields.map((field: FieldData, index: number) => {
      if (field.params.type === 'outer') {
        outerOperatorIndex.push(index);
      }
    });
    if (outerOperatorIndex.length > 0) {
      let previousIndex = 0;
      outerOperatorIndex.forEach(operatorIndex => {
        let emptyCondition: Condition = getEmptyCondition();
        emptyCondition.fields = condition.fields.slice(
          previousIndex + 1,
          operatorIndex - 1,
        );
        retData.push({ ...emptyCondition });
        emptyCondition = getConditionOperator();
        emptyCondition.fields = condition.fields.slice(
          operatorIndex,
          operatorIndex + 1,
        );
        retData.push({ ...emptyCondition });
        previousIndex = operatorIndex + 1;
      });
      const emptyCondition: Condition = getEmptyCondition();
      emptyCondition.fields = condition.fields.slice(
        previousIndex + 1,
        condition.fields.length - 1,
      );
      retData.push({ ...emptyCondition });
    } else {
      const emptyCondition: Condition = getEmptyCondition();
      emptyCondition.fields = condition.fields;
      retData.push({ ...emptyCondition });
    }
  }
  return retData;
};

export const getStrategyFromConditions = (fields: FieldData[]): Strategy => {
  return {
    condition_key: uuid(),
    conditions: [
      {
        name: '',
        id: uuid(),
        type: 'condition' as ConditionType,
        fields,
      },
    ],
  };
};

export const addFieldInStrategy = (
  strategy: Strategy,
  conditionIndex: number,
  field: FieldData,
  fieldIndex: number,
): Strategy => {
  const { conditions } = strategy;
  const condition = conditions[conditionIndex];
  condition.id = uuid();
  condition.fields.splice(fieldIndex, 0, field);
  return strategy;
};

// export const addFieldAtPositionInStrategy = (
//   strategy: Strategy,
//   conditionIndex: number,
//   field: FieldData,
//   fieldIndex: number,
// ): Strategy => {
//   const { conditions } = strategy;
//   const condition = conditions[conditionIndex];
//   condition.id = uuid();
//   condition.fields.splice(fieldIndex, 0, field);
//   return strategy;
// };

export const editFieldInStrategy = (
  strategy: Strategy,
  conditionIndex: number,
  field: FieldData,
  fieldIndex: number,
): Strategy => {
  const { conditions } = strategy;
  const condition = conditions[conditionIndex];
  condition.id = uuid();
  condition.fields[fieldIndex] = field;
  return strategy;
};

export const removeFieldInStrategy = (
  strategy: Strategy,
  conditionIndex: number,
  fieldIndex: number,
): Strategy => {
  const { conditions } = strategy;
  const condition = conditions[conditionIndex];
  condition.id = uuid();
  condition.fields.splice(fieldIndex, 1);
  return strategy;
};

// export const addConditionInStrategy = (
//   strategy: Strategy,
//   condition: Condition,
//   conditionIndex: number,
// ) => {
//   const { conditions } = strategy;
//   const conditionToCopy = conditions[conditionIndex];
//   const conditionsBeforeNewCondition = conditions.slice(0, conditionIndex + 1);
//   const conditionsAfterNewCondition = conditions.slice(conditionIndex + 1);
//   const newConditions = [
//     ...conditionsBeforeNewCondition,
//     {
//       name: 'Condition operator',
//       id: uuid(),
//       type: 'condition_operator' as ConditionType,
//       fields: [
//         {
//           key: 'and',
//           params: {},
//         },
//       ],
//     },
//     {
//       ...conditionToCopy,
//       id: uuid(),
//       fields: [...conditionToCopy.fields],
//     },
//     ...conditionsAfterNewCondition,
//   ];
// };

const getConditionOperator = () => {
  return {
    name: 'Condition operator',
    id: uuid(),
    type: 'condition_operator' as ConditionType,
    fields: [
      {
        key: 'and',
        type: 'condition',
        params: {
          type: 'outer',
        },
      },
    ],
  };
};
const getEmptyCondition = () => {
  return {
    name: '',
    id: uuid(),
    type: 'condition' as ConditionType,
    fields: [],
  } as Condition;
};
export const addNewConditionInStrategy = (
  strategy: Strategy,
  conditionIndex: number,
) => {
  const { conditions } = strategy;
  const newCondition: Condition[] = [];
  if (conditions.length > 0) {
    newCondition.push(getConditionOperator());
  }
  newCondition.push(getEmptyCondition());
  conditions.splice(conditionIndex, 0, ...newCondition);
  return strategy;
};

export const pasteConditionInStrategy = (
  strategy: Strategy,
  conditionIndex: number,
  condition: Condition,
) => {
  const { conditions } = strategy;
  conditions[conditionIndex] = Object.assign(conditions[conditionIndex], {
    name: condition.name,
    fields: [...condition.fields],
  });
  return strategy;
};

export const removeConditionInStrategy = (
  strategy: Strategy,
  conditionIndex: number,
) => {
  const conditions = strategy.conditions;
  conditions.splice(
    conditionIndex === conditions.length - 1
      ? conditionIndex - 1
      : conditionIndex,
    2,
  );
  return strategy;
};

export const StrategyMode = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  TEXT: 'TEXT',
};
