import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { groupBy, decodeUrlData } from 'utils/GenericFunctions';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PositionsListLayout from 'app/design/layouts/PositionsListLayout';
import BotHistoryPositionsModal from 'app/components/Bots/BotHistory/BotHistoryPositionsModal';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import { Position } from 'types/ApiServicesTypes';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import useGetBotPositions from 'services/Bots/useGetBotPositions';

export function BotSignalsPositionsPage() {
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();

  // Hooks
  const history = useHistory();
  const { t } = useTranslation();
  const [page, setPage] = React.useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(
    null,
  );

  const baseApiUrl = `strategies/${bid}/history`;
  const handleChange = (newValue: number) => {
    if (newValue === 1) {
      history.replace({
        pathname: `/bots/${bid}/signals/${sid}/orders/${title}`,
      });
    }
  };

  const botId = parseInt(bid);
  const {
    data: botPositions,
    isLoading,
    refetch,
    isSuccess,
  } = useGetBotPositions(botId, page, baseApiUrl);

  const botGrpPositions: PositionsListLayoutProps = useMemo(() => {
    if (isSuccess && botPositions) {
      const data = groupBy(
        botPositions,
        'exit_time',
        true,
      ) as PositionsListLayoutProps;
      return data;
    } else return [] as PositionsListLayoutProps;
  }, [isSuccess, botPositions]);

  const totalPages =
    botPositions && botPositions.length > 0
      ? Number(botPositions[0]['total_pages'])
      : 0;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const handleModalOpen = (positionData: Position) => {
    setSelectedPosition(positionData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedPosition(null);
  };

  return (
    <>
      <Helmet>
        <title>Signal Positions</title>
        <meta name="description" content="Signal Positions" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${decodeUrlData(title)} | Signal`}
        buttonHandleClick={() => history.goBack()}
        tabs={
          <ContentTabs
            value={0}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.position') },
              { label: t('buttons.order') },
            ]}
          />
        }
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_SIGNALS_POSITIONS}
            data={{
              sid,
              botId,
              botName: title,
            }}
          />
        }
      />
      <PositionsListLayout
        data={botGrpPositions}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleClick={handleModalOpen}
        handleChangeTab={handleChange}
      />
      {selectedPosition && (
        <BotHistoryPositionsModal
          open={openModal}
          handleClose={handleModalClose}
          data={selectedPosition}
          title={<FormattedMessage id="buttons.position" />}
        />
      )}
    </>
  );
}
