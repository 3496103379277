import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import {
  formatNumber,
  getColorByNum,
  getFeesColor,
} from 'utils/GenericFunctions';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

type ProfilLossByDateSummaryDetailProps = {
  total_fees: number;
  total_profit_loss: number;
  net_profit_loss: number;
  baseUrl: string;
  currencySymbol: string;
};

const ProfilLossByDateSummaryDetail = ({
  total_fees,
  total_profit_loss,
  net_profit_loss,
  baseUrl,
  currencySymbol,
}: ProfilLossByDateSummaryDetailProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  const isFromDiscover = baseUrl.startsWith('marketplace');
  let isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);
  isShowResult = isFromDiscover ? isShowResult : true;
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  return (
    <>
      {isChargesAndProfit || isShowResult ? (
        <Box
          className="p-main-spacing"
          sx={{ backgroundColor: 'grey.100', py: 2 }}
        >
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              pb: { xs: 1, md: 0 },
              justifyContent: 'center',
            }}
          >
            {isMdUp ? (
              <>
                {isShowResult && (
                  <Box
                    sx={{
                      pb: 1,
                      flexFlow: 'column-reverse',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      <FormattedMessage id="summary_detail.realised_p_or_l" />
                    </Typography>
                    <Typography
                      variant="h6"
                      color={getColorByNum(total_profit_loss)}
                    >
                      {`${currencySymbol}${formatNumber(
                        total_profit_loss,
                        false,
                        0,
                      )}`}
                    </Typography>
                  </Box>
                )}
                {isChargesAndProfit && (
                  <Box
                    sx={{
                      pb: 1,
                      flexFlow: 'column-reverse',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      <FormattedMessage id="brokerage_fees" />
                    </Typography>
                    <Typography variant="h6" color={getFeesColor()}>
                      {currencySymbol}
                      {formatNumber(Math.round(total_fees), false, 0)}
                    </Typography>
                  </Box>
                )}
                {isShowResult && isChargesAndProfit && (
                  <Box
                    sx={{
                      mb: 1,
                      flexFlow: 'column-reverse',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      <FormattedMessage id="summary_detail.net_profit" />
                    </Typography>
                    <Typography
                      variant="h6"
                      color={getColorByNum(net_profit_loss)}
                    >
                      {currencySymbol}
                      {formatNumber(net_profit_loss, false, 0)}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                {isShowResult && (
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Typography variant="body3" color="text.secondary">
                      <FormattedMessage id="summary_detail.realised_p_or_l" />
                    </Typography>
                    <Typography
                      variant="body3"
                      color={getColorByNum(total_profit_loss)}
                      fontWeight={500}
                    >
                      {currencySymbol}
                      {formatNumber(total_profit_loss, false, 0)}
                    </Typography>
                  </Box>
                )}
                {isChargesAndProfit && (
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    sx={{ mb: isShowResult ? 1 : 0 }}
                  >
                    <Typography variant="body3" color="text.secondary">
                      <FormattedMessage id="brokerage_fees" />
                    </Typography>
                    <Typography
                      variant="body3"
                      color={getFeesColor()}
                      fontWeight={500}
                    >
                      {currencySymbol}
                      {formatNumber(Math.round(total_fees), false, 0)}
                    </Typography>
                  </Box>
                )}
                {isShowResult && isChargesAndProfit && (
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems="center"
                  >
                    <Typography variant="body3" color="text.secondary">
                      <FormattedMessage id="summary_detail.net_profit" />
                    </Typography>
                    <Typography
                      variant="body3"
                      color={getColorByNum(net_profit_loss)}
                      fontWeight={500}
                    >
                      {currencySymbol}
                      {formatNumber(net_profit_loss, false, 0)}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default memo(ProfilLossByDateSummaryDetail);
