import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { PostBotExitAllAndStop } from 'services/apiServices';
import { PostBotExitAllAndStopResult, Errors } from 'types/ApiServicesTypes';

export default function usePostBotExitAllAndStop(id: number, openFrom: string) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<PostBotExitAllAndStopResult, Errors>(
    ['PostBotExitAllAndStop', id],
    () =>
      PostBotExitAllAndStop(openFrom, id)
        .then(res => {
          // if (res.error) {
          //   // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          //   showAlert!(res.error);
          // }
          return res;
        })
        .catch(err => {
          showAlert!(err.message as string);
          return err;
        }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
