import { useMutation } from 'react-query';
import { GetLogout } from 'services/apiServices';
import { GetLogoutResult } from 'types/ApiServicesTypes';

export const useLogout = () => {
  const mutation = useMutation<GetLogoutResult, Error>('GetLogout', () =>
    GetLogout(),
  );
  return mutation;
};
