import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BotTradeStatsData } from 'types/ApiServicesTypes';
import { formatNumber } from 'utils/GenericFunctions';
import { styled } from '@mui/material/styles';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

const MuiBasicTable = styled(Table)(({ theme }) => ({
  '& th,td': {
    padding: 0,
    paddingBottom: theme.spacing(2),
    border: 'none',
  },
}));

const InfoListTradeStatsProfitLoss = ({
  data,
  currencySymbol,
}: {
  data: BotTradeStatsData;
  currencySymbol: string;
}) => {
  return (
    <Box sx={{ px: 3, pt: 2 }}>
      <TableContainer>
        <MuiBasicTable style={{ width: '100%', textAlign: 'right' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body2"
                  color="text.disabled"
                  fontWeight={400}
                >
                  <FormattedMessage id="summary_labels.trades" />
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: '98px' }}>
                <Typography variant="body2" color="text.disabled">
                  <FormattedMessage id="summary_labels.win" />
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: '98px' }}>
                <Typography variant="body2" color="text.disabled">
                  <FormattedMessage id="summary_labels.loss" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_labels.total" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500}>
                  {data.numberofwinningtrades
                    ? data.numberofwinningtrades
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500}>
                  {data.numberoflosingtrades ? data.numberoflosingtrades : '-'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_labels.max_consecutive" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500}>
                  {data.maxconsecutivewinningtrades
                    ? data.maxconsecutivewinningtrades
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500}>
                  {data.maxconsecutivelosingtrades
                    ? data.maxconsecutivelosingtrades
                    : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiBasicTable>
      </TableContainer>
      <Divider sx={{ mb: 2 }} />
      <TableContainer>
        <MuiBasicTable style={{ width: '100%', textAlign: 'right' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body2"
                  color="text.disabled"
                  fontWeight={400}
                >
                  <FormattedMessage id="summary_labels.gross_profit_loss" />
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: '98px' }}>
                <Typography variant="body2" color="text.disabled">
                  <FormattedMessage id="summary_labels.win" />
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: '98px' }}>
                <Typography variant="body2" color="text.disabled">
                  <FormattedMessage id="summary_labels.loss" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_labels.avg.title" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body3"
                  fontWeight={500}
                  color="success.main"
                >
                  {data.average_profit
                    ? `${
                        data.average_profit > 0
                          ? `${currencySymbol}` +
                            formatNumber(data.average_profit)
                          : `-${currencySymbol}` +
                            formatNumber(data.average_profit * -1)
                      }`
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500} color="error.main">
                  {/* {data.average_loss
                    ? `${
                        data.average_loss > 0
                          ? '₹' + formatNumber(data.average_loss)
                          : '-₹' + formatNumber(data.average_loss * -1)
                      }`
                    : '-'} */}
                  {currencySymbol}
                  {formatNumber(data.average_loss)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_labels.largest" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body3"
                  fontWeight={500}
                  color="success.main"
                >
                  {data.largestprofit
                    ? `${
                        data.largestprofit > 0
                          ? `${currencySymbol}` +
                            formatNumber(data.largestprofit)
                          : `-${currencySymbol}` +
                            formatNumber(data.largestprofit * -1)
                      }`
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500} color="error.main">
                  {data.largestloss
                    ? `${currencySymbol + formatNumber(data.largestloss)}`
                    : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiBasicTable>
      </TableContainer>
      <Divider sx={{ mb: 2 }} />
      <TableContainer>
        <MuiBasicTable style={{ width: '100%', textAlign: 'right' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body2"
                  color="text.disabled"
                  fontWeight={400}
                >
                  <FormattedMessage id="summary_labels.trade_duration" />
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: '98px' }}>
                <Typography variant="body2" color="text.disabled">
                  <FormattedMessage id="summary_labels.win" />
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: '98px' }}>
                <Typography variant="body2" color="text.disabled">
                  <FormattedMessage id="summary_labels.loss" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_labels.avg.title" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500}>
                  {data.averagewinningtradeduration
                    ? data.averagewinningtradeduration
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body3" fontWeight={500}>
                  {data.averagelosingtradeduration
                    ? data.averagelosingtradeduration
                    : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiBasicTable>
      </TableContainer>
    </Box>
  );
};

export default InfoListTradeStatsProfitLoss;
