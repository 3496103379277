import { useQuery } from 'react-query';
import { GetStatistics } from 'services/apiServices';
import { Errors, GetStatisticsResult } from 'types/ApiServicesTypes';

export default function useGetStatistics(baseApiUrl: string) {
  const objQuery = useQuery<GetStatisticsResult, Errors>(
    ['GetStatistics', baseApiUrl],
    () =>
      GetStatistics(baseApiUrl, 'GET').then(res => {
        return res;
      }),
  );

  return objQuery;
}
