import { useQuery } from 'react-query';
import { iframUrl } from 'services/apiServices';
import { Errors, GetFetchIframUrlResult } from 'types/ApiServicesTypes';

export default function useFetchUrl(strategy_id: string, method: string) {
  const objQuery = useQuery<GetFetchIframUrlResult, Errors>(
    ['GetFetchUrl', strategy_id],
    () =>
      iframUrl(strategy_id, method).then(res => {
        return res;
      }),
  );

  return objQuery;
}
