import { memo, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Typography, Box, Grid } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import BotHistoryPositionsModal from 'app/components/Bots/BotHistory/BotHistoryPositionsModal';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';

// Service
import { Position, PostBotActionParams, Errors } from 'types/ApiServicesTypes';
import { GetPortfolioBotDetail } from 'services/apiServices';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import TodaysPositions from 'app/components/Portfolio/TodaysPositions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import usePostBotAction from 'services/Bots/usePostBotAction';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import usePostBotExitAllAndStop from 'services/Bots/usePostBotExitAllAndStop';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const BotStopModal = ({
  open,
  // openFrom,
  bid,
  sid,
  botName,
  handleClose,
  category,
}: {
  open: boolean;
  openFrom: string;
  bid: number;
  sid: number;
  botName: string;
  handleClose: (isSuccess, message?: string) => void;
  category: string;
}) => {
  const [selectedPos, setSelectedPos] = useState<Position | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  let subscriptionCategory = '';

  const { data: positions, isLoading } = useQuery<Position[] | null, Errors>(
    ['GetPortfolioBotDetail', bid, sid],
    async () => {
      return await GetPortfolioBotDetail(`portfolio/${sid}`, 'GET').then(
        res => {
          if (res && res.today_positions && res.today_positions.length > 0) {
            const positionsFilterData = res.today_positions.filter(
              x => x.exit_price === 0,
            );
            return positionsFilterData;
          }
          return null;
        },
      );
    },
    { enabled: open },
  );

  const handlePositionClick = useCallback((position: Position) => {
    setSelectedPos(position);
    setOpenModal(true);
  }, []);

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedPos(null);
  };

  const mutation = usePostBotAction(sid, 'marketplace');
  const mutationExitAllAndStop = usePostBotExitAllAndStop(sid, 'marketplace');

  const handleStopClick = () => {
    subscriptionCategory = category;
    setOpenMessage(false);

    const objReq: PostBotActionParams = {
      type: 'stop',
      multiplier: 1,
    };
    mutation.mutate(objReq, {
      onSuccess: res => {
        if (!res.error && open) {
          // handleClose(true, 'Bot is successfully stopped');

          // Log entry for stop subscription paper/live
          logFirebaseEventEntry(
            'BOT_STOP_' + subscriptionCategory.toUpperCase(),
            {
              bot_id: bid,
            },
          );

          handleClose(true);
          mutation.reset();
        }
        if (res.error) {
          setOpenMessage(true);
          mutation.reset();
        }
      },
    });
  };

  // if (mutation.isSuccess && open) {
  //   // handleClose(true, 'Bot is successfully stopped');

  //   // Log entry for stop subscription paper/live
  //   logFirebaseEventEntry('BOT_STOP_' + subscriptionCategory.toUpperCase(), {
  //     bot_id: bid,
  //   });

  //   handleClose(true);
  //   mutation.reset();
  // }

  // if (mutation.error && open) {
  //   mutation.reset();
  //   setOpenMessage(true);
  // }

  const handleExitAllAndStopClick = () => {
    subscriptionCategory = category;
    setOpenMessage(false);

    mutationExitAllAndStop.mutate(undefined, {
      onSuccess: res => {
        if (!res && open) {
          // // Log entry for stop subscription paper/live
          // logFirebaseEventEntry(
          //   'BOT_STOP_' + subscriptionCategory.toUpperCase(),
          //   {
          //     bot_id: bid,
          //   },
          // );

          // handleClose(true);
          mutationExitAllAndStop.reset();
        }
        if (res) {
          handleClose(true);
          mutationExitAllAndStop.reset();
        }
      },
    });
  };

  const handleCloseModal = () => {
    handleClose(true);
  };

  return (
    <>
      <CircularLoader open={isLoading} zIndex={1302} />
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <>
            <Box className="m-main-spacing">
              <Typography variant="body1" sx={{ pb: 4, fontWeight: 400 }}>
                <FormattedMessage id="bot_stop_modal.are_you_sure" />{' '}
                <b>{botName}</b>?
              </Typography>
              {positions && positions.length > 0 && (
                <Typography variant="body1" sx={{ pb: 2, fontWeight: 400 }}>
                  <FormattedMessage id="bot_stop_modal.you_still_have" />
                </Typography>
              )}
            </Box>
            <Box>
              {positions && (
                <TodaysPositions
                  positions={positions}
                  handlePositionClick={handlePositionClick}
                />
              )}
            </Box>
          </>
        }
        footer={
          <>
            {positions && positions.length > 0 && (
              <FeatureFlag
                features={[FEATURES.IS_EXIT_ALL_AND_STOP]}
                fallback={<></>}
              >
                <MuiLoadingButton
                  fullWidth
                  variant="outlined"
                  color="error"
                  sx={{ my: 2 }}
                  onClick={handleExitAllAndStopClick}
                  loading={mutationExitAllAndStop.isLoading}
                  loadingPosition="start"
                >
                  <FormattedMessage id="buttons.exit_all" />
                </MuiLoadingButton>
              </FeatureFlag>
            )}
            <MuiLoadingButton
              fullWidth
              variant="outlined"
              color="error"
              sx={{ mb: 2 }}
              onClick={handleStopClick}
              loading={mutation.isLoading}
              loadingPosition="start"
            >
              <FormattedMessage id="buttons.stop" />
            </MuiLoadingButton>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </>
        }
      />
      <SnakbarMessageModal
        open={openMessage}
        handleClose={() => setOpenMessage(false)}
        body={<FormattedMessage id="snackbar_msg_modal.paper_testing_failed" />}
        footer={
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenMessage(false)}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
              </Grid>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleStopClick}
                >
                  <FormattedMessage id="buttons.retry" />
                </MuiButton>
              </Grid>
            </Grid>
          </>
        }
      />
      <BotHistoryPositionsModal
        open={openModal}
        handleClose={handleModalClose}
        data={selectedPos || null}
        title={<FormattedMessage id="buttons.position" />}
        buySellPositions={true}
      />
    </>
  );
};

export default memo(BotStopModal);
