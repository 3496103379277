import { useQuery } from 'react-query';
import { GetAllTodaysPositions } from 'services/apiServices';
import { Errors, Position } from 'types/ApiServicesTypes';

export default function useGetBotAllTodayPosition(
  value: boolean,
  ptype: string,
) {
  const queryKey = ['GetAllTodaysPositions', value, ptype];
  const objQuery = useQuery<Position[], Errors>(
    queryKey,
    () => GetAllTodaysPositions(ptype),
    { enabled: !!value },
  );
  return objQuery;
}
