import { TradeOptions } from 'app/components/TradeIdeas/types';
import { useQuery } from 'react-query';
import { GetTradeOptionData } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';

export default function useGetTradeOptionsData(symbol: string, view: string) {
  const objQuery = useQuery<TradeOptions, Errors>(
    ['GetTradeOptionsData', symbol, view],
    () => GetTradeOptionData(symbol, view).then(res => res.data),
    {
      // enabled: !!view,
    },
  );
  return objQuery;
}
