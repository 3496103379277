import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { PageTitleWithButtonVariantProps } from '../types';

const PageTitleWithButtonVariant = ({
  title,
  buttonText,
  buttonAction,
  filterChips,
}: PageTitleWithButtonVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ pt: 1, pb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ flex: '1' }}>
          {title}
        </Typography>
        <MuiButton size="small" onClick={buttonAction}>
          {buttonText}
        </MuiButton>
      </Box>
      {filterChips && (
        <Box
          sx={{
            pt: 1,
            overflow: 'auto',
            display: 'flex',
            '&::-webkit-scrollbar': { display: 'none' },
            gap: 1,
          }}
        >
          {filterChips}
        </Box>
      )}
    </Box>
  );
};

export default PageTitleWithButtonVariant;
