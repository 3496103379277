// import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
interface CalendarProps {
  whichDate: string;
  value?: string;
  onChange: (date: string | null) => void;
  datetype: string;
  startDate?: string;
}

function MyDatePicker({
  whichDate,
  value,
  onChange,
  datetype,
  startDate,
}: CalendarProps) {
  // const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
  //   value ? dayjs(value, 'YYYY-MM-DD') : null,
  // );

  // const oneYearsAgo = dayjs().subtract(1, 'year');
  const startingMonth = moment()
    .set('year', 2021)
    .set('month', 0)
    .set('date', 1);

  const handleDateChange = (date: moment.Moment | null) => {
    // setSelectedDate(date);
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      onChange(formattedDate);
    } else {
      onChange(null);
    }
  };

  // const minDate = dayjs().subtract(1095.75, 'days');

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* Use AdapterDayjs */}
        <Stack component="form" noValidate>
          <DesktopDatePicker
            label={whichDate}
            format="YYYY/MM/DD"
            value={moment(value, 'YYYY/MM/DD')}
            onChange={handleDateChange}
            minDate={datetype === 'end' ? moment(startDate) : startingMonth}
            maxDate={moment()}
            slotProps={{ textField: { size: 'medium' } }}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}

export default MyDatePicker;
