import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { PostImportFileData } from 'services/apiServices';
import { Errors, ImportStrategyResult } from 'types/ApiServicesTypes';
export type PostStrategyDataPickerProps = {
  attachment: string;
  bot_name: string;
};
export const usePostStrategyData = (successCallback: () => void) => {
  const { showAlert } = useContext(ToastContext);

  const mutation = useMutation<
    ImportStrategyResult,
    Errors,
    Pick<PostStrategyDataPickerProps, 'attachment' | 'bot_name'>
  >('PostStrategyData', value => {
    return PostImportFileData(value)
      .then(res => {
        if (res.success) {
          successCallback();
          showAlert!(
            res.success ? 'Your Request has Been Registered' : '',
            'success',
          );
        } else if (!res || res.errors)
          showAlert!(res.errors ? res.errors : 'Something went wrong. ');
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      });
  });

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
};
