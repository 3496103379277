import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { Item } from 'app/design/speedBot/EntityItem';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString } from 'utils/GenericFunctions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useGetTicketData from 'services/Menu/useGetTicketsData';
import { GetTicketsData } from 'types/ApiServicesTypes';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import StatusMessage from 'app/components/Common/StatusMessage';
// import Support from '../../../../assets/images/Support.png';
export const getStatusChipColor = (
  status: string,
):
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'purplelight'
  | 'greyselected'
  | 'greylight'
  | 'infolight'
  | 'successlight'
  | 'errorlight'
  | 'warninglight'
  | undefined => {
  switch (status.toLowerCase()) {
    case 'new':
      return 'infolight';
    case 'in progress':
      return 'purplelight';
    case 'solved':
      return 'successlight';
    default:
      return 'primary';
  }
};
const TicketListView = () => {
  const breadcrumb = useBreadcrumbManager();
  const { t } = useTranslation();
  const { data: ticketData, isLoading } = useGetTicketData();
  return (
    <Box
      sx={
        {
          // height: '100vh',
        }
      }
    >
      <CircularLoader open={isLoading} />

      <Box>
        {ticketData &&
          ticketData.data &&
          ticketData.data.length > 0 &&
          ticketData.data.map((ticket: GetTicketsData, i) => (
            <Fragment key={i}>
              <Item
                variant="itemSubtextIcon"
                subtext={''}
                title={
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ mr: 2 }}>{ticket.name}</Typography>
                    <MuiChip
                      color={getStatusChipColor(ticket.status)}
                      label={ticket.status}
                      size="small"
                      sx={{ height: '1.4rem' }}
                    />
                  </Box>
                }
                bottomChip={
                  <Typography variant="caption">
                    Created at {formatDateString(ticket.date, 'DD/MM/YY')}
                  </Typography>
                }
                icon={<ChevronRightIcon />}
                handleClick={() =>
                  breadcrumb.push(
                    `help/ticketdetail/${ticket.id}`,
                    PAGES.TICKET_DETAILS,
                    `/menu/help`,
                    t('menu.page_title.help'),
                  )
                }
              />
              {ticketData.data.length - 1 !== i && (
                <Divider className="m-main-spacing" sx={{ py: 0 }} />
              )}
            </Fragment>
          ))}
      </Box>
      {ticketData && ticketData.data && ticketData.data.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            // alignContent: 'center',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',

            alignItems: 'center',
            mt: 20,
          }}
        >
          {/* <img src={Support} style={{ width: '150px', height: '150px' }} /> */}
          <StatusMessage
            title={'Currently No Ticket Request pending'}
            subtitle={'Create a new Ticket'}
            fontStyle="italic"
          />
        </Box>
      )}
    </Box>
  );
};

export default TicketListView;
