import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { Box, Grid } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import MarketplaceItems from 'app/design/itemsComponent/MarketplaceItems';
import { decodeUrlData } from 'utils/GenericFunctions';

// Service
import { GetMarketplaceBotsByCategory } from 'services/apiServices';
import {
  Errors,
  GetMarketplaceBotsByCategoryResult,
} from 'types/ApiServicesTypes';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function MarketplaceCategoryPage() {
  const { id, title, subtitle } =
    useParams<{ id: string; title: string; subtitle: string }>();
  const history = useHistory();

  const query = useQuery<GetMarketplaceBotsByCategoryResult, Errors>(
    ['GetMarketplaceBotsByCategory', 'marketplace', parseInt(id)],
    () => GetMarketplaceBotsByCategory('marketplace', 'GET', parseInt(id)),
  );
  const { data: marketplaceBotsData, isLoading } = query;

  const marketplaceBots =
    marketplaceBotsData &&
    marketplaceBotsData.length > 0 &&
    marketplaceBotsData[0].bots &&
    marketplaceBotsData[0].bots.length > 0
      ? marketplaceBotsData[0].bots
      : null;

  return (
    <>
      <Helmet>
        <title>Discover bots</title>
        <meta name="description" content="Discover bots" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        buttonText={decodeUrlData(title)}
        title={decodeUrlData(subtitle)}
        buttonHandleClick={() => history.goBack()}
        breadcrumb={<Breadcrumbs page={PAGES.MARKETPLACE_CATEGORY} data={{}} />}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          spacing={0}
          sx={{
            maxWidth: { xs: '32rem', md: '50rem' },
            flex: { lg: 1, xl: 1 },
          }}
        >
          {marketplaceBots && (
            <MarketplaceItems
              bots={marketplaceBots}
              isGraph={true}
              isSearch={true}
            />
          )}
        </Grid>
      </Box>
    </>
  );
}
