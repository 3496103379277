import { SymbolData, SymbolDatav2 } from 'types/Symbols';

export const transformSymbolDatav2ToSymbolData = (
  symbols: SymbolDatav2[],
): SymbolData[] => {
  return symbols.map(symbol => ({
    Open: symbol.O,
    High: symbol.H,
    Low: symbol.L,
    Close: symbol.C,
    LastClose: symbol.LC,
    Volume: symbol.V,
    tickname: symbol.S,
    time: symbol.T,
    dirty: symbol.D,
  }));
};
