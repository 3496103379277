import { createContext, memo, useCallback, useContext, useState } from 'react';
import { getCookie } from 'typescript-cookie';
import { SESSION_ID, USER_ID, USER_NAME } from 'constants/common';
import { UserInfo } from 'types/User';
import { useAuthFromQueryParams } from 'app/hooks/useAuthFromQueryParams';

export const getUserInfo = () => {
  const sessionId = getCookie(SESSION_ID) as string;
  const userName = getCookie(USER_NAME) as string;
  const userId = getCookie(USER_ID) as string;

  return {
    sessionId,
    userName,
    userId,
  };
};

type UserContextType = {
  user: UserInfo | undefined;
  setUserDetails: (userDetail: UserInfo) => void;
};
const initialValue = {
  user: undefined,
  // eslint-disable-next-line
  setUserDetails: (userDetail: UserInfo) => {},
};

export const UserContext = createContext<UserContextType>(initialValue);

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState<UserInfo | undefined>(undefined);
  const setUserDetails = useCallback((userDetail: UserInfo) => {
    setUser(userDetail);
  }, []);

  useAuthFromQueryParams();

  return (
    <UserContext.Provider
      value={{
        user,
        setUserDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = memo(UserContextProvider);

export const useUserDetails = () => {
  const userContext = useContext(UserContext);
  return userContext;
};
