import { FieldDataWithValidate } from 'app/components/Builder/Strategy/fields/types';
import { SaveUserBotParams } from 'types/ApiServicesTypes';
import { InfoType } from 'types/ComponentTypes';
import { ComboBox } from 'types/Generic';
import { Instrument } from 'types/Instrument';
import { formatDateString } from 'utils/GenericFunctions';
import {
  StrategyBuilderSymbolsFormData,
  StrategyBuilderEntryExitFormData,
  StrategyBuilderPortfolioFormData,
  LongShortEntryExit,
  SbStrategy,
  StrategyBuilderFormData,
} from './types';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

export const ChartTypesData: ComboBox[] = [
  { label: 'Candlestick', value: 'Candlestick' },
];

export const IntervalsData: ComboBox[] = [
  { label: '1 Min', value: '00:01:00' },
  { label: '2 Min', value: '00:02:00' },
  { label: '3 Min', value: '00:03:00' },
  { label: '5 Min', value: '00:05:00' },
  { label: '10 Min', value: '00:10:00' },
  { label: '15 Min', value: '00:15:00' },
  { label: '20 Min', value: '00:20:00' },
  { label: '25 Min', value: '00:25:00' },
  { label: '30 Min', value: '00:30:00' },
  { label: '45 Min', value: '00:45:00' },
  { label: '1 Hour', value: '01:00:00' },
  { label: '75 Min', value: '01:15:00' },
  { label: '2 Hour', value: '02:00:00' },
  { label: '125 Min', value: '02:05:00' },
  { label: '3 Hour', value: '03:00:00' },
  { label: '4 Hour', value: '04:00:00' },
  { label: '1 Day', value: '24:00:00' },
];

export const PositionDirectionData: ComboBox[] = [
  { label: 'Long', value: 'long' },
  { label: 'Short', value: 'short' },
  { label: 'Both', value: 'both' },
];

export const RiskTypeData: ComboBox[] = [
  { label: 'Points', value: 'points' },
  { label: 'Percentage', value: 'percentage' },
];

export const PositioningSizingData: ComboBox[] = [
  { label: 'Quantity Based', value: 'quantity_based' },
  { label: 'Capital Based (Absolute)', value: 'capital_based_absolute' },
  { label: 'Capital Based (%)', value: 'capital_based_percentage' },
  { label: 'Risk Based', value: 'risk_based' },
];

export const OrderTypeData: ComboBox[] = [
  { label: 'Limit', value: 'limit' },
  { label: 'Market', value: 'market' },
];

export const HoldingTypeData: ComboBox[] = [
  { label: 'CNC / NRML', value: 'cnc' },
  { label: 'MIS / Intraday', value: 'mis' },
];

export const isValidConditions = (
  conditions: FieldDataWithValidate,
  type: 'entry' | 'exit',
): boolean => {
  if (type === 'entry')
    return (conditions &&
      conditions.conditions.length &&
      conditions.isConditionValid) as boolean;
  else return conditions && conditions.isConditionValid;
};

export const transformSaveUserBotRequest = (
  symbolsFormData: StrategyBuilderSymbolsFormData,
  entryExitFormData: StrategyBuilderEntryExitFormData,
  portfolioFormData: StrategyBuilderPortfolioFormData,
): SaveUserBotParams => {
  const blanckConditions: LongShortEntryExit = {
    condition_key: '',
    conditions: [],
  };
  const objReq: SaveUserBotParams = {
    name: symbolsFormData.botName,
    sb_strategy: {
      chart: symbolsFormData.chartType,
      universe: symbolsFormData.symbols.map(x => {
        return {
          name: x.name,
          segment: x.exchange_ids,
          symbol: x.trading_symbol,
        };
      }),
      resolution: symbolsFormData.interval,
      direction: entryExitFormData.positionDirection,
      long_entry:
        entryExitFormData.longEntryConditions &&
        entryExitFormData.longEntryConditions.conditions &&
        entryExitFormData.longEntryConditions.conditions.length
          ? ({
              condition_key: 'LongEntryConditions',
              conditions: entryExitFormData.longEntryConditions.conditions,
            } as LongShortEntryExit)
          : blanckConditions,
      long_exit:
        entryExitFormData.longExitConditions &&
        entryExitFormData.longExitConditions.conditions &&
        entryExitFormData.longExitConditions.conditions.length
          ? ({
              condition_key: 'LongExitConditions',
              conditions: entryExitFormData.longExitConditions.conditions,
            } as LongShortEntryExit)
          : blanckConditions,
      short_entry:
        entryExitFormData.shortEntryConditions &&
        entryExitFormData.shortEntryConditions.conditions &&
        entryExitFormData.shortEntryConditions.conditions.length
          ? ({
              condition_key: 'ShortEntryConditions',
              conditions: entryExitFormData.shortEntryConditions.conditions,
            } as LongShortEntryExit)
          : blanckConditions,
      short_exit:
        entryExitFormData.shortExitConditions &&
        entryExitFormData.shortExitConditions.conditions &&
        entryExitFormData.shortExitConditions.conditions.length
          ? ({
              condition_key: 'ShortExitConditions',
              conditions: entryExitFormData.shortExitConditions.conditions,
            } as LongShortEntryExit)
          : blanckConditions,
      risk_type: entryExitFormData.targetType,
      order_sizing: {
        type: portfolioFormData.positioningSizing,
        value: portfolioFormData.positionValue,
      },
      risk_percentage: portfolioFormData.maxRiskAmount,
      order_type: portfolioFormData.orderType,
      order_valid_till: portfolioFormData.limitOrderValid
        ? portfolioFormData.limitOrderValid
        : '0',
      holding_type: portfolioFormData.holdingType,
      square_off_time:
        portfolioFormData.holdingType === 'cnc'
          ? '00:00'
          : portfolioFormData.squareOffTime,
      target_per: entryExitFormData.target,
      stop_loss_per: entryExitFormData.stoploss,
      trailing_sl_enabled: entryExitFormData.isTrailStoploss,
      trailling_sl: entryExitFormData.trailStoploss,
      start_date: formatDateString(
        new Date(new Date().setMonth(new Date().getMonth() - 30)),
        'DD/MM/YYYY',
      ),
      end_date: formatDateString(
        new Date(new Date().setDate(new Date().getDate() - 1)),
        'DD/MM/YYYY',
      ),
      initial_capital: '1000000',
      trading_start_time: '09:00',
      trading_stop_time: '23:59',
    } as SbStrategy,
  };

  return objReq;
};

export const transformSaveUserBotResponse = (
  userBotDetails: SaveUserBotParams | undefined,
): StrategyBuilderFormData | null => {
  if (
    !userBotDetails ||
    !userBotDetails.sb_strategy ||
    !userBotDetails.sb_strategy.direction
  )
    return null;

  const botStrategy = userBotDetails.sb_strategy;

  const objReq: StrategyBuilderFormData = {
    botName: userBotDetails.name,
    symbols: {
      botName: userBotDetails.name,
      chartType: botStrategy.chart,
      interval: botStrategy.resolution,
      symbols: botStrategy.universe
        ? botStrategy.universe.map(x => {
            return {
              name: x.name,
              exchange_ids: x.segment,
              trading_symbol: x.symbol,
            } as Instrument;
          })
        : [],
    } as StrategyBuilderSymbolsFormData,
    entryExitData: {
      positionDirection: botStrategy.direction,
      longEntryConditions: {
        conditions: botStrategy.long_entry
          ? botStrategy.long_entry.conditions
          : [],
        isConditionValid: true,
      },
      longExitConditions: {
        conditions: botStrategy.long_exit
          ? botStrategy.long_exit.conditions
          : [],
        isConditionValid: true,
      },
      shortEntryConditions: {
        conditions: botStrategy.short_entry
          ? botStrategy.short_entry.conditions
          : [],
        isConditionValid: true,
      },
      shortExitConditions: {
        conditions: botStrategy.short_exit
          ? botStrategy.short_exit.conditions
          : [],
        isConditionValid: true,
      },
      targetType: botStrategy.risk_type,
      target: botStrategy.target_per,
      stoploss: botStrategy.stop_loss_per,
      isTrailStoploss: botStrategy.trailing_sl_enabled,
      trailStoploss: botStrategy.trailling_sl,
    },
    portfolioData: {
      positioningSizing: botStrategy.order_sizing.type,
      positionValue: botStrategy.order_sizing.value,
      orderType: botStrategy.order_type,
      holdingType: botStrategy.holding_type,
      squareOffTime: botStrategy.square_off_time,
      limitOrderValid: botStrategy.order_valid_till
        ? parseInt(botStrategy.order_valid_till)
        : 0,
      maxRiskAmount: botStrategy.risk_percentage,
    },
  };

  return objReq;
};

export const positionSizingInfoModalData: InfoType[] = [
  {
    title: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.quantity_based.title" />
    ),
    desc: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.quantity_based.desc" />
    ),
  },
  {
    title: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.capital_based_absolute.title" />
    ),
    desc: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.capital_based_absolute.desc" />
    ),
  },
  {
    title: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.capital_based_percent.title" />
    ),
    desc: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.capital_based_percent.desc" />
    ),
  },
  {
    title: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.risk_based.title" />
    ),
    desc: (
      <FormattedMessage id="user_bot_startegy.position_sizing_info_modal.risk_based.desc" />
    ),
  },
];

export enum StrategyBuilderTypes {
  'equity',
  'option',
  'code',
}

export enum StrategyBuiltWithTypes {
  'equity_builder' = 'equity_builder',
  'option_builder' = 'option_builder',
  'code_builder' = 'code_builder',
}
