import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import PageHeader from 'app/design/speedBot/PageHeader';
import { PortfolioSumaryWithPositions } from 'app/components/Portfolio/PortfolioSumaryWithPositions';
import useGetPortfolioBotDetail from 'services/Portfolio/useGetPortfolioBotDetail';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PortfolioBotActions from 'app/components/Portfolio/PortfolioBotActions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { MessageAlerts } from 'app/components/Common/MessageAlerts';
import { Box } from '@mui/material';
import usePostClearError from 'services/Portfolio/usePostClearError';
import { encodeUrlData } from 'utils/GenericFunctions';

export function PortfolioDetailPage(): JSX.Element {
  const { id, ptype } = useParams<{ id: string; ptype: string }>();
  const { data, isLoading } = useGetPortfolioBotDetail(id);
  const mutate = usePostClearError(parseInt(id));

  const handleClearClick = (errorId: number) => {
    mutate.mutate({ errorId });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Portfolio</title>
        <meta name="description" content="Portfolio Detail Page" />
      </Helmet>
      <CircularLoader open={isLoading || mutate.isLoading} />
      {data && data.data && (
        <>
          <PageHeader
            variant="paperTesting"
            buttonText={
              <FormattedMessage id="portfolio.page_title.portfolio" />
            }
            title={data.data.bot_name}
            value={data.data.time}
            chipProps={{
              variant: 'filled',
              label: data.data.category,
              color:
                data.data.category === 'active' ? 'infolight' : 'greylight',
            }}
            buttonHandleClick={`/portfolio?ptype=${ptype}`}
            breadcrumb={
              <Breadcrumbs
                page={PAGES.PORTFOLIO_DETAIL}
                data={{ ptype: ptype }}
              />
            }
          />
          <Box className="p-main-spacing" sx={{ mb: 3 }}>
            <MessageAlerts
              errors={data.data.errors}
              handleClearClick={handleClearClick}
            />
          </Box>
          <PortfolioSumaryWithPositions
            type={ptype}
            data={data}
            isLoading={isLoading}
          />
          <PortfolioBotActions
            id={id}
            botId={data.data.botid}
            ptype={ptype}
            slave={data.data.slave}
            botName={encodeUrlData(data.data.bot_name)}
            positionCount={data.data.position_count}
            orderCount={data.data.order_count}
            scripts={data?.data.scripts || []}
          />
        </>
      )}
    </Fragment>
  );
}
