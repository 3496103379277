import React from 'react';
import throttle from 'lodash/throttle';
import intersection from 'lodash/intersection';
import { Socket } from 'socket.io-client';

import { useSocket } from 'app/components/Context/SocketContext';
import { SymbolData, SymbolDatav2, SymbolsData } from 'types/Symbols';

import { State, TickDataCallbak } from './types';
import { transformSymbolDatav2ToSymbolData } from './utils';
import { SOCKET_CONSTANTS } from 'constants/socket';

export class TickDataManager {
  private state: State;
  private socket: Socket;
  constructor(socket: Socket) {
    this.state = {
      symbolsData: {},
      newSymbolsData: {},
      registredSymbols: {},
      registredComponents: {},
    };
    this.socket = socket;
    this.socket.on(
      SOCKET_CONSTANTS.TICK_CHANNEL,
      this.onSymbolsDatav2.bind(this),
    );

    this.socket.on('connect', () => {
      Object.entries(this.state.registredComponents).forEach(entry => {
        const componentData = entry[1];
        const { symbols } = componentData;
        this.socket.emit(SOCKET_CONSTANTS.SUBSCRIPTION_CHANNEL, symbols);
      });
    });
  }

  private resetNewSymbolsData() {
    this.state.newSymbolsData = {};
  }

  private static getSymbolsDataFromLocalStroage() {
    return localStorage.LiveMarketData
      ? (JSON.parse(localStorage.LiveMarketData as string) as SymbolsData)
      : {};
  }

  private static saveSymbolsDataToLocalStorage(newSymbolsData: SymbolsData) {
    const existingSymbolsData =
      TickDataManager.getSymbolsDataFromLocalStroage();
    const updateSymbolsData = {
      ...existingSymbolsData,
      ...newSymbolsData,
    };

    localStorage.LiveMarketData = JSON.stringify(updateSymbolsData);
  }

  public checkForLocalStorageSymbolsData() {
    const symbolsData = TickDataManager.getSymbolsDataFromLocalStroage();
    this.state.newSymbolsData = symbolsData;
    this.callComponentsCallback();
  }

  private callComponentsCallback() {
    const newSymbolsData = this.state.newSymbolsData;
    const registredComponents = this.state.registredComponents;
    const newSymbols = Object.keys(newSymbolsData);

    Object.entries(registredComponents).forEach(entry => {
      const componentData = entry[1];
      const { symbols: componentSymbols, callback } = componentData;

      const isComponentSymbolsChanged = intersection(
        componentSymbols,
        newSymbols,
      );
      if (isComponentSymbolsChanged.length > 0) {
        const componentSymbolsData = componentSymbols.reduce(
          (accumulator, tickname) => {
            accumulator[tickname] = this.state.symbolsData[tickname];
            return accumulator;
          },
          {},
        );
        callback(componentSymbolsData);
      }
    });
  }

  throttleCallComponentsCallback = throttle(() => {
    this.callComponentsCallback();
    this.resetNewSymbolsData();
    TickDataManager.saveSymbolsDataToLocalStorage(this.state.newSymbolsData);
  }, 1 * 1000);

  private onSymbolData(data: SymbolData) {
    this.state.newSymbolsData[data.tickname] = data;
    this.throttleCallComponentsCallback();
  }

  private onSymbolsDatav2(symbols: SymbolDatav2[]) {
    const transformedSymbols = transformSymbolDatav2ToSymbolData(symbols);
    transformedSymbols.forEach(symbol => {
      this.state.symbolsData[symbol.tickname] = symbol;
      this.state.newSymbolsData[symbol.tickname] = symbol;
    });
    this.throttleCallComponentsCallback();
  }

  public unRegisterSymbolsData(componentId: string) {
    const registredSymbols = this.state.registredSymbols;
    const registredComponents = this.state.registredComponents;
    const symbols = registredComponents[componentId].symbols;
    symbols.map(symbol => {
      if (registredSymbols[symbol] === 1) {
        registredSymbols[symbol] = 0;
      } else {
        registredSymbols[symbol] = registredSymbols[symbol] - 1;
      }
    });
    delete registredComponents[componentId];
  }

  public registerForSymbolsData(
    componentId: string,
    symbols: string[],
    callback: TickDataCallbak,
    // broker?: number,
  ) {
    const registredSymbols = this.state.registredSymbols;
    const registredComponents = this.state.registredComponents;

    if (registredComponents[componentId]) {
      this.unRegisterSymbolsData(componentId);
    }
    // if (broker)
    this.socket.emit(SOCKET_CONSTANTS.SUBSCRIPTION_CHANNEL, symbols);

    symbols.map(symbol => {
      if (!registredSymbols[symbol]) {
        // socket.on(symbol, onSymbolData);
        registredSymbols[symbol] = 1;
      } else {
        registredSymbols[symbol] = registredSymbols[symbol] + 1;
      }
    });

    registredComponents[componentId] = { symbols, callback };
  }
}

export const useTickDataManager = (verifiedBrokers?: boolean) => {
  const { socket } = useSocket();
  const [tickDataManager, setTickDataManager] =
    React.useState<TickDataManager>();

  React.useEffect(() => {
    if (socket && verifiedBrokers) {
      setTickDataManager(new TickDataManager(socket));
    } else {
      localStorage.removeItem('niftySymbolData');
    }
  }, [socket, verifiedBrokers]);

  return tickDataManager;
};
