import { useCallback, useEffect, useState } from 'react';
import { useLogDataManager } from './LogDataManager';
import { v4 as uuidv4 } from 'uuid';
import { LogResultData, SOCKET_LOG_MESSAGE_TYPES } from './types';

export type LogDataType = {
  result: string;
  type: string;
  message: string[];
};
export const useGetLogData = (id: number) => {
  const logDataManager = useLogDataManager();
  const [updatedLogs, setUpdatedLogs] = useState<string[]>([]);
  const [updatedErrors, setUpdatedErrors] = useState<string[]>([]);

  const onUpdatedLogs = useCallback(
    (data: LogResultData) => {
      if (data) {
        if (id === data.bot_id || id === data.strategy_id) {
          setUpdatedLogs(updatedLogs => [...updatedLogs, ...data.data]);
        }
      }
    },
    [id],
  );

  const onUpdatedErrors = useCallback(
    (data: LogResultData) => {
      if (data) {
        if (id === data.bot_id || id === data.strategy_id) {
          setUpdatedErrors(data.data);
        }
      }
    },
    [id],
  );

  const resetLogMessages = () => setUpdatedLogs([]);

  useEffect(() => {
    const logComponentId: string = uuidv4();
    const errorCompontId: string = uuidv4();
    if (logDataManager) {
      logDataManager.registerComponent(
        logComponentId,
        SOCKET_LOG_MESSAGE_TYPES.LOG,
        onUpdatedLogs,
      );
      logDataManager.registerComponent(
        errorCompontId,
        SOCKET_LOG_MESSAGE_TYPES.WARN,
        onUpdatedErrors,
      );
    }
    return () => {
      if (logDataManager) {
        logDataManager.unRegisterComponent(logComponentId);
        logDataManager.unRegisterComponent(errorCompontId);
      }
    };
  }, [logDataManager, onUpdatedLogs, onUpdatedErrors]);
  return { updatedLogs, updatedErrors, resetLogMessages };
};
