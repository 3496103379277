import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const BotsOnboarding = () => {
  const isDiscover = useIsFeatureFlag([FEATURES.IS_DISCOVER]);
  return (
    <>
      <Box sx={{ m: 3, mb: 4 }}>
        <Typography variant={'h5'}>Bots</Typography>
      </Box>
      <Box sx={{ mx: 3, my: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ pb: 2 }}>
            This is where you would see all your Bots, once you create or
            subscribe one.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" fontWeight="600" sx={{ pb: 2 }}>
            Let&apos;s get you started !
          </Typography>
          <Typography variant="subtitle1" sx={{ pb: 2 }}>
            Select one of the option below
          </Typography>
        </Box>
        <Box>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mb: 2, justifyContent: 'start' }}
            component={RouterLink}
            to={isDiscover ? '/discover/bots' : '/templates'}
          >
            {isDiscover
              ? 'Subscribe to a Bot from Discover'
              : 'Subscribe to a Bot from Templates'}
          </MuiButton>
        </Box>
      </Box>
    </>
  );
};

export default BotsOnboarding;
