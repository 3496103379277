import { Box, Grid } from '@mui/material';

import MarketplaceItems from 'app/design/itemsComponent/MarketplaceItems';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

// Service
import MaketplaceSearchTextbox from 'app/components/Marketplace/MaketplaceSearchTextbox';
import useGetMarketplaceBots, {
  MIN_TEXT_SEARCH,
} from 'services/Marketplace/GetMarketplaceBots';
import StatusMessage from '../Common/StatusMessage';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const MarketplaceSearchContainer = () => {
  const { t } = useTranslation();

  const isTraderSearch = window.location.pathname.startsWith('/traders');
  const {
    data: botData,
    keyword,
    handleSearchKeywordChange,
    isLoading,
  } = useGetMarketplaceBots(isTraderSearch);

  return (
    <>
      <MaketplaceSearchTextbox
        headerTitle={t('market.page_title.search.title')}
        hint={t('market.search.placeholder')}
        txtKeyword={keyword}
        handleChangeSearch={handleSearchKeywordChange}
      />
      {!isLoading && botData && keyword.length >= MIN_TEXT_SEARCH && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center' }}
          {...(isTraderSearch && { className: 'p-main-spacing' })}
        >
          <Grid
            container
            spacing={isTraderSearch ? 2 : 0}
            sx={{
              maxWidth: { xs: '32rem', md: '50rem' },
              flex: { lg: 1, xl: 1 },
              py: 2,
            }}
          >
            <MarketplaceItems
              bots={botData}
              isGraph={true}
              isSearch={true}
              isTrader={isTraderSearch}
            />
          </Grid>
        </Box>
      )}
      {isLoading && (
        <StatusMessage
          title=""
          subtitle={<FormattedMessage id="market.search.searching" />}
        />
      )}

      {!isLoading && keyword !== '' && botData && botData.length === 0 && (
        <StatusMessage
          title={<FormattedMessage id="market.search.no_result" />}
          subtitle={
            <>
              <FormattedMessage id="market.search.for" /> &quot;{keyword}
              &quot;
            </>
          }
        />
      )}
    </>
  );
};

export default memo(MarketplaceSearchContainer);
