import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { groupBy, encodeUrlData, decodeUrlData } from 'utils/GenericFunctions';
import PositionsListLayout from 'app/design/layouts/PositionsListLayout';
import BotHistoryPositionsModal from 'app/components/Bots/BotHistory/BotHistoryPositionsModal';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';

// Service
import { Position } from 'types/ApiServicesTypes';
import PageHeader from 'app/design/speedBot/PageHeader';
import useGetBotPositions from 'services/Bots/useGetBotPositions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function MarketplacePositionsPage() {
  const { id, title } = useParams<{ id: string; title: string }>();
  const { t } = useTranslation();
  const titleData = decodeUrlData(title);
  const history = useHistory();
  const [page, setPage] = React.useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(
    null,
  );
  const isFromTrader = window.location.pathname.startsWith('/trader');

  const baseApiUrl = `marketplace/bots/${id}`;

  // For change tab
  const handleChange = (newValue: number) => {
    const path = `/${
      isFromTrader ? 'trader' : 'discover'
    }/${id}/orders/${encodeUrlData(titleData)}`;
    if (newValue === 1) {
      history.replace({ pathname: path });
    }
  };

  const botId = parseInt(id);
  const { data, isLoading } = useGetBotPositions(botId, page, baseApiUrl);

  const totalPages =
    data && data.length > 0 ? Number(data[0]['total_pages']) : 0;
  const botPositions = data && data.length > 0 ? data : [];
  const botGrpPositions = groupBy(
    botPositions,
    'exit_time',
    true,
  ) as PositionsListLayoutProps;

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleModalOpen = (positionData: Position) => {
    setSelectedPosition(positionData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedPosition(null);
  };

  return (
    <>
      <Helmet>
        <title>Discover Positions</title>
        <meta name="description" content="Discover Positions Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="tabsWithBack"
        buttonText={`${titleData}`}
        buttonHandleClick={() => history.goBack()}
        breadcrumb={
          <Breadcrumbs
            page={
              isFromTrader
                ? PAGES.TRADERS_POSITIONS
                : PAGES.MARKETPLACE_POSITIONS
            }
            data={{ botName: title, botId: botId }}
          />
        }
        tabs={
          <ContentTabs
            value={0}
            onChange={(e, newVal) => handleChange(newVal)}
            tabs={[
              { label: t('buttons.position') },
              { label: t('buttons.order') },
            ]}
          />
        }
      />
      <PositionsListLayout
        data={botGrpPositions}
        page={page}
        totalPages={totalPages}
        handleChangePagination={handleChangePagination}
        handleClick={handleModalOpen}
        handleChangeTab={handleChange}
      />
      <BotHistoryPositionsModal
        open={openModal}
        handleClose={handleModalClose}
        data={selectedPosition || null}
        title={<FormattedMessage id="buttons.position" />}
      />
    </>
  );
}
