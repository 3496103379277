import { useMutation } from 'react-query';
import { GetStatistics } from 'services/apiServices';
import { Errors, GetStatisticsResult } from 'types/ApiServicesTypes';

export default function useGetStatisticsMutate(baseApiUrl: string) {
  const queryKey = ['GetStatistics', baseApiUrl];
  const mutation = useMutation<GetStatisticsResult, Errors>(queryKey, () =>
    GetStatistics(baseApiUrl, 'GET'),
  );

  return mutation;
}
