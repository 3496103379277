import { useQuery } from 'react-query';
import { GetBacktestQueue } from 'services/apiServices';
import { Errors, GetBacktestQueueResult } from 'types/ApiServicesTypes';

export default function useGetBacktestQueue() {
  const objQuery = useQuery<GetBacktestQueueResult, Errors>(
    'GetBacktestQueue',
    () => GetBacktestQueue(),
  );
  return objQuery;
}
