import { useQuery } from 'react-query';
import { GetBotPositions } from 'services/apiServices';
import { Errors, GetBotPositionsResult } from 'types/ApiServicesTypes';

export default function useGetBotPositions(
  botId: number,
  page: number,
  baseApiUrl: string,
  maxPage = 10,
) {
  const queryKey = ['GetBotPositions', baseApiUrl, botId, page, maxPage];
  const objQuery = useQuery<GetBotPositionsResult, Errors>(queryKey, () =>
    GetBotPositions(baseApiUrl, 'GET', botId, page, maxPage),
  );
  return objQuery;
}
