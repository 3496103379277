import {
  Box,
  // IconButton,
  Typography,
} from '@mui/material';
import NotificationButton from 'app/components/Portfolio/NotificationButton';
import { PageTitlePortfolioVariantProps } from '../types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
// import { IosShare } from '@mui/icons-material';
import SocialShareModal from 'app/components/Portfolio/SocialShareModal';
import usePostPortfolioShare from 'services/Portfolio/usePostPortfolioShare';
import moment from 'moment';
import { Suspense, useEffect, useState } from 'react';
import { isUserLoggedIn } from 'utils/auth';
import AppbarNotification from 'app/components/Layouts/MainLayout/AppbarNotification';
import useGetProfile from 'services/Menu/useGetProfile';
// import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
// import { FEATURES } from 'types/Feature';
// import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
// export const ToggleValue = signal(false);
const PageTitlePortfolioVariant = ({
  title,
  tabs,
  sideBarView,
}: PageTitlePortfolioVariantProps) => {
  // const isSharePortfolio = useIsFeatureFlag([FEATURES.IS_SHARE_PORTFOLIO]);
  const isMdUp = useGetMediaQueryUp('md');
  const [socialShareModal, setSocialShareModal] = useState(false);
  const [completeURL, setCompleteURL] = useState('');
  // const [toggleView, setToggleView] = useState<boolean>(false);
  // ToggleValue.value = toggleView;
  const URL: string = location.origin;
  const postportflioMutation = usePostPortfolioShare();
  const sendDate = moment(postportflioMutation.data?.date).format('YYYY-MM-DD');
  const isLoggedIn = isUserLoggedIn();
  // const { open, toggle: toggleView } = useContext(ToggleContext);
  // eslint-disable-next-line no-console
  // const isViewAllPositions = useIsFeatureFlag([FEATURES.IS_VIEW_ALL_POSITIONS]);
  // const handleMutateClick = () => {
  //   postportflioMutation.mutate();
  //   // eslint-disable-next-line no-console
  //   setSocialShareModal(true);
  // };
  // const clickToSendData = () => {
  // };

  // queryParameters==='active'?'Live':null
  useEffect(() => {
    setCompleteURL(
      `${URL}/portfoliopnl/share/${postportflioMutation.data?.user_id}?date=${sendDate}`,
    );
  }, [URL, postportflioMutation.data?.user_id, sendDate]);
  const { data: stiteInfo } = useGetSiteInfo();
  const { data: profile } = useGetProfile();

  return (
    <Box sx={{ pt: 3 }}>
      <Box
        className="p-main-spacing"
        sx={{
          display: 'flex',
          alignItems: 'center',
          pb: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flex: '1' }}>
          {!isMdUp && stiteInfo && stiteInfo.small_logo && (
            <img src={stiteInfo.small_logo} />
          )}
          <Typography variant="h5" fontWeight={500} sx={{ pl: 0.5 }}>
            {title}
          </Typography>
        </Box>
        {isMdUp === false ? (
          <Box>
            <Box
              // flex={1}
              display="flex"
              justifyContent="flex-end"
              // className="p-main-spacing"
              alignItems="center"
              gap={0.5}
            >
              {/* <Typography variant="body2">
              {toTitleCase(
                queryParameters === 'active' ? 'Live' : queryParameters,
              ) + ' '}
              bots
            </Typography> */}
              <>
                {/* {isViewAllPositions &&
                (queryParameters === 'active' ||
                  queryParameters === 'paper') && (
                  <>
                    <Tooltip
                      title={!open ? 'See all Positions' : 'See all bots'}
                    >
                      <MuiSwitchUnstyled checked={open} onChange={toggleView} />
                    </Tooltip>
                    <Typography variant="body3">
               
                      positions
                    </Typography>
                  </>
                )} */}
              </>
              {isLoggedIn && <NotificationButton />}
            </Box>
          </Box>
        ) : (
          sideBarView && (
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Suspense fallback={<div>Loading..</div>}>
                <AppbarNotification activeNav={'menu'} profile={profile} />
              </Suspense>
            </Box>
          )
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box flex={1}>{tabs}</Box>
      </Box>
      <SocialShareModal
        generatedURL={
          postportflioMutation.isLoading
            ? 'Your URL is being generated...'
            : completeURL
        }
        handleClose={() => setSocialShareModal(false)}
        open={socialShareModal}
      />
    </Box>
  );
};

export default PageTitlePortfolioVariant;
