import { memo } from 'react';
import { Typography, Box } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

const PlanCancelModal = ({
  open,
  date,
  isLoading,
  handleConfirm,
  handleClose,
}: {
  open: boolean;
  date: string;
  isLoading: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}) => {
  return (
    <>
      <MuiModal
        open={open}
        handleClose={handleClose}
        header={
          <Box className="text-ellipsis">
            <PageHeader variant="close" buttonHandleClick={handleClose} />
          </Box>
        }
        body={
          <>
            <CircularLoader open={isLoading} />
            <Box className="m-main-spacing">
              <Box sx={{ pb: 3 }}>
                <Typography variant="h6" sx={{ pb: 4 }}>
                  <FormattedMessage id="menu.cancel_your_plan" />
                </Typography>

                <Typography
                  variant="body1"
                  fontWeight={400}
                  sx={{ pb: 0.5 }}
                  color="text.secondary"
                >
                  <FormattedMessage id="menu.cancellation_will_be_effective" />
                </Typography>

                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1">{date}</Typography>
                </Box>

                <Typography variant="body1" fontWeight={400}>
                  <FormattedMessage id="menu.restart_your_plan_anytime" />
                </Typography>
              </Box>
            </Box>
          </>
        }
        footer={
          <Box className="m-main-spacing" sx={{ pb: 2 }}>
            <MuiButton
              variant="contained"
              fullWidth
              onClick={handleConfirm}
              sx={{ mb: 2 }}
            >
              <FormattedMessage id="buttons.confirm_cancellation" />
            </MuiButton>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </Box>
        }
      />
    </>
  );
};
export default memo(PlanCancelModal);
