import { Grid } from '@mui/material';

const OnlyIframe = ({ title, url }: { title: string; url: string }) => {
  return (
    <Grid
      item
      order={{ xs: 1, md: 1, lg: 2 }}
      sx={{
        aspectRatio: '16/9',
        width: { xs: '100%', lg: '33%' },
        maxHeight: '450px',
        mb: { xs: 2, lg: 0 },
        ml: { xs: 0, lg: 0 },
      }}
    >
      <iframe
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '0.8em',
        }}
        src={url}
        title={title}
        // frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </Grid>
  );
};

export default OnlyIframe;
