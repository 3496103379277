import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  GeneratorPages,
  BreadcrumbData,
} from 'app/components/Common/Breadcrumb/types';
import ListViewCalendar from 'app/design/uiComponents/Calendar/ListViewCalendar';
import MonthCalendar from 'app/design/uiComponents/Calendar/MonthCalendar';
import { transformProfitLossByDateData } from 'app/design/uiComponents/Calendar/utils';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
// import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import PageHeader from '../../PageHeader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
// import { formatDateString, ifFutureDate } from 'utils/GenericFunctions';

// Services
import useGetProfitLossByDateMutate from 'services/Statistics/useGetProfitLossByDateMutate';
import useGetStatisticsMutate from 'services/Statistics/useGetStatisticsMutate';
import { ProfitLossByDateData } from 'types/ApiServicesTypes';
import StatusMessage from 'app/components/Common/StatusMessage';
import ProfilLossByDateSummaryDetail from './ProfilLossByDateSummaryDetail';
import { formatDateString, ifFutureDate } from 'utils/GenericFunctions';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

let page = 1;
let isEnd = false;

const ProfitLossByDateReport = ({
  baseUrl,
  pageHeadButtonText,
  breadCrumbPage,
  breadCrumbData,
}: {
  baseUrl: string;
  pageHeadButtonText: string;
  breadCrumbPage: GeneratorPages;
  breadCrumbData: BreadcrumbData;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const isMdUp = useGetMediaQueryUp('md');
  const [isListView, setIsListView] = useState<boolean>(true);
  const [withFees, setWithFees] = useState<boolean>(false);
  const [data, _setData] = useState<ProfitLossByDateData | null>(null);
  const dataStateRef = useRef<ProfitLossByDateData | null>(null);
  const minMaxDateRef = useRef({ minDate: '', maxDate: '' });
  const mutationStatistics = useGetStatisticsMutate(baseUrl);
  const mutation = useGetProfitLossByDateMutate(baseUrl);

  const setData = (data: ProfitLossByDateData | null) => {
    dataStateRef.current = data;
    _setData(data);
  };

  // For set initial values when render a component first time
  useEffect(() => {
    page = 1;
    isEnd = false;
  }, []);

  useEffect(() => {
    page = 1;
    isEnd = false;
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withFees]);

  const fetchData = (isReset?: boolean) => {
    if (isEnd) return;
    const { minDate, maxDate } = minMaxDateRef.current;
    if (!minDate || !maxDate) return;

    mutation.mutate(
      { minDate, maxDate, page, withFees, period: 'day' },
      {
        onSuccess: res => {
          if (res) {
            const dateDate = isReset
              ? null
              : data
              ? data
              : dataStateRef.current;

            const resultData: ProfitLossByDateData | null = res;
            if (dateDate) {
              resultData.profitLossData = [
                ...dateDate.profitLossData,
                ...resultData.profitLossData,
              ];
            }
            resultData.endDate = resultData.maxDate;

            // For range is over for fetching the data
            if (new Date(resultData.startDate) < new Date(resultData.minDate)) {
              resultData.startDate = resultData.minDate;
              isEnd = true;
            }

            if (res.profitLossData && res.profitLossData.length)
              resultData.transformProfitLossData =
                transformProfitLossByDateData(resultData.profitLossData);
            setData(resultData);
            page++;

            if (
              isListView &&
              new Date(resultData.startDate) >= new Date(resultData.minDate) &&
              resultData.profitLossData.length === 0
            ) {
              fetchData();
            }
          }
        },
      },
    );
  };

  useEffect(() => {
    if (isListView && !isEnd && (!data || data.profitLossData.length === 0)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListView]);

  // // For load more data when scroll go down
  // // Add scroll event
  // const handleScroll = (e: Event) => {
  //   if (isEnd) return;

  //   const el = e.target as HTMLInputElement;
  //   const containerHeight = el.clientHeight;
  //   const scrollHeight = el.scrollHeight;
  //   const scrollTop = el.scrollTop;

  //   if (scrollHeight - (containerHeight + scrollTop) <= 0) {
  //     fetchData();
  //   }
  // };

  useEffect(() => {
    mutationStatistics.mutate(void '', {
      onSuccess: res => {
        let endDate = res.statistics.end_date;
        if (ifFutureDate(res.statistics.end_date) === 'Current') {
          endDate = formatDateString(new Date(), 'YYYY-MM-DD');
        }
        minMaxDateRef.current = {
          minDate: res.statistics.start_date,
          maxDate: endDate,
        };
        fetchData();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const el = document.getElementById('main-container');
  //   if (el) el.onscroll = handleScroll;

  //   return () => {
  //     if (el) el.onscroll = null;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <CircularLoader
        open={mutationStatistics.isLoading || mutation.isLoading}
      />
      <Box>
        <PageHeader
          variant="withIconWithDescriptionWithBack"
          title={t('reports.page_title.profit_and_loss')}
          description={t('reports.by_date')}
          descriptionSubItem={
            <FeatureFlag
              features={[FEATURES.IS_CHARGES_AND_PROFIT]}
              fallback={<></>}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={withFees}
                    onChange={e => setWithFees(e.target.checked)}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight={400}
                  >
                    {t('reports.with_fees')}
                  </Typography>
                }
                labelPlacement="start"
              />
            </FeatureFlag>
          }
          iconProps={{
            icon: isListView ? <CalendarTodayIcon /> : <ListIcon />,
            // icon: <ListIcon />,
            // selected: isListView,
            selected: false,
            handleClick: () => setIsListView(!isListView),
            tooltipText: isListView
              ? t('reports.view_calendar')
              : t('reports.view_summary'),
          }}
          buttonText={pageHeadButtonText}
          buttonHandleClick={() => {
            history.goBack();
          }}
          breadcrumb={
            <Breadcrumbs page={breadCrumbPage} data={breadCrumbData} />
          }
        />
      </Box>
      <Box className="m-main-spacing" sx={{ mb: 2 }}>
        <Typography
          variant={'body2'}
          sx={{ fontWeight: 400, color: 'text.secondary' }}
        >
          {withFees
            ? t('reports.summay_with_fees')
            : t('reports.summay_without_fees')}
        </Typography>
      </Box>
      {mutationStatistics.data && (
        <Box>
          <ProfilLossByDateSummaryDetail
            currencySymbol={mutationStatistics.data.currency_symbol}
            net_profit_loss={mutationStatistics.data.statistics.net_profit}
            total_profit_loss={mutationStatistics.data.statistics.total_profit}
            total_fees={mutationStatistics.data.statistics.total_fees}
            baseUrl={baseUrl}
          />
        </Box>
      )}
      <Box>
        <Box sx={{ mb: 2 }}>
          {data && data.profitLossData.length && isListView ? (
            <ListViewCalendar
              currencySymbol={mutationStatistics.data?.currency_symbol || ''}
              data={data}
              initialCapital={
                mutationStatistics.data
                  ? mutationStatistics.data.statistics.initial_capital
                  : 0
              }
            />
          ) : data && data.profitLossData.length ? (
            <MonthCalendar data={data} />
          ) : data && !data.profitLossData.length ? (
            <StatusMessage
              title={t('reports.report_empty')}
              subtitle={t('reports.couldnt_find')}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        {/* {isMdUp && !isEnd && data && data.profitLossData.length > 0 && ( */}
        {!isEnd && data && data.profitLossData.length > 0 && (
          <MuiButton
            variant="contained"
            color="secondary"
            onClick={() => fetchData()}
          >
            <FormattedMessage id="buttons.load_more" />
          </MuiButton>
        )}
      </Box>
    </>
  );
};

export default memo(ProfitLossByDateReport);
