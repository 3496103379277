import { memo, useCallback, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { StatisticsData } from 'types/ApiServicesTypes';
import {
  decimalFormat,
  formatNumber,
  getArrowDirByNum,
  getColorByNum,
  getDate,
  getDuration,
  getDateWithToday,
} from 'utils/GenericFunctions';
import { ItemSeparator } from '../../EntityItem';
import InfoItemWrapper from '../../Common/InfoItemWrapper';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import CapitalChangeModal from './CapitalChangeModal';
import MuiButton from 'app/design/uiComponents/MuiButton';

const InfoListBacktesting = ({
  data,
  mddAmount,
  baseApiUrl,
  currencySymbol,
}: {
  data: StatisticsData;
  mddAmount: number;
  baseApiUrl: string;
  currencySymbol: string;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const isFromDiscover = baseApiUrl.startsWith('marketplace');
  let isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);
  isShowResult = isFromDiscover ? isShowResult : true;
  const [initialCapital, setInitialCapital] = useState<number>(
    data.initial_capital,
  );
  const [openCapitalChangeModal, setOpenCapitalChangeModal] =
    useState<boolean>(false);

  const handleCapitalChange = useCallback(
    (success: boolean, capital: number) => {
      if (success) setInitialCapital(capital);
      setOpenCapitalChangeModal(false);
    },
    [],
  );
  const feesPercentage = (data.total_fees * 100) / data.initial_capital;

  const isStatisticsCalculator = useIsFeatureFlag([
    FEATURES.IS_STATISTICS_CALCULATOR,
  ]);

  // Only check mdd for backtest statistics
  const isShowDDInAmount = useIsFeatureFlag([FEATURES.IS_SHOW_DD_IN_AMOUNT]);

  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  // data.end_date = formatDateString(new Date(), 'YYYY-MM-DD');
  const startDate = data.start_date
    ? getDateWithToday(data.start_date)
    : undefined;
  const endDate = data.end_date ? getDateWithToday(data.end_date) : undefined;

  return (
    <Box sx={{ px: 3, py: 2 }}>
      {(data.initial_capital !== undefined ||
        data.candle_interval !== undefined) && (
        <>
          <Grid container spacing={0}>
            {data.initial_capital !== undefined && (
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: {
                    xs: 'block',
                    md: 'flex',
                  },
                  alignItems: 'center',
                  pb: 2,
                  gap: 2,
                }}
              >
                {isStatisticsCalculator && !isMdUp ? (
                  <MuiButton
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ mb: 2 }}
                    onClick={() => setOpenCapitalChangeModal(true)}
                  >
                    <FormattedMessage id="summary_labels.form.profit_calculator" />
                  </MuiButton>
                ) : null}
                <InfoItemWrapper>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.initial_capital" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                    sx={{ my: 'auto' }}
                  >
                    {`${currencySymbol}${formatNumber(initialCapital)}`}
                  </Typography>
                </InfoItemWrapper>
                {isStatisticsCalculator && isMdUp ? (
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenCapitalChangeModal(true)}
                  >
                    <FormattedMessage id="summary_labels.form.profit_calculator" />
                  </MuiButton>
                ) : null}
              </Grid>
            )}
            {/* {data.candle_interval !== undefined && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.candle_interval" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                  >
                    {data.candle_interval}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )} */}
            {isShowResult &&
              !isChargesAndProfit &&
              data.total_profit !== undefined && (
                <Grid item xs={12} md={4}>
                  <InfoItemWrapper pb={2}>
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ pt: { md: 0.5 } }}
                    >
                      <FormattedMessage id="summary_labels.total_profit" />
                    </Typography>
                    <Typography
                      variant={isMdUp ? 'body2' : 'body3'}
                      fontWeight={{ xs: 500, md: 400 }}
                      color={getColorByNum(data.total_profit)}
                    >
                      {`${getArrowDirByNum(
                        data.total_profit,
                      )} ${currencySymbol}${formatNumber(
                        isStatisticsCalculator
                          ? (initialCapital * data.total_profit_percentage) /
                              100
                          : data.total_profit,
                        false,
                        0,
                      )}`}
                      <Typography
                        variant="body3"
                        fontWeight={{ xs: 500, md: 400 }}
                        sx={{ ml: 0.5 }}
                      >
                        {data.total_profit_percentage
                          ? `(${
                              data.total_profit > 0 ? '+' : ''
                            }${decimalFormat(data.total_profit_percentage)}%)`
                          : `(${decimalFormat(0)}%)`}
                      </Typography>
                    </Typography>
                  </InfoItemWrapper>
                </Grid>
              )}
          </Grid>
          <ItemSeparator variant="1px" />
        </>
      )}
      {(data.start_date !== undefined ||
        data.end_date !== undefined ||
        data.total_trads !== undefined) && (
        <>
          <Grid container spacing={0} sx={{ pt: 2 }}>
            {(data.start_date !== undefined || data.end_date !== undefined) && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.period" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                  >
                    {`${startDate} ${data.end_date ? `→ ${endDate}` : ''}`}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
            {data.start_date !== undefined && data.end_date !== undefined && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.duration" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                  >
                    {getDuration(
                      startDate === 'Today'
                        ? getDate(new Date(), false)
                        : data.start_date,
                      endDate === 'Today'
                        ? getDate(new Date(), false)
                        : data.end_date,
                    )}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
            {data.total_trads !== undefined && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.positions" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                    sx={{ display: 'inline-block' }}
                  >
                    {data.total_trads ? data.total_trads : 0}
                    <Typography
                      variant="body3"
                      fontWeight={{ xs: 500, md: 400 }}
                      sx={{ ml: 0.5 }}
                    >
                      (
                    </Typography>
                    <Typography
                      variant="body3"
                      fontWeight={{ xs: 500, md: 400 }}
                      color={'success.main'}
                    >
                      {data.winrate
                        ? `+${decimalFormat(data.winrate)}%`
                        : `${decimalFormat(0)}%`}
                    </Typography>
                    <Typography
                      variant="body3"
                      fontWeight={{ xs: 500, md: 400 }}
                      sx={{ ml: 0.5 }}
                    >
                      |
                    </Typography>
                    <Typography
                      variant="body3"
                      fontWeight={{ xs: 500, md: 400 }}
                      color={'error.main'}
                      sx={{ ml: 0.5 }}
                    >
                      {data.lossrate
                        ? `-${decimalFormat(data.lossrate)}%`
                        : `${decimalFormat(0)}%`}
                    </Typography>
                    <Typography
                      variant="body3"
                      fontWeight={{ xs: 500, md: 400 }}
                    >
                      )
                    </Typography>
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
          </Grid>
          <ItemSeparator variant="1px" />
        </>
      )}
      {isShowResult &&
        isChargesAndProfit &&
        (data.total_profit !== undefined ||
          data.total_fees !== undefined ||
          data.net_profit !== undefined) && (
          <>
            <Grid container spacing={0} sx={{ pt: 2 }}>
              {data.total_profit !== undefined && (
                <Grid item xs={12} md={4}>
                  <InfoItemWrapper pb={2}>
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ pt: { md: 0.5 } }}
                    >
                      <FormattedMessage id="summary_labels.total_profit" />
                    </Typography>
                    <Typography
                      variant={isMdUp ? 'body2' : 'body3'}
                      fontWeight={{ xs: 500, md: 400 }}
                      color={getColorByNum(data.total_profit)}
                    >
                      {`${getArrowDirByNum(
                        data.total_profit,
                      )} ${currencySymbol}${formatNumber(
                        isStatisticsCalculator
                          ? (initialCapital * data.total_profit_percentage) /
                              100
                          : data.total_profit,
                        false,
                        0,
                      )}`}
                      <Typography
                        variant="body3"
                        fontWeight={{ xs: 500, md: 400 }}
                        sx={{ ml: 0.5 }}
                      >
                        {data.total_profit_percentage
                          ? `(${
                              data.total_profit > 0 ? '+' : ''
                            }${decimalFormat(data.total_profit_percentage)}%)`
                          : `(${decimalFormat(0)}%)`}
                      </Typography>
                    </Typography>
                  </InfoItemWrapper>
                </Grid>
              )}
              {data.total_fees !== undefined && (
                <Grid item xs={12} md={4}>
                  <InfoItemWrapper pb={2}>
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ pt: { md: 0.5 } }}
                    >
                      <FormattedMessage id="brokerage_fees" />
                    </Typography>
                    <Typography
                      variant={isMdUp ? 'body2' : 'body3'}
                      fontWeight={{ xs: 500, md: 400 }}
                    >
                      {data.total_fees
                        ? `${currencySymbol}${formatNumber(
                            isStatisticsCalculator
                              ? (initialCapital * feesPercentage) / 100
                              : data.total_fees,
                            false,
                            0,
                          )}`
                        : `${currencySymbol}${formatNumber(0)}`}
                    </Typography>
                  </InfoItemWrapper>
                </Grid>
              )}
              {data.net_profit !== undefined && (
                <Grid item xs={12} md={4}>
                  <InfoItemWrapper pb={2}>
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ pt: { md: 0.5 } }}
                    >
                      <FormattedMessage id="summary_labels.net_profit" />
                    </Typography>
                    <Typography
                      variant={isMdUp ? 'body2' : 'body3'}
                      fontWeight={{ xs: 500, md: 400 }}
                      color={getColorByNum(data.net_profit)}
                    >
                      {`${getArrowDirByNum(
                        data.net_profit,
                      )} ${currencySymbol}${formatNumber(
                        isStatisticsCalculator
                          ? (initialCapital * data.net_profit_percentage) / 100
                          : data.net_profit,
                        false,
                        0,
                      )}`}
                      <Typography
                        variant="body3"
                        fontWeight={{ xs: 500, md: 400 }}
                        sx={{ ml: 0.5 }}
                      >
                        {data.net_profit_percentage
                          ? `(${data.net_profit > 0 ? '+' : ''}${decimalFormat(
                              data.net_profit_percentage,
                            )}%)`
                          : `(${decimalFormat(0)}%)`}
                      </Typography>
                    </Typography>
                  </InfoItemWrapper>
                </Grid>
              )}
            </Grid>
            <ItemSeparator variant="1px" />
          </>
        )}
      {(data.car !== undefined ||
        data.drawdown !== undefined ||
        data.sharp_ratio !== undefined) && (
        <>
          <Grid container spacing={0} sx={{ pt: 2 }}>
            {isShowResult && data.car !== undefined && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.annual_return" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                    color={getColorByNum(data.annual_return_percentage)}
                  >
                    {`${data.annual_return_percentage}%`}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
            {!isShowResult && isChargesAndProfit && data.total_profit && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="brokerage_fees" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                  >
                    {data.total_fees
                      ? `${currencySymbol}${formatNumber(
                          isStatisticsCalculator
                            ? initialCapital * feesPercentage
                            : data.total_fees,
                          false,
                          0,
                        )}`
                      : `${currencySymbol}${formatNumber(0)}`}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
            {data.drawdown !== undefined && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.mdd" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                    color={data.drawdown < 0 ? 'error.main' : 'text.primary'}
                  >
                    {isShowDDInAmount
                      ? `${currencySymbol}${formatNumber(
                          isStatisticsCalculator
                            ? (initialCapital * data.drawdown) / 100
                            : mddAmount,
                        )} (${decimalFormat(data.drawdown)}%)`
                      : `${decimalFormat(data.drawdown)}%`}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
            {data.max_drawdown_duration !== undefined && (
              <Grid item xs={12} md={4}>
                <InfoItemWrapper pb={2}>
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    sx={{ pt: { md: 0.5 } }}
                  >
                    <FormattedMessage id="summary_labels.mdd_duration" />
                  </Typography>
                  <Typography
                    variant={isMdUp ? 'body2' : 'body3'}
                    fontWeight={{ xs: 500, md: 400 }}
                  >
                    {data.max_drawdown_duration}
                  </Typography>
                </InfoItemWrapper>
              </Grid>
            )}
          </Grid>
          <ItemSeparator variant="1px" />
        </>
      )}
      {(data.average_profit !== undefined ||
        data.average_loss !== undefined ||
        data.max_profit !== undefined ||
        data.max_loss !== undefined) && (
        <Box display={{ xs: 'block', md: 'flex' }}>
          {(data.average_profit !== undefined ||
            data.average_loss !== undefined) && (
            <>
              <Box flex={1} sx={{ pt: 2 }}>
                <Typography
                  variant="body3"
                  color="text.secondary"
                  sx={{ pb: { md: 1 }, display: { xs: 'none', md: 'block' } }}
                >
                  <FormattedMessage id="summary_labels.avg.title_day" />
                </Typography>
                <Grid container spacing={0}>
                  {data.average_profit !== undefined && (
                    <Grid item xs={12} md={6}>
                      <InfoItemWrapper pb={isMdUp ? 0 : 2}>
                        <Typography
                          variant="body3"
                          color="text.secondary"
                          sx={{ pt: { md: 0.5 } }}
                        >
                          {isMdUp ? (
                            <FormattedMessage id="summary_labels.gross_profit" />
                          ) : (
                            <FormattedMessage id="summary_labels.avg.avg_profit" />
                          )}
                        </Typography>
                        <Typography
                          variant={isMdUp ? 'body2' : 'body3'}
                          fontWeight={{ xs: 500, md: 400 }}
                          color={getColorByNum(data.average_profit)}
                        >
                          {`${currencySymbol}${formatNumber(
                            isStatisticsCalculator
                              ? (initialCapital *
                                  data.average_profit_percentage) /
                                  100
                              : data.average_profit,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {data.average_profit_percentage
                              ? `(${
                                  data.average_profit > 0 ? '+' : ''
                                }${decimalFormat(
                                  data.average_profit_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </Typography>
                      </InfoItemWrapper>
                    </Grid>
                  )}
                  {data.average_loss !== undefined && (
                    <Grid item xs={12} md={6}>
                      <InfoItemWrapper pb={isMdUp ? 0 : 2}>
                        <Typography
                          variant="body3"
                          color="text.secondary"
                          sx={{ pt: { md: 0.5 } }}
                        >
                          {isMdUp ? (
                            <FormattedMessage id="summary_labels.gross_loss" />
                          ) : (
                            <FormattedMessage id="summary_labels.avg.avg_loss" />
                          )}
                        </Typography>
                        <Typography
                          variant={isMdUp ? 'body2' : 'body3'}
                          fontWeight={{ xs: 500, md: 400 }}
                          color={getColorByNum(data.average_loss)}
                        >
                          {`${currencySymbol}${formatNumber(
                            isStatisticsCalculator
                              ? (initialCapital *
                                  data.average_loss_percentage) /
                                  100
                              : data.average_loss,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {data.average_loss_percentage
                              ? `(${decimalFormat(
                                  data.average_loss_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </Typography>
                      </InfoItemWrapper>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {isMdUp && (
                <Box sx={{ mx: 2, my: 1, pt: 2 }} display="flex">
                  <ItemSeparator variant="1px" orientation="vertical" />
                </Box>
              )}
              {!isMdUp && <ItemSeparator variant="1px" />}
            </>
          )}
          {(data.max_profit !== undefined || data.max_loss !== undefined) && (
            <Box flex={1} sx={{ pt: 2 }}>
              <Typography
                variant="body3"
                color="text.secondary"
                sx={{ pb: { md: 1 }, display: { xs: 'none', md: 'block' } }}
              >
                <FormattedMessage id="summary_labels.max.title" />
              </Typography>
              <Grid container spacing={0}>
                {data.max_profit !== undefined && (
                  <Grid item xs={12} md={6}>
                    <InfoItemWrapper pb={isMdUp ? 0 : 2}>
                      <Typography
                        variant="body3"
                        color="text.secondary"
                        sx={{ pt: { md: 0.5 } }}
                      >
                        {isMdUp ? (
                          <FormattedMessage id="summary_labels.gross_profit" />
                        ) : (
                          <FormattedMessage id="summary_labels.max.max_profit" />
                        )}
                      </Typography>
                      <Typography
                        variant={isMdUp ? 'body2' : 'body3'}
                        fontWeight={{ xs: 500, md: 400 }}
                        color={getColorByNum(data.max_profit)}
                      >
                        {`${currencySymbol}${formatNumber(
                          isStatisticsCalculator
                            ? (initialCapital * data.max_profit_percentage) /
                                100
                            : data.max_profit,
                          false,
                          0,
                        )}`}
                        <Typography variant="body3" sx={{ ml: 0.5 }}>
                          {data.max_profit_percentage
                            ? `(${
                                data.max_profit > 0 ? '+' : ''
                              }${decimalFormat(data.max_profit_percentage)}%)`
                            : `(${decimalFormat(0)}%)`}
                        </Typography>
                      </Typography>
                    </InfoItemWrapper>
                  </Grid>
                )}
                {data.max_loss !== undefined && (
                  <Grid item xs={12} md={6}>
                    <InfoItemWrapper>
                      <Typography
                        variant="body3"
                        color="text.secondary"
                        sx={{ pt: { md: 0.5 } }}
                      >
                        {isMdUp ? (
                          <FormattedMessage id="summary_labels.gross_loss" />
                        ) : (
                          <FormattedMessage id="summary_labels.max.max_loss" />
                        )}
                      </Typography>
                      <Typography
                        variant={isMdUp ? 'body2' : 'body3'}
                        fontWeight={{ xs: 500, md: 400 }}
                        color={getColorByNum(data.max_loss)}
                      >
                        {`${currencySymbol}${formatNumber(
                          isStatisticsCalculator
                            ? (initialCapital * data.max_loss_percentage) / 100
                            : data.max_loss,
                          false,
                          0,
                        )}`}
                        <Typography variant="body3" sx={{ ml: 0.5 }}>
                          {data.max_loss_percentage
                            ? `(${data.max_loss > 0 ? '+' : ''}${decimalFormat(
                                data.max_loss_percentage,
                              )}%)`
                            : `(${decimalFormat(0)}%)`}
                        </Typography>
                      </Typography>
                    </InfoItemWrapper>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      )}
      <CapitalChangeModal
        initialCapital={initialCapital}
        open={openCapitalChangeModal}
        handleClose={handleCapitalChange}
      />
    </Box>
  );
};

export default memo(InfoListBacktesting);
