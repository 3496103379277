import { useQuery } from 'react-query';
import { GetBotHisory } from 'services/apiServices';
import { Errors, GetBotHistoryResult } from 'types/ApiServicesTypes';

export default function useGetBotHistory(bId: number) {
  const queryKey = ['GetBotHistory', bId];
  const objQuery = useQuery<GetBotHistoryResult, Errors>(queryKey, () =>
    GetBotHisory(bId),
  );

  return objQuery;
}
