import { memo } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import {
  BarChart,
  Bar,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  CartesianGrid,
  Tooltip,
  // Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import Typography from '@mui/material/Typography';

import GreyBgCard from 'app/design/uiComponents/BoxGrey';
import { getMarketPlaceBotGraphData } from 'services/apiServices';
import { BotReturnsGraphData, Errors } from 'types/ApiServicesTypes';

import { BarGraphPoint } from 'app/design/uiComponents/Charts/BaseBarChart';
import {
  GRAPH_COLOR,
  // STROKE_COLOR,
  BAR_STROKE_COLOR,
  RED_STROKE_COLOR,
} from 'app/design/uiComponents/Charts/constants';

import { calculateAmountUnitWise, decimalFormat } from 'utils/GenericFunctions';
import {
  calculateAmountUnit,
  calculateXAxisTicks,
  capitalFormatter,
  getGroupBy,
  renderXAxisTick,
  xAxisTickFormatter,
  yAxisTickFormatter,
} from '../ChartUtils';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useIsFeatureFlag } from '../../FeatureFlag';
import { FEATURES } from 'types/Feature';

export const convertGraphDataToPoints = (
  graphData: BotReturnsGraphData,
  amountUnit: string,
): BarGraphPoint[] => {
  const points = [] as BarGraphPoint[];

  graphData.forEach(item => {
    const date = moment(
      item.label,
      item.label.split(' ').length === 3
        ? 'DD MMM YYYY'
        : item.label.split(' ').length === 2
        ? 'MMMM YYYY'
        : 'YYYY',
    ).toDate();
    const amount = calculateAmountUnitWise(Number(item.value), amountUnit);
    const yKey = amount > 0 ? 'positiveY' : 'negativeY';
    points.push({
      x: date.getTime(),
      date,
      [yKey]: parseFloat(decimalFormat(amount)) || 0,
      label: item.label,
      percentage: item.value2,
    });
  });

  return points;
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<Payload<ValueType, NameType>>;
  label?: string;
  amountUnit: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload, amountUnit } = props;
  if (active && payload && payload.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const point = payload[0].payload;
    const capital = capitalFormatter(
      point.negativeY
        ? (point.negativeY as number)
        : (point.positiveY as number),
      amountUnit,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const percent = point.percentage;

    return (
      <GreyBgCard padding={1}>
        <Typography variant="body2" sx={{ fontSize: '0.625rem' }}>
          {/* {`Date : ${formatDateString(
            point.date as string,
            'DD MMM YYYY HH:MM:SS',
          )}`} */}
          {`Date : ${point.label}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: '0.625rem' }}
        >{`Net Capital : ${capital}`}</Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: '0.625rem' }}
        >{`Percent : ${percent}%`}</Typography>
      </GreyBgCard>
    );
  }

  return null;
};

interface Props {
  id: number;
  baseApiUrl: string;
  minHeight?: string;
}

const ReturnsGraph = (props: Props) => {
  const { minHeight, baseApiUrl } = props;
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);
  // Api calling for fetch returns Bar graph data
  const { data } = useQuery<BotReturnsGraphData, Errors>(
    ['GetMarketplacBarGraphReturns', baseApiUrl],
    () =>
      getMarketPlaceBotGraphData<BotReturnsGraphData>(
        baseApiUrl,
        'returns',
        !isChargesAndProfit,
      ),
  );

  if (!data) {
    return null;
  }

  const graphApiResponse = data || [];
  const amounts = graphApiResponse.map(item => item.value);
  const amountUnit = calculateAmountUnit(amounts);
  const graphData = convertGraphDataToPoints(graphApiResponse, amountUnit);
  const dates = graphData.map(item => item.date);
  const groupBy = getGroupBy(dates);
  const yAxisProps = {
    tickSize: 0,
    tickMargin: 5,
    orientation: 'right' as YAxisProps['orientation'],
    width: 30,
    tickFormatter: yAxisTickFormatter(amountUnit),
  };

  const xAxisTicks = calculateXAxisTicks(dates as Date[], groupBy);
  const xAxisProps = {
    dataKey: 'x',
    ticks: xAxisTicks,
    // padding: { left: 0 },
    scale: 'time' as XAxisProps['scale'],
    type: 'number' as XAxisProps['type'],
    domain: ['dataMin', 'auto'],
    tickSize: 20,
    interval: 'preserveStartEnd' as XAxisProps['interval'],
    tickFormatter: xAxisTickFormatter(groupBy),
    tick: renderXAxisTick(groupBy)(xAxisTicks),
    // minTickGap: 10,
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      {...(minHeight && { minHeight: minHeight })}
    >
      <BarChart data={graphData}>
        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="25%" stopColor={GRAPH_COLOR} stopOpacity={99.77} />
            <stop offset="100%" stopColor={GRAPH_COLOR} stopOpacity={-30.03} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis {...xAxisProps} padding={{ left: 10, right: 10 }} />
        <YAxis {...yAxisProps} />
        <Tooltip
          content={<CustomTooltip amountUnit={amountUnit} />}
          cursor={false}
        />
        <ReferenceLine y={0} stroke={'rgba(0, 0, 0, 0.04)'} />
        {/* <Bar dataKey="y" fill="url(#gradientFill)" stroke={STROKE_COLOR} /> */}
        <Bar
          dataKey="positiveY"
          stackId="a"
          fill={BAR_STROKE_COLOR}
          stroke={BAR_STROKE_COLOR}
          maxBarSize={20}
        />
        <Bar
          dataKey="negativeY"
          stackId="a"
          fill={RED_STROKE_COLOR}
          stroke={RED_STROKE_COLOR}
          maxBarSize={20}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default memo(ReturnsGraph);
