import moment from 'moment';
import { useMutation } from 'react-query';
import { GetBotPositionsForExcel } from 'services/apiServices';
import { Errors, GetBotPositionsResult } from 'types/ApiServicesTypes';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { ToastContext } from 'app/components/Context/ToastContext';
import { SymbolsData } from 'types/Symbols';

const MONTH_PAGINATION = 6;
export default function useGetBotPositionsForExcel() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    GetBotPositionsResult,
    Errors,
    Pick<
      {
        botId: number;
        baseApiUrl: string;
        minDate: string;
        maxDate: string;
        page: number;
        withFees: boolean;
        period: string;
        withPercentage: boolean;
        fileName: string;
        liveFeedData: SymbolsData;
      },
      | 'botId'
      | 'fileName'
      | 'baseApiUrl'
      | 'minDate'
      | 'maxDate'
      | 'page'
      | 'withFees'
      | 'period'
      | 'withPercentage'
      | 'liveFeedData'
    >
  >('GetBotPositions', async params => {
    let startDt = params.minDate,
      endDt = params.maxDate;
    if (params.period !== 'month') {
      const subtractedDates = getMonthSubtractedDate(
        params.maxDate,
        params.page,
      );
      startDt = subtractedDates.startDt;
      endDt = subtractedDates.endDt;
    }
    return await GetBotPositionsForExcel(
      params.baseApiUrl,
      'GET',
      params.botId,
      startDt,
      endDt,
      params.withFees,
      params.period,
      params.withPercentage,
      params.liveFeedData,
    )
      .then(res => {
        saveAs(
          new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' }),
          `${params.fileName ? params.fileName : ''}_${
            startDt ? startDt : ''
          }_${endDt ? endDt : ''}.xlsx`,
        );
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      });
  });

  return mutation;
}
const getMonthSubtractedDate = (lastDate: string, page: number) => {
  const maxDate = moment(lastDate);
  const endDate = moment(maxDate)
    .subtract((page - 1) * MONTH_PAGINATION, 'M')
    .endOf('month');
  const endDt = endDate.format('YYYY-MM-DD');
  const startDate = endDate
    .subtract(MONTH_PAGINATION - 1, 'M')
    .startOf('month');
  const startDt = startDate.format('YYYY-MM-DD');

  return { startDt: startDt, endDt: endDt };
};
