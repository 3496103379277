import { useQuery } from 'react-query';
import { GetTradeIdeasMetadata } from 'services/apiServices';
import { Errors, GetTradesIdeasMetadataResult } from 'types/ApiServicesTypes';

export default function useGetTradeIdeasMetaData() {
  const objQuery = useQuery<GetTradesIdeasMetadataResult, Errors>(
    ['GetTradeIdeasMetadata'],
    () => GetTradeIdeasMetadata().then(res => res),
  );

  return objQuery;
}
