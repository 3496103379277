import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useHistory } from 'react-router-dom';
import {
  ItemHead,
  ItemSeparator,
  BillingItem,
} from 'app/design/speedBot/EntityItem';

export function SellOnMarketBillingDetailsPage() {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Sell On Discover Edit</title>
        <meta name="description" content="Sell On Discover Page Edit" />
      </Helmet>
      <CircularLoader open={false} />

      <Box>
        <PageHeader
          variant="inactive"
          buttonText={'Sell On Discover'}
          title={
            <FormattedMessage id="sell_on_market.page_title.sell_on_market_billing_details" />
          }
          buttonHandleClick={() => history.goBack()}
        />
      </Box>

      <ItemHead
        variant="small"
        title={<FormattedMessage id="menu.billing_history" />}
      />
      <ItemSeparator variant="1px" className="m-main-spacing" />
      <BillingItem
        orderDate={'7 Feb 2022'}
        amount={1250}
        fromDate={'07/12/2021'}
        toDate={'06/01/2022'}
        subValue={`5 x ₹${250}`}
      />
      <ItemSeparator variant="1px" className="m-main-spacing" />
      <BillingItem
        orderDate={'7 Feb 2022'}
        amount={250}
        fromDate={'07/12/2021'}
        toDate={'06/01/2022'}
        subValue={`4 x ₹${1000}`}
      />
      <ItemSeparator variant="1px" className="m-main-spacing" />
      <BillingItem
        orderDate={'7 Feb 2022'}
        amount={250}
        fromDate={'07/12/2021'}
        toDate={'06/01/2022'}
        subValue={`2 x ₹${400}`}
      />
    </>
  );
}
