import { Fragment, memo } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import PageHeader from 'app/design/speedBot/PageHeader';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import MuiButton from 'app/design/uiComponents/MuiButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DirectionValueOptions } from '../FieldsData';
import MuiToggleButton from 'app/design/uiComponents/MuiToggleButton';
import { OptionSetSpreadValueType, OptionSetTeplateValueType } from './types';
import { DropdownFieldValue } from '../types';

const OptionSetSpreadModal = ({
  open,
  handleClose,
  handleApplyTemplate,
  contractSelectionOptions,
  expiryTypeValueOptions,
}: {
  open: boolean;
  handleClose: () => void;
  handleApplyTemplate: (
    source: string,
    value: OptionSetTeplateValueType,
  ) => void;
  contractSelectionOptions: DropdownFieldValue[];
  expiryTypeValueOptions: DropdownFieldValue[];
}) => {
  const isMdUp = useGetMediaQueryUp('md');

  return (
    <>
      <MuiModal
        open={open}
        handleClose={handleClose}
        isFullScreen={!isMdUp}
        isAutoHeight={isMdUp}
        header={
          <Box sx={{ pt: 2 }}>
            <PageHeader
              variant="withIcon"
              title="Spread"
              iconProps={{
                handleClick: () => handleClose(),
                icon: <CloseIcon fontSize="small" />,
              }}
            />
            <Box className="p-main-spacing">
              <ItemSeparator variant="2px" />
            </Box>
          </Box>
        }
        body={
          <Box
            sx={{ py: 2, height: '100%', overflow: 'auto', display: 'grid' }}
          >
            <Formik
              initialValues={
                {
                  type: 'call',
                  value1: 0,
                  value2: 5,
                  expiry: 'weekly_0',
                  lots: 1,
                  direction: 'long',
                } as OptionSetSpreadValueType
              }
              validationSchema={Yup.object().shape({
                value1: Yup.number().required('+1x is required'),
                value2: Yup.number().required('-1x is required'),
                expiry: Yup.string().required('Expiry is required'),
                lots: Yup.number().min(1, '').required('Lots is required'),
              })}
              onSubmit={(values, actions) => {
                handleApplyTemplate('SPREAD', values);
                handleClose();
                actions.resetForm();
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                handleBlur,
                handleChange,
                touched,
                isValid,
                resetForm,
              }) => {
                return (
                  <>
                    <Box
                      className="m-main-spacing"
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        pt: 1,
                        position: 'relative',
                      }}
                    >
                      <Box sx={{ mb: 2, flex: 1 }}>
                        <Box sx={{ mb: 3 }}>
                          <FormControl>
                            <RadioGroup
                              row
                              name="type"
                              value={values.type}
                              onChange={e =>
                                setFieldValue('type', e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="call"
                                control={<Radio />}
                                label="Call Spread"
                              />
                              <FormControlLabel
                                value="put"
                                control={<Radio />}
                                label="Put Spread"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Grid container columnSpacing={2}>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              sx={{ mb: 3 }}
                              error={Boolean(touched.value1 && errors.value1)}
                            >
                              <InputLabel id="label-value1">
                                +1x [{values.type === 'call' ? 'CE' : 'PE'}]
                              </InputLabel>
                              <Select
                                labelId="label-value1"
                                name="value1"
                                value={values.value1}
                                label={`+1x [${
                                  values.type === 'call' ? 'CE' : 'PE'
                                }]`}
                                onChange={e => {
                                  setFieldValue('value1', e.target.value);
                                }}
                              >
                                {contractSelectionOptions.map(interval => (
                                  <MenuItem
                                    value={interval.key}
                                    key={interval.key}
                                  >
                                    {interval.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.value1 && errors.value1 && (
                                <FormHelperText error>
                                  {errors.value1}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              sx={{ mb: 3 }}
                              error={Boolean(touched.value2 && errors.value2)}
                            >
                              <InputLabel id="label-value2">
                                -1x [{values.type === 'call' ? 'CE' : 'PE'}]
                              </InputLabel>
                              <Select
                                labelId="label-value2"
                                name="value2"
                                value={values.value2}
                                label={`-1x [${
                                  values.type === 'call' ? 'CE' : 'PE'
                                }]`}
                                onChange={e => {
                                  setFieldValue('value2', e.target.value);
                                }}
                              >
                                {contractSelectionOptions.map(interval => (
                                  <MenuItem
                                    value={interval.key}
                                    key={interval.key}
                                  >
                                    {interval.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.value2 && errors.value2 && (
                                <FormHelperText error>
                                  {errors.value2}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              sx={{ mb: 3 }}
                              error={Boolean(touched.expiry && errors.expiry)}
                            >
                              <InputLabel id="label-expiry">Expiry</InputLabel>
                              <Select
                                labelId="label-expiry"
                                name="expiry"
                                value={values.expiry}
                                label="Expiry"
                                onChange={e => {
                                  setFieldValue('expiry', e.target.value);
                                }}
                              >
                                {expiryTypeValueOptions.map(interval => (
                                  <MenuItem
                                    value={interval.key}
                                    key={interval.key}
                                  >
                                    {interval.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.expiry && errors.expiry && (
                                <FormHelperText error>
                                  {errors.expiry}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              error={Boolean(touched.lots && errors.lots)}
                              variant="outlined"
                              sx={{ mb: 3 }}
                            >
                              <InputLabel htmlFor="outlined-adornment-lots">
                                Lots
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-lots"
                                type="number"
                                name="lots"
                                value={values.lots}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{
                                  min: 1,
                                  style: { textAlign: 'center' },
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setFieldValue('lots', values.lots + 1)
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                startAdornment={
                                  <InputAdornment position="start">
                                    <IconButton
                                      onClick={() =>
                                        setFieldValue(
                                          'lots',
                                          values.lots <= 1
                                            ? 1
                                            : values.lots - 1,
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Lots"
                              />
                              {touched.lots && errors.lots && (
                                <FormHelperText error sx={{ mx: 0 }}>
                                  {errors.lots}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ mb: 3 }}>
                              <Box display="flex" gap={2}>
                                {DirectionValueOptions.map(item => (
                                  <Fragment key={item.key}>
                                    <MuiToggleButton
                                      label={item.label}
                                      value={item.key}
                                      isSelected={item.key === values.direction}
                                      setValue={val => {
                                        setFieldValue('direction', val);
                                      }}
                                    />
                                  </Fragment>
                                ))}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        sx={{
                          textAlign: 'center',
                          display: { xs: 'block', md: 'flex' },
                          justifyContent: 'center',
                          flexDirection: 'row-reverse',
                          gap: 2,
                        }}
                      >
                        <MuiButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={!isValid}
                          sx={{ mb: isMdUp ? 0 : 2 }}
                        >
                          Apply Template
                        </MuiButton>
                        <MuiButton
                          variant="contained"
                          color="secondary"
                          fullWidth
                          onClick={() => {
                            resetForm();
                            handleClose();
                          }}
                        >
                          Cancel
                        </MuiButton>
                      </Box>
                    </Box>
                  </>
                );
              }}
            </Formik>
          </Box>
        }
      />
    </>
  );
};

export default memo(OptionSetSpreadModal);
