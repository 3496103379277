import { useState, memo } from 'react';
import { Box, Typography } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import PlanConfirmModal from 'app/components/Bots/PlanUpgrade/PlanConfirmModal';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import {
  SubscribePlanResult,
  SubscribePlanStatusParams,
  SubscribePlanStatusResult,
  SubscriptionTransformPlan,
} from 'types/SubscriptionPlans';
import PlanConfirmInfo from 'app/components/Bots/PlanUpgrade/PlanConfirmInfo';
import useGetProfile from 'services/Menu/useGetProfile';
import { createRequestSubscriptionPlan } from 'app/components/Menu/utils';
import useSubscribePlanStatus from 'services/Menu/useSubscribePlanStatus';
import useSubscribePlan from 'services/Menu/useSubscribePlan';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useTranslation } from 'react-i18next';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { formatNumber } from 'utils/GenericFunctions';
import useGetPlans from 'services/Menu/useGetPlans';

let objPaymentStatusReq: SubscribePlanStatusParams = {
  razorpay_payment_id: '',
  razorpay_subscription_id: '',
  razorpay_signature: '',
  razorpay_order_id: '',
};

const PlanSubscribeConfirm = ({
  type,
  selectedPlan,
  resetPlanInfo,
  handleClose,
}: {
  type: 'upgrade' | 'change';
  selectedPlan: SubscriptionTransformPlan;
  resetPlanInfo: () => void;
  handleClose?: () => void;
}) => {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const isMdUp = useGetMediaQueryUp('md');
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [billingType, setBillingType] = useState<string>(
    selectedPlan.recurring_price_yearly ? 'annual' : 'monthly',
  );
  const [paymentMode, setPaymentMode] = useState<string>('one_time');
  const [couponCode, setCouponCode] = useState<string>('');
  const [clickcode, setClickCode] = useState<string>('');
  const [discountSubtotal, setDiscountSubtotal] = useState<number>();
  const isCreditWallet = useIsFeatureFlag([FEATURES.IS_CREDIT_WALLET]);
  const handleChangeBillingType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setBillingType((event.target as HTMLInputElement).value);
  };

  const handleChangePaymentMode = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPaymentMode((event.target as HTMLInputElement).value);
  };
  const handleCouponCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode((e.target as HTMLInputElement).value);
  };
  const getSubtotal = (value: number) => {
    setDiscountSubtotal(value);
  };
  const applyClick = (value: string) => {
    setClickCode(value);
    // console.log('clikcode',clickcode)
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    if (handleClose) handleClose();
    breadcrumb.goBackPathPage(`/menu/plan-details`);
  };

  const handleOpenConfirmModal = () => {
    if (!openConfirmModal) setOpenConfirmModal(true);
  };

  const { data: profileDetails, isLoading: isLoadingGetProfile } =
    useGetProfile();
  const { data: stiteInfo } = useGetSiteInfo();

  const { data: plans, isLoading: planLoading } = useGetPlans(clickcode);
  const subscribePlanStatusSuccessCallback = (
    res: SubscribePlanStatusResult,
  ) => {
    if (res.success && !res.error) {
      // Log entry for upgrade/change plan
      logFirebaseEventEntry('SUBSCRIPTION_STOP_' + type.toUpperCase());

      handleOpenConfirmModal();
    }
  };
  const mutationSubscribePlanStatus = useSubscribePlanStatus(
    subscribePlanStatusSuccessCallback,
  );

  const subscribePlanSuccessCallback = (
    res: SubscribePlanResult,
    planId: number,
  ) => {
    if (res.status === 'active') {
      handleOpenConfirmModal();
      return;
    }
    const options = {
      // key: process.env.REACT_APP_REAZORPAY_PUBLIC_KEY as string,
      key: stiteInfo ? stiteInfo.razorpay_key : '',
      handler: response => {
        objPaymentStatusReq = {
          razorpay_payment_id: response.razorpay_payment_id as string,
          razorpay_subscription_id: response.razorpay_subscription_id as string,
          razorpay_signature: response.razorpay_signature as string,
          razorpay_order_id: response.razorpay_order_id as string,
        };
        if (res.payment_type === 'one_time') {
          objPaymentStatusReq['invoice_id'] = res.invoice_id;
        } else {
          objPaymentStatusReq['subscription_id'] = res.subscription_id;
        }
        handlePaymentStatusReq();
      },
      prefill: {
        name: profileDetails?.name,
        email: profileDetails?.email,
        contact: profileDetails?.mobile,
      },
      notes: {
        note_key_1: `${
          selectedPlan.plan_name +
          ' ' +
          (planId === selectedPlan?.plan_id_monthly ? 'Monthly' : 'Yearly')
        }`,
        note_key_2: `${
          planId === selectedPlan?.plan_id_monthly
            ? selectedPlan.description_monthly
            : selectedPlan.description_yearly
        }`,
      },
      // "theme": {
      //   "color": "#F37254"
      // },
    };

    if (res.payment_type === 'one_time') {
      options['order_id'] = res.razorpay_order_id;
    } else {
      options['subscription_id'] = res.razorpay_subscription_id;
    }

    // eslint-disable-next-line
    const paymentObject = new (window as any).Razorpay(options);
    // eslint-disable-next-line
    paymentObject.open();
  };
  const mutationSubscribePlan = useSubscribePlan(subscribePlanSuccessCallback);

  const handlePaymentClick = (
    billingType: string,
    paymentMode: string,
    paymentAcquirer: string,
  ) => {
    if (selectedPlan && billingType) {
      const objReq = createRequestSubscriptionPlan(billingType, selectedPlan);
      objReq.end_date = '28/03/2024';
      objReq.payment_type = paymentMode;
      objReq.payment_acquirer = paymentAcquirer;
      objReq.coupon_code = clickcode;
      mutationSubscribePlan.mutate(objReq);
    }
  };

  const handlePaymentStatusReq = () => {
    mutationSubscribePlanStatus.mutate(objPaymentStatusReq);
  };

  const subTotal =
    billingType === 'annual'
      ? selectedPlan.recurring_price_yearly
      : selectedPlan.recurring_price_monthly;

  return (
    <>
      <CircularLoader
        open={
          isLoadingGetProfile ||
          mutationSubscribePlan.isLoading ||
          mutationSubscribePlanStatus.isLoading ||
          planLoading
        }
      />
      {!isMdUp && (
        <PageHeader
          variant="common"
          title={
            <FormattedMessage
              id={
                type === 'upgrade'
                  ? 'menu.page_title.plan_upgrade'
                  : 'menu.page_title.plan_change'
              }
            />
          }
        />
      )}
      <Box sx={{ flex: 1 }}>
        <Box className="m-main-spacing" sx={{ mb: 3 }}>
          <Typography variant="body1" fontWeight={400}>
            <FormattedMessage id="menu.confirm_your_plan" />
          </Typography>
        </Box>

        {plans && (
          <PlanConfirmInfo
            plan={selectedPlan}
            billingType={billingType}
            handleChangeBillingType={handleChangeBillingType}
            paymentMode={paymentMode}
            handleChangePaymentMode={handleChangePaymentMode}
            handleCouponCodeChange={handleCouponCodeChange}
            couponCodeValue={couponCode}
            applyClick={applyClick}
            planWithCouponCode={plans}
            getSubtotal={getSubtotal}
            currencySymbol={stiteInfo?.currency_symbol || ''}
          />
        )}

        <Box className="m-main-spacing" sx={{ mb: 3 }}>
          <Typography variant="body2" fontWeight={400} color="text.secondary">
            {t('menu.plan_purchases_are_nonrefundable', {
              seteName: stiteInfo ? stiteInfo.name : '',
            })}
          </Typography>
          {isCreditWallet &&
          paymentMode === 'one_time' &&
          profileDetails &&
          profileDetails.wallet_balance >= subTotal ? (
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Typography variant="body1" color="text.secondary">
                <FormattedMessage id="menu.wallet_balance" />
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                {formatNumber(profileDetails.wallet_balance)}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className="m-main-spacing" sx={{ mb: 0 }}>
        {isCreditWallet &&
        paymentMode === 'one_time' &&
        profileDetails &&
        profileDetails.wallet_balance >= Number(discountSubtotal) ? (
          <MuiButton
            fullWidth
            variant="contained"
            sx={{ mb: 2 }}
            onClick={() => {
              handlePaymentClick(billingType, paymentMode, 'speedbot_wallet');
            }}
          >
            <FormattedMessage id="menu.pay_with_wallet" />
          </MuiButton>
        ) : null}
        <MuiButton
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          onClick={() => {
            if (mutationSubscribePlanStatus.isError) handlePaymentStatusReq();
            else handlePaymentClick(billingType, paymentMode, 'razorpay');
          }}
        >
          {mutationSubscribePlanStatus.isError ? (
            <FormattedMessage id="menu.retry" />
          ) : (
            <FormattedMessage id="menu.pay_with_razorpay" />
          )}
        </MuiButton>
        <MuiButton
          fullWidth
          variant="contained"
          color="secondary"
          onClick={resetPlanInfo}
        >
          <FormattedMessage id="buttons.cancel" />
        </MuiButton>
      </Box>

      <PlanConfirmModal
        type={type}
        billingType={billingType}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        selectedPlan={selectedPlan.plan_name}
        paymentInfo={{
          paymentId: objPaymentStatusReq.razorpay_payment_id,
          method:
            profileDetails && profileDetails.user_plan
              ? profileDetails.user_plan.payment_source
              : '-',
          mobile: profileDetails ? profileDetails.mobile : '',
        }}
      />
    </>
  );
};
export default memo(PlanSubscribeConfirm);
