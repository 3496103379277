import { Box, Typography } from '@mui/material';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { FEATURES } from 'types/Feature';

const BotActions = ({
  variant,
  botCategory,
  handlePaperModal,
  handleStopModal,
  handleDeployModal,
}: {
  variant: 'trader' | 'bot';
  botCategory: string;
  handlePaperModal: () => void;
  handleStopModal: () => void;
  handleDeployModal: () => void;
}) => {
  return (
    <Box sx={{ mb: 3 }} className="m-main-spacing">
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'flex-end' },
          alignItems: { md: 'center' },
        }}
      >
        {botCategory === 'inactive' && (
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ mr: { md: 4 }, mb: { xs: 1, md: 0 } }}
          >
            {variant === 'trader' ? (
              <FormattedMessage id="traders.detail.subscribe" />
            ) : (
              <FormattedMessage id="market.bot_detail.subscribe" />
            )}
          </Typography>
        )}
        <Box sx={{ display: { xs: 'flex', md: 'contents' } }}>
          <FeatureFlag features={[FEATURES.IS_PAPER_TRADING]} fallback={<></>}>
            {botCategory === 'inactive' ? (
              <MuiButton
                sx={{ maxWidth: { md: '192px' }, flex: 1, mr: 1 }}
                variant="outlined"
                onClick={() => handlePaperModal()}
              >
                <FormattedMessage id="buttons.paper" />
              </MuiButton>
            ) : (
              <MuiButton
                sx={{ maxWidth: { md: '192px' }, flex: 1, mr: 1 }}
                variant="outlined"
                color="error"
                onClick={() => handleStopModal()}
              >
                {variant === 'trader' ? (
                  <FormattedMessage id="buttons.stop_copy" />
                ) : (
                  <FormattedMessage id="buttons.stop" />
                )}
              </MuiButton>
            )}
          </FeatureFlag>
          {botCategory === 'inactive' || botCategory === 'paper' ? (
            <FeatureFlag features={[FEATURES.IS_GOLIVE]} fallback={<></>}>
              <MuiButton
                variant="contained"
                sx={{ maxWidth: { md: '192px' }, flex: 1 }}
                onClick={() => handleDeployModal()}
              >
                <FormattedMessage id="buttons.deploy" />
              </MuiButton>
            </FeatureFlag>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BotActions;
