import { Box, Typography } from '@mui/material';
import SelectableCheckbox from './SelectableCheckbox';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import { ReportDropDownValue } from 'types/ApiServicesTypes';

type FilterRadioGroupProps = {
  label?: string;
  inline?: boolean;
  controlName: string;
  checkBoxItems: ReportDropDownValue[];
  withBottomBorder?: boolean;
  handleChange: (label: string, value: boolean) => void;
  selectedValues: Record<string, boolean>;
};

function FilterCheckBoxGroup({
  label,
  inline,
  controlName,
  handleChange,
  checkBoxItems,
  selectedValues,
  withBottomBorder,
}: FilterRadioGroupProps) {
  return (
    <>
      {label && <Typography variant="body1">{label}</Typography>}
      <Box
        {...(inline && {
          sx: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row',
          },
        })}
      >
        {checkBoxItems?.map((item, i) => (
          <Box key={item.value} {...(inline && { width: '100%' })}>
            <SelectableCheckbox
              checked={selectedValues[item.label]}
              label={item.label}
              value={item.label}
              handleChange={(_, checked) =>
                handleChange(`${controlName}.${item.label}`, checked)
              }
            />
            {withBottomBorder && checkBoxItems.length - 1 !== i && (
              <ItemSeparator variant="1px" />
            )}
          </Box>
        ))}
      </Box>
    </>
  );
}

export default FilterCheckBoxGroup;
