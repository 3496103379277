import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import moment from 'moment';
import React from 'react';
import { GetPortfolioShareResult } from 'types/ApiServicesTypes';
import HistoryCard from './HistoryCard';
interface SharedDate {
  date: string;
  net_profit_loss: number;
}
const PastHistory = ({
  open,
  handleClose,
  tableData,
  isSelected,
}: {
  open: boolean;
  handleClose: (success: boolean) => void;
  tableData?: GetPortfolioShareResult;
  isSelected: (selectedDate: string) => boolean;
}) => {
  const dateCardSX = {
    ml: 'auto',
    maxWidth: '100%',

    flexDirection: 'column',
    boxShadow: '0 4px 4px rgba(0,0,0,0.2)',

    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '1em',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  };
  const groupDatesByMonth = (dates: SharedDate[]) => {
    const groupedDates: { [key: string]: SharedDate[] } = {};

    dates?.forEach(dateItem => {
      const monthYear = moment(dateItem.date).format('MMMM YYYY');

      if (!groupedDates[monthYear]) {
        groupedDates[monthYear] = [];
      }
      groupedDates[monthYear].unshift(dateItem);
    });

    return groupedDates;
  };
  const newDateArray = groupDatesByMonth(
    tableData?.data.shared_dates as SharedDate[],
  );
  return (
    <MuiFullScreenModal
      open={open}
      handleClose={handleClose}
      body={
        <Box sx={{ dateCardSX, pb: 0 }}>
          <Typography
            variant="h5"
            sx={{
              pb: 2,
              paddingLeft: 2,
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <FormattedMessage id="portfolio.public.history" />
          </Typography>
          {newDateArray &&
            Object.entries(newDateArray)
              .reverse()
              .map(([monthYear, dates], index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="body1"
                    sx={{
                      py: 2,
                      paddingLeft: 2,
                      borderBottom: '1px solid #e0e0e0',
                    }}
                  >
                    {monthYear}
                  </Typography>
                  {dates.map((dateItem, dateIndex) => (
                    <Box key={dateIndex} onClick={() => handleClose(false)}>
                      <HistoryCard
                        isActive={isSelected(
                          moment(dateItem.date).format('YYYY-MM-DD'),
                        )}
                        profit={dateItem.net_profit_loss}
                        time={dateItem.date}
                      />
                    </Box>
                  ))}
                </React.Fragment>
              ))}
        </Box>
      }
    />
  );
};

export default PastHistory;
