import { createContext, memo, useEffect, useState } from 'react';
export type ThemeContextType = {
  isDarkTheme: boolean;
  toggleTheme: () => void;
};
export const themeContext = createContext<ThemeContextType>({
  isDarkTheme: true,
  toggleTheme: () => {
    //
  },
});
const ToggleThemeComp = ({ children }) => {
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  };

  const [isDarkTheme, setIsDarkTheme] = useState(getInitialTheme());
  const toggleTheme = () => {
    const newTheme = !isDarkTheme;
    setIsDarkTheme(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsDarkTheme(savedTheme === 'dark');
    }
  }, []);
  return (
    <themeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      {children}
    </themeContext.Provider>
  );
};
export const ToggleThemeProvider = memo(ToggleThemeComp);
