import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import ProfitLossByDateReport from 'app/design/speedBot/ComponentItem/Common/ProfitLossByDateReport';

export function MarketplaceProfitLossByDateReportPage() {
  const { id, title: botName } = useParams<{ id: string; title: string }>();
  const botId = parseInt(id);
  const baseUrl = `marketplace/bots/${id}`;

  const isFromTrader = window.location.pathname.startsWith('/trader');

  return (
    <>
      <Helmet>
        <title>Profit And Loss By Date Report</title>
        <meta
          name="description"
          content="Profit And Loss By Date Report Page"
        />
      </Helmet>
      <ProfitLossByDateReport
        baseUrl={baseUrl}
        pageHeadButtonText={botName}
        breadCrumbPage={
          isFromTrader ? PAGES.TRADERS_PROFITLOSS : PAGES.MARKETPLACE_PROFITLOSS
        }
        breadCrumbData={{ botName, botId }}
      />
    </>
  );
}
