import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { PostBotExitStrategyPositions } from 'services/apiServices';
import {
  PostBotExitStrategyPositionsResult,
  Errors,
} from 'types/ApiServicesTypes';

export default function usePostBotExitStrategyPositions(id: number) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostBotExitStrategyPositionsResult,
    Errors,
    { positionIds: number[] }
  >(['PostBotExitStrategyPositions', id], values =>
    PostBotExitStrategyPositions(id, values.positionIds)
      .then(res => {
        if (res.error) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          showAlert!(res.error);
        }
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
