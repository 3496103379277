import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, ButtonGroup, IconButton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DownloadIcon from '@mui/icons-material/Download';

import PortfolioGraph from 'app/components/Common/Charts/Marketplace/PortfolioGraph';
import ReturnsGraph from 'app/components/Common/Charts/Marketplace/ReturnsGraph';
import TradesGraph from 'app/components/Common/Charts/Marketplace/TradesGraph';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import InfoModal from 'app/components/Common/InfoModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { infoModalData } from './utils';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import { PAGES } from '../Common/Breadcrumb/types';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import MuiButton from 'app/design/uiComponents/MuiButton';
import InfoListBacktesting from 'app/design/speedBot/ComponentItem/Marketplace/InfoListBacktesting';
import BacktestingBotActions from './BacktestingBotActions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

// Services
import useGetStatistics from 'services/Statistics/useGetStatistics';
import { GetStatisticsResult } from 'types/ApiServicesTypes';
import useGetBotPositionsForExcel from 'services/Bots/useGetBotPositionsForExcel';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useGetTickData } from 'app/hooks/useGetTickData';
import useDownloadBotExcel from 'services/Bots/useDownloadBotExcel';

const graphComponentMap = {
  Portfolio: PortfolioGraph,
  Returns: ReturnsGraph,
  Trades: TradesGraph,
};

const checkCanEditBacktesting = (
  statisticsData: GetStatisticsResult | undefined,
) => {
  return !!(statisticsData && statisticsData.statistics.is_own_bot);
};

export default function Statistics({
  id,
  subId,
  baseApiUrl,
  hideTabs,
  title,
  source,
  positionsOnClick,
  symbolsOnClick,
  ordersOnClick,
  profitLossOnClick,
}: {
  id: number;
  subId?: string;
  baseApiUrl: string;
  hideTabs?: boolean;
  title?: string;
  source?: string;
  positionsOnClick?: () => void;
  symbolsOnClick?: () => void;
  ordersOnClick?: () => void;
  profitLossOnClick?: () => void;
}) {
  const executedPage = useRef(0);
  const exportPageCount = process.env?.REACT_APP_FEATURE_EXPORT_DATA_COUNT
    ? parseInt(process.env.REACT_APP_FEATURE_EXPORT_DATA_COUNT)
    : 100;
  const breadcrumb = useBreadcrumbManager();
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const [activeButton, setActiveButton] = useState<string>('Portfolio');
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [progressData, setProgressData] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const GraphComponent = graphComponentMap[activeButton];

  const { data: statisticsData } = useGetStatistics(baseApiUrl);
  const canEditBacktesting = checkCanEditBacktesting(statisticsData);
  const mutationGetPositions = useGetBotPositionsForExcel();
  const symbols = React.useMemo(
    () =>
      statisticsData
        ? statisticsData.statistics.scripts.map(symbol => symbol.symbol)
        : [],
    [statisticsData],
  );
  const liveFeedData = useGetTickData(symbols);

  const infoClickHandler = React.useCallback(() => {
    setOpenInfo(true);
  }, [setOpenInfo]);

  const handleInfoClose = React.useCallback(() => {
    setOpenInfo(false);
  }, [setOpenInfo]);

  const handleDownloadExcel = () => {
    setProgressData(true);
    if (!statisticsData) return;

    const params = {
      botId: id,
      baseApiUrl,
      fileName: statisticsData.statistics.name,
      maxPage: exportPageCount,
      minDate: statisticsData.statistics.start_date,
      maxDate: statisticsData.statistics.end_date,
      page: 1,
      withFees: true,
      period: 'month' || 'day',
      withPercentage: true,
      liveFeedData,
    };

    executedPage.current = 0;

    mutationGetPositions.mutate(params, {
      onSuccess: () => {
        setProgressData(false);
      },
    });
  };

  const downloadBotDataMutation = useDownloadBotExcel();

  const handleDownloadBotData = (botId: number, botName: string) => {
    setProgressData(true);
    downloadBotDataMutation.mutate(
      {
        botId,
        fileName: botName,
      },
      {
        onSuccess: () => setProgressData(false),
      },
    );
  };

  const isOptionType = !(statisticsData && statisticsData.type === 'option');

  return (
    <>
      <CircularLoader open={progressData} />
      <ItemHead
        variant="largeWithIcon"
        title={<FormattedMessage id="statistics" />}
        icon={
          <IconButton
            sx={{ p: 0.75, color: 'action.active' }}
            onClick={infoClickHandler}
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        }
      />
      <Box
        sx={{ py: 1, textAlign: 'right', ...(!isMdUp && { mb: 2 }) }}
        className="m-main-spacing"
      >
        {canEditBacktesting && (
          <FeatureFlag features={[FEATURES.IS_BACKTESTING]} fallback={<></>}>
            <MuiButton
              sx={{ flex: 1 }}
              variant="contained"
              color="secondary"
              // component={RouterLink}
              // to={{
              //   pathname: `/bot/${statisticsData?.statistics.user_botid}/backtesting`,
              //   state: {
              //     page: pageFrom,
              //     ptype: ptype,
              //     subId: subId,
              //     fromMoreInfo,
              //   },
              // }}
              onClick={() =>
                breadcrumb.push(
                  `/bot/${statisticsData?.statistics.user_botid}/backtesting/${
                    subId ? subId : ''
                  }`,
                  PAGES.BOT_BACK_TESTING,
                  `/bots/${id}/backtest/${subId}/${title}`,
                  t('statistics'),
                )
              }
              startIcon={<EditIcon />}
              fullWidth={!isMdUp}
            >
              <FormattedMessage id="buttons.edit" />
            </MuiButton>
            {!isOptionType && (
              <FeatureFlag
                features={[FEATURES.IS_TRADING_VIEW_CHART_BUTTON]}
                fallback={<></>}
              >
                <MuiButton
                  sx={{
                    flex: 1,
                    ...(isMdUp && { ml: 2 }),
                    ...(!isMdUp && { mt: 2 }),
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    breadcrumb.push(
                      `/bot/${id}/${
                        statisticsData?.statistics.user_botid
                      }/tradingview/${subId ? subId : '0'}/${title}`,
                      PAGES.BOT_BACK_TESTING,
                      `/bots/${id}/backtest/${subId}/${title}`,
                      t('statistics'),
                    )
                  }
                  startIcon={<AutoGraphIcon />}
                  fullWidth={!isMdUp}
                >
                  <FormattedMessage id="buttons.view_chart" />
                </MuiButton>
              </FeatureFlag>
            )}
            <FeatureFlag
              features={[FEATURES.IS_DOWNLOAD_EXCEL_ORDERS]}
              fallback={<></>}
            >
              <MuiButton
                sx={{
                  flex: 1,
                  ...(isMdUp && { ml: 2 }),
                  ...(!isMdUp && { mt: 2 }),
                }}
                variant="contained"
                color="secondary"
                startIcon={<DownloadIcon />}
                fullWidth={!isMdUp}
                onClick={() => {
                  void handleDownloadExcel();
                }}
              >
                <FormattedMessage id="buttons.export_excel" />
              </MuiButton>
            </FeatureFlag>
          </FeatureFlag>
        )}
        {(source === 'PORTFOLIO' || source === 'HISTORY') &&
          statisticsData?.statistics.sub_id !== undefined &&
          statisticsData.statistics.sub_id > 0 && (
            <FeatureFlag
              features={[FEATURES.IS_EXPORT_BOT_DATA]}
              fallback={<></>}
            >
              <MuiButton
                sx={{
                  flex: 1,
                  ...(isMdUp && { ml: 2 }),
                  ...(!isMdUp && { mt: 2 }),
                }}
                variant="contained"
                color="secondary"
                startIcon={<DownloadIcon />}
                fullWidth={!isMdUp}
                onClick={() => {
                  handleDownloadBotData(
                    statisticsData.statistics.sub_id,
                    statisticsData.statistics.name,
                  );
                }}
              >
                <FormattedMessage id="buttons.export_excel" />
              </MuiButton>
            </FeatureFlag>
          )}
      </Box>
      {!hideTabs && (
        <Box
          sx={{ mb: 2 }}
          className="m-main-spacing"
          display="flex"
          justifyContent={'center'}
        >
          <ButtonGroup
            variant="contained"
            color="secondary"
            fullWidth
            disableElevation
            sx={{ maxWidth: '30rem' }}
          >
            <MuiButton
              sx={{
                backgroundColor:
                  activeButton === 'Portfolio' ? 'grey.200' : 'grey.100',
              }}
              onClick={() => setActiveButton('Portfolio')}
            >
              <FormattedMessage id="buttons.portfolio" />
            </MuiButton>
            <MuiButton
              sx={{
                backgroundColor:
                  activeButton === 'Returns' ? 'grey.200' : 'grey.100',
              }}
              onClick={() => setActiveButton('Returns')}
            >
              <FormattedMessage id="buttons.returns" />
            </MuiButton>
            <MuiButton
              sx={{
                backgroundColor:
                  activeButton === 'Trades' ? 'grey.200' : 'grey.100',
              }}
              onClick={() => setActiveButton('Trades')}
            >
              <FormattedMessage id="buttons.position" />
            </MuiButton>
          </ButtonGroup>
        </Box>
      )}
      <Box className="m-main-spacing">
        <GraphComponent id={id} minHeight="150px" baseApiUrl={baseApiUrl} />
      </Box>
      {statisticsData && statisticsData.statistics && (
        <>
          <InfoListBacktesting
            currencySymbol={statisticsData.currency_symbol}
            data={statisticsData.statistics}
            mddAmount={
              statisticsData.advance_statistics
                ? statisticsData.advance_statistics.maximumclosedtradedrawdown
                : 0
            }
            baseApiUrl={baseApiUrl}
          />
          {source && ['DISCOVER', 'BACKTEST'].includes(source) && (
            <FeatureFlag
              features={[FEATURES.IS_BACKTEST_DISCLAIMER]}
              fallback={<></>}
            >
              <Box className="p-main-spacing" sx={{ mt: 2 }}>
                <Alert severity="warning">
                  <Typography variant="body2">
                    {t('backtesting.disclaimer')}
                  </Typography>
                </Alert>
              </Box>
            </FeatureFlag>
          )}
          <BacktestingBotActions
            id={id.toString()}
            subId={subId}
            baseApiUrl={baseApiUrl}
            botName={title!}
            botScripts={statisticsData.statistics.scripts || []}
            positionCount={statisticsData.statistics.position_count}
            orderCount={statisticsData.statistics.order_count}
            ordersOnClick={ordersOnClick}
            positionsOnClick={positionsOnClick}
            symbolsOnClick={symbolsOnClick}
            profitLossOnClick={profitLossOnClick}
          />
        </>
      )}
      <InfoModal
        data={infoModalData}
        open={openInfo}
        handleClose={handleInfoClose}
        closeBtn={true}
      />
    </>
  );
}
