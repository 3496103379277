import { ToastContext } from 'app/components/Context/ToastContext';
import moment from 'moment';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { GetProfitLossByDate } from 'services/apiServices';
import { Errors, GetProfitLossByDateResult } from 'types/ApiServicesTypes';

const MONTH_PAGINATION = 6;

export default function useGetProfitLossByDateMutate(baseApiUrl: string) {
  const { showAlert } = useContext(ToastContext);

  const queryKey = ['GetProfitLossByDate', baseApiUrl];
  const mutation = useMutation<
    GetProfitLossByDateResult,
    Errors,
    Pick<
      {
        minDate: string;
        maxDate: string;
        page: number;
        withFees: boolean;
        period: 'day' | 'month';
        withPercentage?: boolean;
      },
      'minDate' | 'maxDate' | 'page' | 'withFees' | 'period' | 'withPercentage'
    >
  >(queryKey, async values => {
    let startDt = values.minDate,
      endDt = values.maxDate;
    if (values.period !== 'month') {
      const subtractedDates = getMonthSubtractedDate(
        values.maxDate,
        values.page,
      );
      startDt = subtractedDates.startDt;
      endDt = subtractedDates.endDt;
    }

    return await GetProfitLossByDate(
      baseApiUrl,
      startDt,
      endDt,
      values.withFees ? 1 : 0,
      values.period,
      values.withPercentage,
    )
      .then(res => {
        return {
          startDate: startDt,
          endDate: endDt,
          minDate: values.minDate,
          maxDate: values.maxDate,
          profitLossData: res,
          transformProfitLossData: [],
        };
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      });
  });

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}

const getMonthSubtractedDate = (lastDate: string, page: number) => {
  const maxDate = moment(lastDate);
  const endDate = moment(maxDate)
    .subtract((page - 1) * MONTH_PAGINATION, 'M')
    .endOf('month');
  const endDt = endDate.format('YYYY-MM-DD');
  const startDate = endDate
    .subtract(MONTH_PAGINATION - 1, 'M')
    .startOf('month');
  const startDt = startDate.format('YYYY-MM-DD');

  return { startDt: startDt, endDt: endDt };
};
