import {
  Box,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import i18next from 'i18next';
import { formatDateString } from 'utils/GenericFunctions';
import { PositionBaseDataType, PositionMetaData } from '../types';
import { OptionChainWithExpiryType } from 'app/pages/Simulator';
import { DeleteForever, ExitToApp } from '@mui/icons-material';
import moment from 'moment';
import { getLots, getMTM, squarOffPosition } from '../utils';
import { FutureDataType, SymbolOptionChainData } from 'types/ApiServicesTypes';
import MuiChip from 'app/design/uiComponents/MuiChip';

const positionRow = (
  position: PositionMetaData,
  currentOptContractDetails: SymbolOptionChainData,
  currentFutContractDetails: FutureDataType,
  date: string,
  lotSize: number,
  removePosition: (position: PositionMetaData) => void,
  updatePosition: (position: PositionMetaData) => void,
): TableRowCellsData[] => {
  // const mtm = current_ltp - position.premium;
  let currentContractDetails: SymbolOptionChainData =
    {} as SymbolOptionChainData;
  if (position.contractType === 'fut') {
    currentContractDetails = {
      close: currentFutContractDetails?.close || 0,
      delta: 0,
      gamma: 0,
      iv: 0,
      rho: 0,
      theta: 0,
      vega: 0,
      contractType: 'fut',
    };
  } else {
    currentContractDetails = currentOptContractDetails;
  }
  const mtm = getMTM(
    Object.values(position.positionCandleWise),
    lotSize,
    currentContractDetails?.close || 0,
  );
  return [
    {
      content: DetailsView(position, updatePosition, date),
      align: 'center',
    },
    {
      // content: position.positionCandleWise.premium,
      content: position.avgPrimium?.toFixed(2).toString() || '-',
      align: 'center',
    },
    {
      content: currentContractDetails?.close || 0,
      align: 'center',
    },
    {
      content: (
        <Typography
          variant="body1"
          color={mtm > 0 ? 'success.main' : mtm == 0 ? '' : 'error.main'}
        >
          {mtm.toFixed(2)}
        </Typography>
      ),
      align: 'center',
    },
    {
      content: (
        <>
          <IconButton
            onClick={e => {
              removePosition(position);
              e.stopPropagation();
            }}
          >
            <DeleteForever />
          </IconButton>
          {getLots(Object.values(position.positionCandleWise), date).lots >
            0 && (
            <IconButton
              onClick={e => {
                updatePosition(
                  squarOffPosition(
                    position,
                    currentContractDetails,
                    date,
                    lotSize,
                  ),
                );
                e.stopPropagation();
              }}
            >
              <ExitToApp />
            </IconButton>
          )}
        </>
      ),
      align: 'center',
    },
  ];
};

export const createPositionTableRows = (
  optionChainData: OptionChainWithExpiryType,
  positions: PositionMetaData[],
  date: string,
  lotSize: number,
  removePosition: (position: PositionMetaData) => void,
  updatePosition: (position: PositionMetaData) => void,
  currentExpiryDate: string,
) => {
  const rows: TableRow<PositionMetaData>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    { text: i18next.t('simulator.table_column.details'), align: 'center' },
    { text: i18next.t('simulator.table_column.entry'), align: 'center' },
    { text: i18next.t('simulator.table_column.ltp'), align: 'center' },
    { text: i18next.t('simulator.table_column.mtm'), align: 'center' },
    { text: i18next.t('simulator.table_column.actions'), align: 'center' },
  ];

  // if (Object.values(positions)) return { rows, columns };
  positions.map(position => {
    let currentOptContractDetails: SymbolOptionChainData =
      {} as SymbolOptionChainData;
    let currentFutContractDetails: FutureDataType = {} as FutureDataType;
    const current_ltp_opt =
      optionChainData &&
      optionChainData[
        `${
          position.contractType === 'fut'
            ? currentExpiryDate
            : position.expiryDate
        }`
      ];

    const current_ltp_pos =
      current_ltp_opt && current_ltp_opt.option[`${position.strike}`];
    if (position.contractType === 'fut') {
      currentFutContractDetails =
        current_ltp_opt && current_ltp_opt.future[`${position.strike}`];
    } else {
      if (position.contractType === 'ce') {
        currentOptContractDetails = current_ltp_pos && current_ltp_pos.CE;
      } else {
        currentOptContractDetails = current_ltp_pos && current_ltp_pos.PE;
      }
    }
    rowCellsData =
      Object.values(position.positionCandleWise || {}).length > 0
        ? positionRow(
            position,
            currentOptContractDetails,
            currentFutContractDetails,
            date,
            lotSize,
            removePosition,
            updatePosition,
          )
        : [];
    rows.push({
      rowCellsData,
      rowData: position,
      pt: 1,
      pb: 1,
    });
  });
  return { rows, columns };
};

const DetailsView = (
  position: PositionMetaData,
  updatePosition: (position: PositionMetaData) => void,
  date: string,
) => {
  const { direction, lots } = getLots(
    Object.values(position.positionCandleWise),
    date,
  );
  return (
    <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
      <Checkbox
        size="small"
        checked={Boolean(position.isActive)}
        onClick={e => {
          e.stopPropagation();
          position = {
            ...position,
            isActive: !position.isActive,
          };
          updatePosition(position);
        }}
      />
      {direction !== '' && (
        <MuiChip
          label={direction === 'long' ? 'S' : 'B'}
          variant="filled"
          size="singleLetter"
          color={direction === 'long' ? 'purplelight' : 'infolight'}
        />
      )}
      <Typography variant="body2">
        {formatDateString(position.expiryDate, 'DD MMM')}
      </Typography>
      {position.contractType !== 'fut' && (
        <Typography variant="body2">{Number(position.strike)}</Typography>
      )}
      <Typography variant="body2">
        {position.contractType.toUpperCase()}
      </Typography>
      <Typography variant="body2">x {lots}</Typography>
    </Box>
  );
};

const BuySellButtonForDetailPositions = (
  position: PositionBaseDataType,
  handleClick: (position: PositionBaseDataType) => void,
) => {
  const handleButtonClick = (clicked: string) => {
    position = {
      ...position,
      direction: clicked,
    };
    handleClick(position);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <IconButton
        sx={{
          borderRadius: 0.7,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'success.main',
          mx: 0.3,
          my: 0.5,
          display: 'flex',
          height: '1rem',
          width: '1rem',
          backgroundColor:
            position && position.direction === 'long'
              ? 'success.main'
              : 'common.white',
          '&:hover': {
            backgroundColor:
              position && position.direction === 'long' ? 'success.dark' : '',
          },
        }}
        onClick={() => {
          handleButtonClick('long');
        }}
      >
        <Typography
          sx={{
            color:
              position && position.direction === 'long'
                ? 'white'
                : 'success.main',
            fontSize: 14,
          }}
        >
          B
        </Typography>
      </IconButton>
      <IconButton
        sx={{
          borderRadius: 0.7,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'error.main',
          mx: 0.3,
          my: 0.5,
          height: '1rem',
          width: '1rem',
          display: 'flex',
          backgroundColor:
            position && position.direction === 'short' ? 'error.main' : '',
          '&:hover': {
            backgroundColor:
              position && position.direction === 'short' ? 'error.dark' : '',
          },
        }}
        onClick={() => {
          handleButtonClick('short');
        }}
      >
        <Typography
          sx={{
            color:
              position && position.direction === 'short'
                ? 'white'
                : 'error.main',
            fontSize: 14,
          }}
        >
          S
        </Typography>
      </IconButton>
    </Box>
  );
};

const detailPositionsRow = (
  position: PositionBaseDataType,
  handleClick: (position: PositionBaseDataType) => void,
  handleRemove: (entryTime: string) => void,
): TableRowCellsData[] => {
  return [
    {
      content: BuySellButtonForDetailPositions(position, handleClick),
      align: 'center',
    },
    {
      content: PositionLotsUpdating(position, handleClick),
      align: 'center',
    },
    {
      content: position.premium,
      align: 'center',
    },
    {
      content: moment(position.entryTime).format('DD MMM HH:mm').toString(),
      align: 'center',
    },
    {
      content: (
        <Box
          className="hover-visible"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '20px',
          }}
        >
          <IconButton
            onClick={e => {
              handleRemove(position.entryTime);
              e.stopPropagation();
            }}
          >
            <DeleteForever />
          </IconButton>
        </Box>
      ),
      align: 'center',
    },
  ];
};
export const detailPositions = (
  positions: PositionMetaData,
  handleClick?: (position: PositionMetaData) => void,
) => {
  const rows: TableRow<PositionBaseDataType>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const handleClicked = (position: PositionBaseDataType) => {
    positions = {
      ...positions,
      positionCandleWise: {
        ...positions.positionCandleWise,
        [position.entryTime]: position,
      },
    };
    handleClick!(positions);
  };

  const handleRemove = (entryTime: string) => {
    delete positions.positionCandleWise[entryTime];
    handleClick!(positions);
  };
  const columns: TableColumns[] = [
    { text: i18next.t('simulator.table_column.bs'), align: 'center' },
    { text: i18next.t('simulator.table_column.lots'), align: 'center' },
    { text: i18next.t('simulator.table_column.price'), align: 'center' },
    { text: i18next.t('simulator.table_column.entry_time'), align: 'center' },
    { text: i18next.t('simulator.table_column.actions'), align: 'center' },
  ];
  if (
    !positions.positionCandleWise ||
    Object.values(positions.positionCandleWise).length === 0
  ) {
    return { row: [], columns: [] };
  } else {
    Object.values(positions.positionCandleWise).map(position => {
      rowCellsData = detailPositionsRow(position, handleClicked, handleRemove);
      rows.push({
        rowCellsData,
        rowData: position,
        visibleOnHover: true,
        pt: 1,
        pb: 1,
      });
    });
  }

  return { rows, columns };
};

const PositionLotsUpdating = (
  position: PositionBaseDataType,
  handleClick: (position: PositionBaseDataType) => void,
) => {
  const handleButtonClick = (clicked: string) => {
    //
    position = {
      ...position,
      lots:
        clicked === 'add'
          ? position.lots <= 199
            ? position.lots + 1
            : 200
          : position.lots > 2
          ? position.lots - 1
          : 1,
    };
    handleClick(position);
  };
  const handleChange = event => {
    const lots = Number(event.target.value);
    if (lots >= 0 && lots <= 200) {
      position = {
        ...position,
        lots: lots,
      };
    }
    handleClick(position);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'start',
      }}
    >
      <IconButton
        sx={{
          borderRadius: 0.7,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'error.main',
          mx: 0.3,
          height: '1.4rem',
          width: '1.2rem',
          display: 'flex',
        }}
        onClick={() => {
          handleButtonClick('remove');
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          -
        </Typography>
      </IconButton>
      <TextField
        id="lots-input"
        variant="outlined"
        type="number"
        value={position.lots.toString()}
        onChange={handleChange}
        inputProps={{
          min: 0,
          step: 1,
          max: 200,
        }}
        sx={{
          width: '3rem',
          height: '1rem',
          '& input': {
            fontSize: 14,
            padding: '2px',
          },
        }}
      />
      <IconButton
        sx={{
          borderRadius: 0.7,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'success.main',
          mx: 0.3,
          display: 'flex',
          height: '1.4rem',
          width: '1.2rem',
        }}
        onClick={() => {
          handleButtonClick('add');
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          +
        </Typography>
      </IconButton>
    </Box>
  );
};
