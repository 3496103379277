import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { AuthenticateWithApple } from 'services/apiServices';
import { Errors, AuthenticateWithGoogleResult } from 'types/ApiServicesTypes';

export default function useAuthenticateWithApple() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    AuthenticateWithGoogleResult,
    Errors,
    { code: string; id_token: string; bundle_id?: string }
  >('AuthenticateWithApple', values =>
    AuthenticateWithApple(values.code, values.id_token, values.bundle_id)
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
