import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import CodeStragyLogsContent from '../StrategyBuilder/CodeStrategyLogsContent';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { Box } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  handleClose: () => void;
  botId: string;
  type: string;
};

const BackTestingLogsModal = ({ open, handleClose, botId, type }: Props) => {
  const isMdUp = useGetMediaQueryUp('md');
  const { t } = useTranslation();
  return (
    <MuiModal
      open={open}
      handleClose={handleClose}
      isFullScreen={!isMdUp}
      maxWidth="lg"
      header={
        <Box>
          <PageHeader
            variant="withIcon"
            title={t('user_bot_startegy.logs')}
            iconProps={{
              handleClick: () => handleClose(),
              icon: <CloseIcon fontSize="small" />,
            }}
          />
        </Box>
      }
      body={<CodeStragyLogsContent botId={botId} type={type} />}
    />
  );
};

export default BackTestingLogsModal;
