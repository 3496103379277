import { memo } from 'react';
import { Position } from 'types/ApiServicesTypes';
import { PositionItem } from '../speedBot/EntityItem';
import { MCXSYMBOLEMULTIPLAYER } from 'types/Position';

const TodaysPositionItem = ({
  position,
  handlePositionClick,
  currentPrice,
  isVisibleCheckbox,
  isChecked,
}: {
  position: Position;
  handlePositionClick: (postion: Position) => void;
  currentPrice: number;
  isVisibleCheckbox?: boolean;
  isChecked?: boolean;
}) => {
  // const isDisabled = Boolean(position.exit_price && position.exit_price > 0);
  const isBuy = position.direction === '0';
  const multiplier =
    (MCXSYMBOLEMULTIPLAYER[position.instrument_id] as number) || 1;

  const profitLoss =
    currentPrice === 0
      ? position.profit_loss
      : isBuy
      ? (currentPrice - position.entry_Price) *
        Math.abs(position.quantity) *
        multiplier
      : (position.entry_Price - currentPrice) *
        Math.abs(position.quantity) *
        multiplier;
  const percentChange =
    currentPrice === 0
      ? position.percentage_change
      : isBuy
      ? ((currentPrice - position.entry_Price) / position.entry_Price) * 100
      : ((position.entry_Price - currentPrice) / position.entry_Price) * 100;
  return (
    <PositionItem
      position={position}
      handleClick={handlePositionClick}
      currentPrice={currentPrice}
      profitLoss={profitLoss}
      percentChange={percentChange}
      isVisibleCheckbox={isVisibleCheckbox}
      isChecked={isChecked}
    />
  );
};

export default memo(TodaysPositionItem);
