import { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { InstrumentStringProps } from 'types/ComponentTypes';

const InstrumentString = (props: InstrumentStringProps) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const baseEle = useRef<HTMLElement>(null);

  const windowResized = () => {
    if (baseEle.current?.parentElement) {
      if (baseEle.current?.parentElement.clientWidth < 225) {
        setShowOne(true);
        setShowTwo(false);
        setShowThree(false);
      } else if (
        baseEle.current?.parentElement.clientWidth < 300 &&
        baseEle.current?.parentElement.clientWidth > 225
      ) {
        setShowOne(false);
        setShowTwo(true);
        setShowThree(false);
      } else {
        setShowOne(false);
        setShowTwo(false);
        setShowThree(true);
      }
    }
  };
  const updateWindowSize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    windowResized();
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, [windowSize]);

  return (
    <Box
      component="span"
      ref={baseEle}
      sx={{
        display: 'inline-block',
      }}
    >
      {showOne && (
        <span>
          {`${props.instrument.slice(0, 1).map(item => ' ' + item)} ${
            props.instrument.length > 1
              ? `+${props.instrument.length - 1} more`
              : ``
          }`}
        </span>
      )}
      {showTwo && (
        <span>
          {`${props.instrument.slice(0, 2).map(item => ' ' + item)} ${
            props.instrument.length > 2
              ? `+${props.instrument.length - 2} more`
              : ``
          }`}
        </span>
      )}
      {showThree && (
        <span>
          {`${props.instrument.slice(0, 3).map(item => ' ' + item)} ${
            props.instrument.length > 3
              ? `+${props.instrument.length - 3} more`
              : ``
          }`}
        </span>
      )}
    </Box>
  );
};

export default InstrumentString;
