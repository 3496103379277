import { useQuery } from 'react-query';
import { GetWalletTransactions } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import { GetWalletTransactionResult } from 'types/SubscriptionPlans';

export default function useGetWalletTransactions(page: number, maxPage = 10) {
  const objQuery = useQuery<GetWalletTransactionResult, Errors>(
    ['GetWalletTransactions', page, maxPage],
    () => GetWalletTransactions(page, maxPage),
    {
      keepPreviousData: true,
    },
  );
  return objQuery;
}
