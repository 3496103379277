import { Box } from '@mui/system';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useBreadcrumbManager } from '../Common/Breadcrumb';
import HoldingBanner3 from '../../../assets/images/banner/HoldingBanner3.png';
import HoldingBanner2 from '../../../assets/images/banner/HoldingBanner2.png';
import HoldingBanner1 from '../../../assets/images/banner/HoldingBanner1.png';
import { PAGES } from '../Common/Breadcrumb/types';
import ButtonCard from './ButtonCard';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const buttonData = [
  {
    click: 'equity',
    button: 'own_equity_bot',
    image: HoldingBanner1,
    type: 'create',
    description:
      '50+ Technical Indicators, Backtesting, Forward Testing, Live Trading and much more',
  },
  {
    click: 'option',
    button: 'own_option_bot',
    image: HoldingBanner2,
    type: 'create',
    description:
      'Transform your thoughts into action with our intuitive option strategy builder',
  },
  {
    click: 'template',
    button: 'own_template',
    image: HoldingBanner3,
    type: 'explore',
    description: `Don't want to create a strategy template from scratch? No worries, you can add readymade strategy template at 1-click.`,
  },
];
const HomeButtons = () => {
  const breadcrumb = useBreadcrumbManager();

  const handleMenuItemclick = (value: string) => {
    breadcrumb.push(
      value !== 'template' ? '/bot-builder?type=' + value : '/templates',
      value === 'template' ? PAGES.TEMPLATES : PAGES.BOTS,
      `/home`,
      'Home',
    );
  };

  const isEquityBotBuilder = useIsFeatureFlag([FEATURES.IS_EQUITY_BOTBUILDER]);
  const isOptionBotBuilder = useIsFeatureFlag([FEATURES.IS_OPTION_BOTBUILDER]);

  if (!isEquityBotBuilder && !isOptionBotBuilder) return <></>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'column', 'row', 'row'],
        marginTop: 2,
        marginBottom: 2,
        justifyContent: 'center',
        columnGap: 1,
      }}
      className="create-bot"
    >
      {buttonData.map(item => {
        if (
          (!isEquityBotBuilder && item.click === 'equity') ||
          (!isOptionBotBuilder && item.click === 'option')
        )
          return;
        return (
          <ButtonCard
            key={item.button}
            position="end"
            text={
              <>
                <FormattedMessage id={`portfolio.boarding.${item.type}`} />{' '}
                <FormattedMessage id={`portfolio.boarding.${item.button}`} />
              </>
            }
            description={item.description}
            image={item.image}
            // link="/bot-builder"
            bgColor="grey.100"
            borderRadius={1}
            handleClick={() => {
              handleMenuItemclick(item.click);
            }}
          />
        );
      })}
    </Box>
  );
};

export default HomeButtons;
