import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

export type MuiBreadcrumbsLinkProps = {
  text: string;
  to?:
    | {
        pathname: string;
        state: {
          page: string;
          ptype: string;
          botName?: string;
          fromMoreInfo?: boolean;
          fromHistory?: boolean;
          fromUserBacktesting?: boolean;
          subId?: string;
        };
      }
    | string;
  active?: boolean;
};

const MuiBreadcrumbs = ({
  links,
}: {
  links: MuiBreadcrumbsLinkProps[];
}): React.ReactElement => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-li:before,.MuiBreadcrumbs-separator:before ': {
          content: 'none',
        },
      }}
    >
      {links &&
        links.map((link: MuiBreadcrumbsLinkProps, i) =>
          link.to ? (
            <RouterLink to={link.to} key={i}>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ ':hover': { textDecoration: 'underline' } }}
                fontWeight={400}
              >
                {link.text}
              </Typography>
            </RouterLink>
          ) : (
            <Typography
              variant="body1"
              fontWeight={400}
              color={link.active ? 'text.primary' : 'inherit'}
              key={i}
            >
              {link.text}
            </Typography>
          ),
        )}
    </Breadcrumbs>
  );
};

export default MuiBreadcrumbs;
