import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { PostBotSignal } from 'services/apiServices';
import {
  PostBotSignalResult,
  Errors,
  PostBotSignalParams,
} from 'types/ApiServicesTypes';

export default function usePostBotSignal(id: number) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostBotSignalResult,
    Errors,
    PostBotSignalParams
  >(['PostBotSignal', id], values =>
    PostBotSignal(id, values)
      .then(res => {
        if (res.error) showAlert!(res.error);
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
