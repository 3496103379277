export type UserInfo = {
  session_id: string;
  username: string;
  uid: number;
  error?: string;
  capabilities?: { features: { liveTrading: boolean } };
};

export enum UserPlanType {
  FIXED = 'fixed',
  PAY_AS_YOU_GO = 'pay_as_you_go',
}
