import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const MainLayoutContainer = styled(Box)<{ isSideNav: boolean }>(
  ({ theme, isSideNav }) => ({
    height: '100%',
    // minHeight: '100vh',
    backgroundColor: theme.component.mainLayoutContainer.main,
    margin: 'auto',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: isSideNav ? 0 : theme.spacing(8.25),
      // paddingBottom: theme.spacing(2),
      // paddingBottom: 0,
    },
  }),
);

export default MainLayoutContainer;
