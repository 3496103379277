import React, { useCallback } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Divider } from '@mui/material';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useGoogleLogin } from '@react-oauth/google';
import Google from 'assets/images/icons/social-google.svg';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

// service
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useDoSingIn } from 'services/Auth';
import useSignupWithGoogle from 'services/Auth/useSignupWithGoogle';
import { useTranslation } from 'react-i18next';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import useAuthenticateWithGoogle from 'services/Auth/useAuthenticateWithGoogle';
import SpeedBotLogo from '../Common/SpeedBotLogo';
// import { getQueryParam } from 'utils/GenericFunctions';

export function HomePage({
  onAuthPageChange,
}: {
  onAuthPageChange: (view: string) => void;
}) {
  const { t } = useTranslation();
  const { doSignIn } = useDoSingIn();
  // const ref = getQueryParam('ref');

  const { data: stiteInfo } = useGetSiteInfo();
  const mutation_signup_google = useSignupWithGoogle();

  const mutation_authenticate_google = useAuthenticateWithGoogle();

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      mutation_authenticate_google.mutate(
        {
          access_token: tokenResponse.access_token,
          email: '',
          expires_in: tokenResponse.expires_in.toString(),
          scope: tokenResponse.scope,
          token_type: tokenResponse.token_type,
        },
        {
          onSuccess: res => {
            if (res) {
              if (res.session_id) {
                doSignIn();
              }
            }
          },
        },
      );
    },
  });
  const handleSignInClick = () => {
    onAuthPageChange('Sign In');
  };
  const handleSignUpClick = () => {
    onAuthPageChange('Sign Up');
  };
  const handleGoogleLogin = useCallback(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('googleSignIn');
    } else {
      googleLogin();
    }
  }, [googleLogin]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Signup Page</title>
        <meta name="description" content="Signup page" />
      </Helmet>
      <CircularLoader open={mutation_signup_google.isLoading} />
      <SigninPageContentLayout>
        {/* Must have max two child element */}
        <Box textAlign="center">
          <SpeedBotLogo />

          <Typography
            variant="h4"
            color="primary.dark"
            sx={{ fontWeight: 700, mt: 4 }}
          >
            <FormattedMessage id="start_your_bot_now" />
          </Typography>
          <Typography variant="h6" color="primary.dark" sx={{ mt: 4 }}>
            {t('join_speedbot_today', {
              seteName: stiteInfo ? stiteInfo.name : '',
            })}
          </Typography>
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="secondary"
            sx={{ borderColor: 'primary.main', mt: 2 }}
            fullWidth={true}
            startIcon={<img src={Google} alt="google" />}
            onClick={handleGoogleLogin}
          >
            <FormattedMessage id="sign_up_with_google" />
          </MuiButton>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
            <Typography
              variant="overline"
              sx={{ mx: 1, textTransform: 'lowercase' }}
            >
              <FormattedMessage id="or" />
            </Typography>
            <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <MuiButton
              className="loginflowBtn"
              size="large"
              variant="contained"
              color="primary"
              onClick={() => handleSignUpClick()}
              // to={`/signup/start?ref=${ref}`}
              fullWidth={true}
              // component={RouterLink}
            >
              <FormattedMessage id="sign_up_with_phone_or_email" />
            </MuiButton>
          </Box>
          <Box sx={{ mt: 2, display: 'flex' }}>
            <Typography variant="caption" color="primary.dark">
              By signing up, you agree to{' '}
              <a
                target="_blank"
                href={
                  stiteInfo?.term_link
                    ? stiteInfo.term_link
                    : '"https://speedbot.tech/terms'
                }
                rel="noreferrer"
              >
                <Typography
                  variant="caption"
                  component="span"
                  sx={{ textDecoration: 'underline' }}
                >
                  Terms of Service and Privacy Policy
                </Typography>
              </a>
              , including Cookie Use.
            </Typography>
          </Box>
          <Typography variant="body1" color="primary.dark" sx={{ mt: 8 }}>
            <FormattedMessage id="login_link" />
          </Typography>
          <Box sx={{ mt: 2 }}>
            <MuiButton
              className="loginflowBtn"
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => handleSignInClick()}
              // component={RouterLink}
              // to={`/signin?ref=${ref}`}
              sx={{ borderColor: 'primary.main' }}
              fullWidth={true}
            >
              <FormattedMessage id="sign_in" />
            </MuiButton>
          </Box>
        </Box>
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default HomePage;
