import { setCookie, removeCookie } from 'typescript-cookie';
import { Resp } from '../types';

// const url = 'https://slopeindex.silentinfotech.com'; // url without tailing slash('/')
const baseURL = '/history';
const db_name = 'master';

export const session_string = 'session_id';
export const active_user_string = 'active_user';
// const temp_session_id = '2f79f1b467a6d3db40146e4ecc63f08594beee53';
const temp_session_id = 'c3d4d7ab04b68d1cd9274703a05a345aefa8c2d7';

function buildRequest(params: unknown) {
  return JSON.stringify({
    jsonrpc: '2.0',
    method: 'call',
    params: params, // payload
  });
}

function handleOdooErrors(response: Resp) {
  if (!response.error) {
    return response.result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const error = response.error;
  const errorObj = {
    title: '    ',
    message: '',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    fullTrace: error,
  };

  if (
    error.code === 200 &&
    error.message === 'Odoo Server Error' &&
    error.data.name === 'werkzeug.exceptions.NotFound'
  ) {
    errorObj.title = 'page_not_found';
    errorObj.message = 'HTTP Error';
  } else if (
    error.code === 200 &&
    error.message === 'Odoo Server Error' &&
    (error.data.name === 'builtins.ValueError' ||
      error.data.name === 'odoo.exceptions.AccessDenied')
  ) {
    errorObj.title = 'authentication failed';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    errorObj.message = error.data.message;
  } else if (
    (error.code === 100 && error.message === 'Odoo Session Expired') || // v8
    (error.code === 300 &&
      error.message === 'OpenERP WebClient Error' &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error.data.debug.match('SessionExpiredException')) // v7
  ) {
    // window.open('/discover', '_self');
    cleanUpInfo();
    const refPath = window.location.pathname + window.location.search;
    let redirectUrl = '/signin';
    if (refPath) (redirectUrl = `/signin?ref=${refPath}`), '_self';
    // if (refPath) window.open(`/signin?ref=${refPath}`, '_self');
    // else window.open(`/signin`, '_self');
    window.location.assign(redirectUrl);

    // alert('Session Expired');
    // return Promise.reject('Session Expired')
  } else if (
    error.message === 'Odoo Server Error' &&
    /FATAL: database "(.+)" does not exist/.test(error.data.message)
  ) {
    errorObj.title = 'database_not_found';
    errorObj.message = error.data.message;
  } else if (error.data.name === 'openerp.exceptions.AccessError') {
    errorObj.title = 'AccessError';
    errorObj.message = error.data.message;
  } else {
    const split = ('' + error.data.fault_code).split('\n')[0].split(' -- ');
    if (split.length > 1) {
      const shiftRes = split.shift();
      error.type = shiftRes ? shiftRes : '';
      error.data.fault_code = error.data.fault_code.substr(
        error.type.length + 4,
      );
    }

    if (error.code === 200 && error.type) {
      errorObj.title = error.type;
      errorObj.message = error.data.fault_code.replace(/\n/g, '<br />');
    } else {
      errorObj.title = error.message;
      errorObj.message = error.data.debug.replace(/\n/g, '<br />');
    }
  }
  return Promise.reject(errorObj);
}

export async function makeApiRequest(path: string, params: unknown = {}) {
  try {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-Openerp-Session-Id': temp_session_id,
      };
      const response = await fetch(`${baseURL}/${path}`, {
        method: 'POST',
        headers: headers,
        body: buildRequest(params),
      });
      const rresponse = (await response.json()) as Resp;
      if (rresponse.result && rresponse.result.session_id) {
        // localStorage.setItem(session_string, rresponse.result.session_id);
        setCookie('tvSessionId', rresponse.result.session_id);
      }
      return handleOdooErrors(rresponse);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      return Promise.reject('Something went wrong');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw new Error(`Api request error: ${error}`);
  }
}

export function isLoggedIn() {
  return localStorage.getItem(session_string) ? true : false;
}

export function logOut() {
  cleanUpInfo();
  return true;
}

export function cleanUpInfo() {
  localStorage.removeItem(session_string);
  localStorage.removeItem(active_user_string);
  removeCookie('tvSessionId');
}

export default async function authenticate(username: string, password: string) {
  const params = {
    db: db_name,
    login: username,
    password: password,
  };
  try {
    const Resp = await makeApiRequest('web/session/authenticate', params);
    localStorage.setItem(active_user_string, JSON.stringify(Resp));
    return Resp;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    throw err;
  }
}
