import { makeApiRequest } from '../HelperTradingView/odoo-rpc.helper';

const dataFeed = (
  historyCallback?: () => void,
  getOrdersCallback?: (
    moduleDefaultOnResultReadyCallback: (symbolsList: string[]) => void,
    exchange: string,
    symbolType: string,
  ) => void,
  getSymbolList?: () => string[],
) => {
  return {
    onReady: (callback: (arg0: unknown) => void) => {
      makeApiRequest('config')
        .then(data => {
          callback(data);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    searchSymbols: (
      userInput: string,
      exchange: string,
      symbolType: string,
      onResultReadyCallback: (arg0: string[]) => void,
    ) => {
      if (userInput.length > 0 && getSymbolList) {
        const returnData = getSymbolList();
        if (returnData && returnData.includes(userInput)) {
          makeApiRequest('search_symbol', {
            query: userInput,
            exchange: exchange,
            symbol_type: symbolType,
          })
            .then(data => {
              const dataT = data as unknown;
              onResultReadyCallback(dataT as string[]);
            })
            .catch(err => {
              onResultReadyCallback([]);
              // eslint-disable-next-line no-console
              console.log(err);
            });
        } else {
          onResultReadyCallback([]);
        }
      } else if (getOrdersCallback) {
        getOrdersCallback(onResultReadyCallback, exchange, symbolType);
      } else {
        return;
      }
    },
    resolveSymbol: (
      symbolName: string,
      onSymbolResolvedCallback: (arg0: unknown) => void,
      onResolveErrorCallback: (arg0: string) => void,
    ) => {
      makeApiRequest('symbol', { symbol: symbolName })
        .then(data => {
          if (data && Object.keys(data).length >= 1) {
            onSymbolResolvedCallback(data);
          } else {
            onResolveErrorCallback('Can not resolve symbol.');
          }
        })
        .catch(err => {
          onResolveErrorCallback(err as string);
        });
    },
    getBars: (
      symbolInfo: { ticker: string },
      resolution: string,
      from: number,
      to: number,
      onHistoryCallback: (arg0: unknown, arg1: unknown) => void,
      onErrorCallback: (arg0: string) => void,
    ) => {
      makeApiRequest('history', {
        symbol: symbolInfo.ticker,
        resolution: resolution,
        from_ts: from,
        to_ts: to,
      })
        .then(data => {
          if (data && Object.keys(data).length >= 1) {
            onHistoryCallback(data.data, data.metadata);
            if (historyCallback) {
              historyCallback();
            }
          } else {
            onErrorCallback('Can not resolve symbol.');
          }
        })
        .catch(err => {
          onErrorCallback(err as string);
        });
    },
    subscribeBars: (
      symbolInfo: unknown,
      resolution: string,
      onRealtimeCallback: () => void,
      subscribeUID: string,
    ) => {
      // eslint-disable-next-line no-console
      console.log(
        '[subscribeBars]: Method call with subscribeUID:',
        subscribeUID,
      );
    },
    unsubscribeBars: (subscriberUID: string) => {
      // eslint-disable-next-line no-console
      console.log(
        '[unsubscribeBars]: Method call with subscriberUID:',
        subscriberUID,
      );
    },
  };
};

export default dataFeed;
