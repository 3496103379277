import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import ProfitLossByDateReport from 'app/design/speedBot/ComponentItem/Common/ProfitLossByDateReport';

export function BotHistoryProfitLossByDateReportPage() {
  const { bid, sid, title, hsid, subtitle } = useParams<{
    bid: string;
    sid: string;
    title: string;
    hsid: string;
    subtitle: string;
  }>();
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const botId = parseInt(bid);
  const baseUrl = `bots/${bid}/history/${hsid}`;

  return (
    <>
      <Helmet>
        <title>Profit And Loss By Date Report</title>
        <meta
          name="description"
          content="Profit And Loss By Date Report Page"
        />
      </Helmet>
      <ProfitLossByDateReport
        baseUrl={baseUrl}
        pageHeadButtonText={title}
        breadCrumbPage={PAGES.BOT_HISTORY_PROFITLOSS}
        breadCrumbData={{
          subId,
          botId,
          botName: title,
          historySubId: hsid,
          title: subtitle,
        }}
      />
    </>
  );
}
