import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { isUserLoggedIn } from 'utils/auth';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

// Firebase analytics
import useFirebasePageTracking from 'app/hooks/useFirebasePageTracking';
import { useTranslation } from 'react-i18next';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { Helmet } from 'react-helmet-async';
import PublicRoutes from './PublicRoutes';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { QueryCacheInvalidateOnSocketNotification } from 'app/components/Common/QueryCacheInvalidation/QueryCacheInvalidateOnSocketNotification';
//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
  const { i18n } = useTranslation();
  const { data: stiteInfo } = useGetSiteInfo();
  const isTemplates = useIsFeatureFlag([
    FEATURES.IS_TEMPLATES,
    FEATURES.IS_ALLOW_PUBLIC_PAGES,
  ]);
  const isHomePage = useIsFeatureFlag([
    FEATURES.IS_HOME_PAGE,
    FEATURES.IS_ALLOW_PUBLIC_PAGES,
  ]);
  // let defaultPath = isTemplates ? '/templates' : '/signin';
  let defaultPath = isHomePage
    ? '/home'
    : isTemplates
    ? '/templates'
    : '/signin';

  const isLoggedIn = isUserLoggedIn();

  if (isLoggedIn) {
    defaultPath = process.env.REACT_APP_DEFAULT_PATH as string;
  }

  // Init page tracking in firebase
  useFirebasePageTracking();

  return (
    <>
      {stiteInfo && stiteInfo.name != undefined && (
        <>
          <Helmet
            titleTemplate={stiteInfo.name ? `%s - ${stiteInfo.name}` : ''}
            defaultTitle={stiteInfo.name}
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content={stiteInfo.name} />
            {stiteInfo.favicon && (
              <>
                <link rel="icon" href={stiteInfo.favicon} />
                <link rel="apple-touch-icon" href={stiteInfo.favicon} />
              </>
            )}
          </Helmet>
          <Switch>
            <Redirect exact from="/" to={defaultPath} />
            <React.Fragment>
              {/* Route for login */}
              <LoginRoutes />

              {/* Routes for main layouts */}
              <MainRoutes />

              {/* Route for public pages */}
              <PublicRoutes />
              {isLoggedIn && <QueryCacheInvalidateOnSocketNotification />}
            </React.Fragment>
          </Switch>
        </>
      )}
    </>
  );
};

export default Routes;
