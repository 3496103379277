export const SYMBOL = {
  'NIFTY 50': 'NIFTY',
  'NIFTY BANK': 'BANKNIFTY',
  'NIFTY FIN SERVICE': 'FINNIFTY',
};

export const LIVEDATASYMBOL = {
  'NIFTY 50': 'NIFTY_SPACE_50',
  'NIFTY BANK': 'NIFTY_SPACE_BANK',
  'NIFTY FIN SERVICE': 'NIFTY_SPACE_FIN_SPACE_SERVICE',
};

export const SUBSCRIPTIONSYMBOL = {
  'NIFTY 50': 'NIFTY_SYMBOL',
  'NIFTY BANK': 'BANK_NIFTY_SYMBOL',
  'NIFTY FIN SERVICE': 'FIN_NIFTY_SYMBOL',
};
