let xDown: number | null = null;
let yDown: number | null = null;
let eventDir: string | null = null;

function getTouches(e: React.TouchEvent<HTMLElement>) {
  return e.touches;
}

export const handleTouchStartHandle = (e: React.TouchEvent<HTMLElement>) => {
  const firstTouch = getTouches(e)[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
};
export const handleTouchMoveHandle = (e: React.TouchEvent<HTMLElement>) => {
  if (!xDown || !yDown) {
    return null;
  }

  const xUp = e.touches[0].clientX;
  const yUp = e.touches[0].clientY;

  const xDiff = xDown - xUp;
  const yDiff = yDown - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    /*most significant*/
    if (xDiff > 0) {
      eventDir = 'right';
      /* right swipe */
    } else {
      eventDir = 'left';
      /* left swipe */
    }
  } else {
    if (yDiff > 0) {
      eventDir = 'down';
      /* down swipe */
    } else {
      eventDir = 'up';
      /* up swipe */
    }
  }
  /* reset values */
  xDown = null;
  yDown = null;

  return eventDir;
};
