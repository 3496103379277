import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { PortfolioItem } from '../speedBot/EntityItem/';

// Service
import { PortfolioBot } from 'types/ApiServicesTypes';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const PortfolioListItem = ({
  bots,
  ptype,
  liveFeedTodaysPosData,
}: {
  bots: PortfolioBot[];
  ptype: string;
  liveFeedTodaysPosData: { [id: number]: number };
}) => {
  const isDisplayUser = useIsFeatureFlag([
    FEATURES.IS_DISPLAY_BROKER_IN_PORTFOLIO,
  ]);

  return (
    <>
      {bots.map((item, j) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const todaysChange: number =
          liveFeedTodaysPosData && liveFeedTodaysPosData[item.id]
            ? liveFeedTodaysPosData[item.id]
            : item.todays_change;
        return (
          <Fragment key={item.id}>
            <PortfolioItem
              todaysChange={todaysChange}
              ptype={ptype}
              item={item}
              isDisplayUser={isDisplayUser}
            />
            {bots.length - 1 !== j && <Divider className="m-main-spacing" />}
          </Fragment>
        );
      })}
    </>
  );
};

export default PortfolioListItem;
