import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { Errors, SignupWithGoogleResult } from 'types/ApiServicesTypes';
import { AuthValues } from 'types/SigninPageTypes';
import { SignupWithGoogle } from 'services/apiServices';

export default function useSignupWithGoogle() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    SignupWithGoogleResult,
    Errors,
    Pick<
      AuthValues,
      'email' | 'access_token' | 'token_type' | 'expires_in' | 'scope'
    >
  >('SignupWithGoogle', values =>
    SignupWithGoogle(
      'GET',
      values.email,
      values.access_token,
      values.token_type,
      values.expires_in,
      values.scope,
    )
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
