export type LogResultData = {
  strategy_id: number;
  bot_id: number;
  msg_type: number;
  data: string[];
};

// export type LogType = BaseLogData & {
//   msg_type: 1;
//   data: string[];
// };

// export type ErrorType = BaseLogData & {
//   msg_type: 2;
//   data: string[];
// };

// export type LogResultData = LogType | ErrorType;

export type BotSubscriptionType = {
  bot_id: number;
  type: string;
};

export enum SOCKET_LOG_MESSAGE_TYPES {
  LOG = 1,
  ERROR = 2,
  WARN = 3,
}

// export type GetDynamicType<T extends SOCKET_LOG_MESSAGE_TYPES> =
//   T extends SOCKET_LOG_MESSAGE_TYPES.LOG ? LogType : ErrorType;

// export type LogDataCallback<T extends SOCKET_LOG_MESSAGE_TYPES> = (
//   data: GetDynamicType<T>,
// ) => void;

export type LogDataCallback = (data: LogResultData) => void;

export type State = {
  result: TempStoringDataType;
  registredComponents: {
    [componentId: string]: {
      messageType: SOCKET_LOG_MESSAGE_TYPES;
      callback: LogDataCallback;
    };
  };
};

export type TempStoringDataType = {
  [key: string]: LogResultData;
};
