import CircularLoader from 'app/design/uiComponents/CircularLoader';
import DynamicForm from './DynamicForm';
import { useTranslation } from 'react-i18next';

// Service
import useGetBrokerForm from 'services/Menu/useGetBrokerForm';
import useCreateBroker from 'services/Menu/useCreateBroker';
import { CreateBrokerParams } from 'types/ApiServicesTypes';

const AddBrokerForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetBrokerForm();

  const mutation = useCreateBroker();
  const handleFormSubmit = values => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const valuesData: CreateBrokerParams = { ...values };
    mutation.mutate(valuesData);
  };

  return (
    <>
      <CircularLoader open={isLoading} />
      {data && data.brokers && (
        <DynamicForm
          formData={data}
          handleFormSubmit={handleFormSubmit}
          submitButtoneText={t('buttons.add')}
          cancelButtonLink="/broker"
          isLoading={mutation.isLoading}
        />
      )}
    </>
  );
};

export default AddBrokerForm;
