import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useSocketNotificationManager } from './SocketNotificationManager';
import {
  NotificationMessage,
  SOCKET_NOTIFICATION_MESSAGE_TYPES,
} from './types';

export const useSocketNotification = (
  messageTypes: SOCKET_NOTIFICATION_MESSAGE_TYPES[],
) => {
  const notificationManager = useSocketNotificationManager();
  const [updatedMessages, setUpdatedMessages] = React.useState<
    NotificationMessage[]
  >([]);

  const onUpdatedMessages = React.useCallback(
    (messages: NotificationMessage[]) => {
      setUpdatedMessages(messages);
    },
    [],
  );

  React.useEffect(() => {
    // eslint-disable-next-line
    const componentId: string = uuidv4();
    if (notificationManager) {
      notificationManager.registerComponent(
        componentId,
        messageTypes,
        onUpdatedMessages,
      );
    }

    return () => {
      if (notificationManager) {
        notificationManager.unRegisterComponent(componentId);
      }
    };
  }, [messageTypes, notificationManager, onUpdatedMessages]);

  return updatedMessages;
};
