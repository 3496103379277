import * as React from 'react';
import './index.css';
import Datafeed from './Datafeeds/datafeed';
// import { widget } from '../../charting_library/charting_library.min';
import SaveLoad from './Datafeeds/saveload';
import moment from 'moment';
import {
  ChartOrders,
  DyanmicObject,
  TVPreviousRange,
  TVShapeId,
  TVWidget,
} from './types';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

type TVChartContainerProps = {
  chartOrders: ChartOrders[];
  symbol: string;
  interval: string;
  containerId: string;
  libraryPath: string;
  fullscreen: string;
  autosize: string;
  fromdate: string;
  todate: string;
  indicators: DyanmicObject[];
  intervalInSeconds: number;
  showDrawingToolbar: boolean;
  highlightedOrder?: ChartOrders | null;
};

export class TVChartContainer extends React.PureComponent<TVChartContainerProps> {
  static defaultProps = {
    symbol: 'COMEX:GC',
    interval: '15',
    containerId: 'tv_chart_container',
    libraryPath: '/charting_library/',
    fullscreen: false,
    autosize: true,
    fromdate: '2020-10-20',
    todate: '2020-10-21',
    intervalInSeconds: 15 * 60,
  };

  tvWidget: TVWidget | null = null;
  previousRange: TVPreviousRange | null = null;
  previousTime = 0;
  createdOrderTimeframe = {};
  createdShapeId: TVShapeId = null;
  hightlightedFromShapeId: TVShapeId = null;
  hightlightedToShapeId: TVShapeId = null;

  componentDidMount() {
    const historyCallback = () => {
      this.loadShapesInGivenResolution();
    };

    const getCurrentSymbolList = () => {
      let retData: string[] = [];
      if (this.props.chartOrders) {
        retData = Array.from(
          new Set(this.props.chartOrders.map(e => e.symbol)),
        );
      }
      return retData;
    };

    const getOrdersCallback = (
      moduleDefaultOnResultReadyCallback: (symbolsList: string[]) => void,
      exchange: string,
      symbolType: string,
    ) => {
      if (this.props.chartOrders) {
        const retData = Array.from(
          new Set(this.props.chartOrders.map(e => e.symbol)),
        );
        if (retData && retData.length) {
          const symbolsList: string[] = [];
          const dataFeed = Datafeed(undefined, undefined, getCurrentSymbolList);
          retData.forEach(list => {
            dataFeed.searchSymbols(list, exchange, symbolType, data => {
              symbolsList.push(...data);
              moduleDefaultOnResultReadyCallback(symbolsList);
            });
          });
        }
      }
    };

    const widgetOptions = {
      symbol: this.props.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: Datafeed(
        historyCallback,
        getOrdersCallback,
        getCurrentSymbolList,
      ),
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      save_load_adapter: SaveLoad,
      timezone: 'Asia/Kolkata',
      // symbol_search_request_delay: 500,
      // debug: true,
      disabled_features: [
        // 'header_symbol_search',
        'symbol_search_hot_key',
        'header_resolutions',
        'header_chart_type',
        // 'header_settings',
        // 'header_indicators',
        'header_compare',
        'header_undo_redo',
      ],
      // enabled_features: ['header_fullscreen_button'],
      locale: getLanguageFromURL() || 'en',
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      // custom_indicators_getter,
    };

    // eslint-disable-next-line
    const tvWidget = new (window as any).TradingView.widget(widgetOptions);
    // const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget as TVWidget;
    this.tvWidget?.onChartReady(() => {
      this.onChartReady(true);
      // tvWidget.chart().createStudy('CupNHandle');
      // tvWidget.chart().createStudy('GRaB', false, true);
    });
    // eslint-disable-next-line
    (window as any).tvWidget = tvWidget;
  }

  componentDidUpdate(prevProps: TVChartContainerProps) {
    if (
      prevProps.fromdate !== this.props.fromdate ||
      prevProps.todate !== this.props.todate
    ) {
      if (this.tvWidget && this.tvWidget._ready) {
        this.onChartReady(false);
        if (this.props.highlightedOrder) {
          this.renderHightlightedShape(this.props.highlightedOrder);
        }
      }
    }
  }

  renderHightlightedShape(hightlightedOrder: ChartOrders | null) {
    if (this.hightlightedFromShapeId) {
      this.tvWidget?.activeChart().removeEntity(this.hightlightedFromShapeId);
    }
    if (this.hightlightedToShapeId) {
      this.tvWidget?.activeChart().removeEntity(this.hightlightedToShapeId);
    }
    if (hightlightedOrder) {
      const fromDateTS = Date.parse(hightlightedOrder.fromDate) / 1000 - 1;
      const toDateTS = Date.parse(hightlightedOrder.toDate) / 1000 - 1;
      this.hightlightedFromShapeId = this.tvWidget?.activeChart().createShape(
        {
          time: fromDateTS,
          channel: hightlightedOrder.entryType === 'Long' ? 'low' : 'high',
        },
        {
          shape:
            hightlightedOrder.entryType === 'Long' ? 'arrow_up' : 'arrow_down',
          vertLabelsAlign: 'top',
          zOrder: 'top',
          lock: true,
          text: hightlightedOrder.entryType === 'Long' ? 'LE' : 'SE',
          overrides: {
            color: '#757575',
          },
        },
      );
      this.hightlightedToShapeId = this.tvWidget?.activeChart().createShape(
        {
          time: toDateTS,
          channel: hightlightedOrder.entryType === 'Long' ? 'high' : 'low',
        },
        {
          shape:
            hightlightedOrder.entryType === 'Long' ? 'arrow_down' : 'arrow_up',
          vertLabelsAlign: 'top',
          zOrder: 'top',
          lock: true,
          text: hightlightedOrder.entryType === 'Long' ? 'LX' : 'SX',
          overrides: {
            color: '#757575',
          },
        },
      );
    }
  }

  onChartReady(drawIndicators: boolean) {
    const { f_date, t_date } = this.calculateFromToDate();
    if (this.props.indicators && drawIndicators) {
      const chart = this.tvWidget?.activeChart();
      if (!this.props.showDrawingToolbar) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        chart?.executeActionById('drawingToolbarAction');
      }
      this.props.indicators.map(indicator => {
        if (indicator.name) {
          try {
            if (chart) {
              chart
                .createStudy(indicator.name, false, false, indicator.options)
                .then(response => {
                  if (chart && indicator.overrides) {
                    chart
                      .getStudyById(response)
                      .applyOverrides(indicator.overrides);
                  }
                })
                .catch(error => {
                  throw error;
                });
            }
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
          }
        }
      });
    }
    this.tvWidget
      ?.activeChart()
      .setVisibleRange({ from: f_date, to: t_date })
      .catch(error => {
        throw error;
      });
  }

  calculateFromToDate() {
    const fromDateTS = Date.parse(this.props.fromdate) / 1000;
    const toDateTS = Date.parse(this.props.todate) / 1000;
    const extraFromDateTs =
      Date.parse(moment(this.props.fromdate).subtract(24, 'hours').toString()) /
      1000;
    const extraToDateTS =
      Date.parse(moment(this.props.todate).add(24, 'hours').toString()) / 1000;
    const isnum = /^\d+$/.test(this.props.interval);
    let f_date: number;
    let t_date: number;
    if (
      this.props.interval === '60' ||
      this.props.interval === '120' ||
      this.props.interval === '180' ||
      this.props.interval === '240'
    ) {
      f_date = extraFromDateTs;
      t_date = extraToDateTS;
    } else if (this.props.interval === 'D') {
      f_date = extraFromDateTs;
      t_date = extraToDateTS;
    } else if (isnum) {
      f_date = extraFromDateTs;
      t_date = extraToDateTS;
    } else {
      f_date = fromDateTS;
      t_date = toDateTS;
    }
    return { f_date, t_date };
  }

  loadShapesInGivenResolution() {
    const { f_date, t_date } = this.calculateFromToDate();
    const range = this.tvWidget?.activeChart().getVisibleRange();
    if (range && range.from >= f_date) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.tvWidget
        ?.activeChart()
        .setVisibleRange({ from: f_date, to: t_date })
        .then(() => {
          const range = this.tvWidget?.activeChart().getVisibleRange();
          if (this.props.chartOrders) {
            this.props.chartOrders.map(order => {
              // One second is deducted due to the below reason.
              // For showing arrow at time we are currently using order time. ex. 9:15 AM. while adding arrow to chart,
              // change to 9:14:59 AM. i.e. subtract 1 sec from time so the arrow should come at previous candle.
              const fromDateTS = Date.parse(order.fromDate) / 1000 - 1;
              const toDateTS = Date.parse(order.toDate) / 1000 - 1;
              const isFromNotIncludedInPreviousRange =
                !this.previousRange ||
                (this.previousRange && this.previousRange.from > fromDateTS);
              if (
                range &&
                fromDateTS > range.from &&
                isFromNotIncludedInPreviousRange &&
                order.symbol === this.props.symbol.split('NSE:')[1].trim()
              ) {
                const entryShapeId = this.tvWidget?.activeChart().createShape(
                  {
                    time: fromDateTS,
                    channel: order.entryType === 'Long' ? 'low' : 'high',
                  },
                  {
                    shape:
                      order.entryType === 'Long' ? 'arrow_up' : 'arrow_down',
                    vertLabelsAlign: 'top',
                    zOrder: 'top',
                    lock: true,
                    text: order.entryType === 'Long' ? 'LE' : 'SE',
                    overrides: {
                      color: order.entryType === 'Long' ? '#0000ff' : '#e91e63',
                    },
                  },
                );
                if (entryShapeId) {
                  const shape = this.tvWidget
                    ?.activeChart()
                    ?.getShapeById(entryShapeId);
                  if (shape) {
                    const entryPoints = shape.getPoints();
                    if (
                      entryPoints &&
                      entryPoints.length &&
                      entryPoints[0].time
                    ) {
                      this.createdOrderTimeframe[entryPoints[0].time] = {
                        ...order,
                        transactionType: 'buy',
                      };
                    }
                  }
                }
              }
              const isToNotIncludedInPreviousRange =
                !this.previousRange ||
                (this.previousRange && this.previousRange.from > toDateTS);
              if (
                range &&
                toDateTS > range.from &&
                isToNotIncludedInPreviousRange &&
                order.symbol === this.props.symbol.split('NSE:')[1].trim()
              ) {
                // eslint-disable-next-line
                const exitShapeId = this.tvWidget?.activeChart().createShape(
                  {
                    time: toDateTS,
                    channel: order.entryType === 'Long' ? 'high' : 'low',
                  },
                  {
                    shape:
                      order.entryType === 'Long' ? 'arrow_down' : 'arrow_up',
                    vertLabelsAlign: 'top',
                    zOrder: 'top',
                    lock: true,
                    text: order.entryType === 'Long' ? 'LX' : 'SX',
                    overrides: {
                      color: order.entryType === 'Long' ? '#0000ff' : '#e91e63',
                    },
                  },
                );
                if (exitShapeId) {
                  const shape = this.tvWidget
                    ?.activeChart()
                    ?.getShapeById(exitShapeId);
                  if (shape) {
                    const exitPoints = shape.getPoints();
                    if (exitPoints && exitPoints.length && exitPoints[0].time) {
                      this.createdOrderTimeframe[exitPoints[0].time] = {
                        ...order,
                        transactionType: 'sell',
                      };
                    }
                  }
                }
              }
            });
            this.previousRange = range as TVPreviousRange;
          }
          this.tvWidget?.activeChart().setResolution(this.props.interval);
          this.tvWidget
            ?.activeChart()
            .onSymbolChanged()
            .subscribe(this, this.setTimezoneBySymbol.bind(this));
          this.tvWidget?.activeChart().crossHairMoved(({ time }) => {
            if (
              time &&
              this.createdOrderTimeframe[time] &&
              !this.createdShapeId
            ) {
              this.previousTime = time;
              this.createdShapeId = this.tvWidget?.activeChart().createShape(
                {
                  time: time,
                  channel:
                    this.createdOrderTimeframe[time].transactionType === 'buy'
                      ? 'high'
                      : 'low',
                },
                {
                  shape: 'balloon',
                  vertLabelsAlign: 'top',
                  zOrder: 'top',
                  lock: true,
                  text:
                    this.createdOrderTimeframe[time].transactionType === 'buy'
                      ? `Entry: ${this.createdOrderTimeframe[time].entryPrice}`
                      : `Exit: ${this.createdOrderTimeframe[time].exitPrice} \n P&L: ${this.createdOrderTimeframe[time].profit_loss}`,
                  overrides: {
                    color:
                      this.createdOrderTimeframe[time].entryType === 'Long'
                        ? '#0000ff'
                        : '#e91e63',
                  },
                },
              );
            } else {
              if (this.createdShapeId && time !== this.previousTime) {
                this.tvWidget?.activeChart().removeEntity(this.createdShapeId);
                this.createdShapeId = null;
              }
            }
          });
          this.props.highlightedOrder &&
            this.renderHightlightedShape(this.props.highlightedOrder);
        })
        .catch(error => {
          throw error;
        });
    }
  }

  setTimezoneBySymbol(data: { timezone: unknown }) {
    if (data.timezone && this.tvWidget) {
      this.tvWidget.activeChart().setTimezone(data.timezone);
    }
  }

  componentWillUnmount() {
    if (this.tvWidget != null) {
      // this.tvWidget.unsubscribeAll();
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <>
        <div id={this.props.containerId} className={'TVChartContainer'} />
      </>
    );
  }
}
