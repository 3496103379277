import { OrderNotificationMessage } from 'app/hooks/useSocketNotification/types';
import { QueryKey } from 'react-query';

export const porfolioPageQueryKey =
  (queryKey: string, portolioBotType: OrderNotificationMessage['sub_type']) =>
  (order: OrderNotificationMessage): QueryKey | undefined => {
    return order.sub_type === portolioBotType ? [queryKey] : undefined;
  };

export const porfolioBotQueryKey =
  (queryKey: string | Array<string | number>) =>
  (order: OrderNotificationMessage): QueryKey => {
    return typeof queryKey === 'string'
      ? [queryKey, order.sub_id]
      : queryKey.concat([Number(order.sub_id)]);
  };
