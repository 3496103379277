import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { PostTicketsData } from 'services/apiServices';
import {
  Errors,
  PostTicketData,
  PostTicketDataResult,
} from 'types/ApiServicesTypes';

export const usePostTicketData = (successCallback: () => void) => {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PostTicketDataResult,
    Errors,
    Pick<PostTicketData, 'subject' | 'description' | 'attachments'>
  >('PostTicketData', value =>
    PostTicketsData(value.subject, value.description, value.attachments)
      .then(res => {
        if (res.success) {
          successCallback();
          showAlert!(
            res.success ? 'Your Request has Been Registered' : '',
            'success',
          );
        } else if (!res || res.errors)
          showAlert!(res.errors ? res.errors : 'Something went wrong. ');
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
};
