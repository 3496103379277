import { Box, Typography } from '@mui/material';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useTranslation } from 'react-i18next';
import useActivateFreeTrial from 'services/Menu/useActivateFreeTrial';
import useGetProfile from 'services/Menu/useGetProfile';
import { FEATURES } from 'types/Feature';

function BotActivateFreeTrial({ isFromModal }: { isFromModal?: boolean }) {
  const { t } = useTranslation();
  const { data: profileDetails } = useGetProfile();
  const activateFreeTrialMutation = useActivateFreeTrial();

  return (
    <FeatureFlag features={[FEATURES.IS_FREE_PLAN_TRIAL]} fallback={<></>}>
      {profileDetails?.free_trial_eligible && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            justifyContent: isFromModal
              ? 'space-between'
              : {
                  xs: 'space-between',
                  md: 'flex-start',
                },
            mt: !isFromModal ? 4 : undefined,
            mb: 2,
          }}
        >
          <Typography variant={isFromModal ? 'body2' : 'body1'}>
            {t('menu.free_plan_trial')}
          </Typography>
          <MuiButton
            onClick={() => activateFreeTrialMutation.mutate()}
            variant="contained"
            disabled={activateFreeTrialMutation.isLoading}
          >
            <FormattedMessage id="menu.activate_now" />
          </MuiButton>
        </Box>
      )}
    </FeatureFlag>
  );
}

export default BotActivateFreeTrial;
