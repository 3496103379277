import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { InfoType } from 'types/ComponentTypes';

export const infoModalData: InfoType[] = [
  {
    title: <FormattedMessage id="backtesting.info.modal.initial_cap.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.initial_cap.desc" />,
  },
  {
    title: (
      <FormattedMessage id="backtesting.info.modal.candle_interval.title" />
    ),
    desc: <FormattedMessage id="backtesting.info.modal.candle_interval.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.period.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.period.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.duration.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.duration.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.positions.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.positions.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.total_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.total_prof.desc" />,
  },
  {
    title: <FormattedMessage id="brokerage_fees" />,
    desc: <FormattedMessage id="backtesting.info.modal.brokerage.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.net_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.net_prof.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.car.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.car.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.mdd.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.mdd.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.sharp_ratio.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.sharp_ratio.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.avg_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.avg_prof.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.avg_loss.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.avg_loss.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.max_prof.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.max_prof.desc" />,
  },
  {
    title: <FormattedMessage id="backtesting.info.modal.max_loss.title" />,
    desc: <FormattedMessage id="backtesting.info.modal.max_loss.desc" />,
  },
];
