import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import ShowMoreText from 'react-show-more-text';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';
import useGetPaidAmount from 'services/Menu/useGetPaidAmount';
import { formatNumber } from 'utils/GenericFunctions';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useTheme } from '@mui/material/styles';
type AddCreditModalProps = {
  open: boolean;
  handleClose: (success: boolean, capital: number) => void;
};

function AddCreditModal({ open, handleClose }: AddCreditModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: siteinfo } = useGetSiteInfo();
  const [buttonAmount, setButtonAmount] = useState<number>(1000);
  const handleAmountButtonClick = (amount: number) => {
    setButtonAmount(() => buttonAmount + amount);
  };
  const { data, isLoading, isFetching } = useGetPaidAmount(buttonAmount);
  const addAmount = [100, 200, 1000, 2000];
  const currencySymbol = siteinfo?.currency_symbol || '';

  return (
    <MuiFullScreenModal
      open={open}
      handleClose={() => handleClose(false, 0)}
      body={
        <Formik
          enableReinitialize
          initialValues={{ amount: buttonAmount }}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .positive()
              .required(
                t('menu.required', {
                  type: t('menu.wallet_modal.amount'),
                }),
              ),
          })}
          onSubmit={values => {
            handleClose(true, values.amount);
          }}
        >
          {({ values, errors, handleSubmit, handleBlur, touched }) => {
            return (
              <>
                <Box
                  className="m-main-spacing"
                  component="form"
                  id="credit-amount-form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 2.25rem)',
                    pt: 1,
                    position: 'relative',
                  }}
                >
                  <Box sx={{ mb: 2, flex: 1 }}>
                    <Typography variant="body1" sx={{ pb: 4, fontWeight: 500 }}>
                      <FormattedMessage id="menu.wallet_modal.description" />
                    </Typography>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} order={1}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.amount && errors.amount)}
                          variant="outlined"
                          sx={{ mb: 3 }}
                        >
                          <InputLabel htmlFor="label-amount">
                            <FormattedMessage id="menu.wallet_modal.credits" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-amount"
                            name="amount"
                            type="number"
                            value={values.amount}
                            onBlur={handleBlur}
                            onChange={e =>
                              setButtonAmount(Number(e.target.value))
                            }
                            label={t('menu.wallet_modal.credits')}
                            inputProps={{ min: 1, step: 500 }}
                          />
                          {touched.amount && errors.amount && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.amount}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap ',
                            justifyContent: 'space-between',
                          }}
                        >
                          {addAmount.map((value, index) => (
                            <MuiButton
                              key={index}
                              variant="contained"
                              sx={{ minWidth: 85, mb: 2 }}
                              onClick={() => handleAmountButtonClick(value)}
                            >
                              {
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography>+{value}</Typography>
                                  <Typography>Credits</Typography>
                                </div>
                              }
                            </MuiButton>
                          ))}
                        </Box>
                        <Alert severity="info">
                          <ShowMoreText
                            lines={3}
                            more={'Read More'}
                            less="Less"
                            className="show-more"
                            anchorClass="anchor-show-more"
                            expanded={false}
                            truncatedEndingComponent={'...'}
                          >
                            {siteinfo && siteinfo.notes_add_credit ? (
                              <Typography
                                component={'div'}
                                variant={'body2'}
                                sx={{
                                  fontWeight: 400,
                                  color: 'text.secondary',
                                }}
                                dangerouslySetInnerHTML={{
                                  // eslint-disable-next-line @typescript-eslint/naming-convention
                                  __html: siteinfo.notes_add_credit,
                                }}
                              ></Typography>
                            ) : (
                              <Typography
                                variant={'body2'}
                                sx={{
                                  fontWeight: 400,
                                  color: 'text.secondary',
                                }}
                              >
                                {t('menu.credit_info')}
                              </Typography>
                            )}
                          </ShowMoreText>
                        </Alert>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={1}>
                    <Box display="flex" alignItems="baseline">
                      <Typography variant="body1" color="text.secondary">
                        Amount To Pay:
                      </Typography>
                      {isLoading || isFetching ? (
                        <CircularProgress
                          size={'1rem'}
                          style={{ alignSelf: 'center', marginLeft: 5 }}
                        />
                      ) : (
                        <Box display={'flex'} ml={1}>
                          <Typography
                            variant="body1"
                            fontWeight={600}
                            style={{
                              fontSize: 18,
                              fontWeight: 'bolder',
                            }}
                          >
                            {currencySymbol}
                            {formatNumber(
                              data?.data.estimated_price || 0,
                              false,
                              0,
                            )}
                          </Typography>

                          {data?.data.discount_percentage &&
                          data.data.discount_percentage > 0 ? (
                            <Box display={'flex'}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: 14,
                                  color: theme.palette.grey[600],
                                  marginLeft: '8px',
                                  textDecoration: 'line-through',
                                  fontWeight: 'light',
                                }}
                              >
                                {currencySymbol}
                                {formatNumber(buttonAmount || 0, false, 0)}
                              </Typography>
                              <Typography
                                variant="body1"
                                fontWeight={600}
                                style={{
                                  fontSize: 18,
                                  color: theme.palette.success.main,
                                  marginLeft: '8px',
                                }}
                              >
                                {data?.data.discount_percentage}%off
                              </Typography>{' '}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      }
      footer={
        <>
          <MuiButton
            fullWidth
            variant="contained"
            sx={{ mb: 2 }}
            type="submit"
            form="credit-amount-form"
          >
            <FormattedMessage id="menu.wallet_modal.proceed_to_pay" />
          </MuiButton>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClose(false, 0)}
          >
            <FormattedMessage id="buttons.cancel" />
          </MuiButton>
        </>
      }
    />
  );
}

export default AddCreditModal;
