import { useQuery } from 'react-query';
import { GetBotDetails } from 'services/apiServices';
import { Errors, GetBotDetailsResult } from 'types/ApiServicesTypes';

export default function useGetBotDetails(bId: number, sId: number) {
  // const queryKey = sId ? ['GetBotDetails', bId, sId] : ['GetBotDetails', bId];
  const queryKey = ['GetBotDetails', bId, sId];
  const objQuery = useQuery<GetBotDetailsResult, Errors>(queryKey, () =>
    GetBotDetails(bId, sId),
  );
  if (objQuery.data && objQuery.data.scripts) {
    objQuery.data.scripts = objQuery.data.scripts.map(item => ({
      ...item,
      symbol: item.symbol.split('_SPACE').join(''),
    }));
  }

  return objQuery;
}
