// Service
import { createBotPositionTableRows } from './utils';
import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import { Position, GetBotPositionsResult } from 'types/ApiServicesTypes';
// import BotPositionRowDetail from './BotPositionRowDetail';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

// const DetailsRowRenderer = ({
//   rowData,
//   handleClose,
// }: {
//   rowData: Position;
//   handleClose: () => void;
// }) => {
//   return <BotPositionRowDetail position={rowData} handleClose={handleClose} />;
// };

const BotPositionsTable = ({
  data,
  handleChartRowClick,
  handleClick,
  page,
}: {
  data: PositionsListLayoutProps | Position[] | GetBotPositionsResult;
  handleChartRowClick?: (positionData: Position) => void;
  handleClick?: (positionData: Position) => void;
  page: number;
}) => {
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);
  const { columns, rows } = createBotPositionTableRows(
    data,
    isChargesAndProfit,
    handleClick,
  );

  return (
    <>
      <Box sx={{ px: 3, mx: 1 }}>
        <CommonTable
          rows={rows}
          columns={columns}
          whichTable="positions"
          // DetailsRowRenderer={DetailsRowRenderer}
          handleChartRowClick={handleChartRowClick}
          page={page}
        />
      </Box>
    </>
  );
};

export default BotPositionsTable;
