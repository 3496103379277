import i18next from 'i18next';
import { Box, Typography } from '@mui/material';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import { calculateLiveFeedPostitionSum } from 'app/components/Portfolio/utils';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { Position, PortfolioBot } from 'types/ApiServicesTypes';
import { AllBotOpenPositions } from 'services/Portfolio/useGetPortfolioActivePaper';
import { SymbolsData } from 'types/Symbols';
import {
  formatNumber,
  decimalFormat,
  getColorByNum,
  getArrowDirByNum,
  getFeesColor,
} from 'utils/GenericFunctions';
import { BreadcrumbPush, PAGES } from 'app/components/Common/Breadcrumb/types';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  BANK_NIFTY_SYMBOL,
  FIN_NIFTY_SYMBOL,
  NIFTY_SYMBOL,
} from 'constants/common';
import { MCXSYMBOLEMULTIPLAYER } from 'types/Position';

export const calculateTodaysChange = (
  openPositions: Array<Position> | Array<AllBotOpenPositions>,
  liveFeedData: SymbolsData,
) => {
  let liveFeedTodaysPosData: { [id: number]: number } = {};

  if (openPositions && openPositions.length > 0) {
    openPositions.forEach((posData: Position | AllBotOpenPositions) => {
      const sum = calculateLiveFeedPostitionSum(liveFeedData, posData);
      liveFeedTodaysPosData = {
        ...liveFeedTodaysPosData,
        [posData.id]: sum,
      };
    });
  }

  return Object.keys(liveFeedTodaysPosData).reduce((sum, key) => {
    sum += Number(liveFeedTodaysPosData[key]);
    return sum;
  }, 0);
};

export const getNiftyData = (liveFeedData: SymbolsData) => {
  const liveNiftyValue = liveFeedData[NIFTY_SYMBOL] || 0;
  const liveNiftyBankValue = liveFeedData[BANK_NIFTY_SYMBOL] || 0;
  const liveFinNiftyValue = liveFeedData[FIN_NIFTY_SYMBOL] || 0;
  const liveNiftyClose = Number(liveNiftyValue.Close);
  const liveNiftyCloseDiff = liveNiftyClose - Number(liveNiftyValue.LastClose);
  const liveNiftyBankClose = Number(liveNiftyBankValue.Close);
  const liveNiftyBankCloseDiff =
    liveNiftyBankClose - Number(liveNiftyBankValue.LastClose);
  const liveFinNiftyClose = Number(liveFinNiftyValue.Close);
  const liveFinNiftyCloseDiff =
    liveFinNiftyClose - Number(liveFinNiftyValue.LastClose);

  return {
    liveNiftyClose,
    liveNiftyCloseDiff,
    liveNiftyBankClose,
    liveNiftyBankCloseDiff,
    liveFinNiftyClose,
    liveFinNiftyCloseDiff,
  };
};

export const getCurrentSymbolPrice = (
  exitPrice: number,
  entryPrice: number,
  liveFeedData: SymbolsData | undefined,
  tradingSymbol: string,
): number => {
  const currentPrice =
    exitPrice === 0 && liveFeedData && liveFeedData[tradingSymbol]
      ? Number(liveFeedData[tradingSymbol].Close)
      : 0;

  return currentPrice ? currentPrice : entryPrice;
};

export const calculateProfitLossAndPerChange = (
  currentPrice: number,
  position: Position,
  avoidFees?: boolean,
): {
  profitLoss: number;
  percentChange: number;
} => {
  const isBuy = position.direction === '0';
  const multiplier =
    (MCXSYMBOLEMULTIPLAYER[position.instrument_id] as number) || 1;
  let profitLoss =
    currentPrice === 0
      ? position.profit_loss
      : isBuy
      ? (currentPrice - position.entry_Price) *
        Math.abs(position.quantity) *
        multiplier
      : (position.entry_Price - currentPrice) *
        Math.abs(position.quantity) *
        multiplier;

  let percentChange =
    currentPrice === 0
      ? position.percentage_change
      : isBuy
      ? ((currentPrice - position.entry_Price) / position.entry_Price) * 100
      : ((position.entry_Price - currentPrice) / position.entry_Price) * 100;

  if (!profitLoss) {
    profitLoss = position.profit_loss - (avoidFees ? 0 : position.totalfees);
  }

  if (!percentChange) {
    percentChange =
      (profitLoss * 100) / (position.entry_Price * position.quantity);
  }

  return { profitLoss, percentChange };
};

export const createOpenPositionTableRows = (
  positions: Position[],
  liveFeedData: SymbolsData | undefined,
  handlePositionClick: (position: Position) => void,
): { rows: TableRow<unknown>[]; columns: TableColumns[] } => {
  const rows: TableRow<unknown>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.symbol') },
    { text: i18next.t('summary_labels.type'), align: 'center' },
    { text: i18next.t('summary_labels.qty'), align: 'center' },
    { text: i18next.t('summary_labels.entry_price'), align: 'right' },
    { text: i18next.t('summary_labels.last_price'), align: 'right' },
    { text: i18next.t('summary_detail.todays_change'), align: 'right' },
    { text: i18next.t('summary_labels.market_value'), align: 'right' },
  ];

  positions.map(position => {
    rowCellsData = [];
    const currentPrice = getCurrentSymbolPrice(
      position.exit_price,
      position.entry_Price,
      liveFeedData,
      position.trading_symbol,
    );
    const isBuy = position.direction === '0';
    const { profitLoss, percentChange } = calculateProfitLossAndPerChange(
      currentPrice,
      position,
      true,
    );
    rowCellsData = [
      {
        content: position.trading_symbol,
        fontWeight: 500,
      },
      {
        content: (
          <MuiChip
            label={isBuy ? 'L' : 'S'}
            color={isBuy ? 'infolight' : 'purplelight'}
            size="singleLetter"
            variant="filled"
            sx={{ ml: 1 }}
          />
        ),
        align: 'center',
      },
      {
        content: position.quantity,
        color: isBuy ? 'info.main' : 'purple.500',
        align: 'center',
      },
      {
        content: position.entry_Price
          ? `${position.currency_symbol}${formatNumber(position.entry_Price)}`
          : '',
        align: 'right',
      },
      {
        content: `${position.currency_symbol}${formatNumber(currentPrice)}`,
        align: 'right',
      },
      {
        content: `${percentChange > 0 ? '+' : ''}${decimalFormat(
          percentChange,
        )}%`,
        textVariant: 'body3',
        align: 'right',
        color: getColorByNum(profitLoss),
      },
      {
        content: `${getArrowDirByNum(profitLoss)} ${
          position.currency_symbol
        }${formatNumber(profitLoss, true)}`,
        align: 'right',
        color: getColorByNum(profitLoss),
        fontWeight: 500,
      },
    ];

    rows.push({
      rowCellsData: rowCellsData,
      rowData: [],
      handleClick: () => {
        handlePositionClick(position);
      },
    });
  });

  return { rows, columns };
};

export const createClosePositionTableRows = (
  positions: Position[],
  handlePositionClick: (position: Position) => void,
): { rows: TableRow<Position>[]; columns: TableColumns[] } => {
  const rows: TableRow<Position>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.symbol') },
    { text: i18next.t('summary_labels.type'), align: 'center' },
    { text: i18next.t('summary_labels.qty'), align: 'center' },
    { text: i18next.t('summary_labels.entry_price'), align: 'right' },
    { text: i18next.t('summary_labels.exit_price'), align: 'right' },
    { text: `${i18next.t('summary_detail.p_or_l')} %`, align: 'right' },
    { text: i18next.t('summary_labels.profit_loss'), align: 'right' },
  ];

  positions.map(position => {
    rowCellsData = [];
    const isBuy = position.direction === '0';
    const { profitLoss, percentChange } = calculateProfitLossAndPerChange(
      0,
      position,
      true,
    );

    rowCellsData = [
      {
        content: position.trading_symbol,
        fontWeight: 500,
      },
      {
        content: (
          <MuiChip
            label={isBuy ? 'L' : 'S'}
            color={isBuy ? 'infolight' : 'purplelight'}
            size="singleLetter"
            variant="filled"
            sx={{ ml: 1 }}
          />
        ),
        align: 'center',
      },
      {
        content: position.quantity,
        color: isBuy ? 'info.main' : 'purple.500',
        align: 'center',
      },
      {
        content: position.entry_Price
          ? `${position.currency_symbol}${formatNumber(position.entry_Price)}`
          : '',
        align: 'right',
      },
      {
        content: `${position.currency_symbol}${formatNumber(
          position.exit_price,
        )}`,
        align: 'right',
      },
      {
        content: `${percentChange > 0 ? '+' : ''}${decimalFormat(
          percentChange,
        )}%`,
        textVariant: 'body3',
        align: 'right',
        color: getColorByNum(profitLoss),
      },
      {
        content: `${getArrowDirByNum(profitLoss)} ${
          position.currency_symbol
        }${formatNumber(profitLoss, true)}`,
        align: 'right',
        color: getColorByNum(profitLoss),
        fontWeight: 500,
      },
    ];

    rows.push({
      rowCellsData,
      rowData: position,
      handleClick: () => {
        handlePositionClick(position);
      },
    });
  });

  return { rows, columns };
};

export const createPositionTableRows = (
  bots: PortfolioBot[],
  ptype: string,
  liveFeedTodaysPosData: { [id: number]: number },
  breadcrumbPush: BreadcrumbPush,
  isDisplayUser: boolean,
  isChargesAndProfit: boolean,
  isNumberFormatting?: boolean,
): { rows: TableRow<PortfolioBot>[]; columns: TableColumns[] } => {
  const decimalPoints = isNumberFormatting ? 2 : 0;

  const rows: TableRow<PortfolioBot>[] = [];
  let rowCellsData: TableRowCellsData[] = [];

  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.duration_days'), key: 'name' },
    {
      text: i18next.t('summary_labels.positions'),
      align: 'center',
      key: 'open_position_count',
    },
    {
      text: i18next.t('summary_labels.initial_capital'),
      align: 'right',
      key: 'initial_capital',
    },
    {
      text: i18next.t('summary_labels.total_profit_loss'),
      align: 'right',
      key: 'bot_info.total_profit_loss',
    },
    {
      text: i18next.t('brokerage_fees'),
      align: 'right',
      hide: !isChargesAndProfit,
      key: 'bot_info.total_fees',
    },
    {
      text: i18next.t('summary_labels.net_profit_loss'),
      align: 'right',
      hide: !isChargesAndProfit,
      key: 'bot_info.net_profit',
    },
    {
      text: i18next.t('summary_labels.value'),
      align: 'right',
      key: 'portfolio_value',
    },
    {
      text: i18next.t('summary_detail.todays_change'),
      align: 'right',
      key: 'todays_change',
    },
  ];

  bots.map(item => {
    rowCellsData = [];
    const netProfitPer = !item.initial_capital
      ? 0
      : (item.bot_info!.net_profit / item.initial_capital) * 100;

    const todaysChange =
      liveFeedTodaysPosData && liveFeedTodaysPosData[item.id]
        ? liveFeedTodaysPosData[item.id]
        : item.todays_change;
    const todaysChangePer =
      item.initial_capital === 0
        ? 0
        : (todaysChange / item.initial_capital) * 100;

    rows.push({
      rowCellsData: [
        {
          content: (
            <>
              <Typography variant="body1" sx={{ pb: 0.5, display: 'flex' }}>
                {item.bot_info && item.bot_info.errors.length > 0 && (
                  <Box sx={{ mr: 0.5, display: 'flex' }}>
                    <WarningAmberIcon color="warning" />
                  </Box>
                )}{' '}
                {item.name}{' '}
                {isDisplayUser &&
                (item.trading_username || item.broker_name) ? (
                  <MuiChip
                    label={item.broker_name || item.trading_username}
                    color={item.is_logged_in ? 'successlight' : 'errorlight'}
                    size="small"
                    variant="filled"
                    sx={{ ml: 1 }}
                  />
                ) : (
                  <></>
                )}
              </Typography>
              {item.instrument && item.instrument.length > 0 && (
                <Typography variant="body2" color="text.secondary">
                  {item.instrument.join(', ')}
                </Typography>
              )}
            </>
          ),
          fontWeight: 500,
          colSpan: 7,
        },
      ],
      rowData: item,
      noBorder: true,
      pb: 1,
      handleClick: () => {
        breadcrumbPush(
          `/portfolio/${ptype}/${item.id}`,
          PAGES.PORTFOLIO,
          `/portfolio?ptype=${ptype}`,
          i18next.t('portfolio.page_title.portfolio'),
        );
      },
      // link: `/portfolio/${ptype}/${item.id}`,
    });

    rowCellsData = [
      {
        content: item.days ? item.days : '',
      },
      {
        content: (
          <MuiChip
            label={`${item.open_position_count} / ${item.position_count}`}
            size="xsmall"
            variant="filled"
            color="greylight"
            sx={{
              fontWeight: 400,
            }}
          />
        ),
        align: 'center',
      },
      {
        content: `${item.currency_symbol}${formatNumber(
          item.initial_capital,
          isNumberFormatting,
          decimalPoints,
        )}`,
        align: 'right',
      },
      {
        content: (
          <>
            {item.currency_symbol}
            {formatNumber(
              item.bot_info!.total_profit_loss,
              isNumberFormatting,
              decimalPoints,
            )}
            <br />({item.bot_info!.total_profit_loss > 0 ? '+' : ''}
            {formatNumber(Number(item.bot_info?.total_profit_loss_per))}%)
          </>
        ),
        color: getColorByNum(item.bot_info!.total_profit_loss),
        align: 'right',
      },
      {
        content: `${item.currency_symbol}${Math.round(
          item.bot_info ? item.bot_info.total_fees : 0,
        )}`,
        color: getFeesColor(),
        align: 'right',
        hide: !isChargesAndProfit,
      },
      {
        content: (
          <>
            {item.currency_symbol}
            {formatNumber(
              item.bot_info!.net_profit,
              isNumberFormatting,
              decimalPoints,
            )}
            <br />({item.bot_info!.net_profit > 0 ? '+' : ''}
            {formatNumber(netProfitPer)}%)
          </>
        ),
        color: getColorByNum(item.bot_info!.net_profit),
        align: 'right',
        hide: !isChargesAndProfit,
      },
      {
        content: `${item.currency_symbol}${formatNumber(
          item.bot_info!.net_profit + item.initial_capital,
          isNumberFormatting,
          decimalPoints,
        )}`,
        color: getColorByNum(item.bot_info!.net_profit),
        align: 'right',
      },
      {
        content: (
          <>
            <span style={{ whiteSpace: 'nowrap' }}>
              {todaysChange === 0 ? '' : getArrowDirByNum(todaysChange)}{' '}
              {item.currency_symbol}
              {formatNumber(todaysChange, isNumberFormatting, decimalPoints)}
            </span>
            <br />({todaysChangePer > 0 ? '+' : ''}
            {formatNumber(todaysChangePer)}%)
          </>
        ),
        textVariant: 'body3',
        align: 'right',
        color: getColorByNum(todaysChange),
        fontWeight: 500,
      },
    ];

    rows.push({
      pt: 0,
      rowCellsData,
      rowData: item,
      handleClick: () => {
        breadcrumbPush(
          `/portfolio/${ptype}/${item.id}`,
          PAGES.PORTFOLIO,
          `/portfolio?ptype=${ptype}`,
          'Portfolio',
        );
      },
      // link: `/portfolio/${ptype}/${item.id}`,
    });
  });

  return { rows, columns };
};
