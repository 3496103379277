import { Box, Typography } from '@mui/material';
import { PageTitleDefaultVariantProps } from '../types';
import AppbarNotification from 'app/components/Layouts/MainLayout/AppbarNotification';
import { Suspense } from 'react';
import useGetProfile from 'services/Menu/useGetProfile';

const PageTitleDefaultVariant = ({
  title,
  note,
  rightElement,
  isProfileshow,
}: PageTitleDefaultVariantProps) => {
  const { data: profile } = useGetProfile();

  return (
    <Box className="p-main-spacing" sx={{ pt: 1, pb: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" flex={1}>
          {title}
        </Typography>
        {rightElement}
        {isProfileshow && (
          <Suspense fallback={<div>Loading..</div>}>
            <AppbarNotification activeNav={'menu'} profile={profile} />
          </Suspense>
        )}
      </Box>
      {note && <Typography variant="body3">{note}</Typography>}
    </Box>
  );
};

export default PageTitleDefaultVariant;
