import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  decimalFormat,
  formatNumber,
  getAnnualReturn,
  getColorByNum,
  getDate,
  getDateWithToday,
  getDuration,
} from 'utils/GenericFunctions';

// Services
import useGetStatistics from 'services/Statistics/useGetStatistics';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const CompareTableRow = ({
  attribute,
  content1,
  content2,
  content1Color,
  content2Color,
}: {
  attribute: string;
  content1: string | JSX.Element;
  content2: string | JSX.Element;
  content1Color?: string;
  content2Color?: string;
}) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body3" fontWeight="500">
          <FormattedMessage id={attribute} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body3"
          {...(content1Color && { color: content1Color })}
        >
          {content1}{' '}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body3"
          {...(content2Color && { color: content2Color })}
        >
          {content2}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export function MarketplaceBotsComparePage() {
  const { id1, id2 } = useParams<{ id1: string; id2: string }>();
  const history = useHistory();

  const isMdUp = useGetMediaQueryUp('md');
  const isShowResult = useIsFeatureFlag([FEATURES.IS_SHOW_RESULT]);

  const baseApiUrl = 'marketplace/bots';
  const { data: statisticsData1, isLoading: isLoading1 } = useGetStatistics(
    `${baseApiUrl}/${id1}`,
  );
  const { data: statisticsData2, isLoading: isLoading2 } = useGetStatistics(
    `${baseApiUrl}/${id2}`,
  );

  const statistics1 =
    statisticsData1 && statisticsData1.statistics
      ? statisticsData1.statistics
      : undefined;
  const statistics2 =
    statisticsData2 && statisticsData2.statistics
      ? statisticsData2.statistics
      : undefined;

  const startDate1 =
    statistics1 && statistics1.start_date
      ? getDateWithToday(statistics1.start_date)
      : undefined;
  const endDate1 =
    statistics1 && statistics1.end_date
      ? getDateWithToday(statistics1.end_date)
      : undefined;

  const startDate2 =
    statistics2 && statistics2.start_date
      ? getDateWithToday(statistics2.start_date)
      : undefined;
  const endDate2 =
    statistics2 && statistics2.end_date
      ? getDateWithToday(statistics2.end_date)
      : undefined;

  const annualReturn1 = statistics1
    ? getAnnualReturn(
        statistics1.start_date,
        statistics1.end_date,
        statistics1.net_profit_percentage,
      )
    : 0;
  const annualReturn2 = statistics2
    ? getAnnualReturn(
        statistics2.start_date,
        statistics2.end_date,
        statistics2.net_profit_percentage,
      )
    : 0;

  return (
    <>
      <Helmet>
        <title>Compare Bots</title>
        <meta name="description" content="Compare Bots Page" />
      </Helmet>

      <CircularLoader open={isLoading1 || isLoading2} />
      <PageHeader
        variant="inactive"
        buttonText={
          <FormattedMessage id="market.page_title.bot_detail.back_btn" />
        }
        // title={`Compare ${
        //   statisticsData1 ? statisticsData1.statistics.name : ''
        // }-${statistics2 ? statistics2.name : ''}`}
        title={`Compare Bots`}
        buttonHandleClick={() => history.goBack()}
        {...(isMdUp && {
          breadcrumb: <Breadcrumbs page={PAGES.MARKETPLACE_DETAIL} data={{}} />,
        })}
      />
      {statistics1 && statistics2 && (
        <>
          <Box
            className="m-main-spacing"
            sx={{ my: 2, ...(!isMdUp && { mx: '0!important' }) }}
          >
            <TableContainer>
              <Table
                sx={{
                  '& td': {
                    fontWeight: 400,
                    wordBreak: 'initial',
                  },
                  '& th,td': {
                    textAlign: 'right',
                    '&:first-child': {
                      // pl: 0,
                      textAlign: 'left!important',
                      backgroundColor: 'grey.200',
                    },
                    ...(isMdUp && { '&:last-child': { pr: 0 } }),
                    // '&:last-child': { pr: 0 },
                  },
                  '& tbody tr': { verticalAlign: 'top' },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ wordBreak: 'initial !important' }}>
                      Attributes
                    </TableCell>
                    <TableCell>{statistics1.name}</TableCell>
                    <TableCell>{statistics2.name}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <CompareTableRow
                    attribute="summary_labels.initial_capital"
                    content1={`${statistics1.currency_symbol}${formatNumber(
                      statistics1.initial_capital,
                    )}`}
                    content2={`${statistics2.currency_symbol}${formatNumber(
                      statistics2.initial_capital,
                    )}`}
                  />
                  <CompareTableRow
                    attribute="summary_labels.candle_interval"
                    content1={statistics1.candle_interval}
                    content2={statistics2.candle_interval}
                  />
                  <CompareTableRow
                    attribute="summary_labels.period"
                    content1={
                      statistics1.start_date && statistics1.end_date
                        ? `${startDate1} ${endDate1 ? `→ ${endDate1}` : ''}`
                        : ''
                    }
                    content2={
                      statistics2.start_date && statistics2.end_date
                        ? `${startDate2} ${endDate2 ? `→ ${endDate2}` : ''}`
                        : ''
                    }
                  />
                  <CompareTableRow
                    attribute="summary_labels.duration"
                    content1={
                      statistics1.start_date && statistics1.end_date
                        ? getDuration(
                            startDate1 === 'Today'
                              ? getDate(new Date(), false)
                              : statistics1.start_date,
                            endDate1 === 'Today'
                              ? getDate(new Date(), false)
                              : statistics1.end_date,
                          )
                        : ''
                    }
                    content2={
                      statistics2.start_date && statistics2.end_date
                        ? getDuration(
                            startDate2 === 'Today'
                              ? getDate(new Date(), false)
                              : statistics2.start_date,
                            endDate2 === 'Today'
                              ? getDate(new Date(), false)
                              : statistics2.end_date,
                          )
                        : ''
                    }
                  />
                  <CompareTableRow
                    attribute="summary_labels.positions"
                    content1={
                      statistics1.total_trads == undefined ? (
                        ''
                      ) : (
                        <>
                          {statistics1.total_trads
                            ? statistics1.total_trads
                            : 0}{' '}
                          (
                          <Typography variant="body3" color={'success.main'}>
                            {statistics1.winrate
                              ? `+${decimalFormat(statistics1.winrate)}%`
                              : `${decimalFormat(0)}%`}
                          </Typography>{' '}
                          |
                          <Typography
                            variant="body3"
                            color={'error.main'}
                            sx={{ ml: 0.5 }}
                          >
                            {statistics1.lossrate
                              ? `-${decimalFormat(statistics1.lossrate)}%`
                              : `${decimalFormat(0)}%`}
                          </Typography>
                          )
                        </>
                      )
                    }
                    content2={
                      statistics2.total_trads == undefined ? (
                        ''
                      ) : (
                        <>
                          {statistics2.total_trads
                            ? statistics2.total_trads
                            : 0}{' '}
                          (
                          <Typography variant="body3" color={'success.main'}>
                            {statistics2.winrate
                              ? `+${decimalFormat(statistics2.winrate)}%`
                              : `${decimalFormat(0)}%`}
                          </Typography>{' '}
                          |
                          <Typography
                            variant="body3"
                            color={'error.main'}
                            sx={{ ml: 0.5 }}
                          >
                            {statistics2.lossrate
                              ? `-${decimalFormat(statistics2.lossrate)}%`
                              : `${decimalFormat(0)}%`}
                          </Typography>
                          )
                        </>
                      )
                    }
                  />
                  {isShowResult && (
                    <>
                      <CompareTableRow
                        attribute="summary_labels.total_profit"
                        content1Color={getColorByNum(statistics1.total_profit)}
                        content2Color={getColorByNum(statistics2.total_profit)}
                        content1={
                          statistics1.total_profit === undefined ? (
                            ''
                          ) : (
                            <>
                              {`${statistics1.currency_symbol}${formatNumber(
                                statistics1.total_profit,
                                false,
                                0,
                              )}`}
                              <Typography variant="body3" sx={{ ml: 0.5 }}>
                                {statistics1.total_profit_percentage
                                  ? `(${
                                      statistics1.total_profit > 0 ? '+' : ''
                                    }${decimalFormat(
                                      statistics1.total_profit_percentage,
                                    )}%)`
                                  : `(${decimalFormat(0)}%)`}
                              </Typography>
                            </>
                          )
                        }
                        content2={
                          statistics2.total_profit === undefined ? (
                            ''
                          ) : (
                            <>
                              {`${statistics2.currency_symbol}${formatNumber(
                                statistics2.total_profit,
                                false,
                                0,
                              )}`}
                              <Typography variant="body3" sx={{ ml: 0.5 }}>
                                {statistics2.total_profit_percentage
                                  ? `(${
                                      statistics2.total_profit > 0 ? '+' : ''
                                    }${decimalFormat(
                                      statistics2.total_profit_percentage,
                                    )}%)`
                                  : `(${decimalFormat(0)}%)`}
                              </Typography>
                            </>
                          )
                        }
                      />
                      <CompareTableRow
                        attribute="brokerage_fees"
                        content1={
                          statistics1.total_fees
                            ? `${statistics1.currency_symbol}${formatNumber(
                                statistics1.total_fees,
                                false,
                                0,
                              )}`
                            : `${statistics1.currency_symbol}${formatNumber(0)}`
                        }
                        content2={
                          statistics2.total_fees
                            ? `${statistics2.currency_symbol}${formatNumber(
                                statistics2.total_fees,
                                false,
                                0,
                              )}`
                            : `${statistics2.currency_symbol}${formatNumber(0)}`
                        }
                      />
                      <CompareTableRow
                        attribute="summary_labels.net_profit"
                        content1Color={getColorByNum(statistics1.net_profit)}
                        content2Color={getColorByNum(statistics2.net_profit)}
                        content1={
                          <>
                            {`${statistics1.currency_symbol}${formatNumber(
                              statistics1.net_profit,
                              false,
                              0,
                            )}`}
                            <Typography variant="body3" sx={{ ml: 0.5 }}>
                              {statistics1.net_profit_percentage
                                ? `(${
                                    statistics1.net_profit > 0 ? '+' : ''
                                  }${decimalFormat(
                                    statistics1.net_profit_percentage,
                                  )}%)`
                                : `(${decimalFormat(0)}%)`}
                            </Typography>
                          </>
                        }
                        content2={
                          <>
                            {`${statistics2.currency_symbol}${formatNumber(
                              statistics2.net_profit,
                              false,
                              0,
                            )}`}
                            <Typography variant="body3" sx={{ ml: 0.5 }}>
                              {statistics2.net_profit_percentage
                                ? `(${
                                    statistics2.net_profit > 0 ? '+' : ''
                                  }${decimalFormat(
                                    statistics2.net_profit_percentage,
                                  )}%)`
                                : `(${decimalFormat(0)}%)`}
                            </Typography>
                          </>
                        }
                      />
                    </>
                  )}
                  <CompareTableRow
                    attribute="summary_labels.annual_return"
                    content1={`${decimalFormat(annualReturn1)}%`}
                    content2={`${decimalFormat(annualReturn2)}%`}
                  />
                  <CompareTableRow
                    attribute="summary_labels.mdd"
                    content1={
                      statistics1.drawdown !== undefined
                        ? `${decimalFormat(statistics1.drawdown)}%`
                        : ''
                    }
                    content2={
                      statistics2.drawdown !== undefined
                        ? `${decimalFormat(statistics2.drawdown)}%`
                        : ''
                    }
                    content1Color={
                      statistics1.drawdown > 0 ? 'error.main' : 'text.primary'
                    }
                    content2Color={
                      statistics2.drawdown > 0 ? 'error.main' : 'text.primary'
                    }
                  />
                  <CompareTableRow
                    attribute="summary_labels.sharp_ratio"
                    content1={`${statistics1.sharp_ratio}`}
                    content2={`${statistics2.sharp_ratio}`}
                  />
                  <CompareTableRow
                    attribute="summary_labels.avg.avg_profit"
                    content1Color={getColorByNum(statistics1.average_profit)}
                    content2Color={getColorByNum(statistics2.average_profit)}
                    content1={
                      statistics1.average_profit_percentage === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics1.currency_symbol}${formatNumber(
                            statistics1.average_profit,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics1.average_profit_percentage
                              ? `(${
                                  statistics1.average_profit > 0 ? '+' : ''
                                }${decimalFormat(
                                  statistics1.average_profit_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                    content2={
                      statistics2.average_profit_percentage === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics2.currency_symbol}${formatNumber(
                            statistics2.average_profit,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics2.average_profit_percentage
                              ? `(${
                                  statistics2.average_profit > 0 ? '+' : ''
                                }${decimalFormat(
                                  statistics2.average_profit_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                  />
                  <CompareTableRow
                    attribute="summary_labels.avg.avg_loss"
                    content1Color={getColorByNum(statistics1.average_loss)}
                    content2Color={getColorByNum(statistics2.average_loss)}
                    content1={
                      statistics1.average_loss === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics1.currency_symbol}${formatNumber(
                            statistics1.average_loss,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics1.average_loss_percentage
                              ? `(${decimalFormat(
                                  statistics1.average_loss_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                    content2={
                      statistics2.average_loss === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics2.currency_symbol}${formatNumber(
                            statistics2.average_loss,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics2.average_loss_percentage
                              ? `(${decimalFormat(
                                  statistics2.average_loss_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                  />

                  <CompareTableRow
                    attribute="summary_labels.max.max_profit"
                    content1Color={getColorByNum(statistics1.max_profit)}
                    content2Color={getColorByNum(statistics2.max_profit)}
                    content1={
                      statistics1.max_profit_percentage === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics1.currency_symbol}${formatNumber(
                            statistics1.max_profit,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics1.max_profit_percentage
                              ? `(${
                                  statistics1.max_profit > 0 ? '+' : ''
                                }${decimalFormat(
                                  statistics1.max_profit_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                    content2={
                      statistics2.max_profit_percentage === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics2.currency_symbol}${formatNumber(
                            statistics2.max_profit,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics2.max_profit_percentage
                              ? `(${
                                  statistics2.max_profit > 0 ? '+' : ''
                                }${decimalFormat(
                                  statistics2.max_profit_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                  />
                  <CompareTableRow
                    attribute="summary_labels.max.max_loss"
                    content1Color={getColorByNum(statistics1.max_loss)}
                    content2Color={getColorByNum(statistics2.max_loss)}
                    content1={
                      statistics1.max_loss === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics1.currency_symbol}${formatNumber(
                            statistics1.max_loss,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics1.max_loss_percentage
                              ? `(${decimalFormat(
                                  statistics1.max_loss_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                    content2={
                      statistics2.max_loss === undefined ? (
                        ''
                      ) : (
                        <>
                          {`${statistics2.currency_symbol}${formatNumber(
                            statistics2.max_loss,
                            false,
                            0,
                          )}`}
                          <Typography variant="body3" sx={{ ml: 0.5 }}>
                            {statistics2.max_loss_percentage
                              ? `(${decimalFormat(
                                  statistics2.max_loss_percentage,
                                )}%)`
                              : `(${decimalFormat(0)}%)`}
                          </Typography>
                        </>
                      )
                    }
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
}
