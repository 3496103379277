import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import PortfolioScripts from 'app/design/speedBot/ComponentItem/Portfolio/PortfolioScripts';
import PageHeader from 'app/design/speedBot/PageHeader';
import StatusMessage from 'app/components/Common/StatusMessage';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

export function BotDetailSymbolsPage() {
  const { bid, sid, title } =
    useParams<{ bid: string; sid: string; title: string }>();
  const history = useHistory();
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);
  const botId = parseInt(bid);
  const { data, isLoading } = useGetBotDetails(botId, subId);
  const scripts = data?.scripts || [];

  return (
    <Fragment>
      <Helmet>
        <title>Bot Symbols</title>
        <meta name="description" content="Bot Symbols Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        title={<FormattedMessage id="bots.page_title.symbols" />}
        buttonText={title}
        buttonHandleClick={() => {
          history.goBack();
        }}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_DETAIL_SYMBOLS}
            data={{ subId, botId, botName: title }}
          />
        }
      />
      {scripts.length > 0 ? (
        <PortfolioScripts scripts={scripts} />
      ) : (
        <StatusMessage
          title={<FormattedMessage id="bots.symbols.status_msg.title" />}
          subtitle={<FormattedMessage id="bots.symbols.status_msg.sub_title" />}
        />
      )}
    </Fragment>
  );
}
