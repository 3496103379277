import { ReactElement } from 'react';
import { Box, Dialog } from '@mui/material';
// import Transition from 'app/design/uiComponents/Transition';
import PageHeader from 'app/design/speedBot/PageHeader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

export default function MuiFullScreenModal({
  open,
  handleClose,
  body,
  footer,
  isNoBackButton,
  isDisableEnforceFocus,
  isDisableEscapeKeyDown,
  handleBackDropClick,
  isDeleteAccountModal,
  width,
}: {
  open: boolean;
  handleClose: (isSuccess, message?: string) => void;
  body?: ReactElement;
  footer?: ReactElement;
  isNoBackButton?: boolean;
  isDisableEnforceFocus?: boolean;
  isDisableEscapeKeyDown?: boolean;
  handleBackDropClick?: () => void;
  isDeleteAccountModal?: boolean;
  width?: string;
}) {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <Dialog
        onBackdropClick={handleBackDropClick}
        // disableEnforceFocus
        // disableEscapeKeyDown
        {...(isDisableEnforceFocus !== false && {
          disableEnforceFocus: true,
        })}
        {...(isDisableEscapeKeyDown !== false && {
          disableEscapeKeyDown: true,
        })}
        {...(!isMdUp && { fullScreen: true })}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        PaperProps={{
          sx: {
            width: {
              md: width || '480px',
              xl: isDeleteAccountModal ? '580px' : width || '407px',
            },
            maxWidth: { md: 'initial' },
          },
        }}
      >
        <Box
          sx={{
            pt: { md: 3 },
            pb: { xs: 3 },
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {!isMdUp && !isNoBackButton && (
            <Box className="text-ellipsis">
              <PageHeader variant="close" buttonHandleClick={handleClose} />
            </Box>
          )}
          <Box sx={{ overflow: 'auto', flex: 1 }}>{body}</Box>
          {footer ? <Box className="m-main-spacing">{footer}</Box> : <></>}
        </Box>
      </Dialog>
    </>
  );
}
