import { MouseEvent, useContext } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import InstrumentString from 'app/design/itemsComponent/InstrumentString';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useTranslation } from 'react-i18next';
import { BotTemplate } from 'types/ApiServicesTypes';
import ShowMoreText from 'react-show-more-text';
import { riskTypes } from '../ComponentItem/Bots/utils';
import { formatNumber } from 'utils/GenericFunctions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { isUserLoggedIn } from 'utils/auth';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
const MAX_TAGS = 3;

export type BotTemplateItemProps = {
  template: BotTemplate;
  highestCount?: number;
};

function BotTemplateItem({ template, highestCount }: BotTemplateItemProps) {
  const { t } = useTranslation();
  const isLoggedIn = isUserLoggedIn();
  const breadcrumb = useBreadcrumbManager();
  const riskType = (
    template.risk_type
      ? riskTypes[template.risk_type]
        ? riskTypes[template.risk_type]
        : template.risk_type
      : ''
  ) as string;
  const isMdUp = useGetMediaQueryUp('md');
  const totalTags = template.tags.length;
  const { isDarkTheme } = useContext(themeContext);
  const { data: siteInfo } = useGetSiteInfo();
  return (
    <Box
      className={location.pathname.startsWith('/home') ? '' : 'p-main-spacing'}
      py={2}
      flex={0.5}
      onClick={() =>
        breadcrumb.push(
          `/templates/bot/${template.id}/${encodeURI(template.name)}`,
          PAGES.TEMPLATES,
          '/templates',
          t('templates.page_title.templates'),
        )
      }
      sx={
        location.pathname.startsWith('/home') && template.image
          ? {
              cursor: 'pointer',
              width: ['70vw', '70vw', '70vw', 'max-content'],
            }
          : location.pathname.startsWith('/home') && template.image == null
          ? {
              cursor: 'pointer',
              maxWidth: '434px',
              width: ['70vw', '70vw', '70vw', '25vw'],
            }
          : { cursor: 'pointer' }
      }
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h6" color="text.primary" fontWeight={500}>
          {location.pathname.startsWith('/home') && !isMdUp
            ? template.name.length > 20
              ? `${template.name.slice(0, 18)}...`
              : template.name
            : template.name}
        </Typography>
        {template.count ? (
          <Tooltip
            title={`More than ${
              siteInfo?.name.toLowerCase().includes('speedbot')
                ? template.count * 10
                : template.count
            } people has used this template.`}
          >
            <Box display="flex" alignItems="center">
              <MuiChip
                icon={
                  highestCount === template.count ? (
                    <LocalFireDepartmentIcon
                      color="action"
                      fontSize="small"
                      sx={{ mx: 0.5 }}
                    />
                  ) : (
                    <></>
                  )
                }
                variant="filled"
                color="greylight"
                label={
                  <>
                    <Typography
                      sx={{ whiteSpace: 'nowrap' }}
                      color={'text.primary'}
                      variant="subtitle2"
                      marginLeft={0.5}
                    >
                      {/* {template.count ? Math.ceil(template.count / 10) * 10 : 5}+ users */}
                      {siteInfo?.name.toLowerCase().includes('speedbot')
                        ? formatNumber(Number(template.count * 10), false, 0)
                        : template.count}
                      + clones
                    </Typography>
                  </>
                }
                size="small"
              />
            </Box>
          </Tooltip>
        ) : (
          <MuiChip
            variant="filled"
            color="successlight"
            label="New"
            size="small"
          />
        )}
      </Box>
      {template.description && (
        <Typography style={{ filter: `invert(${isDarkTheme ? 1 : 0})` }}>
          <ShowMoreText
            lines={2}
            more={
              <span style={{ visibility: 'hidden', height: '0px' }}>Show</span>
            }
            less=""
            className="show-more"
            anchorClass="anchor-show-more"
            expanded={false}
            truncatedEndingComponent={'...'}
          >
            <Typography
              variant={'body2'}
              sx={{ fontWeight: 400, color: 'text.secondary', width: '100px' }}
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: template.description,
              }}
            ></Typography>
          </ShowMoreText>
        </Typography>
      )}
      {template.image && (
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            mt: 1,
            aspectRatio: '16/9',
            maxHeight: '262px',
          }}
        >
          <img
            src={template.image}
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '12px',
            }}
          />
        </Box>
      )}

      <Box mt={2} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="body2" fontWeight={400}>
          Required Capital
          <Typography
            variant="body2"
            component={'span'}
            fontWeight={600}
            ml={0.5}
          >
            {template.currency_symbol}
            {formatNumber(template.initial_capital, true, 0)}
          </Typography>
        </Typography>
        {riskType && (
          <MuiChip
            size="xsmall"
            color={
              riskType === 'High Risk'
                ? 'errorlight'
                : riskType === 'Moderate Risk'
                ? 'infolight'
                : 'successlight'
            }
            label={riskType}
          />
        )}
      </Box>
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="body3" color="text.secondary">
            <InstrumentString instrument={template.symbols} />
          </Typography>
          {template.position_type && (
            <Typography variant="body3" color={'text.secondary'}>
              {' | '}
              {template.position_type}
            </Typography>
          )}
          <Box
            sx={{
              mt: 1,
              gap: 1,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {template.tags
              .slice(0, template.tags.length === 3 ? MAX_TAGS : 2)
              .map(tag => (
                <MuiChip label={tag} key={tag} color="infolight" size="small" />
              ))}
            {totalTags > MAX_TAGS && (
              <Typography variant="caption">{`+ ${
                totalTags - MAX_TAGS + 1
              } more`}</Typography>
            )}
          </Box>
        </Box>
        {isLoggedIn && (
          <MuiButton
            variant="contained"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              if (template.strategy_type !== 'custom') {
                breadcrumb.push(
                  `/template-bot-builder/${template.id}`,
                  PAGES.TEMPLATES,
                  `/templates`,
                  t('templates.page_title.templates'),
                );
                e.stopPropagation();
              }
            }}
            sx={{ alignSelf: 'flex-end' }}
          >
            <FormattedMessage id="templates.detail.clone_bot" />
          </MuiButton>
        )}
      </Box>
    </Box>
  );
}

export default BotTemplateItem;
