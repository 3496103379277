import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Position, PositionMetaDataIgnoreKeys } from 'types/ApiServicesTypes';
import {
  decimalFormat,
  formatNumber,
  getColorByNum,
  getDate,
  getFeesColor,
} from 'utils/GenericFunctions';
import { ItemSeparator } from '../../EntityItem';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const InfoListPosition = ({ data }: { data: Position }) => {
  const netProfit = data ? data.profit_loss - data.totalfees : 0;
  const isPositionMetaData = useIsFeatureFlag([FEATURES.IS_POSITION_METADATA]);

  const metaData = Object.entries(data.metadata ? data.metadata : {}).filter(
    ([key]) =>
      !(key in PositionMetaDataIgnoreKeys || key.startsWith('private_')),
  );
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.qty" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.quantity.toString()}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.entry_price" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.currency_symbol}
          {formatNumber(data.entry_Price)}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.exit_price" />
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          color={getColorByNum(data.profit_loss)}
        >
          {data.exit_price
            ? `${data.currency_symbol}${formatNumber(
                data.exit_price,
              )} (${decimalFormat(data.percentage_change)}%)`
            : '-'}
        </Typography>
      </Box>
      <ItemSeparator variant="1px" />
      <Box display={'flex'} justifyContent={'space-between'} sx={{ py: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.profit" /> /{' '}
          <FormattedMessage id="summary_labels.loss" />
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          color={getColorByNum(data.profit_loss)}
        >
          {data.currency_symbol}
          {formatNumber(data.profit_loss)}
        </Typography>
      </Box>
      <FeatureFlag features={[FEATURES.IS_CHARGES_AND_PROFIT]} fallback={<></>}>
        <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="brokerage_fees" />
          </Typography>
          <Typography variant="body3" fontWeight={500} color={getFeesColor()}>
            {data.exit_price
              ? `${data.currency_symbol}${formatNumber(data.totalfees)}`
              : '-'}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="summary_labels.net_profit" />
          </Typography>
          <Typography
            variant="body3"
            fontWeight={500}
            color={getColorByNum(netProfit)}
          >
            {data.exit_price
              ? `${data.currency_symbol}${formatNumber(netProfit)}`
              : '-'}
          </Typography>
        </Box>
      </FeatureFlag>
      <ItemSeparator variant="1px" />
      <Box display={'flex'} justifyContent={'space-between'} sx={{ py: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.entry_time" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {getDate(data.entry_time, true)}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.exit_time" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.exit_price ? getDate(data.exit_time, true) : '-'}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.duration" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.exit_price ? data.duration : '-'}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.status" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.status}
        </Typography>
      </Box>
      {isPositionMetaData && metaData.length > 0 && (
        <>
          <ItemSeparator variant="1px" />
          <Box mt={2}>
            {metaData.map(([key, value]) => (
              <Box
                key={key}
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                sx={{ pb: 2 }}
                alignItems={'baseline'}
              >
                <Typography
                  variant="body3"
                  color="text.secondary"
                  flexShrink={0}
                >
                  {key}
                </Typography>
                <Typography variant="body3" fontWeight={500}>
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default InfoListPosition;
