import moment from 'moment';
import {
  formatDateString,
  getDateDifference,
  getAmountUnit,
} from 'utils/GenericFunctions';

export const groupByToFormat = {
  year: 'YYYY',
  month: 'MMM',
  days: 'DD',
};

export const calculateXAxisTicks = (dates: Date[], groupBy = 'month') => {
  dates.sort((a, b) => a.getTime() - b.getTime());

  const firstDateMoment = moment(dates[0]);
  const lastDateMoment = moment(dates[dates.length - 1]);

  const ticks: number[] = [];

  let currentDate = firstDateMoment;
  while (currentDate.toDate() <= lastDateMoment.toDate()) {
    ticks.push(currentDate.toDate().getTime());
    currentDate = currentDate.add(1, groupBy as moment.DurationInputArg2);
  }

  return ticks;
};

export const renderXAxisTick = (groupBy: string) => (ticks: number[]) => {
  const chartTick = (tickProps: {
    x: number;
    y: number;
    payload: { value: string | Date };
  }) => {
    const { x, y, payload } = tickProps;
    const isFirstTick = +payload.value === ticks[0];
    const translateX = isFirstTick ? 5 : x;

    const format: string = groupByToFormat[groupBy] as string;
    const tickText = formatDateString(payload.value, format);
    return (
      <g transform={`translate(${translateX},${y - 20})`}>
        <text
          x={5}
          y={0}
          dy={16}
          textAnchor="start"
          fill="#666"
          className={'recharts-cartesian-axis-tick-value'}
        >
          {tickText}
        </text>
      </g>
    );
  };
  return chartTick;
};

export const xAxisTickFormatter =
  (groupBy: string) => (time: number, index: number) => {
    const format: string = groupByToFormat[groupBy] as string;
    const dateString = formatDateString(new Date(time), format);
    return index === 0
      ? dateString.slice(0, Math.ceil(dateString.length / 2))
      : dateString;
  };

export const capitalFormatter = (value: number, amountUnit: string): string => {
  return `${value}${amountUnit}`;
};

export const yAxisTickFormatter =
  (amountUnit: string) =>
  // tickFormatter must have index parameter, so can't remove from here
  //  even though we don't use it.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (value: number, index: number): string => {
    return capitalFormatter(value, amountUnit);
  };

export const getGroupBy = (dates: string[] | Date[]): string => {
  const dtFrom = dates[0];
  const dtTo = dates[dates.length - 1];
  const yearDifference = getDateDifference(dtFrom, dtTo, 'years');
  const monthDifference = getDateDifference(dtFrom, dtTo, 'months');

  if (yearDifference >= 1) {
    return 'year';
  } else if (monthDifference >= 1) {
    return 'month';
  }

  return 'days';
};

export const calculateAmountUnit = (amounts: number[]) => {
  const sum = amounts.reduce((accumulator, amount) => accumulator + amount, 0);
  const avg = sum / amounts.length;
  return getAmountUnit(avg);
};
