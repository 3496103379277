import { useContext } from 'react';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useMutation } from 'react-query';
import { GetBrokerAuthUrl } from 'services/apiServices';
import { GetBrokerAuthUrlResult, Errors } from 'types/ApiServicesTypes';

export default function useGetBrokerAuthUrl() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    GetBrokerAuthUrlResult,
    Errors,
    Pick<{ id: number }, 'id'>
  >('EditBroker', values =>
    GetBrokerAuthUrl(values.id)
      .then(res => {
        if (res && res.success && res.loginUrl)
          window.location.assign(res.loginUrl);
        else if (res && res.error) showAlert!(res.error);
        else showAlert!('Something went wrong. Please try again.');
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
