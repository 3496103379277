import { SCHEDULED_EVENTS } from 'constants/common';
import { getCookie, setCookie } from 'typescript-cookie';
import { ScheduledEvents } from './types';

export const getEventsFromCookies = () => {
  let events: ScheduledEvents[] = [];
  const eventData = getCookie(SCHEDULED_EVENTS);
  if (eventData) events = JSON.parse(eventData) as ScheduledEvents[];
  const dt = new Date();
  events = events.filter(x => new Date(x.expired) >= dt);
  return events;
};

export const addEventToCookies = (eventData: ScheduledEvents) => {
  const events = getEventsFromCookies();
  events.push(eventData);
  setCookie(SCHEDULED_EVENTS, JSON.stringify(events));
  return events;
};

export const removeEventFromCookies = (id: number) => {
  let events = getEventsFromCookies();
  events = events.filter(x => x.id !== id);
  setCookie(SCHEDULED_EVENTS, JSON.stringify(events));
  return events;
};
