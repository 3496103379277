import CircularLoader from 'app/design/uiComponents/CircularLoader';

// Service
import useGetStatistics from 'services/Statistics/useGetStatistics';
import InfoListAdvanceStats from 'app/design/speedBot/ComponentItem/Bots/InfoListAdvanceStats';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { memo } from 'react';

export function InfoAdvanceStats({ baseApiUrl }: { baseApiUrl: string }) {
  const { data: statisticsData, isLoading } = useGetStatistics(baseApiUrl);
  const isFromBacktest = baseApiUrl.indexOf('/backtest') !== -1;
  const isFromDiscover = baseApiUrl.indexOf('/marketplace') !== -1;

  // Only check mdd for backtest statistics and discover
  const isShowDDInAmount =
    useIsFeatureFlag([FEATURES.IS_SHOW_DD_IN_AMOUNT]) &&
    (isFromBacktest || isFromDiscover);

  return (
    <>
      <CircularLoader open={isLoading} />
      {statisticsData && statisticsData.advance_statistics && (
        <InfoListAdvanceStats
          currencySymbol={statisticsData.currency_symbol}
          data={statisticsData.advance_statistics}
          initialCapital={statisticsData.statistics.initial_capital}
          isShowDDInAmount={isShowDDInAmount}
          baseApiUrl={baseApiUrl}
        />
      )}
    </>
  );
}

export default memo(InfoAdvanceStats);
