import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import useGetProfile from 'services/Menu/useGetProfile';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import Invoices from 'app/components/Menu/Invoices';
import ActivePlanInfo from 'app/components/Menu/ActivePlanInfo';
import { useEffect } from 'react';
import { loadScript } from 'utils/loadScript';

export function BillingDetailsPage() {
  const { data: profileDetails, isLoading } = useGetProfile();
  const userPlan =
    profileDetails && profileDetails.user_plan
      ? profileDetails.user_plan
      : null;

  useEffect(() => {
    void loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
      'razorpay_script',
    );

    return () => {
      document.getElementById('razorpay_script')!.remove();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Plan Detail Page</title>
        <meta name="description" content="Plan Detail Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      {userPlan && userPlan.plan && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <PageHeader
              variant="inactive"
              title={<FormattedMessage id="menu.billing_details" />}
              buttonHandleClick="/menu/plan-details"
              buttonText={<FormattedMessage id="menu.plan" />}
              breadcrumb={
                <Breadcrumbs page={PAGES.BILLING_DETAILS} data={{}} />
              }
            />
          </Box>
          <Box className="m-main-spacing" sx={{ pb: 3 }}>
            <ActivePlanInfo userPlan={userPlan} isFromBilling />
          </Box>
          <Invoices />
          {/* <ItemHead
            variant="small"
            title={<FormattedMessage id="menu.billing_history" />}
          />
          <ItemSeparator variant="1px" className="m-main-spacing" />
          <BillingItem
            orderDate={'7 Feb 2022'}
            amount={250}
            fromDate={'07/12/2021'}
            toDate={'06/01/2022'}
            subValue={`(incl. gst) ₹${23}`}
          />
          <ItemSeparator variant="1px" className="m-main-spacing" />
          <BillingItem
            orderDate={'7 Feb 2022'}
            amount={250}
            fromDate={'07/12/2021'}
            toDate={'06/01/2022'}
            subValue={`(incl. gst) ₹${23}`}
          />
          <ItemSeparator variant="1px" className="m-main-spacing" />
          <BillingItem
            orderDate={'7 Feb 2022'}
            amount={250}
            fromDate={'07/12/2021'}
            toDate={'06/01/2022'}
            subValue={`(incl. gst) ₹${23}`}
          /> */}
        </Box>
      )}
    </>
  );
}
