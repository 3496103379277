import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

const useFirebasePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    // // eslint-disable-next-line
    // const analytics =
    //   // eslint-disable-next-line
    //   (window as any).firebase && (window as any).firebase.analytics;
    // if (typeof analytics === 'function') {
    //   const page_path = location.pathname + location.search;
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    //   analytics().setCurrentScreen(page_path);
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    //   analytics().logEvent('page_view', { page_path });
    // }
    const firebaseAnalytics = getAnalytics();
    logEvent(firebaseAnalytics, 'page_view', {
      page_path: location.pathname + location.search,
      screen_name: location.pathname + location.search,
      // screen_name: document.title,
      // page_title: location.pathname + location.search,
    });
  }, [location]);
  return null;
};

export default useFirebasePageTracking;
