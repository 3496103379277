import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'app/components/Context/ToastContext';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import usePostUsersBotAction from 'services/Bots/usePostUsersBotAction';
import { PostUsersBotActionParams } from 'types/ApiServicesTypes';

const BotGenerateDuplicateModal = ({
  open,
  bid,
  handleClose,
  category,
}: {
  open: boolean;
  bid: number;
  handleClose: (isSuccess, message?: string) => void;
  category: string;
}) => {
  const history = useHistory();
  const mutation = usePostUsersBotAction(bid);
  const { showAlert } = useContext(ToastContext);
  const { t } = useTranslation();

  const handleDuplicateClick = (name: string) => {
    const objReq: PostUsersBotActionParams = { name, action: 'duplicate' };
    mutation.mutate(objReq, {
      onSuccess: res => {
        if (res.success) {
          showAlert!(t('bot_duplicate_modal.duplicate_success'), 'success');
          history.push('/bots');
          logFirebaseEventEntry('BOT_DUPLICATE_' + category.toUpperCase(), {
            bot_id: bid,
          });

          handleClose(true);
        }
      },
    });
  };

  const handleCloseModal = () => {
    handleClose(true);
  };

  return (
    <>
      <CircularLoader open={mutation.isLoading} zIndex={1302} />
      <MuiFullScreenModal
        open={open}
        handleClose={handleCloseModal}
        body={
          <Formik
            enableReinitialize
            initialValues={{ botName: '' }}
            validationSchema={Yup.object().shape({
              botName: Yup.string().required(
                t('user_backtesting.valid_msg.req', {
                  name: t('user_bot_startegy.form.bot_name'),
                }),
              ),
            })}
            onSubmit={values => {
              handleDuplicateClick(values.botName);
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleBlur,
              handleChange,
              touched,
            }) => {
              return (
                <>
                  <Box
                    className="m-main-spacing"
                    component="form"
                    id="duplicate-form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'calc(100% - 2.25rem)',
                      pt: 1,
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ mb: 2, flex: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ pb: 4, fontWeight: 500 }}
                      >
                        <FormattedMessage id="buttons.duplicate_bot" />
                      </Typography>
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12} order={1}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.botName && errors.botName)}
                            variant="outlined"
                            sx={{ mb: 3 }}
                          >
                            <InputLabel htmlFor="label-botName">
                              <FormattedMessage id="user_bot_startegy.form.bot_name" />
                            </InputLabel>
                            <OutlinedInput
                              id="label-botName"
                              name="botName"
                              value={values.botName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={t('user_bot_startegy.form.bot_name')}
                            />
                            {touched.botName && errors.botName && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.botName}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              );
            }}
          </Formik>
        }
        footer={
          <>
            <MuiLoadingButton
              fullWidth
              variant="contained"
              sx={{ mb: 2 }}
              type="submit"
              form="duplicate-form"
              loading={mutation.isLoading}
              loadingPosition="start"
            >
              <FormattedMessage id="buttons.duplicate_bot" />
            </MuiLoadingButton>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClose}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </>
        }
      />
    </>
  );
};

export default memo(BotGenerateDuplicateModal);
