import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
  PageTitleWithIconVariantIconProps,
  PageTitleWithIconWithDescriptionVariantProps,
} from '../types';
import { Link as RouterLink } from 'react-router-dom';

const PageTitleIconButton = ({
  iconProps,
}: {
  iconProps: PageTitleWithIconVariantIconProps;
}) => {
  return (
    <IconButton
      size="small"
      {...(iconProps.handleClick && { onClick: iconProps.handleClick })}
      {...(iconProps.link && { component: RouterLink, to: iconProps.link })}
      sx={{
        backgroundColor: theme =>
          !iconProps.selected
            ? 'transparent'
            : theme.palette.grey[300] + ' !important',
        borderRadius: 1,
        padding: 0.5,
      }}
    >
      {iconProps.icon}
    </IconButton>
  );
};

const PageTitleWithIconWithDescriptionVariant = ({
  title,
  iconProps,
  description,
  descriptionSubItem,
}: PageTitleWithIconWithDescriptionVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ pb: 2 }}>
      <Box sx={{ pt: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ flex: '1' }}>
          {title}
        </Typography>
        {iconProps.tooltipText ? (
          <Tooltip title={iconProps.tooltipText}>
            <Box>
              <PageTitleIconButton iconProps={iconProps} />
            </Box>
          </Tooltip>
        ) : (
          <PageTitleIconButton iconProps={iconProps} />
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant="body2"
          color="text.secondary"
          fontWeight={400}
          sx={{ pt: 1, flex: 1 }}
        >
          {description}
        </Typography>
        {descriptionSubItem}
      </Box>
    </Box>
  );
};

export default PageTitleWithIconWithDescriptionVariant;
