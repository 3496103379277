import { styled } from '@mui/material/styles';
import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

const MuiStyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  // justifyContent: 'space-between',
  '&.MuiButton-sizeSmall': {
    fontSize: `calc(${theme.spacing(1.75)} - 1px)`,
    lineHeight: theme.spacing(2.75),
    letterSpacing: '0.46px',
  },
  '&.MuiButton-sizeMedium, &.MuiButton-sizeXlarge, &.MuiButton-sizeXxlarge': {
    fontSize: theme.spacing(1.75),
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  '&:not(.MuiButton-outlined)': {
    '&.MuiButton-sizeMedium': {
      padding: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
    },
    '&.MuiButton-sizeXlarge': {
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    },
    '&.MuiButton-sizeXxlarge': {
      padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
    },
  },
  // Remove 1 px from all sides because of border added 1 ox in contained variant
  '&.MuiButton-outlined': {
    '&.MuiButton-sizeMedium': {
      padding: `calc(${theme.spacing(0.75)} - 1px) calc(${theme.spacing(
        2,
      )} - 1px)`,
    },
    '&.MuiButton-sizeXlarge': {
      padding: `calc(${theme.spacing(1.5)} - 1px) calc(${theme.spacing(
        2,
      )} - 1px)`,
    },
    '&.MuiButton-sizeXxlarge': {
      padding: `calc(${theme.spacing(2.5)} - 1px) calc(${theme.spacing(
        2,
      )} - 1px)`,
    },
  },
}));

type CastedForwardRefButtonType = <C extends React.ElementType>(
  props: LoadingButtonProps<C, { component?: C }>,
  ref?: React.Ref<HTMLButtonElement>,
) => React.ReactElement;
const CastedForwardRefButtonFnc: CastedForwardRefButtonType = (props, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiStyledLoadingButton ref={ref} {...rest}>
      {children}
    </MuiStyledLoadingButton>
  );
};
const MuiLoadingButton = React.forwardRef(
  CastedForwardRefButtonFnc,
) as CastedForwardRefButtonType;

export default MuiLoadingButton;
