import { memo, useEffect } from 'react';
import { Box } from '@mui/material';

// Services
import { useGetPortfolio } from 'services/Portfolio/useGetPortfolioActivePaper';
import ActivePaperTabItem from './ActivePaperTabItem';
// import { FeatureFlag } from '../Common/FeatureFlag';
// import { FEATURES } from 'types/Feature';
import useGetPortfolioBotsV2 from 'services/Portfolio/useGetPortfolioBotV2';
import {
  // GetPortfolioBotResult,
  PortfolioBotsData,
} from 'types/ApiServicesTypes';
import { BotsPage } from 'app/pages/Bots/BotsPage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      <Box sx={{ display: value === index ? 'show' : 'hide' }}>{children}</Box>
      {/* {value === index && <>{children}</>} */}
    </div>
  );
}

const ActivePaperTabs = ({
  selectedTab,
  handleChangeTab,
  ptype,
}: {
  selectedTab: number;
  handleChangeTab: (newValue: number) => void;
  ptype: string;
}): JSX.Element => {
  const { data, isLoading } = useGetPortfolio();
  const { data: manualData, isLoading: manualLoading } =
    useGetPortfolioBotsV2(ptype);
  const transformdata = manualData as PortfolioBotsData;
  useEffect(() => {
    if (
      !ptype &&
      data &&
      (!data.active || (data && data.active && data.active.bots.length === 0))
    ) {
      handleChangeTab(2);
    }
  }, [ptype, data, handleChangeTab]);
  // const activeTab = 'all';

  return (
    <>
      <TabPanel value={selectedTab} index={0}>
        <ActivePaperTabItem
          type="ACTIVE"
          isLoading={isLoading}
          portfolioData={data ? data.active : undefined}
          symbols={data && data.active ? data && data.active.symbols : []}
          openPositions={
            data && data.active ? data && data.active.positionData : []
          }
          handleChangeTab={handleChangeTab}
          selectedTab={selectedTab === 0 ? 'active' : 'paper'}
        />
      </TabPanel>
      {/* <FeatureFlag features={[FEATURES.IS_PAPER_TRADING]} fallback={<></>}> */}
      <TabPanel value={selectedTab} index={1}>
        <ActivePaperTabItem
          type="PAPER"
          isLoading={isLoading}
          portfolioData={data ? data.paper : undefined}
          symbols={data && data.paper ? data.paper.symbols : []}
          openPositions={data && data.paper ? data.paper.positionData : []}
          handleChangeTab={handleChangeTab}
          selectedTab={selectedTab === 0 ? 'active' : 'paper'}
        />
      </TabPanel>
      {/* </FeatureFlag> */}
      <TabPanel value={selectedTab} index={2}>
        {/* <ActivePaperTabItem
          type="MYBOTS"
          isLoading={isLoading}
          portfolioData={data ? data.active : undefined}
          symbols={data && data.active ? data && data.active.symbols : []}
          openPositions={
            data && data.active ? data && data.active.positionData : []
          }
          handleChangeTab={handleChangeTab}
          selectedTab={'mybot'}
        /> */}
        <BotsPage selectedTab={selectedTab} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <ActivePaperTabItem
          portfolioData={transformdata ? transformdata : undefined}
          type="MANUAL"
          isLoading={isLoading && manualLoading}
          // manualData={manualData?.bots}
          symbols={data && data.active ? data && data.active.symbols : []}
          openPositions={
            data && data.active ? data && data.active.positionData : []
          }
          handleChangeTab={handleChangeTab}
          selectedTab={'manual'}
        />
      </TabPanel>

      {/* <TabPanel value={selectedTab} index={3}>
        <BotsList activeTab={activeTab} />
      </TabPanel> */}
    </>
  );
};

export default memo(ActivePaperTabs);
