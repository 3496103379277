import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { Formik } from 'formik';
import { useState } from 'react';
import { useDoSingOut } from 'services/Auth';
import { useGetAccountDelete } from 'services/Menu/useGetAccountDelete';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

type DeleteAccountModalProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
};

function DeleteAccountModal({ open, handleClose }: DeleteAccountModalProps) {
  const theme = useTheme();
  const [varificationText, setVarificationText] = useState('');
  const varificationCode = 'I UNDERSTAND';
  const isMdUp = useGetMediaQueryUp('md');
  const { data: siteInfo } = useGetSiteInfo();
  const { doSignOut } = useDoSingOut();
  const handleAccountDeleteRequest = () => {
    handleClose(true);
    doSignOut();
  };
  const accountDeleteAction = useGetAccountDelete(handleAccountDeleteRequest);
  return (
    <MuiModal
      maxWidth="md"
      isFullScreen={!isMdUp}
      isAutoHeight={isMdUp}
      open={open}
      handleClose={handleClose}
      body={
        <Formik
          enableReinitialize
          initialValues={{ inputText: varificationText }}
          onSubmit={() => accountDeleteAction.mutate()}
        >
          {({ values, errors, handleSubmit, handleBlur, touched }) => {
            return (
              <>
                <Box
                  className="m-main-spacing"
                  component="form"
                  id="varification-form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 2.25rem)',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ py: 3, flex: 1 }}>
                    <Typography
                      variant="h6"
                      fontSize={'22px'}
                      fontWeight={600}
                      mb={2}
                    >
                      Deleting Your {siteInfo?.name} Account
                    </Typography>
                    <Alert severity="error" sx={{ mb: 1 }} icon={isMdUp}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, textAlign: 'justify' }}
                      >
                        <Typography
                          component={'span'}
                          fontWeight={600}
                          variant="body1"
                          mr={1}
                        >
                          Important Notice:
                        </Typography>
                        When you choose to delete your account, please be aware
                        that your data will be retained for 30 days. If you sign
                        up again within this period, your account and associated
                        data will be fully restored. However, if you do not log
                        in during this timeframe, your account and all data will
                        be permanently deleted, and recovery will not be
                        possible. We recommend logging in within the 30-day
                        window if you wish to retain your data. If you have any
                        questions, feel free to contact our support team.
                      </Typography>
                    </Alert>
                    <Grid container columnSpacing={2} mt={2}>
                      <Grid item xs={12} order={1}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              mb: { xs: 0.5, md: 0 },
                              mr: { xs: 0, md: 1 },
                            }}
                          >
                            Please type the following to confirm:
                          </Typography>
                          <code
                            style={{
                              backgroundColor: theme.palette.grey[300],
                              borderRadius: '8px',
                            }}
                          >
                            <Typography
                              width={'fit-content'}
                              variant="body1"
                              fontSize={'18px'}
                              p={0.5}
                            >
                              {varificationCode}
                            </Typography>
                          </code>
                        </Box>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.inputText && errors.inputText)}
                          variant="outlined"
                          sx={{ mt: 1 }}
                        >
                          <OutlinedInput
                            id="label-amount"
                            name="inputText"
                            type="text"
                            value={values.inputText}
                            onBlur={handleBlur}
                            autoFocus={true}
                            onChange={e => setVarificationText(e.target.value)}
                            inputProps={{ min: 1, step: 500 }}
                            style={{
                              outlineStyle: 'solid',
                              outlineWidth: 4,
                              outlineColor: theme.palette.info.main,
                            }}
                          />
                          {touched.inputText && errors.inputText && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.inputText}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      }
      footer={
        <Box
          className="p-main-spacing"
          sx={{ display: 'flex', flexDirection: 'row', pb: 3 }}
        >
          <MuiButton
            variant="contained"
            color="secondary"
            type="button"
            sx={{ mr: 1 }}
            fullWidth
            onClick={() => handleClose(false)}
          >
            <FormattedMessage id="buttons.cancel" />
          </MuiButton>
          <MuiButton
            fullWidth
            variant="contained"
            color="error"
            type="submit"
            form="varification-form"
            disabled={varificationCode !== varificationText ? true : false}
          >
            <FormattedMessage id="buttons.delete_acc_conf" />
          </MuiButton>
        </Box>
      }
    />
  );
}

export default DeleteAccountModal;
