import { memo } from 'react';
import { PageHeaderVariantsProps } from './types';
import {
  PageTitleBackVariant,
  PageTitleDefaultVariant,
  PageTitleCommonVariant,
  PageTitleSmallVariant,
  PageTitleSmallWithIconVariant,
  PageTitleWithIconVariant,
  PageTitleWithButtonVariant,
  PageTitleWithChipWithSubtitleVariant,
  PageTitleWithChipWithValueVariant,
  PageTitleWithDescriptionVariant,
  PageTitleWithSubtitleVariant,
  PageTitleCloseVariant,
  PageTitleTabsWithBackVariant,
  PageTitlePortfolioVariant,
  PageTitleBotsVariant,
  PageTitleMarketVariant,
  PageTitlePaperTestingVariant,
  PageTitleInactiveVariant,
  PageTitleWithChipWithSubtitleWithBackVariant,
} from './PageHeaderVariants';
import PageTitleWithIconWithBackVariant from './PageHeaderVariants/PageTitleWithIconWithBackVariant';
import PageTitleWithIconWithDescriptionVariant from './PageHeaderVariants/PageTitleWithIconWithDescriptionVariant';
import PageTitleWithIconWithDescriptionWithBackVariant from './PageHeaderVariants/PageTitleWithIconWithDescriptionWithBackVariant';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const PageHeader = (props: PageHeaderVariantsProps) => {
  const { variant } = props;
  const isSideBar = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);

  switch (variant) {
    case 'default':
      return <PageTitleDefaultVariant title={props.title} />;
    case 'common':
      return <PageTitleCommonVariant title={props.title} />;
    case 'small':
      return <PageTitleSmallVariant title={props.title} />;
    case 'smallWithIcon':
      return (
        <PageTitleSmallWithIconVariant
          title={props.title}
          iconProps={props.iconProps}
        />
      );
    case 'withIcon':
      return (
        <PageTitleWithIconVariant
          title={props.title}
          iconProps={props.iconProps}
          filterChips={props.filterChips}
          titleElipsis={props.titleElipsis}
        />
      );
    case 'withIconWithDescription':
      return (
        <PageTitleWithIconWithDescriptionVariant
          title={props.title}
          iconProps={props.iconProps}
          description={props.description}
        />
      );
    case 'withButton':
      return (
        <PageTitleWithButtonVariant
          title={props.title}
          buttonText={props.buttonText}
          buttonAction={props.buttonAction}
          filterChips={props.filterChips}
        />
      );
    case 'withChipWithSubtitle':
      return (
        <PageTitleWithChipWithSubtitleVariant
          title={props.title}
          subtitle={props.subtitle}
          chipProps={props.chipProps}
        />
      );
    case 'withChipWithValue':
      return (
        <PageTitleWithChipWithValueVariant
          title={props.title}
          value={props.value}
          chipProps={props.chipProps}
        />
      );
    case 'withDescription':
      return (
        <PageTitleWithDescriptionVariant
          title={props.title}
          description={props.description}
        />
      );
    case 'withSubtitle':
      return (
        <PageTitleWithSubtitleVariant
          title={props.title}
          subtitle={props.subtitle}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
          showMdUp={props.showMdUp && props.showMdUp}
        />
      );
    case 'close':
      return (
        <PageTitleCloseVariant buttonHandleClick={props.buttonHandleClick} />
      );
    case 'back':
      return (
        <PageTitleBackVariant
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
        />
      );
    case 'tabsWithBack':
      return (
        <PageTitleTabsWithBackVariant
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          tabs={props.tabs}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
        />
      );
    case 'portfolio':
      return (
        <PageTitlePortfolioVariant
          title={props.title}
          tabs={props.tabs}
          sideBarView={isSideBar}
        />
      );
    case 'bots':
      return (
        <PageTitleBotsVariant
          title={props.title}
          filterChips={props.filterChips}
        />
      );
    case 'market':
      return (
        <PageTitleMarketVariant
          title={props.title}
          marketIconProps={props.marketIconProps}
          rightElement={props.rightElement}
          sideBarView={isSideBar}
          isMenu={props.isMenu && props.isMenu}
        />
      );
    case 'paperTesting':
      return (
        <PageTitlePaperTestingVariant
          title={props.title}
          value={props.value}
          chipProps={props.chipProps}
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
        />
      );
    case 'inactive':
      return (
        <PageTitleInactiveVariant
          title={props.title}
          note={props.note}
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
          rightElement={props.rightElement}
        />
      );
    case 'withChipWithSubtitleWithBack':
      return (
        <PageTitleWithChipWithSubtitleWithBackVariant
          title={props.title}
          subtitle={props.subtitle}
          chipProps={props.chipProps}
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          hideBackButton={props.hideBackButton}
        />
      );
    case 'withIconWithBack':
      return (
        <PageTitleWithIconWithBackVariant
          title={props.title}
          iconProps={props.iconProps}
          subButton={props.subButton}
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
          secondButton={props.secondButton as JSX.Element}
        />
      );
    case 'withIconWithDescriptionWithBack':
      return (
        <PageTitleWithIconWithDescriptionWithBackVariant
          title={props.title}
          iconProps={props.iconProps}
          buttonText={props.buttonText}
          buttonHandleClick={props.buttonHandleClick}
          breadcrumbLinks={props.breadcrumbLinks}
          breadcrumb={props.breadcrumb}
          description={props.description}
          descriptionSubItem={props.descriptionSubItem}
        />
      );
    default:
      return <></>;
  }
};

export default memo(PageHeader);
