import { Redirect } from 'react-router-dom';

// project imports
import { GuardProps } from 'types/ComponentTypes';
import { isUserLoggedIn } from 'utils/auth';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  if (!isUserLoggedIn()) {
    const refPath = window.location.pathname + window.location.search;

    return <Redirect to={`/signin?ref=${refPath}`} />;
  }
  return children;
};

export default AuthGuard;
