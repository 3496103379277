import { useMutation } from 'react-query';
import { DownloadBotExcel } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { ToastContext } from 'app/components/Context/ToastContext';

export default function useDownloadBotExcel() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    boolean,
    Errors,
    {
      botId: number;
      fileName: string;
    }
  >('GetBotPositions', async params => {
    return await DownloadBotExcel(params.botId)
      .then(res => {
        saveAs(
          new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' }),
          `${params.fileName}.xlsx`,
        );
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      });
  });

  return mutation;
}
