import React from 'react';
import { useQuery } from 'react-query';
import { GetAllBots } from 'services/apiServices';
import { Errors, GetBotCardDetailsResult } from 'types/ApiServicesTypes';
import { debounce } from 'lodash';

const WAIT_INTERVAL = 300;

export default function useGetAllBots(
  page = 1,
  search?: string,
  type?: string,
) {
  const [currentPage, setCurrentPage] = React.useState(page);

  const objQuery = useQuery<GetBotCardDetailsResult, Errors>(
    ['GetAllBots', currentPage, type],
    () => GetAllBots('bots', 'GET', currentPage, search, type),
  );
  if (objQuery.data && objQuery.data.data && objQuery.data.data.result) {
    objQuery.data.data.result = objQuery.data.data.result.map(item => ({
      ...item,
      symbols: item.symbols.map(item => item.split('_SPACE').join('')),
    }));
  }
  const { refetch } = objQuery;

  const debouncedRefetch = React.useMemo(() => {
    return debounce(refetch, WAIT_INTERVAL);
  }, [refetch]);

  // When `search` or `type` changes, reset the page to 1 and refetch
  React.useEffect(() => {
    setCurrentPage(1);
    void debouncedRefetch();
  }, [search, type, debouncedRefetch]);

  // If the page changes, refetch the data
  React.useEffect(() => {
    void refetch();
  }, [currentPage, refetch]);

  return {
    ...objQuery,
    currentPage,
    setCurrentPage,
  };
}
