import { Box, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { decodeUrlData, encodeUrlData } from 'utils/GenericFunctions';
import { GetPortfolioBotDetailScripts } from 'types/ApiServicesTypes';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import BoxGrey from 'app/design/uiComponents/BoxGrey';
import BotStopAction from '../Bots/BotDetail/BotStopAction';
import { useIsFeatureFlag } from '../Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

type PortfolioBotActionsProps = {
  id: string;
  botId: number;
  ptype: string;
  slave?: boolean;
  botName: string;
  positionCount: number;
  orderCount: number;
  scripts: GetPortfolioBotDetailScripts[];
};

const PortfolioBotActions = ({
  id,
  botId,
  ptype,
  botName,
  positionCount,
  orderCount,
  scripts,
  slave,
}: PortfolioBotActionsProps): JSX.Element => {
  const { t } = useTranslation();

  const breadcrumb = useBreadcrumbManager();

  const isLogs = useIsFeatureFlag([FEATURES.IS_LOGS]) && !slave;
  return (
    <>
      <Box
        className="p-main-spacing"
        sx={{
          py: { xs: 2, md: 4 },
        }}
      >
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid
            item
            xs={6}
            md={isLogs ? 3 : 4}
            display="flex"
            justifyContent="end"
          >
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{
                justifyContent: 'space-between',
                height: '100%',
                textAlign: 'left',
              }}
              onClick={() => {
                if (positionCount > 0) {
                  breadcrumb.push(
                    `/portfolio/${ptype}/${id}/positions/${encodeUrlData(
                      botName,
                    )}/${botId}`,
                    PAGES.PORTFOLIO_DETAIL,
                    `/portfolio/${ptype}/${id}`,
                    decodeUrlData(botName),
                  );
                }
              }}
              endIcon={<ChevronRightIcon />}
            >
              {positionCount} <FormattedMessage id="buttons.position" />
            </MuiButton>
          </Grid>
          <Grid item xs={6} md={isLogs ? 3 : 4}>
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{
                justifyContent: 'space-between',
                height: '100%',
                textAlign: 'left',
              }}
              onClick={() => {
                if (orderCount > 0) {
                  breadcrumb.push(
                    `/portfolio/${ptype}/${id}/orders/${botName}/${botId}`,
                    PAGES.PORTFOLIO_DETAIL,
                    `/portfolio/${ptype}/${id}`,
                    decodeUrlData(botName),
                  );
                }
              }}
              endIcon={<ChevronRightIcon />}
            >
              {orderCount} <FormattedMessage id="buttons.order" />
            </MuiButton>
          </Grid>
          <Grid
            item
            xs={12}
            md={isLogs ? 3 : 4}
            display="flex"
            justifyContent="end"
          >
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{ justifyContent: 'space-between' }}
              onClick={() =>
                breadcrumb.push(
                  `/portfolio/${ptype}/${id}/symbols/${botName}`,
                  PAGES.PORTFOLIO_DETAIL,
                  `/portfolio/${ptype}/${id}`,
                  decodeUrlData(botName),
                )
              }
              endIcon={<ChevronRightIcon />}
            >
              {scripts.length} <FormattedMessage id="buttons.symbol" />
            </MuiButton>
          </Grid>
          {ptype !== 'manual' ? (
            <>
              {isLogs ? (
                <Grid item xs={12} md={3} display="flex" justifyContent="end">
                  <MuiButton
                    color="secondary"
                    size="xlarge"
                    variant="contained"
                    fullWidth
                    sx={{ justifyContent: 'space-between' }}
                    onClick={() =>
                      breadcrumb.push(
                        `/bots/live/logs/${id}`,
                        PAGES.PORTFOLIO_DETAIL,
                        `/portfolio/${ptype}/${id}`,
                        decodeUrlData(botName),
                      )
                    }
                    endIcon={<ChevronRightIcon />}
                  >
                    <FormattedMessage id="buttons.bot_log" />
                  </MuiButton>
                </Grid>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Grid item xs={12} md={3} display="flex" justifyContent="end">
              <MuiButton
                color="secondary"
                size="xlarge"
                variant="contained"
                fullWidth
                sx={{ justifyContent: 'space-between' }}
                onClick={() =>
                  breadcrumb.push(
                    `/portfolio/${ptype}/${id}/signals/${botName}/${botId}`,
                    PAGES.PORTFOLIO_DETAIL,
                    `/portfolio/${ptype}/${id}`,
                    decodeUrlData(botName),
                  )
                }
                endIcon={<ChevronRightIcon />}
              >
                <FormattedMessage id="bot_signals.title" />
              </MuiButton>
            </Grid>
          )}
        </Grid>
        {/* {showSignals && botDetails && data && (
          <Collapse in={showSignals}>
            <SignalListLayout
              exchanges={botDetails.exchanges}
              data={data}
              page={page}
              totalPages={data && data.length ? data[0].total_pages : 0}
              handleChangePagination={handleChangePagination}
              handleSelectSignal={() => null}
            />
          </Collapse>
        )} */}
        <BoxGrey sx={{ my: 2 }}>
          <MuiButton
            size="xxlarge"
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<ChevronRightIcon />}
            sx={{ justifyContent: 'space-between' }}
            onClick={() =>
              breadcrumb.push(
                `/portfolio/${ptype}/${id}/statistics/${botName}`,
                PAGES.PORTFOLIO_DETAIL,
                `/portfolio/${ptype}/${id}`,
                decodeUrlData(botName),
              )
            }
          >
            <FormattedMessage id="statistics" />
          </MuiButton>
          <Divider sx={{ borderColor: 'grey.300' }} />
          <MuiButton
            size="xxlarge"
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<ChevronRightIcon />}
            sx={{ justifyContent: 'space-between' }}
            onClick={() =>
              breadcrumb.push(
                `/bots/${botId}/details/${id}/${t(
                  'portfolio.page_title.portfolio',
                )}`,
                PAGES.PORTFOLIO_DETAIL,
                `/portfolio/${ptype}/${id}`,
                decodeUrlData(botName),
              )
            }
          >
            <FormattedMessage id="buttons.more_info" />
          </MuiButton>
        </BoxGrey>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <BotStopAction
            search={''}
            bid={botId.toString()}
            sid={id}
            isRestart={false}
          />
        </Box>
      </Box>
    </>
  );
};
export default PortfolioBotActions;
