import { useState, memo } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import { ItemHead, ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FieldData } from '../../Builder/Strategy/fields/types';
import StrategyBuilder from '../../Builder/Strategy/StrategyBuilder';
import { StrategyMode } from '../../Builder/Strategy/utils/strategy-data';
import { PositionDirectionData } from 'app/components/Bots/StrategyBuilder/util';
import CodeIcon from '@mui/icons-material/Code';

// const ItemMenu = ({
//   item,
//   handleItemClick,
// }: {
//   item: string | ReactElement;
//   handleItemClick: () => void;
// }) => {
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const open = Boolean(anchorEl);
//   const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const handleClick = () => {
//     handleClose();
//     handleItemClick();
//   };
//   return (
//     <div>
//       <IconButton
//         aria-label="more"
//         id={`long-button`}
//         aria-controls={open ? `long-menu` : undefined}
//         aria-expanded={open ? 'true' : undefined}
//         aria-haspopup="true"
//         onClick={handleIconClick}
//       >
//         <MoreVertIcon />
//       </IconButton>
//       <Menu
//         id={`long-menu`}
//         MenuListProps={{ 'aria-labelledby': `long-button` }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         elevation={0}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//       >
//         <MenuItem onClick={handleClick}>{item}</MenuItem>
//       </Menu>
//     </div>
//   );
// };

export const PositionCondition = ({
  direction,
  mode,
  conditions,
}: {
  direction: string;
  mode: string;
  conditions: FieldData[];
}) => {
  return (
    <Box className="m-main-spacing">
      <Box sx={{ py: 2 }}>
        <Typography variant="body3" fontWeight="500">
          <FormattedMessage id={`user_bot_startegy.${direction}`} />
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <StrategyBuilder
          inputConditions={conditions}
          isValid={true}
          mode={mode}
        />
      </Box>
    </Box>
  );
};

const PositionDirectionConditions = ({
  direction,
  longEntryconditions,
  longExitconditions,
  shortEntryconditions,
  shortExitconditions,
}: {
  direction: string;
  longEntryconditions: FieldData[];
  longExitconditions: FieldData[];
  shortEntryconditions: FieldData[];
  shortExitconditions: FieldData[];
}) => {
  const [mode, setMode] = useState<string>(StrategyMode.VIEW);

  const handleChangeMode = () => {
    if (mode === StrategyMode.VIEW) setMode(StrategyMode.TEXT);
    else setMode(StrategyMode.VIEW);
  };

  const directionLabel = PositionDirectionData.filter(
    x => x.value === direction,
  )[0]?.label;
  return (
    <Box>
      <ItemHead
        variant="mediumWithIcon"
        title={
          <>
            <FormattedMessage id="user_bot_startegy.entry_exit" /> (
            {directionLabel})
          </>
        }
        icon={
          <IconButton
            onClick={handleChangeMode}
            size="small"
            sx={{
              backgroundColor: theme =>
                mode === StrategyMode.TEXT
                  ? 'transparent'
                  : theme.palette.grey[300] + ' !important',
              borderRadius: 1,
            }}
          >
            <CodeIcon />
          </IconButton>
        }
      />
      <Box className="m-main-spacing">
        <ItemSeparator variant="2px" />
      </Box>

      {(direction === 'long' || direction === 'both') && (
        <PositionCondition
          direction="long_entry"
          mode={mode}
          conditions={longEntryconditions}
        />
      )}
      {(direction === 'long' || direction === 'both') && (
        <PositionCondition
          direction="long_exit"
          mode={mode}
          conditions={longExitconditions}
        />
      )}
      {(direction === 'short' || direction === 'both') && (
        <PositionCondition
          direction="short_entry"
          mode={mode}
          conditions={shortEntryconditions}
        />
      )}
      {(direction === 'short' || direction === 'both') && (
        <PositionCondition
          direction="short_exit"
          mode={mode}
          conditions={shortExitconditions}
        />
      )}
    </Box>
  );
};

export default memo(PositionDirectionConditions);
