import { MarketHeadItemProps as CategoryHeadItemProps } from './types';
import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';

const CategoryHeadItem = ({
  isSeeAll,
  category,
  fullWidth,
  handleClick,
}: CategoryHeadItemProps) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{
        py: 2,
        display: { md: 'flex' },
        flexDirection: { md: 'column' },
        alignItems: { md: fullWidth ? 'initial' : 'flex-start' },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ display: { xs: 'flex', md: fullWidth ? 'flex' : 'contents' } }}
      >
        <Typography
          variant="h6"
          fontWeight={500}
          color="text.primary"
          sx={{
            flex: 1,
            order: { md: 1 },
            cursor: isSeeAll ? 'pointer' : 'default',
          }}
          {...(isSeeAll && {
            onClick: handleClick,
          })}
        >
          {category.category_name}
        </Typography>
        {isSeeAll && (
          <MuiButton
            variant="contained"
            size="small"
            color="secondary"
            sx={{ order: { md: 3 }, mt: { md: fullWidth ? 0 : 4 } }}
            {...(isSeeAll && {
              onClick: handleClick,
            })}
          >
            <FormattedMessage id="buttons.see_all" />
          </MuiButton>
        )}
      </Box>
      {category.description && (
        <Typography
          variant="body3"
          sx={{
            mt: { xs: 1, md: 2 },
            order: { md: 2 },
            maxWidth: fullWidth ? 'lg' : '100%',
          }}
          color="text.secondary"
        >
          {category.description}
        </Typography>
      )}
    </Box>
  );
};

export default CategoryHeadItem;
