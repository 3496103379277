import { Box } from '@mui/material';
import StatusMessage from 'app/components/Common/StatusMessage';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useGetTickData } from 'app/hooks/useGetTickData';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetBotTodaysPositionsQueryResult,
  Position,
} from 'types/ApiServicesTypes';
import BotSignalTodaysClosePositionsTable from './BotSignalTodaysClosePositionsTable';
import BotSignalTodaysOpenPositions from './BotSignalTodaysOpenPositions';
import BotSignalTodaysOpenPositionsTable from './BotSignalTodaysOpenPositionsTable';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  handleTouchMoveHandle,
  handleTouchStartHandle,
} from 'app/design/layouts/swipeUtil';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import AddIcon from '@mui/icons-material/Add';
import BotGenerateSignalModal from './BotGenerateSignalModal';
import BotSignalTodaysChange from './BotSignalTodaysChange';
import { convertPositionToSignal } from './util';
import { getQueryParam } from 'utils/GenericFunctions';

let eventDir: string | null = null;

const BotSignalTodaysPositions = ({
  botId,
  data,
  isLoading,
  handleChangeTab,
  handleExitPositions,
  isLoadingExit,
  exchanges,
}: {
  botId: number;
  data: GetBotTodaysPositionsQueryResult;
  isLoading: boolean;
  handleChangeTab?: (newValue: number) => void;
  handleExitPositions: (positionIds: number[]) => void;
  isLoadingExit: boolean;
  exchanges: string[];
}) => {
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const { openPositions, closePositions, symbols } = data;

  const liveFeedData = useGetTickData(symbols);

  const [selectedItems, setSelectedItems] = useState<number[] | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleSetSelectedItems = useCallback(
    (id: number) => {
      if (!selectedItems) {
        setSelectedItems([id]);
        return;
      }

      const items = selectedItems ? [...selectedItems] : [];
      const index = items.findIndex(x => x === id);
      if (index >= 0) {
        items.splice(index, 1);
      } else {
        items.push(id);
      }
      setSelectedItems(items);
    },
    [selectedItems],
  );

  const handlePositionClick = useCallback(
    (position: Position) => {
      handleSetSelectedItems(position.id);
    },
    [handleSetSelectedItems],
  );

  const handleClickSelectAndCancel = useCallback(
    (action: 'select' | 'cancel') => {
      if (action === 'select') setSelectedItems([]);
      else setSelectedItems(null);
    },
    [],
  );

  const handleClickSelectAll = useCallback(() => {
    if (!openPositions.length) return;

    if (selectedItems && openPositions.length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      const selectedItemData = openPositions.map(x => x.id);
      setSelectedItems(selectedItemData);
    }
  }, [openPositions, selectedItems]);

  const handleclickAddPosition = () => {
    setOpenModal(true);
  };

  const handleClickExit = () => {
    if (!selectedItems || selectedItems.length === 0) return;
    handleExitPositions(selectedItems);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    handleTouchStartHandle(e);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const eventDirRes = handleTouchMoveHandle(e);
    if (eventDirRes) eventDir = eventDirRes;
  };
  const handleTouchEnd = () => {
    if (!isMdUp && eventDir === 'right' && handleChangeTab) {
      handleChangeTab(1);
      eventDir = '';
    }
  };

  const selectedFirstPosition =
    openPositions &&
    openPositions.length &&
    selectedItems &&
    selectedItems.length
      ? openPositions.filter(x => x.id === selectedItems[0])[0]
      : null;

  const isDebugMode = getQueryParam('debug', 'false') === 'true';

  return (
    <Box
      // sx={{ py: 1, height: '100%' }}
      sx={{ py: 1 }}
      onTouchStart={(touchStartEvent: React.TouchEvent<HTMLElement>) =>
        handleTouchStart(touchStartEvent)
      }
      onTouchMove={(touchMoveEvent: React.TouchEvent<HTMLElement>) =>
        handleTouchMove(touchMoveEvent)
      }
      onTouchEnd={() => handleTouchEnd()}
    >
      <BotSignalTodaysChange data={data} liveFeedData={liveFeedData} />

      {openPositions.length > 0 && (
        <Box className="p-main-spacing" sx={{ mb: 1 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ my: 1 }}
            gap={1}
          >
            {!isMdUp && (
              <Box>
                {!selectedItems ? (
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickSelectAndCancel('select')}
                  >
                    <FormattedMessage id="buttons.select" />
                  </MuiButton>
                ) : (
                  <>
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={handleClickSelectAll}
                      sx={{ mr: 1 }}
                    >
                      <FormattedMessage id="buttons.select_all" />
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      onClick={() => handleClickSelectAndCancel('cancel')}
                    >
                      <FormattedMessage id="buttons.cancel" />
                    </MuiButton>
                  </>
                )}
              </Box>
            )}
            {selectedItems && selectedItems.length > 0 && (
              <Box
                display="flex"
                justifyContent="end"
                gap={1}
                sx={{ ml: 'auto' }}
              >
                {isMdUp && selectedItems.length === 1 && (
                  <MuiButton
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleclickAddPosition}
                  >
                    <FormattedMessage id="buttons.add_position" />
                  </MuiButton>
                )}
                <MuiLoadingButton
                  variant="contained"
                  endIcon={<LogoutIcon />}
                  onClick={handleClickExit}
                  loading={isLoadingExit}
                >
                  <FormattedMessage id="buttons.exit" />
                </MuiLoadingButton>
              </Box>
            )}
          </Box>
          {!isMdUp && selectedItems && selectedItems.length === 1 && (
            <Box>
              <MuiButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleclickAddPosition}
                fullWidth
              >
                <FormattedMessage id="buttons.add_position" />
              </MuiButton>
            </Box>
          )}
        </Box>
      )}
      <Box>
        {!isLoading &&
        (openPositions.length > 0 || closePositions.length > 0) ? (
          <>
            {isMdUp ? (
              <BotSignalTodaysOpenPositionsTable
                positions={openPositions}
                liveFeedData={liveFeedData}
                handlePositionClick={handlePositionClick}
                handleClickSelectPosition={handlePositionClick}
                handleClickSelectAll={handleClickSelectAll}
                selectedItems={selectedItems}
                isDebugMode={isDebugMode}
              />
            ) : (
              <Box>
                <BotSignalTodaysOpenPositions
                  positions={openPositions}
                  liveFeedData={liveFeedData}
                  handlePositionClick={handlePositionClick}
                  isTopBorder={true}
                  selectedItems={selectedItems}
                />
              </Box>
            )}
            {closePositions.length > 0 && (
              <>
                <ItemHead
                  variant="small"
                  title={t('todays_position.title.close')}
                  color={'text.secondary'}
                />
                {isMdUp ? (
                  <BotSignalTodaysClosePositionsTable
                    positions={closePositions}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    handlePositionClick={position => {
                      //code
                    }}
                    isDebugMode={isDebugMode}
                  />
                ) : (
                  <BotSignalTodaysOpenPositions
                    positions={closePositions}
                    liveFeedData={liveFeedData}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    handlePositionClick={position => {
                      //code
                    }}
                    isTopBorder={true}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <StatusMessage
            title={t('todays_position.no_pos.title')}
            subtitle={t('todays_position.no_pos.sub_title')}
          />
        )}
      </Box>

      <BotGenerateSignalModal
        isModify={false}
        exchanges={exchanges}
        open={openModal}
        handleClose={handleCloseModal}
        botId={botId}
        signal={
          selectedFirstPosition
            ? convertPositionToSignal(selectedFirstPosition)
            : null
        }
        isDebugMode={isDebugMode}
      />
    </Box>
  );
};

export default BotSignalTodaysPositions;
