interface PureIframeProps {
  url: string;
}

const PureIframe = ({ url }: PureIframeProps) => {
  const uniqueUrl = `${url}/?timestamp=${new Date().getTime()}`;
  return (
    <div style={{ height: '80vh', position: 'relative' }}>
      <iframe
        key={uniqueUrl}
        src={uniqueUrl}
        width="100%"
        height="90%"
      ></iframe>
    </div>
  );
};

export default PureIframe;
