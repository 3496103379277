export enum SOCKET_NOTIFICATION_MESSAGE_TYPES {
  ORDER = 1,
}

export type BaseNotificationMessage = {
  msg_type: SOCKET_NOTIFICATION_MESSAGE_TYPES;
};

export type OrderNotificationMessage = BaseNotificationMessage & {
  direction: string;
  id: number;
  instrument_id: string;
  last_fill_time: string;
  order_date: string;
  price: number;
  quantity: number;
  status: string;
  sub_id: string;
  sub_type: string;
  tag: string;
  trading_symbol: string;
  type: string;
  value: number;
};

export type NotificationMessage = OrderNotificationMessage;

export type NotificationCallback = (messages: NotificationMessage[]) => void;

export type State = {
  // registredMessageTypes: SOCKET_NOTIFICATION_MESSAGE_TYPES[];
  newMessages: NotificationMessage[];
  registredComponents: {
    [componentId: string]: {
      messageTypes: SOCKET_NOTIFICATION_MESSAGE_TYPES[];
      callback: NotificationCallback;
    };
  };
};
