import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { PutCancelBacktestQueue } from 'services/apiServices';
import { Errors, PutCancelBacktestQueueResult } from 'types/ApiServicesTypes';

export default function usePutCancelBacktestQueue() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    PutCancelBacktestQueueResult,
    Errors,
    Pick<{ id: number }, 'id'>
  >('PutCancelBacktestQueue', values =>
    PutCancelBacktestQueue(values.id)
      .then(res => {
        if (!res || res.status_code !== 200 || res.error)
          showAlert!(
            res.error ? res.error : 'Something went wrong. Please try again.',
          );
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
