import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Divider, Grid } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiButton from 'app/design/uiComponents/MuiButton';
import InfoAdvanceStats from '../Bots/Backtesting/InfoAdvanceStats';
import InfoTradeStats from '../Bots/Backtesting/InfoTradeStats';
import { GetPortfolioBotDetailScripts } from 'types/ApiServicesTypes';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import useGetStatistics from 'services/Statistics/useGetStatistics';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { FEATURES } from 'types/Feature';
import { FeatureFlag } from '../Common/FeatureFlag';

type BacktestingBotActionsProps = {
  id: string;
  subId?: string;
  baseApiUrl: string;
  botName: string;
  botScripts: GetPortfolioBotDetailScripts[];
  positionCount: number;
  orderCount: number;
  ordersOnClick?: () => void;
  positionsOnClick?: () => void;
  symbolsOnClick?: () => void;
  profitLossOnClick?: () => void;
};

type CollapsableButtonProps = {
  text: string | React.ReactElement;
  open: boolean;
  setOpen: (open: boolean) => void;
  linkTo?: {
    pathname: string;
    state: {
      subtitle: string;
      baseApiUrl: string;
    };
  };
  children?: React.ReactChild | React.ReactChild[];
};
const CollapsableButton = (props: CollapsableButtonProps) => {
  const { text, open, setOpen, linkTo, children } = props;
  const isMdUp = useGetMediaQueryUp('md');

  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <>
      <MuiButton
        color="secondary"
        size="xxlarge"
        variant="contained"
        fullWidth
        sx={{
          justifyContent: { xs: 'space-between', md: 'start' },
          borderRadius: 0,
        }}
        {...(!isMdUp && {
          component: props => <RouterLink {...props} />,
          to: linkTo,
          endIcon: <ChevronRightIcon />,
        })}
        {...(isMdUp && {
          onClick: handleToggle,
          startIcon: open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />,
        })}
      >
        {text}
      </MuiButton>
      <Collapse in={open}>
        <Box sx={{ backgroundColor: 'grey.100' }}>
          {children}
          <Box
            sx={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={handleToggle}
          >
            <KeyboardArrowUp />
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

const BacktestingBotActions = ({
  id,
  subId,
  baseApiUrl,
  botName,
  botScripts,
  positionCount,
  orderCount,
  ordersOnClick,
  positionsOnClick,
  symbolsOnClick,
  profitLossOnClick,
}: BacktestingBotActionsProps): JSX.Element => {
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const [openAdvanceStats, setOpenAdvanceStats] = useState<boolean>(false);
  const [openTradeStats, setOpenTradeStats] = useState<boolean>(false);

  const handleOnClickTradeStats = useCallback(
    (val: boolean) => {
      setOpenTradeStats(val);
    },
    [setOpenTradeStats],
  );

  const handleOnClickAdvanceStats = useCallback(
    (val: boolean) => {
      setOpenAdvanceStats(val);
    },
    [setOpenAdvanceStats],
  );

  const { data: statisticsData } = useGetStatistics(baseApiUrl);

  return (
    <>
      <Box
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid item xs={6} md={4} display="flex" justifyContent="end">
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{ justifyContent: 'space-between' }}
              {...(positionCount &&
                positionsOnClick && { onClick: positionsOnClick })}
              endIcon={<ChevronRightIcon />}
            >
              {positionCount} <FormattedMessage id="buttons.position" />
            </MuiButton>
          </Grid>
          <Grid item xs={6} md={4}>
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{ justifyContent: 'space-between' }}
              {...(orderCount && ordersOnClick && { onClick: ordersOnClick })}
              endIcon={<ChevronRightIcon />}
            >
              {orderCount} <FormattedMessage id="buttons.order" />
            </MuiButton>
          </Grid>
          <Grid item xs={12} md={4} display="flex" justifyContent="end">
            <MuiButton
              color="secondary"
              size="xlarge"
              variant="contained"
              fullWidth
              sx={{ justifyContent: 'space-between' }}
              {...(botScripts.length &&
                symbolsOnClick && { onClick: symbolsOnClick })}
              endIcon={<ChevronRightIcon />}
            >
              {botScripts.length} <FormattedMessage id="buttons.symbol" />
            </MuiButton>
          </Grid>
          {profitLossOnClick && (
            <FeatureFlag
              features={[FEATURES.IS_PROFITLOSS_REPORT_BY_DATE]}
              fallback={<></>}
            >
              <Grid item xs={12} md={4} display="flex" justifyContent="end">
                <MuiButton
                  color="secondary"
                  size="xlarge"
                  variant="contained"
                  fullWidth
                  sx={{ justifyContent: 'space-between' }}
                  {...(botScripts.length &&
                    profitLossOnClick && { onClick: profitLossOnClick })}
                  endIcon={<ChevronRightIcon />}
                >
                  <FormattedMessage id="buttons.profit_and_loss_summary" />
                </MuiButton>
              </Grid>
            </FeatureFlag>
          )}
        </Grid>

        <Box
          sx={{
            mt: 1,
            // py: 1,
            // backgroundColor: 'grey.200',
            borderRadius: 0.5,
            overflow: 'hidden',
          }}
        >
          <CollapsableButton
            linkTo={{
              pathname: `/bots/${id}/backtest/${
                subId ? subId : '0'
              }/tradestats/${botName}`,
              state: { subtitle: t('statistics'), baseApiUrl: baseApiUrl },
            }}
            text={<FormattedMessage id="buttons.trade_stats" />}
            open={openTradeStats}
            setOpen={handleOnClickTradeStats}
          >
            <>{isMdUp && <InfoTradeStats baseApiUrl={baseApiUrl} />}</>
          </CollapsableButton>
          {statisticsData && statisticsData.advance_statistics && (
            <>
              <Divider sx={{ borderColor: 'grey.300' }} />
              <CollapsableButton
                linkTo={{
                  pathname: `/bots/${id}/backtest/${
                    subId ? subId : '0'
                  }/advancestats/${botName}`,
                  state: { subtitle: t('statistics'), baseApiUrl: baseApiUrl },
                }}
                text={<FormattedMessage id="buttons.adv_stats" />}
                open={openAdvanceStats}
                setOpen={handleOnClickAdvanceStats}
              >
                <>{isMdUp && <InfoAdvanceStats baseApiUrl={baseApiUrl} />}</>
              </CollapsableButton>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default BacktestingBotActions;
