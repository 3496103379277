import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type CapitalChangeModalProps = {
  initialCapital: number;
  open: boolean;
  handleClose: (success: boolean, capital: number) => void;
};

function CapitalChangeModal({
  open,
  handleClose,
  initialCapital,
}: CapitalChangeModalProps) {
  const { t } = useTranslation();
  return (
    <MuiFullScreenModal
      open={open}
      handleClose={() => handleClose(false, initialCapital)}
      body={
        <Formik
          enableReinitialize
          initialValues={{ initialCapital }}
          validationSchema={Yup.object().shape({
            initialCapital: Yup.number()
              .min(
                1,
                t('common.must_be_greater', {
                  key: t('summary_labels.capital'),
                  value: '1',
                }),
              )
              .required(
                t('menu.required', {
                  type: t('summary_labels.capital'),
                }),
              ),
          })}
          onSubmit={values => {
            handleClose(true, values.initialCapital);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
          }) => {
            return (
              <>
                <Box
                  className="m-main-spacing"
                  component="form"
                  id="initial-capital-form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 2.25rem)',
                    pt: 1,
                    position: 'relative',
                  }}
                >
                  <Box sx={{ mb: 2, flex: 1 }}>
                    <Typography variant="body1" sx={{ pb: 4, fontWeight: 500 }}>
                      <FormattedMessage id="summary_labels.form.calculator_disclaimer" />
                    </Typography>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} order={1}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.initialCapital && errors.initialCapital,
                          )}
                          variant="outlined"
                          sx={{ mb: 3 }}
                        >
                          <InputLabel htmlFor="label-initial-capital">
                            <FormattedMessage id="summary_labels.capital" />
                          </InputLabel>
                          <OutlinedInput
                            autoFocus={true}
                            id="label-initial-capital"
                            name="initialCapital"
                            type="number"
                            value={values.initialCapital}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={t('summary_labels.capital')}
                          />
                          {touched.initialCapital && errors.initialCapital && (
                            <FormHelperText error sx={{ mx: 0 }}>
                              {errors.initialCapital}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      }
      footer={
        <>
          <MuiButton
            fullWidth
            variant="contained"
            sx={{ mb: 2 }}
            type="submit"
            form="initial-capital-form"
          >
            <FormattedMessage id="summary_labels.change" />
          </MuiButton>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClose(false, initialCapital)}
          >
            <FormattedMessage id="buttons.cancel" />
          </MuiButton>
        </>
      }
    />
  );
}

export default CapitalChangeModal;
