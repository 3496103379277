import { useState } from 'react';
import { Typography, Box, Link, Checkbox, Divider } from '@mui/material';
import { getLiveTerms } from './util';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useTranslation } from 'react-i18next';

export default function BotDeployTermConditions({
  botName,
  setStepNumber,
  onclose,
}: {
  botName: string;
  setStepNumber: (val: number) => void;
  onclose: () => void;
}) {
  const { t } = useTranslation();
  const [agreeTemConditions, setAgreeTemConditions] = useState<boolean>(false);
  const { data: stiteInfo } = useGetSiteInfo();

  const liveTerms = getLiveTerms(stiteInfo ? stiteInfo.name : '');

  return (
    <>
      <Box
        className="m-main-spacing"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box flex={1}>
          <Typography variant="body1" sx={{ pb: 1, fontWeight: 400 }}>
            <FormattedMessage id="common.are_you_sure" />
          </Typography>
          <Typography
            variant="body1"
            sx={{ pb: 1, display: 'flex', fontWeight: 600 }}
            component="span"
          >
            <FormattedMessage id="buttons.deploy" />
            <Typography
              variant="body1"
              sx={{ pl: 0.5, fontWeight: 400 }}
              component="span"
            >
              <FormattedMessage id="common.with" />
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ pb: 4 }}>
            {botName}?
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontWeight: 400 }}
            color="text.secondary"
          >
            <ul style={{ marginTop: 0, marginBottom: 24, paddingLeft: 24 }}>
              {liveTerms && liveTerms.map((term, i) => <li key={i}>{term}</li>)}
            </ul>
          </Typography>
        </Box>
        <Box>
          <Box>
            <Box
              sx={{
                borderRadius: 0.5,
                backgroundColor: 'grey.100',
                mb: 4,
                cursor: 'pointer',
              }}
              display="flex"
              onClick={() => setAgreeTemConditions(!agreeTemConditions)}
            >
              <Box sx={{ p: 1.5 }}>
                <Checkbox checked={agreeTemConditions} />
              </Box>
              <Box display="flex" sx={{ py: 1 }}>
                <Divider
                  orientation="vertical"
                  sx={{ borderColor: 'grey.300' }}
                />
              </Box>
              <Box sx={{ py: 1.5, px: 2 }} flex={1}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={400}
                  component="div"
                >
                  {stiteInfo && stiteInfo.name
                    ? t('bot_deploy_modal.agree')
                    : t('bot_deploy_modal.agree_to', {
                        seteName: stiteInfo ? stiteInfo.name : '',
                      })}
                  <br />
                  <Link
                    href={
                      stiteInfo && stiteInfo.term_link
                        ? stiteInfo.term_link
                        : '#'
                    }
                    {...(stiteInfo &&
                      stiteInfo.term_link && { target: '_blank' })}
                    fontWeight={500}
                    underline="none"
                  >
                    <FormattedMessage id="bot_deploy_modal.terms_title" />
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <MuiButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setStepNumber(2)}
              sx={{ mb: 2 }}
              disabled={!agreeTemConditions}
            >
              <FormattedMessage id="buttons.continue" />
            </MuiButton>
            <MuiButton
              variant="contained"
              color="secondary"
              fullWidth
              onClick={onclose}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
