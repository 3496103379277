import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { MobileSignup } from 'services/apiServices';
import { Errors, MobileSignupResult } from 'types/ApiServicesTypes';

export default function useMobileSignup() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    MobileSignupResult,
    Errors,
    Pick<
      {
        username: string;
        email: string;
        country_code: string;
        phone: string;
        password: string;
        referral: string;
      },
      'username' | 'email' | 'country_code' | 'phone' | 'password' | 'referral'
    >
  >('MobileSignup', values =>
    MobileSignup(
      values.username,
      values.email,
      values.country_code,
      values.phone,
      values.password,
      values.referral,
    )
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
