import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { getQueryParams, replaceAllChars } from 'utils/GenericFunctions';
import useAuthBroker from 'services/Menu/useAuthBroker';
export function BrokerAuthPage() {
  const mutation = useAuthBroker();
  const { uuid } = useParams<{ uuid: string }>();

  useEffect(() => {
    const {
      request_token,
      userid: userId,
      key: apiKey,
      auth_token,
      feed_token,
      refresh_token,
      auth_code,
      state,
      code,
      RequestToken,
      apisession,
      requestToken,
    } = getQueryParams([
      'request_token',
      'userid',
      'key',
      'auth_token',
      'feed_token',
      'refresh_token',
      'auth_code',
      'state',
      'code',
      'RequestToken',
      'apisession',
      'requestToken',
    ]);

    mutation.mutate({
      uuid,
      request_token:
        request_token !== undefined && state !== undefined
          ? replaceAllChars(request_token, ' ', '+')
          : request_token,
      userId,
      apiKey,
      auth_token,
      feed_token,
      refresh_token,
      auth_code,
      state,
      code,
      RequestToken,
      apisession,
      requestToken,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Broker Auth</title>
        <meta name="description" content="Broker Auth Page" />
      </Helmet>

      <CircularLoader open={true} />
    </>
  );
}
