import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { VersionType } from './types';
import { useTheme } from '@mui/material/styles';

// export function utcToLocalUsingMoment(utcDate: string) {
//   const utcMoment = moment.utc(utcDate);

//   const localMoment = utcMoment.local();
//   return localMoment;
// }
function CodeStrategyVersionControlCard({
  versions,
  selectedVersionId,
  handleClose,
}: {
  versions: VersionType;
  selectedVersionId: string;
  handleClose?: () => void;
}) {
  const iframe = document.getElementById('iframe') as HTMLIFrameElement;
  const theme = useTheme();
  const history = useHistory();
  const handleClick = (version: string) => {
    const urlParams = new URLSearchParams();
    urlParams.set('versionid', `${version}`);
    history.replace({
      search: urlParams.toString(),
    });
    iframe && iframe?.contentWindow?.location.reload();
    if (handleClose) handleClose();
  };

  return (
    // <NavLink to={`${utcToLocalUsingMoment(time).format('YYYY-MM-DD')}`}>
    <>
      {versions &&
        Object.entries(versions).map((version, index) => (
          <Box
            key={index}
            sx={{
              borderLeft:
                selectedVersionId === version[0]
                  ? `4px solid ${theme.component.versionControlCard.borderColor}`
                  : index === 0 && !selectedVersionId
                  ? `4px solid ${theme.component.versionControlCard.borderColor}`
                  : '4px solid transparent',
              backgroundColor:
                selectedVersionId === version[0]
                  ? theme.component.versionControlCard.backgroundColor
                  : index === 0 && !selectedVersionId
                  ? theme.component.versionControlCard.backgroundColor
                  : 'transparent',
              transition: 'background-color 0.3s',
              ':hover': {
                backgroundColor:
                  theme.component.versionControlCard.backgroundColor,
              },
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
              // display: 'flex',
              // flexDirection: 'column',
              // justifyContent: 'space-between',
              p: 3,
            }}
            onClick={() => {
              handleClick(version[0]);
            }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              textAlign={'center'}
            >
              {moment.utc(version[1]).local().format('MMM DD, YYYY, h:mm a')}
            </Typography>
          </Box>
        ))}

      {/* // </NavLink> */}
    </>
  );
}

export default memo(CodeStrategyVersionControlCard);
