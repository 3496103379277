import { SmallVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const SmallVariant = (props: SmallVariantProps) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Typography variant="body2" color={props.color}>
        {props.title}
      </Typography>
    </Box>
  );
};

export default SmallVariant;
