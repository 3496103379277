import React from 'react';
import { IconButton, useTheme } from '@mui/material';

import { FieldData } from '../types';
import { FieldKeyToField } from '../fieldsData';
import { SelectField } from '../SelectField';
import { cloneDeep } from 'lodash';
import useClasses from '../../utils/useClasses';
import { v4 } from 'uuid';
import CreateIcon from '@mui/icons-material/Create';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { StrategyMode } from '../../utils/strategy-data';

// Style const

interface Props {
  fieldData: FieldData;
  onRemove: () => void;
  onEditDone?: (fieldData: FieldData) => void;
  getPossibleValues?: (conditionString: string) => string[];
  mode: string;
  onEdit: () => void;
  validateMathDescFindErrorIndex?: (conditions: FieldData[]) => {
    status: boolean;
    mathErrorIndex: number;
  };
  validateMathCondition?: (condition: FieldData) => boolean;
  isValid?: {
    status: boolean;
    errorIndex: number;
  };
  fieldIndex: number;
  isMathValid?: {
    status: boolean;
    mathErrorIndex: number;
  };
}

const defaultFieldData = {
  key: '',
  type: '',
  params: {},
};

type EditMode = {
  [key: string]: boolean[];
};

export function MathSingleOp(props: Props) {
  const [editPeriod, setEditPeriod] = React.useState<boolean>(false);
  const theme = useTheme();
  const styles = () => ({
    container: {
      height: '44px',
      display: 'flex',
      alignItems: 'center',
    },
    nameContainer: {
      background: theme.palette.grey[100],
      height: '100%',
      padding: '4px 12px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      margin: '0 3px',
    },
    name: {
      color: theme.palette.common.black,
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '0.15px',
      lineHeight: '20.02px',
      marginRight: '8px',
      width: 'max-content',
    },
    brackets: {
      color: theme.palette.grey[800],
      fontWeight: 400,
      fontSize: '32px',
      letterSpacing: '0.15px',
      lineHeight: '38.73px',
    },
    bracketRight: {
      marginRight: '12px',
    },
    icon: {
      width: '4px',
      height: '16px',
      color: theme.palette.grey[900],
      marginRight: '6px',
    },
    fieldContainer: {
      display: 'flex',
      alignItems: 'stretch',
      position: 'relative',
    },
    addFieldIcon: {
      display: 'none',
      position: 'absolute',
      top: '-28px',
      padding: 0,
      color: theme.palette.grey[600],
      left: '-5px',
    },
    seperatar: {
      display: 'flex',
      height: '44px',
      justifyContent: 'center',
      width: '16px',
      backgroundColor: theme.palette.grey[100],
      borderRadius: '8px',
      position: 'relative',
      border: `1px dashed ${theme.palette.grey[500]}`,
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      '&:hover .addFieldIcon': {
        display: 'block !important',
      },
    },
    invalid: {
      border: `1px dashed ${theme.palette.error.main} !important`,
    },
  });
  const classes = useClasses(styles);
  const {
    fieldData,
    onRemove,
    onEditDone,
    getPossibleValues,
    mode,
    validateMathDescFindErrorIndex,
    validateMathCondition,
    isValid,
    fieldIndex,
    isMathValid,
  } = props;
  const param1 = fieldData.params.param1 as FieldData[];
  fieldData.params['param1'] = param1 && param1.length > 0 ? param1 : [];
  const [editMode, setEditMode] = React.useState<EditMode>({
    param1: new Array(fieldData.params.param1.length).fill(false) as boolean[],
  });
  const [possibleValues, setPossibleValues] = React.useState<string[]>([]);

  const { key } = fieldData;
  const name = FieldKeyToField()[key].name;

  const onDone = (fd: FieldData, paramKey: string, index: number) => {
    fieldData.params[paramKey][index] = fd;
    editMode[paramKey][index] = false;
    setEditMode(cloneDeep(editMode));
    onEditDone && onEditDone(fieldData);
  };

  const onCancel = (paramKey: string, index: number) => {
    const params = fieldData.params[paramKey] as FieldData[];
    if (!params[index].key) {
      params.splice(index, 1);
      editMode[paramKey].splice(index, 1);
    } else {
      editMode[paramKey][index] = false;
    }
    setEditMode(cloneDeep(editMode));
  };

  const onEditIconClick = (paramKey: string, index: number) => {
    const params = fieldData.params[paramKey] as FieldData[];
    const conditionString = createConditionString(params.slice(0, index));
    const newPossibleValues = getPossibleValues
      ? getPossibleValues(conditionString)
      : [];
    editMode[paramKey][index] = true;
    setEditMode(cloneDeep(editMode));
    setPossibleValues(newPossibleValues);
  };

  const createParamsString = (params: FieldData[]) => {
    let keys = '';
    params.forEach((param: FieldData) => (keys += param.key));
    return keys;
  };

  const createConditionString = (params1: FieldData[]) => {
    let keys = `${fieldData.key}(`;
    if (params1) {
      keys += createParamsString(params1);
    }
    return keys;
  };

  const onAddClicked = (paramKey: string, index: number) => {
    const params = fieldData.params[paramKey] as FieldData[];
    const conditionString = createConditionString(params.slice(0, index));
    const newPossibleValues = getPossibleValues
      ? getPossibleValues(conditionString)
      : [];
    params.splice(index, 0, cloneDeep(defaultFieldData));
    editMode[paramKey].splice(index, 0, true);
    fieldData.params[paramKey] = params;
    setEditMode(cloneDeep(editMode));
    setPossibleValues(newPossibleValues);
    //   onEditDone && onEditDone(fieldData);
  };

  const onFieldRemoveClicked = (paramKey: string, index: number) => {
    const params = fieldData.params[paramKey] as FieldData[];
    params.splice(index, 1);
    editMode[paramKey].splice(index, 1);
    setEditMode(cloneDeep(editMode));
    onEditDone && onEditDone(fieldData);
  };

  const onEditParam = () => {
    onEditDone && onEditDone(fieldData);
  };

  const renderFieldData = (paramKey: string) => {
    const params = fieldData.params[paramKey] as FieldData[];
    let validateMathDesCondition = { status: false, mathErrorIndex: -1 };
    if (params.length > 0 && validateMathDescFindErrorIndex) {
      for (const value of params) {
        const key = value.key;
        validateMathDesCondition = key
          ? validateMathDescFindErrorIndex(params)
          : validateMathDesCondition;
      }
    }
    const retData: JSX.Element[] = [
      mode === StrategyMode.EDIT ? (
        <div
          key={v4()}
          className={`${classes.seperatar} ${
            params.length === 0 ||
            (validateMathDesCondition &&
              !validateMathDesCondition.status &&
              validateMathDesCondition.mathErrorIndex <= 0)
              ? classes.invalid
              : ''
          }`}
        >
          <div className={classes.addFieldLine} />
          <IconButton
            className={`${classes.addFieldIcon} addFieldIcon`}
            color="secondary"
            onClick={() => onAddClicked(paramKey, 0)}
          >
            <AddCircleSharpIcon fontSize={'medium'} />
          </IconButton>
        </div>
      ) : (
        <></>
      ),
    ];
    const editParams = editMode[paramKey];
    params.map((param: FieldData, index: number) => {
      const key = param.key;
      const FieldComponent = key ? FieldKeyToField()[key].component : null;
      retData.push(
        <>
          {editParams[index] ? (
            <SelectField
              defaultFieldData={param}
              onDone={fieldData => onDone(fieldData, paramKey, index)}
              onCancel={() => onCancel(paramKey, index)}
              possibleValues={possibleValues ? possibleValues : []}
              onRemove={() => onFieldRemoveClicked(paramKey, index)}
            />
          ) : (
            <div className={classes.fieldContainer}>
              {!!FieldComponent && (
                <FieldComponent
                  onEditDone={onEditParam}
                  onRemove={() => onFieldRemoveClicked(paramKey, index)}
                  getPossibleValues={getPossibleValues}
                  fieldData={param}
                  mode={mode}
                  onEdit={() => onEditIconClick(paramKey, index)}
                  validateMathDescFindErrorIndex={
                    validateMathDescFindErrorIndex
                  }
                  validateMathCondition={validateMathCondition}
                  fieldIndex={index}
                  isMathValid={validateMathDesCondition}
                />
              )}
            </div>
          )}
          {mode === StrategyMode.EDIT ? (
            <div
              className={`${classes.seperatar} ${
                key &&
                validateMathDescFindErrorIndex &&
                validateMathDesCondition &&
                !validateMathDesCondition.status &&
                validateMathDesCondition.mathErrorIndex === index &&
                param.type !== 'mathfunctions'
                  ? classes.invalid
                  : ''
              }`}
            >
              <div className={classes.addFieldLine} />
              <IconButton
                className={`${classes.addFieldIcon} addFieldIcon`}
                color="secondary"
                onClick={() => onAddClicked(paramKey, index + 1)}
              >
                <AddCircleSharpIcon fontSize={'medium'} />
              </IconButton>
            </div>
          ) : (
            <></>
          )}
        </>,
      );
    });
    return retData;
  };

  const onClickRemove = () => {
    onRemove();
  };

  const onClickEdit = () => {
    setEditPeriod(true);
  };

  const onDonePeriod = React.useCallback(
    (fd: FieldData) => {
      setEditPeriod(false);
      // fd.params.period = fd.params.value;
      onEditDone && onEditDone(fd);
    },
    [onEditDone],
  );

  const onCancelPeriod = React.useCallback(() => {
    setEditPeriod(false);
  }, [setEditPeriod]);

  return (
    <div
      className={`${classes.container} ${
        (validateMathCondition && !validateMathCondition(fieldData)) ||
        (!isMathValid &&
          isValid &&
          isValid.status &&
          isValid.errorIndex < fieldIndex) ||
        (isMathValid &&
          !isMathValid.status &&
          isMathValid.mathErrorIndex < fieldIndex)
          ? classes.invalid
          : ''
      }`}
    >
      {editPeriod ? (
        <SelectField
          defaultFieldData={fieldData}
          onDone={fieldData => onDonePeriod(fieldData)}
          onCancel={() => onCancelPeriod()}
          possibleValues={possibleValues ? possibleValues : []}
          onRemove={onClickRemove}
        />
      ) : (
        <>
          <div className={classes.nameContainer}>
            <div className={classes.name}>{name}</div>
            <div className={`${classes.brackets} ${classes.bracketLeft}`}>
              (
            </div>
          </div>
          {renderFieldData('param1')}
          <div
            className={classes.nameContainer}
            style={{ paddingRight: '0px' }}
          >
            <div className={`${classes.brackets} ${classes.bracketRight}`}>
              )
            </div>
            {mode === StrategyMode.EDIT ? (
              <IconButton
                id="menu"
                onClick={onClickEdit}
                className={classes.icon}
              >
                <CreateIcon fontSize={'small'} />
              </IconButton>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
}
