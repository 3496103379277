import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import { decodeUrlData } from 'utils/GenericFunctions';
import { HistoryItem } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Services
import useGetBotHistory from 'services/Bots/useGetBotHistory';

export function BotHistoryPage() {
  // Hooks
  const breadcrumb = useBreadcrumbManager();
  const { t } = useTranslation();
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();
  const history = useHistory();
  const { data: botHistoryData, isLoading } = useGetBotHistory(parseInt(bid));

  return (
    <>
      <Helmet>
        <title>Bot History</title>
        <meta name="description" content="Bot History Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <Box className="sticky-top" sx={{ backgroundColor: 'secondary.light ' }}>
        <PageHeader
          variant="inactive"
          buttonText={decodeUrlData(title)}
          title={<FormattedMessage id="bots.page_title.history" />}
          buttonHandleClick={() => history.goBack()}
          breadcrumb={
            <Breadcrumbs
              page={PAGES.BOT_HISTORY}
              data={{ subId: sid, botId: bid, botName: title }}
            />
          }
        />
        <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
      </Box>
      <Box>
        {botHistoryData &&
          botHistoryData.map((history, i) => (
            <Box key={i}>
              <HistoryItem
                data={history}
                handleClick={() =>
                  breadcrumb.push(
                    `/bots/${history.bot_id}/history/${sid}/${history.sub_id}/${title}`,
                    PAGES.BOT_DETAIL,
                    `/bots/${history.bot_id}/history/${sid}/${title}`,
                    t('bots.page_title.history'),
                  )
                }
              />
              {botHistoryData.length - 1 !== i && (
                <Divider className="m-main-spacing" />
              )}
            </Box>
          ))}
      </Box>
    </>
  );
}
