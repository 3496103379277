import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { GetBrokerForm } from 'services/apiServices';
import { Errors, GetBrokerFormResult } from 'types/ApiServicesTypes';

export default function useGetBrokerForm() {
  const { showAlert } = useContext(ToastContext);
  const objQuery = useQuery<GetBrokerFormResult, Errors>('GetBrokerForm', () =>
    GetBrokerForm(),
  );
  if (objQuery.isError && objQuery.error) {
    showAlert!(objQuery.error.message);
  }

  return objQuery;
}
