import {
  DesktopDateTimePicker,
  LocalizationProvider,
  TimeView,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import TimeLineProgress from './TimeLineProgress';
import { Box } from '@mui/material';

const TimeLineContent = ({
  date,
  setDate,
}: {
  date: string;
  setDate: (date: string) => void;
}) => {
  const handleClickDate = (selectedDate: moment.Moment | null) => {
    if (selectedDate) {
      const formattedDate = selectedDate.format('YYYY-MM-DD HH:mm:ss ');
      setDate(formattedDate);
    }
  };
  const disableWeekends = (selectedDate: moment.Moment | null) => {
    return selectedDate?.day() === 0 || selectedDate?.day() === 6;
  };
  const disableTimes = (
    selectedDate: moment.Moment | null,
    timeView: TimeView,
  ) => {
    if (selectedDate) {
      const hours = selectedDate.hours();
      const minutes = selectedDate.minutes();
      if (timeView === 'hours') {
        return hours < 9 || hours > 15;
      } else if (timeView === 'minutes') {
        return (hours === 9 && minutes < 16) || (hours === 15 && minutes > 30);
      }
    }
    return false;
  };

  return (
    <Box display={'flex'}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDateTimePicker
          label={'Date'}
          format="YYYY-MM-DD HH:mm"
          value={moment(date, 'YYYY-MM-DD HH:mm:ss')}
          onChange={handleClickDate}
          disableFuture
          shouldDisableDate={disableWeekends}
          shouldDisableTime={disableTimes}
          timeSteps={{ minutes: 1 }}
          minDateTime={moment('2023-03-01 09:16:00', 'YYYY-MM-DD HH:mm:ss')}
          ampm={false}
          sx={{
            '& .MuiMultiSectionDigitalClockSection-root': {
              overflowY: 'hidden !important',
            },
            '& .MuiOutlinedInput-input': {
              py: 2.5,
            },
            height: '100%',
          }}
          slotProps={{
            digitalClockSectionItem: {
              sx: {
                scrollbarWidth: 'none',
                overflowY: 'hidden  ',
                '::-webkit-scrollbar': {
                  width: '1px',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
      <TimeLineProgress date={date} setDate={setDate} />
    </Box>
  );
};

export default TimeLineContent;
