import { Fragment } from 'react';
import { Divider } from '@mui/material';
import TodaysPositionItem from 'app/design/itemsComponent/TodaysPositionItem';
import { TodaysPositionsProps } from 'types/Position';

export const ACTIVE = 'ACTIVE';
export const PAPER = 'PAPER';

const TodaysPositions = ({
  positions,
  handlePositionClick,
  liveFeedData,
  isTopBorder,
}: TodaysPositionsProps) => {
  return (
    <>
      {positions.length > 0 && (
        <>
          {isTopBorder && (
            <Divider
              className="m-main-spacing"
              sx={{
                borderColor: 'grey.200',
                borderBottomWidth: '2px',
              }}
            />
          )}
          {positions.map((position, i) => (
            <Fragment key={i}>
              <TodaysPositionItem
                position={position}
                handlePositionClick={handlePositionClick}
                currentPrice={
                  position.exit_price === 0 &&
                  liveFeedData &&
                  liveFeedData[position.trading_symbol]
                    ? Number(liveFeedData[position.trading_symbol].Close)
                    : 0
                }
              />
              {positions.length - 1 !== i && (
                <Divider className="m-main-spacing" />
              )}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default TodaysPositions;
