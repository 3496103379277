import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BotHistory } from 'types/ApiServicesTypes';
import {
  decimalFormat,
  formatDateString,
  formatNumber,
  getColorByNum,
  ifFutureDate,
} from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';
import InstrumentString from 'app/design/itemsComponent/InstrumentString';

type HistoryItemProps = {
  data: BotHistory;
  link?: {
    pathname: string;
    state: {
      page: string;
      ptype: string;
      subId: string;
      fromMoreInfo: boolean;
    };
  };
  handleClick?: () => void;
};

const HistoryItem = (props: HistoryItemProps) => {
  const {
    start_data,
    end_date,
    category,
    total_profit_loss,
    total_profit_loss_per,
    net_profit,
    // net_profit_percentage,
    initial_capital,
    portfolio_value,
    instrument,
    currency_symbol,
  } = props.data;

  const instrumentData = instrument.map(x => x.symbol);

  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box
        {...(props.link && { component: RouterLink, to: props.link })}
        {...(props.handleClick !== undefined && { onClick: props.handleClick })}
        sx={{ cursor: 'pointer' }}
      >
        <Box display="flex" justifyContent="space-between" sx={{ pb: 0.5 }}>
          <Box flex={1} display="flex">
            <Typography variant="body2" display="flex" alignItems="center">
              {formatDateString(start_data, 'DD MMM')}{' '}
              {formatDateString(start_data, 'YYYY') !==
              formatDateString(end_date, 'YYYY')
                ? formatDateString(start_data, 'YYYY')
                : ''}
              <ArrowForwardIcon sx={{ fontSize: '1rem', mx: 0.4587 }} />{' '}
              {ifFutureDate(formatDateString(end_date, 'DD MMM YYYY'))}
            </Typography>
          </Box>
          <Box sx={{ pl: 2 }}>
            {category && (
              <MuiChip label={category} size="xsmall" color="greylight" />
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" sx={{ pb: 0.5 }}>
          <Box flex={1}>
            <Typography
              color="text.secondary"
              display="block"
              variant="body3"
              sx={{ mt: 0.5 }}
            >
              {instrumentData.length > 0 && (
                <InstrumentString instrument={instrumentData} />
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 0.5 }}
        >
          <Box flex={1} display="flex">
            <Typography variant="body3" fontWeight={400} color="text.secondary">
              Profit / Loss
            </Typography>
          </Box>
          <Box sx={{ pl: 2 }}>
            <Typography
              variant="body2"
              fontWeight="500"
              color={getColorByNum(total_profit_loss)}
            >
              {currency_symbol}
              {formatNumber(total_profit_loss)} (
              {decimalFormat(total_profit_loss_per)}
              %)
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 0.5 }}
        >
          <Box flex={1} display="flex">
            <Typography variant="body3" fontWeight={400} color="text.secondary">
              Total Value
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body3" fontWeight={500}>
              {currency_symbol}
              {formatNumber(initial_capital, true)}
            </Typography>
            <ArrowForwardIcon sx={{ fontSize: '12px', ml: 0.5 }} />
            <Typography
              variant="body3"
              fontWeight={500}
              color={getColorByNum(net_profit)}
              sx={{ ml: 0.5 }}
            >
              {`${currency_symbol}${formatNumber(portfolio_value, true)}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HistoryItem;
