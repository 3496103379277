import { CAPABILITIES } from 'types/Capability';
import {
  backTestingChecker,
  liveTradingChecker,
  paperTestingChecker,
} from './capabilityCheckers';

export const capabilityToChecker = {
  [CAPABILITIES.BACK_TESTING]: backTestingChecker,
  [CAPABILITIES.LIVE_TRADING]: liveTradingChecker,
  [CAPABILITIES.PAPER_TESTING]: paperTestingChecker,
};
