import { Box } from '@mui/material';
import {
  ItemHead,
  ItemSeparator,
  SymbolItem,
} from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { BANK_NIFTY_SYMBOL, NIFTY_SYMBOL } from 'constants/common';
import { Universe } from '../StrategyBuilder/types';

const StrategySymbols = ({ symbols }: { symbols: Universe[] }) => {
  const unique = symbols.filter((obj, index) => {
    return index === symbols.findIndex(o => obj.symbol === o.symbol);
  });

  return (
    <Box sx={{ backgroundColor: 'grey.100' }}>
      <ItemHead
        variant="small"
        title={<FormattedMessage id="user_bot_startegy.symbols" />}
      />
      <Box className="m-main-spacing">
        <ItemSeparator variant="1px" />
      </Box>
      {unique.length &&
        unique.map(symbol => {
          const symbolData =
            symbol.symbol === NIFTY_SYMBOL
              ? { symbol: 'NIFTY 50', company_name: 'NIFTY 50 INDEX' }
              : symbol.symbol === BANK_NIFTY_SYMBOL
              ? { symbol: 'NIFTY BANK', company_name: 'NIFTY BANK INDEX' }
              : { symbol: symbol.symbol, company_name: symbol.name };

          return (
            <Box key={symbol.symbol}>
              <SymbolItem symbol={symbolData} />
              <ItemSeparator variant="1px" />
            </Box>
          );
        })}
    </Box>
  );
};

export default StrategySymbols;
