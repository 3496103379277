import { Box, Typography } from '@mui/material';

export default function Ribbon(props) {
  return (
    <Box
      sx={{
        zIndex: 1,
        top: '-5px',
        right: '-5px',
        width: '100px',
        height: '100px',
        textAlign: 'right',
        overflow: 'hidden',
        position: 'absolute',
      }}
    >
      <Typography
        component={'span'}
        sx={{
          top: '28px',
          color: '#fff',
          fontSize: '12px',
          fontWeight: 500,
          textAlign: 'center',
          lineHeight: '20px',
          display: 'block',
          right: '-25px',
          width: '125px',
          position: 'absolute',
          transform: 'rotate(45deg)',
          WebkitTransform: 'rotate(45deg)',
          background: 'linear-gradient(#00897b 0%, #00695c 100%)',
          boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            top: '100%',
            zIndex: -1,
            borderLeft: '3px solid #00695c',
            borderRight: '3px solid transparent',
            borderBottom: '3px solid transparent',
            borderTop: '3px solid #00695c',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            right: '0px',
            top: '100%',
            zIndex: -1,
            borderLeft: '3px solid transparent',
            borderRight: '3px solid #00695c',
            borderBottom: '3px solid transparent',
            borderTop: '3px solid #00695c',
          },
        }}
      >
        {props.children}
      </Typography>
    </Box>
  );
}
