import { ToastContext } from 'app/components/Context/ToastContext';
import { useContext } from 'react';
import useGetProfileFeatures from 'services/Menu/useGetProfileFeatures';
import { FEATURES } from 'types/Feature';
import { featureChecker } from './featureChecker';

type FeatureFlagProps = {
  features: FEATURES[];
  fallback: React.ReactNode;
  children: React.ReactNode;
};

export const FeatureFlag = ({
  features,
  fallback,
  children,
}: FeatureFlagProps) => {
  const { data, isLoading } = useGetProfileFeatures();

  const { showAlert } = useContext(ToastContext);

  let isValid = true;

  features.forEach(feature => {
    if (data && data[feature] !== undefined) {
      if (data[feature] === 0) {
        isValid = false;
        return;
      }
    } else {
      const isFeatureValid = featureChecker[feature];
      if (!isFeatureValid) {
        isValid = false;
        return;
      }
    }
  });

  if (isLoading) return <></>;

  if (isValid) {
    return <>{children}</>;
  } else if (fallback) {
    return <>{fallback}</>;
  } else {
    showAlert!('Feature is not available');
    return <></>;
  }
};

export const useIsFeatureFlag = (features: FEATURES[]) => {
  const { data } = useGetProfileFeatures();
  let isValid = true;

  features.forEach(feature => {
    if (data && data[feature] !== undefined) {
      if (data[feature] === 0) {
        isValid = false;
        return;
      }
    } else {
      const isFeatureValid = featureChecker[feature];
      if (!isFeatureValid) {
        isValid = false;
        return;
      }
    }
  });

  return isValid;
};

export enum FeatureStages {
  DISABLE = 0,
  BETA = 1,
  ALPHA = 2,
  STABLE = 3,
}

export const useIsFeatureFlagWithStage = (
  feature: FEATURES,
): {
  enabled: boolean;
  stage: FeatureStages;
} => {
  const { data } = useGetProfileFeatures();
  if (data && data[feature] !== undefined) {
    return {
      enabled: data[feature] > 0,
      stage: data[feature],
    };
  } else {
    return {
      enabled: !!featureChecker[feature],
      stage: FeatureStages.STABLE,
    };
  }
};
