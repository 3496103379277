import { useQuery } from 'react-query';
import { GetNotifications } from 'services/apiServices';
import { Errors, GetNotificationResultProps } from 'types/ApiServicesTypes';

export default function useGetNotifications() {
  const objQuery = useQuery<GetNotificationResultProps, Errors>(
    'GetNotifications',
    () =>
      GetNotifications().then(res => {
        if (res && res.length > 0) {
          const unreadCountData = res.filter(x => x.read !== true).length;
          return {
            data: res,
            unreadCount: unreadCountData ? unreadCountData : 0,
          };
        }
        return null;
      }),
  );
  return objQuery;
}
