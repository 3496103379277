import { useContext } from 'react';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useMutation } from 'react-query';
import { BrokerLogout } from 'services/apiServices';
import { BrokerLogoutResult, Errors } from 'types/ApiServicesTypes';

export default function useBrokerLogout() {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    BrokerLogoutResult,
    Errors,
    Pick<{ id: number }, 'id'>
  >('BrokerLogout', values =>
    BrokerLogout(values.id)
      .then(res => {
        if (res && res.success) {
          showAlert!('Logged out successfully.', 'success');
          return res;
        } else if (res && res.error) showAlert!(res.error);
        else showAlert!('Something went wrong. Please try again.');
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
