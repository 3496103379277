import React, { Fragment } from 'react';
import { Divider, Pagination, Box } from '@mui/material';
import { formatDateString } from 'utils/GenericFunctions';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import { ItemHead, PositionItem } from '../speedBot/EntityItem';
import BotPositionsTable from '../speedBot/ComponentItem/Bots/BotPositionsTable';
import { Position } from 'types/ApiServicesTypes';
import { handleTouchMoveHandle, handleTouchStartHandle } from './swipeUtil';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

let eventDir: string | null = null;

const PositionsListLayout = ({
  data,
  page,
  totalPages,
  handleChangePagination,
  handleClick,
  handleChartRowClick,
  handleChangeTab,
}: {
  data: PositionsListLayoutProps;
  page: number;
  totalPages: number;
  handleChangePagination: (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => void;
  handleClick?: (positionData: Position) => void;
  handleChartRowClick?: (positionData: Position) => void;
  handleChangeTab?: (newValue: number) => void;
}) => {
  const isMdUp = useGetMediaQueryUp('md');

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    handleTouchStartHandle(e);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const eventDirRes = handleTouchMoveHandle(e);
    if (eventDirRes) eventDir = eventDirRes;
  };
  const handleTouchEnd = () => {
    if (!isMdUp && eventDir === 'right' && handleChangeTab) {
      handleChangeTab(1);
      eventDir = '';
    }
  };

  return (
    <>
      <Box
        sx={{ py: 1, height: '100%' }}
        onTouchStart={(touchStartEvent: React.TouchEvent<HTMLElement>) =>
          handleTouchStart(touchStartEvent)
        }
        onTouchMove={(touchMoveEvent: React.TouchEvent<HTMLElement>) =>
          handleTouchMove(touchMoveEvent)
        }
        onTouchEnd={() => handleTouchEnd()}
      >
        {isMdUp ? (
          <BotPositionsTable
            page={page}
            data={data}
            handleClick={handleClick}
            handleChartRowClick={handleChartRowClick}
          />
        ) : (
          data &&
          data.map((grp, i: number) => (
            <Box key={i}>
              <ItemHead
                variant="small"
                title={formatDateString(grp.key, 'ddd, D MMM YYYY')}
                color="text.disabled"
              />
              <Divider className="m-main-spacing" />
              {grp.data &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                grp.data.map((position: Position) => (
                  <Fragment key={position.id}>
                    <PositionItem
                      position={position}
                      handleClick={handleClick}
                    />
                    {grp.data.length - 1 !== i && (
                      <Divider className="m-main-spacing" />
                    )}
                  </Fragment>
                ))}
            </Box>
          ))
        )}
        {data && data.length > 0 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePagination}
            color="primary"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '1.5rem',
              pb: 3,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PositionsListLayout;
