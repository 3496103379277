import { useQuery } from 'react-query';
import { GetTicketMessageData } from 'services/apiServices';
import { Errors, GetTicketMessageResult } from 'types/ApiServicesTypes';

export default function useGetTicketMessageData(id: number) {
  const objQuery = useQuery<GetTicketMessageResult, Errors>(
    ['GetTicketMessageData', id],
    async () => {
      if (id) return await GetTicketMessageData(id);
      else return {} as GetTicketMessageResult;
    },
  );
  return objQuery;
}
