import { styled } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';

const MuiBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  '& .MuiBottomNavigationAction-root': {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(18),
    },
  },
  '& .MuiBottomNavigationAction-root.Mui-selected': {
    '&:after': {
      content: "''",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      zIndex: 2,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      borderTop: `none`,
      '&:after': {
        bottom: '-2px',
        top: 'auto',
      },
    },
  },
  '& .MuiButtonBase-root svg': {
    fontSize: '1.5rem',
  },
  '& .MuiBottomNavigationAction-label': {
    marginTop: theme.spacing(0.5),
    fontSize: '0.625rem',
    whiteSpace: 'pre',
    '&.Mui-selected': {
      fontSize: '0.625rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.625rem',
      '&.Mui-selected': {
        fontSize: '0.625rem',
      },
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '0',
      marginLeft: theme.spacing(1),
      fontSize: '0.875rem',
      '&.Mui-selected': {
        fontSize: '0.875rem',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .MuiButtonBase-root': {
      flexDirection: 'row',
    },
  },
}));

export default MuiBottomNavigation;
