import { Box, alpha } from '@mui/material';
import { ColorBarProps } from './types';

const ColorBar = ({ percentage, color }: ColorBarProps) => {
  const isSuccess = color.color === 'green';

  return (
    <Box
      sx={{
        height: theme => theme.spacing(1.5),
        maxWidth: '24px',
        borderRadius: 0.5,
        backgroundColor: theme =>
          isSuccess
            ? alpha(theme.palette.success.main, color.opacity)
            : alpha(theme.palette.error.main, color.opacity),
        width: `${percentage / 4}px`,
        ...(isSuccess && { ml: '50%' }),
        ...(!isSuccess && { mr: '50%' }),
      }}
    ></Box>
  );
};

export default ColorBar;
