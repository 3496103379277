import { LargeWithIconVariantProps } from '../../types';
import { Box, Typography } from '@mui/material';

const LargeWithIconVariant = (props: LargeWithIconVariantProps) => {
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2 }}
      display="flex"
      alignItems="start"
    >
      <Box display="flex" alignItems="center" sx={{ whiteSpace: 'nowrap' }}>
        <Box sx={{ flex: { xs: 1, md: 'unset' } }}>
          <Typography variant="h6" fontWeight={500} color="primary.main">
            {props.title}
          </Typography>
        </Box>
        <Box sx={{ ml: 0.75 }}>{props.icon}</Box>
      </Box>
      {props.extraComp}
    </Box>
  );
};

export default LargeWithIconVariant;
