import { Box } from '@mui/material';
import { OptionChainWithExpiryType } from 'app/pages/Simulator';
import { PositionMetaData, PositionWithExpiryData } from '../types';
import PositionCustomTable from './PositionCustomTable';

const PositionTab = ({
  positionsWithExpiry,
  optionChainData,
  lotSize,
  date,
  currentExpiryDate,
  updatePositions,
  removePosition,
}: {
  positionsWithExpiry: PositionWithExpiryData;
  optionChainData: OptionChainWithExpiryType;
  lotSize: number;
  date: string;
  currentExpiryDate: string;
  updatePositions: (positions: PositionMetaData) => void;
  removePosition: (position: PositionMetaData) => void;
}) => {
  const positions = convertPositionsMetaData(positionsWithExpiry);

  const handleClick = () => {
    //
  };

  return (
    <Box>
      <PositionCustomTable
        optionChainData={optionChainData}
        handleOptionClick={handleClick}
        updatePositions={updatePositions}
        positions={positions}
        date={date}
        lotSize={lotSize}
        removePosition={removePosition}
        currentExpiryDate={currentExpiryDate}
      />
    </Box>
  );
};

export default PositionTab;

export const convertPositionsMetaData = (
  positionsWithExpiryData: PositionWithExpiryData,
) => {
  let positions: PositionMetaData[] = [] as PositionMetaData[];
  positions = Object.values(positionsWithExpiryData)
    .map(position => Object.values(position))
    .flat();
  return positions;
};
