import { memo, useState } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { getDaysBetweenDates } from './utils';
import CalendarDateRow from './CalendarDateRow';
import moment from 'moment';
import { ProfitLossByDateData } from 'types/ApiServicesTypes';

const CalendarTable = styled(Box)(({ theme }) => ({
  '& table': {
    borderCollapse: 'inherit',
    width: '100%',
    borderSpacing: theme.spacing(1),

    '& .month-name': {
      textAlign: 'center',
      paddingBottom: theme.spacing(0.5),
    },

    '& th, td': {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '14px',
      textAlign: 'center',
      color: theme.palette.text.disabled,
    },

    '& tbody > tr > td:not(.past-future-date)': {
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.grey[200],
      color: theme.palette.text.disabled,
    },
  },
}));

const MonthCalendar = ({ data }: { data: ProfitLossByDateData }) => {
  // getting all months
  const months = getDaysBetweenDates(
    new Date(data.startDate),
    new Date(data.endDate),
  );

  const [openTooltip, setOpenTooltip] = useState('');
  const handleTooltipClose = () => {
    if (openTooltip) {
      setOpenTooltip('');
    }
  };
  const handleTooltipOpen = (dt: string) => {
    setTimeout(() => {
      setOpenTooltip(dt);
    }, 150);
  };

  return (
    <CalendarTable
      className="m-main-spacing"
      onClick={() => handleTooltipClose()}
    >
      <Grid container spacing={2}>
        {months.map((monthData, index) => (
          <Grid item key={index} xs={12} md={6} lg={4}>
            <Box className="tableContainer">
              <table className="calender-date">
                <thead>
                  <tr>
                    <th className="month-name" colSpan={7}>
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        color="text.primary"
                      >
                        {moment()
                          .month(monthData.month - 1)
                          .format('MMM')}{' '}
                        {monthData.year}
                      </Typography>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        S
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        M
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        T
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        W
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        T
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        F
                      </Typography>
                    </th>
                    <th>
                      <Typography variant="body3" fontWeight={500}>
                        S
                      </Typography>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <CalendarDateRow
                    month={monthData.month - 1}
                    year={monthData.year}
                    data={data}
                    openTooltip={openTooltip}
                    handleTooltipClose={handleTooltipClose}
                    handleTooltipOpen={handleTooltipOpen}
                  />
                </tbody>
              </table>
            </Box>
          </Grid>
        ))}
      </Grid>
    </CalendarTable>
  );
};

export default memo(MonthCalendar);
