import { Fragment } from 'react';
import { Position } from 'types/ApiServicesTypes';
import { Box } from '@mui/material';
import TodaysPositionItem from '../speedBot/EntityItem/TodayspositionItem';
import { SymbolsData } from 'types/Symbols';

const PositionItemCard = ({
  data,
  handleClick,
  sortKey,
  liveFeedData,
  sortDirection,
}: {
  data: Position[];
  handleClick?: (positionData: Position) => void;
  sortKey: string;
  liveFeedData?: SymbolsData;
  sortDirection: 'asc' | 'desc';
}) => {
  const sortedData = [...data].sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const aValue = a[sortKey];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const bValue = b[sortKey];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
  });

  // eslint-disable-next-line no-console
  // console.log('ss', rows);
  return (
    <Box>
      {sortedData.map((position, i: number) => {
        return (
          <Box key={i}>
            <Fragment key={position.id}>
              <TodaysPositionItem
                position={position}
                currentPrice={
                  position.exit_price === 0 &&
                  liveFeedData &&
                  liveFeedData[position.trading_symbol]
                    ? Number(liveFeedData[position.trading_symbol].Close)
                    : 0
                }
                handleClick={handleClick}
              />
            </Fragment>
          </Box>
        );
      })}
    </Box>
  );
};

export default PositionItemCard;
