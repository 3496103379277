import { FieldData, FieldParamValue } from './types';
import { FieldKeyToField } from './fieldsData';
import useClasses from '../utils/useClasses';
import { IconButton, useTheme } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { StrategyMode } from '../utils/strategy-data';

// Style const

interface Props {
  fieldData: FieldData;
  mode: string;
  onEdit: () => void;
  onRemove: () => void;
  isValid?: {
    status: boolean;
    errorIndex: number;
  };
  fieldIndex: number;
  isMathValid?: {
    status: boolean;
    mathErrorIndex: number;
  };
}

type AccumulatorType = {
  [key: string]: string | number | boolean;
};

type RenderValues = string | number | boolean;

export function MovingAverageField(props: Props) {
  const { fieldData, mode, onEdit, isValid, fieldIndex, isMathValid } = props;
  const theme = useTheme();
  const styles = () => ({
    container: {
      height: '44px',
      margin: '0px 3px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '4px 0px',
      background: theme.palette.info.light,
    },
    indicatorInfo: {
      margin: '0px 12px',
      width: 'max-content',
    },
    indicatorName: {
      fontSize: '14px',
      color: theme.component.equityBuilder.blueText,
      letterSpacing: '0.15px',
      lineHeight: '143%',
    },
    indicatorData: {
      fontSize: '12px',
      letterSpacing: '0.15px',
      lineHeight: '130%',
      color: theme.component.equityBuilder.blueText,
    },
    icon: {
      width: '4px',
      height: '16px',
      color: theme.component.equityBuilder.blueText,
      marginRight: '6px',
    },
    invalid: {
      border: '1px dashed #f44336 !important',
    },
  });
  const classes = useClasses(styles);
  const { key, params: paramsData } = fieldData;
  const indicator = FieldKeyToField()[key];
  const { params: indicatorParams } = indicator;

  const paramsRenderValues: RenderValues[] = indicatorParams.map(param => {
    if (param.type === 'dropdown') {
      const paramValues = param.values;
      const paramValuesByKeys = paramValues.reduce(
        (accumulator: AccumulatorType, value) => {
          accumulator[value.key] = value.value;
          return accumulator;
        },
        {} as AccumulatorType,
      );
      const paramValue: RenderValues =
        paramValuesByKeys[paramsData[param.key] as FieldParamValue];
      return paramValue;
    } else {
      return paramsData[param.key] as RenderValues;
    }
  });
  const paramRenderString = paramsRenderValues.join(', ');
  return (
    <div
      className={`${classes.container} ${
        (isValid && isValid.status && isValid.errorIndex < fieldIndex) ||
        (isMathValid &&
          !isMathValid.status &&
          isMathValid.mathErrorIndex < fieldIndex)
          ? classes.invalid
          : ''
      }`}
    >
      <div className={classes.indicatorInfo}>
        <div className={classes.indicatorName}>{indicator.name}</div>
        <div className={classes.indicatorData}>({paramRenderString})</div>
      </div>
      {mode === StrategyMode.EDIT ? (
        <IconButton id="menu" onClick={onEdit} className={classes.icon}>
          <CreateIcon fontSize={'small'} />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
}
