import { useContext } from 'react';
import { useMutation } from 'react-query';
import { ToastContext } from 'app/components/Context/ToastContext';
import { VerifyOtp } from 'services/apiServices';
import { Errors, VerifyOtpResult } from 'types/ApiServicesTypes';

export default function useVerifyOtp(ccode: string, phone: string) {
  const { showAlert } = useContext(ToastContext);
  const mutation = useMutation<
    VerifyOtpResult,
    Errors,
    Pick<{ otp: string }, 'otp'>
  >('VerifyOtp', values =>
    VerifyOtp(ccode, phone, values.otp)
      .then(res => {
        return res;
      })
      .catch(err => {
        showAlert!(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    showAlert!(mutation.error.message);
  }

  return mutation;
}
