import { Box, Typography } from '@mui/material';
import {
  useGetMediaQueryDown,
  useGetMediaQueryUp,
} from 'app/hooks/useGetMediaQuery';
import { PortfolioSymbol } from 'types/ApiServicesTypes';
import { SymbolsData } from 'types/Symbols';
import {
  formatNumber,
  getArrowDirByNum,
  getColorByNum,
} from 'utils/GenericFunctions';

function PortfolioSymbolItem({
  liveFeedData,
  symbol,
  isLast,
  home,
}: {
  liveFeedData: SymbolsData;
  symbol: PortfolioSymbol;
  isLast: boolean;
  home?: boolean;
}) {
  const isMaxUp = useGetMediaQueryDown('max');

  const liveSymbolValue = liveFeedData[symbol.value];
  const liveClose = liveSymbolValue && Number(liveSymbolValue.Close);
  const liveCloseDiff =
    liveSymbolValue && liveClose - Number(liveSymbolValue.LastClose);
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <Box
        lineHeight={0}
        sx={{
          mr: { xs: 0, md: isLast ? 0 : 2 },
          textAlign: isLast ? 'right' : undefined,
        }}
      >
        <Typography
          variant="body3"
          fontWeight={500}
          display={!isMdUp && home ? 'inline' : home ? 'flex' : 'inline'}
        >
          {symbol.label}{' '}
        </Typography>
        <Typography
          variant="body3"
          display={
            isMaxUp && home
              ? ['inline', 'inline', 'inline', 'flex']
              : !isMdUp && home
              ? 'inline'
              : 'inline'
          }
          color={getColorByNum(liveCloseDiff)}
          fontWeight={500}
        >
          {getArrowDirByNum(liveCloseDiff) + formatNumber(liveClose)}
        </Typography>
        <Typography
          variant="body3"
          sx={{
            ml: { xs: 0, md: 0.5 },
            display: { xs: 'block', md: 'inline' },
          }}
          color={getColorByNum(liveCloseDiff)}
        >
          {`${
            liveCloseDiff > 0 ? '+' : liveCloseDiff < 0 ? '-' : ''
          }${formatNumber(liveCloseDiff)} (${formatNumber(
            (liveCloseDiff / liveClose) * 100,
          )}%)`}
        </Typography>
      </Box>
    </>
  );
}

export default PortfolioSymbolItem;
